import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { useParams } from 'react-router-dom';
import { getAbseenteismByTechnicianId, getEmployeeById } from '../../../../services/Employee';
import { toast } from 'react-toastify';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function AbsenteeismDetails() {

    const {id} = useParams();

    const [data, setData] = useState({});
    const [name, setName] = useState('');
    let common = [];

    async function getDetails(){
        const toastAbsenteeism = toast.loading("Buscando dados, aguarde...");

        let name = await getEmployeeById(id);
        let dataReturn = await getAbseenteismByTechnicianId(id);
        if(dataReturn?.data?.response === 'success' && name?.data?.response === 'success'){
            handleSuccessToast('Dados encontrados!', 'update', toastAbsenteeism);
            setData(dataReturn?.data?.data);
            setName(name?.data?.data?.nome_tec || '');
        }else{
            handleErrorsToast('Sem dados!', dataReturn?.data, 'update', toastAbsenteeism);
            setData({});
        }
    }

    function renderTables() {

        for (let ano in data) {
            let columns = [{
                id: 'void_' + ano,
                name: '',
                sortable: false,
                filter: false
            }];

            let dataToTable = [];

            let dadosAno = data[ano];
            let percentual = dadosAno.percentuais
            let quantidade = dadosAno.quantidade
            
            let line = {['void_'+ano]: 'Percentual'}

            for (let mes in percentual) {
                let id = mes + "_" + ano;

                columns.push({
                    id: id,
                    name: mes
                })
                line = {...line, [id]: percentual[mes]}
            }

            dataToTable.push(line);

            line = {['void_'+ano]: 'Quantidade'}

            for(let mes in quantidade){
                let id = mes + "_" + ano;

                line = {...line, [id]: quantidade[mes]}
            }

            dataToTable.push(line);

            common.push(
                <Card title={ano} collapseId={`ano_${ano}`} icon='fa-calendar' key={`ano_${ano}`}>
                    <div className="row">
                        <div className="col-12">
                            <p><b>Percentual Total de Faltas: </b>{`${percentual.total}%`}</p>
                            <p><b>Quantidade Total de Faltas: </b>{quantidade.total}</p>
                        </div>
                        <div className="col-12">
                            <PrimeTable
                                columns={columns}
                                rows={dataToTable}
                                id={`detalhes_prime_${ano}`}
                            ></PrimeTable>
                        </div>
                    </div>
                </Card>
            )
        }
        return common;
    }

    useEffect(()=>{
        getDetails();
    }, []);

    return (
        <Content headerTitle={`Detalhes Absenteísmo - ${name}`}>
            <SubHeaderSpecific subHeaderConfig='dashboard'></SubHeaderSpecific>
            {renderTables()}
        </Content>
    );
}