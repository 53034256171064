import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import { createBox } from '../../../../services/MamBox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function CreateBox({ isOpen = false, setIsOpen = null }) {
    const INITIAL_STATE = {
        local: '',
        box: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        const toastProducts = toast.loading('Cadastrando estoque, aguarde...');
        let params = {
            ...formData,
            local: formData?.local?.value || ''
        }
        let dataReturn = await createBox(params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Estoque cadastrado com sucesso!', 'update', toastProducts);
            setFormData(INITIAL_STATE);
        } else if (dataReturn?.data?.message === 'The box has already been taken.') {
            handleErrorsToast('Este estoque já está cadastrado!', dataReturn?.data, 'update', toastProducts);
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar o estoque!', dataReturn?.data, 'update', toastProducts);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Estoque'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 6, 6, 6]} genericOption='UfOptions' required></GenericSelect>
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="box">Nome do Estoque</label>
                        <InputText name="box" id="box" placeholder='Fabricante' value={formData?.box || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required></InputText>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}