import axios from "axios";

const api = process.env.REACT_APP_API_VIACEP || 'https://viacep.com.br/ws/'
const api_opencep = process.env.REACT_APP_API_OPENCEP || 'https://opencep.com/v1/'

export async function getDataByCep(cep){

    const fetchData = async (baseApi) => {
        try{
            let data = await axios.get(`${baseApi}${cep}${baseApi.includes('opencep') ? '.json' : '/json'}`, {timeout: 10000});
            return data;
        }catch(e){
            return null;
        }
    }

    const response = await fetchData(api);
    
    if(response && response?.data?.erro !== 'true') return response;
    
    const response2 = await fetchData(api_opencep);

    if(response2) return response2;

    return 'erro';
}

