import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllGroups } from '../../services/Group';
import { Col, FormLabel } from 'react-bootstrap';

export default function GrupoAcessoSelect({ formData, setFormData, fieldName = 'grupos', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [groupsOptions, setGroupsOptions] = useState([]);

    async function getGroups() {
        let dataReturn = await getAllGroups();
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let groups = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.descricao }
            }) || [];
            setGroupsOptions(groups);
        } else {
            setGroupsOptions([]);
        }
    }


    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getGroups();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Grupos / Perfil</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={groupsOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}