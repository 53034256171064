import React, {useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createManufacturer } from '../../../../services/Manufacturer';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function CreateManufacturer({ isOpen = false, setIsOpen = null }) {
    const INITIAL_STATE = {
        nome_fabricante: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        const toastProducts = toast.loading('Cadastrando fabricante, aguarde...');

        let dataReturn = await createManufacturer(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Fabricante cadastrado com sucesso!', 'update', toastProducts);
            setFormData(INITIAL_STATE);
        }else if(dataReturn?.data?.message === 'The nome fabricante has already been taken.'){
            handleErrorsToast('Este fabricante já está cadastrado!', dataReturn?.data, 'update', toastProducts);
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar o fabricante!', dataReturn?.data, 'update', toastProducts);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Fabricante'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="nome_fabricante">Fabricante</label>
                        <InputText name="nome_fabricante" id="nome_fabricante" className='col-12' placeholder='Fabricante' value={formData?.nome_fabricante || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required></InputText>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}