import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getShipmentById, updateDactValuesBulk } from '../../../../services/Shipment';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { bulkAddGed } from '../../../../services/Ged';
import { Button, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function BulkPendingDactDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, pendingDactId = '', setUpdateLogistic = null, handleClearRows = null }) {

    const initialState = {
        dact: '',
        valor_dact: '',
        arquivo: ''
    }

    const [formData, setFormData] = useState(initialState);
    const [tableFormData, setTableFormData] = useState([]);
    const arquivoRef = useRef();
    const mySwal = withReactContent(Swal);
    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto'
        },
        {
            id: 'serial_produto',
            name: 'Serial'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto'
        }
    ]

    async function submitForm(e) {
        e.preventDefault();

        const toastData = toast.loading('Atualizando dados, aguarde...');

        let params = {
            ...formData,
            ids_remessa: pendingDactId.split(',')
        }

        let dataReturn = await updateDactValuesBulk(params);

        if (dataReturn?.status === 200) {
            await submitFile();
            let data = dataReturn?.data?.data;
            let errors = [];

            for (let d in data) {
                if (!data[d]['success']) {
                    errors.push({ message: data[d]['message'] });
                }
            }

            if (errors?.length > 0) {
                handleErrorsToast('Algumas remessas não foram atualizadas', {}, 'update', toastData);
                mySwal.fire({
                    title: 'Algumas remessas não foram atualizadas!',
                    text: 'Segue os erros abaixo',
                    html: (
                        <ul>
                            {errors.map((item, idx) => {
                                return <li key={idx}>Erro: {item?.message}</li>
                            })}
                        </ul>
                    )
                })
            } else {
                handleSuccessToast('Todas as remessas foram atualizadas com sucesso!', 'update', toastData);
            }
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
            handleClearRows();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastData);
        }
    }

    async function submitFile(){
        const formDataProduct = new FormData();

        formDataProduct.append('files[]', formData?.arquivo);
        formDataProduct.append('submodulo', 'remessas');
        formDataProduct.append('titulo', `${Date.now()}_${formData?.dact}`); 
        tableFormData.forEach(item => formDataProduct.append('ids_produto[]', item?.id_produto));
    
        let dataReturn = await bulkAddGed(formDataProduct);

        if(dataReturn?.status === 201){
            handleSuccessToast('Upload da DACT efetuada com sucesso!');
            arquivoRef.current.value = '';
        }else{
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
        }
    }

    async function getShipments() {
        let shipmentIdArray = pendingDactId.split(',');

        for (let id of shipmentIdArray) {
            getShipmentById(id).then((resp) => {
                let item = resp?.data?.data;
                setTableFormData(prev => [...prev, { 
                    nome_produto: item?.product?.partnumber?.modelo || '', 
                    serial_produto: item?.product?.serial || '', 
                    tipo_produto: item?.product?.nome_produto || '', 
                    id_produto: item?.product?.id || ''
                }])
            }).catch(e => handleErrorsToast('Ocorreu um erro', e?.data));
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(pendingDactId)) {
            setFormData(initialState);
            setTableFormData([]);
            getShipments();
        }
    }, [isOpen, pendingDactId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={'Atualizar Remessas'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <Form onSubmit={submitForm}>
                <Row>
                    <Col>
                        <h5>Preencha todos os dados da DACT</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimeTable
                            columns={columns}
                            rows={tableFormData}
                            needExport={false}
                            id='bulk_pending_dact'
                        ></PrimeTable>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6>Detalhes da DACT</h6>
                    </Col>
                </Row>
                <Row>
                    <Col md='4' sm='12'>
                        <FormLabel htmlFor="dact">DACT</FormLabel>
                        <FormControl type="text" name="dact" id="dact" placeholder='DACT' value={formData?.dact || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </Col>
                    <Col md='4' sm='12'>
                        <FormLabel htmlFor="valor_dact">Valor DACT</FormLabel>
                        <FormControl type="number" min={0} step={0.01} name="valor_dact" id="valor_dact" placeholder='NFe de Saída' value={formData?.valor_dact || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </Col>
                    <Col md='4' sm='12'>
                        <FormLabel htmlFor="arquivo">Arquivo DACT</FormLabel>
                        <FormControl type="file" name="arquivo" id="arquivo" ref={arquivoRef} onChange={e => {setFormData(prevState=> ({...prevState, [e.target.name]: e.target.files[0]}))}} required/>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button variant='primary' type="submit">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        </Dialog>);
}