import React, { useEffect, useRef, useState } from 'react';
import Cep from '../../../common/Cep/Cep';
import GenericSelect from '../../../common/Selects/GenericSelect';
import { useNavigate } from 'react-router-dom';
import { generateRatPdf, getRatReportByTicketId, updateRatReport } from '../../../services/Rat';
import { getCurrentDate, getCurrentTime, handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import { toast } from 'react-toastify';

export default function RatRegistration({ ticket_id = '', ticket = '' }) {
    const INITIAL_STATE = {
        "id": null,
        "id_tecnico": null,
        "id_contrato": null,
        "id_chamado": null,
        "chamado": null,
        "codigo_ul": null,
        "realizado_por": null,
        "empresa_parceira": null,
        "data_hora_inicio_atendimento": null,
        "data_hora_fim_atendimento": null,
        "dados_basicos_razao_social": null,
        "dados_basicos_endereco": null,
        "dados_basicos_numero": null,
        "dados_basicos_bairro": null,
        "dados_basicos_cep": null,
        "dados_basicos_cidade": null,
        "dados_basicos_estado": null,
        "dados_basicos_contato": null,
        "dados_basicos_telefone": null,
        "ver_tec_inst_lot_link_principal": null,
        "ver_tec_inst_lot_op_principal": null,
        "ver_tec_inst_lot_link_backup": null,
        "ver_tec_inst_lot_op_backup": null,
        "rede_int_patch_panel_inst_rack_sencinet": null,
        "rede_int_possui_keystone_inst_patch_panel": null,
        "rede_int_cabo_rede_canalet_corr": null,
        "rede_int_qtd_pontos_inst": null,
        "rede_int_keystone_inst_terminais": null,
        "rede_int_obs_rede_internta_ponto_rede": null,
        "file_rede_int_foto_fachada": null,
        "file_rede_int_foto_rack_aberto_antes_ins": null,
        "file_rede_int_foto_nobreak": null,
        "file_rede_int_foto_passagem_cabeamento": null,
        "file_rede_int_foto_rack_aberto_apos_inst": null,
        "file_rede_int_foto_rack_fechado": null,
        "file_rede_int_foto_visao_geral": null,
        "file_rede_int_foto_modem_elsys_inst": null,
        "file_rede_int_foto_sim_card_retirado": null,
        "file_rede_int_foto_sim_card_inst": null,
        "file_rede_int_foto_trajeto_cados": null,
        "file_rede_int_foto_idu_desinstalado": null,
        "file_rede_int_foto_antena_satelite": null,
        "file_rede_int_print_nivel_sinal_elsys": null,
        "file_foto_switch_instalado": null,
        "file_foto_switch_retirado": null,
        "file_foto_evidencia_1": null,
        "file_foto_evidencia_2": null,
        "file_foto_evidencia_3": null,
        "file_foto_evidencia_4": null,
        "file_foto_evidencia_5": null,
        "cond_racks_sencinet_rack_boas_cond": null,
        "cond_racks_sencinet_rack_org": null,
        "cond_racks_sencinet_corr_fixado": null,
        "cond_racks_claro_rack_boas_cond": null,
        "cond_racks_claro_rack_org": null,
        "cond_racks_claro_corr_fixado": null,
        "file_cond_racks_rack_sencinet": null,
        "file_cond_racks_rack_claro": null,
        "file_cond_racks_visao_geral_racks": null,
        "cond_racks_obs_cond_racks": null,
        "switch_sencinet_gerenciavel": null,
        "switch_claro_gerenciavel": null,
        "file_switch_rack_sencinet": null,
        "file_switch_rack_claro": null,
        "data_registro": null,
        "data_modificacao": null,
        "dados_eq_inst_modem": null,
        "dados_eq_inst_modem_serial": null,
        "dados_eq_inst_chip": null,
        "dados_eq_inst_imei": null,
        "dados_eq_inst_router": null,
        "dados_eq_inst_router_serial": null,
        "dados_eq_inst_switch": null,
        "dados_eq_inst_switch_serial": null,
        "dados_eq_desinst_modem": null,
        "dados_eq_desinst_modem_serial": null,
        "dados_eq_desinst_chip": null,
        "dados_eq_desinst_imei": null,
        "dados_eq_desinst_idu": null,
        "dados_eq_desinst_idu_serial": null,
        "dados_eq_desinst_switch": null,
        "dados_eq_desinst_switch_serial": null,
        "file_foto_waze": null,
        "minutos_concluir_etapa_1": null,
        "dados_eq_desinst_router": null,
        "dados_eq_desinst_router_serial": null
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const fotoRackRef = useRef('');
    const fotoNobreakRef = useRef('');
    const fotoCabeamentoRef = useRef('');
    const fotoRackPosRef = useRef('');
    const fotoRackFechadoRef = useRef('');
    const fotoVisaoGeralRef = useRef('');
    const fotoModemRef = useRef('');
    const fotoSimCardRef = useRef('');
    const fotoSimCardRetiradoRef = useRef('');
    const fotoTrajetoRef = useRef('');
    const fotoIduDesinstaladoRef = useRef('');
    const fotoAntenaSateliteRef = useRef('');
    const printNivelSinalRef = useRef('');
    const switchInstaladoRef = useRef('');
    const switchRetiradoRef = useRef('');
    const adicional1Ref = useRef('');
    const adicional2Ref = useRef('');
    const adicional3Ref = useRef('');
    const adicional4Ref = useRef('');
    const adicional5Ref = useRef('');
    const rackSencinetRef = useRef('');
    const rackOiRef = useRef('');
    const vistaGeralRacksRef = useRef('');
    const switchSencinetRef = useRef('');
    const switchOiRef = useRef('');
    const navigate = useNavigate();

    async function getReport() {
        let dataReturn = await getRatReportByTicketId(ticket_id);

        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data;

            setFormData({
                ...data,
                ver_tec_inst_lot_link_principal: {value: data?.ver_tec_inst_lot_link_principal || '', label: data?.ver_tec_inst_lot_link_principal || ''},
                ver_tec_inst_lot_op_principal: {value: data?.ver_tec_inst_lot_op_principal || '', label: data?.ver_tec_inst_lot_op_principal || ''},
                ver_tec_inst_lot_link_backup: {value: data?.ver_tec_inst_lot_link_backup || '', label: data?.ver_tec_inst_lot_link_backup || ''},
                ver_tec_inst_lot_op_backup: {value: data?.ver_tec_inst_lot_op_backup || '', label: data?.ver_tec_inst_lot_op_backup || ''},
                rede_int_patch_panel_inst_rack_sencinet: {value: data?.rede_int_patch_panel_inst_rack_sencinet || '', label: data?.rede_int_patch_panel_inst_rack_sencinet || ''},
                rede_int_possui_keystone_inst_patch_panel: {value: data?.rede_int_possui_keystone_inst_patch_panel || '', label: data?.rede_int_possui_keystone_inst_patch_panel || ''},
                rede_int_cabo_rede_canalet_corr: {value: data?.rede_int_cabo_rede_canalet_corr || '', label: data?.rede_int_cabo_rede_canalet_corr || '' },
                rede_int_keystone_inst_terminais: {value: data?.rede_int_keystone_inst_terminais || '', label: data?.rede_int_keystone_inst_terminais || '' },
                cond_racks_sencinet_rack_boas_cond: {value: data?.cond_racks_sencinet_rack_boas_cond || '', label: data?.cond_racks_sencinet_rack_boas_cond || ''},
                cond_racks_sencinet_rack_org: {value: data?.cond_racks_sencinet_rack_org || '', label: data?.cond_racks_sencinet_rack_org || ''},
                cond_racks_sencinet_corr_fixado: {value: data?.cond_racks_sencinet_corr_fixado || '', label: data?.cond_racks_sencinet_corr_fixado || ''},
                switch_sencinet_gerenciavel: {value: data?.switch_sencinet_gerenciavel || '', label: data?.switch_sencinet_gerenciavel || ''},
                switch_claro_gerenciavel: {value: data?.switch_claro_gerenciavel || '', label: data?.switch_claro_gerenciavel || ''},
                cond_racks_claro_corr_fixado: {value: data?.cond_racks_claro_corr_fixado || '', label: data?.cond_racks_claro_corr_fixado || ''},
                cond_racks_claro_rack_boas_cond: {value: data?.cond_racks_claro_rack_boas_cond || '', label: data?.cond_racks_claro_rack_boas_cond || ''},
                cond_racks_claro_rack_org: {value: data?.cond_racks_claro_rack_org || '', label: data?.cond_racks_claro_rack_org || ''},
                chamado: ticket,
                data_hora_fim_atendimento: `${getCurrentDate()} ${getCurrentTime()}:00`
            });
        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
            navigate('/rat/chamados');
        }
    }

    async function updateReport(e) {
        e.preventDefault();
        let toastData = toast.loading('Cadastrando RAT, aguarde...');
        let params = new FormData();

        params.append('id_tecnico', formData?.id_tecnico);
        params.append('id_contrato', formData?.id_contrato);
        params.append('id_chamado', formData?.id_chamado);
        params.append('chamado', formData?.chamado);
        params.append('codigo_ul', formData?.codigo_ul);
        params.append('realizado_por', formData?.realizado_por);
        params.append('empresa_parceira', formData?.empresa_parceira);
        params.append('data_hora_inicio_atendimento', formData?.data_hora_inicio_atendimento);
        params.append('data_hora_fim_atendimento', formData?.data_hora_fim_atendimento);
        params.append('dados_basicos_razao_social', formData?.dados_basicos_razao_social);
        params.append('dados_basicos_endereco', formData?.dados_basicos_endereco);
        params.append('dados_basicos_numero', formData?.dados_basicos_numero);
        params.append('dados_basicos_bairro', formData?.dados_basicos_bairro);
        params.append('dados_basicos_cep', formData?.dados_basicos_cep);
        params.append('dados_basicos_cidade', formData?.dados_basicos_cidade);
        params.append('dados_basicos_estado', formData?.dados_basicos_estado);
        params.append('dados_basicos_contato', formData?.dados_basicos_contato);
        params.append('dados_basicos_telefone', formData?.dados_basicos_telefone);
        params.append('ver_tec_inst_lot_link_principal', formData?.ver_tec_inst_lot_link_principal?.value);
        params.append('ver_tec_inst_lot_op_principal', formData?.ver_tec_inst_lot_op_principal?.value);
        params.append('ver_tec_inst_lot_link_backup', formData?.ver_tec_inst_lot_link_backup?.value);
        params.append('ver_tec_inst_lot_op_backup', formData?.ver_tec_inst_lot_op_backup?.value);
        params.append('rede_int_patch_panel_inst_rack_sencinet', formData?.rede_int_patch_panel_inst_rack_sencinet?.value);
        params.append('rede_int_possui_keystone_inst_patch_panel', formData?.rede_int_possui_keystone_inst_patch_panel?.value);
        params.append('rede_int_cabo_rede_canalet_corr', formData?.rede_int_cabo_rede_canalet_corr?.value);
        params.append('rede_int_qtd_pontos_inst', formData?.rede_int_qtd_pontos_inst);
        params.append('rede_int_keystone_inst_terminais', formData?.rede_int_keystone_inst_terminais?.value);
        params.append('rede_int_obs_rede_internta_ponto_rede', formData?.rede_int_obs_rede_internta_ponto_rede);
        params.append('file_rede_int_foto_rack_aberto_antes_ins', formData?.file_rede_int_foto_rack_aberto_antes_ins);
        params.append('file_rede_int_foto_nobreak', formData?.file_rede_int_foto_nobreak);
        params.append('file_rede_int_foto_passagem_cabeamento', formData?.file_rede_int_foto_passagem_cabeamento);
        params.append('file_rede_int_foto_rack_aberto_apos_inst', formData?.file_rede_int_foto_rack_aberto_apos_inst);
        params.append('file_rede_int_foto_rack_fechado', formData?.file_rede_int_foto_rack_fechado);
        params.append('file_rede_int_foto_visao_geral', formData?.file_rede_int_foto_visao_geral);
        params.append('file_rede_int_foto_modem_elsys_inst', formData?.file_rede_int_foto_modem_elsys_inst);
        params.append('file_rede_int_foto_sim_card_retirado', formData?.file_rede_int_foto_sim_card_retirado);
        params.append('file_rede_int_foto_sim_card_inst', formData?.file_rede_int_foto_sim_card_inst);
        params.append('file_rede_int_foto_trajeto_cados', formData?.file_rede_int_foto_trajeto_cados);
        params.append('file_rede_int_foto_idu_desinstalado', formData?.file_rede_int_foto_idu_desinstalado);
        params.append('file_rede_int_foto_antena_satelite', formData?.file_rede_int_foto_antena_satelite);
        params.append('file_rede_int_print_nivel_sinal_elsys', formData?.file_rede_int_print_nivel_sinal_elsys);
        params.append('file_foto_switch_instalado', formData?.file_foto_switch_instalado);
        params.append('file_foto_switch_retirado', formData?.file_foto_switch_retirado);
        params.append('file_foto_evidencia_1', formData?.file_foto_evidencia_1);
        params.append('file_foto_evidencia_2', formData?.file_foto_evidencia_2);
        params.append('file_foto_evidencia_3', formData?.file_foto_evidencia_3);
        params.append('file_foto_evidencia_4', formData?.file_foto_evidencia_4);
        params.append('file_foto_evidencia_5', formData?.file_foto_evidencia_5);
        params.append('cond_racks_sencinet_rack_boas_cond', formData?.cond_racks_sencinet_rack_boas_cond?.value);
        params.append('cond_racks_sencinet_rack_org', formData?.cond_racks_sencinet_rack_org?.value);
        params.append('cond_racks_sencinet_corr_fixado', formData?.cond_racks_sencinet_corr_fixado?.value);
        params.append('cond_racks_claro_rack_boas_cond', formData?.cond_racks_claro_rack_boas_cond?.value);
        params.append('cond_racks_claro_rack_org', formData?.cond_racks_claro_rack_org?.value);
        params.append('cond_racks_claro_corr_fixado', formData?.cond_racks_claro_corr_fixado?.value);
        params.append('file_cond_racks_rack_sencinet', formData?.file_cond_racks_rack_sencinet);
        params.append('file_cond_racks_rack_claro', formData?.file_cond_racks_rack_claro);
        params.append('file_cond_racks_visao_geral_racks', formData?.file_cond_racks_visao_geral_racks);
        params.append('cond_racks_obs_cond_racks', formData?.cond_racks_obs_cond_racks);
        params.append('switch_sencinet_gerenciavel', formData?.switch_sencinet_gerenciavel?.value);
        params.append('switch_claro_gerenciavel', formData?.switch_claro_gerenciavel?.value);
        params.append('file_switch_rack_sencinet', formData?.file_switch_rack_sencinet);
        params.append('file_switch_rack_claro', formData?.file_switch_rack_claro);
        params.append('data_registro', formData?.data_registro);
        params.append('data_modificacao', formData?.data_modificacao);
        params.append('dados_eq_inst_modem', formData?.dados_eq_inst_modem);
        params.append('dados_eq_inst_modem_serial', formData?.dados_eq_inst_modem_serial);
        params.append('dados_eq_inst_chip', formData?.dados_eq_inst_chip);
        params.append('dados_eq_inst_imei', formData?.dados_eq_inst_imei);
        params.append('dados_eq_inst_router', formData?.dados_eq_inst_router);
        params.append('dados_eq_inst_chip', formData?.dados_eq_inst_chip);
        params.append('dados_eq_inst_router_serial', formData?.dados_eq_inst_router_serial);
        params.append('dados_eq_inst_switch', formData?.dados_eq_inst_switch);
        params.append('dados_eq_inst_switch_serial', formData?.dados_eq_inst_switch_serial);
        params.append('dados_eq_desinst_modem', formData?.dados_eq_desinst_modem);
        params.append('dados_eq_desinst_modem_serial', formData?.dados_eq_desinst_modem_serial);
        params.append('dados_eq_desinst_chip', formData?.dados_eq_desinst_chip);
        params.append('dados_eq_desinst_imei', formData?.dados_eq_desinst_imei);
        params.append('dados_eq_desinst_idu', formData?.dados_eq_desinst_idu);
        params.append('dados_eq_desinst_idu_serial', formData?.dados_eq_desinst_idu_serial);
        params.append('dados_eq_desinst_switch', formData?.dados_eq_desinst_switch);
        params.append('dados_eq_desinst_switch_serial', formData?.dados_eq_desinst_switch_serial);
        params.append('dados_eq_desinst_router', formData?.dados_eq_desinst_router);
        params.append('dados_eq_desinst_router_serial', formData?.dados_eq_desinst_router_serial);

        let dataReturn = await updateRatReport(formData?.id, params);

        if(dataReturn?.status === 200){
            handleSuccessToast('RAT cadastrada com sucesso! Aguarde a geração do arquivo', 'update', toastData);
            showPDF();
        }else{
            handleErrorsToast('Ocorreu um erro ao gerar a RAT', dataReturn?.data, 'update', toastData);
        }
    }

    async function showPDF(){
        let toastData = toast.loading('Gerando RAT, aguarde...');

        let dataReturn = await generateRatPdf(formData?.id);

        if(dataReturn?.status === 200){
            handleSuccessToast('RAT gerada com sucesso! Aguarde a abertura do arquivo em uma nova guia', 'update', toastData);
            const url = URL.createObjectURL(dataReturn?.data);

            window.open(url, '_blank');

            URL.revokeObjectURL(url);
        }else{
            handleErrorsToast('Ocorreu um erro ao gerar a RAT', dataReturn?.data, 'update', toastData);

        }
    }

    useEffect(() => {
        getReport();
    }, [ticket_id]);

    return (
        <form onSubmit={updateReport}>
            <div className="row">
                <div className="col-12">
                    <h5>Chamado</h5>
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="chamado">Chamado</label>
                    <input type="text" className='form-control' name='chamado' id='chamado' value={formData?.chamado || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="codigo_ul">Código UL</label>
                    <input type="text" className='form-control' name='codigo_ul' id='codigo_ul' value={formData?.codigo_ul || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} required />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="realizado_por">Realizado por</label>
                    <input type="text" className='form-control' name='realizado_por' id='realizado_por' value={formData?.realizado_por || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="empresa_parceira">Empresa Parceira</label>
                    <input type="text" className='form-control' name='empresa_parceira' id='empresa_parceira' value={formData?.empresa_parceira || ''} readOnly />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Datas/Horários</h5>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="data_hora_inicio_atendimento">Início do Atendimento</label>
                    <input type="text" className='form-control' name='data_hora_inicio_atendimento' id='data_hora_inicio_atendimento' value={formData?.data_hora_inicio_atendimento || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="data_hora_fim_atendimento">Fim do Atendimento</label>
                    <input type="text" className='form-control' name='data_hora_fim_atendimento' id='data_hora_fim_atendimento' value={formData?.data_hora_fim_atendimento || ''} readOnly />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Dados Básicos</h5>
                </div>
                <div className="col-sm-12">
                    <label htmlFor="dados_basicos_razao_social">Razão Social</label>
                    <input type="text" className='form-control' name='dados_basicos_razao_social' id='dados_basicos_razao_social' value={formData?.dados_basicos_razao_social || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-12">
                    <Cep
                        formData={formData}
                        setFormData={setFormData}
                        formDataNames={{
                            bairro: 'dados_basicos_bairro',
                            cep: 'dados_basicos_cep',
                            cidade: 'dados_basicos_cidade',
                            numero: 'dados_basicos_numero',
                            estado: 'dados_basicos_estado',
                            logradouro: 'dados_basicos_endereco'
                        }}
                        hasNumero
                    ></Cep>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="dados_basicos_contato">Contato</label>
                    <input type="text" className='form-control' name='dados_basicos_contato' id='dados_basicos_contato' value={formData?.dados_basicos_contato || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="dados_basicos_telefone">Fone/Fax</label>
                    <input type="text" className='form-control' name='dados_basicos_telefone' id='dados_basicos_telefone' value={formData?.dados_basicos_telefone || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Verificação das tecnologias instaladas na lotérica</h5>
                </div>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'ver_tec_inst_lot_link_principal'} cols={[12, 3, 3, 3]} title='Link Principal' genericOption='LinkPrincipalOptions'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'ver_tec_inst_lot_op_principal'} cols={[12, 3, 3, 3]} title='Operadora' genericOption='OperadoraOptions'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'ver_tec_inst_lot_link_backup'} cols={[12, 3, 3, 3]} title='Link Backup' genericOption='LinkBackupOptions'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'ver_tec_inst_lot_op_backup'} cols={[12, 3, 3, 3]} title='Operadora' genericOption='OperadoraOptions'></GenericSelect>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Equipamento Instalado</h5>
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_modem">Modem</label>
                    <input type="text" className='form-control' name='dados_eq_inst_modem' id='dados_eq_inst_modem' value={formData?.dados_eq_inst_modem || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_modem_serial">Modem Serial</label>
                    <input type="text" className='form-control' name='dados_eq_inst_modem_serial' id='dados_eq_inst_modem_serial' value={formData?.dados_eq_inst_modem_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_chip">Chip</label>
                    <input type="text" className='form-control' name='dados_eq_inst_chip' id='dados_eq_inst_chip' value={formData?.dados_eq_inst_chip || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_imei">IMEI</label>
                    <input type="text" className='form-control' name='dados_eq_inst_imei' id='dados_eq_inst_imei' value={formData?.dados_eq_inst_imei || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_router">Router</label>
                    <input type="text" className='form-control' name='dados_eq_inst_router' id='dados_eq_inst_router' value={formData?.dados_eq_inst_router || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_router_serial">Router Switch</label>
                    <input type="text" className='form-control' name='dados_eq_inst_router_serial' id='dados_eq_inst_router_serial' value={formData?.dados_eq_inst_router_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_switch">Switch</label>
                    <input type="text" className='form-control' name='dados_eq_inst_switch' id='dados_eq_inst_switch' value={formData?.dados_eq_inst_switch || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_inst_switch_serial">Switch Serial</label>
                    <input type="text" className='form-control' name='dados_eq_inst_switch_serial' id='dados_eq_inst_switch_serial' value={formData?.dados_eq_inst_switch_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Equipamento Desinstalado</h5>
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_modem">Modem</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_modem' id='dados_eq_desinst_modem' value={formData?.dados_eq_desinst_modem || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_modem_serial">Modem Serial</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_modem_serial' id='dados_eq_desinst_modem_serial' value={formData?.dados_eq_desinst_modem_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_chip">Chip</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_chip' id='dados_eq_desinst_chip' value={formData?.dados_eq_desinst_chip || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_imei">IMEI</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_imei' id='dados_eq_desinst_imei' value={formData?.dados_eq_desinst_imei || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_idu">IDU</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_idu' id='dados_eq_desinst_idu' value={formData?.dados_eq_desinst_idu || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_idu_serial">IDU Serial</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_idu_serial' id='dados_eq_desinst_idu_serial' value={formData?.dados_eq_desinst_idu_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_switch">Switch</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_switch' id='dados_eq_desinst_switch' value={formData?.dados_eq_desinst_switch || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="dados_eq_desinst_switch_serial">Switch Serial</label>
                    <input type="text" className='form-control' name='dados_eq_desinst_switch_serial' id='dados_eq_desinst_switch_serial' value={formData?.dados_eq_desinst_switch_serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Rede Interna - Ponto de Rede</h5>
                </div>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'rede_int_patch_panel_inst_rack_sencinet'} cols={[12, 3, 3, 3]} title='Patch Panel instalado no rack Sencinet?'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'rede_int_possui_keystone_inst_patch_panel'} cols={[12, 3, 3, 3]} title='Possui Keystone no patch panel?'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'rede_int_cabo_rede_canalet_corr'} cols={[12, 3, 3, 3]} title='Cabos de rede canaletados corretamente?'></GenericSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'rede_int_keystone_inst_terminais'} cols={[12, 3, 3, 3]} title='Keystone instalado nos terminais?'></GenericSelect>
                <div className="col-3">
                    <label htmlFor="rede_int_qtd_pontos_inst">Quantidade de Pontos Instalados</label>
                    <input type="number" name="rede_int_qtd_pontos_inst" id="rede_int_qtd_pontos_inst" className='form-control' value={formData?.rede_int_qtd_pontos_inst || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_fachada">Foto da Fachada</label>
                    <input type="text" className='form-control' id='file_rede_int_foto_fachada' name='file_rede_int_foto_fachada' value={formData?.file_rede_int_foto_fachada || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_rack_aberto_antes_ins">Foto Rack Aberto (Antes da Instalação)</label>
                    <input accept='image/*' type="file" ref={fotoRackRef} className='form-control' id='file_rede_int_foto_rack_aberto_antes_ins' name='file_rede_int_foto_rack_aberto_antes_ins' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_nobreak">Foto do Nobreak</label>
                    <input accept='image/*' type="file" ref={fotoNobreakRef} className='form-control' id='file_rede_int_foto_nobreak' name='file_rede_int_foto_nobreak' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_passagem_cabeamento">Foto da Passagem do Cabeamento na UL</label>
                    <input accept='image/*' type="file" ref={fotoCabeamentoRef} className='form-control' id='file_rede_int_foto_passagem_cabeamento' name='file_rede_int_foto_passagem_cabeamento' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_rack_aberto_apos_inst">Foto Rack Aberto (Após Instalação)</label>
                    <input accept='image/*' type="file" ref={fotoRackPosRef} className='form-control' id='file_rede_int_foto_rack_aberto_apos_inst' name='file_rede_int_foto_rack_aberto_apos_inst' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_rack_fechado">Foto Rack Fechado (Após Instalação)</label>
                    <input accept='image/*' type="file" ref={fotoRackFechadoRef} className='form-control' id='file_rede_int_foto_rack_fechado' name='file_rede_int_foto_rack_fechado' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_visao_geral">Foto da Visão Geral (Após Instalação)</label>
                    <input accept='image/*' type="file" ref={fotoVisaoGeralRef} className='form-control' id='file_rede_int_foto_visao_geral' name='file_rede_int_foto_visao_geral' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_modem_elsys_inst">Foto do Modem Elsys Instalado</label>
                    <input accept='image/*' type="file" ref={fotoModemRef} className='form-control' id='file_rede_int_foto_modem_elsys_inst' name='file_rede_int_foto_modem_elsys_inst' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_sim_card_retirado">Foto do Sim Card Retirado (BT) (Nº DE SÉRIE)</label>
                    <input accept='image/*' type="file" ref={fotoSimCardRef} className='form-control' id='file_rede_int_foto_sim_card_retirado' name='file_rede_int_foto_sim_card_retirado' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_sim_card_inst">Foto do Sim Card Instalado (Nº DE SÉRIE)</label>
                    <input accept='image/*' type="file" ref={fotoSimCardRetiradoRef} className='form-control' id='file_rede_int_foto_sim_card_inst' name='file_rede_int_foto_sim_card_inst' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_trajeto_cados">Foto do Trajeto dos Cabos</label>
                    <input accept='image/*' type="file" ref={fotoTrajetoRef} className='form-control' id='file_rede_int_foto_trajeto_cados' name='file_rede_int_foto_trajeto_cados' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_idu_desinstalado">Foto do IDU desinstalado (Nº DE SÉRIE)</label>
                    <input accept='image/*' type="file" ref={fotoIduDesinstaladoRef} className='form-control' id='file_rede_int_foto_idu_desinstalado' name='file_rede_int_foto_idu_desinstalado' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_foto_antena_satelite">Foto da antena satélite (Brasilsat ou Skyware)</label>
                    <input accept='image/*' type="file" ref={fotoAntenaSateliteRef} className='form-control' id='file_rede_int_foto_antena_satelite' name='file_rede_int_foto_antena_satelite' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_rede_int_print_nivel_sinal_elsys">Print nível do sinal Elsys</label>
                    <input accept='image/*' type="file" ref={printNivelSinalRef} className='form-control' id='file_rede_int_print_nivel_sinal_elsys' name='file_rede_int_print_nivel_sinal_elsys' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_switch_instalado">Switch Instalado (Serial)</label>
                    <input accept='image/*' type="file" ref={switchInstaladoRef} className='form-control' id='file_foto_switch_instalado' name='file_foto_switch_instalado' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_switch_retirado">Switch Retirado (Serial)</label>
                    <input accept='image/*' type="file" ref={switchRetiradoRef} className='form-control' id='file_foto_switch_retirado' name='file_foto_switch_retirado' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>

                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_evidencia_1">Evidencia Adicional 1</label>
                    <input accept='image/*' type="file" ref={adicional1Ref} className='form-control' id='file_foto_evidencia_1' name='file_foto_evidencia_1' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_evidencia_2">Evidencia Adicional 2</label>
                    <input accept='image/*' type="file" ref={adicional2Ref} className='form-control' id='file_foto_evidencia_2' name='file_foto_evidencia_2' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_evidencia_3">Evidencia Adicional 3</label>
                    <input accept='image/*' type="file" ref={adicional3Ref} className='form-control' id='file_foto_evidencia_3' name='file_foto_evidencia_3' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_evidencia_4">Evidencia Adicional 4</label>
                    <input accept='image/*' type="file" ref={adicional4Ref} className='form-control' id='file_foto_evidencia_4' name='file_foto_evidencia_4' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="file_foto_evidencia_5">Evidencia Adicional 5</label>
                    <input accept='image/*' type="file" ref={adicional5Ref} className='form-control' id='file_foto_evidencia_5' name='file_foto_evidencia_5' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-12">
                    <label htmlFor="rede_int_obs_rede_internta_ponto_rede">Observações</label>
                    <textarea name="rede_int_obs_rede_internta_ponto_rede" id="rede_int_obs_rede_internta_ponto_rede" className='form-control' rows={7} value={formData?.rede_int_obs_rede_internta_ponto_rede || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}></textarea>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Condições dos RACKS</h5>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <h6>SENCINET</h6>
                        </div>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_sencinet_rack_boas_cond'} title='Rack em boas condições?'></GenericSelect>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_sencinet_rack_org'} title='Rack está organizado?'></GenericSelect>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_sencinet_corr_fixado'} title='Corretamente fixado?'></GenericSelect>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <h6>OI</h6>
                        </div>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_claro_rack_boas_cond'} title='Rack em boas condições?'></GenericSelect>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_claro_rack_org'} title='Rack está organizado?'></GenericSelect>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'cond_racks_claro_corr_fixado'} title='Corretamente fixado?'></GenericSelect>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="file_cond_racks_rack_sencinet">Rack Sencinet</label>
                    <input accept='image/*' type="file" ref={rackSencinetRef} className='form-control' id='file_cond_racks_rack_sencinet' name='file_cond_racks_rack_sencinet' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="file_cond_racks_rack_claro">Rack OI</label>
                    <input accept='image/*' type="file" ref={rackOiRef} className='form-control' id='file_cond_racks_rack_claro' name='file_cond_racks_rack_claro' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="file_cond_racks_visao_geral_racks">Vista geral dos racks</label>
                    <input accept='image/*' type="file" ref={vistaGeralRacksRef} className='form-control' id='file_cond_racks_visao_geral_racks' name='file_cond_racks_visao_geral_racks' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                </div>
                <div className="col-12">
                    <label htmlFor="cond_racks_obs_cond_racks">Observações</label>
                    <textarea name="cond_racks_obs_cond_racks" id="cond_racks_obs_cond_racks" className='form-control' rows={7} value={formData?.cond_racks_obs_cond_racks || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}></textarea>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Switch</h5>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <h6>SENCINET</h6>
                        </div>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'switch_sencinet_gerenciavel'} title='Gerenciável?'></GenericSelect>
                        <div className="col-sm-12">
                            <label htmlFor="file_switch_rack_sencinet">Switch Sencinet</label>
                            <input accept='image/*' type="file" ref={switchSencinetRef} className='form-control' id='file_switch_rack_sencinet' name='file_switch_rack_sencinet' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <h6>OI</h6>
                        </div>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'switch_claro_gerenciavel'} title='Gerenciável?'></GenericSelect>
                        <div className="col-sm-12">
                            <label htmlFor="file_switch_rack_claro">Switch OI</label>
                            <input accept='image/*' type="file" ref={switchOiRef} className='form-control' id='file_switch_rack_claro' name='file_switch_rack_claro' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-12">
                    <button type='submit' className='btn btn-primary'>Cadastrar</button>
                </div>
            </div>
        </form>
    )
}