import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { approveSpecialValueById, getSpecialValueById } from '../../../../../services/SpecialPartnerValues';
import If from '../../../../../common/Layout/If';
import GenericSelect from '../../../../../common/Selects/GenericSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function SpecialPartnerDetails({ isOpen = false, setModalOpen = null, id_valor_especial = '', updateParent = null}) {
    const INITIAL_STATE = {
        id_contrato: '',
        integradora: '',
        id_representante: '',
        id_contrato_valor_comercial: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: '',
        aprovado: '',
        descricao_reprovado: '',
        grupo: '',
        descricao_valor_alto: '',
        num_chamado: ''
    }

    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);
    const [originalData, setOriginalData] = useState(INITIAL_STATE);

    async function getPartnerValuesData() {
        const toastValues = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getSpecialValueById(id_valor_especial);

        if (dataReturn?.data?.response === 'success') {
            let valoresTabelaData = dataReturn?.data?.data;

            setFormValoresTabela(prevState => ({
                ...prevState,
                ...valoresTabelaData,
                num_chamado: valoresTabelaData?.ticket?.num_chamado || '',
                aprovado: { value: dataReturn?.data?.data?.aprovado || '', label: dataReturn?.data?.data?.aprovado || '' }
            }));
            setOriginalData(valoresTabelaData?.partner_value);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastValues);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastValues);
            setModalOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        const toastValues = toast.loading('Atualizando status, aguarde...');

        let params = {
            ...formValoresTabela,
            aprovado: formValoresTabela?.aprovado?.value || '',
            descricao_reprovado: formValoresTabela?.aprovado?.value === 'aprovado' ? '' : formValoresTabela?.descricao_reprovado
        }

        let dataReturn = await approveSpecialValueById(id_valor_especial, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Status atualizado com sucesso!", 'update', toastValues);
            updateParent();
            setModalOpen(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o status!', dataReturn?.data, 'update', toastValues);
        }
    }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(id_valor_especial)) {
            getPartnerValuesData();
        }

        if (!isOpen) {
            setFormValoresTabela(INITIAL_STATE);
        }
    }, [isOpen, id_valor_especial]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header='Aprovar Valores Especiais'
            maximizable
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="">Nº Chamado</label>
                        <input type="text" name="num_chamado" id="num_chamado" className="form-control" value={formValoresTabela?.num_chamado || ''} readOnly={true} placeholder='...' />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_chamado">R$ Chamado (R${originalData?.valor_chamado || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial (R${originalData?.valor_fora_hr_comercial || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_improdutivo">Chamado Improdutivo (R${originalData?.valor_chamado_improdutivo || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_improdutivo" id="valor_improdutivo" className='form-control' value={formValoresTabela?.valor_improdutivo || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m (R${originalData?.valor_ha_diurna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m (R${originalData?.valor_ha_noturna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km (R${originalData?.valor_km_apos_51 || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} placeholder='...' readOnly={true} required={true} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="observacoes">Motivo dos Valores Especiais</label>
                        <textarea name="observacoes" id="observacoes" rows="10" className="form-control" value={formValoresTabela?.observacoes || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={true} required={true}></textarea>
                    </div>
                    <GenericSelect formData={formValoresTabela} setFormData={setFormValoresTabela} fieldName={'aprovado'} cols={[12, 12, 12, 12]} title='Status Aprovação' genericOption='AprovadoValorComercialOptions' required={true}></GenericSelect>
                    <If condition={formValoresTabela?.aprovado?.value === 'reprovado'}>
                        <div className="col-12">
                            <label htmlFor="descricao_reprovado">Motivo da Reprovação</label>
                            <textarea name="descricao_reprovado" id="descricao_reprovado" rows="10" className='form-control' value={formValoresTabela?.descricao_reprovado} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true}></textarea>
                        </div>
                    </If>
                    <div className="col-12 d-flex align-items-center mt-1">
                        <button type="submit" className='btn btn-primary'>Atualizar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}