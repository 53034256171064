import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { Context } from '../../App';

export default function ProtectedRoute({ permission_group = [], permission = '', children }) {
    const [userDataLoaded, setUserDataLoaded] = useState(false);
    const {userDataState} = useContext(Context);
    const { checkAllPermissions } = usePermissions();
    
    useEffect(() => {
        if (userDataState.nome !== '') {
            setUserDataLoaded(true);
        }
    }, [userDataLoaded, userDataState]);

    if (!userDataLoaded) {
        return;
    }

    if (checkAllPermissions(permission_group)) {
        return children;
    }else{
        return <Navigate to="/" replace />
    }
}