import React from 'react';
import { getDataByCep } from '../../services/ViaCep';
import If from '../Layout/If';
import { handleErrorsToast } from '../../configs/GenericFunctions';
import { Col, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function Cep({
    formDataNames = {
        'cep': 'cep',
        'estado': 'estado',
        'cidade': 'cidade',
        'bairro': 'bairro',
        'logradouro': 'logradouro',
        'complemento': 'complemento',
        'numero': 'numero'
    },
    formData,
    setFormData,
    readOnly = false,
    required = false,
    hasComplemento = false,
    hasNumero = false
}) {

    async function getCepData(cep, campos = {}) {
        let dataReturn = await getDataByCep(cep);
        if (dataReturn?.data?.cep) {
            let data = dataReturn.data

            for (let d in data) {
                if (campos[d] !== undefined) {
                    setFormData(prevState => ({ ...prevState, [campos[d]]: data[d] }))
                }
            }
        } else {
            handleErrorsToast('CEP não encontrado! Por favor, digite um CEP válido.');
            for (let c in campos) {
                setFormData(prevState => ({ ...prevState, [campos[c]]: '' }));
            }
        }
    }

    function handleCepField(e) {
        const { value } = e.target;
        const filteredValue = value.replace(/[^0-9]/g, '');
        e.target.value = filteredValue;
        setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

        //chave = campo da api
        //valor = campo do formulario
        let cepFields = { 'uf': formDataNames['estado'], 'localidade': formDataNames['cidade'], 'bairro': formDataNames['bairro'], 'logradouro': formDataNames['logradouro'] };

        if (value.length === 8) {
            getCepData(value, cepFields);
        }

        if (value.length < 8) {
            for (let c in cepFields) {
                setFormData(prevState => ({ ...prevState, [cepFields[c]]: '' }));
            }
        }
    }

    return (
        <Row>
            <Col sm='12' md='5'>
                <FormLabel htmlFor="cep">CEP (Apenas Números)</FormLabel>
                <FormControl type="text" name={formDataNames['cep']} id={formDataNames['cep']} minLength={0} maxLength={8} value={formData[formDataNames['cep']] || ''} onChange={handleCepField} placeholder='...' readOnly={readOnly} required={required} />
            </Col>
            <Col sm='12' md='2'>
                <FormLabel htmlFor="estado">Estado</FormLabel>
                <FormControl type="text" name={formDataNames['estado']} id={formDataNames['estado']} placeholder='...' value={formData[formDataNames['estado']] || ''} readOnly />
            </Col>
            <Col sm='12' md='5'>
                <FormLabel htmlFor="cidade">Cidade</FormLabel>
                <FormControl type="text" name={formDataNames['cidade']} id={formDataNames['cidade']} placeholder='...' value={formData[formDataNames['cidade']] || ''} readOnly />
            </Col>
            <Col sm='12' md='6'>
                <FormLabel htmlFor="bairro">Bairro</FormLabel>
                <FormControl type="text" name={formDataNames['bairro']} id={formDataNames['bairro']} placeholder='...' value={formData[formDataNames['bairro']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
            </Col>
            <Col sm='12' md='6'>
                <FormLabel htmlFor="logradouro">Logradouro</FormLabel>
                <FormControl type="text" name={formDataNames['logradouro']} id={formDataNames['logradouro']} placeholder='...' value={formData[formDataNames['logradouro']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
            </Col>
            <If condition={hasComplemento}>
                <Col sm='12' md='8'>
                    <FormLabel htmlFor="complemento">{hasNumero ? 'Complemento' : 'Número / Compl. / Referência / Obs'}</FormLabel>
                    <FormControl type="text" name={formDataNames["complemento"]} id={formDataNames["complemento"]} value={formData[formDataNames['complemento']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={hasNumero ? false : required} />
                </Col>
            </If>
            <If condition={hasNumero}>
                <Col sm='12' md='4'>
                    <FormLabel htmlFor="numero">Número</FormLabel>
                    <FormControl type="text" name={formDataNames["numero"]} id={formDataNames["numero"]} value={formData[formDataNames['numero']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
                </Col>
            </If>
        </Row >
    )
}