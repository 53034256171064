import React from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
export default function TechniciansChartContent({children}) {

    return (
        <Content headerTitle='Capilaridade de Técnicos'>
            <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                {children}
            </Card>
        </Content>
    );
}