import React, { useEffect, useState } from 'react';
import MainChatComponent from '../../../common/Chat/MainChatComponent';
import { useLocation, useParams } from 'react-router-dom';

export default function ExternalChatApp({setToken = null}){
    const { tokenData } = useParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const ticketId = urlParams.get("ticketId") || '';
    const [channelId, setChannelId] = useState(urlParams.get("channelId") || '');

    useEffect(()=>{
        setToken({access_token: tokenData});
    }, [])

    return (<MainChatComponent ticketId={ticketId || ''} channelIdValue={channelId || ''} setChannelId={setChannelId}></MainChatComponent>);
}