import React from 'react';
import If from '../../../../common/Layout/If';
import { getTechnicianById } from '../../../../services/Technician';
import { toast } from 'react-toastify';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import IntegradoraSelect from '../../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../../common/Selects/ContratosSelect';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import ProdutosRmaSelect from '../../../../common/Selects/ProdutosRmaSelect';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import usePermissions from '../../../../hooks/usePermissions';

export default function Form({ mode = 'view', submitForm = null, formData = {}, setFormData = null }) {
    const {checkPermission} = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_logistica', 'ShowProducts', 'Form');
    const fullReadOnly = ['view', 'update-internal-description', 'update-cancel-product'];
    const partialReadOnly = ['view', 'update-internal-description'];

    async function handleTechnician(id) {

        const toastTechnician = toast.loading('Procurando dados do técnico, aguarde...');

        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn.data.data;
            let tipoTecnico = "Não Definido";

            if (data.id_representante !== null) {
                tipoTecnico = data.id_representante === 1 ? 'CLT' : 'Parceiro'
            }
            handleSuccessToast('Dados encontrados com sucesso!', 'update', toastTechnician);
            setFormData(prevState => ({ ...prevState, email: data?.email_tec || '', tipo_tecnico: tipoTecnico }));
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar dados do técnico!', dataReturn?.data, 'update', toastTechnician);
            setFormData(prevState => ({ ...prevState, email: '', tipo_tecnico: '' }));
        }
    }

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '' }));
    }

    function handleRepresentante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_tecnico: '', email: '', tipo_tecnico: '' }))
    }

    function handleIntegradora(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_contrato: '' }));
    }

    function handleTecnico(value, name) {
        if (!['', undefined, null].includes(value?.value)) {
            setFormData(prev => ({ ...prev, [name]: value }));
            handleTechnician(value?.value);
        } else {
            setFormData(prev => ({ ...prev, [name]: value, email: '', tipo_tecnico: '' }))
        }
    }

    function handleLocal(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, box: '' }))
    }

    function handleProvenienteProduto(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_produto_rma: '' }));
    }

    function handleStatusProduto(value, name) {
        let isRMA = /\b\s?RMA-?\b/.test(value?.label);
        let params = !isRMA ? {
            id_produto_rma: '',
            numero_rma: '',
            proveniente_rma: 'nao'
        } : {};

        setFormData(prev => ({ ...prev, [name]: value, ...params }));
    }

    function isRequired() {
        if (mode === 'bulk-update') {
            return false;
        }

        return true;
    }

    return (<form onSubmit={submitForm}>
        <div className='row'>
            <div className="col-md-4 col-sm-12">
                <h5>Informações Básicas</h5>
                <div className="row">
                    <FabricanteSelect formData={formData} setFormData={setFormData} fieldName='id_fabricante' title='Fabricante' advancedSetFormData={handleFabricante} required={isRequired()} isDisabled={fullReadOnly.includes(mode)}></FabricanteSelect>
                    <ModelosSelect formData={formData} setFormData={setFormData} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value || ''} required={isRequired()} isDisabled={fullReadOnly.includes(mode)}></ModelosSelect>
                    <div className="col-12">
                        <label htmlFor="nomeProduto">Tipo de Produto</label>
                        <input type="text" name="nome_produto" id="nomeProduto" className="form-control" placeholder="Tipo do Produto" value={formData?.nome_produto || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={isRequired()} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <If condition={mode !== 'bulk-update'}>
                        <div className="col-12">
                            <label htmlFor="serialProduto">Serial</label>
                            <input type="text" name="serial" id="serialProduto" className="form-control" placeholder="Serial" value={formData?.serial || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={isRequired()} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="macProduto">MAC</label>
                            <input type="text" name="mac" id="macProduto" className="form-control" placeholder="MAC" value={formData?.mac || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="imeiProduto">IMEI</label>
                            <input type="text" name="imei" id="imeiProduto" className="form-control" placeholder="IMEI" value={formData?.imei || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="patrimonioProduto">Patrimônio</label>
                            <input type="text" name="patrimonio" id="patrimonioProduto" className="form-control" placeholder="Patrimônio" value={formData?.patrimonio || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                    </If>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <h5>Informações de Entrada</h5>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="codigoSapProduto">Código SAP</label>
                        <input type="text" name="codigo_sap" id="codigoSapProduto" className="form-control" placeholder="Código SAP" value={formData?.codigo_sap || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="nfeEntradaProduto">NFe de Entrada</label>
                        <input type="text" name="nfe_entrada" id="nfeEntradaProduto" className="form-control" placeholder="NFe de Entrada" value={formData?.nfe_entrada || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="dataNfeProduto">Data da NFe de Entrada</label>
                        <input type="date" name="data_nfe_entrada" id="dataNfeProduto" className="form-control" value={formData?.data_nfe_entrada || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="pesoEquipamentoProduto">Peso do Equipamento (KG)</label>
                        <input type="number" min="0" step="0.01" name="peso_equipamento" id="pesoEquipamentoProduto" className="form-control" placeholder="Peso do Equipamento" value={formData?.peso_equipamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="precoEquipamentoProduto">Preço do Equipamento</label>
                        <input type="number" min="0" step="0.01" name="preco_equipamento" id="precoEquipamentoProduto" className="form-control" placeholder="Preço do Equipamento" value={formData?.preco_equipamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="invoice">Invoice</label>
                        <input type="text" name="invoice" id="invoice" className="form-control" placeholder="Invoice" value={formData?.invoice || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="id_localidade">ID Localidade</label>
                        <input type="text" name="id_localidade" id="id_localidade" className="form-control" placeholder='ID Localidade' value={formData?.id_localidade || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <h5>Outras Informações</h5>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'kit_spare'} title={'Kit Spare'} isDisabled={fullReadOnly.includes(mode)}></GenericSelect>
                <IntegradoraSelect formData={formData} setFormData={setFormData} fieldName='id_integradora' advancedSetFormData={handleIntegradora} isDisabled={fullReadOnly.includes(mode)}></IntegradoraSelect>
                <ContratosSelect formData={formData} setFormData={setFormData} fieldName='id_contrato' dependsOnIntegradora={true} id_integradora_string={[formData?.id_integradora?.value].toString()} isDisabled={fullReadOnly.includes(mode)}></ContratosSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'valorado'} title={'Valorado/Não Valorado'} isDisabled={fullReadOnly.includes(mode)}></GenericSelect>
                <If condition={((mode === 'view' && checkPermission(pagePermissions[0]) || (['edit', 'update-internal-description', 'update-cancel-product', 'create', 'bulk-update'].includes(mode) && checkPermission(pagePermissions[1]))))}> 
                    <div className="col-12">
                        <label htmlFor="descricao_interna">Descrição Interna</label>
                        <textarea name="descricao_interna" id="descricao_interna" className='form-control' rows={10} value={formData?.descricao_interna || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} readOnly={mode === 'view'}></textarea>
                    </div>
                </If>
            </div>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <h5>Localização</h5>
                <GenericSelect formData={formData} setFormData={setFormData} genericOption={'UfOptions'} fieldName={'local'} title={'Local (UF)'} advancedSetFormData={handleLocal} isDisabled={partialReadOnly.includes(mode)}></GenericSelect>
                <BoxSelect formData={formData} setFormData={setFormData} fieldName={'box'} uf={formData?.local?.value || ''} isDisabled={partialReadOnly.includes(mode)}></BoxSelect>
                <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'id_corredor'} fieldName={'id_corredor'} module={'produtos'} title={'Corredor'} isDisabled={partialReadOnly.includes(mode)}></ModuleOptionsSelect>
                <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'id_estante'} fieldName={'id_estante'} module={'produtos'} title={'Estante'} isDisabled={partialReadOnly.includes(mode)}></ModuleOptionsSelect>
                <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'id_prateleira'} fieldName={'id_prateleira'} module={'produtos'} title={'Prateleiras'} isDisabled={partialReadOnly.includes(mode)}></ModuleOptionsSelect>
            </div>
            <div className="col-md-4 col-sm-12">
                <h5>Técnico e Contato</h5>
                <div className="row">
                    <RepresentanteSelect formData={formData} setFormData={setFormData} fieldName='id_representante' advancedSetFormData={handleRepresentante} isDisabled={partialReadOnly.includes(mode)}></RepresentanteSelect>
                    <TecnicosSelect formData={formData} setFormData={setFormData} fieldName='id_tecnico' dependsOnRepresentante={true} id_representante={formData?.id_representante?.value || ''} advancedSetFormData={handleTecnico} isDisabled={partialReadOnly.includes(mode)}></TecnicosSelect>
                    <div className="col-12">
                        <label htmlFor="tipoTecnicoProduto">Tipo de Técnico</label>
                        <input type="text" name="tipo_tecnico" id="tipoTecnicoProduto" className="form-control" placeholder="Tipo de Técnico" value={formData?.tipo_tecnico || ''} readOnly />
                    </div>
                    <div className="col-12">
                        <label htmlFor="emailProduto">Email</label>
                        <input type="text" name="email" id="emailProduto" className="form-control" placeholder="Email" value={formData?.email || ''} readOnly />
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <h5>Status do Produto</h5>
                <div className="row">
                    <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'} required={isRequired()} advancedSetFormData={handleStatusProduto} isDisabled={partialReadOnly.includes(mode)}></ModuleOptionsSelect>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className="row">
            <If condition={mode !== 'bulk-update'}>
                <div className="col-md-4 col-sm-12">
                    <div className="row">
                        <div className="col-12">
                            <h5>Dados Reversa</h5>
                        </div>
                        <div className="col-12">
                            <label htmlFor="numero_reversa">Número Reversa</label>
                            <input type="text" name="numero_reversa" id="numero_reversa" className='form-control' placeholder='Número Reversa' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.numero_reversa || ''} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="serial_defeituoso">Serial Defeituoso</label>
                            <input type="text" name="serial_defeituoso" id="serial_defeituoso" className='form-control' placeholder='Serial Defeituoso' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.serial_defeituoso || ''} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="nf_retorno">NF Retorno</label>
                            <input type="text" name="nf_retorno" id="nf_retorno" className='form-control' placeholder='NF Retorno' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.nf_retorno || ''} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="rastreamento_retorno">Rastreamento Retorno</label>
                            <input type="text" name="rastreamento_retorno" id="rastreamento_retorno" className='form-control' placeholder='Rastreamento Retorno' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.rastreamento_retorno || ''} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="data_reversa">Data Reversa</label>
                            <input type="date" name="data_reversa" id="data_reversa" className="form-control" value={formData?.data_reversa || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="data_postagem_retorno">Data Postagem Retorno</label>
                            <input type="date" name="data_postagem_retorno" id="data_postagem_retorno" className="form-control" value={formData?.data_postagem_retorno || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="data_retorno">Data Retorno</label>
                            <input type="date" name="data_retorno" id="data_retorno" className="form-control" value={formData?.data_retorno || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="data_expiracao_reversa">Data Expiração Reversa</label>
                            <input type="date" name="data_expiracao_reversa" id="data_expiracao_reversa" className="form-control" value={formData?.data_expiracao_reversa || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='id_usuario_gestor' cols={[12, 12, 12, 12]} userType='all' required={true} title='Gestora Responsável' isDisabled={fullReadOnly.includes(mode)}></UsuariosAtivosSelect>
                        <div className="col-12">
                            <label htmlFor="data_informada_gestao">Data Informada a Gestão</label>
                            <input type="date" name="data_informada_gestao" id="data_informada_gestao" className="form-control" value={formData?.data_informada_gestao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                    </div>
                </div>
            </If>
            <If condition={/\b\s?RMA-?\b/.test(formData?.id_status_produto?.label) && mode !== 'bulk-update'}>
                <div className="col-md-4 col-sm-12">
                    <div className="row">
                        <div className="col-12">
                            <h5>Dados RMA</h5>
                        </div>
                        <div className="col-12">
                            <label htmlFor="numero_rma">Número de RMA</label>
                            <input type="text" name="numero_rma" id="numero_rma" className='form-control' placeholder='Número de RMA' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.numero_rma || ''} required={isRequired()} readOnly={fullReadOnly.includes(mode)} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="">Proveniente de RMA?</label>
                            <div className="form-check">
                                <input type="radio" name="proveniente_rma" id="proveniente_rma_nao" className="form-check-input" value={'nao'} checked={formData?.proveniente_rma === 'nao'} onChange={(e) => handleProvenienteProduto(e.target.value, e.target.name)} disabled={fullReadOnly.includes(mode)} />
                                <label htmlFor="proveniente_rma_nao" className="form-check-label">Não</label>
                            </div>
                            <div className="form-check">
                                <input type="radio" name="proveniente_rma" id="proveniente_rma_sim" className="form-check-input" value={'sim'} checked={formData?.proveniente_rma === 'sim'} onChange={(e) => handleProvenienteProduto(e.target.value, e.target.name)} disabled={fullReadOnly.includes(mode)} />
                                <label htmlFor="proveniente_rma_sim" className="form-check-label">Sim</label>
                            </div>
                        </div>
                        <If condition={formData?.proveniente_rma === 'sim'}>
                            <ProdutosRmaSelect formData={formData} setFormData={setFormData} cols={[12, 12, 12, 12]} title='Produto' fieldName='id_produto_rma' required={isRequired} isDisabled={fullReadOnly.includes(mode)}></ProdutosRmaSelect>
                        </If>
                    </div>
                </div>
            </If>
        </div>
        <If condition={mode !== 'view'}>
            <div className="row">
                <div className="col-12 mt-1">
                    <button type="submit" className='btn btn-secondary float-right'>{mode === 'create' ? 'Cadastrar' : 'Salvar'}</button>
                </div>
            </div>
        </If>
    </form>)

}