import React from 'react';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { Link, useNavigate } from 'react-router-dom';

export default function BrazilTable({rows = []}) {
    const navigate = useNavigate();

    const columns = [
        {
            id: 'pais',
            name: 'País',
        },
        {
            id: 'estado',
            name: 'Estado',
            body: (row) => (<Link to={'/chamados/capilaridade-tecnicos/qtd/' + row?.estado}>{row.estado}</Link>)
        },
        {
            id: 'total',
            name: 'Quantidade Técnicos',
            body: (row) => (<Link to={`/chamados/capilaridade-tecnicos/tecnicos/${row?.estado}/all`}>{row.total}</Link>)
        },
    ]

    return (<PrimeTable
        columns={columns}
        rows={rows}
        id='brazil_table'
    ></PrimeTable>)
}