import React, { useState } from 'react';
import Card from '../../../common/Layout/Card';
import { addNoteByTicketId } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function HistoricoBacklogCard({
    getHistory = null,
    originalData = {},
    id = ''
}){
    const INITIAL_STATE = {
        nota_historico_fechamento: ''
    }

    const [backlogForm, setBacklogForm] = useState(INITIAL_STATE);

    async function addBacklogNote(e) {
        e.preventDefault();

        let formData = {
            descricao: backlogForm?.nota_historico_fechamento,
            tipo: 'backlog',
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading("Adicionando nota, aguarde...");

        let dataReturn = await addNoteByTicketId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Nota adicionada com sucesso!', 'update', toastNote);
            getHistory();
            setBacklogForm(prevState => ({ ...prevState, nota_historico_fechamento: '' }));
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar a nota!', dataReturn?.data, 'update', toastNote);
        }
    }

    return (<Card title={"Histórico Backlog"} icon={"fa-check"}>
        <form onSubmit={addBacklogNote}>
            <div className="row">
                <div className="col-12 mb-2">
                    <label htmlFor="nota_historico_fechamento">Nota de Histórico</label>
                    <textarea name="nota_historico_fechamento" id="nota_historico_fechamento" cols="30" rows="10" className="form-control" placeholder='...' value={backlogForm?.nota_historico_fechamento || ''} onChange={(e) => setBacklogForm(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                </div>
                <div className="col-12">
                    <button type='submit' className="btn btn-secondary">Adicionar Nota</button>
                </div>
            </div>
        </form>
    </Card>);
}