import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { getAllPermissions } from '../../../services/DevMode';
import { toast } from 'react-toastify';
import If from '../../../common/Layout/If';
import usePermissions from '../../../hooks/usePermissions';
import ModulosSelect from '../../../common/Selects/ModulosSelect';
import SubmodulosSelect from '../../../common/Selects/SubmodulosSelect';
import CreatePermissions from './CreatePermissions';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function Permissions() {

    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [openCreatePermission, setOpenCreatePermission] = useState(false);
    const { checkPermission } = usePermissions();
    const columns = [
        {
            id: 'id',
            name: 'ID'
        },
        {
            id: 'nome',
            name: 'Nome'
        },
        {
            id: 'descricao',
            name: 'Descrição'
        },
        {
            id: 'slug',
            name: 'Slug'
        },
        {
            id: 'status',
            name: 'Status',
            body: (row) => row?.status === 1 ? 'Ativo' : 'Inativo'
        }
    ]

    const getPermissions = async (value, name) => {
        setSearchData(prev => ({ ...prev, [name]: value }));
        const toastModules = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllPermissions(value?.value);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastModules);

            let modules = dataReturn.data.data;
            setData(modules);

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastModules);
            setData([]);
        }
    }

    const handleModules = (value, name) => {
        setSearchData(prev => ({ ...prev, [name]: value, id_submodulo: '' }));
    }

    return (
        <Content headerTitle='Gerenciar Permissões'>
            <CreatePermissions isOpen={openCreatePermission} setIsOpen={() => setOpenCreatePermission(!openCreatePermission)}></CreatePermissions>
            <SubHeaderSpecific subHeaderConfig='dev_mode'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <If condition={checkPermission('')}>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={() => setOpenCreatePermission(!openCreatePermission)}>Cadastrar Permissão</button>
                        </div>
                    </If>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <h3>Filtro</h3>
                    </div>
                    <ModulosSelect formData={searchData} setFormData={setSearchData} fieldName='id_modulo' cols={[12, 6, 6, 6]} advancedSetFormData={handleModules}></ModulosSelect>
                    <SubmodulosSelect formData={searchData} setFormData={setSearchData} fieldName='id_submodulo' cols={[12, 6, 6, 6]} id_modulo={searchData?.id_modulo?.value || ''} advancedSetFormData={getPermissions}></SubmodulosSelect>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='permissions_table'
                        ></PrimeTable>
                    </div>
                </div>
            </Card >
        </Content >
    );
}