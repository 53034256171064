import React, { useContext, useEffect, useState } from 'react';
import { getSubheaders } from '../../../configs/MenuItemsConfigs';

import { Link, useLocation } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';
import { countShipmentByStatus } from '../../../services/Shipment';
import { Context } from '../../../App';
import { countProductsByStatus, getCountRMA, getReversaCount } from '../../../services/Product';
import { getRepairStatusCount } from '../../../services/Repair';
import { Card, CardBody } from 'react-bootstrap';

export default function SubHeaderSpecific({ subHeaderConfig = 'retirada', updateLogistic = false, setUpdateLogistic = null }) {
    const userData = useContext(Context);
    const location = useLocation();
    const { checkPermission } = usePermissions();
    const [menuButtons, setMenuButtons] = useState([]);
    const milliseconds = 300000;
    let interval;

    async function renderSub(){
        let productsCount = {};

        if (subHeaderConfig === 'mam_logistica') {
            let dataReturn = await countShipmentByStatus();
            let dataReturnRMA = await getCountRMA();
            let dataReturnReversa = await getReversaCount();
            let dataReturnReversaGerada = await getCountProductsByStatus(['reversa_gerada']);

            productsCount = dataReturn?.data?.data || {};
            productsCount['pecas_rma'] = dataReturnRMA?.data?.data || 0;
            productsCount['pecas_reversa'] = dataReturnReversa?.data?.data || 0;
            productsCount['pecas_reversa_gerada'] = dataReturnReversaGerada || 0;
        }

        if(['mam_reparos', 'mam_logistica'].includes(subHeaderConfig)){
            let dataReturn = await getRepairStatusCount();
            productsCount = { ...productsCount, ...dataReturn?.data?.data || {}};
        }

        let subheader = getSubheaders()?.find(item => item.id === subHeaderConfig)
        let menuButtons = subheader?.menu_paths.map((item, idx) => {

            if(checkPermission(item.menu_permission)){
                return (
                    <Link 
                        to={item.path} 
                        className={`ml-1 mt-1 btn btn-sm btn-primary ${location.pathname.includes(item.path.split("?")[0]) ? 'active' : ''}`} 
                        key={idx}>
                            {item.name} 
                            {
                                ['mam_reparos', 'mam_logistica'].includes(subHeaderConfig) 
                                && productsCount?.[item?.count_name] ? <>&nbsp;<span className='badge badge-warning float-right'>{productsCount?.[item?.count_name]}</span></> : ''}
                    </Link>
                )
            }
            return null;
        });

        setMenuButtons(menuButtons);
    }

    async function getCountProductsByStatus(status = []){
        let dataReturn = await countProductsByStatus({status_produto: status});

        if(dataReturn?.status === 200){
            let data = dataReturn?.data?.data;
            let count = 0;

            for(let d in data){
                count += data[d];
            }

            return count;
        }else{
            return 0;
        }
    }

    useEffect(() => {
        renderSub();

        if (subHeaderConfig === 'mam_logistica') {
            interval = setInterval(() => {
                renderSub();
            }, milliseconds);

            return () => {
                clearInterval(interval);
            }
        }

    }, [userData?.userDataState?.permissoes, location?.pathname]);

    useEffect(() => {
        if(updateLogistic === true && ['mam_logistica', 'mam_reparos'].includes(subHeaderConfig)){
            renderSub();
            setUpdateLogistic(false);
        }
    }, [updateLogistic, subHeaderConfig]);

    return (
        <Card>
            <CardBody>
                {menuButtons}
            </CardBody>
        </Card>
    )
}