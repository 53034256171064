import interceptorInstance from "./Interceptor";

export async function getComponents(id_modelo = '', usado = '', id_produto = '',){
    let search = (id_modelo !== '' || usado !== '' || id_produto !== '') ? '?' : '';

    if(id_modelo !== ''){
        search += `id_modelo=${id_modelo}&`;
    }

    if(id_produto !== ''){
        search += `id_produto=${id_produto}&`;
    }

    if(usado !== ''){
        search += `usado=${usado}`;
    }

    search = search.endsWith('&') ? search.replace('/&+$/', '') : search;

    try{
        let data = await interceptorInstance.get(`repair/components${search}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getComponentById(id){
    try{
        let data = await interceptorInstance.get(`repair/components/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createComponent(params){
    try{
        let data = await interceptorInstance.post(`repair/components`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteComponentById(id){
    try{    
        let data = await interceptorInstance.delete(`repair/components/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateComponent(id, params){
    try{
        let data = await interceptorInstance.put(`repair/components/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createReport(params){
    try{
        let data = await interceptorInstance.post(`repair/report/create`, params);
        return data;
    }catch(e){
        return e.response;
    } 
}

export async function getReports(page = 1, perPage = 10, status = '', data_inicio = '', data_fim = '', houve_reparo = ''){
    let search = `?page=${page}&perPage=${perPage}`;

    if(status !== ''){
        search += `&status=${status}`;
    }

    if(data_fim !== ''){
        search += `&data_fim=${data_fim}`;
    }

    if(data_inicio !== ''){
        search += `&data_inicio=${data_inicio}`;
    }

    if(houve_reparo !== ''){
        search += `&houve_reparo=${houve_reparo}`;
    }

    try{
        let data = await interceptorInstance.get(`repair/report/list${search}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getReportById(id){
    try{
        let data = await interceptorInstance.get(`repair/report/${id}/show`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function startAnalysis(id){
    try{
        let data = await interceptorInstance.patch(`repair/report/${id}/start`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getRepairStatusCount(){
    try{
        let data = await interceptorInstance.get(`repair/report/count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function reopenAnalysis(id){
    try{
        let data = await interceptorInstance.patch(`repair/report/${id}/reopen`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateAnalysis(id, params){
    try{
        let data = await interceptorInstance.patch(`repair/report/${id}/finish`, params);
        return data;
    }catch(e){
        return e.response;
    }
}