import React from 'react';
import Card from '../../../common/Layout/Card';

export default function NovaDataAgendamento({detailsData = {}, setDetailsData = null}) {
    return (<Card title="Nova Data Agendamento">
        <div className="row">
            <div className="col-6">
                <div className="form-group">
                    <label htmlFor="">Data Agendamento</label>
                    <input type="date" name="data_agendamento" className='form-control' value={detailsData?.data_agendamento || ''} onChange={e => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                </div>
            </div>
            <div className="col-6">
                <div className="form-group">
                    <label htmlFor="">Hora Agendamento</label>
                    <input type="time" name="hora_agendamento" className='form-control' value={detailsData?.hora_agendamento || ''} onChange={e => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                </div>
            </div>
        </div>
    </Card>)
}