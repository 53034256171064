import interceptorInstance from "./Interceptor";

export async function getSteps(){
    try{
        let data = await interceptorInstance.get(`app/rat/etapas`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getRatReportByTicketId(ticket_id){
    try{
        let data = await interceptorInstance.get(`app/rat/relatorio/${ticket_id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createStepByTicketId(params){
    try{
        let data = await interceptorInstance.post(`app/rat/etapas/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTicketByTicketId(ticket_id){
    try{
        let data = await interceptorInstance.get(`app/ticket/${ticket_id}?rat_etapa=1`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createRatReport(params){
    try{
        let data = await interceptorInstance.post(`app/rat/relatorio/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateRatReport(idRat, params){
    try{
        let data = await interceptorInstance.post(`app/rat/relatorio/${idRat}/update`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function generateRatPdf(idRat){
    try{
        let data = await interceptorInstance.get(`app/rat/relatorio/${idRat}/old`, {responseType: 'blob'});
        return data;
    }catch(e){
        return e.response;
    }
}