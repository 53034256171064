import interceptorInstance from './Interceptor';

export async function countByQueue(){
    try{
        let data = await interceptorInstance.get('ticket/count-by-queue');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function countByStatus(){
    try{
        let data = await interceptorInstance.get('ticket/count-by-status');
        return data;
    }catch(e){  
        return e.response;
    }
}

export async function getTickets(params = {}){
    try{
        let data = await interceptorInstance.post('ticket/all', params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllTicketStatus(){
    try{
        let data = await interceptorInstance.get('ticket/get/status');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllActivities(){
    try{
        let data = await interceptorInstance.get('ticket/get/activities')
        return data;
    }catch(e){  
        return e.response;
    }
}

export async function getAllManufacturers(){
    try{
        let data = await interceptorInstance.get('ticket/get/manufacturers');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTechnicianDisponibility(params){
    try{
        let data = await interceptorInstance.post('ticket/check-technician-disponibility', params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createTicket(params){
    try{
        let data = await interceptorInstance.post('ticket/create', params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTicketById(id){
    try{
        let data = await interceptorInstance.get('ticket/search/'+id);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function changeOwnerByTicketId(id, params){
    try{
        let data = await interceptorInstance.patch('ticket/change-ticket-owner/' + id, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getHistoryByTicketId(id, param = ''){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/history/${param}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getHistoryImagesByTicketId(id){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/attachment/images`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllUnproductiveOptions(){
    try{
        let data = await interceptorInstance.get('ticket/get/unproductive-reason');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getStatusHistoryByTicketId(id){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/status-history`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAttachmentsByTicketId(id){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/attachment`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function uploadAttachmentByTicketId(id, param){
    try{
        let data = await interceptorInstance.post(`ticket/attachment/upload/${id}`, param, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteAttachmentByAttachmentId(id){
    try{
        let data = await interceptorInstance.delete(`ticket/attachment/delete/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function downloadAttachmentByAttachmentId(id){
    try{
        let data = await interceptorInstance.get(`ticket/attachment/download/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function generatePasswordByTicketId(id, params){
    try{
        let data = await interceptorInstance.post(`ticket/support-password/create/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPasswordsDataByTicketId(id){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/support-passwords`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function validateDocumentByTicketId(id, params){
    try{
        let data = await interceptorInstance.post(`ticket/document-validation/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function addNoteByTicketId(id, params){
    try{
        let data = await interceptorInstance.post(`ticket/history/note/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function generateChildTicketByTicketId(id){
    try{
        let data = await interceptorInstance.post(`ticket/duplicate/${id}`, {});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateDataByTicketId(id, params){
    try{
        let data = await interceptorInstance.patch(`ticket/update/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getLogisticsInvolvementValues(){
    try{
        let data = await interceptorInstance.get(`ticket/get/logistics-involvement-values`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getEquipmentsByTicketIdAndStatus(id, status){
    try{
        let data = await interceptorInstance.get(`ticket/search/${id}/equipments/${status}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getShippingModeData(){
    try{
        let data = await interceptorInstance.get(`ticket/get/shipping-mode`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function addEquipmentByTicketId(id, params){
    try{
        let data = await interceptorInstance.post(`ticket/equipment/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function removeEquipmentByIds(params){
    try{
        let data = await interceptorInstance.delete(`ticket/equipment/delete`, { params });
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateEquipmentStatus(params){
    try{
        let data = await interceptorInstance.patch(`ticket/equipment/ticket-status`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function submitEquipmentReturn(id, params){
    try{
        let data = await interceptorInstance.post(`ticket/equipment-return/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getUnassignedTickets(){
    try{
        let data = await interceptorInstance.get(`ticket/unassigned-tickets`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPartnerUnassignedTickets(){
    try{
        let data = await interceptorInstance.get(`ticket/partner-unassigned-tickets`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function assignTicket(params){
    try{    
        let data = await interceptorInstance.post(`ticket/assign`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function assignCreatedTicket(params){
    try{    
        let data = await interceptorInstance.post(`ticket/assign-created-by`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function assignPartnerTicket(params){
    try{
        let data = await interceptorInstance.post(`ticket/assign-partner`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function assignSupportTicket(params){
    try{
        let data = await interceptorInstance.post(`ticket/assign-support`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function importTicketDocument(params){
    try{
        let data = await interceptorInstance.post(`ticket/import`, params);
        return data;
    }catch(e){
        return e.response;
    }    
}