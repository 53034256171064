import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createModule } from '../../../services/DevMode';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreateModule({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
}) {
    const INITIAL_STATE = {
        nome_modulo: '',
        descricao: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        let toastData = toast.loading("Cadastrando Modulo, aguarde...");

        let dataReturn = await createModule(formData);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast('Módulo adicionado com sucesso!', 'update', toastData);
            updateParent();
            setFormData(INITIAL_STATE);
        }else{
            handleErrorsToast('Ocorreu um erro ao adicionar o módulo.', dataReturn?.data, 'update', toastData);
        }
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Módulo'
            maximizable
        >
            <form onSubmit={submitForm}>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 mb-1'>
                        <label htmlFor="nome_modulo">Nome do Módulo</label>
                        <input type='text' name='nome_modulo' className='form-control' placeholder='Nome do Módulo' value={formData?.nome_modulo || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-sm-12 col-md-6 mb-1'>
                        <label htmlFor="descricao">Descrição do Módulo</label>
                        <input type='text' name='descricao' className='form-control' placeholder='Descrição' value={formData?.descricao || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}