import Content from "../../../common/Layout/Content";
import Dashboard from "../../../common/Dashboard/Dashboard";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";

export default function Fust() {
    return (
        <Content headerTitle="Projeto Fust">
            <SubHeaderSpecific subHeaderConfig="dashboard"></SubHeaderSpecific>
            <Dashboard slug_dashboard="dashboard_fust"></Dashboard>
        </Content>
    );
}