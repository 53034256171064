import React from 'react';

import lotteries from '../resources/imgs/marcas/logo_oi.png';
import oi_purple from '../resources/imgs/marcas/oi-logo-purple.png';
import oi_orange from '../resources/imgs/marcas/oi-logo-orange.png';
import mcdonalds from '../resources/imgs/marcas/logo_mcdonalds.png';
import bradesco from '../resources/imgs/marcas/logo_bradesco.png';
import gts from '../resources/imgs/marcas/gts.png';
import sencinet from '../resources/imgs/marcas/sencinet.png';
import vivo from '../resources/imgs/marcas/vivo.png';
import banco_brasil from '../resources/imgs/marcas/banco_brasil.jpeg';
import tecban from '../resources/imgs/marcas/tecban.png';

import MainChat from '../pages/Chat/MainChat';
import ShowAbsenteeism from '../pages/Dashboard/Technician/Absenteeism/ShowAbsenteeism';
import ShowTicketsStatus from '../pages/Dashboard/Tickets/TodayTickets/ShowTicketsStatus';
import ShowTickets from '../pages/Tickets/ShowTickets/ShowTickets';
import AbsenteeismDetails from '../pages/Dashboard/Technician/Absenteeism/AbsenteeismDetails';
import Profile from '../pages/User/Profile';
import Main from '../pages/Main/Main';
import N2Tickets from '../pages/Tickets/N2Tickets/N2Tickets';
// import Reports from '../pages/Reports/Reports';
import CreateTicket from '../pages/Tickets/CreateTicket/CreateTicket';
import CreateTicketXls from '../pages/Tickets/CreateTicketXls';
import ShowTicketGroup from '../pages/Tickets/TicketsGroup/ShowTicketGroup';
import ShowPartnerGroup from '../pages/Tickets/PartnersGroup/ShowPartnerGroup';
import TechniciansMonitoring from '../pages/Tickets/TechniciansMonitoring/TechniciansMonitoring';
import ResponsavelAtendimento from '../pages/Tickets/ResponsavelAtendimento';
import ResponsavelParceria from '../pages/Tickets/ResponsavelParceria';
import SupportResponsible from '../pages/Tickets/SupportResponsible';
import TicketDetails from '../pages/Tickets/TicketDetails/TicketDetails';
import CreateTicketGroup from '../pages/Tickets/TicketsGroup/CreateTicketGroup';
import TicketGroupDetails from '../pages/Tickets/TicketsGroup/TicketGroupDetails';
import CreatePartnerGroup from '../pages/Tickets/PartnersGroup/CreatePartnerGroup';
import PartnerGroupDetails from '../pages/Tickets/PartnersGroup/PartnerGroupDetails';
import ShowProducts from '../pages/Logistics/Products/ShowProducts/ShowProducts';
import PendingTickets from '../pages/Logistics/Products/PendingTickets/PendingTickets';
import ProductsToSend from '../pages/Logistics/Products/ProductsToSend/ProductsToSend';
import Invoice from '../pages/Logistics/Products/Invoice/Invoice';
import InTransit from '../pages/Logistics/Products/InTransit/InTransit';
import PendingDact from '../pages/Logistics/Products/PendingDact/PendingDact';
import AwaitingCancel from '../pages/Logistics/Products/AwaitingCancel/AwaitingCancel';
import ProductsReceived from '../pages/Logistics/Products/ProductsReceived/ProductsReceived';
import ShowContracts from '../pages/Registrations/Contracts/ShowContracts';
import ContractDetails from '../pages/Registrations/Contracts/ContractDetails/ContractDetails';
import CreateContract from '../pages/Registrations/Contracts/CreateContract';
import ShowIntegrators from '../pages/Registrations/Integrators/ShowIntegrators';
import CreateTechnician from '../pages/Registrations/Technician/CreateTechnician';
import TechnicianDetails from '../pages/Registrations/Technician/TechnicianDetails/TechnicianDetails';
import ShowTechnicians from '../pages/Registrations/Technician/ShowTechnicians';
import CreatePartner from '../pages/Registrations/Partners/CreatePartner';
import PartnerDetails from '../pages/Registrations/Partners/PartnerDetails/PartnerDetails';
import ShowPartners from '../pages/Registrations/Partners/ShowPartners';
import CreateIntegrator from '../pages/Registrations/Integrators/CreateIntegrator';
import IntegratorDetails from '../pages/Registrations/Integrators/IntegratorDetails';
import AcionamentosManagement from '../pages/Solutions/Acionamentos/AcionamentosManagement';
import CreateAcionamento from '../pages/Solutions/Acionamentos/CreateAcionamento';
// import RetiradaManagement from '../pages/Solutions/Retirada/RetiradaManagement';
// import CreateRetirada from '../pages/Solutions/Retirada/CreateRetirada';
// import RetiradaDetails from '../pages/Solutions/Retirada/RetiradaDetails';
import ShowUsers from '../pages/Users/ShowUsers';
import CreateUser from '../pages/Users/CreateUser';
import UserDetails from '../pages/Users/UserDetails';
import UserGroup from '../pages/UserGroup/UserGroup';
import ShowRefund from '../pages/Billing/Refund/ShowRefunds';
import EmployeeRefundsDetails from '../pages/Billing/Refund/EmployeeRefundsDetails';
import Modules from '../pages/DevMode/Modules/Modules';
import Submodules from '../pages/DevMode/Submodules/Submodules';
import Permissions from '../pages/DevMode/Permissions/Permissions';
import ShowRMA from '../pages/Logistics/Products/ShowRMA/ShowRMA';
import SpareParts from '../pages/Repair/SpareParts/SpareParts';
import RepairReportsTable from '../pages/Repair/Reports/RepairReportsTable';
import CanceledShipments from '../pages/Logistics/Products/CanceledShipments/CanceledShipments';
import AwaitingWithdraw from '../pages/Logistics/Products/AwaitingWithdraw/AwaitingWithdraw';
// import ShowTicketsRat from '../pages/Rat/RatTickets/ShowRatTickets';
import ShowRatTickets from '../pages/Rat/RatTickets/ShowRatTickets';
// import ShowRatContracts from '../pages/Rat/RatContracts/ShowRatContracts';
// import RatRegistration from '../pages/Rat/RatRegistration/RatRegistration';
// import RatSteps from '../pages/Rat/RatSteps/RatSteps';
import RatDetails from '../pages/Rat/RatDetails/RatDetails';
import TechniciansByBrazil from '../pages/Tickets/TechniciansChart/Brazil/TechniciansByBrazil';
import TechniciansByStates from '../pages/Tickets/TechniciansChart/States/TechniciansByStates';
import TechniciansByCities from '../pages/Tickets/TechniciansChart/TechniciansByCities';
import LogisticReportOi from '../pages/Logistics/Reports/LogisticReportOi';
import LogisticReportCostCenter from '../pages/Logistics/Reports/LogisticReportCostCenter';
import LogisticReportLogistic from '../pages/Logistics/Reports/LogisticReportLogistic/LogisticReportLogistic';
import AwaitingTest from '../pages/Logistics/Products/AwaitingTest/AwaitingTest';
import MetabaseManager from '../pages/DevMode/MetabaseManager/MetabaseManager';
import TagsManager from '../pages/Admin/TagsManager/TagsManager';
import Fust from '../pages/Dashboard/Projects/Fust';
import FieldOptionsManager from '../pages/Admin/FieldOptionsManager/FieldOptionsManager';
import TicketImport from '../pages/Imports/TicketImport/TicketImport';
import TicketImportDetails from '../pages/Imports/TicketImport/TicketImportDetails/TicketImportDetails';
import GeneratedReturn from '../pages/Logistics/Products/GeneratedReturn/GeneratedReturn';

//Aqui vão os itens da sidenav e subheader

export const MenuConfigs = [
    {
        id: 'main',
        name: 'Main',
        icon: 'fas fa-comments',
        sidenav_group: false,
        children: [
            {
                id: 'Main',
                element: <Main></Main>,
                routes_path: '/',
                routes_permission: []
            }
        ]
    },
    {
        id: 'perfil',
        name: 'Perfil',
        icon: 'fas fa-profile',
        sidenav_group: false,
        children: [
            {
                id: 'Profile',
                element: <Profile></Profile>,
                routes_path: '/perfil/dados',
                routes_permission: []
            }
        ]
    },
    {
        id: 'mam_talks',
        name: 'MAMINFO Talks',
        icon: 'fas fa-comments',
        sidenav_group: true,
        children: [
            {
                id: 'MainChat',
                element: <MainChat></MainChat>,
                routes_path: '/chat',
                routes_permission: ['acessar_chat_global'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    FloatChatButton: {
                        page: ['acessar_chat_global', 'acessar_chat_chamado']
                    },
                    ChannelsChat: {
                        page: ['acessar_livechat'],
                        create: ['criacao_canal', 'criar_livechat']
                    },
                    ChatDetails: {
                        page: ['editar_detalhes_canal'],
                        add_button: ['adicionar_participante', 'adicionar_participante_externo', 'escalar_acionamento', 'escalar_suporte', 'escalar_parceria', 'escalar_responsavel_chamado', 'escalar_tecnico'],
                        contact: ['remover_participante', 'criacao_canal', 'sair_grupo'],
                        external_contact: ['remover_participante_externo', 'renovar_senha_participante_externo', 'renovar_data_participante_externo']
                    },
                    MessagesChat: {
                        page: ['enviar_mensagem']
                    }
                },
                menu_paths: [
                    {
                        name: 'Chat',
                        path: '/chat',
                        menu_permission: 'acessar_chat_global', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: false
                    }
                ]
            }
        ]
    },
    {
        id: 'dashboard',
        name: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        sidenav_group: true,
        children: [
            {
                id: 'ShowAbsenteeism',
                element: <ShowAbsenteeism></ShowAbsenteeism>,
                routes_path: '/dashboard/tecnicos/absenteismo/dados',
                routes_permission: ['acessar_absenteismo_tecnicos_dashboard'],
                menu_paths: [ //Se aparecer no menu
                    {
                        name: 'Técnicos',
                        path: '/dashboard/tecnicos/absenteismo/dados',
                        menu_permission: 'acessar_absenteismo_tecnicos_dashboard', //Permissao
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'AbsenteeismDetails',
                element: <AbsenteeismDetails></AbsenteeismDetails>,
                routes_path: '/dashboard/tecnicos/absenteismo/detalhes/:id',
                routes_permission: ['acessar_detalhes_absenteismo_tecnico_dashboard'],
            },
            {
                id: 'ShowTicketsStatus',
                element: <ShowTicketsStatus></ShowTicketsStatus>,
                routes_path: '/dashboard/chamados',
                routes_permission: ['acessar_chamados_dashboard'],
                menu_paths: [ //Se aparecer no menu
                    {
                        name: 'Chamados',
                        path: '/dashboard/chamados',
                        menu_permission: 'acessar_chamados_dashboard', //Permissao
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'Fust',
                element: <Fust></Fust>,
                routes_path: '/dashboard/fust',
                routes_permission: ['acessar_fust_dashboard'],
                menu_paths: [ //Se aparecer no menu
                    {
                        name: 'Projeto Fust',
                        path: '/dashboard/fust',
                        menu_permission: 'acessar_fust_dashboard', //Permissao
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
        ]
    },
    {
        id: 'mam_chamados',
        name: 'MAM Chamados',
        icon: 'fas fa-book',
        sidenav_group: true,
        children: [
            {
                id: 'ShowTickets',
                element: <ShowTickets></ShowTickets>,
                routes_path: '/chamados/dados/:id?',
                routes_permission: ['acessar_chamados', 'acessar_suporte_n1', 'acessar_parcerias', 'aguardando_tecnico_instalacao', 'aguardando_manutencao', 'aguardando_instalacao', 'pendente_agendamento', 'pendente_fechamento', 'aguardando_fechamento', 'aguardando_gestor', 'documento_pendente_validacao', 'gts', 'oi', 'sencinet', 'bradesco', 'mcdonalds', 'telefonica_vivo', 'banco_brasil', 'tecban', 'acessar_chamados_rejeitados', 'aguardando_cliente', 'aguardando_agendamento', 'oi_manutencao', 'oi_instalacao'],
                subcomponents_permissions: {
                    FloatSearchButton: {
                        select: ['buscar_historico']
                    }
                },
                menu_paths: [
                    {
                        name: 'Chamados',
                        path: '/chamados/dados/all',
                        menu_permission: 'acessar_chamados',
                        sidenav: true,
                        subheader: true
                    },
                    {
                        name: 'Chamados Rejeitados',
                        path: '/chamados/dados/rejeitados?status_chamado=Rejeitado', //quando tem caminho
                        menu_permission: 'acessar_chamados_rejeitados',
                        sidenav: true,
                        subheader: true
                    },
                    {
                        name: 'Suporte N1',
                        path: '/chamados/dados/suporte-n1?status_chamado=Aberto&status_chamado=Técnico%20chegou%20ao%20cliente&status_chamado=Agendado&status_chamado=Técnico%20Ciente&status_chamado=Técnico%20em%20deslocamento&status_chamado=Técnico%20aguardando%20liberação%20de%20acesso&status_chamado=Técnico%20em%20atendimento&status_chamado=Suporte%20interno%20(Fila)&status_chamado=Suporte%20interno%20(Atendimento)&status_chamado=Suporte%20externo%20/%20cliente&status_chamado=Suporte%20projetos', //quando tem caminho
                        menu_permission: 'acessar_suporte_n1',
                        sidenav: true,
                        subheader: true
                    },
                    {
                        name: 'Parcerias',
                        path: '/chamados/dados/parcerias?status_chamado=Aguardando%Técnico&data_inicial=2023-01-01', //quando tem caminho
                        menu_permission: 'acessar_parcerias',
                        sidenav: true,
                        subheader: true
                    },
                    {
                        name: 'Aguardando Técnico Instalação',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Técnico%20Instalação', //quando tem caminho
                        menu_permission: 'aguardando_tecnico_instalacao',
                        headerIcon: true,
                        type: "tech_waiting_installation",
                        icon: "fa-spinner",
                        color: "#0aff12",
                    },
                    {
                        name: 'Aguardando Manutenção',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Técnico%20Manutenção', //quando tem caminho
                        menu_permission: 'aguardando_manutencao',
                        headerIcon: true,
                        type: "tech_waiting_maintenance",
                        icon: "fa-wrench",
                        color: "#0aff12",
                    },
                    {
                        name: 'Aguardando Instalação',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Devolução%20Equipamento', //quando tem caminho
                        menu_permission: 'aguardando_instalacao',
                        headerIcon: true,
                        type: "end_of_service",
                        icon: "fa-briefcase",
                        color: "#0aff12",
                    },
                    {
                        name: 'Pendente Agendamento',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Sugerido%20(CLI)&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&key_ticket_cliente_null=false&remove_contrato=161139&remove_contrato=160814&remove_contrato=44474&remove_contrato=44506&remove_contrato=161190&remove_contrato=161195&remove_contrato=161196&remove_contrato=161033&remove_contrato=161034&remove_contrato=161035&remove_contrato=161036&remove_contrato=161037&remove_contrato=161049', //quando tem caminho
                        menu_permission: 'pendente_agendamento',
                        headerIcon: true,
                        type: "draft_for_action",
                        icon: "fa-exclamation-triangle",
                        color: "#fff700",
                    },
                    {
                        name: 'Aguardando Agendamento',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Agendamento', //quando tem caminho
                        menu_permission: 'aguardando_agendamento',
                        headerIcon: true,
                        type: "awaiting_schedule",
                        icon: "fa-calendar",
                        color: "#fff700",
                    },
                    {
                        name: 'Aguardando Cliente',
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Cliente', //quando tem caminho
                        menu_permission: 'aguardando_cliente',
                        headerIcon: true,
                        type: "awaiting_customer",
                        icon: "fa-users",
                        color: "#fff700",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Imediato', //quando tem caminho
                        menu_permission: 'pendente_fechamento',
                        headerIcon: true,
                        type: "pending_closure",
                        icon: "fa-exclamation-circle",
                        name: "Imediato",
                        color: "#ff0000",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Fechamento&status_chamado=Aguardando%20fechamento&gts=false', //quando tem caminho
                        menu_permission: 'aguardando_fechamento',
                        headerIcon: true,
                        type: "awaiting_closure",
                        icon: "fa-lock",
                        name: "Aguardando Fechamento",
                        color: "#ffa500",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aprovação%20Do%20Responsável', //quando tem caminho
                        menu_permission: 'aguardando_gestor',
                        headerIcon: true,
                        type: "manager_approval",
                        icon: "fa-file",
                        name: "Aguardando Gestor",
                        color: "#fff700",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2024-05-01&documentacao_entregue=Pendente%20de%20Validação&status_chamado=Fechado', //quando tem caminho
                        menu_permission: 'documento_pendente_validacao',
                        headerIcon: true,
                        type: "pending_doc_validation",
                        icon: "fa-scroll",
                        name: "Documento Pendente Validação",
                        color: "#0aff12",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&contrato=161193&contrato=161201', //quando tem caminho
                        menu_permission: 'oi_gateway_migracao_manutencao',
                        headerIcon: true,
                        type: "pending_doc_validation",
                        type: "migration_maintenance_oi",
                        icon: "fa-phone",
                        name: "Oi Gateway Migração e Manutenção",
                        color: "#0aff12",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aguardando%20Fechamento&status_chamado=Aguardando%20fechamento&gts=true', //quando tem caminho
                        menu_permission: 'gts',
                        headerImage: true,
                        type: "awaiting_closure_gts",
                        image: gts,
                        name: "GTS",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&key_ticket_cliente_null=true', //quando tem caminho
                        menu_permission: 'oi',
                        headerImage: true,
                        type: "lotteries",
                        image: lotteries,
                        name: "Oi",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=44506', //quando tem caminho
                        menu_permission: 'oi_manutencao',
                        headerImage: true,
                        type: "oi",
                        image: oi_purple,
                        name: "Oi - Manutenção",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=161190&contrato=161195&contrato=161196', //quando tem caminho
                        menu_permission: 'oi_instalacao',
                        headerImage: true,
                        type: "configuration_oi",
                        image: oi_orange,
                        name: "Oi - Instalação",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=161139&integradora=160', //quando tem caminho
                        menu_permission: 'sencinet',
                        headerImage: true,
                        type: "sencinet",
                        image: sencinet,
                        name: "Sencinet",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=44474', //quando tem caminho
                        menu_permission: 'bradesco',
                        headerImage: true,
                        type: "bradesco",
                        image: bradesco,
                        name: "Bradesco",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=160832&contrato=160814', //quando tem caminho
                        menu_permission: 'mcdonalds',
                        headerImage: true,
                        type: "mcdonalds",
                        image: mcdonalds,
                        name: "McDonalds",
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Aberto', //quando tem caminho
                        menu_permission: 'telefonica_vivo',
                        headerImage: true,
                        type: 'telefonica_vivo',
                        image: vivo,
                        name: 'Telefônica Vivo',
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=161165', //quando tem caminho
                        menu_permission: 'banco_brasil',
                        headerImage: true,
                        type: 'banco_brasil',
                        image: banco_brasil,
                        name: 'Banco do Brasil',
                    },
                    {
                        path: '/chamados/dados/all?data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=160843&contrato=160844', //quando tem caminho
                        menu_permission: 'tecban',
                        headerImage: true,
                        type: 'tecban',
                        image: tecban,
                        name: 'TecBan',
                    },
                ]
            },
            {
                id: 'N2Tickets',
                element: <N2Tickets></N2Tickets>,
                routes_path: '/chamados/suporte-n2',
                routes_permission: ['acessar_suporte_n2'],
                menu_paths: [
                    {
                        name: 'Suporte N2',
                        path: '/chamados/suporte-n2',
                        menu_permission: 'acessar_suporte_n2',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            // {
            //     id: 'Reports',
            //     element: <Reports></Reports>,
            //     routes_path: '/relatorios',
            //     routes_permission: ['acessar_relatorios'],
            //     menu_paths: [
            //         {
            //             name: 'Relatórios',
            //             path: '/relatorios',
            //             menu_permission: 'acessar_relatorios',
            //             sidenav: true,
            //             subheader: true
            //         }
            //     ]
            // },
            {
                id: 'CreateTicket',
                element: <CreateTicket></CreateTicket>,
                routes_path: '/chamados/abertura-chamados',
                routes_permission: ['acessar_abertura_chamados'],
                subcomponents_permissions: {
                    CreateTicket: {
                        form: ['info_adicional_circuito_criar_chamado', 'os_ba_criar_chamado']
                    }
                },
                menu_paths: [
                    {
                        name: 'Abertura de Chamados',
                        path: '/chamados/abertura-chamados',
                        menu_permission: 'acessar_abertura_chamados',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CreateTicketXls',
                element: <CreateTicketXls></CreateTicketXls>,
                routes_path: '/chamados/abertura-chamado-xls',
                routes_permission: ['acessar_abertura_chamados_xls'],
                menu_paths: [
                    {
                        name: 'Abrir Chamados XLS',
                        path: '/chamados/abertura-chamado-xls',
                        menu_permission: 'acessar_abertura_chamados_xls',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'ResponsavelAtendimento',
                element: <ResponsavelAtendimento></ResponsavelAtendimento>,
                routes_path: '/chamados/responsavel-atendimento',
                routes_permission: ['acessar_responsavel_atendimento'],
                menu_paths: [
                    {
                        name: 'Resp. Atendimento',
                        path: '/chamados/responsavel-atendimento',
                        menu_permission: 'acessar_responsavel_atendimento',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'ResponsavelParceria',
                element: <ResponsavelParceria></ResponsavelParceria>,
                routes_path: '/chamados/responsavel-parceria',
                routes_permission: ['acessar_responsavel_parcerias'],
                menu_paths: [
                    {
                        name: 'Resp. Parceria',
                        path: '/chamados/responsavel-parceria',
                        menu_permission: 'acessar_responsavel_parcerias',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'SupportResponsible',
                element: <SupportResponsible></SupportResponsible>,
                routes_path: '/chamados/responsavel-suporte',
                routes_permission: ['acessar_responsavel_suporte'],
                menu_paths: [
                    {
                        name: 'Resp. Suporte',
                        path: '/chamados/responsavel-suporte',
                        menu_permission: 'acessar_responsavel_suporte',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'TechniciansMonitoring',
                element: <TechniciansMonitoring></TechniciansMonitoring>,
                routes_path: '/chamados/monitoramento-tecnicos',
                routes_permission: ['acessar_monitoramento_tecnicos'],
                menu_paths: [
                    {
                        name: 'Monitoramento de Técnicos',
                        path: '/chamados/monitoramento-tecnicos',
                        menu_permission: 'acessar_monitoramento_tecnicos',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'TechniciansByBrazil',
                element: <TechniciansByBrazil></TechniciansByBrazil>,
                routes_path: '/chamados/capilaridade-tecnicos',
                routes_permission: ['acessar_capilaridade_tecnicos'],
                menu_paths: [
                    {
                        name: 'Capilaridade de Técnicos',
                        path: '/chamados/capilaridade-tecnicos',
                        menu_permission: 'acessar_capilaridade_tecnicos',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'TechniciansByStates',
                element: <TechniciansByStates></TechniciansByStates>,
                routes_path: '/chamados/capilaridade-tecnicos/qtd/:estado',
                routes_permission: ['acessar_capilaridade_tecnicos']
            },
            {
                id: 'TechniciansByCities',
                element: <TechniciansByCities></TechniciansByCities>,
                routes_path: '/chamados/capilaridade-tecnicos/tecnicos/:estado/:cidade?',
                routes_permission: ['acessar_capilaridade_tecnicos']
            },
            {
                id: 'TicketDetails',
                element: <TicketDetails></TicketDetails>,
                routes_path: '/chamados/detalhes-chamado/:id',
                routes_permission: ['acessar_detalhes_chamado'],
                subcomponents_permissions: {
                    EquipamentoRemessaCard: {
                        page: ['adicionar_equipamento_remessa_spare'],
                        action: ['cancelar_remessa_detalhes_chamado', 'alterar_status_remessa_detalhes_chamado', 'atualizar_remessa_detalhes_chamado']
                    },
                    EquipamentoRetornoCard: {
                        page: ['adicionar_equipamento_retorno']
                    },
                    RegistroTrabalhoCard: {
                        page: ['adicionar_nota_externa_cliente', 'adicionar_nota_parcerias', 'adicionar_nota_interna', 'visualizar_historico_maminfo', 'visualizar_historico_notas_externas', 'visualizar_historico_parcerias', 'visualizar_historico_backlog', 'visualizar_historico_tecnico', 'visualizar_historico_imagens'],
                        escalation: ['escalar_acionamento', 'escalar_suporte', 'escalar_parceria', 'escalar_responsavel_chamado', 'escalar_tecnico'],
                    },
                    SenhaAtendimentoCard: {
                        page: ['cadastrar_senha_atendimento']
                    },
                    AtendimentoCard: {
                        page: ['alterar_tecnico', 'alterar_km_tecnico', 'alterar_agendamento', 'alterar_data_desejada']
                    },
                    AttachmentData: {
                        page: ['gerar_rat']
                    },
                    ChamadosCardData: {
                        page: ['alterar_numero_chamado', 'alterar_fabricante', 'alterar_circuito', 'alterar_contrato', 'alterar_numero_rma', 'alterar_os_ba']
                    },
                    ClienteCardData: {
                        page: ['alterar_cliente']
                    },
                    StatusCard: {
                        page: ['alterar_status_chamado', 'alterar_urgencia', 'alterar_prioridade', 'alterar_tags', 'adicionar_tag', 'remover_tag']
                    },
                    TicketDetails: {
                        page: ['cadastrar_senha_atendimento', 'alterar_detalhes_chamado', 'enviar_email', 'gerar_chamado_filho', 'visualizar_responsavel_suporte_analista', 'visualizar_mapa', 'validar_documentacao', 'adicionar_historico_backlog', 'visualizar_senha_atendimento', 'anexar_arquivo', 'visualizar_equipamento_retorno', 'visualizar_reembolsos_pendentes'],
                        details_chamado_button: ['alterar_chamado_aprovacao_responsavel', 'alterar_chamados_cancelados', 'alterar_chamados_fechados']
                    }
                }
            },
        ]
    },
    {
        id: 'grupos_atendimento',
        name: 'MAM Grupos de Atendimento',
        icon: 'fas fa-book',
        sidenav_group: true,
        children: [
            {
                id: 'ShowTicketGroup',
                element: <ShowTicketGroup></ShowTicketGroup>,
                routes_path: '/chamados/grupos-atendimento/dados',
                routes_permission: ['acessar_grupos_atendimento'],
                menu_paths: [
                    {
                        name: 'Grupos de Atendimento',
                        path: '/chamados/grupos-atendimento/dados',
                        menu_permission: 'acessar_grupos_atendimento',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CreateTicketGroup',
                element: <CreateTicketGroup></CreateTicketGroup>,
                routes_path: '/chamados/grupos-atendimento/cadastrar',
                routes_permission: ['acessar_cadastro_grupos_atendimento'],
                menu_paths: [
                    {
                        name: 'Criar Grupo de Atendimento',
                        path: '/chamados/grupos-atendimento/cadastrar',
                        menu_permission: 'acessar_cadastro_grupos_atendimento',
                        sidenav: false,
                        subheader: true
                    }
                ]
            },
            {
                id: 'TicketGroupDetails',
                element: <TicketGroupDetails></TicketGroupDetails>,
                routes_path: '/chamados/grupos-atendimento/detalhes/:id',
                routes_permission: ['acessar_detalhes_grupo_atendimento'],
            }
        ]
    },
    {
        id: 'grupos_parcerias',
        name: 'MAM Grupos de Parcerias',
        icon: 'fas fa-book',
        sidenav_group: true,
        children: [
            {
                id: 'ShowPartnerGroup',
                element: <ShowPartnerGroup></ShowPartnerGroup>,
                routes_path: '/chamados/grupos-parceria/dados',
                routes_permission: ['acessar_grupos_parcerias'],
                menu_paths: [
                    {
                        name: 'Grupos de Parcerias',
                        path: '/chamados/grupos-parceria/dados',
                        menu_permission: 'acessar_grupos_parcerias',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CreatePartnerGroup',
                element: <CreatePartnerGroup></CreatePartnerGroup>,
                routes_path: '/chamados/grupos-parceria/cadastrar',
                routes_permission: ['acessar_cadastro_grupos_parcerias'],
                menu_paths: [
                    {
                        name: 'Criar Grupo de Parcerias',
                        path: '/chamados/grupos-parceria/cadastrar',
                        menu_permission: 'acessar_cadastro_grupos_parcerias',
                        sidenav: false,
                        subheader: true
                    }
                ]
            },
            {
                id: 'PartnerGroupDetails',
                element: <PartnerGroupDetails></PartnerGroupDetails>,
                routes_path: '/chamados/grupos-parceria/detalhes/:id',
                routes_permission: ['acessar_detalhes_grupos_parcerias'],
            }
        ]
    },
    {
        id: 'mam_rat',
        name: 'RAT',
        icon: 'fas fa-book',
        sidenav_group: true,
        children: [
            {
                id: 'ShowTicketsRat',
                element: <ShowRatTickets></ShowRatTickets>,
                routes_path: '/rat/chamados',
                routes_permission: ['acessar_rat'],
                menu_paths: [
                    {
                        name: 'Chamados RAT',
                        path: '/rat/chamados',
                        menu_permission: 'acessar_rat',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            // {
            //     id: 'ShowRatContracts',
            //     element: <ShowRatContracts></ShowRatContracts>,
            //     routes_path: '/rat/contratos',
            //     routes_permission: [],
            //     menu_paths: [
            //         {
            //             name: 'Contratos RAT',
            //             path: '/rat/contratos',
            //             menu_permission: '',
            //             sidenav: true,
            //             subheader: true
            //         }
            //     ]
            // },
            {
                id: 'RatDetails',
                element: <RatDetails></RatDetails>,
                routes_path: '/rat/detalhes/:id',
                routes_permission: ['acessar_rat']
            }
        ]
    },
    // {
    //     id: 'importacoes',
    //     name: 'MAM Importações',
    //     icon: 'fas fa-file-upload',
    //     sidenav_group: true,
    //     children: [
    //         {
    //             id: 'TicketImport',
    //             element: <TicketImport></TicketImport>,
    //             routes_path: '/importacoes/chamados/dados',
    //             routes_permission: [],
    //             menu_paths: [
    //                 {
    //                     name: 'Importar Chamados',
    //                     path: '/importacoes/chamados/dados',
    //                     menu_permission: '',
    //                     sidenav: true,
    //                     subheader: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'TicketImportDetails',
    //             element: <TicketImportDetails></TicketImportDetails>,
    //             routes_path: '/importacoes/chamados/detalhes/:id',
    //             routes_permission: []
    //         }
    //     ]
    // },
    {
        id: 'mam_logistica',
        name: 'MAM Logística',
        icon: 'fas fa-box',
        sidenav_group: true,
        children: [
            {
                id: 'ShowProducts',
                element: <ShowProducts></ShowProducts>,
                routes_path: '/logistica/produtos/dados',
                routes_permission: ['acessar_produtos'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ProductDetails: {
                        tab: ['visualizar_ged', 'visualizar_linha_tempo', 'ver_detalhes_remessa_produto', 'alterar_detalhes_remessa_produto'],
                        page: ['editar_ged_produto']
                    },
                    ShowProducts: {
                        action: ['visualizar_dados_produto', 'duplicar_produto', 'editar_produto', 'criar_remessa', 'deletar_produto', 'enviar_reparo'],
                        cadastro: ['adicionar_fabricante', 'adicionar_nome_produto', 'adicionar_produto', 'adicionar_estoque', 'adicionar_produto_lote'],
                        acoes_em_massa: ['editar_em_massa', 'criar_remessa_em_massa'],
                        acoes: ['criar_remessa_serial']
                    },
                    Form: {
                        page: ['visualizar_descricao_interna', 'editar_descricao_interna']
                    }
                },
                menu_paths: [
                    {
                        name: 'Produtos',
                        path: '/logistica/produtos/dados',
                        menu_permission: 'acessar_produtos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                    }
                ]
            },
            {
                id: 'InRepairReportsTable',
                element: <RepairReportsTable></RepairReportsTable>,
                routes_path: '/logistica/laudos/:status?',
                routes_permission: ['acessar_aberto'],
                menu_paths: [
                    {
                        name: 'Em Reparo',
                        path: '/logistica/laudos/aberto',
                        menu_permission: 'acessar_aberto',
                        sidenav: true,
                        subheader: true,
                        count_name: 'aberto'
                    }
                ]
            },
            // {
            //     id: 'AwaitingTest',
            //     element: <AwaitingTest></AwaitingTest>,
            //     routes_path: '/logistica/produtos/aguardando-teste',
            //     routes_permission: ['acessar_aguardando_teste'],
            //     menu_paths: [
            //         {
            //             name: 'Aguardando Teste',
            //             path: '/logistica/produtos/aguardando-teste',
            //             menu_permission: 'acessar_aguardando_teste',
            //             sidenav: true,
            //             subheader: true,
            //             count_name: 'aguardando_teste'
            //         }
            //     ]
            // },
            {
                id: 'PendingTickets',
                element: <PendingTickets></PendingTickets>,
                routes_path: '/logistica/produtos/chamados-pendentes',
                routes_permission: ['acessar_chamados_pendentes'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    PendingTickets: {
                        action: ['visualizar_dados_chamados_pendentes', 'editar_chamado_pendente', 'cancelar_remessa_chamado_pendente'],
                        page: ['editar_massa_chamados_pendentes']
                    }
                },
                menu_paths: [
                    {
                        name: 'Chamados Pendentes',
                        path: '/logistica/produtos/chamados-pendentes',
                        menu_permission: 'acessar_chamados_pendentes', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: 'produto_pendente'
                    }
                ]
            },
            {
                id: 'ProductsToSend',
                element: <ProductsToSend></ProductsToSend>,
                routes_path: '/logistica/produtos/pecas-enviar',
                routes_permission: ['acessar_pecas_enviar'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ProductsToSend: {
                        action: ['visualizar_detalhes_pecas_enviar', 'editar_pecas_enviar', 'cancelar_solicitacao_pecas_enviar'],
                        acoes_em_massa: ['editar_pecas_enviar_em_massa']
                    }
                },
                menu_paths: [
                    {
                        name: 'Peças a Enviar',
                        path: '/logistica/produtos/pecas-enviar',
                        menu_permission: 'acessar_pecas_enviar', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "aguardando_envio"
                    }
                ]
            },
            {
                id: 'Invoice',
                element: <Invoice></Invoice>,
                routes_path: '/logistica/produtos/emissao-nfe',
                routes_permission: ['acessar_emissao_nfe'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    Invoice: {
                        action: ['visualizar_nfe', 'editar_emissao_nfe', 'retornar_para_peca_enviar', 'cancelar_emissao_nfe'],
                        acoes_em_massa: ['editar_emissao_nfe_em_massa']
                    },
                    InvoiceDetails: {
                        tab: ['visualizar_ged_nfe'],
                        page: ['editar_ged_nfe']
                    }
                },
                menu_paths: [
                    {
                        name: 'Emissão de NFE',
                        path: '/logistica/produtos/emissao-nfe',
                        menu_permission: 'acessar_emissao_nfe', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "aguardando_nfe_saida"
                    }
                ]
            },
            {
                id: 'InTransit',
                element: <InTransit></InTransit>,
                routes_path: '/logistica/produtos/em-transito',
                routes_permission: ['acessar_em_transito'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    InTransit: {
                        action: ['visualizar_em_transito', 'marcar_como_recebido_em_transito', 'retornar_aguardando_nfe', 'cancelar_em_transito', 'alterar_detalhes_remessa_em_transito'],
                        acoes_em_massa: ['marcar_como_recebido_em_massa']
                    },
                    InTransitDetails: {
                        tab: ['visualizar_ged_em_transito'],
                        page: ['editar_ged_em_transito']
                    }
                },
                menu_paths: [
                    {
                        name: 'Em Trânsito',
                        path: '/logistica/produtos/em-transito',
                        menu_permission: 'acessar_em_transito', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "em_transito",

                        headerIcon: true,
                        type: "in_transit",
                        icon: "fa-truck",
                        color: "#fff700",
                    },
                ]
            },
            {
                id: 'PendingDact',
                element: <PendingDact></PendingDact>,
                routes_path: '/logistica/produtos/pendente-dact',
                routes_permission: ['acessar_pendente_dact'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    PendingDact: {
                        action: ['visualizar_valores_dact', 'editar_valores_dact', 'cancelar_remessa_dact'], 
                        acoes_em_massa: ['editar_pendente_dact_em_massa']
                    },
                    PendingDactDetails: {
                        tab: ['visualizar_ged_dact'],
                        page: ['editar_ged_dact']
                    }
                },
                menu_paths: [
                    {
                        name: 'Pendente DACT',
                        path: '/logistica/produtos/pendente-dact',
                        menu_permission: 'acessar_pendente_dact', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "pendente_dact"
                    }
                ]
            },
            {
                id: 'AwaitingWithdraw',
                element: <AwaitingWithdraw></AwaitingWithdraw>,
                routes_path: '/logistica/produtos/aguardando-retirada',
                routes_permission: ['acessar_aguardando_retirada'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    AwaitingWithdraw: {
                        action: ['visualizar_aguardando_retirada', 'assinar_aguardando_retirada', 'cancelar_remessa_aguardando_retirada']
                    },
                    AwaitingWithdrawDetails: {
                        tab: ['visualizar_ged_aguardando_retirada']
                    }
                },
                menu_paths: [
                    {
                        name: 'Aguardando Retirada',
                        path: '/logistica/produtos/aguardando-retirada',
                        menu_permission: 'acessar_aguardando_retirada', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "aguardando_retirada_em_maos"
                    }
                ]
            },
            {
                id: 'AwaitingCancel',
                element: <AwaitingCancel></AwaitingCancel>,
                routes_path: '/logistica/produtos/aguardando-cancelamento',
                routes_permission: ['acessar_cancelar_remessas'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    AwaitingCancel: {
                        action: ['ver_detalhes_remessa_aguardando_cancelamento', 'ver_detalhes_produto_aguardando_cancelamento', 'at_descricao_interna_aguardando_cancelamento', 'cancelar_remessa_aguardando_cancelamento']
                    },
                },
                menu_paths: [
                    {
                        name: 'Aguardando Cancelamento',
                        path: '/logistica/produtos/aguardando-cancelamento',
                        menu_permission: 'acessar_cancelar_remessas', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: "aguardando_cancelamento"
                    }
                ]
            },
            {
                id: 'ProductsReceived',
                element: <ProductsReceived></ProductsReceived>,
                routes_path: '/logistica/produtos/produtos-recebidos',
                routes_permission: ['acessar_produtos_recebidos'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ProductReceivedDetails: {
                        tab: ['visualizar_ged']
                    },
                    ProductsReceived: {
                        action: ['visualizar_detalhes_produto_recebido']
                    }
                },
                menu_paths: [
                    {
                        name: 'Produtos Recebidos',
                        path: '/logistica/produtos/produtos-recebidos',
                        menu_permission: 'acessar_produtos_recebidos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CanceledShipments',
                element: <CanceledShipments></CanceledShipments>,
                routes_path: '/logistica/produtos/remessas-canceladas',
                routes_permission: ['acessar_remessas_canceladas'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    CanceledShipments: {
                        action: ['ver_detalhes_remessa_cancelada', 'visualizar_dados_produto_cancelado', 'editar_produto_cancelado']
                    }
                },
                menu_paths: [
                    {
                        name: 'Remessas Canceladas',
                        path: '/logistica/produtos/remessas-canceladas',
                        menu_permission: 'acessar_remessas_canceladas', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: 'canceladas'
                    }
                ]
            },
            {
                id: 'ShowRMA',
                element: <ShowRMA></ShowRMA>,
                routes_path: '/logistica/produtos/rma',
                routes_permission: ['acessar_rma'],
                subcomponents_permissions: {
                    ShowRMA: {
                        action: ['ver_detalhes_rma', 'editar_dados_rma']
                    },
                    ProductRMADetails: {
                        tab: ['ver_ged_rma'],
                        page: ['editar_ged_rma']
                    }
                },
                menu_paths: [
                    {
                        name: 'Peças em RMA',
                        path: '/logistica/produtos/rma',
                        menu_permission: 'acessar_rma',
                        sidenav: true,
                        subheader: true,
                        count_name: 'pecas_rma'
                    }
                ]
            },
            {
                id: 'ShowReturn',
                element: <ShowProducts queue='return'></ShowProducts>,
                routes_path: '/logistica/reversa/dados',
                routes_permission: ['acessar_produtos_reversa'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ProductDetails: {
                        tab: ['visualizar_ged_reversa', 'visualizar_linha_tempo_reversa', 'ver_detalhes_remessa_produto_reversa', 'alterar_detalhes_remessa_produto_reversa'],
                        page: ['editar_ged_produto_reversa']
                    },
                    ShowReturn: {
                        action: ['visualizar_dados_produto_reversa', 'editar_produto_reversa'],
                    }
                },
                menu_paths: [
                    {
                        name: 'Reversa',
                        path: '/logistica/reversa/dados',
                        menu_permission: 'acessar_produtos_reversa', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: 'pecas_reversa'
                    }
                ]
            },
            {
                id: 'GeneratedReturn',
                element: <GeneratedReturn></GeneratedReturn>,
                routes_path: '/logistica/reversa-gerada/dados',
                routes_permission: ['acessar_reversa_gerada'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    GeneratedReturn: {
                        action: ['visualizar_dados_produto_reversa_gerada', 'editar_produto_reversa_gerada'],
                        acoes_em_massa: ['editar_produtos_reversa_massa']
                    }
                },
                menu_paths: [
                    {
                        name: 'Reversa Gerada',
                        path: '/logistica/reversa-gerada/dados',
                        menu_permission: 'acessar_reversa_gerada', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true,
                        count_name: 'pecas_reversa_gerada'
                    }
                ]
            },
        ]
    },
    {
        id: 'mam_logistica_relatorios',
        name: 'MAM Logística - Relatórios',
        icon: 'fas fa-file',
        sidenav_group: true,
        children: [
            {
                id: 'LogisticReportOi',
                element: <LogisticReportOi></LogisticReportOi>,
                routes_path: '/logistica/relatorios/oi',
                routes_permission: ['acessar_relatorio_oi'],
                menu_paths: [
                    {
                        name: 'Relatório - Oi',
                        path: '/logistica/relatorios/oi',
                        menu_permission: 'acessar_relatorio_oi',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'LogisticReportCostCenter',
                element: <LogisticReportCostCenter></LogisticReportCostCenter>,
                routes_path: '/logistica/relatorios/centro-custo',
                routes_permission: ['acessar_relatorio_centro_custo'],
                menu_paths: [
                    {
                        name: 'Relatório - Centro de Custo',
                        path: '/logistica/relatorios/centro-custo',
                        menu_permission: 'acessar_relatorio_centro_custo',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'LogisticReportLogistic',
                element: <LogisticReportLogistic></LogisticReportLogistic>,
                routes_path: '/logistica/relatorios/logistica',
                routes_permission: ['acessar_relatorio_logistica'],
                menu_paths: [
                    {
                        name: 'Relatório - Logística',
                        path: '/logistica/relatorios/logistica',
                        menu_permission: 'acessar_relatorio_logistica',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
        ]
    },
    {
        id: 'mam_reparos',
        name: 'MAM Reparos',
        icon: 'fas fa-tools',
        sidenav_group: true,
        children: [
            {
                id: 'RepairReportsTable',
                element: <RepairReportsTable></RepairReportsTable>,
                routes_path: '/reparos/laudos/:status?',
                routes_permission: ['acessar_aberto', 'acessar_analise', 'acessar_aguardando_componente', 'acessar_finalizado'],
                subcomponents_permissions: {
                    RepairReportsTable: {
                        action: ['visualizar_laudo', 'iniciar_analise', 'atualizar_analise', 'reabrir_analise'],
                        page: ['cadastrar_laudo', 'filtrar_laudos']
                    }
                },
                menu_paths: [
                    {
                        name: 'Laudos Técnicos - Em Aberto',
                        path: '/reparos/laudos/aberto',
                        menu_permission: 'acessar_aberto',
                        sidenav: true,
                        subheader: true,
                        count_name: 'aberto'
                    },
                    {
                        name: 'Laudos Técnicos - Em Análise',
                        path: '/reparos/laudos/em_analise',
                        menu_permission: 'acessar_analise',
                        sidenav: true,
                        subheader: true,
                        count_name: 'em_analise'
                    },
                    {
                        name: 'Laudos Técnicos - Aguardando Componente',
                        path: '/reparos/laudos/aguardando_componente',
                        menu_permission: 'acessar_aguardando_componente',
                        sidenav: true,
                        subheader: true,
                        count_name: 'aguardando_componente'
                    },
                    {
                        name: 'Laudos Técnicos - Finalizado',
                        path: '/reparos/laudos/finalizado',
                        menu_permission: 'acessar_finalizado',
                        sidenav: true,
                        subheader: true,
                        count_name: 'finalizado'
                    },
                ]
            },
            {
                id: 'SpareParts',
                element: <SpareParts></SpareParts>,
                routes_path: '/reparos/componentes',
                routes_permission: ['acessar_componentes'],
                subcomponents_permissions: {
                    SpareParts: {
                        action: ['ver_componente', 'deletar_componente'],
                        page: ['cadastrar_componente']
                    }
                },
                menu_paths: [
                    {
                        name: 'Saving - Spare Parts',
                        path: '/reparos/componentes',
                        menu_permission: 'acessar_componentes',
                        sidenav: true,
                        subheader: false
                    }
                ]
            }
        ]
    },
    {
        id: 'mam_cadastros',
        name: 'MAM Cadastros',
        icon: 'fas fa-list',
        sidenav_group: true,
        children: [
            {
                id: 'ShowContracts',
                element: <ShowContracts></ShowContracts>,
                routes_path: '/cadastros/contratos/dados/:status?',
                routes_permission: ['acessar_visualizar_contratos'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowContracts: {
                        action: ['ativar_desativar_contratos'],
                        page: ['cadastrar_contrato', 'visualizar_contratos_inativos', 'exportar_dados_contrato']
                    }
                },
                menu_paths: [
                    {
                        name: 'Contratos',
                        path: '/cadastros/contratos/dados/',
                        menu_permission: 'acessar_visualizar_contratos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'ContractDetails',
                element: <ContractDetails></ContractDetails>,
                routes_path: '/cadastros/contratos/detalhes/:id',
                routes_permission: ['acessar_detalhes_contrato'],
                subcomponents_permissions: {
                    ContractValuesTable: {
                        action: ['editar_valores_contrato', 'aprovar_valores_contrato', 'excluir_valores_contrato'],
                        table: ['ver_target_maximo'],
                        page: ['cadastrar_valores_contrato']
                    },
                    ContractDetails: {
                        page: ['alterar_dados_contrato', 'acessar_valores_contrato']
                    }
                }
            },
            {
                id: 'CreateContract',
                element: <CreateContract></CreateContract>,
                routes_path: '/cadastros/contratos/cadastrar',
                routes_permission: ['acessar_cadastrar_contrato'],
            },
            {
                id: 'ShowIntegrators',
                element: <ShowIntegrators></ShowIntegrators>,
                routes_path: '/cadastros/integradoras/dados/:status?',
                routes_permission: ['acessar_visualizar_integradoras'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowIntegrators: {
                        action: ['ativar_desativar_integradora'],
                        page: ['cadastrar_integradora', 'visualizar_integradoras_inativas', 'exportar_dados_integradora']
                    }
                },
                menu_paths: [
                    {
                        name: 'Integradora',
                        path: '/cadastros/integradoras/dados/',
                        menu_permission: 'acessar_visualizar_integradoras', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'IntegratorDetails',
                element: <IntegratorDetails></IntegratorDetails>,
                routes_path: '/cadastros/integradoras/detalhes/:id',
                routes_permission: ['acessar_detalhes_integradora'],
                subcomponents_permissions: {
                    IntegratorDetails: {
                        page: ['alterar_dados_integradora']
                    }
                }
            },
            {
                id: 'CreateIntegrator',
                element: <CreateIntegrator></CreateIntegrator>,
                routes_path: '/cadastros/integradoras/cadastrar',
                routes_permission: ['acessar_cadastrar_integradoras'],
            },
            {
                id: 'ShowPartners',
                element: <ShowPartners></ShowPartners>,
                routes_path: '/cadastros/representantes/dados/:status?',
                routes_permission: ['acessar_visualizar_representantes'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowPartners: {
                        action: ['ativar_desativar_representante'],
                        page: ['cadastrar_representante', 'visualizar_representantes_inativos', 'exportar_dados_representante']
                    }
                },
                menu_paths: [
                    {
                        name: 'Representantes',
                        path: '/cadastros/representantes/dados',
                        menu_permission: 'acessar_visualizar_representantes', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'PartnerDetails',
                element: <PartnerDetails></PartnerDetails>,
                routes_path: '/cadastros/representantes/detalhes/:id',
                routes_permission: ['acessar_detalhes_representante'],
                subcomponents_permissions: {
                    PartnerDetails: {
                        page: ['alterar_dados_representante', 'visualizar_valores_contrato_representantes']
                    },
                    PartnerValuesTable: {
                        action: ['editar_valores_contrato_representantes', 'aprovar_valores_contrato_representantes', 'cadastrar_valores_contrato_especiais', 'excluir_valores_contrato_representantes'],
                        page: ['cadastrar_valores_contrato_representantes', 'visualizar_valores_contrato_especiais']
                    },
                    SpecialPartnerTable: {
                        action: ['aprovar_valores_contrato_especiais', 'acessar_chamado', 'excluir_valores_contrato_especiais']
                    }
                }
            },
            {
                id: 'CreatePartner',
                element: <CreatePartner></CreatePartner>,
                routes_path: '/cadastros/representantes/cadastrar',
                routes_permission: ['acessar_cadastro_representante'],
            },
            {
                id: 'ShowTechnicians',
                element: <ShowTechnicians type='technician'></ShowTechnicians>,
                routes_path: '/cadastros/tecnicos/dados/:status?',
                routes_permission: ['acessar_visualizar_tecnicos'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowTechnicians: {
                        action_activate_deactivate: ['ativar_desativar_tecnico'],
                        action_accredit_deaccredit: ['credenciar_descredenciar_tecnico'],
                        page: ['cadastrar_tecnico', 'visualizar_tecnicos_inativos', 'exportar_dados_tecnicos']
                    }
                },
                menu_paths: [
                    {
                        name: 'Técnicos',
                        path: '/cadastros/tecnicos/dados',
                        menu_permission: 'acessar_visualizar_tecnicos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'TechnicianDetails',
                element: <TechnicianDetails type='technician'></TechnicianDetails>,
                routes_path: '/cadastros/tecnicos/detalhes/:id',
                routes_permission: ['acessar_detalhes_tecnico'],
                subcomponents_permissions: {
                    TechnicianDetails: {
                        page: ['alterar_dados_tecnico', 'visualizar_veiculos_alugados', 'visualizar_dados_bancarios', 'editar_dados_bancarios', 'visualizar_equipamentos_comodato', 'visualizar_afastamentos', 'alterar_contratos']
                    },
                    LeavesTable: {
                        action: ['cancelar_afastamento', 'atualizar_arquivo_afastamento', 'baixar_anexo_afastamento'],
                        page: ['cadastrar_afastamento']
                    },
                    LendingEquipmentTable: {
                        action: ['deletar_item_comodato'],
                        page: ['cadastrar_item_comodato']
                    },
                    RentedVehicles: {
                        action: ['cancelar_veiculo_alugado'],
                        page: ['cadastrar_veiculos_alugados']
                    }
                }
            },
            {
                id: 'CreateTechnician',
                element: <CreateTechnician type='technician'></CreateTechnician>,
                routes_path: '/cadastros/tecnicos/cadastrar',
                routes_permission: ['acessar_cadastro_tecnico'],
            },
            //Funcionarios
            {
                id: 'ShowEmployees',
                element: <ShowTechnicians type='employee'></ShowTechnicians>,
                routes_path: '/cadastros/funcionarios/dados/:status?',
                routes_permission: ['acessar_visualizar_funcionarios'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowEmployees: {
                        action_activate_deactivate: ['ativar_desativar_funcionario'],
                        page: ['cadastrar_funcionario', 'visualizar_funcionarios_inativos', 'exportar_dados_funcionario']
                    }
                },
                menu_paths: [
                    {
                        name: 'Funcionários',
                        path: '/cadastros/funcionarios/dados',
                        menu_permission: 'acessar_visualizar_funcionarios', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'EmployeeDetails',
                element: <TechnicianDetails type='employee'></TechnicianDetails>,
                routes_path: '/cadastros/funcionarios/detalhes/:id',
                routes_permission: ['acessar_detalhes_funcionario'],
                subcomponents_permissions: {
                    EmployeeDetails: {
                        page: ['alterar_dados_funcionario', 'visualizar_veiculos_alugados_funcionario', 'visualizar_dados_bancarios_funcionario', 'editar_dados_bancarios_funcionario', 'visualizar_equipamentos_comodato_funcionario', 'visualizar_afastamentos_funcionario']
                    },
                    LeavesTable: {
                        action: ['cancelar_afastamento_funcionario', 'atualizar_arquivo_afastamento_funcionario', 'baixar_anexo_afastamento_funcionario'],
                        page: ['cadastrar_afastamento_funcionario']
                    },
                    LendingEquipmentTable: {
                        action: ['deletar_item_comodato_funcionario'],
                        page: ['cadastrar_item_comodato_funcionario']
                    },
                    RentedVehicles: {
                        action: ['cancelar_veiculo_alugado_funcionario'],
                        page: ['cadastrar_veiculos_alugados_funcionario']
                    }
                }
            },
            {
                id: 'CreateEmployee',
                element: <CreateTechnician type='employee'></CreateTechnician>,
                routes_path: '/cadastros/funcionarios/cadastrar',
                routes_permission: ['acessar_cadastro_funcionario'],
            },
        ]
    },
    {
        id: 'acionamento',
        name: 'MAM Soluções - Acionamento',
        icon: 'fas fa-list',
        sidenav_group: true,
        children: [
            {
                id: 'AcionamentosManagement',
                element: <AcionamentosManagement></AcionamentosManagement>,
                routes_path: '/solucoes/acionamentos/dados',
                routes_permission: ['acessar_visualizar_acionamentos'], //Permissao para entrar na rota
                menu_paths: [
                    {
                        name: 'T EDC Acionamentos',
                        path: '/solucoes/acionamentos/dados',
                        menu_permission: 'acessar_visualizar_acionamentos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CreateAcionamento',
                element: <CreateAcionamento></CreateAcionamento>,
                routes_path: '/solucoes/acionamentos/cadastrar',
                routes_permission: ['acessar_cadastrar_acionamentos'],
                menu_paths: [
                    {
                        name: 'Incluir Acionamentos',
                        path: '/solucoes/acionamentos/cadastrar',
                        menu_permission: 'acessar_cadastrar_acionamentos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
        ]
    },
    // {
    //     id: 'retirada',
    //     name: 'MAM Soluções - Retirada',
    //     icon: 'fas fa-list',
    //     sidenav_group: true,
    //     children: [
    //         {
    //             id: 'RetiradaManagement',
    //             element: <RetiradaManagement></RetiradaManagement>,
    //             routes_path: '/solucoes/retirada/dados',
    //             routes_permission: ['acessar_visualizar_retirada'], //Permissao para entrar na rota
    //             menu_paths: [
    //                 {
    //                     name: 'T EDC Retirada',
    //                     path: '/solucoes/retirada/dados',
    //                     menu_permission: 'acessar_visualizar_retirada', //Permissao para ver no submenu e na sidenav
    //                     sidenav: true,
    //                     subheader: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'CreateRetirada',
    //             element: <CreateRetirada></CreateRetirada>,
    //             routes_path: '/solucoes/retirada/cadastrar',
    //             routes_permission: ['acessar_cadastro_retirada'],
    //             menu_paths: [
    //                 {
    //                     name: 'Incluir Acionamento de Retirada',
    //                     path: '/solucoes/retirada/cadastrar',
    //                     menu_permission: 'acessar_cadastro_retirada', //Permissao para ver no submenu e na sidenav
    //                     sidenav: true,
    //                     subheader: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'RetiradaDetails',
    //             element: <RetiradaDetails></RetiradaDetails>,
    //             routes_path: '/solucoes/retirada/detalhes/:id',
    //             routes_permission: ['acessar_detalhes_retirada'],
    //         },
    //     ]
    // },
    {
        id: 'mam_usuarios',
        name: 'MAM Usuários',
        icon: 'fas fa-user',
        sidenav_group: true,
        children: [
            {
                id: 'ShowUsers',
                element: <ShowUsers></ShowUsers>,
                routes_path: '/usuarios/dados/:status?',
                routes_permission: ['acessar_visualizar_usuarios'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    ShowUsers: {
                        action: ['visualizar_dados_usuario', 'ativar_desativar_usuario'],
                        page: ['visualizar_usuarios_inativos']
                    }
                },
                menu_paths: [
                    {
                        name: 'Gerenciar Usuários',
                        path: '/usuarios/dados',
                        menu_permission: 'acessar_visualizar_usuarios', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'CreateUser',
                element: <CreateUser></CreateUser>,
                routes_path: '/usuarios/cadastrar',
                routes_permission: ['acessar_cadastrar_usuarios'], //Permissao para entrar na rota
                menu_paths: [
                    {
                        name: 'Cadastrar Usuários',
                        path: '/usuarios/cadastrar',
                        menu_permission: 'acessar_cadastrar_usuarios', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'UserGroup',
                element: <UserGroup></UserGroup>,
                routes_path: '/usuarios/grupos',
                routes_permission: ['acessar_visualizar_grupos_usuarios'], //Permissao para entrar na rota
                menu_paths: [
                    {
                        name: 'Gerenciar Grupos',
                        path: '/usuarios/grupos',
                        menu_permission: 'acessar_visualizar_grupos_usuarios', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'UserDetails',
                element: <UserDetails></UserDetails>,
                routes_path: '/usuarios/detalhes/:id',
                routes_permission: ['acessar_detalhes_usuario'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    UserDetails: {
                        page: ['alterar_dados_usuario', 'visualizar_grupo_acesso_usuario', 'editar_grupo_acesso_usuario', 'visualizar_login', 'editar_login', 'visualizar_afastamento_usuario', 'editar_afastamento_usuario', 'visualizar_usuario_cliente', 'editar_usuario_cliente']
                    }
                },
            }
        ]
    },
    {
        id: 'mam_tecnicos',
        name: 'MAM Faturamento',
        icon: 'fas fa-credit-card',
        sidenav_group: true,
        children: [
            {
                id: 'ShowRefund',
                element: <ShowRefund></ShowRefund>,
                routes_path: '/faturamento/reembolso/dados',
                routes_permission: ['acessar_visualizar_reembolsos'], //Permissao para entrar na rota
                menu_paths: [
                    {
                        name: 'Reembolso',
                        path: '/faturamento/reembolso/dados',
                        menu_permission: 'acessar_visualizar_reembolsos', //Permissao para ver no submenu e na sidenav
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'EmployeeRefundsDetails',
                element: <EmployeeRefundsDetails></EmployeeRefundsDetails>,
                routes_path: '/faturamento/reembolso/detalhes/:id',
                routes_permission: ['acessar_detalhes_reembolso'], //Permissao para entrar na rota
                subcomponents_permissions: {
                    EmployeeRefundsDetails: {
                        page: ['alterar_reembolso_tecnico', 'cadastrar_reembolso']
                    }
                }
            }
        ]
    },
    {
        id: 'dev_mode',
        name: 'DEV MODE',
        icon: 'fas fa-code',
        sidenav_group: true,
        children: [
            {
                id: 'Modules',
                element: <Modules></Modules>,
                routes_path: '/dev/modulos/dados',
                routes_permission: ['crud_modulos'],
                menu_paths: [
                    {
                        name: 'Modulos',
                        path: '/dev/modulos/dados',
                        menu_permission: 'crud_modulos',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'Submodules',
                element: <Submodules></Submodules>,
                routes_path: '/dev/submodulos/dados',
                routes_permission: ['crud_submodulos'],
                menu_paths: [
                    {
                        name: 'Submodulos',
                        path: '/dev/submodulos/dados',
                        menu_permission: 'crud_submodulos',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'Permissions',
                element: <Permissions></Permissions>,
                routes_path: '/dev/permissoes/dados',
                routes_permission: ['crud_permissoes'],
                menu_paths: [
                    {
                        name: 'Permissões',
                        path: '/dev/permissoes/dados',
                        menu_permission: 'crud_permissoes',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'MetabaseManager',
                element: <MetabaseManager></MetabaseManager>,
                routes_path: '/dev/metabase',
                routes_permission: ['crud_metabase'],
                menu_paths: [
                    {
                        name: 'Metabase',
                        path: '/dev/metabase',
                        menu_permission: 'crud_metabase',
                        sidenav: true,
                        subheader: true
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        name: 'Administrador',
        icon: 'fas fa-user-cog',
        sidenav_group: true,
        children: [
            {
                id: 'TagsManager',
                element: <TagsManager></TagsManager>,
                routes_path: '/admin/tags/dados',
                routes_permission: ['crud_tags'],
                menu_paths: [
                    {
                        name: 'Tags',
                        path: '/admin/tags/dados',
                        menu_permission: 'crud_tags',
                        sidenav: true,
                        subheader: true
                    }
                ]
            },
            {
                id: 'FieldsOptionsManager',
                element: <FieldOptionsManager></FieldOptionsManager>,
                routes_path: '/admin/campos/dados',
                routes_permission: ['crud_opcoes_campos'],
                menu_paths: [
                    {
                        name: 'Opções Campos',
                        path: '/admin/campos/dados',
                        menu_permission: 'crud_opcoes_campos',
                        sidenav: true,
                        subheader: true
                    }
                ]
            }
        ]
    },
]

export const getElements = () => {
    return MenuConfigs
        .filter((item) => item?.children && item?.children?.length > 0)
        .flatMap(item => item?.children);
}

export const getSidenavs = () => {
    return MenuConfigs
        .filter(item => item?.sidenav_group)
        .filter(item => item?.children && item?.children?.length > 0)
        .map(item => ({
            ...item,
            permissions: item.children.map(item => item.routes_permission).flat(),
            menu_paths: item.children.filter(item2 => item2?.menu_paths && item2?.menu_paths?.length > 0)
                .flatMap(item => item.menu_paths
                    .filter(item => item.sidenav))
        }));
}

export const getSubheaders = () => {
    return MenuConfigs
        .filter(item => item?.children && item?.children?.length > 0)
        .map(item => ({
            ...item,
            menu_paths: item.children.filter(item2 => item2?.menu_paths && item2?.menu_paths?.length > 0)
                .flatMap(item => item.menu_paths
                    .filter(item => item.subheader))
        }));
}

export const getHeaderIconCounts = () => {
    return MenuConfigs
        .filter(item => item?.children && item?.children?.length > 0)
        .flatMap(item => item?.children)
        .flatMap(item => item?.menu_paths)
        .filter(item => item?.headerIcon);
}

export const getHeaderImageCounts = () => {
    return MenuConfigs
        .filter(item => item?.children && item?.children?.length > 0)
        .flatMap(item => item?.children)
        .flatMap(item => item?.menu_paths)
        .filter(item => item?.headerImage);
}

export const getSubComponentsPermissions = (menuId, childrenId, subcomponent) => {
    return MenuConfigs
        .filter(item => item.id === menuId)[0]
        ?.children?.filter(item => item.id === childrenId)[0]
        ?.subcomponents_permissions?.[subcomponent];
}