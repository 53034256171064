import { useContext } from 'react';
import { Context } from '../App';

export default function usePermissions(){
    const userData = useContext(Context);

    //Função para verificar todas as permissoes
    const checkAllPermissions = (permissions = []) => {
        let permissoes = userData?.userDataState?.permissoes;
        
        if(permissions.length === 0){
            return true;
        }

        for(let p of permissoes){
            if(permissions.includes(p)){
                return true;
            }
        }

        return false;
    }

    //Função para verificar uma permissao
    const checkPermission = (permission = '') => {
        if(permission === ''){
            return true;
        }

        let userPermissions = userData?.userDataState?.permissoes;

        if(userPermissions.includes(permission)){
            return true;
        }

        return false;

    }

    return {
        checkPermission,
        checkAllPermissions,
    }

}