import interceptorInstance from "./Interceptor";

export async function getTechnicianById(id){
    try{
         let data = await interceptorInstance.get('technician/' + id);
         return data;
    }catch(e){
        return e.response;
    }
}

export async function changeTechnicianCredentialById(id, status){
    try{
        let data = await interceptorInstance.patch(`technician/credencial/${id}/${status}`, {});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailability(params, id_contrato = ''){
    let queryParams = '';
    if(!['', undefined, null, false].includes(id_contrato)){
        queryParams = `?id_contrato=${id_contrato}`
    }

    try{
        let data = await interceptorInstance.post(`technician/availability${queryParams}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailabilityInTransit(params, id_contrato = ''){
    let queryParams = '';
    if(!['', undefined, null, false].includes(id_contrato)){
        queryParams = `?id_contrato=${id_contrato}`
    }
    try{
        let data = await interceptorInstance.post(`technician/availability-in-transit${queryParams}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailabilityHomeAddress(params, id_contrato = ''){
    let queryParams = '';
    if(!['', undefined, null, false].includes(id_contrato)){
        queryParams = `?id_contrato=${id_contrato}`
    }
    try{
        let data = await interceptorInstance.post(`technician/availability-by-home-address${queryParams}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPartnersByTechnicianId(id){
    try{
        let data = await interceptorInstance.get(`technician/search/${id}/partners`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getProductsByTechnicianId(id){
    try{
        let data = await interceptorInstance.get(`technician/${id}/products`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTechnicianUser(id){
    try{
        let data = await interceptorInstance.get(`technician/${id}/user`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAvailableTechniciansByState(data_agendamento){
    try{
        let data = await interceptorInstance.get(`technician/count-availability-by-state?data_agendamento=${data_agendamento}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTechnicianByStateFilter(data_agendamento = '', estado = ''){
    try{
        let data = await interceptorInstance.get(`technician/availability-by-state?data_agendamento=${data_agendamento}&estado_end=${estado}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAvailabilityCitiesByState(){
    try{
        let data = await interceptorInstance.get('technician/available-cities-by-state');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function countAvailableTechniciansByCity(data_agendamento = '', estado_end = ''){
    try{
        let data = await interceptorInstance.get(`technician/count-available-cities-by-state?data_agendamento=${data_agendamento}&estado_end=${estado_end}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTechniciansByCityAndState(data_agendamento = '', estado = '', cidade = ''){
    try{   
        let data = await interceptorInstance.get(`technician/available-technicians-by-city?data_agendamento=${data_agendamento}&estado_end=${estado}&cidade_end=${cidade}`);
        return data;
    }catch(e){
        return e.response;
    }
}