import interceptorInstance from "./Interceptor";

export async function getAllGroups(){
    try{
        let dados = await interceptorInstance.get(`group/all`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function createGroup(params){
    try{
        let dados = await interceptorInstance.post(`group/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getGroupById(id){
    try{
        let data = await interceptorInstance.get(`group/search/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateGroupById(id, params){
    try{
        let data = await interceptorInstance.put(`group/update/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}