import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createSubmodule } from '../../../services/DevMode';
import ModulosSelect from '../../../common/Selects/ModulosSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreateSubmodule({
    isOpen = false,
    setIsOpen = null,
}) {
    const INITIAL_STATE = {
        nome_submodulo: '',
        descricao: '',
        id_modulo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        let toastData = toast.loading("Cadastrando Submódulo, aguarde...");

        let params = {
            ...formData,
            id_modulo: formData?.id_modulo?.value || ''
        }

        let dataReturn = await createSubmodule(params);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast('Submódulo adicionado com sucesso!', 'update', toastData);
            setFormData(prev => ({...prev, nome_submodulo: '', descricao: ''}));
        }else{
            handleErrorsToast('Ocorreu um erro ao adicionar o Submódulo', dataReturn?.data, 'update', toastData);
        }
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Submódulo'
            maximizable
        >
            <form onSubmit={submitForm}>
                <div className='row'>
                    <ModulosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} required={true}></ModulosSelect>
                    <div className='col-sm-12 col-md-4 mb-1'>
                        <label htmlFor="nome_submodulo">Nome do Submódulo</label>
                        <input type='text' name='nome_submodulo' className='form-control' placeholder='Nome do Submódulo' value={formData?.nome_submodulo || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-sm-12 col-md-4 mb-1'>
                        <label htmlFor="descricao">Descrição do Submódulo</label>
                        <input type='text' name='descricao' className='form-control' placeholder='Descrição' value={formData?.descricao || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}