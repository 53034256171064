import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-toastify/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useToken from './hooks/useToken';
import defaultUserImage from './resources/imgs/profile/default.jpg';
import Login from './pages/Login/Login';
import { getPermissions, getUserData } from './services/Users';
import Header from './common/Layout/Header/Header';
import SideNav from './common/Layout/Nav/SideNav';
import Footer from './common/Layout/Footer';
import ExternalChat from './pages/Chat/ExternalChatEmail/ExternalChat';
import MainRoutes from './common/Routes/MainRoutes';
import If from './common/Layout/If';
import ExternalChatApp from './pages/Chat/ExternalChatApp/ExternalChatApp';

const initialState = { nome: '', login: '', email: '', cliente: '', id_contrato: '', permissoes: [], id_usuario: '', id_tecnico: '', url_foto_perfil: defaultUserImage, atualizar_qtd_faturamento: false, type: '' }

export const Context = React.createContext(initialState);

function App() {
  const [userDataState, setUserDataState] = useState(initialState);
  const [collapseSidebar, setCollapseSidebar] = useState(true);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 990);
  const [preloader, setPreloader] = useState(true);
  const childRef = useRef(null);
  const { token, setToken } = useToken();
  const location = window.location;

  const isLoggedIn = !!token; // Check if token exists

  useEffect(() => {
    // Solicitar permissão para notificações do navegador ao carregar a página
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    // if (document.readyState === "complete") {
    //   setPreloader(false);
    // } else {
    //   const handleLoad = () => setPreloader(false);
    //   window.addEventListener("load", handleLoad);

    //   return () => window.removeEventListener("load", handleLoad);
    // }

    // Simula um carregamento de dados
    setTimeout(() => {
      setPreloader(false);
    }, 1500);
  }, []);

  async function fetchUserData() {
    let dadosUsuario = await getUserData();
    let dadosPermissoes = await getPermissions();

    setUserDataState(prevState => ({
      ...prevState,
      nome: dadosUsuario?.data?.data?.nome,
      email: dadosUsuario?.data?.data?.email,
      login: dadosUsuario?.data?.data?.login,
      cliente: dadosUsuario?.data?.data?.cliente,
      id_tecnico: dadosUsuario?.data?.data?.id_tecnico || '',
      id_contrato: dadosUsuario?.data?.data?.id_contrato || '',
      permissoes: dadosPermissoes?.data?.data?.permissions,
      id_usuario: parseInt(dadosUsuario?.data?.data?.id),
      url_foto_perfil: `${dadosUsuario?.data?.data?.url_foto_perfil || defaultUserImage}?v=${Date.now()}`,
      atualizar_qtd_faturamento: true,
      type: 'interno'
    }));
  }

  function handleClickOutside(e) {
    if (isTablet && !collapseSidebar && childRef.current && !childRef.current.contains(e.target)) {
      setCollapseSidebar(true);
    }
  }

  useEffect(() => {
    if (location.pathname.includes('/external-chat')) {
      localStorage.clear();
      return;
    }

    if (isLoggedIn) {
      fetchUserData();
    }

  }, [isLoggedIn, location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 990);
      setCollapseSidebar(true);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Context.Provider value={{ userDataState, setUserDataState }}>
      <If condition={preloader}>
        <div className="h-screen flex flex-column justify-content-center align-items-center ">
          <img className="animation_spinner" src="/imgs/logo/logo_star_maminfo.png" alt="MaminfoLogo" height="60" width="60"></img>
        </div>
      </If>
      <If condition={!preloader}>
        <div className={`sidebar-mini layout-fixed ${collapseSidebar ? 'sidebar-collapse sidebar-closed' : 'sidebar-open'}`} onClick={handleClickOutside}>
          <div className='wrapper'>
            <If condition={location.pathname.includes('/app-chat')}>
              <BrowserRouter>
                <Routes>
                  <Route path='/app-chat/:tokenData' element={<ExternalChatApp setToken={setToken}></ExternalChatApp>}></Route>
                </Routes>
                <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
              </BrowserRouter>
            </If>
            <If condition={location.pathname.includes('/external-chat')}>
              <BrowserRouter>
                <Routes>
                  <Route path='/external-chat/:id' element={<ExternalChat></ExternalChat>}></Route>
                </Routes>
                <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
              </BrowserRouter>
            </If>

            <If condition={(!isLoggedIn && !location.pathname.includes('/external-chat') && !location.pathname.includes('/app-chat'))}>
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<Login setToken={setToken}></Login>}></Route>
                  <Route path='/login' element={<Login setToken={setToken}></Login>}></Route>
                  <Route path='*' element={<Login setToken={setToken}></Login>}></Route>
                </Routes>
                <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
              </BrowserRouter>
            </If>

            <If condition={(isLoggedIn && !location.pathname.includes('/external-chat') && !location.pathname.includes('/app-chat'))}>
              <BrowserRouter>
                <Header setToken={setToken} setIsCollapsed={setCollapseSidebar} isTablet={isTablet} isCollapsed={collapseSidebar}></Header>
                <SideNav childRef={childRef}></SideNav>
                <MainRoutes></MainRoutes>
                <Footer></Footer>
                <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
              </BrowserRouter>
            </If>
          </div>
        </div>
      </If>
    </Context.Provider>
  );
}

export default App;