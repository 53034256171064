import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createGroup } from '../../services/Group';
import ModulosSelect from '../../common/Selects/ModulosSelect';
import SubmodulosSelect from '../../common/Selects/SubmodulosSelect';
import PermissoesSelect from '../../common/Selects/PermissoesSelect';
import PrimeTable from '../../common/Layout/Table/PrimeTable';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';

export default function CreateUserGroup({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
}) {
    const INITIAL_STATE = {
        descricao: '',
        permissoes: ''
    }

    const INITIAL_PREVIEW_STATE = {
        id_modulo: '',
        id_submodulo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [previewData, setPreviewData] = useState(INITIAL_PREVIEW_STATE);
    const [previewTableData, setPreviewTableData] = useState([]);

    const columns = [
        {
            id: 'action',
            name: 'Excluir',
            body: (row) => (<button type='button' className='btn btn-danger' onClick={()=>removePermissionFromTable(row)}><i className='fas fa-trash'></i></button>)
        },
        {
            id: 'modulo',
            name: 'Modulo'
        },
        {
            id: 'submodulo',
            name: 'Submódulo'
        },
        {
            id: 'permissoes',
            name: 'Permissões',
            filter: false,
            body: (row) => {
                return <PermissoesSelect formData={row} setFormData={setPreviewTableData} isMulti id_submodulo={row.id_submodulo} showTitle={false} advancedSetFormData={(value, name) => handlePermissions(value, name, row)}></PermissoesSelect>
            }
        }
    ]

    async function submitForm(e) {
        e.preventDefault();
        let permissoes = previewTableData.filter(item => item?.id_permissao && item?.id_permissao?.length > 0).flatMap(item => item.id_permissao);

        let params = {
            ...formData,
            permissoes: permissoes.map((item) => ({id_permissao: item.value}))
        }

        let toastData = toast.loading("Cadastrando Grupo de Usuários, aguarde...");

        let dataReturn = await createGroup(params);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast('Grupo adicionado com sucesso!', 'update', toastData);
            setFormData(INITIAL_STATE);
            setPreviewData(INITIAL_PREVIEW_STATE);
            setPreviewTableData([]);
            updateParent();
        }else if(dataReturn?.data?.message === 'Já existe um grupo com essa descrição.'){
            handleErrorsToast('Este grupo de usuários já existe!', dataReturn?.data, 'update', toastData);
        }else{
            handleErrorsToast('Ocorreu um erro ao adicionar o grupo!', dataReturn?.data, 'update', toastData);
        }
    }

    function removePermissionFromTable(row){
        let currentPreviewTableData = previewTableData.filter(item => item.id_submodulo !== row.id_submodulo);

        setPreviewTableData(currentPreviewTableData);
    }

    const handleModules = (value, name) => {
        setPreviewData(prev => ({ ...prev, [name]: value, id_submodulo: '' }));
    }

    const handleSubModules = (value, name) => {
        if (previewTableData.find(item => item?.id_submodulo === value?.value)) {
            return;
        }
        setPreviewTableData(prev => [...prev, {
            id_modulo: previewData?.id_modulo?.value || '',
            id_submodulo: value?.value || '',
            modulo: previewData?.id_modulo?.label || '',
            submodulo: value?.label || '',
        }]);

        setPreviewData(INITIAL_PREVIEW_STATE);
    }

    const handlePermissions = (value, name, row) => {
        setPreviewTableData(prev => prev
            .map(rowAtual =>
                rowAtual.id_submodulo === row.id_submodulo
                    ? { ...rowAtual, [name]: value }
                    : rowAtual
            )
        );
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
        setPreviewData(INITIAL_PREVIEW_STATE);
        setPreviewTableData([]);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Grupo de Usuário'
            draggable={false}
            resizable={false}
            maximized
        >
            <div className='row'>
                <ModulosSelect formData={previewData} setFormData={setPreviewData} advancedSetFormData={handleModules} cols={[12, 6, 6, 6]} required></ModulosSelect>
                <SubmodulosSelect formData={previewData} setFormData={setPreviewData} advancedSetFormData={handleSubModules} id_modulo={previewData?.id_modulo?.value || ''} cols={[12, 6, 6, 6]} required></SubmodulosSelect>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5>Pré-visualizar Permissões</h5>
                    <PrimeTable
                        columns={columns}
                        rows={previewTableData}
                        needExport={false}
                        id='create_user_group'
                    ></PrimeTable>
                </div>
            </div>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className='col-12 mb-1'>
                        <label htmlFor="descricao">Nome do Grupo</label>
                        <input type='text' name='descricao' className='form-control' placeholder='Nome do Grupo de Usuários' value={formData?.descricao || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} required></input>
                    </div>
                    <div className='col-12 mt-1'>
                        <button type='submit' className='btn btn-primary' disabled={previewTableData?.filter(item => item?.id_permissao && item?.id_permissao?.length > 0).length < 1 || formData?.descricao?.length < 1}>Finalizar Cadastro</button>
                    </div>
                </div>
            </form>
        </Dialog >);
}