import React, { useCallback, useContext, useEffect, useState } from "react";
import Content from "../../common/Layout/Content";
import Card from "../../common/Layout/Card";
import defaultUserImage from '../../resources/imgs/profile/default.jpg';
import { withMask } from "use-mask-input";
import { Context } from "../../App";
import { toast } from "react-toastify";
import { getUserDataById, updateUser, updateUserProfilePhoto } from "../../services/Users";
import { useNavigate } from "react-router-dom";
import ImageCropper from "../../common/ImageCropper/ImageCropper";
import { Dialog } from "primereact/dialog";
import { handleErrorsToast, handleSuccessToast, onImageError } from "../../configs/GenericFunctions";

export default function Profile() {

    const initialState = {
        nome: '',
        email: '',
        telefone: '',
        login: '',
        senha: '',
        url_foto_perfil: ''
    }

    const [formUserData, setFormUserData] = useState(initialState)
    const userData = useContext(Context);
    const navigate = useNavigate();
    const [showPwd, setShowPwd] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    const getUserData = useCallback(async () =>{
        if (userData?.userDataState?.id_usuario === '') {
            return;
        }

        const toastUsers = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getUserDataById(userData?.userDataState?.id_usuario);

        if (dataReturn?.data?.response === 'success') {
            let user = dataReturn?.data?.data;
            handleSuccessToast('Dados encontrados!', 'update', toastUsers);
            setFormUserData({
                ...user,
                url_foto_perfil: user.url_foto_perfil + "?v=" + Date.now()
            });
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastUsers);
            navigate("/");
        }
    }, [userData?.userDataState?.id_usuario, navigate]);

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            nome: formUserData?.nome || '',
            login: formUserData?.login || '',
            email: formUserData?.email || '',
            telefone: formUserData?.telefone || '',
            senha: formUserData?.senha || ''
        }

        const toastUsers = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updateUser(userData?.userDataState?.id_usuario, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Usuário atualizado com sucesso!', 'update', toastUsers);
            getUserData();
        } else if (dataReturn?.data?.message === 'The login has already been taken.') {
            handleErrorsToast('Este usuário já existe', dataReturn?.data, 'update', toastUsers);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o usuário!', dataReturn?.data, 'update', toastUsers);
        }
    }

    async function updateProfile(updateImage) {
        if(updateImage.size > 4194304){
            handleErrorsToast('Tamanho de imagem maior que o permitido! Por favor, use uma imagem com menos que 4MB.')
            return;
        }

        let formData = new FormData();

        formData.append('foto_perfil', updateImage, updateImage?.type.replace("/", "."));

        const toastPhoto = toast.loading("Atualizando foto de perfil, aguarde...");

        let dataReturn = await updateUserProfilePhoto(userData?.userDataState?.id_usuario, formData);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Foto atualizada com sucesso!', 'update', toastPhoto);
            getUserData();
            userData?.setUserDataState(prevState => ({ ...prevState, url_foto_perfil: formUserData.url_foto_perfil + "?v=" + Date.now() }))
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar a foto!', dataReturn?.data, 'update', toastPhoto);
        }
    }

    useEffect(() => {
        getUserData();
    }, [getUserData]);


    return (
        <div>
            <Content headerTitle="Dados do Usuário">
                <div className="row">
                    <div className="col-md-12 col-lg-3">
                        <Card hideCardHeader={true}>
                            <div className="row">
                                <div className="col-12">
                                    <h5><i className='fas fa-dot-circle'></i> Foto de Perfil</h5>
                                </div>
                                <div className="col-12 d-flex align-content-center justify-content-center image">
                                    <img alt='profile' src={formUserData?.url_foto_perfil || defaultUserImage} className="img-circle" style={{ width: '100%', maxWidth: '332px', cursor: 'pointer' }} title="Clique aqui para alterar sua foto" onError={onImageError} onClick={() => setOpenModal(prevState => !prevState)}></img>
                                </div>

                                <Dialog visible={openModal} onHide={() => setOpenModal(!openModal)} header="Alterar Foto">
                                    <div className="row">
                                        <div className="col-12">
                                            <ImageCropper updateImage={updateProfile} setModalOpen={() => setOpenModal(!openModal)}></ImageCropper>
                                        </div>
                                    </div>
                                </Dialog>

                            </div>
                        </Card>
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <Card hideCardHeader={true}>
                            <form onSubmit={submitForm}>
                                <div className="row">
                                    <div className="col-12">
                                        <h5><i className='fas fa-dot-circle'></i> Dados</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="nome">Nome Completo</label>
                                        <input type="text" name="nome" id="nome" className="form-control" value={formUserData?.nome || ''} placeholder='...' required={true} readOnly={true} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="login">Login</label>
                                        <input type="text" name="login" id="login" className="form-control text-lowercase" value={formUserData?.login || ''} placeholder='...' required={true} readOnly={true} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name="email" id="email" className="form-control" value={formUserData?.email || ''} placeholder='...' required={true} readOnly={true} />
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <label htmlFor="telefone">Telefone Celular</label>
                                        <input type="tel" name="telefone" id="telefone" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} className="form-control" value={formUserData?.telefone || ''} onChange={(e) => setFormUserData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} />
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <label htmlFor="senha">Nova Senha(Deixe em branco para manter a atual)</label>
                                        <div className="input-group mb-3">
                                            <input type={!showPwd ? "password" : "text"} name="senha" id="senha" minLength={8} className="form-control" placeholder="..." value={formUserData?.senha || ''} onChange={(e) => (setFormUserData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end mt-1">
                                        <button type="submit" className="btn btn-primary">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </Content>
        </div>
    );
}