import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getSubModules } from '../../services/DevMode';
import { Col, FormLabel } from 'react-bootstrap';

export default function SubmodulosSelect({ formData, setFormData, fieldName = 'id_submodulo', cols = [12, 12, 12, 12], isDisabled = false, required = false, id_modulo = '', advancedSetFormData = null }) {
    const [submodulosOptions, setSubmodulosOptions] = useState([]);

    async function getAllSubmodulesByModuleId(id) {
        let dataReturn = await getSubModules(id);
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let submodulos = dataReturn.data.data.map((item, idx) => ({ value: item.id, label: item.nome_submodulo }));
            setSubmodulosOptions(submodulos);
        } else {
            setSubmodulosOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        let id = id_modulo;
        if (![undefined, null, ''].includes(id)) {
            getAllSubmodulesByModuleId(id);
        } else {
            setSubmodulosOptions([]);
        }

    }, [id_modulo]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Submódulos</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={submodulosOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
                isClearable={true}
            ></Select>
        </Col>
    );
}