import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import Swal from 'sweetalert2';
import Content from '../../../../common/Layout/Content';
import { toast } from 'react-toastify';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import MarkAsReceived from './MarkAsReceived';
import InTransitDetails from './InTransitDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { checkValue, getShipmentsByTicketId, handleErrorsToast, handleSuccessToast, openNewTab, showBulkShipmentMessage } from '../../../../configs/GenericFunctions';
import ShipmentFilters from '../Components/ShipmentFilters';
import BulkMarkAsReceived from './BulkMarkAsReceived';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';

export default function InTransit() {
    let interval;
    const [data, setData] = useState([]);
    const [openInTransitDetails, setOpenInTransitDetails] = useState(false);
    const [inTransitId, setInTransitId] = useState('');
    const [openMarkAsReceived, setOpenMarkAsReceived] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [search, setSearch] = useState('');

    const [bulkInTransitId, setBulkInTransitId] = useState('');
    const [openBulkMarkAsReceivedModal, setOpenBulkMarkAsReceivedModal] = useState(false);

    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, acoes_em_massa: acoesEmMassaPermissions } = getSubComponentsPermissions('mam_logistica', 'InTransit', 'InTransit');
    const milliseconds = 300000; //5 minutos
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openInTransitDetailsModal(row.id_produto, 'view')}>Ver</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[4])}>
                        <DropdownItem onClick={() => openInTransitDetailsModal(row.id_produto, 'update')}>Editar Remessa</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openMarkAsReceivedModal(row)}>Marcar como recebido</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2])}>
                        <DropdownItem onClick={() => showReturnToInvoiceSwal(row.id_produto)}>Cancelar Em Trânsito</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[3])}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Remessa</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</Button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'data_postagem_remessa',
            name: 'Data da Postagem'
        },
        {
            id: 'data_prazo_entrega',
            name: 'Data Prevista da Entrega'
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'status_transportadora',
            name: 'Status Transportadora',
        },
        {
            id: 'cep_produto',
            name: 'CEP',
        },
        {
            id: 'uf_produto',
            name: 'UF',
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
        },
        {
            id: 'rua_produto',
            name: 'Rua',
        },
        {
            id: 'numero_produto',
            name: 'Número',
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
        },
    ]

    const conditionRow = (row) => {
        return {
            "danger-color": row.cor_row === 'vermelho',
            "warning-color": row.cor_row === 'amarelo',
            "success-color": row.cor_row === 'verde',
        }
    }

    function openInTransitDetailsModal(id, mode) {
        setInTransitId(id);
        setViewMode(mode);
        setOpenInTransitDetails(true);
    }

    async function showReturnToInvoiceSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente retornar a remessa para emissão de NFE?',
            text: 'Todos os campos da NFE poderão ser alterados.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            returnToInvoice(id);
        }
    }

    async function returnToInvoice(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO NFE SAÍDA' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Emissão cancelada com sucesso!', 'update', toastProducts);
            getInTransitByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a emissão!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando remessa, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO CANCELAMENTO' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            getInTransitByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function getInTransitByStatus(page = 1, perPage = 100, search = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('EM TRÂNSITO', page, perPage, search);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                status_transportadora: item?.status_entrega_remessa || '',
                cor_row: item?.cor_row || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || '',
                data_postagem_remessa: item?.data_postagem_remessa || '',
                data_prazo_entrega: item?.prazo_entrega || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    async function openMarkAsReceivedModal(row) {
        if (checkValue(row.id_chamado_produto)) {
            handleSingleShipmentModal(row);
            return;
        }

        let product = await getShipmentsByTicketId('EM TRÂNSITO', `&id_chamado=${row.id_chamado_produto}`);

        if (product?.length < 2) {
            handleSingleShipmentModal(row);
        } else {
            showBulkShipmentMessage(() => handleSingleShipmentModal(row), () => handleBulkMarkAsReceivedModal(product));
        }
    }

    function handleSingleShipmentModal(row) {
        setInTransitId(row.id_produto);
        setOpenMarkAsReceived(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getInTransitByStatus(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getInTransitByStatus(1, rows, search);
    }

    function handleFilters(search = '') {
        setPerPage(100);
        setPage(0);
        setSearch(search);
        getInTransitByStatus(1, 100, search);
    }

    function handleBulkMarkAsReceivedModal(selectedRowsParam = []) {
        let shipmentIdArray = [];

        let selectedRowsData = selectedRowsParam?.length > 0 ? selectedRowsParam : selectedRows;

        for (let i = 0; i < selectedRowsData.length; i++) {
            shipmentIdArray.push(selectedRowsData[i]?.id_produto);
        }

        setBulkInTransitId(shipmentIdArray.toString());
        setOpenBulkMarkAsReceivedModal(true);
    }

    useEffect(() => {
        //Ver de quanto em quanto tempo será atualizado
        getInTransitByStatus();

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getInTransitByStatus();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (<Content headerTitle='Peças em Trânsito'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <MarkAsReceived isOpen={openMarkAsReceived} setModalOpen={() => setOpenMarkAsReceived(!openMarkAsReceived)} inTransitId={inTransitId} setUpdateProducts={() => getInTransitByStatus(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></MarkAsReceived>
        <InTransitDetails isOpen={openInTransitDetails} setModalOpen={() => setOpenInTransitDetails(!openInTransitDetails)} inTransitId={inTransitId} setUpdateProducts={() => getInTransitByStatus(page + 1, perPage, search)} viewMode={viewMode}></InTransitDetails>
        <BulkMarkAsReceived handleClearRows={() => setSelectedRows([])} isOpen={openBulkMarkAsReceivedModal} setModalOpen={() => setOpenBulkMarkAsReceivedModal(!openBulkMarkAsReceivedModal)} inTransitId={bulkInTransitId} setUpdateLogistic={setUpdateLogistic} setUpdateProducts={() => getInTransitByStatus(page + 1, perPage, search)}></BulkMarkAsReceived>
        <Card title='Cadastro de Produtos - Peças em Trânsito'>
            <Row>
                <Col sm='12'>
                    <If condition={selectedRows?.length > 0 && checkAllPermissions(acoesEmMassaPermissions)}>
                        <DropdownButton className='inline m-1' title='Ações em Massa'>
                            <If condition={checkPermission(acoesEmMassaPermissions[0])}>
                                <DropdownItem onClick={handleBulkMarkAsReceivedModal}>Marcar como recebido em massa</DropdownItem>
                            </If>
                        </DropdownButton>
                    </If>
                    <Button type='button' className="m-1" onClick={() => getInTransitByStatus()}>Atualizar Entregas</Button>
                </Col>
            </Row>
            <ShipmentFilters handleFilters={handleFilters} setSearch={setSearch}></ShipmentFilters>
            <Row>
                <Col sm='12'>
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        customStyles={conditionRow}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='in_transit'
                        reorderableColumns
                        selectableRows={checkAllPermissions(acoesEmMassaPermissions) ? true : false}
                        selectedRows={checkAllPermissions(acoesEmMassaPermissions) ? selectedRows : null}
                        handleSelect={checkAllPermissions(acoesEmMassaPermissions) ? setSelectedRows : null}
                    ></PrimeTable>
                </Col>
            </Row>
        </Card>
    </Content>)

}