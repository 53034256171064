import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { checkValue, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import If from '../../../../common/Layout/If';
import { updateEquipmentStatus } from '../../../../services/Ticket';
import { getOptionsByModuleField } from '../../../../services/Options';
import { Button, Form, FormControl, FormLabel, Row, Col } from 'react-bootstrap';

export default function UpdateShipmentStatus({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    dados_remessa = {},
    mode = 'edit',
    setMode = null,
    confirmShipment = null
}) {

    const INITIAL_STATE = {
        id_remessa: '',
        id_produto: '',
        id_equipamento_chamado: '',
        status_produto: '',
        modal_envio: { value: dados_remessa?.modal_envio || '', label: dados_remessa?.modal_envio || ''}
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            id_remessa: dados_remessa?.id_remessa,
            id_produto: dados_remessa?.id_produto,
            id_equipamento_chamado: dados_remessa?.id_equipamento_chamado,
            modal_envio: formData?.modal_envio?.value || dados_remessa?.modal_envio || 'N/A',
            status_produto: formData?.status_produto?.value || ''
        }

        if (params?.status_produto === '') {
            handleErrorsToast('Por favor, selecione um status da remessa ou cancele a operação clicando no X');
            return;
        } else {
            const toastData = toast.loading('Alterando status, aguarde...');

            let dataReturn = await updateEquipmentStatus(params);

            if (dataReturn?.status === 200) {
                handleSuccessToast('Status atualizado com sucesso!', 'update', toastData);
                confirmShipment(dados_remessa?.id_remessa);
                updateParent();
            } else {
                handleErrorsToast('Ocorreu um erro.', dataReturn?.data, 'update', toastData);
            }
        }
    }

    async function getChaveStatusProduto(){
        let dataReturn = await getOptionsByModuleField('produtos', 'id_status_produto');
        
        if(dataReturn?.status === 200){
            let statusProduto = dataReturn?.data?.data?.data?.filter(item => item.valor_opcao === dados_remessa?.status_remessa)[0];
            setFormData(prev => ({...prev, status_produto: {value: statusProduto?.chave_opcao || '', label: dados_remessa?.status_remessa || ''}}));
        }
    }

    async function confirmShipmentData(){
        confirmShipment(dados_remessa?.id_remessa);
        handleSuccessToast("Remessa confirmada com sucesso!");
        setIsOpen(false);
    }

    useEffect(() => {
        if(isOpen){
            setFormData(INITIAL_STATE);
            getChaveStatusProduto();
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header={mode === 'edit' ? 'Atualizar Remessa' : 'Confirmar Dados da Remessa'}
            maximizable={true}
        >
            <Form onSubmit={submitForm}>
                <Row>
                    <Col sm='12' md='6'>
                        <FormLabel>Fabricante</FormLabel>
                        <FormControl type="text" value={dados_remessa?.fabricante_remessa || ''} readOnly />
                    </Col>
                    <Col sm='12' md='6'>
                        <FormLabel>Nome do Produto</FormLabel>
                        <FormControl type="text" value={dados_remessa?.partnumber?.modelo || dados_remessa?.product?.partnumber?.modelo || ''} readOnly />
                    </Col>
                    <If condition={!['PRODUTO PENDENTE'].includes(dados_remessa?.status_remessa) && !checkValue(dados_remessa?.product?.serial)}>
                        <Col sm='12' md='6'>
                            <FormLabel>Tipo do Produto</FormLabel>
                            <FormControl type="text" value={dados_remessa?.product?.nome_produto || ''} readOnly />
                        </Col>
                        <Col sm='12' md='6'>
                            <FormLabel>Serial</FormLabel>
                            <FormControl type="text" value={dados_remessa?.product?.serial || ''} readOnly />
                        </Col>
                    </If>
                    <If condition={!['PRODUTO PENDENTE'].includes(dados_remessa?.status_remessa)}>
                        <GenericSelect fieldName={'status_produto'} formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} genericOption='StatusRemessaOptions' isClearable title='Status Remessa' isDisabled={mode === 'confirm'}></GenericSelect>
                    </If>
                    <ModalEnvioSelect fieldName='modal_envio' formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} isDisabled={mode === 'confirm'}></ModalEnvioSelect>
                    <If condition={mode === 'edit'}>
                        <Col sm='12' className="mt-1">
                            <Button variant='primary' type="submit">Salvar</Button>
                        </Col>
                    </If>
                    <If condition={mode === 'confirm'}>
                        <Col sm='12' className='mt-1'>
                            <Button variant='success' className='mr-1' onClick={()=>confirmShipmentData()}>Confirmar Dados</Button>
                            <Button variant='warning' onClick={()=>setMode('edit')}>Atualizar Dados</Button>
                        </Col>
                    </If>
                </Row>
            </Form>
        </Dialog>);
}