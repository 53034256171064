import { Button, Col, DropdownButton, DropdownItem, Row } from "react-bootstrap";
import Card from "../../../common/Layout/Card";
import Content from "../../../common/Layout/Content";
import PrimeTable from "../../../common/Layout/Table/PrimeTable";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import If from "../../../common/Layout/If";
import { getAllTags, removeTag } from "../../../services/Tags";
import { ColorPicker } from "primereact/colorpicker";
import CreateTag from "./CreateTag";
import Swal from "sweetalert2";
import UpdateTag from "./UpdateTag";

export default function TagsManager() {
    const columns = [
        {
            id: 'action',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <DropdownItem onClick={() => openUpdateTagModal(row)}>Atualizar</DropdownItem>
                    <DropdownItem onClick={() => showRemoveTagSwal(row.id)}>Excluir</DropdownItem>
                </DropdownButton>
            )
        },
        {
            id: 'nome',
            name: 'Nome'
        },
        {
            id: 'nome_upper',
            name: 'Nome Maiusculo'
        },
        {
            id: 'cor_hexadecimal',
            name: 'Cor',
            body: (row) => (
                <>
                    <ColorPicker format="hex" value={row.cor_hexadecimal} onClick={() => { }} className="mr-1"></ColorPicker>
                    <span>{row.cor_hexadecimal}</span>
                </>
            )
        },
        {
            id: 'created_at',
            name: 'Criado em'
        },
        {
            id: 'updated_at',
            name: 'Atualizado em'
        }
    ];

    const [data, setData] = useState([]);
    const [openCreateTag, setOpenCreateTag] = useState(false);
    const [openUpdateTag, setOpenUpdateTag] = useState(false);
    const [updateId, setUpdateId] = useState('');

    async function getTags() {
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllTags();

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            let tags = dataReturn?.data;

            setData(tags);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastData);
            setData([]);
        }
    }

    async function showRemoveTagSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo remover esta tag?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            confirmButtonColor: 'red'
        });

        if (swal.isConfirmed) {
            removeTagData(id);
        }
    }

    async function removeTagData(id) {
        const toastData = toast.loading("Removendo tag, aguarde...");

        let dataReturn = await removeTag(id);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Tag removida com sucesso', 'update', toastData);
            getTags();
        } else {
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }

    function openUpdateTagModal(row) {
        setUpdateId(row.id);
        setOpenUpdateTag(true);
    }

    useEffect(() => {
        getTags();
    }, []);

    return (
        <Content headerTitle="Gerenciar Tags">
            <If condition={openCreateTag}>
                <CreateTag isOpen={openCreateTag} setIsOpen={() => setOpenCreateTag(!openCreateTag)} updateParent={getTags}></CreateTag>
            </If>
            <If condition={openUpdateTag}>
                <UpdateTag isOpen={openUpdateTag} setIsOpen={() => setOpenUpdateTag(!openUpdateTag)} updateParent={getTags} updateId={updateId}></UpdateTag>
            </If>
            <SubHeaderSpecific subHeaderConfig='admin'></SubHeaderSpecific>
            <Card hideCardHeader>
                <Row>
                    <Col>
                        <Button variant='primary' type="button" onClick={() => setOpenCreateTag(!openCreateTag)}>Cadastrar Tag</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='tags_table'
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    );
}