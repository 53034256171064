import React, { useState } from 'react';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';
import { withMask } from 'use-mask-input';
import { InputMask } from 'primereact/inputmask';
import Cep from '../../../../common/Cep/Cep';
import RentedVehicles from '../TechnicianDetails/RentedVehicles/RentedVehicles';
import LendingEquipmentTable from '../TechnicianDetails/LendingEquipment/LendingEquipmentTable';
import LeavesTable from '../TechnicianDetails/Leaves/LeavesTable';
import IntegradoraSelect from '../../../../common/Selects/IntegradoraSelect';
import ContratosMultiSelect from '../../../../common/Selects/ContratosMultiSelect';

export default function Form({ type, page = 'create', submitForm = null, formTechnicianData = {}, setFormTechnicianData = null, id = '', updateData = null, setUpdataData = null }) {
    const [showPwd, setShowPwd] = useState(false);
    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'TechnicianDetails', 'TechnicianDetails');
    const { page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'EmployeeDetails', 'EmployeeDetails');

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    return (<>
        <form onSubmit={submitForm}>
            <If condition={page === 'update'}>
                <div className="row">
                    <div className="col-12">
                        <If condition={checkPermission(type === 'technician' ? pagePermissions[0] : pagePermissionsEmployee[0])}>
                            <If condition={!updateData}>
                                <button type="button" className="btn btn-primary" onClick={() => (setUpdataData(prevState => !prevState))}>Alterar</button>
                            </If>
                            <If condition={updateData}>
                                <button type="submit" className="btn btn-primary">Salvar Alterações</button>
                            </If>
                        </If>
                    </div>
                </div>
            </If>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> {`${type === 'technician' ? 'Técnico' : 'Funcionário'}`}</h5>
                </div>
                <div className="col-lg-2 col-md-12">
                    <div className="form-group">
                        <label htmlFor="nome_tec">Nome</label>
                        <input type="text" className="form-control" name="nome_tec" id="nome_tec" value={formTechnicianData?.nome_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required readOnly={!updateData && page === 'update'} />
                    </div>
                </div>
                <div className="col-lg-2 col-md-12">
                    <div className="form-group">
                        <label htmlFor="email_tec">Email Corporativo</label>
                        <input type="email" className="form-control" name="email_tec" id="email_tec" value={formTechnicianData?.email_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                    </div>
                </div>
                <div className="col-lg-2 col-md-12">
                    <div className="form-group">
                        <label htmlFor="email_tec_pessoal">Email Pessoal</label>
                        <input type="email" className="form-control" name="email_tec_pessoal" id="email_tec_pessoal" value={formTechnicianData?.email_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                    </div>
                </div>
                <div className="col-lg-2 col-md-12">
                    <div className="form-group">
                        <label htmlFor="nome_mae">Nome da Mãe</label>
                        <input type="text" className="form-control" name="nome_mae" id="nome_mae" value={formTechnicianData?.nome_mae || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                    </div>
                </div>
                <div className="col-lg-2 col-md-12">
                    <div className="form-group">
                        <label htmlFor="rg_tec_orgao_expedidor">Órgão Expeditor</label>
                        <input type="text" className="form-control" name="rg_tec_orgao_expedidor" id="rg_tec_orgao_expedidor" value={formTechnicianData?.rg_tec_orgao_expedidor || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                    </div>
                </div>
                <If condition={type === 'employee'}>
                    <GenericSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName={'categoria'} title={'Categoria'} cols={[12, 12, 2, 2]} genericOption='CategoriaColaboradorSemTecnicoOptions' required isDisabled={!updateData && page === 'update'}></GenericSelect>
                    <GenericSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName={'modal_contratacao'} title={'Modal de Contratação'} cols={[12, 12, 2, 2]} genericOption='ModalContratacaoOptions' required isDisabled={!updateData && page === 'update'}></GenericSelect>
                </If>
                <If condition={type === 'technician'}>
                    <RepresentanteSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_representante' cols={[12, 12, 2, 2]} isMulti={page === 'update'} required isDisabled={!updateData && page === 'update'}></RepresentanteSelect>
                </If>
                <If condition={(
                    (Array.isArray(formTechnicianData?.id_representante) && formTechnicianData?.id_representante?.filter((item, idx) => item?.label === 'PJ - MAMINFO SOLUÇÕES').length > 0)
                    || (!Array.isArray(formTechnicianData?.id_representante) && formTechnicianData?.id_representante?.label === 'PJ - MAMINFO SOLUÇÕES')
                )
                    || (formTechnicianData?.modal_contratacao?.value === 'PJ' && formTechnicianData?.id_representante?.label === 'PJ - MAMINFO SOLUÇÕES')}>
                    <UsuariosAtivosSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_usuario_gestor' cols={[12, 12, 2, 2]} userType='all' required title='Gestor' isDisabled={!updateData && page === 'update'}></UsuariosAtivosSelect>
                </If>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="celular_tec">Celular Corporativo</label>
                    <input type="text" name="celular_tec" id="celular_tec" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="celular_tec_pessoal">Celular Pessoal</label>
                    <input type="text" name="celular_tec_pessoal" id="celular_tec_pessoal" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="telefone_recados">Telefone Recado</label>
                    <input type="text" name="telefone_recados" id="telefone_recados" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_recados || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="telefone_fixo_tec">Telefone Fixo</label>
                    <input type="text" name="telefone_fixo_tec" id="telefone_fixo_tec" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_fixo_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="rg_tec">RG</label>
                    <input type="text" name="rg_tec" id="rg_tec" className="form-control" value={formTechnicianData?.rg_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="cpf_cnpj">CPF</label>
                    <input type="text" name="cpf_cnpj" id="cpf_cnpj" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_cnpj || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <If condition={type === "employee" && formTechnicianData?.modal_contratacao?.value === 'PJ'}>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cnpj">CNPJ</label>
                        <InputMask name='cnpj' id='cnpj' value={formTechnicianData?.cnpj || ''} className='form-control' mask="99.999.999/9999-99" placeholder='99.999.999/9999-99' onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'}></InputMask>
                    </div>
                </If>
                <GenericSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName={'estado_civil'} title={'Estado Civil'} cols={[12, 12, 2, 2]} genericOption='EstadoCivilOptions' isDisabled={!updateData && page === 'update'} ></GenericSelect>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="data_nascimento">Data Nascimento</label>
                    <input type="date" name="data_nascimento" id="data_nascimento" className="form-control" value={formTechnicianData?.data_nascimento || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="numero_cnh">Nº CNH</label>
                    <input type="text" name="numero_cnh" id="numero_cnh" className="form-control" value={formTechnicianData?.numero_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="data_validade_cnh">Data de Validade CNH</label>
                    <input type="date" name="data_validade_cnh" id="data_validade_cnh" className="form-control" value={formTechnicianData?.data_validade_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="data_exame_periodico">Data Exame Periódico</label>
                    <input type="date" name="data_exame_periodico" id="data_exame_periodico" className="form-control" value={formTechnicianData?.data_exame_periodico || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="data_validade_aso">Data de Validade ASO</label>
                    <input type="date" name="data_validade_aso" id="data_validade_aso" className="form-control" value={formTechnicianData?.data_validade_aso || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'} />
                </div>
            </div>
            <hr />
            <If condition={type === 'technician' && checkPermission(pagePermissions[6])}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-dot-circle"></i> Integradoras/Contratos</h5>
                        <div className="row">
                            <IntegradoraSelect formData={formTechnicianData} setFormData={setFormTechnicianData} cols={[12, 6, 6, 6]} isMulti fieldName='id_integradora' isDisabled={!updateData && page === 'update'}></IntegradoraSelect>
                            <ContratosMultiSelect formData={formTechnicianData} setFormData={setFormTechnicianData} cols={[12, 6, 6, 6]} fieldName='id_contrato' dependsOnIntegradora id_integradora_string={formTechnicianData?.id_integradora?.map(item => item.value).toString()} isDisabled={!updateData && page === 'update'}></ContratosMultiSelect>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </If>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Endereço</h5>
                </div>
            </div>
            <Cep
                formData={formTechnicianData}
                setFormData={setFormTechnicianData}
                hasNumero
                formDataNames={{
                    'cep': 'cep_end',
                    'bairro': 'bairro_end',
                    'cidade': 'cidade_end',
                    'estado': 'estado_end',
                    'logradouro': 'logradouro_end',
                    'numero': 'numero_complemento_endereco'
                }}
                required
                readOnly={!updateData && page === 'update'}
            ></Cep>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5><i className="fas fa-dot-circle"></i> Veículo Particular</h5>
                </div>
                <div className="col-lg-4 col-md-12">
                    <label htmlFor="veiculo">Veículo</label>
                    <input type="text" name="veiculo" id="veiculo" className="form-control" value={formTechnicianData?.veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-4 col-md-12">
                    <label htmlFor="modelo_veiculo">Modelo</label>
                    <input type="text" name="modelo_veiculo" id="modelo_veiculo" className="form-control" value={formTechnicianData?.modelo_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="cor_veiculo">Cor</label>
                    <input type="text" name="cor_veiculo" id="cor_veiculo" className="form-control" value={formTechnicianData?.cor_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
                <div className="col-lg-2 col-md-12">
                    <label htmlFor="placa_veiculo">Placa</label>
                    <input type="text" name="placa_veiculo" id="placa_veiculo" className="form-control" value={formTechnicianData?.placa_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'} />
                </div>
            </div>
            <hr></hr>
            <If condition={checkPermission(type === 'technician' ? pagePermissions[1] : pagePermissionsEmployee[1]) && page === 'update'}>
                <RentedVehicles technicianId={id} readOnly={!updateData && page === 'update'} type={type}></RentedVehicles>
                <hr></hr>
            </If>
            <If condition={page === 'create' && checkPermission(type === 'technician' ? pagePermissions[2] : pagePermissionsEmployee[2])}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Dados Bancários</h5>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="banco">Banco</label>
                        <input type="text" name="banco" id="banco" className="form-control" value={formTechnicianData?.banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="tipo_conta_banco">Tipo Conta</label>
                        <input type="text" name="tipo_conta_banco" id="tipo_conta_banco" className="form-control" value={formTechnicianData?.tipo_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="agencia_banco">Agência</label>
                        <input type="text" name="agencia_banco" id="agencia_banco" className="form-control" value={formTechnicianData?.agencia_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="num_conta_banco">Nº Conta</label>
                        <input type="text" name="num_conta_banco" id="num_conta_banco" className="form-control" value={formTechnicianData?.num_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="titular_banco2">Titular 2</label>
                        <input type="text" name="titular_banco2" id="titular_banco2" className="form-control" value={formTechnicianData?.titular_banco2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cpf_titular2">CPF Titular 2</label>
                        <input type="text" name="cpf_titular2" id="cpf_titular2" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_titular2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={(!updateData && page === 'update') || !checkPermission(pagePermissions[3])} />
                    </div>
                </div>
                <hr></hr>
            </If>
            <If condition={page === 'update' && formTechnicianData?.tech_user?.length > 0 && type === 'technician'}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Login App Mobile</h5>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="login_tec">Login</label>
                        <input type="text" name="login_tec" id="login_tec" className="form-control" placeholder='...' value={formTechnicianData?.login_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="senha_tec">Senha (Digite a nova senha para alterar ou deixe em branco para manter a senha atual)</label>
                        <div className="input-group mb-3">
                            <input type={!showPwd ? "password" : "text"} name="senha_tec" id="senha_tec" minLength={8} className="form-control" placeholder="..." value={formTechnicianData?.senha_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData && page === 'update'} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </If>
            {/* <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Divergências Técnico</h5>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-secondary" disabled={!updateData}>Zerar Contagem de Divergências</button>
                    </div>
                    <div className="col-12">
                        <Table columns={divergenciaTecnicoColumn} data={[]} id='divergencia_tecnico'></Table>
                    </div>
                </div>
                <hr></hr> */}
            <If condition={checkPermission(type === 'technician' ? pagePermissions[4] : pagePermissionsEmployee[4]) && page === 'update'}>
                <LendingEquipmentTable id={id} updateData={!updateData && page === 'update'} type={type}></LendingEquipmentTable>
                <hr></hr>
            </If>
            <If condition={checkPermission(type === 'technician' ? pagePermissions[5] : pagePermissionsEmployee[5]) && page === 'update'}>
                <LeavesTable id={id} updateData={!updateData && page === 'update'} type={type}></LeavesTable>
                <hr></hr>
            </If>
            <div className="row">
                <div className="col-12">
                    <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="descricao">Observações</label>
                        <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formTechnicianData?.descricao || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData && page === 'update'}></textarea>
                    </div>
                </div>
            </div>
            <If condition={page === 'create'}>
                <div className="row">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </If>
        </form>
    </>);
}