import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import Card from '../../../common/Layout/Card';
import GenericSelect from '../../../common/Selects/GenericSelect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTickets } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import { N2StatusChamadoOptions } from '../../../configs/GenericOptions';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { handleSuccessToast } from '../../../configs/GenericFunctions';

export default function N2Tickets() {
    const INITIAL_STATE = {
        data_inicial: '',
        data_final: '',
        status_chamado: [{value: 'Suporte interno (Fila)', label: 'Suporte interno (Fila)'}]
    }
    const arrayFields = ['status_chamado', 'contrato', 'remove_contrato', 'integradora'];
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (<Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>)
        },
        {
            id: 'num_chamado',
            name: 'ID Ticket Cli. / Nº Chamado',
            // body: (row) => (<span onClick={() => copyToClipboard(row.num_chamado)}>{row.num_chamado}</span>)
        },
        {
            id: 'status_chamado',
            name: 'Status Chamado',
        },
        {
            id: 'tipo_atividade',
            name: 'Tipo Atividade',
        },
        {
            id: 'usuario_responsavel_suporte',
            name: 'Nome do Analista Responsável',
        },
        {
            id: 'data_entrada_suporte',
            name: 'Data / Hora de Entrada na Fila',
        },
        {
            id: 'data_assumido_pelo_suporte',
            name: 'Data / Hora Designação de Analista',
        },
        {
            id: 'contrato',
            name: 'Contrato',
        },
        {
            id: 'integradora',
            name: 'Integradora',
        },

        {
            id: 'tecnico',
            name: 'Técnico',
            body: (row) => (<Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}>{row.tecnico}</Link>),
        },
        {
            id: 'contato_tecnico',
            name: 'Telefone',

        },
    ];

    const conditionRow = (row) => {
        return {
            "danger-color custom-style": row.cor_row === 'vermelho',
            "info-color custom-style": row.cor_row === 'azul',
            "warning-color custom-style": row.cor_row === 'amarelo',
            "success-color custom-style": row.cor_row === 'verde',
        }
    }

    async function getTicketsByFilter(e) {
        e.preventDefault();
        let params = {};

        params['data_inicial'] = formData.data_inicial;
        params['data_final'] = formData.data_final;
        params['status_chamado'] = formData.status_chamado.map((item) => (item?.value === undefined ? '' : item.value));
        let url = `?`;

        for (let p in params) {
            if (arrayFields.includes(p)) {
                for (let c = 0; c < params[p].length; c++) {
                    url += (`${p}=${params[p][c]}&`);
                }
            } else {
                url += (`${p}=${params[p]}&`);
            }
        }

        url = url.slice(0, -1);

        navigate(url);
    }

    async function getTicketsByParam(page, perPage) {
        const toastTicket = toast.loading("Carregando, aguarde...");
        try {
            let urlParams = new URLSearchParams(location.search);
            let params = {
                page: page,
                per_page: perPage
            }
            let booleanFields = ['gts', 'key_ticket_cliente_null']

            urlParams.forEach((item, idx) => {

                if (item !== "") {
                    if (arrayFields.includes(idx)) {
                        if (!params[idx]) {
                            params[idx] = [];
                        }
                        params[idx].push(item);
                    } else if (booleanFields.includes(idx)) {
                        params[idx] = item === "true" ? true : false;
                    } else {
                        params[idx] = item;
                    }
                }
            });
            setLoading(true);
            let dataReturn = await getTickets(params);
            if (dataReturn?.data?.response === 'success') {
                let ticketData = dataReturn?.data?.data || [];
                if (ticketData?.tickets?.length > 0) {
                    ticketData.tickets = ticketData?.tickets?.map((item, idx) => {
                        for (let i in item) {
                            item[i] = [undefined, null].includes(item[i]) ? '' : item[i]
                        }
                        return item;
                    });
                }
                setData(ticketData?.tickets);
                setPaginationTotalRows(ticketData?.pagination?.total)
            } else {
                setPaginationTotalRows(0)
                setData([]);
            }
            setLoading(false);
        } catch (e) {
            setData([]);
            setLoading(false);
        }
        handleSuccessToast('Pesquisa realizada com sucesso!', 'update', toastTicket);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
    }

    useEffect(() => {
        getTicketsByParam(page + 1, perPage);
    }, [location]);

    useEffect(() => {
        if (formData?.status_chamado.filter(item => item.value === '').length > 0) {
            let options = N2StatusChamadoOptions.filter(item => item.value !== '');
            setFormData(prevState => ({ ...prevState, status_chamado: options }))
        }
    }, [formData?.status_chamado]);

    return (<Content headerTitle='Chamados'>
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <FloatSearchButton></FloatSearchButton>
        <Card hideCardHeader={true}>
            <form onSubmit={getTicketsByFilter}>
                <div className="row">
                    <div className="col">
                        <label htmlFor="data_inicial">Data Início</label>
                        <input type="date" name="data_inicial" id="data_inicial" className="form-control" value={formData?.data_inicial || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col">
                        <label htmlFor="data_inicial">Data Fim</label>
                        <input type="date" name="data_final" id="data_final" className="form-control" value={formData?.data_final || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <GenericSelect formData={formData} setFormData={setFormData} cols={[12, 12, 3, 3]} fieldName={'status_chamado'} title={'Status Chamado'} required={true} genericOption='N2StatusChamadoOptions' isMulti={true}></GenericSelect>
                    <div className="col d-flex align-items-end mt-1">
                        <button type="submit" className="btn btn-primary">Filtrar Chamados</button>
                    </div>
                </div>
            </form>
        </Card>
        <Card hideCardHeader={true}>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
                customStyles={conditionRow}
                id='tickets_n2'
            ></PrimeTable>
        </Card>
    </Content>)
}