import React, { useContext, useState } from 'react';
import Card from '../../../../common/Layout/Card';
import { withMask } from 'use-mask-input';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import { addNoteByTicketId } from '../../../../services/Ticket';
import { Context } from '../../../../App';
import { toast } from 'react-toastify';
import { createChannel, getChannelsByTicketId, sendMessageByChannelId } from '../../../../services/Chat';
import { createNotification } from '../../../../services/Notifications';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import AdicionarNotaExternaModal from './AdicionarNotaExternaModal';
import AdicionarNotaParceriasModal from './AdicionarNotaParceriasModal';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { getTechnicianUser } from '../../../../services/Technician';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';

export default function RegistroTrabalhoCard({
    detailsData = {},
    setDetailsData = null,
    originalData = {},
    id = '',
    getHistory = null,
    setChannelId = null,
    setFloatChatOpened = null,
    historico = '',
    historicoExternoCliente = '',
    historicoTecnico = '',
    historicoBacklog = '',
    historicoParcerias = '',
    imgArray = [],
    imgThumbArray = []
}) {
    const INITIAL_STATE = {
        nota_chamado: '',
    }
    const userData = useContext(Context);
    const [showNote, setShowNote] = useState(false);
    const [showPartnerNote, setShowPartnerNote] = useState(false);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const { checkPermission } = usePermissions();
    const { page: pagePermissions, escalation: escalationPermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'RegistroTrabalhoCard');

    async function addNote(e, type, field) {
        e.preventDefault();

        let params = {
            descricao: formData?.[field],
            tipo: type,
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading('Adicionando Nota, aguarde...');

        let dataReturn = await addNoteByTicketId(id, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Nota adicionada com sucesso!', 'update', toastNote);
            getHistory();
            setFormData(prev => ({ ...prev, [field]: '' }));
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar a nota!', dataReturn?.data, 'update', toastNote);
        }
    }

    async function checkIfChannelExists(type, name = '', description = '') {
        let dataReturn = await getChannelsByTicketId(originalData?.id);

        if (dataReturn?.status === 200) {
            let channels = dataReturn?.data?.data || [];
            for (let item of channels) {
                if (item?.channel_name === name && item?.channel_description === description) {
                    setChannelId(item?._id);
                    setFloatChatOpened(true);
                    return;
                }
            }

            createChannelByResponsibleType(type, name, description);
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os canais!', dataReturn?.data);
        }
    }

    async function createChannelByResponsibleType(type, name = '', description = '') {
        let user;
        let formData = {
            channel_name: name,
            ticket_id: originalData?.id,
            channel_description: description,
            users: [userData?.userDataState?.id_usuario],
            type: 'channel'
        }

        if ([undefined, null, ''].includes(originalData?.[type]?.id)) {
            handleErrorsToast('Responsável não encontrado');
            return;
        }

        if (originalData?.[type]?.id === userData?.userDataState?.id_usuario) {
            handleErrorsToast('Você não pode adicionar a si mesmo!');
            return;
        }

        if (type === 'technician') {
            let dataReturn = await getTechnicianUser(originalData?.technician?.id);

            if (dataReturn?.status === 200) {
                let technician = dataReturn?.data?.data;

                if ([null, '', undefined].includes(technician?.id)) {
                    handleErrorsToast('Este técnico não tem usuário!');
                    return;
                }

                user = technician?.id;

            } else {
                handleErrorsToast('Ocorreu um erro ao buscar o técnico!', dataReturn?.data);
                return;
            }
        }

        user = type === 'technician' ? user : originalData?.[type]?.id;

        formData.users = [...formData.users, user];

        const toastCreateChannel = toast.loading("Criando canal, aguarde...");

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {

            if (formData?.nota_chamado?.length) {
                let params = new FormData();
                params.append('message', formData?.nota_chamado);

                let messageReturn = await sendMessageByChannelId(dataReturn?.data?.data?._id, params);

                if (messageReturn?.data?.response !== 'success') {
                    handleErrorsToast('Ocorreu um erro ao enviar a mensagem', dataReturn?.data);
                }
            }

            let notificationParams = {
                type: 'danger',
                users: [originalData?.[type]?.id],
                module_id: originalData?.id,
                module: 'ticket',
                event: 'help'
            }

            await createNotification(notificationParams);

            handleSuccessToast('Canal criado, aguarde a abertura dele!', 'update', toastCreateChannel);

            setChannelId(dataReturn?.data?.data?._id);
            setFloatChatOpened(true);

        } else {
            handleErrorsToast('Ocorreu um erro ao criar o canal!', dataReturn?.data, 'update', toastCreateChannel);
        }
    }

    function handleResponsavelImprodutivo(value, name){
        setDetailsData(p => ({...p, [name]:value, motivo_improdutivo: ''}));
    }

    return (<Card title={`Registro de Trabalho`}>
        <AdicionarNotaExternaModal isOpen={showNote} setIsOpen={() => setShowNote(!showNote)} id={id} originalData={originalData} updateParent={getHistory}></AdicionarNotaExternaModal>
        <AdicionarNotaParceriasModal isOpen={showPartnerNote} setIsOpen={() => setShowPartnerNote(!showPartnerNote)} id={id} originalData={originalData} updateParent={getHistory}></AdicionarNotaParceriasModal>
        <div className="row" id="registroTrabalhoFechamento">
            <If condition={(detailsData?.status_chamado?.value === "Fechado" && originalData?.status_chamado !== "Fechado")}>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Data do Início do Atendimento</label>
                        <input type="date" name="data_inicio_atendimento" className="form-control" value={detailsData?.data_inicio_atendimento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Hora do Início do Atendimento</label>
                        <input type="time" name="hora_inicio_atendimento" className="form-control" value={detailsData?.hora_inicio_atendimento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Dt. Encerramento do Atendimento</label>
                        <input type="date" name="data_encerramento" className="form-control" value={detailsData?.data_encerramento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Hr. Encerramento do Atendimento</label>
                        <input type="time" name="hora_encerramento" className="form-control" value={detailsData?.hora_encerramento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="">Senha Integradora</label>
                        <input type="text" name="senha_integradora" className="form-control" value={detailsData?.senha_integradora || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Nome do Contato do Cliente</label>
                        <input type="text" name="nome_analista_integracao" className="form-control" placeholder='...' value={detailsData?.nome_analista_integracao || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Telefone do Contato no Cliente</label>
                        <input type="tel" className="form-control" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} name="tel_contato_analista_integracao" placeholder='...' value={detailsData?.tel_contato_analista_integracao || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Documento/Matrícula do Contato no Cliente</label>
                        <input type="text" name="documento_matricula" className="form-control" placeholder='...' value={detailsData?.documento_matricula || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <If condition={detailsData?.id_integradora?.label === 'Oi S/A'}>
                    <div className="col-md-4 col-sm-6">
                        <div className='form-group'>
                            <label htmlFor="protocolo_anatel">Protocolo Anatel</label>
                            <input type="text" className="form-control" name="protocolo_anatel" id="protocolo_anatel" value={detailsData?.protocolo_anatel || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required/>
                        </div>
                    </div>
                </If>
                <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'ocasiao_fechamento'} title={'Ocasião Fechamento'} cols={[6, 6, 6, 6]} genericOption={'OcasiaoFechamento'} required={true}></GenericSelect>
                <If condition={detailsData?.ocasiao_fechamento?.value === "Improdutivo"}>
                    <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'responsavel_improdutivo'} title={'Responsável Improdutivo'} cols={[6, 6, 6, 6]} genericOption={'ResponsavelImprodutivoOptions'} required={true} advancedSetFormData={handleResponsavelImprodutivo}></GenericSelect>
                    <ModuleOptionsSelect formData={detailsData} setFormData={setDetailsData} cols={[6, 6, 6, 6]} field={detailsData?.responsavel_improdutivo?.value || 'none'} fieldName={'motivo_improdutivo'} module={'chamados'} title={'Motivo Improdutivo'} required={true}></ModuleOptionsSelect>                    
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="">Justificativa de fechamento improdutivo</label>
                            <textarea name="justificativa_ocasiao_fechamento" cols="30" rows="10" className="form-control" placeholder='...' value={detailsData?.justificativa_ocasiao_fechamento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                        </div>
                    </div>
                </If>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Descrição do Fechamento</label>
                        <textarea name="descricao_fechamento" cols="30" rows="10" className="form-control" value={detailsData?.descricao_fechamento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                </div>
            </If>
        </div>
        <div className="row">
            <div className="col-12 mb-2">
                <If condition={checkPermission(pagePermissions[0])}>
                    <button className="btn btn-primary mr-1 mb-1" onClick={() => setShowNote(prevState => !prevState)}>Adicionar Nota Externa Cliente</button>
                </If>
                <If condition={checkPermission(pagePermissions[1])}>
                    <button className="btn btn-primary mb-1" onClick={() => setShowPartnerNote(prevState => !prevState)}>Adicionar Nota Parcerias</button>
                </If>
            </div>
            <div className="col-12 mb-2">
                <div className="row">
                    <If condition={checkPermission(escalationPermissions[0])}>
                        <div className="col-lg-2 col-sm-6 mb-1">
                            <button type="button" className="btn btn-primary btn-block" onClick={() => checkIfChannelExists('user', 'Acionamento', 'Acionamento')}>MAMINFO Talks Acionamento</button>
                        </div>
                    </If>
                    <If condition={checkPermission(escalationPermissions[3])}>
                        <div className="col-lg-2 col-sm-6 mb-1">
                            <button type="button" className="btn btn-primary btn-block" onClick={() => checkIfChannelExists('ticket_responsible_user', 'Resp. Chamado', 'Resp. Chamado')}>MAMINFO Talks Resp. Chamado</button>
                        </div>
                    </If>
                    <If condition={checkPermission(escalationPermissions[2])}>
                        <div className="col-lg-2 col-sm-6 mb-1">
                            <button type="button" className="btn btn-primary btn-block" onClick={() => checkIfChannelExists('partnership_responsible_user', 'Resp. Parcerias', 'Resp. Parcerias')}>MAMINFO Talks Resp. Parcerias</button>
                        </div>
                    </If>
                    <If condition={checkPermission(escalationPermissions[1])}>
                        <div className="col-lg-2 col-sm-6 mb-1">
                            <button type="button" className="btn btn-primary btn-block" onClick={() => checkIfChannelExists('support_responsible_user', 'Resp. Suporte', 'Resp. Suporte')}>MAMINFO Talks Resp. Suporte</button>
                        </div>
                    </If>
                    <If condition={checkPermission(escalationPermissions[4])}>
                        <div className="col-lg-2 col-sm-6 mb-1">
                            <button type="button" className="btn btn-primary btn-block" onClick={() => checkIfChannelExists('technician', 'Técnico', 'Técnico')}>MAMINFO Talks Técnico</button>
                        </div>
                    </If>
                </div>
            </div>
            <If condition={originalData?.status_chamado !== "Fechado" && checkPermission(pagePermissions[2])}>
                <div className="col-12">
                    <form onSubmit={(e) => addNote(e, 'interna', 'nota_chamado')}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label htmlFor="nota_chamado">Adicionar Nota Interna Maminfo</label>
                                    <textarea name="nota_chamado" id="nota_chamado" cols="30" rows="10" className="form-control" placeholder="..." value={formData?.nota_chamado || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-6 mt-1'>
                                <button type='submit' className='btn btn-secondary btn-block' disabled={!formData?.nota_chamado?.length}>Adicionar Nota Interna</button>
                            </div>
                        </div>
                    </form>
                </div>
            </If>
            <If condition={checkPermission(pagePermissions[3])}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Maminfo</label>
                        <textarea className='form-control' rows={10} readOnly value={historico}></textarea>
                    </div>
                </div>
            </If>
            <If condition={checkPermission(pagePermissions[4])}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico {userData.userDataState.id_contrato !== '' ? '' : 'Notas Externas Cliente'}</label>
                        <textarea className='form-control' rows={10} readOnly value={historicoExternoCliente}></textarea>
                    </div>
                </div>
            </If>
            <If condition={checkPermission(pagePermissions[5])}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Parcerias</label>
                        <textarea className='form-control' rows={10} readOnly value={historicoParcerias}></textarea>
                    </div>
                </div>
            </If>
            <If condition={checkPermission(pagePermissions[6]) && ["Fechado"].includes(originalData?.status_chamado)}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Backlog</label>
                        <textarea className='form-control' rows={10} readOnly value={historicoBacklog}></textarea>
                    </div>
                </div>
            </If>
            <If condition={historicoTecnico !== "" && checkPermission(pagePermissions[7])}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Técnicos</label>
                        <textarea cols="30" rows="10" className="form-control" readOnly value={historicoTecnico}></textarea>
                    </div>
                </div>
            </If>
            <If condition={imgArray.length > 0 && checkPermission(pagePermissions[8])}>
                <div className="col-12">
                    <label htmlFor="">Imagens</label>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div id="imgCarousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    {imgArray}
                                </div>
                                <button type="button" className="carousel-control-prev" data-target="#imgCarousel" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </button>
                                <button type="button" className="carousel-control-next" data-target="#imgCarousel" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12" id="slider-thumbs">
                            <ul className='list-inline'>
                                {imgThumbArray}
                            </ul>
                        </div>
                    </div>
                </div>
            </If>
        </div>
    </Card>);
}