import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";

export default function FieldOptionsForm({type = 'create', formData = {}, setFormData = null, submitForm = null}){
    return (
        <Form onSubmit={submitForm}>
            <Row className="mb-1">
                <Col sm='3'>
                    <FormLabel htmlFor="id_modulo">ID Módulo</FormLabel>
                    <FormControl type="text" name="id_modulo" id="id_modulo" placeholder="ID Módulo" value={formData?.id_modulo || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} required></FormControl>
                </Col>
                <Col sm='3'>
                    <FormLabel htmlFor="id_campo">ID Campo</FormLabel>
                    <FormControl type="text" name="id_campo" id="id_campo" placeholder="ID Campo" value={formData?.id_campo || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} required></FormControl>
                </Col>
                <Col sm='3'>
                    <FormLabel htmlFor="valor_opcao">Valor Opção</FormLabel>
                    <FormControl type="text" name="valor_opcao" id="valor_opcao" placeholder="Valor Opção" value={formData?.valor_opcao || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} required></FormControl>
                </Col>
                <Col sm='3'>
                    <FormLabel htmlFor="chave_opcao">Chave Opção</FormLabel>
                    <FormControl type="text" name="chave_opcao" id="chave_opcao" placeholder="Chave Opção" value={formData?.chave_opcao || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" type="submit">{type === 'create' ? 'Cadastrar' : 'Salvar'}</Button>
                </Col>
            </Row>
        </Form>
    )
}