import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getPartnerUnassignedTickets, getTickets, getUnassignedTickets } from '../../services/Ticket';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast, getCurrentDate } from '../../configs/GenericFunctions';
import { Col, FormLabel } from 'react-bootstrap';

export default function ChamadosSelect({ formData, setFormData, update = false, setUpdate = null, fieldName = 'chamados', title = 'Chamados', type = '', cols = [12, 12, 12, 12], isMulti = false, isDisabled = false, required = false, data_inicial = '', data_final = '', today_field = 'responsavel_atendimento' }) {
    const [ticketsOptions, setTicketsOptions] = useState([]);

    async function getTicketsData() {
        let params = {
            data_inicial: data_inicial || getCurrentDate(0, -1, 0),
            data_final: data_final || getCurrentDate(),
            page: 1,
            per_page: 300
        }

        const toastTicket = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getTickets(params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Pesquisa realizada com sucesso!', 'update', toastTicket);
            let tickets = dataReturn?.data?.data?.tickets?.map((item, idx) => {
                return { value: item.id, label: `${item.num_chamado} - ${item.status_chamado}` }
            }) || [];
            setTicketsOptions(tickets);
        } else {
            handleErrorsToast('Ocorreu um erro ao pesquisar os dados!', dataReturn?.data, 'update', toastTicket);
            setTicketsOptions([]);
        }
    }

    async function getUnassignedTicketsOptions() {
        let dataReturn = await getUnassignedTickets();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data) {
            let unassignedData = dataReturn?.data?.data;
            let unassignedArray = unassignedData.map((item, idx) => {
                return ({ value: item.id, label: `${item.chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.customer?.nome_cliente || ''}|${item?.integrator?.nome || ''}|${item?.contract?.contrato || ''}|` })
            });
            setTicketsOptions(unassignedArray);
        } else {
            setTicketsOptions([]);
        }
    }

    async function getPartnerUnassignedTicketsOptions() {
        let dataReturn = await getPartnerUnassignedTickets();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data) {
            let unassignedData = dataReturn?.data?.data;
            let unassignedArray = unassignedData.map((item, idx) => {
                return ({ value: item.id, label: `${item.chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.customer?.nome_cliente || ''}|${item?.integrator?.nome || ''}|${item?.contract?.contrato || ''}|` })
            });
            setTicketsOptions(unassignedArray);
        } else {
            setTicketsOptions([]);
        }
    }

    async function getTodayTickets() {
        let dataReturn = await getTickets({page: 1, per_page: 200});
        if (dataReturn?.data?.response === 'success') {
            let tickets = dataReturn?.data?.data?.tickets.map((item, idx) => {
                return ({ value: item.id, label: `${item.num_chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.cliente || ''}|${item?.integradora || ''}|${item?.contrato || ''}|${item?.[today_field]}` }) || [];
            })
            setTicketsOptions(tickets);
        } else {
            setTicketsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    function getData() {
        switch (type) {
            case "unassigned":
                getUnassignedTicketsOptions();
                break;
            case "partner unassigned":
                getPartnerUnassignedTicketsOptions();
                break;
            case "today":
                getTodayTickets();
                break;
            default:
                getTicketsData();
                break;
        }
    }

    useEffect(() => {
        if (update){
            getData();
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={ticketsOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}