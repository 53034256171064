import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createPermission } from '../../../services/DevMode';
import ModulosSelect from '../../../common/Selects/ModulosSelect';
import SubmodulosSelect from '../../../common/Selects/SubmodulosSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreatePermissions({
    isOpen = false,
    setIsOpen = null,
}) {
    const INITIAL_STATE = {
        nome: '',
        descricao: '',
        id_modulo: '',
        id_submodulo: '',
        slug: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        let toastData = toast.loading("Cadastrando Permissão, aguarde...");

        let params = {
            ...formData,
            id_submodulo: formData?.id_submodulo?.value || ''
        }

        let dataReturn = await createPermission(params);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast("Permissão adicionada com sucesso!", 'update', toastData);
            setFormData(prev => ({...prev, nome: '', descricao: '', slug: ''}));
        }else if(dataReturn?.data?.message === 'The slug has already been taken.'){
            handleErrorsToast('Essa permissão já existe!', dataReturn?.data, 'update', toastData);
        }else{
            handleErrorsToast('Ocorreu um erro ao adicionar o Permissão!', dataReturn?.data, 'update', toastData);
        }
    }

    const handleModules = (value, name) => {
        setFormData(prev => ({ ...prev, [name]: value, id_submodulo: '' }));
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Permissões'
            maximizable
        >
            <form onSubmit={submitForm}>
                <div className='row'>
                    <ModulosSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} required advancedSetFormData={handleModules}></ModulosSelect>
                    <SubmodulosSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} id_modulo={formData?.id_modulo?.value || ''} required></SubmodulosSelect>
                    <div className='col-sm-12 col-md-4 mb-1'>
                        <label htmlFor="nome">Nome da Permissão</label>
                        <input type='text' name='nome' className='form-control' placeholder='Nome da Permissão' value={formData?.nome || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-sm-12 col-md-4 mb-1'>
                        <label htmlFor="descricao">Descrição da Permissão</label>
                        <input type='text' name='descricao' className='form-control' placeholder='Descrição' value={formData?.descricao || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-sm-12 col-md-4 mb-1'>
                        <label htmlFor="slug">Slug</label>
                        <input type='text' name='slug' className='form-control' placeholder='Slug' value={formData?.slug || ''} onChange={(e) => setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} required></input>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}