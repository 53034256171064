import React, { useEffect, useState } from 'react';
import TechniciansChartContent from '../Components/TechniciansChartContent';
import StatesTable from './StatesTable';
import { Link, useParams } from 'react-router-dom';
import { getCurrentDate, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { countAvailableTechniciansByCity } from '../../../../services/Technician';
import { toast } from 'react-toastify';
import StatesPieChart from './StatesPieChart';

export default function TechniciansByStates(){
    const { estado } = useParams();
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);
    let interval;
    const milliseconds = 600000; //10 minutos

    useEffect(()=>{
        async function getStatesData(){
            const toastData = toast.loading('Carregando dados aguarde...');

            let data_agendamento = getCurrentDate();
            let dataReturn = await countAvailableTechniciansByCity(data_agendamento, estado);

            if(dataReturn?.status === 200){
                let stateData = dataReturn?.data?.data?.quantidade_tecnicos_por_cidade;

                let dataToTable = stateData?.map((item)=>({pais: 'Brasil', estado: estado, cidade: item?.cidade_end, qtd_tecnicos: item?.total_tecnicos || 0}));
                let dataToChart = stateData?.map((item)=>({x: item?.cidade_end, estado: estado, value: item?.total_tecnicos || 0, url: `/chamados/capilaridade-tecnicos/tecnicos/${estado}/${item?.cidade_end}`}));
                setData(dataToTable);
                setChartData(dataToChart);
                handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            }else{
                handleErrorsToast('Ocorreu um erro:', dataReturn?.data, 'update', toastData);
            }
        }

        getStatesData();
        
        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getStatesData();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };
    }, [estado]);
    return (
        <TechniciansChartContent>
            <div className="row">
                <div className="col-12">
                   <Link to={'/chamados/capilaridade-tecnicos'} role='button' className='btn btn-warning'>Ver todos os Estados</Link>
                </div>
                <div className="col-12 text-center">
                    <h2>Distribuição de Técnicos por Estado - {estado}</h2>
                    <p>Passe com o mouse por cima do gráfico para ver a cidade e valores.</p>
                    <p>Clique nos campos da tabela para filtrar mais dados.</p>
                </div>
                <div className="col-12">
                    <StatesPieChart data={chartData}></StatesPieChart>
                </div>
                <div className="col-12">
                    <StatesTable data={data}></StatesTable>
                </div>
            </div>
        </TechniciansChartContent>
    );
}