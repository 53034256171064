import React, { useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { Link } from 'react-router-dom';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { getAbseenteismByDataRange } from '../../../../services/Employee';
import { toast } from 'react-toastify';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { handleErrorsToast, handleSuccessToast, getCurrentDate } from '../../../../configs/GenericFunctions';

export default function ShowAbsenteeism() {

    const initialState = {
        data_inicial: getCurrentDate(),
        data_final: getCurrentDate()
    }

    const [filterData, setFilterData] = useState(initialState);
    const [data, setData] = useState([]);
    const columns = [
        {
            id: 'nome_absenteismo',
            name: 'Nome',
            body: (row) => (<Link to={`/cadastros/tecnicos/detalhes/${row.id_tecnico_absenteismo}`} target='_blank'>{row.nome_absenteismo}</Link>)
        },
        {
            id: 'tipo_tecnico_absenteismo',
            name: 'Tipo Técnico'
        },
        {
            id: 'tipo_afastamento_absenteismo',
            name: 'Tipo Afastamento'
        },
        {
            id: 'data_inicio_absenteismo',
            name: 'Data Inicial'
        },
        {
            id: 'data_final_absenteismo',
            name: 'Data Final'
        },
        {
            id: 'action_indicadores_absenteismo',
            name: 'Ação',
            body: (row) => (
                <Link to={`/dashboard/tecnicos/absenteismo/detalhes/${row.id_tecnico_absenteismo}`} className='btn btn-primary'>Detalhes Técnico</Link>
            )
        },
    ]

    async function submitForm(e) {
        e.preventDefault();

        let formData = { ...filterData }

        const toastAbsenteeism = toast.loading("Buscando dados, aguarde...");

        let dataReturn = await getAbseenteismByDataRange(formData);

        if (dataReturn?.data?.length > 0) {
            handleSuccessToast('Dados encontrados!', 'update', toastAbsenteeism);
            let data = dataReturn?.data.map((item) => {
                return ({
                    id_tecnico_absenteismo: item?.id_tecnico || '',
                    nome_absenteismo: item?.technician?.nome_tec || '',
                    tipo_tecnico_absenteismo: `${item?.technician?.categoria || ''} - ${item?.technician?.modal_contratacao || ''}`,
                    tipo_afastamento_absenteismo: item?.tipo_afastamento || '',
                    data_inicio_absenteismo: item?.data_inicial || '',
                    data_final_absenteismo: item?.data_final || ''
                });
            });

            setData(data);
        } else {
            handleErrorsToast('Sem dados!', dataReturn?.data, 'update', toastAbsenteeism);
            setData([]);
        }
    }

    return (<Content headerTitle='Absenteísmo - Técnicos'>
        <SubHeaderSpecific subHeaderConfig='dashboard'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-md-12 col-lg-5">
                        <label htmlFor="data_inicial">Data Inicial</label>
                        <input type="date" name="data_inicial" id="data_inicial" className='form-control' value={filterData?.data_inicial} onChange={(e) => setFilterData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <label htmlFor="data_final">Data Final</label>
                        <input type="date" name="data_final" id="data_final" className='form-control' value={filterData?.data_final} onChange={(e) => setFilterData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-12 col-lg-2 d-flex">
                        <button type="submit" className='btn btn-primary btn-block align-self-end mt-1'>Filtrar</button>
                    </div>
                </div>
            </form>
        </Card>
        <Card hideCardHeader={true}>
            <PrimeTable
                columns={columns}
                rows={data}
                id='show_abseenteism'
            ></PrimeTable>
        </Card>
    </Content>);
}