import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import ContratosMultiSelect from '../../../common/Selects/ContratosMultiSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { updatePartnerGroup, getGroupDetailsById, removeContractFromPartnerGroup } from '../../../services/PartnerGroup';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function PartnerGroupDetails() {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialState = {
        id_usuario: '',
        contratos: '',
        nome: '',
        descricao: ''
    }

    const [formData, setFormData] = useState(initialState);
    const [contractsTableData, setContractTableData] = useState([]);

    const columns = [
        {
            id: 'contratos_grupo_parceria',
            name: 'Contratos',
        },
        {
            id: 'action_grupo_parceria',
            name: 'Ação',
            body: (row) => (
                <div>
                    <button type="button" className='btn btn-warning m-1 d-inline' onClick={() => showDeleteContractSwal(row.id_contrato_grupo_parceria)}><i className='fas fa-trash'></i></button>
                </div>
            ),
        }
    ]

    async function submitForm(e) {
        e.preventDefault();

        let contratos = [];

        if(formData?.contratos?.length > 0){
            contratos = formData?.contratos?.filter(item => item.value !== 'todos').map((item, idx) => {
                return item.value;
            }) || [];
        }

        let params = {
            ...formData,
            contratos: contratos,
            id_usuario: formData?.id_usuario?.value || '',
            nome: formData?.id_usuario?.label || ''
        }
        const toastSubmit = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updatePartnerGroup(id, params);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Grupo de parceria atualizado com sucesso!', 'update', toastSubmit);
            getGroupDetails();
            setFormData(prevState => ({...prevState, contratos: ''}));
        } else if (dataReturn?.data?.message === 'The id usuario has already been taken.') {
            handleErrorsToast('Este usuário já possui um grupo de parceria!', dataReturn?.data, 'update', toastSubmit);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o grupo de parceria!', dataReturn?.data, 'update', toastSubmit);
        }
    }

    async function getGroupDetails() {
        const toastDetails = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getGroupDetailsById(id);

        if (dataReturn?.data?.response === 'success') {
            let dados = dataReturn?.data?.data;
            let contratos = dataReturn?.data?.data?.contracts?.map((item, idx) => {
                return ({
                    id_contrato_grupo_parceria: item?.id,
                    contratos_grupo_parceria: item?.contrato
                });
            }) || [];
            handleSuccessToast("Dados encontrados!", 'update', toastDetails);
            setFormData(prevState => ({
                ...prevState,
                id_usuario: { value: dados?.id_usuario || '', label: dados?.nome_grupo || '' },
                descricao: dados?.descricao || ''
            }));

            if (contratos?.length > 0) {
                setContractTableData(contratos);
            } else {
                setContractTableData([]);
            }

        } else if (dataReturn?.data?.message === 'Group not found!') {
            handleErrorsToast('Este registro não existe!', dataReturn?.data, 'update', toastDetails);
            navigate('/chamados/grupos-parceria/dados');
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar o grupo de parceria!', dataReturn?.data, 'update', toastDetails);
        }
    }

    async function showDeleteContractSwal(id) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente excluir este contrato?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            deleteContract(id);
        }
    }

    async function deleteContract(idContrato) {

        let formData = {
            contratos: []
        }

        formData.contratos.push(idContrato);

        const toastUsers = toast.loading("Excluindo contrato, aguarde...");

        let dataReturn = await removeContractFromPartnerGroup(id, formData);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast('Contrato excluido com sucesso!', 'update', toastUsers);
            getGroupDetails();
        }else{
            handleErrorsToast('Ocorreu um erro ao excluir o contrato!', dataReturn?.data, 'update', toastUsers);
        }
    }

    useEffect(() => {
        getGroupDetails();
    }, []);

    return (<Content headerTitle='Detalhes do Grupo de Parcerias'>
        <SubHeaderSpecific subHeaderConfig='grupos_parcerias'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Integradora/Contrato</h5>
                </div>
                <UsuariosAtivosSelect formData={formData} setFormData={setFormData} title='Responsável Parceria' fieldName='id_usuario' cols={[12, 12, 6, 6]} required={true}></UsuariosAtivosSelect>
                <ContratosMultiSelect formData={formData} setFormData={setFormData} fieldName='contratos' cols={[12, 12, 6, 6]}></ContratosMultiSelect>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Contratos do Grupo de Contrato</h5>
                </div>
                <div className="col-12">
                    <PrimeTable 
                        columns={columns}
                        rows={contractsTableData}
                        id='contratos_table'
                    ></PrimeTable>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Detalhamento</h5>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="descricao">Observações</label>
                        <textarea rows={5} className="form-control" name="descricao" id="descricao" placeholder='...' value={formData.descricao} onChange={e => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button type="submit" className='btn btn-primary'>Salvar</button>
                </div>
            </div>
            </form>
        </Card>
    </Content>)
}