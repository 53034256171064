import React from "react";
import Content from "../../common/Layout/Content";

function Main() {
    return (
        <Content headerTitle="Bem-vindo ao Maminfo ERP">
            <p>Selecione um módulo ao lado</p>
        </Content>
    );
}

export default Main;