import { Button, Col, DropdownButton, DropdownItem, Row } from "react-bootstrap";
import Card from "../../../common/Layout/Card";
import Content from "../../../common/Layout/Content";
import PrimeTable from "../../../common/Layout/Table/PrimeTable";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import If from "../../../common/Layout/If";
import Swal from "sweetalert2";
import { activeDeactiveOption, getAllOptions } from "../../../services/Options";
import CreateFieldOption from "./CreateFieldOption";
import UpdateFieldOption from "./UpdateFieldOption";

export default function FieldOptionsManager() {
    const columns = [
        {
            id: 'action',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <DropdownItem onClick={() => openUpdateTagModal(row)}>Atualizar</DropdownItem>
                    <DropdownItem onClick={() => showRemoveOptionSwal(row)}>{row.status === 1 ? 'Desativar' : 'Ativar'}</DropdownItem>
                </DropdownButton>
            )
        },
        {
            id: 'id_modulo',
            name: 'Id Modulo'
        },
        {
            id: 'id_campo',
            name: 'Id Campo'
        },
        {
            id: 'valor_opcao',
            name: 'Valor Opção'
        },
        {
            id: 'chave_opcao',
            name: 'Chave Opção'
        },
        {
            id: 'status',
            name: 'Status',
            body: (row) => (row?.status === 1 ? 'Ativo' : 'Inativo')
        },
        {
            id: 'created_at',
            name: 'Criado em'
        }
    ];

    const [data, setData] = useState([]);
    const [openCreateField, setOpenCreateField] = useState(false);
    const [openUpdateField, setOpenUpdateField] = useState(false);
    const [updateId, setUpdateId] = useState('');

    async function getFieldOptions() {
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllOptions();

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            let tags = dataReturn?.data?.data;
            setData(tags);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastData);
            setData([]);
        }
    }

    async function showRemoveOptionSwal(row) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo remover esta opção?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            confirmButtonColor: 'red'
        });

        if (swal.isConfirmed) {
            removeOptionsData(row);
        }
    }

    async function removeOptionsData(row) {
        const toastData = toast.loading("Alterando status da opção, aguarde...");

        let dataReturn = await activeDeactiveOption(row.id, {
            status: row.status === 1 ? 0 : 1
        });

        if (dataReturn?.status === 200) {
            handleSuccessToast('Opção alterada com sucesso', 'update', toastData);
            getFieldOptions();
        } else {
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }

    function openUpdateTagModal(row) {
        setUpdateId(row.id);
        setOpenUpdateField(true);
    }

    useEffect(() => {
        getFieldOptions();
    }, []);

    return (
        <Content headerTitle="Gerenciar Campos">
            <If condition={openCreateField}>
                <CreateFieldOption isOpen={openCreateField} setIsOpen={() => setOpenCreateField(!openCreateField)} updateParent={getFieldOptions}></CreateFieldOption>
            </If>
            <If condition={openUpdateField}>
                <UpdateFieldOption isOpen={openUpdateField} setIsOpen={() => setOpenUpdateField(!openUpdateField)} updateParent={getFieldOptions} updateId={updateId}></UpdateFieldOption>
            </If>
            <SubHeaderSpecific subHeaderConfig='admin'></SubHeaderSpecific>
            <Card hideCardHeader>
                <Row>
                    <Col>
                        <Button variant='primary' type="button" onClick={() => setOpenCreateField(!openCreateField)}>Cadastrar Campo</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='fields_options_table'
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    );
}