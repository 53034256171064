import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { addNoteByTicketId } from '../../../../services/Ticket';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function AdicionarNotaParceriasModal({ isOpen = false, setIsOpen = null, id = '', originalData = {}, updateParent = null }) {
    const INITIAL_STATE = {
        nota_parcerias: '',
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    async function addNote(e, type, field) {
        e.preventDefault();

        let params = {
            descricao: formData?.[field],
            tipo: type,
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading('Adicionando Nota, aguarde...');

        let dataReturn = await addNoteByTicketId(id, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Nota adicionada com sucesso!", 'update', toastNote);
            updateParent();
            setFormData(prev => ({ ...prev, [field]: '' }));
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar a nota!', dataReturn?.data, 'update', toastNote);
        }
    }

    return (<Dialog
        header='Adicionar Nota Parcerias'
        visible={isOpen}
        onHide={setIsOpen}
        maximizable={true}
    >
        <form onSubmit={(e) => addNote(e, 'parceria', 'nota_parcerias')}>
            <div className='row'>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="nota_parcerias">Adicionar Nota Parcerias</label>
                        <textarea name="nota_parcerias" id="nota_parcerias" placeholder="..." rows={10} className="form-control" value={formData?.nota_parcerias || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                </div>
                <div className="col-12">
                    <button type='submit' className="btn btn-secondary" disabled={!formData?.nota_parcerias?.length}>Adicionar Nota Parcerias</button>
                </div>
            </div>
        </form>
    </Dialog>)
}