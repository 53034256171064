import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllContractsByIntegrator, getContractsByStatus } from '../../services/Contracts';
import { Col, FormLabel } from 'react-bootstrap';

export default function ContratosSelect({ formData, setFormData, fieldName = 'contrato', cols = [12, 12, 12, 12], isDisabled = false, required = false, dependsOnIntegradora = false, advancedSetFormData = null, id_integradora_string = '', selectAllContracts = true, fieldTitle = 'Contratos' }) {
    const [contractOptions, setContractOptions] = useState([]);

    async function getContractsByIdIntegrator() {
        let idIntegradoras = id_integradora_string.split(",");
        if (idIntegradoras.length > 0 && !idIntegradoras.includes(undefined) && !idIntegradoras.includes('')) {
            let dadosIntegradoras = [];
            for (let id of idIntegradoras) {
                let dadosIntegradora = await getContractsByIntegrator(id);

                dadosIntegradoras = [...dadosIntegradoras, ...dadosIntegradora];
            }
            setContractOptions(dadosIntegradoras);
        } else {
            setContractOptions([]);
        }
    }

    async function getContractsByIntegrator(id) {
        let dataReturn = await getAllContractsByIntegrator(id);
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let contracts = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.contrato }
            });
            return contracts;
        } else {
            return [];
        }
    }

    async function getAllContracts() {

        let dadosIntegradora = await getContractsByStatus(1);

        if (dadosIntegradora?.data?.response === 'success') {
            let contracts = dadosIntegradora.data.data.map((item, idx) => {
                return { value: item.id, label: `${item.integrator.nome} - ${item.contrato}` }
            });

            let selectAllOptions = selectAllContracts ? [{ value: 'todos', label: 'Selecionar Todos' }, ...contracts] : contracts;

            setContractOptions(selectAllOptions);
        } else {
            setContractOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData === null) {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        } else {
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        if (dependsOnIntegradora) {
            getContractsByIdIntegrator();
        } else {
            getAllContracts();
        }
    }, [id_integradora_string, fieldName]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{fieldTitle}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={contractOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}