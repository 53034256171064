import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { generatePasswordByTicketId } from '../../../../services/Ticket';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function CreateSenhaAtendimento({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    detailsData = {},
    id = ''
}) {
    const initialGeneratePasswordState = {
        descricao_atividade: ''
    }

    const [generatePasswordForm, setGeneratePasswordForm] = useState(initialGeneratePasswordState);

    async function generatePassword(e) {
        e.preventDefault()

        let formData = {
            descricao_atividade: generatePasswordForm?.descricao_atividade,
            status_chamado: detailsData?.status_chamado?.value
        }

        const toastPassword = toast.loading("Gerando Senha, aguarde...");

        let dataReturn = await generatePasswordByTicketId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            let message = dataReturn?.data?.data?.message || 'Senha gerada com sucesso!';
            handleSuccessToast(message, 'update', toastPassword);
            updateParent();
            setIsOpen(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao gerar a senha!', dataReturn?.data, 'update', toastPassword);
        }

        setGeneratePasswordForm(initialGeneratePasswordState);
    }

    useEffect(() => {
        setGeneratePasswordForm(initialGeneratePasswordState);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Senha de Atendimento'
            maximizable={true}
        >
            <form onSubmit={generatePassword}>
                <div className='row'>
                    <div className='col-12 mb-1'>
                        <input type='text' className='form-control' name='descricao_atividade' placeholder='Descrição da Atividade relacionada à senha gerada' value={generatePasswordForm?.descricao_atividade || ''} onChange={(e) => setGeneratePasswordForm(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required></input>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='btn btn-secondary w-100'>Gerar e Salvar Senha Atendimento</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}