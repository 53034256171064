import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { addNoteByTicketId } from '../../../../services/Ticket';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { updateTickets, updateTicketStatus } from '../../../../services/IntegracaoJira';

export default function AdicionarNotaExternaModal({ isOpen = false, setIsOpen = null, id = '', originalData = {}, updateParent = null }) {
    const INITIAL_STATE = {
        nota_externa_cliente: '',
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    async function addNote(e, type, field){
        e.preventDefault();

        let params = {
            descricao: formData?.[field],
            tipo: type,
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading('Adicionando Nota, aguarde...');

        let dataReturn = await addNoteByTicketId(id, params);

        if(dataReturn?.data?.response === 'success'){
            // let data = dataReturn?.data;
            handleSuccessToast('Nota adicionada com sucesso!', 'update', toastNote);
            updateParent();
            setFormData(prev => ({...prev, [field]: ''}));

            // if(data?.key_ticket_cliente){
            //     //Aqui vão a chamada da api do Jira
            //     updateTickets();
            //     updateTicketStatus({id: data?.id});
            // }
        }else{
            handleErrorsToast("Ocorreu um erro ao adicionar a nota!", dataReturn?.data, 'update', toastNote);
        }
    }

    return (<Dialog
        header='Adicionar Nota Externa'
        visible={isOpen}
        onHide={setIsOpen}
        maximizable={true}
    >
            <form onSubmit={(e) => addNote(e, 'externa', 'nota_externa_cliente')}>
                <div className='row'>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="nota_externa_cliente">Adicionar Nota Externa Cliente</label>
                            <textarea name="nota_externa_cliente" id="nota_externa_cliente" placeholder="..." rows={10} className="form-control" value={formData?.nota_externa_cliente || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type='submit' className="btn btn-secondary" disabled={!formData?.nota_externa_cliente?.length}>Adicionar Nota Externa</button>
                    </div>
                </div>
            </form>
    </Dialog>)
}