import React, { useState } from 'react';
import usePermissions from '../../../../../hooks/usePermissions';
import If from '../../../../../common/Layout/If';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteSpecialPartnerValue } from '../../../../../services/SpecialPartnerValues';
import SpecialPartnerDetails from './SpecialPartnerDetails';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, openNewTab } from '../../../../../configs/GenericFunctions';

export default function SpecialPartnerTable({ specialData = [], updateParent = null }) {
    const { checkPermission, checkAllPermissions } = usePermissions();
    const [specialId, setSpecialId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { action: actionPermissions } = getSubComponentsPermissions('mam_cadastros', 'PartnerDetails', 'SpecialPartnerTable');

    const columns = [
        {
            id: 'action_valores_especiais',
            name: 'Ação',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        <If condition={(checkPermission(actionPermissions[0])) && ['reprovado', 'pendente'].includes(row.status_aprovacao_valores_especiais)}>
                            <button type="button" className='dropdown-item' onClick={() => openSpecialPartnerDetails(row.id_valores_especiais)}>Aprovar</button>
                        </If>
                        <If condition={row.status_aprovacao_valores_especiais === 'aprovado' && checkPermission(actionPermissions[1])}>
                            <button type="button" className='dropdown-item' onClick={() => openNewTab(`/chamados/detalhes-chamado/${row.id_chamado}`)}>Abrir Chamado</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[2])}>
                            <button type="button" className='dropdown-item' onClick={() => showDeleteSpecialValueSwal(row.id_valores_especiais)}>Excluir</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'status_aprovacao_valores_especiais',
            name: 'Status Aprovação',
        },
        {
            id: 'num_chamado_valores_especiais',
            name: 'Nº Chamado',
        },
        {
            id: 'contrato_valores_especiais',
            name: 'Contrato',
        },
        {
            id: 'escopo_valores_especiais',
            name: 'Escopo',
        },
        {
            id: 'valor_chamado_valores_especiais',
            name: 'R$ Chamado',
        },
        {
            id: 'valor_fora_valores_especiais',
            name: 'Valor Fora Hrs Comercial',
        },
        {
            id: 'chamado_improdutivo_valores_especiais',
            name: 'Chamado Improdutivo',
        },
        {
            id: 'h_a_diurna_valores_especiais',
            name: 'H.A (Diurna) Após 3h31m',
        },
        {
            id: 'h_a_noturna_valores_especiais',
            name: 'H.A (Noturna) Após 3h31m',
        },
        {
            id: 'valor_apos_51_km_valores_especiais',
            name: 'R$ KM Após 51 Km',
        },
        {
            id: 'motivo_valores_especiais',
            name: 'Motivo Valores Especiais',
        },
        {
            id: 'motivo_reprovacao_valores_especiais',
            name: 'Motivo Reprovação',
        },
        {
            id: 'usuario_criador_contrato',
            name: 'Usuário Criador'
        },
        {
            id: 'usuario_aprovador_contrato',
            name: 'Usuário Aprovador'
        }
    ];

    async function showDeleteSpecialValueSwal(id) {
        let mySwal = await Swal.fire({
            titleText: 'Deseja realmente excluir esse valor especial?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (mySwal.isConfirmed) {
            deleteValue(id);
        }
    }

    async function deleteValue(id) {
        const toastValues = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deleteSpecialPartnerValue(id);

        if (dataReturn?.status === 204) {
            handleSuccessToast('Dados excluidos com sucesso!', 'update', toastValues);
            updateParent();
        } else {
            handleErrorsToast('Ocorreu um erro ao excluir os dados!', dataReturn?.data, 'update', toastValues);
        }
    }

    function openSpecialPartnerDetails(id) {
        setIsOpen(true)
        setSpecialId(id);
    }

    return (
        <div className='row'>
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Valores Especiais</h5>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={columns}
                    rows={specialData}
                    id='special_partner_table'
                ></PrimeTable>
            </div>
            <SpecialPartnerDetails id_valor_especial={specialId} updateParent={updateParent} isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)}></SpecialPartnerDetails>
        </div>
    );
}