import React from 'react';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { Link } from 'react-router-dom';

export default function StateTable({data = []}) {
    const columns = [
        {
            id: 'pais',
            name: 'País',
            body: (row) => (<Link to={'/chamados/capilaridade-tecnicos'}>{row?.pais}</Link>)
        },
        {
            id: 'estado',
            name: 'Estado'
        },
        {
            id: 'cidade',
            name: 'Cidade',
            body: (row) => (<Link to={`/chamados/capilaridade-tecnicos/tecnicos/${row?.estado}/${row?.cidade}`}>{row?.cidade}</Link>)
        },
        {
            id: 'qtd_tecnicos',
            name: 'Quantidade Técnicos',
            body: (row) => (<Link to={`/chamados/capilaridade-tecnicos/tecnicos/${row?.estado}/${row?.cidade || 'all'}`}>{row?.qtd_tecnicos}</Link>)
        },
    ];

    return (<PrimeTable
        columns={columns}
        rows={data}
        id='states_table'
    ></PrimeTable>)
}