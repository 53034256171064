import React, { useContext, useEffect, useState } from 'react';
import styles from './ChannelsChat.module.css';
import { Context } from '../../App';
import { createChannel, getChannelsByTicketId, getChannelsListByUserId } from '../../services/Chat';
import Contacts from './Contacts';
import ChannelData from './ChannelData';
import { toast } from 'react-toastify';
import SearchField from './SearchField';
import echo from '../../services/LaravelEcho';
import { ChatContext } from './MainChatComponent';
import useChat from '../../hooks/useChat';
import { getTicketById } from '../../services/Ticket';
import usePermissions from '../../hooks/usePermissions';
import If from '../Layout/If';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';
import { Button, Card, CardBody, CardHeader, Col, DropdownButton, DropdownItem, ListGroup, ListGroupItem, Row } from 'react-bootstrap';

export default function ChannelsChat({ style = {}, openChat }) {
    const initialState = {
        type: '',
        users: [],
        ticket_id: null,
        channel_description: '',
        channel_name: ''
    }

    const userData = useContext(Context);
    const { chatState, setChatState } = useContext(ChatContext);
    const { getChannelName, getChannelImage } = useChat();
    const [channelArray, setChannelArray] = useState([]);
    const [filteredChannelArray, setFilteredChannelArray] = useState([]);
    const [pendingMessages, setPendingMessages] = useState({ channel: 0, livechat: 0 });
    const [typeChannel, setTypeChannel] = useState("channel");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChannelDataModalOpen, setIsChannelDataModalOpen] = useState(false);
    const [newChannelFormData, setNewChannelFormData] = useState(initialState);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { page: pagePermissions, create: createPermissions } = getSubComponentsPermissions('mam_talks', 'MainChat', 'ChannelsChat');
    const STYLE = {
        ...style
    }

    const createNewChannel = async (formData) => {
        const toastCreateChannel = toast.loading("Criando canal, aguarde...");

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Canal criado com sucesso!', 'update', toastCreateChannel);
            setTypeChannel(dataReturn?.data?.data?.type);
            openChat();
            setChatState(prevState => (
                {
                    ...prevState,
                    channelId: dataReturn?.data?.data?._id
                }
            ))
        } else {
            handleErrorsToast('Ocorreu um erro ao criar o canal', dataReturn?.data, 'update', toastCreateChannel);
        }
    }

    const createNewOfficialChannel = async () => {
        let ticketData = await getTicketById(chatState?.ticketId);

        if (ticketData?.data?.response === 'success') {
            let ticket = ticketData?.data?.data;

            if (ticket?.ticket_responsible_user?.id !== userData?.userDataState?.id_usuario) {
                return;
            }

            let formData = {
                channel_name: 'OFICIAL',
                channel_description: 'Canal Oficial do Chamado',
                type: 'channel',
                ticket_id: +chatState?.ticketId,
                users: [userData?.userDataState?.id_usuario]
            }

            if (ticket?.technician?.id_usuario) {
                formData.users.push(ticket?.technician?.id_usuario)
            }

            await createNewChannel(formData);
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os dados do chamado!', ticketData?.data);
        }
    }

    const getChannels = async () => {
        let channels;
        if (chatState?.ticketId === '') {
            channels = await getChannelsListByUserId();
        } else {
            channels = await getChannelsByTicketId(chatState?.ticketId);
        }

        let countObj = {
            channel: 0,
            livechat: 0
        }

        if (channels?.data?.response === 'success') {
            let channelsData = channels?.data?.data.map((item, idx) => {
                if (item.type === 'channel') {
                    countObj.channel += item.pending_message_count;
                }

                if (item.type === 'livechat') {
                    countObj.livechat += item.pending_message_count;
                }

                return {
                    ...item,
                    channel_name: getChannelName(item)
                };
            })
            setPendingMessages(countObj);
            setChannelArray(channelsData);
            setFilteredChannelArray(channelsData);

            if (channels?.data?.data?.filter(item => (item?.channel_name === 'OFICIAL' && item?.channel_description === 'Canal Oficial do Chamado')).length === 0 && chatState?.ticketId !== '') {
                await createNewOfficialChannel();
                return;
            }
        } else {
            handleErrorsToast('Ocorreu um erro ao procurar os canais!', channels?.data);
        }
    }

    function renderChannelsList() {
        return (filteredChannelArray.filter(item => item.type === typeChannel).map((item, idx) => {

            let usersCount = item.users.length;
            let channelImg = getChannelImage(item.users);

            return (
                <ListGroupItem className={`${chatState?.channelId === item._id ? 'active hover:bg-blue-500 hover:border-blue-500' : ''} ${styles.list_group_item_style}`} aria-current="true" onClick={() => {
                    openChat()
                    setChatState(prevState => {
                        return {
                            ...prevState,
                            channelId: item._id,
                            page: 1
                        }
                    })
                }} key={item._id}>
                    <div className="flex flex-row">
                        <div className={`${styles.list_group_img} ${usersCount < 3 ? '' : 'flex-wrap'}`}>
                            {channelImg}
                        </div>
                        <div className="text-truncate">
                            <span><b>{item.channel_name}</b> {item.pending_message_count > 0 && <span className="badge badge-warning float-right">{item.pending_message_count}</span>}</span>
                        </div>
                    </div>
                </ListGroupItem>)
        }))
    }

    function setCreateChannelType(type) {
        setIsChannelDataModalOpen(!isChannelDataModalOpen);
        setNewChannelFormData(prevState => ({ ...prevState, type: type }));
    }

    function searchChannels(search) {
        let channelsList = channelArray.filter(item => item.channel_name.toLowerCase().includes(search.toLowerCase()));

        setFilteredChannelArray(channelsList);
    }

    async function startCreateChannel(formData) {

        let params = {
            ...newChannelFormData,
            ...formData,
            ticket_id: +formData?.ticket_id?.value || null,
            users: [userData?.userDataState?.id_usuario]
        }

        setNewChannelFormData(prevState => ({
            ...prevState,
            ...params
        }));

        if (newChannelFormData?.type === 'channel') {
            setIsChannelDataModalOpen(!isChannelDataModalOpen);
            setIsModalOpen(!isModalOpen);
        }

        if (newChannelFormData?.type === 'livechat') {
            setIsChannelDataModalOpen(!isChannelDataModalOpen);
            await createNewChannel(params);
        }
    }

    async function appendMembersToChannel(members) {
        if (members?.length === 0) {
            handleErrorsToast('Por favor, selecione pelo menos 1 contato!');
            return;
        }

        let membersId = members.map((item, idx) => {
            return item.id
        })

        let formData = {
            ...newChannelFormData,
            users: [...newChannelFormData.users, ...membersId]
        }

        await createNewChannel(formData);

        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        getChannels();

        const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

        webSocketChannel.listen('ChannelUpdate', (e) => {
            if (e.notification === 'channel_update') {
                getChannels();
            }
        })

        return () => {
            webSocketChannel.stopListening(`ChannelUpdate`);
        }
    }, [userData, typeChannel, chatState?.channelId]);

    return (
        <Card style={STYLE}>
            <CardHeader style={{ height: '60px' }}>
                <Row>
                    <Col xs='4' className="flex justify-content-center">
                        <Button type='button' variant='primary' title='Canais' onClick={() => { setTypeChannel("channel"); setChatState(prevState => ({ ...prevState, channelId: '' })) }}><i className='fas fa-inbox'></i>{pendingMessages.channel > 0 && <span className="badge badge-warning float-right">{pendingMessages.channel}</span>}</Button>
                    </Col>
                    <Col xs='4' className="flex justify-content-center">
                        <If condition={checkPermission(pagePermissions[0])}>
                            <Button variant='warning' type='button' title='Live Chat' onClick={() => { setTypeChannel("livechat"); setChatState(prevState => ({ ...prevState, channelId: '' })) }}><i className='fas fa-comments'></i>{pendingMessages.livechat > 0 && <span className="badge badge-warning float-right">{pendingMessages.livechat}</span>}</Button>
                        </If>
                    </Col>
                    <Col xs='4' className="flex justify-content-center">
                        <If condition={checkAllPermissions(createPermissions)}>
                            <DropdownButton title={
                                <span>
                                    <i className='fas fa-plus'></i>
                                </span>
                            } variant='success'>
                                <If condition={checkPermission(createPermissions[0])}>
                                    <DropdownItem onClick={() => setCreateChannelType('channel')}>Canal</DropdownItem>
                                </If>
                                <If condition={checkPermission(createPermissions[1])}>
                                    <DropdownItem onClick={() => setCreateChannelType('livechat')}>Live Chat</DropdownItem>
                                </If>
                            </DropdownButton>
                        </If>
                    </Col>
                    <Contacts isOpen={isModalOpen} setModalOpen={() => setIsModalOpen(!isModalOpen)} title='Novo Canal' setMembers={appendMembersToChannel}></Contacts>
                    <ChannelData title={'Novo Canal'} isOpen={isChannelDataModalOpen} setModalOpen={() => setIsChannelDataModalOpen(!isChannelDataModalOpen)} changeChannelData={startCreateChannel} ></ChannelData>
                </Row>
            </CardHeader>
            <CardHeader>
                <Row>
                    <Col sm='12'>
                        <SearchField searchFunction={searchChannels}></SearchField>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody style={{ overflowY: 'auto', marginBottom: '2px' }}>
                <Row>
                    <Col sm='12'>
                        <ListGroup>
                            {
                                renderChannelsList()
                            }
                        </ListGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>)
}