import interceptorInstance from "./Interceptor";

export async function getAvailableProducts(id_modelo = '', search = ''){
    try{
        let queryParams = id_modelo !== '' || search !== '' ? '?' : '';

        if(search !== ''){
            queryParams += "search=" + search + "&"
        }
        
        if(id_modelo !== ''){
            queryParams += "id_modelo=" + id_modelo;
        }

        let data = await interceptorInstance.get("product/available" + queryParams);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function setProductStatusByProductId(id, params){
    try{
        let data = await interceptorInstance.post(`product/temporarily-update-status/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getProductByProductId(id){
    try{    
        let data = await interceptorInstance.get(`product/get/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createProduct(params){
    try{
        let data = await interceptorInstance.post(`product/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllProducts(page = 1, perPage = 10, params = ''){
    try{
        let data = await interceptorInstance.get(`product/all?page=${page}&per_page=${perPage}${params}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllProductsParams(page = 1, perPage = 10, params = {}){
    try{
        let data = await interceptorInstance.get(`product/all`, {
            params: {
                page: page,
                per_page: perPage,
                ...params
            }
        });
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getProductHistory(productId){
    try{
        let data = await interceptorInstance.get(`product/search/${productId}/history`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateProductData(productId, params){
    try{
        let data = await interceptorInstance.put(`product/update/${productId}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function duplicateProduct(productId){
    try{
        let data = await interceptorInstance.post(`product/duplicate/${productId}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteProduct(productId){
    try{
        let data = await interceptorInstance.delete(`product/delete/${productId}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function bulkUpdateProducts(params){
    try{
        let data = await interceptorInstance.patch(`product/bulk-update`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getProductsByIdLocalidade(params){
    try{
        let data = await interceptorInstance.get(`product/search/${params}/id-localidade`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getRMAProducts(){
    try{
        let data = await interceptorInstance.get(`product/rma`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getCountRMA(){
    try{
        let data = await interceptorInstance.get(`product/rma/count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getScrapProducts(){
    try{
        let data = await interceptorInstance.get(`product/scrap`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getReversaCount(){
    try{
        let data = await interceptorInstance.get(`product/reversa/count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getProductsReversa(){
    try{
        let data = await interceptorInstance.get(`product/reversa`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function bulkCreate(params){
    try{
        let data = await interceptorInstance.post(`product/bulk-create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function countProductsByStatus(params){
    try{
        let data = await interceptorInstance.get('product/count-by-status', {
            params: {
                ...params
            }
        });

        return data;
    }catch(e){
        return e.response;
    }
}

export async function exportProducts(params){
    try{
        let data = await interceptorInstance.post(`product/export-logistic`, params);
        return data;
    }catch(e){
        return e.response;
    }
}