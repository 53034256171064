import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Main from '../../pages/Main/Main';
import { getElements } from '../../configs/MenuItemsConfigs';

export default function MainRoutes() {

    const elements = getElements();

    function renderRoutes() {
        return elements.map((item, idx) => {
            if (item?.routes_permission.length > 0) {
                return <Route path={item.routes_path} key={idx} element={
                    <ProtectedRoute permission_group={item.routes_permission}>
                        {item.element}
                    </ProtectedRoute>
                }></Route>
            }

            return <Route path={item.routes_path} key={idx} element={item.element}></Route>
        })
    }

    return (
        <Routes>
            {
                renderRoutes()
            }
            <Route path="*" element={<Main></Main>}></Route>
        </Routes>
    )
}