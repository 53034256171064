import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import RatSteps from '../RatSteps/RatSteps';
import RatRegistration from '../RatRegistration/RatRegistration';
import { useNavigate, useParams } from 'react-router-dom';
import { getRatReportByTicketId, getTicketByTicketId } from '../../../services/Rat';
import If from '../../../common/Layout/If';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import { toast } from 'react-toastify';

export default function RatDetails(){
    const { id } = useParams();
    const [stepOrReport, setStepOrReport] = useState('step');
    const [step, setStep] = useState(1);
    const [ticket, setTicket] = useState('');
    const [contractId, setContractId] = useState('');
    const [partnerName, setPartnerName] = useState('');

    const navigate = useNavigate();

    async function getTicket(){
        const toastData = toast.loading('Carregando dados, aguarde...');
        let dataReturn = await getTicketByTicketId(id);

        if(dataReturn?.status === 200){
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            let ticketData = dataReturn?.data?.data || {};
            setTicket(ticketData?.num_chamado);
            setContractId(ticketData?.id_contrato);
            setPartnerName(ticketData?.partner?.nome_representante);
            if([null, undefined, '', false].includes(ticketData?.ultima_etapa)){
                setStep(1);
                setStepOrReport('step');
                return;
            }

            if(ticketData?.ultima_etapa >= 7){
                setStep(ticketData?.ultima_etapa + 1);
                setStepOrReport('report');
                return;
            }

            setStep(ticketData?.ultima_etapa + 1);
            setStepOrReport('step');
        }else{
            handleErrorsToast('Ocorreu um erro', dataReturn?.data, 'update', toastData);
            navigate('/rat/chamados');
        }
    }

    useEffect(()=>{
        getTicket();
    }, [id]);

    return (
        <Content headerTitle={stepOrReport === 'step' ? 'Etapas RAT' : 'Relatório'}>
            <SubHeaderSpecific subHeaderConfig='mam_rat'></SubHeaderSpecific>
            <Card hideCardHeader>
                <If condition={stepOrReport === 'step'}>
                    <RatSteps step={step} ticket={ticket} ticket_id={id} contract_id={contractId} partner_name={partnerName}></RatSteps>
                </If>
                <If condition={stepOrReport === 'report'}>
                    <RatRegistration ticket={ticket} ticket_id={id}></RatRegistration>
                </If>
            </Card>
        </Content>
    )
}