import './ShowTickets.css';
import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import { getTickets } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import IntegradoraSelect from '../../../common/Selects/IntegradoraSelect';
import ContratosMultiSelect from '../../../common/Selects/ContratosMultiSelect';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../common/Selects/TecnicosSelect';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import If from '../../../common/Layout/If';
import { Badge } from 'primereact/badge';
import { Button, Card, CardBody, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';
import TagsSelect from '../../../common/Selects/TagsSelect';

export default function ShowTickets() {
    //Variables
    let interval;
    const arrayFields = ['status_chamado', 'contrato', 'remove_contrato', 'integradora', 'tags'];
    const initialState = { data_inicial: '', data_final: '', status_chamado: [], id_usuario_responsavel_chamado: '', integradora: [], contrato: [], id_representante: '', id_tecnico: '', tags: [] }
    const [filterForm, setFilterForm] = useState(initialState);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState([]);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [loading, setIsLoading] = useState(true);
    const milliseconds = 300000; //5 minutos
    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (<Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>)
        },
        {
            id: 'num_chamado',
            name: 'ID Ticket Cli. / Nº Chamado',
        },
        {
            id: 'tags',
            name: 'Tags',
            body: (row) => (<div className='flex flex-row flex-wrap w-15rem'>
                {
                    row?.ticket_tags?.map((item) =>  (
                    <Badge
                    className='cursor-pointer'
                        key={`${item?.id_tag}_tag`} 
                        style={{ backgroundColor: `#${item?.cor_hexadecimal}` }} 
                        value={item?.nome || ''}
                        onClick={()=>filterByTagId(item)}
                        ></Badge>
                    )
                )
                }
                </div>
            )
        },
        {
            id: 'data_agendamento',
            name: 'Data Agendamento',
        },
        {
            id: 'hora_agendamento',
            name: 'Hora Agendamento',
        },
        {
            id: 'responsavel_atendimento',
            name: 'Responsavel Atendimento',
        },
        {
            id: 'urgencia',
            name: 'Urgencia',
            body: (row) => (<b style={{ color: 'red' }}>{row.urgencia}</b>)
        },
        {
            id: 'status_chamado',
            name: 'Status Chamado',
        },
        {
            id: 'cliente',
            name: 'Cliente',
        },
        {
            id: 'integradora',
            name: 'Integradora',
        },
        {
            id: 'contrato',
            name: 'Contrato',
        },
        {
            id: 'tecnico',
            name: 'Técnico',
            body: (row) => (<Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}>{row.tecnico}</Link>)
        },
        {
            id: 'representante',
            name: 'Representante',
        },
        {
            id: 'doc_entregue_validacao',
            name: 'Doc Entregue / Validação',
            body: (row) => (<b style={{ color: 'red' }}>{row.doc_entregue_validacao}</b>)
        },
        {
            id: 'contato_tecnico',
            name: 'Contato Tecnico',
        },
        {
            id: 'status_tecnico',
            name: 'Status Técnico',
        },
        {
            id: 'descricao',
            name: 'Descrição',
        },
        {
            id: 'endereco_cliente',
            name: 'End. Cliente',
        },
        {
            id: 'bairro_cliente',
            name: 'Bairro Cliente',
        },
        {
            id: 'cidade_cliente',
            name: 'Cidade Cliente',
        },
        {
            id: 'estado_cliente',
            name: 'Estado Cliente',
        },
        {
            id: 'cep_cliente',
            name: 'CEP Cliente',
        },
        {
            id: 'compl_end_cliente',
            name: 'Comp. End. Cliente',
        },
        {
            id: 'contato_no_cliente',
            name: 'Contato no Cliente',
        },
        {
            id: 'tel_contato_cliente',
            name: 'Tel. Contato Cliente',
        },
        {
            id: 'inf_adc_contrato',
            name: 'Inf. Adc. Contrato',
        },
        {
            id: 'endereco_partida',
            name: 'Logradouro da Partida',
        },
        {
            id: 'bairro_partida',
            name: 'Bairro da Partida',
        },
        {
            id: 'cidade_partida',
            name: 'Cidade da Partida',
        },
        {
            id: 'estado_partida',
            name: 'Estado da Partida',
        },
        {
            id: 'cep_partida',
            name: 'CEP da Partida',
        },
        {
            id: 'compl_ref_obs_endereco_partida',
            name: 'Comp. End. Partida',
        },
        {
            id: 'data_registro',
            name: 'Data de Registro',
        },
        {
            id: 'data_inicio_desloc',
            name: 'Data Início Desloc',
        },
        {
            id: 'hora_inicio_desloc',
            name: 'Hora Início Desloc',
        },
        {
            id: 'data_fim_desloc',
            name: 'Data Fim Desloc',
        },
        {
            id: 'hora_fim_desloc',
            name: 'Hora Fim Desloc',
        },
        {
            id: 'data_solicitacao',
            name: 'Data da Solicitação',
        },
        {
            id: 'hora_solicitacao',
            name: 'Hora da Solicitação',
        },
        {
            id: 'data_desejada',
            name: 'Data Desejada'
        },
        {
            id: 'hora_desejada',
            name: 'Hora Desejada'
        },
        {
            id: 'usuario_responsavel_parceria',
            name: 'Resp. Parceria',
        },
        {
            id: 'key_ticket_cliente',
            name: 'Key Ticket Cliente'
        },
        {
            id: 'fabricante',
            name: 'Fabricante',
        },
        {
            id: 'ocasiao_fechamento',
            name: 'Ocasião Fechamento'
        },
        {
            id: 'responsavel_improdutivo',
            name: 'Responsável Improdutivo'
        },
        {
            id: 'motivo_improdutivo',
            name: 'Motivo Ocasião'
        },
        {
            id: 'contrato_centro_custo',
            name: 'Centro de Custo'
        },
        {
            id: 'senha_atendimento',
            name: 'Senha Atendimento'
        },
        {
            id: 'data_inicio_atendimento',
            name: 'Data Início Atendimento'
        },
        {
            id: 'hora_inicio_atendimento',
            name: 'Hora Início Atendimento'
        },
        {
            id: 'data_fim_atendimento',
            name: 'Data Fim Atendimento'
        },
        {
            id: 'hora_fim_atendimento',
            name: 'Hora Fim Atendimento'
        },
        {
            id: 'tempo_atendimento',
            name: 'Tempo de Atendimento'
        },
        {
            id: 'contrato_km_maximo',
            name: 'Km Máximo Contrato'
        },
        {
            id: 'chamado_km_real',
            name: 'Km Real'
        },
        {
            id: 'nome_solicitante',
            name: 'Nome Solicitante'
        },
        {
            id: 'senha_integradora',
            name: 'Senha Integradora'
        },
        {
            id: 'info_adicional_circuito',
            name: 'Circuito'
        },
        {
            id: 'equipamentos_remessa',
            name: 'Equipamentos de Remessa'
        },
        {
            id: 'equipamentos_retorno',
            name: 'Equipamentos de Retorno'
        },
        {
            id: 'os_ba',
            name: 'OS/BA'
        },
        {
            id: 'protocolo_anatel',
            name: 'Protocolo Anatel'
        }
    ];

    //Functions 
    const conditionRow = (row) => {
        return {
            "danger-color custom-style": row.cor_row === 'vermelho',
            "info-color custom-style": row.cor_row === 'azul',
            "warning-color custom-style": row.cor_row === 'amarelo',
            "success-color custom-style": row.cor_row === 'verde',
        }
    }

    async function getTicketsByParam(page, perPage, exportData = false) {
        const toastTicket = toast.loading("Carregando, aguarde...");

        try {
            let urlParams = new URLSearchParams(location.search);
            let params = {
                page: page,
                per_page: perPage,
                export: exportData
            }
            let booleanFields = ['gts', 'key_ticket_cliente_null']

            urlParams.forEach((item, idx) => {

                if (item !== "") {
                    if (arrayFields.includes(idx)) {
                        if (!params[idx]) {
                            params[idx] = [];
                        }
                        params[idx].push(item);
                    } else if (booleanFields.includes(idx)) {
                        params[idx] = item === "true" ? true : false;
                    } else {
                        params[idx] = item;
                    }
                }
            });

            if (!exportData) {
                setIsLoading(true);
                setData([]);
                setPaginationTotalRows(0);
            }

            let dataReturn = await getTickets(params);

            if ([202, 200].includes(dataReturn?.status)) {
                if (exportData) {
                    let message = dataReturn?.data?.data?.message;
                    handleSuccessToast(message, 'update', toastTicket);
                } else {
                    let ticketData = dataReturn?.data?.data || [];
                    if (ticketData?.tickets?.length > 0) {
                        ticketData.tickets = ticketData?.tickets?.map((item) => {
                            for (let i in item) {
                                item[i] = [undefined, null].includes(item[i]) ? '' : item[i]
                            }
                            return item;
                        });
                    }
                    setData(ticketData?.tickets);
                    setPaginationTotalRows(ticketData?.pagination?.total);
                    handleSuccessToast('Pesquisa realizada com sucesso!', 'update', toastTicket);
                }

            } else {
                setPaginationTotalRows(0)
                setData([]);
                handleErrorsToast(`Ocorreu um erro:`, dataReturn?.data, 'update', toastTicket);
            }

            setIsLoading(false);

        } catch (e) {
            setData([]);
            setIsLoading(false);
            handleErrorsToast(`Ocorreu um erro: ${e}`, {}, 'update', toastTicket);
        }
    }

    function handleFilterForm(e) {
        setFilterForm({ ...filterForm, [e.target.name]: e.target.value });
    }

    function handleClearForm() {
        setFilterForm(initialState);
    }

    function getTicketsByFilter(e) {
        e.preventDefault();
        handleFilterParams(filterForm);
    }

    function handleFilterParams(filter){
        let params = {};

        params['data_inicial'] = filter.data_inicial;
        params['data_final'] = filter.data_final;
        params['status_chamado'] = id === 'rejeitados' ? ['Rejeitado'] : filter.status_chamado.map((item) => (item?.value === undefined ? '' : item.value));
        params['integradora'] = filter.integradora.map((item) => (item?.value === undefined ? '' : item.value));
        params['contrato'] = filter.contrato.map((item) => (item?.value === undefined ? '' : item.value));
        params['id_representante'] = filter.id_representante?.value === undefined || filter.id_tecnico?.value !== undefined ? '' : filter.id_representante?.value;
        params['id_tecnico'] = filter.id_tecnico?.value === undefined ? '' : filter.id_tecnico?.value;
        params['id_usuario_responsavel_chamado'] = filter.id_usuario_responsavel_chamado?.value === undefined ? '' : filter.id_usuario_responsavel_chamado?.value;
        params['tags'] = (filter?.tags || [])?.map((item) => (item?.value === undefined ? '' : item?.value));
        let url = `?`;

        for (let p in params) {
            if (arrayFields.includes(p)) {
                for (let c = 0; c < params[p].length; c++) {
                    url += (`${p}=${params[p][c]}&`);
                }
            } else {
                url += (`${p}=${params[p]}&`);
            }
        }

        url = url.slice(0, -1);

        navigate(url);
    }

    function handleSetPartner(value, name) {
        setFilterForm(prevState => ({ ...prevState, [name]: value, id_tecnico: '' }));
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
    }

    function handleXlsxExport() {
        getTicketsByParam(1, paginationTotalRows, true);
    }

    function calculateDays() {
        let urlParams = new URLSearchParams(location.search);
        let data_inicial = urlParams.get('data_inicial');
        let data_final = urlParams.get('data_final');
        if ((!['', undefined, null, false].includes(filterForm?.data_inicial) || !['', undefined, null, false].includes(data_inicial)) && (!['', undefined, null, false].includes(filterForm?.data_final) || !['', undefined, null, false].includes(data_final))) {
            const firstDate = new Date(filterForm?.data_inicial || data_inicial);
            const lastDate = new Date(filterForm?.data_final || data_final);

            const days = Math.ceil(Math.abs(lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

            if (days > 6) {
                return true;
            }

            return false;
        }

        return false;
    }

    function filterByTagId(tag){
        setFilterForm(p =>{
            let newState = { ...p, ...filterForm, tags: [{value: tag?.id_tag, label: tag?.nome}]}
            handleFilterParams(newState);
            return newState;
        });
    }

    //Hooks
    useEffect(() => {
        getTicketsByParam(page + 1, perPage);

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getTicketsByParam(page + 1, perPage);
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };

    }, [page, perPage, location.search]);

    //Component's return 
    return (
        <div key={id}>
            <Content headerTitle="Chamados">
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <FloatSearchButton></FloatSearchButton>
                <Card>
                    <CardBody>
                        <Form onSubmit={getTicketsByFilter}>
                            <Row>
                                <Col>
                                    <FormLabel htmlFor='dataInicio'>Data Início</FormLabel>
                                    <FormControl type='date' name='data_inicial' id='dataInicio' value={filterForm.data_inicial} onChange={handleFilterForm}></FormControl>
                                </Col>
                                <Col>
                                    <FormLabel htmlFor='dataFim'>Data Fim</FormLabel>
                                    <FormControl type='date' name='data_final' id='dataFim' value={filterForm.data_final} onChange={handleFilterForm}></FormControl>
                                </Col>
                                <If condition={id !== 'rejeitados'}>
                                    <StatusChamadoSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='status_chamado' isMulti={true}></StatusChamadoSelect>
                                </If>
                                <UsuariosAtivosSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_usuario_responsavel_chamado' title='Resp. Atendimento' userType='support'></UsuariosAtivosSelect>
                                <IntegradoraSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='integradora' isMulti={true}></IntegradoraSelect>
                                <ContratosMultiSelect
                                    formData={filterForm}
                                    setFormData={setFilterForm}
                                    cols={[]}
                                    dependsOnIntegradora={true}
                                    id_integradora_string={filterForm?.integradora?.length > 0 ? filterForm?.integradora?.map((item) => item?.value).toString() : ''}>
                                </ContratosMultiSelect>
                                <RepresentanteSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_representante' advancedSetFormData={handleSetPartner}></RepresentanteSelect>
                                <TecnicosSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_tecnico' id_representante={filterForm?.id_representante?.value}></TecnicosSelect>
                                <TagsSelect formData={filterForm} setFormData={setFilterForm} cols={[]} isMulti></TagsSelect>
                            </Row>
                            <Row className='mt-1'>
                                <Col>
                                    <Button type='submit' variant='primary' className='mr-1 inline'>Filtrar</Button>
                                    <Button type='button' variant='warning' className='ml-1 inline' onClick={handleClearForm}>Limpar Filtros</Button>
                                </Col>
                            </Row>
                        </Form>
                        <span style={{ fontSize: '0.8rem' }}>Obs: Caso não passe nenhuma data, o filtro irá considerar a data de hoje!</span>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            customStyles={conditionRow}
                            id='show_tickets'
                            reorderableColumns
                            xlsxFunction={calculateDays() ? handleXlsxExport : null}
                        ></PrimeTable>
                    </CardBody>
                </Card>
            </Content>
        </div>
    );
}