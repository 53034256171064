import { Col, FormControl, FormLabel, Row, Form, Button } from "react-bootstrap";
import Card from "../../../../common/Layout/Card";
import Content from "../../../../common/Layout/Content";
import SubHeaderSpecific from "../../../../common/Layout/SubHeader/SubHeaderSpecific";
import PrimeTable from "../../../../common/Layout/Table/PrimeTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast, openNewTab } from "../../../../configs/GenericFunctions";
import { exportProducts, getAllProductsParams } from "../../../../services/Product";
import Filters from "./Filters";

export default function LogisticReportLogistic() {

    const [formData, setFormData] = useState({});
   
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [productsData, setProductsData] = useState([]);

    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'integradora_produto',
            name: 'Integradora Produto',
        },
        {
            id: 'projeto_produto',
            name: 'Projeto'
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'status_produto',
            name: 'Status'
        },
        {
            id: 'num_chamado_produto',
            name: 'Número do Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row?.id_chamado_produto)}>{row.num_chamado_produto}</Button>)
        },
        {
            id: 'data_aplicacao_produto',
            name: 'Data Aplicação'
        },
        {
            id: 'rastreamento_produto',
            name: 'Rastreamento'
        },
        {
            id: 'local_produto',
            name: 'UF'
        },
        {
            id: 'id_localidade_produto',
            name: 'Id Localidade'
        },
        {
            id: 'nfe_entrada_produto',
            name: 'NFe Entrada'
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFe Saída'
        },
        {
            id: 'codigo_sap_produto',
            name: 'Código SAP'
        },
        {
            id: 'os_ba_produto',
            name: 'OS/BA'
        },
        {
            id: 'circuito_produto',
            name: 'Circuito'
        },
        {
            id: 'protocolo_anatel_produto',
            name: 'Protocolo Anatel'
        }
    ]

    async function filterForm(params) {
        getLogisticReport(1, 100, params);
        setPerPage(100);
        setPage(0);
        setFormData(params);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getLogisticReport(newPage + 1, perPage, formData);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getLogisticReport(1, rows, formData);
    }

    async function getLogisticReport(page = 1, perPage = 100, params = {}) {
        setLoading(true);

        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getAllProductsParams(page, perPage, params);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let data = dataReturn?.data?.data?.data;

            let products = data?.map((item) => {
                return (
                    {
                        id_produto: item?.id,
                        status_produto: item.status?.valor_opcao || '',
                        nome_produto: item?.partnumber?.modelo || '',
                        serial_produto: item?.serial,
                        codigo_sap_produto: item?.codigo_sap || '',
                        nfe_entrada_produto: item?.nfe_entrada || '',
                        integradora_produto: item?.integrator?.nome || '',
                        projeto_produto: item.contract?.contrato || '',
                        valorado_produto: item.valorado || '',
                        local_produto: item?.local || '',
                        tecnico_produto: item.technician?.nome_tec || '',
                        chave_opcao_status_produto: item?.status?.chave_opcao || '',
                        id_localidade_produto: item?.id_localidade || '',
                        id_tecnico_produto: item?.id_tecnico || '',
                        id_contrato_produto: item?.id_contrato || '',
                        id_chamado_produto: item?.last_shipment?.id_chamado || '',
                        num_chamado_produto: item?.last_shipment?.ticket?.num_chamado || '',
                        rastreamento_produto: item?.last_shipment?.rastreamento || '',
                        nfe_saida_produto: item?.last_shipment?.nfe_saida || '',
                        os_ba_produto: item?.last_shipment?.ticket?.os_ba || '',
                        circuito_produto: item?.last_shipment?.ticket?.info_adicional_circuito || '',
                        protocolo_anatel_produto: item?.last_shipment?.ticket?.protocolo_anatel || '',
                        data_aplicacao_produto: item?.last_shipment?.ticket?.data_agendamento || ''
                    }
                );
            });
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setProductsData(products);
            setFormData(params);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setProductsData([]);
        }
        setLoading(false);
    }

      async function handleXlsxExport(){
            const toastData = toast.loading("Carregando dados, aguarde...");
    
            let dataReturn = await exportProducts(formData);
            
            if(dataReturn?.status === 200){
                handleSuccessToast(dataReturn?.data?.message || 'Exportação iniciada com sucesso. Você receberá um email com os dados.', 'update', toastData);
            }else{
                handleErrorsToast("Ocorreu um ou mais erros: ", dataReturn?.data, 'update', toastData);
            }
        }

    useEffect(()=>{
        getLogisticReport();
    }, []);

    return (
        <Content headerTitle="Relatório Logística">
            <SubHeaderSpecific subHeaderConfig="mam_logistica_relatorios"></SubHeaderSpecific>
            <Card title="Relatório Logística">
                <Row>
                    <Col sm='12'>
                        <Filters filterForm={filterForm} setFilters={setFormData}></Filters>
                    </Col>
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={productsData}
                            id='relatorio_logistica_table'
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            reorderableColumns
                            xlsxFunction={paginationTotalRows > 100 ? handleXlsxExport : null}
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    )
}