import interceptorInstance from "./Interceptor";

export async function getModules(){
    try{
        let data = await interceptorInstance.get(`dev-mode/get-modules`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createModule(params){
    try{
        let data = await interceptorInstance.post(`dev-mode/create-module`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getSubModules(moduleId){
    try{
        let data = await interceptorInstance.get(`dev-mode/get-submodules/${moduleId}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createSubmodule(params){
    try{
        let data = await interceptorInstance.post(`dev-mode/create-submodule`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllPermissions(submoduleId){
    try{
        let data = await interceptorInstance.get(`dev-mode/get-permissions/${submoduleId}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createPermission(params){
    try{
        let data = await interceptorInstance.post(`dev-mode/create-permission`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllDashboards(){
    try{
        let data = await interceptorInstance.get('dev-mode/metabase/dashboards');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createDashboard(params){
    try{
        let data = await interceptorInstance.post('dev-mode/metabase/dashboard', params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getEmbedDashboard(slug){
    try{
        let data = await interceptorInstance.get(`dev-mode/metabase/embed/${slug}`);
        return data;
    }catch(e){
        return e.response;
    }
}