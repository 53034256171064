import React, { useEffect, useState } from 'react';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { toast } from 'react-toastify';
import { getProductByProductId } from '../../../../services/Product';
import { createBulkShipment, createShipment } from '../../../../services/Shipment';
import If from '../../../../common/Layout/If';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';

export default function CreateShipment({ isOpen = false, setIsOpen = null, productId = '', setUpdateProducts = null, setUpdateLogistic = null, mode = 'single', handleClearRows = null }) {
    const INITIAL_STATE = {
        urgencia_remessa: '',
        tipo_remessa: '',
        id_tecnico: '',
        modal_envio: '',
        responsavel_separacao: '',
        local: '',
        box: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [productData, setProductData] = useState(null);
    const [productsData, setProductsData] = useState(null);
    const [showProducts, setShowProducts] = useState(false);

    async function getProductDataById() {
        const toastProducts = toast.loading('Carregando dados do Produto, aguarde...');

        let dataReturn = await getProductByProductId(productId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let product = dataReturn?.data?.data;

            setProductData(prevState => (
                {
                    ...prevState,
                    ...product
                }
            ));
            if (product?.status?.chave_opcao === 'spare_tecnico') {
                setFormData(prevState => ({ ...prevState, tipo_remessa: { value: 'envio_tecnico', label: 'ENVIO TÉCNICO' } }));
            }
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setIsOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            chave_tipo_remessa: formData?.tipo_remessa?.value || '',
            id_tecnico: formData?.tipo_remessa?.value === 'envio_tecnico' ? formData?.id_tecnico?.value : '',
            modal_envio: formData?.modal_envio?.value || '',
            responsavel_separacao: formData?.responsavel_separacao?.label || '',
            local: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.local?.value : '',
            box: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.box?.value : ''
        }

        const toastProducts = toast.loading('Cadastrando remessa, aguarde...');

        let dataReturn = await createShipment(productId, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cadastrada com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setIsOpen(false);
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function submitBulkForm(e) {
        e.preventDefault();

        let params = {
            ids_produto: productId.split(","),
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            chave_tipo_remessa: formData?.tipo_remessa?.value || '',
            id_tecnico: formData?.tipo_remessa?.value === 'envio_tecnico' ? formData?.id_tecnico?.value : '',
            modal_envio: formData?.modal_envio?.value || '',
            responsavel_separacao: formData?.responsavel_separacao?.label || '',
            local: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.local?.value : '',
            box: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.box?.value : ''
        }

        const toastData = toast.loading('Cadastrando remessa, aguarde...');

        let dataReturn = await createBulkShipment(params);

        if(dataReturn?.status === 201){
            handleSuccessToast('Remessas criadas com sucesso!', 'update', toastData);
            setUpdateProducts();
            setIsOpen(false);
            setUpdateLogistic(true);
            handleClearRows();
        }else{
            handleErrorsToast("Ocorreu um erro", dataReturn?.data, 'update', toastData);
        }
    }

    async function getProductsData() {
        let ids = productId.split(",");
        let products = [];

        for (let id of ids) {
            let dataReturn = await getProductByProductId(id);

            if (dataReturn?.status === 200) {
                products.push(dataReturn?.data?.data);
            }
        }

        setProductsData(products.map((item, idx) => (
            <div className='row' key={idx}>
                <div className="col-sm-12 col-md-4">
                    <label>Fabricante</label>
                    <InputText className='col-12' value={item?.partnumber?.manufacturer?.nome_fabricante || ''} disabled></InputText>
                </div>
                <div className="col-sm-12 col-md-4">
                    <label>Nome do Produto</label>
                    <InputText className='col-12' value={item?.partnumber?.modelo || ''} disabled></InputText>
                </div>
                <div className="col-sm-12 col-md-4">
                    <label>Tipo do Produto</label>
                    <InputText className='col-12' value={item?.nome_produto || ''} disabled></InputText>
                </div>
            </div>
        )));

    }

    useEffect(() => {
        if (isOpen) {
            setShowProducts(false);
            setFormData(INITIAL_STATE);
            if (!['', undefined, null].includes(productId)) {
                if (mode === 'single') {
                    getProductDataById();
                }

                if (mode === 'bulk') {
                    getProductsData();
                }
            } else {
                setProductData({});
                setProductsData([]);
            }
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header={'Deseja criar remessa para o(s) produto(s) selecionado(s)?'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={mode === 'single' ? submitForm : submitBulkForm}>
                <div className="row">
                    <If condition={mode === 'single'}>
                        <div className="col-sm-12 col-md-4">
                            <label>Fabricante</label>
                            <InputText className='col-12' value={productData?.partnumber?.manufacturer?.nome_fabricante || ''} disabled></InputText>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label>Nome do Produto</label>
                            <InputText className='col-12' value={productData?.partnumber?.modelo || ''} disabled></InputText>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label>Tipo do Produto</label>
                            <InputText className='col-12' value={productData?.nome_produto || ''} disabled></InputText>
                        </div>
                    </If>
                    <If condition={mode === 'bulk'}>
                        <div className="col-12">
                            <h6 className='cursor-pointer' onClick={() => setShowProducts(prev => !prev)}>Produtos (Clique para {showProducts ? 'Esconder' : 'Expandir'})</h6>
                        </div>
                        <If condition={showProducts}>
                            <div className="col-12">
                                {productsData}
                            </div>
                        </If>
                    </If>
                    <GenericSelect fieldName={'urgencia_remessa'} formData={formData} setFormData={setFormData} title={'Urgência da Remessa'} cols={[12, 6, 4, 4]} genericOption='UrgenciaRemessaOptions' required></GenericSelect>
                    <If condition={productData?.status?.chave_opcao !== 'spare_tecnico' || mode === 'bulk'}>
                        <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'tipo_remessa'} fieldName={'tipo_remessa'} module={'produtos'} title={'Tipo Remessa'} cols={[12, 6, 3, 3]} responseValueField='chave_opcao'></ModuleOptionsSelect>
                    </If>
                    <If condition={formData?.tipo_remessa?.value === 'movimentacao_interna'}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 6, 2, 2]} genericOption='UfOptions' required></GenericSelect>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 6, 2, 2]} required uf={formData?.local?.value}></BoxSelect>
                    </If>
                    <If condition={formData?.tipo_remessa?.value === 'envio_tecnico'}>
                        <If condition={mode === 'single'}>
                            <div className="col-lg-2 col-md-12">
                                <label htmlFor="tecnico_responsavel">Técnico Responsável</label>
                                <InputText className='col-12' name="tecnico_responsavel" id="tecnico_responsavel" placeholder='Técnico Remetente' value={productData?.technician?.nome_tec || ''} disabled></InputText>
                            </div>
                        </If>
                        <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='representante' required></RepresentanteSelect>
                        <TecnicosSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='id_tecnico' id_representante={formData?.representante?.value || ''} required></TecnicosSelect>
                    </If>
                    <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='modal_envio' required></ModalEnvioSelect>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='responsavel_separacao' cols={[12, 6, 3, 3]} userType='all' required title='Responsável pela Separação' userGroup='logistica_operacao'></UsuariosAtivosSelect>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar Remessa</button>
                    </div>
                </div>
            </form>
        </Dialog>
    )
}