import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import GenericSelect from '../../../common/Selects/GenericSelect';
import { useParams } from 'react-router-dom';
import If from '../../../common/Layout/If';
import CreateReport from './CreateReport';
import { toast } from 'react-toastify';
import { getReports, reopenAnalysis, startAnalysis } from '../../../services/Repair';
import ReportDetails from './ReportDetails';
import Swal from 'sweetalert2';
import usePermissions from '../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function RepairReportsTable() {

    const INITIAL_STATE = {
        data_inicio: '',
        data_fim: '',
        houve_reparo: ''
    }

    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState(INITIAL_STATE);
    const [load, setLoad] = useState(false);
    const [createReportVisible, setCreateReportVisible] = useState(false);

    const [id, setId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [reportDetailVisible, setReportDetailVisible] = useState(false);

    const [updateReport, setUpdateReport] = useState(false);

    const { checkAllPermissions, checkPermission } = usePermissions();
    const { action: actionPermission, page: pagePermission } = getSubComponentsPermissions('mam_reparos', 'RepairReportsTable', 'RepairReportsTable');

    const { status } = useParams();

    const columns = [
        {
            id: 'action_laudo',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermission[0])}>
                            <button type="button" className="dropdown-item" onClick={() => openReportDetailsModal(row.id, 'view')}>Visualizar Laudo</button>
                        </If>
                        <If condition={['aberto'].includes(status) && checkPermission(actionPermission[1])}>
                            <button type="button" className="dropdown-item" onClick={() => showStartAnalysisSwal(row.id)}>Iniciar Análise</button>
                        </If>
                        <If condition={['em_analise', 'aguardando_componente'].includes(status) && checkPermission(actionPermission[2])}>
                            <button type="button" className="dropdown-item" onClick={() =>openReportDetailsModal(row.id, 'edit')}>Atualizar Análise</button>
                        </If>
                        <If condition={status === 'finalizado' && checkPermission(actionPermission[3])}>
                            <button type="button" className="dropdown-item" onClick={() => showReopenAnalysisSwal(row.id)}>Reabrir Análise</button>
                        </If>
                    </div>
                </div >),
            permission: checkAllPermissions(actionPermission)
        },
        {
            id: 'serial',
            name: 'Serial',
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'fabricante',
            name: 'Fabricante',
        },
        {
            id: 'data_solicitacao',
            name: 'Data de Solicitação',
        },
        {
            id: 'data_fechamento',
            name: 'Data de Fechamento',
        },
        {
            id: 'houve_reparo',
            name: 'Houve Reparo'
        },
        {
            id: 'aberto_por',
            name: 'Aberto Por',
        },
        {
            id: 'finalizado_por',
            name: 'Finalizado Por'
        }
    ];

    function searchData(e) {
        e.preventDefault();

        handleRowPerPagePrime(100);
    }

    async function getReportsData(page = 1, perPage = 100) {
        setLoading(true);
        const toastProduct = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getReports(page, perPage, status, (filterData?.data_inicio || ''), (filterData?.data_fim || ''), (filterData?.houve_reparo?.value || ''));

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data?.data.map((item) => ({
                id: item?.id || '',
                serial: item?.product_repair?.serial || '',
                nome_produto: item?.product_repair?.partnumber?.modelo || '',
                tipo_produto: item?.product_repair?.nome_produto || '',
                fabricante: item?.product_repair?.partnumber?.manufacturer?.nome_fabricante || '',
                data_solicitacao: item?.created_at || '',
                data_fechamento: item?.data_finalizacao_laudo || '',
                houve_reparo: item?.houve_reparo === 0 ? 'Não' : 'Sim',
                aberto_por: item?.created_by?.nome || '',
                finalizado_por: item?.finalized_by?.nome || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(data);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.date, 'update', toastProduct);
        }
        setLoading(false);
    }

    async function showReopenAnalysisSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja voltar este laudo para Em Análise?',
            text: 'Todo o processo de documentação da peça terá de ser feito novamente!',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (swal.isConfirmed) {
            reopenAnalysisById(id);
        }
    }

    async function startAnalysisById(id) {
        const toastProduct = toast.loading('Iniciando análise, aguarde...');

        let dataReturn = await startAnalysis(id);

        if (dataReturn?.data?.response === 'success') {
            getReportsData();
            setUpdateReport(true);
            openReportDetailsModal(id, 'edit');
            handleSuccessToast('Análise iniciada com sucesso!', 'update', toastProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao iniciar a análise', dataReturn?.data, 'update', toastProduct);
        }
    }

    async function showStartAnalysisSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja iniciar a análise desta peça?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (swal.isConfirmed) {
            startAnalysisById(id);
        }
    }

    async function reopenAnalysisById(id) {
        const toastProduct = toast.loading('Reabrindo análise, aguarde...');

        let dataReturn = await reopenAnalysis(id);

        if (dataReturn?.data?.response === 'success') {
            getReportsData();
            setUpdateReport(true);
            openReportDetailsModal(id, 'edit');
            handleSuccessToast('Análise reaberta com sucesso!', 'update', toastProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao reabrir a analise', dataReturn?.data, 'update', toastProduct);
        }
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getReportsData(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getReportsData(1, rows);
    }

    function openReportDetailsModal(id, view) {
        setId(id);
        setViewMode(view);
        setReportDetailVisible(true);
    }

    useEffect(() => {
        setFilterData(INITIAL_STATE);
        setLoad(true);
    }, [status]);

    useEffect(() => {
        if (load) {
            getReportsData();
            setLoad(false);
        }
    }, [load]);

    return (
        <Content headerTitle='Laudos Técnicos'>
            <SubHeaderSpecific subHeaderConfig='mam_reparos' setUpdateLogistic={setUpdateReport} updateLogistic={updateReport}></SubHeaderSpecific>
            <CreateReport visible={createReportVisible} onHide={() => setCreateReportVisible(!createReportVisible)} updateParent={() => getReportsData(page + 1, perPage)} setUpdateReport={setUpdateReport}></CreateReport>
            <ReportDetails id={id} onHide={() => setReportDetailVisible(!reportDetailVisible)} visible={reportDetailVisible} viewMode={viewMode} updateParent={() => getReportsData(page + 1, perPage)} setUpdateReport={setUpdateReport}></ReportDetails>
            <Card title='Laudos'>
                <div className="row">
                    <If condition={status === 'aberto' && checkPermission(pagePermission[0])}>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={() => setCreateReportVisible(!createReportVisible)}>Cadastrar Laudo</button>
                        </div>
                    </If>
                    <If condition={checkPermission(pagePermission[1])}>
                        <div className="col-12">
                            <form onSubmit={searchData}>
                                <div className="row">
                                    <If condition={status === 'finalizado'}>
                                        <GenericSelect formData={filterData} setFormData={setFilterData} fieldName={'houve_reparo'} cols={[12, 3, 3, 3]} genericOption='HouveReparoOptions' title='Houve Reparo?' required></GenericSelect>
                                    </If>
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor="data_inicio">Data Inicial</label>
                                        <input type="date" name="data_inicio" id="data_inicio" className="form-control" value={filterData?.data_inicio || ''} onChange={(e) => setFilterData(prev => ({ ...prev, [e.target.name]: e.target.value }))} required />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor="data_fim">Data Final</label>
                                        <input type="date" name="data_fim" id="data_fim" className="form-control" value={filterData?.data_fim || ''} onChange={(e) => setFilterData(prev => ({ ...prev, [e.target.name]: e.target.value }))} required />
                                    </div>
                                    <div className="col-sm-12 col-md-3 d-flex align-items-end mt-1">
                                        <button type="submit" className="btn btn-primary">Pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </If>
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            id='laudos_tecnicos_primetable'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    );
}