import React, { useState } from 'react';
import If from './If';
import {Card as BootstrapCard, CardBody, CardHeader} from 'react-bootstrap';

export default function Card({ hideCardHeader = false, collapseId = '', icon = '', title = '', children }) {

    const [collapse, setCollapse] = useState(collapseId ? true : false);

    const handleCollapse = () => {
        if(collapseId !== ''){
            setCollapse(!collapse)
        }
    }

    return (
        <BootstrapCard>
            <If condition={!hideCardHeader}>
                <CardHeader
                    onClick={handleCollapse}
                    style={collapseId !== '' ? { cursor: 'pointer' } : undefined}>
                    <h5>{icon && (<i className={`fas ${icon}`}></i>)} {title}</h5>
                </CardHeader>
            </If>
            <If condition={!collapse}>
                <CardBody>
                    {children}
                </CardBody>
            </If>
        </BootstrapCard>
    );
}