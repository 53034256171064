import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ShipmentDetails from './ShipmentDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { checkValue, getShipmentsByTicketId, getTicketQuant, handleErrorsToast, handleSuccessToast, openNewTab, showBulkShipmentMessage } from '../../../../configs/GenericFunctions';
import ShipmentFilters from '../Components/ShipmentFilters';
import BulkShipmentDetails from './BulkShipmentDetails';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';

export default function ProductsToSend() {

    const [data, setData] = useState([]);

    const [productId, setProductId] = useState('');
    const [shipmentDetailsMode, setShipmentDetailsMode] = useState('view');
    const [shipmentDetailsModal, setShipmentDetailsModal] = useState(false);

    const [bulkShipmentDetailsModal, setBulkShipmentDetailsModal] = useState(false);

    const [bulkShipmentId, setBulkShipmentId] = useState('');
    const [bulkTicketId, setBulkTicketId] = useState('');
    const [bulkShipmentType, setBulkShipmentType] = useState('');
    const [bulkSendModal, setBulkSendModal] = useState('');

    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [search, setSearch] = useState('');
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, acoes_em_massa: acoesEmMassaPermissions } = getSubComponentsPermissions('mam_logistica', 'ProductsToSend', 'ProductsToSend');
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openShipmentDetailsModal(row, 'view')}>Ver</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openShipmentDetailsModal(row, 'edit')}>Editar</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2])}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Solicitação</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'data_criacao_produto',
            name: 'Data de Criação'
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<Button type='button' variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</Button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto',
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        }
    ];

    async function getProductsToSendByStatus(page = 1, perPage = 100, search = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO ENVIO', page, perPage, search);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || '',
                chave_tipo_remessa_produto: item?.chave_tipo_remessa || '',
                data_criacao_produto: item?.created_at_br || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    async function openShipmentDetailsModal(row, mode) {
        if (mode === 'view' || checkValue(row.id_chamado_produto)) {
            handleSingleShipmentModal(row, mode);
            return;
        }

        let product = await getShipmentsByTicketId('AGUARDANDO ENVIO', `&id_chamado=${row.id_chamado_produto}`);

        if (product?.length < 2) {
            handleSingleShipmentModal(row, mode);
        } else {
            showBulkShipmentMessage(() => handleSingleShipmentModal(row, mode), () => handleBulkShipmentModal(product));
        }
    }

    function handleSingleShipmentModal(row, mode) {
        setProductId(row.id_produto);
        setShipmentDetailsMode(mode);
        setShipmentDetailsModal(true);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Emissão cancelada com sucesso!', 'update', toastProducts);
            getProductsToSendByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a emissão!', dataReturn?.data, 'update', toastProducts);
        }
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getProductsToSendByStatus(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getProductsToSendByStatus(1, rows, search);
    }

    function handleFilters(search = '') {
        setPerPage(100);
        setPage(0);
        setSearch(search);
        getProductsToSendByStatus(1, 100, search);
    }

    function handleBulkShipmentModal(selectedRowsParam = []) {
        let chamado = '';
        let tipo_remessa = '';
        let modal_envio = '';
        let shipmentIdArray = [];
        let selectedRowsData = selectedRowsParam?.length > 0 ? selectedRowsParam : selectedRows;

        for (let i = 0; i < selectedRowsData.length; i++) {

            if (i === 0) {
                chamado = selectedRowsData[i]?.id_chamado_produto;
                tipo_remessa = selectedRowsData[i]?.chave_tipo_remessa_produto;
                modal_envio = selectedRowsData[i]?.modal_envio_produto;
                shipmentIdArray.push(selectedRowsData[i]?.id_produto);
                continue;
            }

            if (chamado !== selectedRowsData[i]?.id_chamado_produto) {
                handleErrorsToast('Todas as remessas devem pertencer ao mesmo chamado!');
                return;
            }

            if (tipo_remessa !== selectedRowsData[i]?.chave_tipo_remessa_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo tipo!');
                return;
            }

            if (modal_envio !== selectedRowsData[i]?.modal_envio_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo modal de envio!');
                return;
            }

            shipmentIdArray.push(selectedRowsData[i]?.id_produto);
        }

        setBulkShipmentId(shipmentIdArray.toString());
        setBulkShipmentType(tipo_remessa);
        setBulkTicketId(chamado);
        setBulkSendModal(modal_envio);
        setBulkShipmentDetailsModal(true);
    }

    useEffect(() => {
        getProductsToSendByStatus();
    }, []);

    return (
        <Content headerTitle="Peças a Enviar">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <ShipmentDetails isOpen={shipmentDetailsModal} setIsOpen={() => setShipmentDetailsModal(!shipmentDetailsModal)} shipmentId={productId} mode={shipmentDetailsMode} setUpdateShipment={() => getProductsToSendByStatus(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></ShipmentDetails>
            <BulkShipmentDetails handleClearRows={() => setSelectedRows([])} isOpen={bulkShipmentDetailsModal} setIsOpen={() => setBulkShipmentDetailsModal(!bulkShipmentDetailsModal)} sendModal={bulkSendModal} shipmentId={bulkShipmentId} shipmentType={bulkShipmentType} ticketId={bulkTicketId} setUpdateShipment={() => getProductsToSendByStatus(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></BulkShipmentDetails>
            <Card title='Cadastro de Produtos - Envio de Peças'>
                <If condition={selectedRows?.length > 0 && checkAllPermissions(acoesEmMassaPermissions)}>
                    <Row>
                        <Col sm='12'>
                            <DropdownButton title='Ações em massa'>
                                <If condition={checkPermission(acoesEmMassaPermissions[0])}>
                                    <DropdownItem onClick={handleBulkShipmentModal}>Enviar peças em massa</DropdownItem>
                                </If>
                            </DropdownButton>
                        </Col>
                    </Row>
                </If>
                <ShipmentFilters handleFilters={handleFilters} setSearch={setSearch}></ShipmentFilters>
                <Row>
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            id='products_to_send'
                            reorderableColumns
                            selectableRows={checkAllPermissions(acoesEmMassaPermissions) ? true : false}
                            selectedRows={checkAllPermissions(acoesEmMassaPermissions) ? selectedRows : null}
                            handleSelect={checkAllPermissions(acoesEmMassaPermissions) ? setSelectedRows : null}
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    )
}