import React, { useEffect, useState, useContext } from 'react';
import Content from '../../../common/Layout/Content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTicketById, getHistoryByTicketId, generateChildTicketByTicketId, updateDataByTicketId, getEquipmentsByTicketIdAndStatus, assignCreatedTicket, getPasswordsDataByTicketId } from '../../../services/Ticket';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import { Context } from '../../../App';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import usePermissions from '../../../hooks/usePermissions';
import './TicketDetails.css'
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import FloatChatButton from '../../../common/Chat/FloatChatButton';
import PendingExpenseTable from './PendingExpenseTable';
import AttachmentData from './AttachmentData';
import MapData from './MapData';
import ChamadosCardData from './ChamadosCardData';
import ClienteCardData from './ClienteCardData';
import SuporteAnalistaResponsavelCard from './SuporteAnalistaResponsavelCard';
import SenhaAtendimentoCard from './SenhaAtendimentoCard/SenhaAtendimentoCard';
import NovaDataAgendamento from './NovaDataAgendamento';
import NovoNumeroRMACard from './NovoNumeroRMACard';
import FabricanteCard from './FabricanteCard';
import CircuitoCard from './CircuitoCard';
import NumeroChamadoCard from './NumeroChamadoCard';
import AtendimentoCard from './AtendimentoCard';
import StatusCard from './StatusCard';
import ValidarDocumentacaoCard from './ValidarDocumentacaoCard';
import NovoContratoCard from './NovoContratoCard';
import HistoricoBacklogCard from './HistoricoBacklogCard';
import HistoricoStatusCard from './HistoricoStatusCard';
import EquipamentoRetornoCard from './EquipamentoRetornoCard/EquipamentoRetornoCard';
import RegistroTrabalhoCard from './RegistroTrabalhoCard/RegistroTrabalhoCard';
import DadosClienteCard from './DadosClienteCard';
import If from '../../../common/Layout/If';
import NovoKmCard from './NovoKmCard';
import DadosTecnicoCard from './DadosTecnicoCard';
import DetalhesAcionamentoCard from './DetalhesAcionamentoCard';
import EquipamentoRemessaCard from './EquipamentoRemessaCard/EquipamentoRemessaCard';
import CreateSenhaAtendimento from './SenhaAtendimentoCard/CreateSenhaAtendimento';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import DataDesejada from './DataDesejada';
import { checkEmptyString, handleErrorsToast, handleSuccessToast, copyToClipboard, formatDate } from '../../../configs/GenericFunctions';
import OsBaCard from './OsBaCard';

export default function TicketDetails() {
    const { checkPermission } = usePermissions();
    const { page: pagePermissions, details_chamado_button: detailsPermission } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'TicketDetails');
    const { page: pagePermissionsChat } = getSubComponentsPermissions('mam_talks', 'MainChat', 'FloatChatButton');

    const initialState = {
        id_representante: '',
        id_tecnico: '',
        id_contrato: '',
        id_cliente: '',
        id_usuario: '',
        chamado: '',
        num_chamado: '',
        id_ticket_cliente: '',
        tipo_atividade: '',
        urgencia: '',
        prioridade: '',
        envolve_logistica: '',
        informacao_adicional: '',
        info_adicional_circuito: '',
        fabricante: '',
        fabricantes: '',
        status_chamado: '',
        nome_solicitante: '',
        status_tec_atendimento: '',
        data_solicitacao: '',
        hora_solicitacao: '',
        data_agendamento: '',
        hora_agendamento: '',
        descricao: '',
        numero_rma: '',
        km_tecnico: '',
        data_registro: '',
        data_modificacao: '',
        tecnico_proj_edc: '',
        documentacao_entregue_url_tecnico: '',
        documentacao_entregue_url: '',
        motivo_liberar_pagamento: '',
        documentacao_entregue: '',
        liberar_pagamento: '',
        nota_validacao_documento: '',
        nota_historico_fechamento: '',
        nota_externa_cliente: '',
        nota_chamado: '',
        data_inicio_atendimento: '',
        hora_inicio_atendimento: '',
        data_encerramento: '',
        hora_encerramento: '',
        senha_integradora: '',
        nome_analista_integracao: '',
        tel_contato_analista_integracao: '',
        documento_matricula: '',
        motivo_improdutivo: '',
        ocasiao_fechamento: '',
        justificativa_ocasiao_fechamento: '',
        descricao_fechamento: '',
        data_desejada: '',
        hora_desejada: '',
        tem_conflito_chamado: false,
        os_ba: '',
        protocolo_anatel: '',
        responsavel_improdutivo: ''
    }

    const { id } = useParams();
    const location = useLocation();
    const userData = useContext(Context);

    const [originalData, setOriginalData] = useState(initialState);
    const [detailsData, setDetailsData] = useState(initialState);
    const [equipmentsData, setEquipmentsData] = useState([]);
    const [confirmedShipments, setConfirmedShipments] = useState([]);

    const [passwordData, setPasswordData] = useState([]);
    const [isCadastrarSenhaOpen, setIsCadastrarSenhaOpen] = useState(false);

    const [historico, setHistorico] = useState('');
    const [historicoTecnico, setHistoricoTecnico] = useState('');
    const [historicoExternoCliente, setHistoricoExternoCliente] = useState('');
    const [historicoParcerias, setHistoricoParcerias] = useState('');
    const [historicoBacklog, setHistoricoBacklog] = useState('');

    const [imgArray, setImgArray] = useState([]);
    const [imgThumbArray, setImgThumbArray] = useState([]);

    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showAlterarClienteBtn, setShowAlterarClienteBtn] = useState(false);
    const [showAlterarTecnicoBtn, setShowAlterarTecnicoBtn] = useState(false);
    const [showAlterarKmBtn, setShowAlterarKmBtn] = useState(false);
    const [showAlterarNumeroChamadoBtn, setShowAlterarNumeroChamadoBtn] = useState(false);
    const [showAlterarCircuitoBtn, setShowAlterarCircuitoBtn] = useState(false);
    const [showAlterarOsBaBtn, setShowAlterarOsBaBtn] = useState(false);
    const [showFabricanteBtn, setShowFabricanteBtn] = useState(false);
    const [showContratoBtn, setShowContratoBtn] = useState(false);
    const [showNumeroRmaBtn, setShowNumeroRmaBtn] = useState(false);
    const [showNovaDataAgendamentoBtn, setShowNovaDataAgendamentoBtn] = useState(false);
    const [showDataDesejadaBtn, setShowDataDesejadaBtn] = useState(false);

    const [floatChatOpened, setFloatChatOpened] = useState(false);
    const [channelId, setChannelId] = useState('');

    const [updateAttachmentData, setUpdateAttachmentData] = useState(false);

    const mySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const handleSetUpdateAttachmentData = (value) => {
        setUpdateAttachmentData(value);
    }

    function handleScrollToViewById(idToScroll) {
        return new Promise((resolve) => {
            let element = document.getElementById(idToScroll);

            if (element) {
                element.scrollIntoView();
                window.requestAnimationFrame(() => resolve());
            } else {
                resolve();
            }
        })

    }

    const getEquipmentsByIdStatus = async () => {
        let dataReturn = await getEquipmentsByTicketIdAndStatus(id, 'Remessa');
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {

            let dadosEquipamentos = dataReturn.data.data;
            let dadosEquipamentosArray = [];

            dadosEquipamentosArray = dadosEquipamentos.map((item, idx) => {
                return ({
                    ...item,
                    id_produto: item?.id_produto || '',
                    id_remessa: item?.id || '',
                    id_equipamento_chamado: item?.id || '',
                    tipo_produto_remessa: item?.product?.nome_produto || '',
                    fabricante_remessa: item?.partnumber?.manufacturer?.nome_fabricante || item?.product?.partnumber?.manufacturer?.nome_fabricante || '',
                    part_number_equipamento_remessa: item?.partnumber?.modelo || item?.product?.partnumber?.modelo || '',
                    serial_equipamento_remessa: item?.product?.serial || '',
                    status_transportadora_equipamento_remessa: item?.status_entrega_remessa || '',
                    modal_envio_equipamento_remessa: item?.modal_envio || '',
                    data_postagem_equipamento_remessa: item?.data_postagem_remessa || '',
                    rastreamento_equipamento_remessa: item?.rastreamento || '',
                    status_remessa: item?.status_remessa || '',
                    prazo_entrega_equipamento_remessa: item?.prazo_entrega || '',
                });
            });

            setEquipmentsData(dadosEquipamentosArray);

        } else {
            setEquipmentsData([]);
        }
    }

    async function getTicketDetailById() {
        let dataReturn = await getTicketById(id);
        if (dataReturn?.data?.response === 'success') {
            let detailsData = dataReturn?.data?.data;
            let cliente = detailsData?.customer;
            let representante = detailsData?.partner;
            let tecnico = detailsData?.technician;
            let enderecoPartida = {...detailsData?.start_address};
            let contrato = detailsData?.contract;
            let integradora = contrato?.integrator;
            let valoresComerciais = detailsData?.partner_commercial_value;
            let valoresEspeciais = detailsData?.special_partner_value;
            let ticketTags = detailsData?.ticket_tags;

            let dataAgendamento = detailsData?.data_agendamento?.split("/") || '';
            let dataInicioAtendimento = detailsData?.data_inicio_atendimento?.split("/") || '';
            let dataDesejada = detailsData?.data_desejada?.split("/") || '';

            let escopo = '';

            if (valoresComerciais?.contract_value?.escopo !== '') {
                escopo = valoresComerciais?.contract_value?.escopo;
            }

            if (valoresEspeciais?.partner_value?.contract_value?.escopo !== '') {
                escopo = valoresEspeciais?.partner_value?.contract_value?.escopo;
            }

            setOriginalData(prevState => ({
                ...prevState,
                ...detailsData,
                escopo_valores: escopo
            }));
            //remove o id do endereço de partida para não sobreescrever o id do chamado;
            delete enderecoPartida?.id;

            setDetailsData(prevState => (
                {
                    ...prevState,
                    ...detailsData,
                    escopo_valores: escopo,
                    data_desejada: dataDesejada === '' ? '' : dataDesejada[2] + "-" + dataDesejada[1].padStart(2, '0') + "-" + dataDesejada[0].padStart(2, '0'),
                    data_agendamento: dataAgendamento === '' ? '' : dataAgendamento[2] + "-" + dataAgendamento[1].padStart(2, '0') + "-" + dataAgendamento[0].padStart(2, '0'),
                    data_inicio_atendimento: dataInicioAtendimento === '' ? '' : dataInicioAtendimento[2] + "-" + dataInicioAtendimento[1].padStart(2, '0') + "-" + dataInicioAtendimento[0].padStart(2, '0'),
                    tipo_atividade: { value: detailsData.tipo_atividade, label: detailsData.tipo_atividade },
                    status_chamado: { value: detailsData.status_chamado, label: detailsData.status_chamado },
                    urgencia: { value: detailsData.urgencia, label: detailsData.urgencia },
                    prioridade: { value: detailsData.prioridade, label: detailsData.prioridade },
                    documentacao_entregue: { value: detailsData.documentacao_entregue, label: detailsData.documentacao_entregue },
                    liberar_pagamento: { value: detailsData.liberar_pagamento, label: detailsData.liberar_pagamento },
                    ocasiao_fechamento: { value: detailsData?.ocasiao_fechamento, label: detailsData?.ocasiao_fechamento },
                    motivo_improdutivo: { value: detailsData?.motivo_improdutivo, label: detailsData?.motivo_improdutivo },
                    id_contrato: { value: contrato?.id, label: contrato?.contrato },
                    id_integradora: { value: integradora?.id, label: integradora?.nome },
                    hora_inicio_atendimento: detailsData?.hora_inicio_atendimento,
                    data_encerramento: detailsData?.data_encerramento,
                    hora_encerramento: detailsData?.hora_encerramento,
                    senha_integradora: detailsData?.senha_integradora,
                    tel_contato_analista_integracao: detailsData?.tel_contato_analista_integracao,
                    nome_analista_integracao: detailsData?.nome_analista_integracao,
                    documento_matricula: detailsData?.documento_matricula,
                    justificativa_ocasiao_fechamento: detailsData?.justificativa_ocasiao_fechamento,
                    descricao_fechamento: detailsData?.descricao_fechamento,
                    id_cliente: cliente?.id,
                    cep_endereco_cliente: cliente?.cep_cliente,
                    estado_endereco_cliente: cliente?.estado_cliente,
                    cidade_endereco_cliente: cliente?.cidade_cliente,
                    bairro_endereco_cliente: cliente?.bairro_cliente,
                    numero_complemento: cliente?.compl_ref_obs_endereco_cliente,
                    nome_cliente: cliente?.nome_cliente,
                    nome_contato_cliente: cliente?.nome_contato_cliente,
                    telefone_contato_cliente: cliente?.telefone_contato_cliente,
                    email_contato_cliente: cliente?.email_contato_cliente,
                    endereco_cliente: cliente?.endereco_cliente,
                    id_tecnico: { value: tecnico?.id, label: tecnico?.nome_tec },
                    id_representante: { value: representante?.id, label: representante?.nome_representante },
                    id_representante_valor_comercial: { value: valoresComerciais?.id, label: valoresComerciais?.contract_value?.escopo },
                    id_valores_especiais: valoresEspeciais?.id || '',
                    escopo_valores_especiais: valoresEspeciais?.partner_value?.contract_value?.escopo || '',
                    protocolo_anatel: detailsData?.protocolo_anatel || '',
                    os_ba: detailsData?.os_ba || '',
                    info_adicional_circuito: detailsData?.info_adicional_circuito || "",
                    ticket_tags: ticketTags?.map(item => ({value: item?.id_tag, label: item?.nome})),
                    ...enderecoPartida
                }
            ));
        }else{
            handleErrorsToast("Ocorreu um erro ao carregar os dados!", dataReturn?.data);
            if(dataReturn?.data?.message === 'Ticket not found!'){
                navigate(`/chamados/dados/all`);
            }
        }
    }

    async function setEmail() {
        let descricao = detailsData?.descricao || '';
        descricao = descricao.replace(/(\r\n|\n|\r)/g, '%0D%0A');

        let body = `Nº Chamado: ${detailsData?.chamado || ''}%0D%0A
Cliente: ${detailsData?.customer?.nome_cliente || ''}%0D%0A
Agendamento: ${detailsData?.data_agendamento || ''} ${detailsData?.hora_agendamento || ''}%0D%0A
Integradora: ${detailsData?.contract?.integrator?.nome_fantasia || ''}%0D%0A
Representante: ${detailsData?.technician?.partner?.nome_representante || detailsData?.partner?.nome_representante || ''}%0D%0A
Técnico: ${detailsData?.technician?.nome_tec || ''}%0D%0A%0D%0A`;

        //Condicional Logistica
        if (equipmentsData.length > 0) {
            body += `
Equipamentos:%0D%0A`;
            let equipamentos = equipmentsData.map((item => {
                return `Logística ${item?.tipo_remessa || ''}:%0D%0A
Destinatário:  ${item?.destinatario || ''}%0D%0A
Endereço: ${item?.rua || ''} ${item?.numero || ''}%0D%0A
Bairro: ${item?.bairro || ''}%0D%0A
Cidade: ${item?.cidade || ''}%0D%0A
Estado ${item?.uf || ''}%0D%0A
CEP: ${item?.cep || ''}%0D%0A
OBS: ${item?.complemento || ''}%0D%0A                
Nome do Produto: ${item?.part_number_equipamento_remessa || ''}%0D%0A
Serial: ${item?.serial_equipamento_remessa || ''}%0D%0A
Rastreio: ${item?.rastreamento_equipamento_remessa || ''}%0D%0A
Modal: ${item?.modal_envio_equipamento_remessa || ''}%0D%0A%0D%0A`
            }));

            for (let e of equipamentos) {
                body += e;
            }
        }

        body += `Endereço Cliente: ${detailsData?.customer?.endereco_cliente || ''}%0D%0A
Bairro: ${detailsData?.customer?.bairro_cliente || ''}%0D%0A
Cidade: ${detailsData?.customer?.cidade_cliente || ''}%0D%0A
Estado ${detailsData?.customer?.estado_cliente || ''}%0D%0A
CEP: ${detailsData?.customer?.cep_cliente || ''}%0D%0A
OBS: ${detailsData?.customer?.compl_ref_obs_endereco_cliente || ''}%0D%0A
Descrição: ${descricao} `;

        body = body.replace(/[\?|&|,]/g, '');
        body = body.replace(/"/g, "''");

        window.location.href = `mailto:atendimento@maminfo.com.br,${detailsData?.technician?.email_tec || ''}?subject=Abertura de Chamado Nº ${detailsData?.chamado} - Maminfo&body=${body}`
    }

    async function getHistoryByType(type) {
        let dataReturn = await getHistoryByTicketId(id, type);
        let divisor = '---------------------------------------------------------------------------------------------------------------------------------------------------------\n';
        let historico = '';
        let historicoTecnico = '';

        if (dataReturn?.data?.response === 'success') {
            let historicoArray = dataReturn?.data?.data;

            for (let h of historicoArray) {
                let historicoParcial = '';
                historicoParcial += divisor;

                historicoParcial += `${h?.data_registro} - ${h?.login} - ${h?.nome} - ${h?.email} - ${h?.telefone}\n`;

                if (type === 'interna' && h?.nota_tecnico === 0) {
                    historicoParcial += `Status ${h?.status}\n`;
                }

                if (h?.status === 'Fechado') {
                    historicoParcial += `Solução de Fechamento: ${h?.descricao}\n`;
                } else {
                    historicoParcial += `Nota: ${h?.descricao}\n`;
                }

                historico += historicoParcial;

                if (type === 'interna' && h?.nota_tecnico === 1) {
                    historicoTecnico += historicoParcial;
                }
            }

            switch(type){
                case 'interna':
                    setHistorico(historico);
                    setHistoricoTecnico(historicoTecnico);
                    break;
                case 'externa':
                    setHistoricoExternoCliente(historico);
                    break;
                case 'backlog':
                    setHistoricoBacklog(historico);
                    break;
                case 'parceria':
                    setHistoricoParcerias(historico);
                    break;
                default:
                    break;
            }           
        }
    }

    async function getHistory() {
        for(let t of ['interna', 'externa', 'backlog', 'parceria']){
            getHistoryByType(t);
        }
    }

    async function generateChildTicket() {
        let response = await mySwal.fire({
            titleText: `Chamado Filho!`,
            text: `Você deseja mesmo gerar um chamado filho?`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
        });

        if (response.isConfirmed) {
            const toastChildTicket = toast.loading("Gerando chamado filho, aguarde...");

            let dataReturn = await generateChildTicketByTicketId(id);

            if (dataReturn?.data?.response === 'success') {
                let chamado = dataReturn?.data?.data;
                handleSuccessToast('Chamado filho gerado com sucesso!', 'update', toastChildTicket);
                goToChildTicket(chamado);
            } else {
                handleErrorsToast('Ocorreu um erro ao gerar o chamado filho!', dataReturn?.data, 'update', toastChildTicket);
            }
        }
    }

    async function goToChildTicket(chamado) {
        copyToClipboard(chamado.num_chamado);

        let response = await mySwal.fire({
            titleText: `Chamado Filho Gerado com Sucesso!`,

            html: (
                <>
                    <h2>Ticket: {chamado.num_chamado}</h2>
                    <div style={{ textAlign: 'left' }}>
                        <p>Ticket copiado com sucesso!</p>
                        <p>Deseja sair do chamado atual e acessar os detalhes do chamado filho gerado?</p>
                    </div>
                </>),
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
        });

        if (response.isConfirmed) {
            navigate(`/chamados/detalhes-chamado/${chamado.id}`);
        }

    }

    async function getPasswordsData() {
        let dataReturn = await getPasswordsDataByTicketId(id);

        if (dataReturn?.data?.length > 0) {
            let passwordsData = dataReturn?.data;

            let passwordsTable = passwordsData.map((item) => {
                return ({ senha_password: item.senha_atendimento, atividade_password: item.atividade_senha_atendimento, data_criacao_password: item.data_registro_br })
            });

            setPasswordData(passwordsTable);
        } else {
            setPasswordData([]);
        }
    }

    async function updateTicket() {
        let proceed = true;

        if(originalData?.status_chamado !== 'Fechado' && detailsData?.status_chamado?.value === 'Fechado' && equipmentsData.length > 0){
            proceed = checkUpdatedShipments();
        }

        if(!proceed){
            return;
        }
        
        let formData = {
            ...detailsData,
            data_fim_deslocamento: formatDate(detailsData?.data_fim_deslocamento),
            data_inicio_deslocamento: formatDate(detailsData?.data_inicio_deslocamento),
            data_solicitacao: formatDate(detailsData?.data_solicitacao) || '',
            data_desejada: formatDate(detailsData?.data_desejada) || '',
            // status_chamado: detailsData?.tem_conflito_chamado ? 'Aprovação do Responsável' : detailsData?.status_chamado?.value || '',
            status_chamado: detailsData?.status_chamado?.value || '',
            data_agendamento: formatDate(detailsData?.data_agendamento) || '',
            hora_agendamento: detailsData?.hora_agendamento || '',
            id_integradora: detailsData?.id_integradora?.value || '',
            id_contrato: detailsData?.id_contrato?.value || '',
            id_representante: detailsData?.id_representante?.value || '',
            descricao: detailsData?.descricao || '',
            tipo_atividade: detailsData?.tipo_atividade?.value || '',
            km_tecnico: detailsData?.km_tecnico || 0,
            num_chamado: detailsData?.num_chamado || '',
            fabricante: detailsData?.fabricantes || '',
            info_adicional_circuito: detailsData?.info_adicional_circuito || '',
            numero_rma: detailsData?.numero_rma || '',

            id_cliente: detailsData?.id_cliente || '',
            nome_cliente: detailsData?.nome_cliente || '',
            nome_contato_cliente: detailsData?.nome_contato_cliente || '',
            telefone_contato_cliente: detailsData?.telefone_contato_cliente || '',
            email_contato_cliente: detailsData?.email_contato_cliente || '',
            cep_endereco_cliente: detailsData?.cep_endereco_cliente || '',
            estado_endereco_cliente: detailsData?.estado_endereco_cliente || '',
            cidade_endereco_cliente: detailsData?.cidade_endereco_cliente || '',
            bairro_endereco_cliente: detailsData?.bairro_endereco_cliente || '',
            logradouro_endereco_cliente: detailsData?.endereco_cliente || '',
            compl_ref_obs_endereco_cliente: detailsData?.numero_complemento || '',

            id_tecnico: detailsData?.id_tecnico?.value || '',
            id_representante_valor_comercial: detailsData?.id_representante_valor_comercial?.value || '',

            logradouro_endereco_partida: detailsData?.logradouro_endereco_partida || '',
            compl_ref_obs_endereco_partida: detailsData?.compl_ref_obs_endereco_partida || '',
            cep_endereco_partida: detailsData?.cep_endereco_partida || '',
            bairro_endereco_partida: detailsData?.bairro_endereco_partida || '',
            estado_endereco_partida: detailsData?.estado_endereco_partida || '',
            cidade_endereco_partida: detailsData?.cidade_endereco_partida || '',

            urgencia: detailsData?.urgencia?.value || '',
            prioridade: detailsData?.prioridade?.value || '',

            data_inicio_atendimento: formatDate(detailsData?.data_inicio_atendimento) || '',
            hora_inicio_atendimento: detailsData?.hora_inicio_atendimento || '',
            data_encerramento: formatDate(detailsData?.data_encerramento) || '',
            hora_encerramento: detailsData?.hora_encerramento || '',
            senha_integradora: detailsData?.senha_integradora || '',
            tel_contato_analista_integracao: detailsData?.tel_contato_analista_integracao || '',
            nome_analista_integracao: detailsData?.nome_analista_integracao || '',
            documento_matricula: detailsData?.documento_matricula || '',
            ocasiao_fechamento: detailsData?.ocasiao_fechamento?.value || '',
            motivo_improdutivo: detailsData?.motivo_improdutivo?.label || '',
            justificativa_ocasiao_fechamento: detailsData?.justificativa_ocasiao_fechamento || '',
            descricao_fechamento: detailsData?.descricao_fechamento || '',

            protocolo_anatel: detailsData?.protocolo_anatel || '',
            os_ba: detailsData?.os_ba || '',

            responsavel_improdutivo: detailsData?.responsavel_improdutivo?.label || ''
        }

        if(['', undefined, null].includes(formData?.bairro_endereco_partida)){
            handleErrorsToast('Preencha o bairro o endereço de partida!');
            return;
        }

        // if(detailsData?.id_integradora?.label === 'Oi S/A'){
        //     let errors = [];

        //     if(checkEmptyString(formData?.os_ba)){
        //         errors.push(<p key={'erro_1'}>O campo OS/BA é obrigatório quando a Integradora é OI/SA.</p>);
        //     }

        //     if(checkEmptyString(formData?.info_adicional_circuito)){
        //         errors.push(<p key={'erro_2'}>O campo circuito é obrigatório quando a Integradora é OI/SA.</p>);
        //     }

        //     if(errors.length > 0){
        //         handleErrorsToast(errors);
        //         return;
        //     }
        // }

        if(formData?.status_chamado === 'Fechado'){
            let errors = [];

            if(['', undefined, null].includes(formData?.data_inicio_atendimento)){
                errors.push(<p key={'erro_1'}>Preencha a data de início do atendimento</p>);
            }

            if(['', undefined, null].includes(formData?.hora_inicio_atendimento)){
                errors.push(<p key={'erro_2'}>Preencha a hora de início do atendimento</p>);
            }

            if(['', undefined, null].includes(formData?.data_encerramento)){
                errors.push(<p key={'erro_3'}>Preencha a data de encerramento</p>);
            }

            if(['', undefined, null].includes(formData?.hora_encerramento)){
                errors.push(<p key={'erro_4'}>Preencha a hora de encerramento</p>);
            }

            if(['', undefined, null].includes(formData?.ocasiao_fechamento)){
                errors.push(<p key={'erro_5'}>Preencha a ocasião de fechamento</p>);
            }

            if(formData?.ocasiao_fechamento === 'Improdutivo' && ['', undefined, null].includes(formData?.motivo_improdutivo)){
                errors.push(<p key={'erro_6'}>Preencha o motivo improdutivo</p>);
            }
            
            if(formData?.ocasiao_fechamento === 'Improdutivo' && checkEmptyString(formData?.justificativa_ocasiao_fechamento)){
                errors.push(<p key={'erro_7'}>Preencha a justificativa do motivo improdutivo</p>);
            }

            if(detailsData?.id_integradora?.label === 'Oi S/A' && checkEmptyString(formData?.protocolo_anatel)){
                errors.push(<p key={'erro_8'}>O campo Protocolo Anatel é obrigatório quando a Integradora é OI/SA.</p>);
            }

            if(formData?.ocasiao_fechamento === 'Improdutivo' && checkEmptyString(formData?.responsavel_improdutivo)){
                errors.push(<p key={'erro_9'}>Preencha o responsável improdutivo</p>);
            }

            // if(checkEmptyString(formData?.descricao_fechamento)){
            //     errors.push(<p key={'erro_9'}>Preencha a descrição do fechamento</p>);
            // }

            if(errors.length > 0){
                handleErrorsToast(errors);
                return;
            }
        }

        const toastUpdateTicket = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateDataByTicketId(id, formData);

        if (dataReturn?.data?.response === 'Ticket updated successfully!') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastUpdateTicket);

            if (detailsData?.status_chamado?.value === 'Fechado' && passwordData.length === 0 && checkPermission(pagePermissions[0])) {
                await showCreatePasswordSwal();
            }

            updateAllData();
            resetBtns();

            if (detailsData?.nota_externa_cliente?.length > 0) {
                await Swal.fire("Você possui uma nota externa não salva!");
            }

            if (detailsData?.nota_chamado?.length > 0) {
                await Swal.fire("Você possui uma nota interna não salva!");
            }

            setDetailsData(prev => ({...prev, tem_conflito_chamado: false}));
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastUpdateTicket);
        }
    }

    async function showCreatePasswordSwal() {
        let swal = await Swal.fire({
            title: 'Gostaria de Gerar uma senha de atendimento?',
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        });

        if (swal.isConfirmed) {
            await handleScrollToViewById('senhasAtendimentoCard');
            setIsCadastrarSenhaOpen(true);
        }
    }

    function updateAllData() {
        handleSetUpdateAttachmentData(true);
        getTicketDetailById();
        getHistory();
    }

    function resetBtns() {
        setShowUpdateBtn(false);
        setShowAlterarClienteBtn(false);
        setShowAlterarTecnicoBtn(false);
        setShowAlterarKmBtn(false);
        setShowAlterarNumeroChamadoBtn(false);
        setShowAlterarCircuitoBtn(false);
        setShowFabricanteBtn(false);
        setShowContratoBtn(false);
        setShowNumeroRmaBtn(false);
        setShowNovaDataAgendamentoBtn(false);
        setShowDataDesejadaBtn(false);
        setShowAlterarOsBaBtn(false);
    }

    function handleAlterarDados(enabled){
        if(enabled){
            setShowUpdateBtn(true);
        }else{
            resetBtns();
            updateAllData();
        }
    }

    async function assignTicketByEmailClick() {

        setEmail();
        let formData = {
            ids_chamado: [id],
            id_responsavel: userData?.userDataState?.id_usuario || ''
        }
        let dataReturn = await assignCreatedTicket(formData);

        if (dataReturn?.data?.data?.message === 'Tickets atualizados com sucesso!') {
            updateAllData();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o chamado!', dataReturn?.data);
        }
    }

    function checkUpdatedShipments(){
        let filteredEquipments = equipmentsData.filter(item => !confirmedShipments.includes(item.id_remessa));
        if(filteredEquipments.length > 0){
            mySwal.fire({
                title: 'Remessas com Confirmação Pendente',
                text: 'Por favor, confirme os dados das remessas em vermelho clicando em Ações e em seguida no botão Confirmar Remessa. As remessas em branco já estão confirmadas e não necessitam de ação.'
            });
            handleScrollToViewById("equipamentosRemessaCard");
            return false;
        }
        
        return true;
    }

    useEffect(() => {
        updateAllData();
    }, [location]);

    useEffect(() => {
        if (showAlterarNumeroChamadoBtn !== false) {
            handleScrollToViewById("updateNumChamado");
        }
    }, [showAlterarNumeroChamadoBtn]);

    useEffect(() => {
        if (showAlterarCircuitoBtn !== false) {
            handleScrollToViewById("updateCircuito");
        }
    }, [showAlterarCircuitoBtn]);

    useEffect(() => {
        if (showAlterarClienteBtn !== false) {
            handleScrollToViewById("updateCustomerData");
        }
    }, [showAlterarClienteBtn]);

    useEffect(() => {
        if (showAlterarTecnicoBtn !== false) {
            handleScrollToViewById("updateTechnician");
        }
    }, [showAlterarTecnicoBtn]);

    useEffect(() => {
        if (showFabricanteBtn !== false) {
            handleScrollToViewById("updateManufacturer");
        }
    }, [showFabricanteBtn]);

    useEffect(() => {
        if (showNumeroRmaBtn !== false) {
            handleScrollToViewById("updateRmaNumber");
        }
    }, [showNumeroRmaBtn]);

    useEffect(() => {
        if (showContratoBtn !== false) {
            handleScrollToViewById("updateContract");
        }
    }, [showContratoBtn]);

    useEffect(() => {
        if (showNovaDataAgendamentoBtn !== false) {
            handleScrollToViewById("updateSchedule");
        }
    }, [showNovaDataAgendamentoBtn]);

    useEffect(()=>{
        if(showDataDesejadaBtn !== false){
            handleScrollToViewById("updateDataDesejada");
        }
    }, [showDataDesejadaBtn]);

    useEffect(() => {
        if (showAlterarKmBtn) {
            handleScrollToViewById("updateKm");
        }
    }, [showAlterarKmBtn]);

    useEffect(()=>{
        if(showAlterarOsBaBtn){
            handleScrollToViewById("updateOsBa");
        }
    }, [showAlterarOsBaBtn]);

    useEffect(() => {
        let statusChamado = detailsData?.status_chamado?.value;

        if (["Fechado", "fechado"].includes(statusChamado) && !['Fechado', 'fechado'].includes(originalData?.status_chamado)) {
            setShowNovaDataAgendamentoBtn(false);
            handleScrollToViewById("registroTrabalhoFechamento");
        }

        if (["Agendamento Sugerido (CLI)", "Agendamento Desejado (CLI)"].includes(statusChamado) && !["Agendamento Sugerido (CLI)", "Agendamento Desejado (CLI)"].includes(originalData?.status_chamado)) {
            setShowNovaDataAgendamentoBtn(true);
            handleScrollToViewById("updateSchedule");
        }
    }, [detailsData?.status_chamado]);

    return (
        <div>
            <Content headerTitle="Detalhes" showChat={false}>
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <FloatSearchButton></FloatSearchButton>
                <If condition={checkPermission(pagePermissionsChat[1])}>
                    <FloatChatButton ticketId={originalData?.id} floatOpened={floatChatOpened} setFloatOpened={setFloatChatOpened} channelId={channelId} setChannelId={setChannelId} listenTo='ticket'></FloatChatButton>
                </If>
                <CreateSenhaAtendimento
                    isOpen={isCadastrarSenhaOpen}
                    setIsOpen={() => setIsCadastrarSenhaOpen(!isCadastrarSenhaOpen)}
                    detailsData={detailsData}
                    id={id}
                    updateParent={() => {
                        getHistory();
                        getPasswordsData();
                    }
                    }></CreateSenhaAtendimento>
                <If condition={showUpdateBtn}>
                    <button type='button' className='btn btn-primary' style={{ position: 'fixed', top: 225, right: 10, zIndex: 9999 }} onClick={updateTicket}>Salvar</button>
                </If>
                <div className='row mb-2'>
                    <div className="col-12">
                        <If condition={(!["Cancelado", "Fechado", "Aprovação do Responsável"].includes(originalData?.status_chamado) && checkPermission(pagePermissions[1])) 
                            || (originalData?.status_chamado === "Cancelado" && checkPermission(detailsPermission[1]))
                            || (originalData?.status_chamado === "Aprovação do Responsável" && checkPermission(detailsPermission[0]))
                            || (originalData?.status_chamado === "Fechado" && checkPermission(detailsPermission[2]))}>
                            <button className="btn btn-secondary float-left m-1" onClick={() => handleAlterarDados(!showUpdateBtn)}>Alterar / Adicionar Nota</button>
                        </If>
                        <If condition={originalData?.status_chamado !== "Aprovação do Responsável" && checkPermission(pagePermissions[2])}>
                            <button className="btn btn-primary float-right m-1" onClick={assignTicketByEmailClick}>Email</button>
                        </If>
                        <If condition={checkPermission(pagePermissions[3])}>
                            <button className="btn btn-primary float-right m-1" onClick={generateChildTicket}>Gerar Chamado Filho</button>
                        </If>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <ChamadosCardData
                            handleScrollToViewById={handleScrollToViewById}
                            detailsData={detailsData}
                            originalData={originalData}
                            setDetailsData={setDetailsData}
                            setShowFabricanteBtn={setShowFabricanteBtn}
                            setShowAlterarNumeroChamadoBtn={setShowAlterarNumeroChamadoBtn}
                            setShowAlterarCircuitoBtn={setShowAlterarCircuitoBtn}
                            setShowContratoBtn={setShowContratoBtn}
                            setShowNumeroRmaBtn={setShowNumeroRmaBtn}
                            setShowAlterarOsBaBtn={setShowAlterarOsBaBtn}
                            showUpdateBtn={showUpdateBtn}
                        >
                        </ChamadosCardData>
                    </div>
                    {/* <If condition={!['Logística', 'Logística - Retorno'].includes(detailsData?.tipo_atividade?.value) || !['Sim - Envio Técnico'].includes(detailsData?.envolve_logistica)}> */}
                    <div className='col-md-3'>
                        <ClienteCardData
                            originalData={originalData}
                            setShowAlterarClienteBtn={setShowAlterarClienteBtn}
                            showUpdateBtn={showUpdateBtn}
                        ></ClienteCardData>
                    </div>
                    {/* </If> */}
                    <div className='col-md-3'>
                        <AtendimentoCard
                            detailsData={detailsData}
                            originalData={originalData}
                            setShowAlterarKmBtn={setShowAlterarKmBtn}
                            setShowAlterarTecnicoBtn={setShowAlterarTecnicoBtn}
                            setShowNovaDataAgendamentoBtn={setShowNovaDataAgendamentoBtn}
                            setShowDataDesejadaBtn={setShowDataDesejadaBtn}
                            showUpdateBtn={showUpdateBtn}
                        ></AtendimentoCard>
                    </div>
                    <div className='col-md-3'>
                        <StatusCard
                            detailsData={detailsData}
                            originalData={originalData}
                            showUpdateBtn={showUpdateBtn}
                            setDetailsData={setDetailsData}
                        ></StatusCard>
                    </div>
                    <div className="col-12">
                        <DetalhesAcionamentoCard
                            detailsData={detailsData}
                            setDetailsData={setDetailsData}
                            showUpdateBtn={showUpdateBtn}
                        ></DetalhesAcionamentoCard>
                    </div>
                    <If condition={(checkPermission(pagePermissions[4]) && ["Suporte interno (Fila)", "Suporte interno (Atendimento)"].includes(detailsData?.status_chamado?.value))}>
                        <div className="col-12">
                            <SuporteAnalistaResponsavelCard
                                id={id}
                                getHistory={getHistory}
                                originalData={originalData}
                                getTicketDetailById={getTicketDetailById}
                            ></SuporteAnalistaResponsavelCard>
                        </div>
                    </If>
                    <If condition={checkPermission(pagePermissions[5])}>
                        <div className='col-12'>
                            <MapData id={id} originalData={originalData}></MapData>
                        </div>
                    </If>
                    <If condition={(originalData?.status_chamado === "Fechado" && checkPermission(pagePermissions[6]))}>
                        <div className="col-12">
                            <ValidarDocumentacaoCard detailsData={detailsData} id={id} originalData={originalData} setDetailsData={setDetailsData} updateAllData={updateAllData}></ValidarDocumentacaoCard>
                        </div>
                    </If>
                    <If condition={originalData?.status_chamado === "Fechado" && checkPermission(pagePermissions[7])}>
                        <div className="col-12">
                            <HistoricoBacklogCard
                                getHistory={getHistory}
                                originalData={originalData}
                                id={id}
                            ></HistoricoBacklogCard>
                        </div>
                    </If>
                    <If condition={showAlterarClienteBtn}>
                        <div className='col-12' id="updateCustomerData">
                            <DadosClienteCard
                                detailsData={detailsData}
                                setDetailsData={setDetailsData}
                            ></DadosClienteCard>
                        </div>
                    </If>
                    <If condition={showAlterarKmBtn}>
                        <div className="col-12" id="updateKm">
                            <NovoKmCard detailsData={detailsData} setDetailsData={setDetailsData}></NovoKmCard>
                        </div>
                    </If>
                    <If condition={(showAlterarTecnicoBtn)}>
                        <div className="col-12" id="updateTechnician">
                            <DadosTecnicoCard
                                detailsData={detailsData}
                                originalData={originalData}
                                setDetailsData={setDetailsData}
                                showAlterarTecnicoBtn={showAlterarTecnicoBtn}
                            ></DadosTecnicoCard>
                        </div>
                    </If>
                    <If condition={showAlterarNumeroChamadoBtn}>
                        <div className="col-12" id='updateNumChamado'>
                            <NumeroChamadoCard detailsData={detailsData} setDetailsData={setDetailsData}></NumeroChamadoCard>
                        </div>
                    </If>
                    <If condition={showAlterarCircuitoBtn}>
                        <div className="col-12" id="updateCircuito">
                            <CircuitoCard detailsData={detailsData} setDetailsData={setDetailsData}></CircuitoCard>
                        </div>
                    </If>
                    <If condition={showAlterarOsBaBtn}>
                        <div className="col-12" id="updateOsBa">
                            <OsBaCard detailsData={detailsData} setDetailsData={setDetailsData}></OsBaCard>
                        </div>
                    </If>
                    <If condition={showFabricanteBtn}>
                        <div className="col-12" id="updateManufacturer">
                            <FabricanteCard detailsData={detailsData} setDetailsData={setDetailsData}></FabricanteCard>
                        </div>
                    </If>
                    <If condition={showContratoBtn}>
                        <div className="col-12" id="updateContract">
                            <NovoContratoCard detailsData={detailsData} setDetailsData={setDetailsData}></NovoContratoCard>
                        </div>
                    </If>
                    <If condition={showNumeroRmaBtn}>
                        <div className="col-12" id="updateRmaNumber">
                            <NovoNumeroRMACard detailsData={detailsData} setDetailsData={setDetailsData}></NovoNumeroRMACard>
                        </div>
                    </If>
                    <If condition={showNovaDataAgendamentoBtn}>
                        <div className="col-12" id="updateSchedule">
                            <NovaDataAgendamento detailsData={detailsData} setDetailsData={setDetailsData}></NovaDataAgendamento>
                        </div>
                    </If>
                    <If condition={showDataDesejadaBtn}>
                        <div className="col-12" id="updateDataDesejada">
                            <DataDesejada detailsData={detailsData} setDetailsData={setDetailsData}></DataDesejada>
                        </div>
                    </If>
                    <div className='col-12'>
                        <RegistroTrabalhoCard
                            detailsData={detailsData}
                            getHistory={getHistory}
                            historico={historico}
                            historicoExternoCliente={historicoExternoCliente}
                            historicoTecnico={historicoTecnico}
                            historicoBacklog={historicoBacklog}
                            historicoParcerias={historicoParcerias}
                            originalData={originalData}
                            setChannelId={setChannelId}
                            setDetailsData={setDetailsData}
                            setFloatChatOpened={setFloatChatOpened}
                            id={id}
                            imgArray={imgArray}
                            imgThumbArray={imgThumbArray}
                        ></RegistroTrabalhoCard>
                    </div>
                    <If condition={checkPermission(pagePermissions[8]) && originalData?.status_chamado === 'Fechado'}>
                        <div className="col-12" id='senhasAtendimentoCard'>
                            <SenhaAtendimentoCard
                                getHistory={getHistory}
                                detailsData={detailsData}
                                id={id}
                                passwordData={passwordData}
                                getPasswordsData={getPasswordsData}
                            ></SenhaAtendimentoCard>
                        </div>
                    </If>
                    <If condition={checkPermission(pagePermissions[9])}>
                    <div className='col-12'>
                        <AttachmentData id={id} detailsData={detailsData} originalData={originalData} setImgArray={setImgArray} setImgThumbArray={setImgThumbArray} updateData={updateAttachmentData} setUpdateData={handleSetUpdateAttachmentData}></AttachmentData>
                    </div>
                    </If>
                    <If condition={(["Fechado"].includes(detailsData?.status_chamado?.value) && checkPermission(pagePermissions[10]))}>
                        <div className="col-12">
                            <EquipamentoRetornoCard
                                id={id}
                                getHistory={getHistory}
                            ></EquipamentoRetornoCard>
                        </div>
                    </If>
                    {/* <If condition={originalData?.tipo_atividade !== "Logística - Retorno"}> */}
                    <div className="col-12" id="equipamentosRemessaCard">
                        <EquipamentoRemessaCard
                            detailsData={detailsData}
                            getHistory={getHistory}
                            id={id}
                            showUpdateBtn={showUpdateBtn}
                            equipmentsData={equipmentsData}
                            confirmedShipments={confirmedShipments}
                            setConfirmedShipments={setConfirmedShipments}
                            getEquipmentsByIdStatus={getEquipmentsByIdStatus}
                            originalData={originalData}
                        ></EquipamentoRemessaCard>
                    </div>
                    {/* </If> */}
                    <If condition={(originalData?.status_chamado !== "Fechado" && detailsData?.status_chamado?.value === "Fechado")}>
                        <div className="col-12">
                            <HistoricoStatusCard id={id}></HistoricoStatusCard>
                        </div>
                    </If>
                    <If condition={checkPermission(pagePermissions[11])}>
                        <div className="col-12">
                            <PendingExpenseTable id_ticket={originalData?.id} data_inicial={originalData?.data_registro}></PendingExpenseTable>
                        </div>
                    </If>
                </div>
            </Content>
        </div >
    );
}