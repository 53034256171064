import React, { useContext, useEffect, useState } from 'react';
import Modal from '../Layout/Modal';
import { addExternalUser } from '../../services/Chat';
import { ChatContext } from './MainChatComponent';
import { toast } from 'react-toastify';
import { getTicketById } from '../../services/Ticket';
import { handleErrorsToast, handleSuccessToast, getCurrentDate, checkValue } from '../../configs/GenericFunctions';
import { Button, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function ExternalContacts({ title = '', isOpen = false, setModalOpen = null }) {
    const INITIAL_STATE = {
        name: '',
        email: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const { chatState, setChatState } = useContext(ChatContext);

    async function addUser(e) {
        e.preventDefault();

        let ticketData = false;
        let date = getCurrentDate();

        const toastAdd = toast.loading("Enviando email, aguarde...");

        if (!['', null].includes(chatState?.channelData?.ticket_id)) {
            ticketData = await getTicketData();

            if (ticketData !== false && !checkValue(ticketData?.data_agendamento)) {
                let ticketDateAgendamento = ticketData?.data_agendamento?.split("/");

                let temporaryDate = new Date(ticketDateAgendamento[2], (parseInt(ticketDateAgendamento[1]) - 1), (parseInt(ticketDateAgendamento[0]) + 1)).toLocaleDateString('pt-BR').split("/");
                date = temporaryDate[2] + "-" + temporaryDate[1].padStart(2, '0') + "-" + temporaryDate[0].padStart(2, '0');
            }
        }

        let params = {
            ...formData,
            expire_in: date
        }

        let dataReturn = await addExternalUser(chatState?.channelId, params);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Email enviado!', 'update', toastAdd);
            delete dataReturn?.data?.data?.users;
            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                externalMembers: dataReturn?.data?.data?.external_users
            }))

        } else if (dataReturn?.data?.errors?.message === 'E-mail já existe no canal') {
            handleErrorsToast('Este email já está cadastrado!', dataReturn?.data, 'update', toastAdd);
        } else {
            handleErrorsToast('Ocorreu um erro ao enviar o email!', dataReturn?.data, 'update', toastAdd);
        }

        setFormData(INITIAL_STATE);

    }

    async function getTicketData() {
        let dataReturn = await getTicketById(chatState?.channelData?.ticket_id);

        if (dataReturn?.data?.response === 'success') {
            return dataReturn?.data?.data;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <Form onSubmit={addUser}>
                <Row>
                    <Col>
                        <FormLabel htmlFor="name">Nome do Participante</FormLabel>
                        <FormControl type="text" name="name" id="name" placeholder='...' value={formData?.name} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormLabel htmlFor="email">Email do Participante</FormLabel>
                        <FormControl type="email" name="email" id="email" placeholder='...' value={formData?.email} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="submit" variant='success'>Enviar Email</Button>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
}