import React, { useEffect, useState } from 'react';
import { bulkUpdateProducts } from '../../../../services/Product';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import Form from './Form';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { TabPanel, TabView } from 'primereact/tabview';
import GedTable from './Ged/GedTable';

export default function BulkUpdate({ isOpen = false, setModalOpen = null, setUpdateProducts=null, handleClearRows=null, ids=[], setUpdateLogistic = null }) {
    const initialState = {
        nome_produto: '',
        id_modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: '',
        id_localidade: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);

    async function updateProducts(e) {
        e.preventDefault();

        let formData = {
            product_ids: ids,
            ...formCadastroProduto,
            id_modelo: formCadastroProduto.id_modelo?.value || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            valorado: formCadastroProduto.valorado?.value || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            tipo_tecnico: formCadastroProduto.tipo_tecnico || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || ''
        }

        const toastCreateProduct = toast.loading("Atualizando dados em massa, aguarde...");

        let dataReturn = await bulkUpdateProducts(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Produtos atualizados com sucesso!', 'update', toastCreateProduct);
            setFormCadastroProduto(initialState);
            setUpdateProducts();
            setModalOpen();
            if(setUpdateLogistic !== null){
                setUpdateLogistic(true);
            }
            handleClearRows();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os produtos!', dataReturn?.data, 'update', toastCreateProduct);
        }
    }

    useEffect(()=>{
        if(isOpen){
            setFormCadastroProduto(initialState); 
        }
    },[isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header='Atualizar Dados em Massa'
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header="Detalhes do Produto">
                    <Form formData={formCadastroProduto} setFormData={setFormCadastroProduto} mode='bulk-update' submitForm={updateProducts}></Form>
                </TabPanel>
                <TabPanel header="GED">
                    <GedTable addTitle='Novo Documento' submodule='produtos' viewMode='edit' multiple idsProduto={ids}></GedTable>
                </TabPanel>
            </TabView>
        </Dialog>);
}