import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getGroupById, updateGroupById } from '../../services/Group';
import ModulosSelect from '../../common/Selects/ModulosSelect';
import SubmodulosSelect from '../../common/Selects/SubmodulosSelect';
import PermissoesSelect from '../../common/Selects/PermissoesSelect';
import PrimeTable from '../../common/Layout/Table/PrimeTable';
import If from '../../common/Layout/If';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';

export default function UserGroupDetails({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    idUserGroup = '',
    viewMode = 'view'
}) {
    const INITIAL_STATE = {
        descricao: '',
        permissoes: ''
    }

    const INITIAL_PREVIEW_STATE = {
        id_modulo: '',
        id_submodulo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [previewData, setPreviewData] = useState(INITIAL_PREVIEW_STATE);
    const [previewTableData, setPreviewTableData] = useState([]);

    const columns = [
        {
            id: 'action',
            name: 'Excluir',
            body: (row) => {
                return (
                <If condition={viewMode === 'edit'}>
                    <button type='button' className='btn btn-danger' onClick={() => removePermissionFromTable(row)}><i className='fas fa-trash'></i></button>
                </If>)
            }
        },
        {
            id: 'modulo',
            name: 'Modulo'
        },
        {
            id: 'submodulo',
            name: 'Submódulo'
        },
        {
            id: 'permissoes',
            name: 'Permissões',
            filter: false,
            body: (row) => {
                return <PermissoesSelect formData={row} setFormData={setPreviewTableData} isMulti id_submodulo={row.id_submodulo} showTitle={false} advancedSetFormData={(value, name) => handlePermissions(value, name, row)} isDisabled={viewMode === 'view'}></PermissoesSelect>
            }
        }
    ]

    async function getGroup(id) {
        let toastData = toast.loading("Carregando grupo, aguarde...");

        let dataReturn = await getGroupById(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Grupo carregado com sucesso!', 'update', toastData);
            let data = dataReturn?.data?.data;

            let tableData = data?.permissions?.map(item => ({
                id_modulo: item?.submodule?.module?.id || '',
                id_submodulo: item?.id_submodulo || '',
                modulo: item?.submodule?.module?.nome_modulo || '',
                submodulo: item?.submodule?.nome_submodulo || ''
            })).filter((item, idx, self) => idx === self.findIndex((obj) => obj.id_submodulo === item.id_submodulo)) || [];

            tableData = tableData.map((item) => ({
                ...item,
                id_permissao: data?.permissions?.filter((item2, idx) => item2?.id_submodulo === item?.id_submodulo).map(item => ({value: item?.id || '', label: item?.nome || ''}))
            }))

            setFormData(data);
            setPreviewTableData(tableData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar o grupo!', dataReturn?.data, 'update', toastData);
            setIsOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();
        let permissoes = previewTableData.filter(item => item?.id_permissao && item?.id_permissao?.length > 0).flatMap(item => item.id_permissao);

        let params = {
            ...formData,
            permissoes: permissoes.map((item) => ({ id_permissao: item.value }))
        }

        let toastData = toast.loading("Atualizando Grupo de Usuários, aguarde...");

        let dataReturn = await updateGroupById(idUserGroup, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Grupo atualizado com sucesso!', 'update', toastData);
            updateParent();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o grupo!', dataReturn?.data, 'update', toastData);
        }
    }

    function removePermissionFromTable(row) {
        let currentPreviewTableData = previewTableData.filter(item => item.id_submodulo !== row.id_submodulo);

        setPreviewTableData(currentPreviewTableData);
    }

    const handleModules = (value, name) => {
        setPreviewData(prev => ({ ...prev, [name]: value, id_submodulo: '' }));
    }

    const handleSubModules = (value, name) => {
        if (previewTableData.find(item => item?.id_submodulo === value?.value)) {
            return;
        }
        setPreviewTableData(prev => [...prev, {
            id_modulo: previewData?.id_modulo?.value || '',
            id_submodulo: value?.value || '',
            modulo: previewData?.id_modulo?.label || '',
            submodulo: value?.label || '',
        }]);

        setPreviewData(INITIAL_PREVIEW_STATE);
    }

    const handlePermissions = (value, name, row) => {
        setPreviewTableData(prev => prev
            .map(rowAtual =>
                rowAtual.id_submodulo === row.id_submodulo
                    ? { ...rowAtual, [name]: value }
                    : rowAtual
            )
        );
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
        setPreviewData(INITIAL_PREVIEW_STATE);
        setPreviewTableData([]);

        if (isOpen && !['', undefined, null].includes(idUserGroup)) {
            getGroup(idUserGroup);
        } else {
            setFormData(INITIAL_STATE);
            setPreviewData(INITIAL_PREVIEW_STATE);
            setPreviewTableData([]);
        }
    }, [isOpen, idUserGroup]);
    
    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header={viewMode === 'view' ? 'Visualizar Grupo' : 'Editar Grupo'}
            draggable={false}
            resizable={false}
            maximized
        >
            <If condition={viewMode === 'edit'}>
                <div className='row'>
                    <ModulosSelect formData={previewData} setFormData={setPreviewData} advancedSetFormData={handleModules} cols={[12, 6, 6, 6]} required></ModulosSelect>
                    <SubmodulosSelect formData={previewData} setFormData={setPreviewData} advancedSetFormData={handleSubModules} id_modulo={previewData?.id_modulo?.value || ''} cols={[12, 6, 6, 6]} required></SubmodulosSelect>
                </div>
            </If>
            <div className="row">
                <div className="col-12">
                    <h5>Pré-visualizar Permissões</h5>
                    <PrimeTable
                        columns={columns}
                        rows={previewTableData}
                        needExport={false}
                        id='user_group_details'
                    ></PrimeTable>
                </div>
            </div>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className='col-12 mb-1'>
                        <label htmlFor="descricao">Nome do Grupo</label>
                        <input type='text' name='descricao' className='form-control' placeholder='Nome do Grupo de Usuários' value={formData?.descricao || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} required></input>
                    </div>
                    <If condition={viewMode === 'edit'}>
                        <div className='col-12 mt-1'>
                            <button type='submit' className='btn btn-primary' disabled={previewTableData?.filter(item => item?.id_permissao && item?.id_permissao?.length > 0).length < 1 || formData?.descricao?.length < 1}>Finalizar Edição</button>
                        </div>
                    </If>
                </div>
            </form>
        </Dialog >
    );
}