import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import EnvolveLogisticaSelect from '../../../../common/Selects/EnvolveLogisticaSelect';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { addEquipmentByTicketId } from '../../../../services/Ticket';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import If from '../../../../common/Layout/If';
import { getProductByProductId } from '../../../../services/Product';
import ProdutosByIdLocalidadeSelect from '../../../../common/Selects/ProdutosByIdLocalidadeSelect';
import { Dialog } from 'primereact/dialog';
import ProdutosByIdTecnicoSelect from '../../../../common/Selects/ProdutosByIdTecnicoSelect';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { getModelsByManufacturers } from '../../../../services/ProductPartnumber';
import { Button, Col, Form, FormCheck, FormControl, FormLabel, Row } from 'react-bootstrap';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import TodosProdutosSelect from '../../../../common/Selects/TodosProdutosSelect';

export default function CreateProduct({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    id_chamado = '',
    num_chamado = '',
    detailsData = {},
    equipmentsData = []
}) {
    const INITIAL_STATE = {
        id_fabricante: '',
        id_modelo: '',
        envolve_logistica: '',
        modal_envio: '',
        id_produto: '',
        exibe_produto: 'nao',
        qtd_enviar: 1,
        qtd_estoque: 0,
        envolve_logistica_troca_guarda: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [disableButton, setDisableButton] = useState(false);

    async function submitForm(e) {
        e.preventDefault();
        setDisableButton(true);
        let paramsData = { ...formData };
        let remessas = [];
        let available = true;
        //Montando o Payload
        if (['sim_id_localidade', 'sim_estoque_tecnico'].includes(paramsData.exibe_produto)) {
            let dataReturn = await getProductByProductId(formData?.id_produto?.value);

            if (dataReturn?.data?.response === 'success') {
                let manufacturer = dataReturn?.data?.data?.partnumber?.manufacturer;
                let partnumber = dataReturn?.data?.data?.partnumber;
                let product = dataReturn?.data?.data;
                paramsData['id_fabricante'] = { value: manufacturer?.id || '', label: manufacturer?.nome_fabricante || '' };
                paramsData['id_modelo'] = { value: partnumber?.id || '', label: partnumber?.modelo || '' };
                paramsData['id_produto'] = { value: product?.id || '', label: `${product?.nome_produto || ''} - ${product?.serial || ''}` };

                remessas.push({
                    ...paramsData,
                    id_produto: paramsData?.id_produto?.value || '',
                    id_modelo: paramsData?.id_modelo?.value || '',
                    modal_envio: paramsData?.modal_envio?.value || ''
                })
            }
        }

        if (paramsData.exibe_produto === 'sim') {
            remessas.push({
                ...paramsData,
                id_produto: paramsData?.id_produto?.value || '',
                id_modelo: paramsData?.id_modelo?.value || '',
                modal_envio: paramsData?.modal_envio?.value || ''
            })
        }

        if (paramsData.exibe_produto === 'sim_troca_guarda') {
            remessas.push({
                ...paramsData,
                id_produto: paramsData?.id_produto?.value || '',
                id_modelo: paramsData?.id_modelo?.value || '',
                modal_envio: paramsData?.envolve_logistica_troca_guarda?.value === 'NÃO' ? 'N/A' : paramsData?.modal_envio?.value
            });
        }

        if (paramsData.exibe_produto === 'nao') {

            if (paramsData.qtd_enviar > paramsData.qtd_estoque) {
                handleErrorsToast('A quantidade de produtos não pode ser maior que a quantidade em estoque');
                setDisableButton(false);
                return;
            }

            if (paramsData.qtd_enviar < 1) {
                handleErrorsToast('A quantidade de produtos não pode ser menor que 1');
                setDisableButton(false);
                return;
            }

            for (let c = 0; c < paramsData.qtd_enviar; c++) {
                remessas.push({
                    ...paramsData,
                    id_produto: paramsData?.id_produto?.value || '',
                    id_modelo: paramsData?.id_modelo?.value || '',
                    modal_envio: paramsData?.modal_envio?.value || ''
                });
            }
        }

        //Validando os dados
        if (paramsData?.exibe_produto !== 'sim_troca_guarda') {
            available = await checkAvailableProductQuant(remessas);
        }

        if (!available) {
            let message = paramsData.exibe_produto === 'nao' ? 'Este produto não permite mais cadastro de remessas, por favor revise a quantidade e tente novamente!' : 'Este produto não permite mais cadastro de remessas, por favor altere uma remessa com o status PRODUTO PENDENTE já existente e tente novamente!'
            handleErrorsToast(message);
            setDisableButton(false);
            return;
        }

        //Enviando os dados
        let params = {
            remessas: remessas,
            envolve_logistica: setEnvolveLogistica()
        }

        let toastAddProduct = toast.loading("Adicionando Produto, aguarde...");
        let addProductResponse = await addEquipmentByTicketId(id_chamado, params);

        if (addProductResponse?.data?.response === 'success') {
            handleSuccessToast("Produto adicionado com sucesso!", "update", toastAddProduct);
            updateParent();
            setFormData(INITIAL_STATE);
        } else {
            handleErrorsToast("Ocorreu um erro ao adicionar o produto!", addProductResponse?.data, 'update', toastAddProduct);
        }
        setDisableButton(false);
    }

    function setEnvolveLogistica() {
        if (formData?.exibe_produto === 'sim_estoque_tecnico' || formData?.envolve_logistica_troca_guarda?.value === 'NÃO') {
            return 'Não';
        }

        if (formData?.envolve_logistica_troca_guarda?.value === 'SIM') {
            return 'Sim - Troca de Guarda';
        }

        return formData?.envolve_logistica?.value;
    }

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '', id_produto: '', qtd_enviar: 1, qtd_estoque: 0 }));
    }

    function handleExibeProduto(value, name) {
        let params = {
            id_produto: '',
            id_fabricante: '',
            id_modelo: '',
            envolve_logistica: '',
            modal_envio: '',
            qtd_enviar: 1,
            qtd_estoque: 0,
            envolve_logistica_troca_guarda: ''
        }
        setFormData(prev => ({ ...prev, [name]: value, ...params }));
    }

    function handleQtdEnviar(e) {

        if (e.target.value > formData?.qtd_estoque) {
            handleErrorsToast("A quantidade a ser enviada não pode ser maior que a quantidade em estoque");
            return;
        }

        if (e.target.value < 1) {
            handleErrorsToast("A quantidade não pode ser menor que 1");
            return;
        }

        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    async function handleModelo(value, name) {
        let qtd_estoque = value?.label?.split(" - ")[1] || 0;

        setFormData(prev => ({ ...prev, [name]: value, qtd_estoque: +qtd_estoque, id_produto: '' }));
    }

    async function checkAvailableProductQuant(rows) {
        let dataReturn = await getModelsByManufacturers(rows[0]['id_fabricante']['value'], 'only_available');
        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data?.partnumber?.filter(item => item.id === rows[0]['id_modelo']);
            const productModelQuant = equipmentsData?.filter(item => item?.partnumber?.id === rows[0]['id_modelo'] && !['AGUARDANDO CANCELAMENTO', 'CANCELADA', 'REMESSA ENTREGUE AO DESTINATÁRIO', 'AGUARDANDO ENVIO'].includes(item.status_remessa)).length;
            if (data.length < 1) {
                return false;
            }

            if (data[0]?.['available_quantity'] > productModelQuant) {
                return true;
            }

            return false;
        } else {
            return false;
        }
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Produto'
            maximizable={true}
        >
            <div style={{ minWidth: '50vw', maxWidth: '50vw' }}>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col>
                            <FormLabel htmlFor="">Necessário selecionar o serial do produto?</FormLabel>
                            <FormCheck type='radio' id='exibe_produto_nao' name='exibe_produto' label='Não' value={'nao'} checked={formData?.exibe_produto === 'nao'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)}></FormCheck>
                            <FormCheck type='radio' id='exibe_produto' name='exibe_produto' label='Sim - Pesquisar a partir do nome do produto' value={'sim'} checked={formData?.exibe_produto === 'sim'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)}></FormCheck>
                            <FormCheck type='radio' id='exibe_produto_sim_localidade' name='exibe_produto' label='Sim - Pesquisar a partir do ID Localidade' value={'sim_id_localidade'} checked={formData?.exibe_produto === 'sim_id_localidade'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)}></FormCheck>
                            <FormCheck type='radio' id='exibe_produto_sim_estoque_tecnico' name='exibe_produto' label='Sim - Pesquisar a partir do Estoque do Técnico' value={'sim_estoque_tecnico'} checked={formData?.exibe_produto === 'sim_estoque_tecnico'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)}></FormCheck>
                            <FormCheck type='radio' id='exibe_produto_sim_troca_guarda' name='exibe_produto' label='Sim - Troca de Guarda' value={'sim_troca_guarda'} checked={formData?.exibe_produto === 'sim_troca_guarda'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)}></FormCheck>
                        </Col>
                    </Row>
                    <Row>
                        <If condition={['nao', 'sim', 'sim_troca_guarda'].includes(formData?.exibe_produto)}>
                            <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_fabricante' required advancedSetFormData={handleFabricante}></FabricanteSelect>
                            <ModelosSelect
                                formData={formData}
                                setFormData={setFormData}
                                cols={[12, 6, 6, 6]}
                                fieldName='id_modelo'
                                id_fabricante={formData?.id_fabricante?.value || ''}
                                required
                                only_available={formData?.exibe_produto !== 'sim_troca_guarda' ? 'only_available' : ''}
                                show_qty={formData?.exibe_produto === 'nao'}
                                advancedSetFormData={handleModelo}>
                            </ModelosSelect>
                            <If condition={formData?.exibe_produto === 'sim'}>
                                <AsyncProdutosSelect formData={formData} setFormData={setFormData} id_modelo={formData?.id_modelo?.value || ''} fieldName='id_produto' required></AsyncProdutosSelect>
                            </If>
                            <If condition={formData?.exibe_produto === 'sim_troca_guarda'}>
                                <TodosProdutosSelect
                                    formData={formData}
                                    setFormData={setFormData}
                                    cols={[12, 6, 6, 6]}
                                    fieldName='id_produto'
                                    title='Produtos para Troca de Guarda'
                                    required
                                    params={
                                        {
                                            remove_id_tecnico: detailsData?.id_tecnico?.value || '',
                                            id_status_produto: 47,
                                            id_modelo: formData?.id_modelo?.value || ''
                                        }
                                    }
                                >
                                </TodosProdutosSelect>
                            </If>
                        </If>
                        <If condition={formData?.exibe_produto === 'sim_id_localidade'}>
                            <ProdutosByIdLocalidadeSelect formData={formData} setFormData={setFormData} fieldName='id_produto' idLocalidade={num_chamado || ''} required title='Produtos no ID Localidade'></ProdutosByIdLocalidadeSelect>
                        </If>
                        <If condition={formData?.exibe_produto === 'sim_estoque_tecnico'}>
                            <ProdutosByIdTecnicoSelect formData={formData} setFormData={setFormData} fieldName='id_produto' id_tecnico={detailsData?.id_tecnico?.value || ''} required title='Produtos com o Técnico'></ProdutosByIdTecnicoSelect>
                        </If>
                        <If condition={['nao', 'sim', 'sim_id_localidade'].includes(formData?.exibe_produto)}>
                            <EnvolveLogisticaSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='envolve_logistica' required filterOptions='Não,Sim - Troca de Guarda'></EnvolveLogisticaSelect>
                            <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='modal_envio' required></ModalEnvioSelect>
                        </If>
                        <If condition={['sim_troca_guarda'].includes(formData?.exibe_produto)}>
                            <GenericSelect formData={formData} setFormData={setFormData} fieldName={'envolve_logistica_troca_guarda'} cols={[12, 6, 6, 6]} title='Envolve Logística' required></GenericSelect>
                            <If condition={formData?.envolve_logistica_troca_guarda?.value === 'SIM'}>
                                <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='modal_envio' required></ModalEnvioSelect>
                            </If>
                        </If>
                        <If condition={formData?.exibe_produto === 'nao'}>
                            <Col sm='12' md='6'>
                                <FormLabel>Quantidade em Estoque</FormLabel>
                                <FormControl type="number" name="qtd_estoque" id="qtd_estoque" value={formData?.qtd_estoque || 0} readOnly />
                            </Col>
                            <Col sm='12' md='6'>
                                <FormLabel htmlFor='qtd_enviar'>Quantidade Desejada</FormLabel>
                                <FormControl type="number" min={1} max={formData?.qtd_estoque || 0} name="qtd_enviar" id="qtd_enviar" value={formData?.qtd_enviar || 1} onChange={handleQtdEnviar} required />
                            </Col>
                        </If>
                    </Row>
                    <Row>
                        <Col className="mt-1">
                            <Button type="submit" variant='primary' disabled={disableButton}>Cadastrar</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Dialog>);
}