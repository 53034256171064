import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import Form from './Form/Form';
import { createReport } from '../../../services/Repair';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreateReport({visible=false, onHide=null, updateParent=null, setUpdateReport=null}){
    
    const INITIAL_STATE = {
        id_fabricante: '',
        id_modelo: '',
        id_produto_reparo: '',
        descricao_defeito: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();
        const toastProduct = toast.loading("Enviando para reparo, aguarde...");

        let params = {
            ...formData,
            id_produto_reparo: formData?.id_produto_reparo?.value || ''
        }

        let dataReturn = await createReport(params);

        if (dataReturn?.data?.response === 'Análise solicitada com sucesso.') {
            handleSuccessToast('Produto enviado para reparo com sucesso', 'update', toastProduct);
            updateParent();
            setUpdateReport(true);
            setFormData(INITIAL_STATE);
        } else if(dataReturn?.data?.message === 'Já existe um laudo de reparo em andamento para este produto. Não é possível criar um novo enquanto o anterior não for finalizado.'){
            handleErrorsToast('', dataReturn?.data, 'update', toastProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao enviar o produto para reparo!', dataReturn?.data, 'update', toastProduct);
        }
    }
    
    return(
        <Dialog
            visible={visible}
            onHide={onHide}
            header='Cadastrar Laudo'
            maximized
            draggable={false}
            resizable={false}
        >
            <Form mode='create' formData={formData} setFormData={setFormData} submitForm={submitForm}></Form>
        </Dialog>
    )
}