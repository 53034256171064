import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getShipmentById, sendBulkConfirmInvoiceInsuance } from '../../../../services/Shipment';
import { Dialog } from 'primereact/dialog';
import { getCurrentDate, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { bulkAddGed } from '../../../../services/Ged';

export default function BulkInvoiceDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, invoiceId = '', setUpdateLogistic = null, handleClearRows = null }) {

    const initialState = {
        rastreamento: '',
        nfe_saida: '',
        valor_equipamento_nf: '',
        prazo_entrega: '',
        data_postagem_remessa: '',
        arquivo: '',
    }

    const [formData, setFormData] = useState(initialState);
    const [tableFormData, setTableFormData] = useState([]);
    const arquivoRef = useRef();
    const mySwal = withReactContent(Swal);
    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto'
        },
        {
            id: 'serial_produto',
            name: 'Serial'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto'
        }
    ]

    async function submitForm(e) {
        e.preventDefault();

        const toastData = toast.loading('Atualizando dados, aguarde...');

        let params = {
            ...formData,
            ids_remessa: invoiceId.split(',')
        }

        let dataReturn = await sendBulkConfirmInvoiceInsuance(params);
        if (dataReturn?.status === 200) {
            await submitFile();
            let data = dataReturn?.data?.data;
            let errors = [];

            for (let d in data) {
                if (!data[d]['success']) {
                    errors.push({ message: data[d]['message'] });
                }
            }

            if (errors.length > 0) {
                handleErrorsToast('Algumas remessas não foram atualizadas', {}, 'update', toastData);
                mySwal.fire({
                    title: 'Algumas remessas não foram atualizadas!',
                    text: 'Segue os erros abaixo',
                    html: (
                        <ul>
                            {errors.map((item, idx) => {
                                return <li key={idx}>Erro: {item?.message}</li>
                            })}
                        </ul>
                    )
                })
            } else {
                handleSuccessToast('Todas as remessas foram atualizadas com sucesso!', 'update', toastData);
            }
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
            handleClearRows();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastData);
        }
    }

    async function submitFile(){
        const formDataProduct = new FormData();

        formDataProduct.append('files[]', formData?.arquivo);
        formDataProduct.append('submodulo', 'remessas');
        formDataProduct.append('titulo', `${Date.now()}_${formData?.nfe_saida}`); 
        tableFormData.forEach(item => formDataProduct.append('ids_produto[]', item?.id_produto));
    
        let dataReturn = await bulkAddGed(formDataProduct);

        if(dataReturn?.status === 201){
            handleSuccessToast('Upload da Nota Fiscal efetuada com sucesso!');
            arquivoRef.current.value = '';
        }else{
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
        }
    }

    async function getShipments() {
        let shipmentIdArray = invoiceId.split(',');

        for (let id of shipmentIdArray) {
            getShipmentById(id).then((resp) => {
                let item = resp?.data?.data;
                setTableFormData(prev => [...prev, { 
                    nome_produto: item?.product?.partnumber?.modelo || '', 
                    serial_produto: item?.product?.serial || '', 
                    tipo_produto: item?.product?.nome_produto || '', 
                    id_produto: item?.product?.id || ''
                }])
            }).catch(e => handleErrorsToast('Ocorreu um erro', e?.data));
        }
    }

    function handleDataPostagem(e) {
        let field1 = e.target.name === 'prazo_entrega' ? e.target.value : formData['prazo_entrega'];
        let field2 = e.target.name === 'data_postagem_remessa' ? e.target.value : formData['data_postagem_remessa'];
        if (field1 < field2) {
            handleErrorsToast('A data de entrega não pode ser menor que a data de postagem!');
            return;
        }

        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(invoiceId)) {
            setFormData(initialState);
            setTableFormData([]);
            getShipments();
        }
    }, [isOpen, invoiceId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={'Atualizar Remessas'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5>{`Preencha todos os dados da NFe`}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={tableFormData}
                            needExport={false}
                            id='bulk_invoice_details'
                        ></PrimeTable>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <h6>Detalhes da NFe</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="rastreamento">Código de Rastreio</label>
                        <input type="text" name="rastreamento" id="rastreamento" className="form-control" placeholder='Rastreamento' value={formData?.rastreamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="nfe_saida">NFe de Saída</label>
                        <input type="text" name="nfe_saida" id="nfe_saida" className="form-control" placeholder='NFe de Saída' value={formData?.nfe_saida || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="valor_equipamento_nf">Valor do Equipamento na NFe</label>
                        <input type="number" name="valor_equipamento_nf" id="valor_equipamento_nf" className="form-control" placeholder='Valor do Equipamento NFe' value={formData?.valor_equipamento_nf || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="prazo_entrega">Prazo de Entrega</label>
                        <input type="date" name="prazo_entrega" id="prazo_entrega" min={getCurrentDate(-1)} className="form-control" placeholder='Prazo de Entrega' value={formData?.prazo_entrega || ''} onChange={(e) => handleDataPostagem(e)} required />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="data_postagem_remessa">Data da Postagem</label>
                        <input type="date" name="data_postagem_remessa" id="data_postagem_remessa" min={getCurrentDate(-1)} className="form-control" placeholder='Data da Postagem' value={formData?.data_postagem_remessa || ''} onChange={(e) => handleDataPostagem(e)} required />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="arquivo">Arquivo Nota Fiscal</label>
                        <input type="file" name="arquivo" id="arquivo" ref={arquivoRef} onChange={e => {setFormData(prevState=> ({...prevState, [e.target.name]: e.target.files[0]}))}} className='form-control' required/>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}