import React, { useState } from 'react';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import If from '../../../../common/Layout/If';
import AddComponents from './AddComponents';
import SpareComponents from './SpareComponents';

export default function Form({ mode = 'view', submitForm = null, formData = {}, setFormData = null }) {

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '', id_produto_reparo: '' }));
    }

    function handleModelo(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_produto_reparo: '' }));
    }

    function handleHouveReparo(value, name){
        setFormData(prev => ({...prev, [name]: value, componentes_spare: []}));
    }

    function handleStatus(value, name){
        let params = {}
        if(value?.value === 'aberto'){
            params['houve_reparo'] = '';
            params['componentes_existentes'] = [];
            params['componentes_novos_comprados'] = [];
            params['componentes_spares'] = [];
            params['descricao_laudo'] = '';
        }

        if(value?.value === 'aguardando_componente'){
            params['houve_reparo'] = '';
            params['componentes_spares'] = [];
            params['descricao_laudo'] = '';
        }
        
        setFormData(prev => ({...prev, [name]: value, ...params}))
    }

    return (<form onSubmit={submitForm}>
        <div className="row">
            <div className="col-12">
                <h4>Informações do Laudo</h4>
            </div>
            <div className="col-12">
                <div className="row">
                    <FabricanteSelect formData={formData} setFormData={setFormData} cols={mode === 'create' ? [12, 4, 4, 4] : [12, 3, 3, 3]} fieldName='id_fabricante' title='Fabricante' required isDisabled={['view', 'edit'].includes(mode)} advancedSetFormData={handleFabricante}></FabricanteSelect>
                    <ModelosSelect formData={formData} setFormData={setFormData} cols={mode === 'create' ? [12, 4, 4, 4] : [12, 3, 3, 3]} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value || ''} isDisabled={['view', 'edit'].includes(mode)} required advancedSetFormData={handleModelo}></ModelosSelect>
                    <AsyncProdutosSelect formData={formData} setFormData={setFormData} cols={mode === 'create' ? [12, 4, 4, 4] : [12, 3, 3, 3]} fieldName='id_produto_reparo' id_modelo={formData?.id_modelo?.value || ''} isDisabled={['view', 'edit'].includes(mode)} required></AsyncProdutosSelect>
                    <If condition={mode !== 'create'}>
                        <div className="col-sm-12 col-md-3">
                            <label htmlFor="created_at">Data da Solicitação</label>
                            <input type="datetime-local" name="created_at" id="created_at" value={formData?.created_at || ''} className="form-control" readOnly={['view', 'edit'].includes(mode)} />
                        </div>
                    </If>
                    <div className={`col-sm-12 col-md-${mode === 'create' ? '12' : '6'}`}>
                        <label htmlFor="descricao_defeito">Descrição do Defeito</label>
                        <textarea name="descricao_defeito" id="descricao_defeito" className='form-control' rows="10" value={formData?.descricao_defeito || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} readOnly={['view', 'edit'].includes(mode)} placeholder='Descreva o Defeito' required></textarea>
                    </div>
                    <If condition={mode !== 'create'}>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="andamento_analise">{mode === 'view' ? 'Histórico da Análise' : 'Andamento da Análise'}</label>
                            <textarea name="andamento_analise" id="andamento_analise" rows="10" value={formData?.andamento_analise || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} className="form-control" placeholder='Descreva o andamento da análise' readOnly={mode === 'view'} required={formData?.status?.value !== 'finalizado'}></textarea>
                        </div>
                    </If>
                    <If condition={mode !== 'create'}>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'status'} genericOption='StatusAnaliseOptions' cols={[12, 6, 6, 6]} title='Status da Análise' required isDisabled={mode === 'view'} advancedSetFormData={handleStatus}></GenericSelect>
                    </If>
                    <If condition={formData?.status?.value === 'finalizado'}>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'houve_reparo'} genericOption='HouveReparoOptions' cols={[12, 6, 6, 6]} title='Houve Reparo?' required isDisabled={mode === 'view'} advancedSetFormData={handleHouveReparo}></GenericSelect>
                    </If>
                    <If condition={formData?.status?.value === 'finalizado'}>
                        <div className="col-12">
                            <label htmlFor="descricao_laudo">Análise Detalhada</label>
                            <textarea name="descricao_laudo" id="descricao_laudo" rows="10" className='form-control' placeholder='Descreva em detalhes tudo que você fez na análise deste produto, independente se houve reparo ou não! Mínimo: 100 caracteres' value={formData?.descricao_laudo || ''} onChange={(e)=>setFormData(prev => ({...prev, [e.target.name]:e.target.value}))} required readOnly={mode === 'view'}></textarea>
                        </div>
                    </If>
                </div>
            </div>
        </div>
        <If condition={['finalizado', 'aguardando_componente'].includes(formData?.status?.value)}>
            <hr />
            <AddComponents mode={mode} formData={formData} setFormData={setFormData}></AddComponents>
        </If>
        <If condition={formData?.houve_reparo?.value === 0}>
            <hr />
            <SpareComponents mode={mode} formData={formData} setFormData={setFormData}></SpareComponents>
        </If>
        <If condition={mode !== 'view'}>
            <div className="row mt-1">
                <div className="col-12">
                    <button type="submit" className="btn btn-primary">{mode === 'create' ? 'Cadastrar' : 'Atualizar Laudo'}</button>
                </div>
            </div>
        </If>
    </form>)
}