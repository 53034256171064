// import {Col, Row } from "react-bootstrap";
// import Card from "../../../../common/Layout/Card";
import Content from "../../../../common/Layout/Content";
import SubHeaderSpecific from "../../../../common/Layout/SubHeader/SubHeaderSpecific";
// import { handleErrorsToast } from "../../../../configs/GenericFunctions";
// import { useEffect, useState } from "react";
// import { getEmbedDashboard } from "../../../../services/DevMode";
import Dashboard from "../../../../common/Dashboard/Dashboard";

export default function ShowTicketsStatus() {

    // const [iframeUrl, setIframeUrl] = useState('');

    // const milliseconds = 300000; //5 minutos
    // let interval;

    // const [data, setData] = useState([]);

    // async function getTicketsByStatus() {
    //     const toastData = toast.loading("Carregando dados, aguarde...");
    //     let dataReturn = await countByStatus();

    //     if (dataReturn?.status === 200) {
    //         let ticketsStatus = dataReturn?.data?.data;
    //         let tempArray = [];
    //         for (let status in ticketsStatus) {
    //             if (!checkValue(status)) {
    //                 tempArray.push({ "qtd_status": ticketsStatus[status] || 0, "nome_status": status || '' });
    //             }
    //         }

    //         setData(tempArray);
    //         renderTable();
    //         handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
    //     } else {
    //         handleErrorsToast('Ocorreu um erro ao buscar os dados!', dataReturn?.data, 'update', toastData);
    //     }
    // }

    // function renderTable() {
    //     let tableData = [...data];
    //     let arrayOfTableData = [];
    //     let chunk = 15;

    //     for (let i = 0; i < tableData.length; i += chunk) {
    //         arrayOfTableData.push(tableData.slice(i, i + chunk))
    //     }

    //     return arrayOfTableData?.map((item, idx) => (
    //         <Col sm='12' md='3' className="text-xs">
    //             <Table size="sm" key={idx} className="w-auto">
    //                 <thead>
    //                     <tr>
    //                         <th>Qtd.</th>
    //                         <th>Status</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         item?.map((item2, idx2) => (
    //                             <tr key={`line_${idx2}_table_${idx}`}>
    //                                 <td>
    //                                     {item2?.qtd_status}
    //                                 </td>
    //                                 <td onClick={() => openNewTab(`/chamados/dados/all?data_inicial=2001-01-01&status_chamado=${item2?.nome_status}`)} className="cursor-pointer">
    //                                     {item2?.nome_status}
    //                                 </td>
    //                             </tr>
    //                         ))
    //                     }
    //                 </tbody>
    //             </Table>
    //         </Col>
    //     ))
    // }

    // async function getIframe(){
    //     let dataReturn = await getEmbedDashboard('dashboard_chamados');

    //     if(dataReturn?.status === 200){
    //         setIframeUrl(dataReturn?.data?.data?.iframe_url);
    //     }else{
    //         handleErrorsToast('Ocorreu um erro ao carregar o dashboard');
    //     }
    // }

    // useEffect(() => {
        // getIframe();
        // getTicketsByStatus();

        // if (interval) {
        //     clearInterval(interval);
        // }

        // interval = setInterval(() => {
        //     getTicketsByStatus();
        // }, milliseconds);

        // return () => {
        //     clearInterval(interval);
        // };
    // }, []);


    return (<Content headerTitle="Chamados">
                <SubHeaderSpecific subHeaderConfig="dashboard"></SubHeaderSpecific>
                <Dashboard slug_dashboard="dashboard_chamados"></Dashboard>
        
        {/* <Card hideCardHeader>
            <Row>
                <Col sm='12'>
                    <h4>Chamados - Total</h4>
                </Col>
                {
                    renderTable()
                }
                <Col className="h-screen" sm='12'>
                    <iframe
                        src={iframeUrl}
                        frameBorder={0}
                        className="w-full h-full"
                    />
                </Col>
            </Row>
        </Card> */}
    </Content>);
}