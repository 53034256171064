import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import { toast } from 'react-toastify';
import { changeShipmentStatus, getAllPendingDactShipments } from '../../../../services/Shipment';
import PendingDactDetails from './PendingDactDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { checkValue, handleErrorsToast, handleSuccessToast, openNewTab, showBulkShipmentMessage } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import BulkPendingDactDetails from './BulkPendingDactDetails';

export default function PendingDact() {
    const [data, setData] = useState([]);
    const [viewMode, setViewMode] = useState('view');
    const [pendingDactId, setPendingDactId] = useState('');
    const [openPendingDactDetails, setOpenPendingDactDetails] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [bulkPendingDactId, setBulkPendingDactId] = useState('');
    const [openBulkPendingDactModal, setOpenBulkPendingDactModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const [selectedRows, setSelectedRows] = useState([]);
    const { action: actionPermissions, acoes_em_massa: acoesEmMassaPermissions } = getSubComponentsPermissions('mam_logistica', 'PendingDact', 'PendingDact');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openPendingDactModal(row, 'view')}>Ver</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openPendingDactModal(row, 'edit')}>Editar Valores DACT</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2])}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Remessa</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</Button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        }
    ];

    async function getPendingDactByStatus(page = 1, perPage = 100) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllPendingDactShipments(page, perPage);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando remessa, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO CANCELAMENTO' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            getPendingDactByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function openPendingDactModal(row, mode) {

        if (mode === 'view' || checkValue(row?.id_chamado_produto)) {
            handleSinglePendingDactModal(row, mode);
            return;
        }

        let products = await getPendingDactByTicketId(row?.id_chamado_produto);

        if (products?.length < 2) {
            handleSinglePendingDactModal(row, mode);
        } else {
            showBulkShipmentMessage(() => handleSinglePendingDactModal(row, mode), () => handleBulkPendingDactModal(products))
        }
    }

    async function getPendingDactByTicketId(id_chamado) {
        let dataReturn = await getAllPendingDactShipments(1, 1000, {
            id_chamado: id_chamado
        });

        if (dataReturn?.status === 200) {
            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                modal_envio_produto: item?.modal_envio || '',
                id_chamado_produto: item?.ticket?.id || '',
                chave_tipo_remessa_produto: item?.chave_tipo_remessa || ''
            }));

            return productData?.length > 0 ? productData : [];
        } else {
            return [];
        }
    }

    function handleBulkPendingDactModal(selectedRowsParam = []) {
        let shipmentIdArray = [];
        let chamado = '';
        let tipo_remessa = '';
        let modal_envio = '';

        let selectedRowsData = selectedRowsParam?.length > 0 ? selectedRowsParam : selectedRows;

        for (let i = 0; i < selectedRowsData.length; i++) {
            if (i === 0) {
                chamado = selectedRowsData[i]?.id_chamado_produto;
                tipo_remessa = selectedRowsData[i]?.tipo_remessa_produto;
                modal_envio = selectedRowsData[i]?.modal_envio_produto;
                shipmentIdArray.push(selectedRowsData[i]?.id_produto);
                continue;
            }

            if (chamado !== selectedRowsData[i]?.id_chamado_produto) {
                handleErrorsToast('Todas as remessas devem pertencer ao mesmo chamado!');
                return;
            }

            if (tipo_remessa !== selectedRowsData[i]?.tipo_remessa_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo tipo!');
                return;
            }

            if (modal_envio !== selectedRowsData[i]?.modal_envio_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo modal de envio!');
                return;
            }

            shipmentIdArray.push(selectedRowsData[i]?.id_produto);
        }

        setBulkPendingDactId(shipmentIdArray.toString());
        setOpenBulkPendingDactModal(true);
    }

    function handleSinglePendingDactModal(row, mode) {
        setPendingDactId(row.id_produto);
        setViewMode(mode);
        setOpenPendingDactDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getPendingDactByStatus(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getPendingDactByStatus(1, rows);
    }

    useEffect(() => {
        getPendingDactByStatus()
    }, []);

    return (<Content headerTitle='Pendente DACT'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <PendingDactDetails isOpen={openPendingDactDetails} setModalOpen={() => setOpenPendingDactDetails(!openPendingDactDetails)} pendingDactId={pendingDactId} viewMode={viewMode} setUpdateProducts={() => getPendingDactByStatus(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></PendingDactDetails>
        <BulkPendingDactDetails isOpen={openBulkPendingDactModal} setModalOpen={() => setOpenBulkPendingDactModal(!openBulkPendingDactModal)} handleClearRows={() => setSelectedRows([])} setUpdateLogistic={setUpdateLogistic} setUpdateProducts={() => getPendingDactByStatus(page + 1, perPage)} pendingDactId={bulkPendingDactId}></BulkPendingDactDetails>
        <Card title='Cadastro de Produtos - Pendente DACT'>
            <If condition={selectedRows?.length > 0 && checkAllPermissions(acoesEmMassaPermissions)}>
                <Row>
                    <Col>
                        <DropdownButton title='Ações em Massa' className='m-1'>
                            <If condition={checkPermission(acoesEmMassaPermissions[0])}>
                                <DropdownItem onClick={handleBulkPendingDactModal}>Editar Pendente DACT</DropdownItem>
                            </If>
                        </DropdownButton>
                    </Col>
                </Row>
            </If>
            <Row>
                <Col>
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='pending_dact'
                        reorderableColumns
                        selectableRows={checkAllPermissions(acoesEmMassaPermissions) ? true : false}
                        selectedRows={checkAllPermissions(acoesEmMassaPermissions) ? selectedRows : null}
                        handleSelect={checkAllPermissions(acoesEmMassaPermissions) ? setSelectedRows : null}
                    ></PrimeTable>
                </Col>
            </Row>
        </Card>
    </Content>)
}