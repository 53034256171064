import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getOptionsByModuleField } from '../../services/Options';
import { Col, FormLabel } from 'react-bootstrap';

export default function ModuleOptionsSelect({ formData, setFormData, fieldName, title, module, field, cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, advancedSetFormData = null, responseValueField = 'id' }) {
    const [moduleOptions, setModuleOptions] = useState([]);

    async function getModuleOptions() {
        let dataReturn = await getOptionsByModuleField(module, field);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data?.data.length > 0) {
            let optionsData = dataReturn?.data?.data?.data;
            let optionsArray = optionsData.map((item, idx) => {
                return ({ value: item[responseValueField], label: item.valor_opcao });
            });
            setModuleOptions(optionsArray);
        } else {
            setModuleOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        getModuleOptions();
    }, [field])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={moduleOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}