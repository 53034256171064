import React, { useState } from 'react';
import ContratosSelect from '../../../../common/Selects/ContratosSelect';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import AsyncChamadosSelect from '../../../../common/Selects/AsyncChamadosSelect';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import { Button, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function ShipmentFilters({ handleFilters = null, setSearch = null }) {
    const INITIAL_STATE = {
        data_inicio: '',
        data_fim: '',
        ids_contrato: '',
        id_fabricante: '',
        ids_modelo: '',
        id_chamado: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const arrayFields = ['ids_contrato', 'ids_modelo'];
    function clearFields() {
        setFormData(INITIAL_STATE);
        setSearch('');
    }

    function submitForm(e) {
        e.preventDefault();
        let params = '';
        for (let fd in formData) {
            if (!['', undefined, null].includes(formData[fd]) || !['', undefined, null].includes(formData[fd]?.value)) {
                let field = fd;
                if (arrayFields.includes(fd)) {
                    field = `${fd}[]`;
                }

                params += `&${field}=${formData[fd]?.value || formData[fd]}`;
            }
        }

        handleFilters(params);
    }

    return (
        <Row>
            <Col>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="data_inicio">Data Início</FormLabel>
                            <FormControl type="date" name="data_inicio" id="data_inicio" value={formData?.data_inicio || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                        </Col>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="data_fim">Data Fim</FormLabel>
                            <FormControl type="date" name="data_fim" id="data_fim" value={formData?.data_fim || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                        </Col>
                        <AsyncChamadosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_chamado' title='ID Localidade\Nº Chamado'></AsyncChamadosSelect>
                        <ContratosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} dependsOnIntegradora={false} fieldName='ids_contrato' selectAllContracts={false}></ContratosSelect>
                        <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_fabricante' title='Fabricante'></FabricanteSelect>
                        <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='ids_modelo' id_fabricante={formData?.id_fabricante?.value}></ModelosSelect>
                        <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'tipo_remessa'} fieldName={'tipo_remessa'} module={'produtos'} title={'Tipo Remessa'} cols={[12, 3, 3, 3]} responseValueField='valor_opcao'></ModuleOptionsSelect>
                        <Col sm='12'>
                            <Button type="button" variant='warning' className="m-1" onClick={clearFields}>Limpar Campos</Button>
                            <Button type='submit' variant='primary' className='m-1'>Pesquisar</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row >
    );
}