import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { changeEmployeeStatusById, getAllEmployee } from '../../../services/Employee';
import { changeTechnicianCredentialById } from '../../../services/Technician';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../hooks/usePermissions';
import If from '../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function ShowTechnicians({ type = "technician" }) {

    const { status } = useParams();
    const [techniciansData, setTechniciansData] = useState([]);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action_activate_deactivate: actionPermissions, action_accredit_deaccredit: actionAccreditPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'ShowTechnicians', 'ShowTechnicians');
    const { action_activate_deactivate: actionPermissionsEmployee, page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'ShowEmployees', 'ShowEmployees');
    const columns = [
        {
            id: 'action_tecnico',
            name: 'Ver',
            body: (row) => (<Link to={`${type === 'technician' ? '/cadastros/tecnicos/detalhes/' : '/cadastros/funcionarios/detalhes/'}${row.id_tecnico}`}><i className='fas fa-eye'></i></Link>),
        },
        {
            id: 'nome_tecnico',
            name: 'Nome',
        },
        {
            id: 'representante_tecnico',
            name: 'Representante',
        },
        {
            id: 'rg_tecnico',
            name: 'RG',
        },
        {
            id: 'cpf_cnpj_tecnico',
            name: 'CPF',
        },
        {
            id: 'cnpj_tecnico',
            name: 'CNPJ'
        },
        {
            id: 'cidade_tecnico',
            name: 'Cidade',
        },
        {
            id: 'estado_tecnico',
            name: 'Estado',
        },
        {
            id: 'contato_tecnico',
            name: 'Contato',
        },
        {
            id: 'action_activate_deactivate_tecnico',
            name: 'Ativar/Desativar',
            body: (row) => (
                <If condition={checkPermission(type === 'technician' ? actionPermissions[0] : actionPermissionsEmployee[0])}>
                    <div className='align-items-center'>
                        <button type="button" className='btn btn-primary' style={{ fontSize: '0.9rem' }} onClick={() => { showChangeTechnicianStatusSwal(row.id_tecnico, row.status_tecnico) }}>{row.status_tecnico === 'Ativo' ? 'Inativar' : 'Ativar'}</button>
                    </div>
                </If>
            ),
            permission: checkAllPermissions(type === "technician" ? actionPermissions : actionPermissionsEmployee)
        },
        {
            id: 'action_accredit_deaccredit_tecnico',
            name: 'Credenciar/Descredenciar',
            body: (row) => (
                <If condition={checkPermission(actionAccreditPermissions[0])}>
                    <div className='align-items-center'>
                        <button type="button" className='btn btn-primary' style={{ fontSize: '0.9rem' }} onClick={() => { showChangeTechnicianCredentialSwal(row.id_tecnico, row.status_tecnico, row.credenciado_tecnico) }}>{row.credenciado_tecnico === 'Credenciado' ? 'Descredenciar' : 'Credenciar'}</button>
                    </div>
                </If>
            ),
            permission: checkAllPermissions(actionAccreditPermissions),
            render: type === 'technician' ? true : false
        }
    ];

    async function getTechnicians(status = 1) {
        const toastTechnicianData = toast.loading("Carregando dados, aguarde...");
        let categoria = type === 'technician' ? ['Técnico'] : ['Residente', 'Home Office', 'Interno'];
        let modal_contratacao = ['CLT', 'PJ'];
        let dataReturn = await getAllEmployee({
            'ativo': status,
            'categoria': categoria,
            'modal_contratacao': modal_contratacao
        });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastTechnicianData);

            let data = dataReturn?.data?.data.map((item) => {

                let partners = item.partners.map((item) => {
                    return item.nome_representante;
                });
                return ({
                    id_tecnico: item?.id || '',
                    nome_tecnico: item?.nome_tec || '',
                    representante_tecnico: partners.join(', ') || '',
                    rg_tecnico: `${item?.rg_tec || ''} ${item?.rg_tec_orgao_expeditor || ''}`,
                    cpf_cnpj_tecnico: item?.cpf_cnpj || '',
                    cnpj_tecnico: item?.cnpj || '',
                    cidade_tecnico: item?.cidade_end || '',
                    estado_tecnico: item?.estado_end || '',
                    contato_tecnico: `${item?.nome_tec || ''}\n ${item?.email_tec} | ${item?.email_tec_pessoal}\n ${item?.celular_tec} | ${item?.celular_tec_pessoal}\n ${item?.telefone_recados} | ${item?.telefone_fixo_tec}`,
                    status_tecnico: item?.ativo === 1 ? 'Ativo' : 'Inativo',
                    credenciado_tecnico: item?.credenciado === 1 ? 'Credenciado' : 'Descredenciado'
                })
            });

            setTechniciansData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastTechnicianData);
            setTechniciansData([]);
        }
    }

    async function showChangeTechnicianCredentialSwal(id, status, credenciado) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente credenciar/descredenciar este técnico?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeTechnicianByCredential(id, status, credenciado);
        }
    }

    async function changeTechnicianByCredential(id, status, credenciado) {

        const toastChangeStatus = toast.loading("Alterando credencial, aguarde...");

        let changeStatusData = credenciado === 'Credenciado' ? 0 : 1;

        let dataReturn = await changeTechnicianCredentialById(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Credencial alterada com sucesso!', 'update', toastChangeStatus);
            let statusData = status === 'Ativo' ? 1 : 0;
            getTechnicians(statusData);
        } else {
            handleErrorsToast('Ocorreu um erro ao alterar a credencial!', dataReturn?.data, 'update', toastChangeStatus);
        }
    }

    async function showChangeTechnicianStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: `Deseja realmente alterar o status deste ${type === 'technician' ? 'técnico' : 'funcionario'}?`,
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeTechnicianByStatus(id, status);
        }
    }

    async function changeTechnicianByStatus(id, status) {

        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await changeEmployeeStatusById(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Status alterado com sucesso!', 'update', toastChangeStatus);
            let statusData = status === 'Ativo' ? 1 : 0;
            getTechnicians(statusData);
        } else {
            handleErrorsToast('Ocorreu um erro ao alterar o status!', dataReturn?.data, 'update', toastChangeStatus);
        }
    }

    useEffect(() => {
        let statusData = 1;

        if (status === 'inativo') {
            statusData = 0
        }

        getTechnicians(statusData);
    }, [status, type])

    return (
        <Content headerTitle={`${type === 'technician' ? 'Técnicos' : 'Funcionarios'}`}>
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className='col-12'>
                        <If condition={checkPermission(type === 'technician' ? pagePermissions[0] : pagePermissionsEmployee[0])}>
                            <Link  to={`${type === 'technician' ? '/cadastros/tecnicos/cadastrar' : '/cadastros/funcionarios/cadastrar'}`} role='button' className='btn btn-primary m-1'>{`Cadastrar ${type === 'technician' ? 'Técnico' : 'Funcionario'}`}</Link>
                        </If>
                        <If condition={checkPermission(type === 'technician' ? pagePermissions[1] : pagePermissionsEmployee[1])}>
                            <Link to={`${type === 'technician' ? '/cadastros/tecnicos/dados' : '/cadastros/funcionarios/dados'}`} role='button' className='btn btn-success m-1'>{`${type === 'technician' ? 'Técnicos' : 'Funcionarios'} Ativos`}</Link>
                            <Link to={`${type === 'technician' ? '/cadastros/tecnicos/dados/inativo' : '/cadastros/funcionarios/dados/inativo'}`} role='button' className='btn btn-warning m-1'>{`${type === 'technician' ? 'Técnicos' : 'Funcionarios'} Inativos`}</Link>
                        </If>
                    </div>
                </div>
                <PrimeTable
                    columns={columns}
                    rows={techniciansData}
                    needExport={checkPermission(type === 'technician' ? pagePermissions[2] : pagePermissionsEmployee[2])}
                    id='show_technicians'
                ></PrimeTable>
            </Card>
        </Content>
    )
}