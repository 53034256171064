import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Cep from '../../../../common/Cep/Cep';
import { getShipmentById } from '../../../../services/Shipment';
import GedTable from '../Components/Ged/GedTable';
import usePermissions from '../../../../hooks/usePermissions';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, getCurrentDate } from '../../../../configs/GenericFunctions';
import ShipmentForm from '../Components/ShipmentForm';

export default function AwaitingWithdrawDetails({ isOpen = false, setModalOpen = null, awaitingWithdrawId = '', viewMode = 'view' }) {

    const initialState = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        local: '',
        box: '',
        id_representante: '',
        id_tecnico: '',
        id_localidade: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: '',
        responsavel_separacao: '',
        destinatario: '',
        data_entrega: getCurrentDate()
    }

    const [formData, setFormData] = useState(initialState);
    const { checkPermission } = usePermissions();
    const { tab: tabPermissions } = getSubComponentsPermissions('mam_logistica', 'AwaitingWithdraw', 'AwaitingWithdrawDetails');

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(awaitingWithdrawId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let shipmentData = dataReturn?.data?.data;

            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
                urgencia_remessa: shipmentData?.urgencia_remessa || '',
                local: shipmentData?.product?.local || '',
                box: shipmentData?.product?.box || '',
                id_localidade: shipmentData?.id_localidade || '',
                modal_envio: shipmentData?.modal_envio || '',
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: shipmentData?.product?.technician?.nome_tec || '',
            }));

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(awaitingWithdrawId)) {
            setFormData(initialState);
            getShipment();
        }
    }, [isOpen, awaitingWithdrawId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={viewMode === 'view' ? 'Visualizar Remessa' : 'Atualizar Remessa'}
            maximized={true}
            draggable={false}
            resizable={false}
            >
            <TabView>
                <TabPanel header='Detalhes da NFe'>
                    <ShipmentForm formData={formData} title='Confirmar Entrega da Remessa' parentPage='AwaitingWithdrawDetails'></ShipmentForm>
                </TabPanel>
                <TabPanel header='GED' visible={checkPermission(tabPermissions[0])}>
                    <GedTable idProduto={formData?.id_produto} viewMode='view' submodule='remessas'></GedTable>
                </TabPanel>
            </TabView>
        </Dialog>);
}