import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';

import CreateProduct from './CreateProduct';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getModelsByManufacturers } from '../../../../services/ProductPartnumber';

export default function ProductsTable({ createTicketForm = {}, setCreateTicketForm = null, productData = [], setProductData = null, num_chamado = '', envolve_logistica = '', id_tecnico = '' }) {

    const [isOpen, setIsOpen] = useState(false);
    const mySwal = withReactContent(Swal);
    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (
                <button type='button' className='btn btn-link' onClick={() => removeProduct(row)}><i className='fas fa-trash'></i></button>
            )
        },
        {
            id: 'id_temp_table',
            name: 'ID'
        },
        {
            id: 'fabricante',
            name: 'Fabricante'
        },
        {
            id: 'modelo',
            name: 'Nome do Produto'
        },
        {
            id: 'serial',
            name: 'Tipo de Produto - Serial'
        }
    ];

    async function removeProduct(row) {
        let data = productData.filter((item) => item.id_temp_table !== row.id_temp_table);
        setProductData(data);
        handleSuccessToast('Produto removido!');
    }

    async function addProduct(rows) {
        let id = 0;
        let repeatedSerials = [];

        if (productData.length > 0) {
            id = productData[productData.length - 1]['id_temp_table'] + 1;
        }

        let available = await checkAvailableProductQuant(rows);

        if(!available){
            handleErrorsToast('Estoque deste modelo esgotado, por favor revise a quantidade disponivel e a cadastrada para este chamado e tente novamente!');
            return;
        }

        const existingSerials = new Set(productData.map(product => product.serial).filter(serial => serial != ''));

        const updatedRows = rows.filter(item => {
            const serial = item?.id_produto?.label || '';
            if(existingSerials.has(serial)){
                repeatedSerials.push(serial);
            }
            return serial === '' || !existingSerials.has(serial);
        }).map((item, idx) => ({
            ...item,
            id_temp_table: id + idx,
            fabricante: item?.id_fabricante?.label || '',
            modelo: item?.id_modelo?.label || '',
            serial: item?.id_produto?.label || ''
        }));

        if(repeatedSerials.length > 0){
            mySwal.fire({
                title: 'Os seguintes seriais já estão pré-cadastrados para este chamado e não podem ser inseridos',
                html: <>
                {
                    repeatedSerials.map((item, idx)=><p key={idx}>{item}</p>)
                }
                </>
            });
        }
        
        setProductData(prev => [...prev, ...updatedRows]);
    }

    async function checkAvailableProductQuant(rows){
        let dataReturn = await getModelsByManufacturers(rows[0]['id_fabricante']['value'], 'only_available');
        if(dataReturn?.status === 200){
            let data = dataReturn?.data?.data?.partnumber?.filter(item => item.id === rows[0]['id_modelo']['value']);
            const productModelQuant = productData?.filter(item => item.modelo === rows[0]['id_modelo']['label']).length;

            if(data[0]['available_quantity'] > productModelQuant){
                return true;
            }

            return false;

        }else{
            return false;
        }
    }

    return (<>
        <CreateProduct isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} updateParent={addProduct} num_chamado={num_chamado} envolve_logistica={envolve_logistica} id_tecnico={id_tecnico}></CreateProduct>
        <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Produtos Envolvidos</h6></div>
        <div className="row">
            <div className="col-sm-12 col-md-6">
                <div className='form-group'>
                    <label htmlFor="numero_rma">Número RMA</label>
                    <input type="text" className="form-control" name="numero_rma" id="numero_rma" value={createTicketForm?.numero_rma} onChange={(e) => setCreateTicketForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='...'/>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-3 d-flex align-items-center">
                <button type="button" className="btn btn-primary" onClick={() => setIsOpen(!isOpen)}>Cadastrar Produtos</button>
            </div>
            <div className="col-md-12">
                <PrimeTable
                    columns={columns}
                    rows={productData}
                    needExport={false}
                    id='product_data_table'
                ></PrimeTable>
            </div>
        </div>
    </>);
}