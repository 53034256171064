import React, { useEffect, useState } from 'react';
import { deletePartnerValuesById, getPartnersValuesByPartnerId } from '../../../../../services/PartnerValues';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PartnerValuesDetails from './PartnerValuesDetails';
import If from '../../../../../common/Layout/If';
import usePermissions from '../../../../../hooks/usePermissions';
import SpecialPartnerTable from '../SpecialPartnerValues/SpecialPartnerTable';
import CreateSpecialPartnerValue from '../SpecialPartnerValues/CreateSpecialPartnerValue';
import CreateParnerValues from './CreatePartnerValues';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function PartnerValuesTable({ id_representante = '' }) {

    const [data, setData] = useState([]);
    const [specialValuesData, setSpecialValueData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateSpecialValueOpen, setIsCreateSpecialValueOpen] = useState(false);
    const [isCreateValueOpen, setIsCreateValueOpen] = useState(false);
    const [idValorRepresentante, setIdValorRepresentante] = useState('');
    const [mode, setMode] = useState("update");
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'PartnerDetails', 'PartnerValuesTable');

    const columns = [
        {
            id: 'action_valores_contrato',
            name: 'Ação',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermissions[0])}>
                            <button type="button" className='dropdown-item' onClick={() => openPartnerValuesDetails(row.id_valores_contrato, 'update')}>Editar</button>
                        </If>
                        <If condition={(checkPermission(actionPermissions[1])) && ['reprovado', 'pendente'].includes(row.status_aprovacao_contrato)}>
                            <button type="button" className='dropdown-item' onClick={() => openPartnerValuesDetails(row.id_valores_contrato, 'approve')}>Aprovar</button>
                        </If>
                        <If condition={(row.status_aprovacao_contrato === 'aprovado' && checkPermission(actionPermissions[2]))}>
                            <button type="button" className="dropdown-item" onClick={() => openCreateSpecialValues(row.id_valores_contrato)}>Criar Valor Especial</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[3])}>
                            <button type="button" className='dropdown-item' onClick={() => showDeletePartnerValuesSwal(row.id_valores_contrato)}>Excluir</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'status_aprovacao_contrato',
            name: 'Status Aprovação',
        },
        {
            id: 'contrato_valores_contrato',
            name: 'Contrato',
        },
        {
            id: 'escopo_valores_contrato',
            name: 'Escopo',
        },
        {
            id: 'valor_chamado_valores_contrato',
            name: 'R$ Chamado',
        },
        {
            id: 'valor_fora_valores_contrato',
            name: 'Valor Fora Hrs Comercial',
        },
        {
            id: 'chamado_improdutivo_valores_contrato',
            name: 'Chamado Improdutivo',
        },
        {
            id: 'h_a_diurna_valores_contrato',
            name: 'H.A (Diurna) Após 3h31m',
        },
        {
            id: 'h_a_noturna_valores_contrato',
            name: 'H.A (Noturna) Após 3h31m',
        },
        {
            id: 'valor_apos_51_km_valores_contrato',
            name: 'R$ KM Após 51 Km',
        },
        {
            id: 'observacoes_valores_contrato',
            name: 'Observações',
        },
        {
            id: 'motivo_alteracoes_valores_contrato',
            name: 'Motivo Alterações Valores de Contrato',
        },
        {
            id: 'motivo_reprovacao_contrato',
            name: 'Motivo Reprovação',
        },
        {
            id: 'tem_valores_especiais',
            name: 'Possui Valores Especiais?',
        },
        {
            id: 'usuario_criador_contrato',
            name: 'Usuário Criador'
        },
        {
            id: 'usuario_aprovador_contrato',
            name: 'Usuário Aprovador'
        }
    ];

    async function getPartnerValues() {

        setSpecialValueData([]);

        let dataReturn = await getPartnersValuesByPartnerId(id_representante);
        if (dataReturn?.data?.response === 'success') {
            let partnerValues = dataReturn?.data?.data?.map((item, idx) => {
                if (item?.special_partner_value?.length > 0) {
                    adjustSpecialValuesData(item?.special_partner_value, item?.contract_value?.escopo, item?.contract_value?.contract?.contrato);
                }

                return {
                    id_valores_contrato: item?.id || '',
                    contrato_valores_contrato: item?.contract_value?.contract?.contrato || '',
                    escopo_valores_contrato: item?.contract_value?.escopo || '',
                    valor_chamado_valores_contrato: item?.valor_chamado || '0,00',
                    valor_fora_valores_contrato: item?.valor_fora_hr_comercial || '0,00',
                    chamado_improdutivo_valores_contrato: item?.valor_chamado_improdutivo || '0,00',
                    h_a_diurna_valores_contrato: item?.valor_ha_diurna || '0,00',
                    h_a_noturna_valores_contrato: item?.valor_ha_noturna || '0,00',
                    valor_apos_51_km_valores_contrato: item?.valor_km_apos_51 || '0,00',
                    observacoes_valores_contrato: item?.observacoes || '',
                    status_aprovacao_contrato: item?.aprovado || '',
                    motivo_alteracoes_valores_contrato: item?.descricao_valor_alto || '',
                    motivo_reprovacao_contrato: item?.descricao_reprovado || '',
                    tem_valores_especiais: item?.special_partner_value?.length > 0 ? 'Sim' : 'Não',
                    usuario_aprovador_contrato: item?.approver?.nome || '',
                    usuario_criador_contrato: item?.creator?.nome || ''
                }
            });
            setData(partnerValues);
        } else {
            setData([]);
        }
    }

    function adjustSpecialValuesData(values = [], escopo = '', contrato = '') {
        let data = values.filter(item => item?.status === 1).map((item, idx) => ({
            id_valores_especiais: item?.id || '',
            contrato_valores_especiais: contrato || '',
            escopo_valores_especiais: escopo || '',
            valor_chamado_valores_especiais: item?.valor_chamado || '0,00',
            valor_fora_valores_especiais: item?.valor_fora_hr_comercial || '0,00',
            chamado_improdutivo_valores_especiais: item?.valor_improdutivo || '0,00',
            h_a_diurna_valores_especiais: item?.valor_ha_diurna || '0,00',
            h_a_noturna_valores_especiais: item?.valor_ha_noturna || '0,00',
            valor_apos_51_km_valores_especiais: item?.valor_km_apos_51 || '0,00',
            motivo_valores_especiais: item?.observacoes || '',
            status_aprovacao_valores_especiais: item?.aprovado || '',
            motivo_reprovacao_valores_especiais: item?.descricao_reprovado || '',
            num_chamado_valores_especiais: item?.ticket?.num_chamado || '',
            id_chamado: item?.id_chamado || '',
            usuario_aprovador_contrato: item?.approver?.nome || '',
            usuario_criador_contrato: item?.creator?.nome || ''
        }));

        setSpecialValueData(prevState => ([...prevState, ...data]));
    }

    async function showDeletePartnerValuesSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente excluir estes valores?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            deletePartnerValues(id);
        }
    }

    async function deletePartnerValues(id) {
        const toastValues = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deletePartnerValuesById(id);

        if (dataReturn?.status === 204) {
            handleSuccessToast('Dados excluidos com sucesso!', 'update', toastValues);
            getPartnerValues();
        } else {
            handleErrorsToast('Ocorreu um erro ao excluir os dados!', dataReturn?.data, 'update', toastValues);
        }
    }

    function openPartnerValuesDetails(id, mode) {
        setIdValorRepresentante(id);
        setMode(mode);
        setIsOpen(true);
    }

    function openCreateSpecialValues(id) {
        setIdValorRepresentante(id);
        setIsCreateSpecialValueOpen(true);
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id_representante)) {
            getPartnerValues();
        }
    }, [id_representante]);

    return (<>
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Valores Contrato</h5>
            </div>
            <div className="col-12">
                <If condition={checkPermission(pagePermissions[0])}>
                    <button type="button" className="btn btn-primary" onClick={() => setIsCreateValueOpen(prevState => !prevState)}>Cadastrar</button>
                </If>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={columns}
                    rows={data}
                    id='contract_values_table'
                ></PrimeTable>
            </div>
        </div>
        <If condition={specialValuesData.length > 0 && checkPermission(pagePermissions[1])}>
            <SpecialPartnerTable specialData={specialValuesData} updateParent={getPartnerValues}></SpecialPartnerTable>
        </If>
        <CreateSpecialPartnerValue isOpen={isCreateSpecialValueOpen} setModalOpen={() => setIsCreateSpecialValueOpen(!isCreateSpecialValueOpen)} id_valor_representante={idValorRepresentante} updateParent={getPartnerValues}></CreateSpecialPartnerValue>
        <PartnerValuesDetails isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)} id_valor_representante={idValorRepresentante} updateParent={getPartnerValues} mode={mode}></PartnerValuesDetails>
        <CreateParnerValues isOpen={isCreateValueOpen} setIsOpen={() => setIsCreateValueOpen(!isCreateValueOpen)} updateParent={getPartnerValues} id_representante={id_representante}></CreateParnerValues>
    </>)
}