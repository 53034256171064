import { Col, FormControl, FormLabel, Row, Form, Button, DropdownButton, DropdownItem } from "react-bootstrap";
import Card from "../../../../common/Layout/Card";
import Content from "../../../../common/Layout/Content";
import SubHeaderSpecific from "../../../../common/Layout/SubHeader/SubHeaderSpecific";
import PrimeTable from "../../../../common/Layout/Table/PrimeTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkValue, handleErrorsToast, handleSuccessToast, showBulkProductMessage } from "../../../../configs/GenericFunctions";
import { getAllProductsParams } from "../../../../services/Product";
import ProductDetails from "../Components/ProductDetails/ProductDetails";
import If from "../../../../common/Layout/If";
import BulkUpdate from "../Components/BulkUpdate";
import { getSubComponentsPermissions } from "../../../../configs/MenuItemsConfigs";
import usePermissions from "../../../../hooks/usePermissions";

export default function GeneratedReturn() {
    const INITIAL_STATE = {
        id_status_produto: 58
    }
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [productsData, setProductsData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [productId, setProductId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);
    const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
    const [bulkId, setBulkId] = useState([]);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, acoes_em_massa: acoesEmMassaPermissions } = getSubComponentsPermissions('mam_logistica', 'GeneratedReturn', 'GeneratedReturn');
    const columns = [
        {
            id: 'action',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openProductModal(row, 'view')}>Ver Produto</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openProductModal(row, 'edit')}>Editar</DropdownItem>
                    </If>
                </DropdownButton>),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial',
            name: 'Serial',
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'status_produto',
            name: 'Status Produto'
        },
        {
            id: 'numero_reversa',
            name: 'Número Reversa'
        },
        {
            id: 'rastreamento_produto',
            name: 'Rastreamento'
        },
        {
            id: 'data_reversa',
            name: 'Data Reversa'
        },
        {
            id: 'data_expiracao_reversa',
            name: 'Data Expiração Reversa'
        }
    ];

    function openSingleProductModal(row, mode) {
        setProductId(row.id);
        setViewMode(mode);
        setIsUpdateProductModalOpen(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getGeneratedReturn(newPage + 1, perPage, INITIAL_STATE);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getGeneratedReturn(1, rows, INITIAL_STATE);
    }

    async function getGeneratedReturn(page = 1, perPage = 100, params = { id_status_produto: 58 }) {
        setLoading(true);

        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getAllProductsParams(page, perPage, params);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let data = dataReturn?.data?.data?.data;
            let products = data?.map((item) => {
                return (
                    {
                        ...item,
                        status_produto: item.status?.valor_opcao || '',
                        nome_produto: item?.partnumber?.modelo || '',
                        rastreamento_produto: item?.last_shipment?.rastreamento || '',
                        tecnico_produto: item?.technician?.nome_tec || ''
                    }
                );
            });
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setProductsData(products);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setProductsData([]);
        }
        setLoading(false);
    }

    const conditionRow = (row) => {
        if (!checkValue(row?.data_expiracao_reversa)) {
            let todayDate = new Date();
            let expirationDate = new Date(row?.data_expiracao_reversa + " 10:00:00");
            let diffMilliseconds = expirationDate.getTime() - todayDate.getTime();
            let diffDays = Math.round(diffMilliseconds / (1000 * 60 * 60 * 24)); // Convertendo para dias
            return {
                "bg-red-200": checkValue(row?.last_shipment?.rastreamento) && diffDays < 10
            }
        }
        return {}
    }

    function handleBulkProductModal(selectedRowsParam = []) {
        let bulkIdArray = [];
        let id_tecnico = '';

        let selectedRowsData = selectedRowsParam?.length > 0 ? selectedRowsParam : selectedRows;
        for (let i = 0; i < selectedRowsData.length; i++) {
            if (i === 0) {
                id_tecnico = selectedRowsData[i]?.id_tecnico;
                bulkIdArray.push(selectedRowsData[i]?.id);
                continue;
            }

            if (id_tecnico !== selectedRowsData[i]?.id_tecnico) {
                handleErrorsToast('Todos os produtos devem pertencer ao mesmo técnico!');
                return;
            }

            bulkIdArray.push(selectedRowsData[i]?.id);
        }

        setBulkId(bulkIdArray);
        setIsBulkUpdateModalOpen(true);
    }

    async function openProductModal(row, mode) {
        if (mode === 'view' || checkValue(row?.id_tecnico)) {
            openSingleProductModal(row, mode);
            return;
        }

        let products = await getAllProductsParams(1, 1000, {...INITIAL_STATE, id_tecnico: row?.id_tecnico});

        let data = products?.data?.data?.data || [];

        if (products?.length < 2) {
            openSingleProductModal(row, mode);
        } else {
            showBulkProductMessage(() => openSingleProductModal(row, mode), () => handleBulkProductModal(data))
        }
    }

    useEffect(() => {
        getGeneratedReturn();
    }, []);

    return (
        <Content headerTitle="Reversa Gerada">
            <SubHeaderSpecific subHeaderConfig="mam_logistica" setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <Card title="Reversa Gerada">
                <Row>
                    <Col sm='12'>
                        <If condition={selectedRows?.length > 0}>
                            <DropdownButton title='Ações em Massa' className="m-1">
                                {/* <If condition={checkPermission(acoesEmMassaPermissions[0])}> */}
                                <DropdownItem onClick={handleBulkProductModal}>Editar Selecionados</DropdownItem>
                                {/* </If> */}
                            </DropdownButton>
                        </If>
                    </Col>
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={productsData}
                            id='reversa_gerada_table'
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            reorderableColumns
                            selectableRows={checkAllPermissions(acoesEmMassaPermissions) ? true : false}
                            selectedRows={checkAllPermissions(acoesEmMassaPermissions) ? selectedRows : null}
                            handleSelect={checkAllPermissions(acoesEmMassaPermissions) ? setSelectedRows : null}
                            customStyles={conditionRow}
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
            <If condition={isUpdateProductModalOpen}>
                <ProductDetails isOpen={isUpdateProductModalOpen} setModalOpen={() => setIsUpdateProductModalOpen(!isUpdateProductModalOpen)} productId={productId} viewMode={viewMode} setUpdateProducts={() => getGeneratedReturn(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></ProductDetails>
            </If>
            <If condition={isBulkUpdateModalOpen}>
                <BulkUpdate isOpen={isBulkUpdateModalOpen} setModalOpen={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)} setUpdateProducts={() => getGeneratedReturn(page + 1, perPage)} handleClearRows={() => setSelectedRows([])} ids={[...bulkId.map((item) => item)]} setUpdateLogistic={setUpdateLogistic}></BulkUpdate>
            </If>
        </Content>
    )
}