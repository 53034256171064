import React, { useEffect, useState } from 'react';
import CreateLeaves from './CreateLeaves';
import Table from '../../../../../common/Layout/Table/Table';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { cancelLeave, getLeavesByEmployeeId, uploadLeaveAttachment } from '../../../../../services/EmployeeLeave';
import withReactContent from 'sweetalert2-react-content';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import If from '../../../../../common/Layout/If';
import usePermissions from '../../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function LeavesTable({ id = '', updateData = false, type = 'technician' }) {
    const [isOpen, setIsOpen] = useState(false);
    const [afastamentoData, setAfastamentoData] = useState([]);
    const mySwal = withReactContent(Swal);
    const { checkAllPermissions, checkPermission } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'TechnicianDetails', 'LeavesTable');
    const { action: actionPermissionsEmployee, page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'EmployeeDetails', 'LeavesTable');

    const afastamentoColumns = [
        {
            id: 'tipo_afastamento_afastamento',
            name: 'Tipo de Afastamento',
        },
        {
            id: 'data_inicio_afastamento',
            name: 'Data de Início',
        },
        {
            id: 'data_fim_afastamento',
            name: 'Data de Término',
        },
        {
            id: 'status_afastamento',
            name: 'Status',
        },
        {
            id: 'action_afastamento',
            name: 'Ação',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={updateData}>
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        <If condition={row.status_afastamento === 'Ativo' && checkPermission(type === 'technician' ? actionPermissions[0] : actionPermissionsEmployee[0])}>
                            <button type="button" className='dropdown-item' onClick={() => showCancelLeavesSwal(row.id_afastamento)}><i className='fas fa-trash'></i>Cancelar</button>
                        </If>
                        <If condition={checkPermission(type === 'technician' ? actionPermissions[1] : actionPermissionsEmployee[1])}>
                            <button type="button" className='dropdown-item' onClick={() => showUpdateLeaveAttachmentSwal(row.id_afastamento)}><i className='fas fa-upload'></i>Atualizar Arquivo</button>
                        </If>
                        <If condition={row.url_anexo_afastamento !== '' && checkPermission(type === 'technician' ? actionPermissions[2] : actionPermissionsEmployee[2])}>
                            <a role='button' href={row.url_anexo_afastamento} className='dropdown-item' download={true} target='_blank'><i className='fas fa-download'></i>Baixar Anexo</a>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(type === 'technician' ? actionPermissions : actionPermissionsEmployee)
        }
    ];

    async function getLeaves() {
        const toastUpdateForm = toast.loading('Pesquisando afastamentos, aguarde...');

        let dataReturn = await getLeavesByEmployeeId(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Afastamento encontrados!', 'update', toastUpdateForm);
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    tipo_afastamento_afastamento: item?.tipo_afastamento,
                    data_inicio_afastamento: item?.data_inicial,
                    data_fim_afastamento: item?.data_final,
                    id_afastamento: item?.id,
                    status_afastamento: item?.status === 1 ? 'Ativo' : 'Inativo',
                    url_anexo_afastamento: item?.url_anexo || ''
                });
            });

            setAfastamentoData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os afastamentos!', dataReturn?.data, 'update', toastUpdateForm);
            setAfastamentoData([]);
        }
    }

    async function showCancelLeavesSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo cancelar este afastamento?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelLeaves(id);
        }
    }

    async function cancelLeaves(id) {
        const toastCancel = toast.loading("Cancelando afastamento, aguarde...");

        let dataReturn = await cancelLeave(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Afastamento cancelado com sucesso!', 'update', toastCancel);
            getLeaves();
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar o afastamento!', dataReturn?.data, 'update', toastCancel);
        }
    }

    async function showUpdateLeaveAttachmentSwal(id_afastamento) {
        let swal = await mySwal.fire({
            title: 'Anexar arquivo!',
            input: 'file',
            inputAttributes: {
                "accept": ".png, .pdf, .jpg, .jpeg",
            },
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Anexar',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Cancelar'
        });

        if (swal.isConfirmed && swal.value !== null) {
            updateLeaveAttachment(id_afastamento, swal.value);
        }
    }

    async function updateLeaveAttachment(id, file) {
        const toastUpdate = toast.loading("Anexando arquivo, aguarde...");

        let formData = new FormData();

        formData.append('arquivo', file);

        let dataReturn = await uploadLeaveAttachment(id, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Arquivo anexado com sucesso!', 'update', toastUpdate);
            getLeaves();
        } else {
            handleErrorsToast('Ocorreu um erro ao anexar o arquivo!', dataReturn?.data, 'update', toastUpdate);
        }
    }

    useEffect(() => {
        getLeaves();
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Afastamentos</h5>
                <If condition={checkPermission(type === 'technician' ? pagePermissions[0] : pagePermissionsEmployee[0])}>
                    <button type="button" className="btn btn-primary" onClick={() => setIsOpen(!isOpen)} disabled={updateData}>Cadastrar</button>
                </If>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={afastamentoColumns}
                    rows={afastamentoData}
                    id='afastamento'
                ></PrimeTable>
            </div>
            <CreateLeaves idTecnico={id} isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} setUpdateParent={getLeaves}></CreateLeaves>
        </div>
    );

}