import React from 'react';
import Card from '../../../common/Layout/Card';

export default function OsBaCard({ detailsData = {}, setDetailsData = null}) {
    return (<Card title={"OS/BA"} icon={"fa-dot-circle"}>
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="os_ba">OS/BA</label>
                    <input type="text" name="os_ba" className="form-control" value={detailsData?.os_ba || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, os_ba: e.target.value }))} placeholder='...'/>
                </div>
            </div>
        </div>
    </Card>)
}