import { Fab } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MainChatComponent from './MainChatComponent';
import Modal from '../Layout/Modal';
import echo from '../../services/LaravelEcho';
import { getPendingMessageByTicketId, getPendingMessages } from '../../services/Chat';
import { Context } from '../../App';

export default function FloatChatButton({ floatOpened = false, setFloatOpened = null, ticketId = '', listenTo = 'all', channelId = '', setChannelId = null }) {
    const fabRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(floatOpened);
    const [pendingMessages, setPendingMessages] = useState(0);
    const userData = useContext(Context);

    async function getUserPendingMessages() {
        let dataReturn;

        if (listenTo === 'all') {
            dataReturn = await getPendingMessages();
        } else if(listenTo === 'ticket') {
            dataReturn = await getPendingMessageByTicketId(ticketId);
        }

        if (dataReturn?.data?.response === 'success') {
            setPendingMessages(dataReturn?.data?.data?.pending_message_count || 0);
        }
    }

    useEffect(() => {

        if(listenTo === 'all'){
            if(userData?.userDataState?.id_usuario !== ''){
                getUserPendingMessages();

                const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);
    
                webSocketChannel.listen('NewMessage', (e)=>{
                    if(e.notification === 'new_message'){
                        getUserPendingMessages();
                    }
                })
            }
        }else if(listenTo === 'ticket'){
            if (ticketId !== "") {
                getUserPendingMessages();
                const channel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}ticket.${ticketId}`);
    
                channel.listen('TicketNewMessage', (e) => {
                    if (e.notification === 'new_message') {
                        getUserPendingMessages();
                    }
                })
    
                return () => {
                    echo.leave(`${process.env.REACT_APP_WEBSOCKET_PREFIX}ticket.${ticketId}`);
                }
            }
        }

    }, [ticketId, listenTo, userData]);

    useEffect(() => {
        setIsModalOpen(floatOpened);
    }, [floatOpened])

    useEffect(() => {
        if (!isModalOpen && setChannelId !== null) {
            setChannelId('');
        }

        if (setFloatOpened !== null) {
            setFloatOpened(isModalOpen);
        }
    }, [isModalOpen])

    return (
        <div>
            <Fab ref={fabRef} size='small' aria-label='chat' sx={{ position: 'fixed', top: 175, right: 10 }} style={{ color: `${pendingMessages > 0 ? "red" : ""}` }} onClick={() => { setIsModalOpen(!isModalOpen) }}>
                <i className='fas fa-comments'></i>{pendingMessages > 0 && pendingMessages}
            </Fab>
            <Modal
                title='MAMINFO Talks'
                isOpen={isModalOpen}
                setModalOpen={() => setIsModalOpen(!isModalOpen)}
                customStyle={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90vw',
                    height: '95vh',
                }}
            >
                <MainChatComponent channelIdValue={channelId} ticketId={ticketId}></MainChatComponent>
            </Modal>
        </div >
    );
}