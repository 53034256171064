import React, { useState } from 'react';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import { toast } from 'react-toastify';
import { handleErrorsToast } from '../../../../configs/GenericFunctions';

export default function SpareComponents({ mode = 'view', formData = {}, setFormData = null}){

    const INITIAL_STATE = {
        componente: '',
        valor: ''
    }

    const [spareComponentData, setSpareComponentData] = useState(INITIAL_STATE);

    const spareColumns = [
        {
            id: 'action_add_spare',
            name: 'Excluir',
            body: (row) => (<button type='button' className='btn btn-danger' onClick={()=>removeSpareComponent(row)}><i className='fas fa-trash'></i></button>),
            permission: mode !== 'view'
        },
        {
            id: 'componente',
            name: 'Componente'
        },
        {
            id: 'valor',
            name: 'Valor'
        }
    ]

    function addSpareComponent(){
        
        for(let c in spareComponentData){
            if(['', undefined, null].includes(spareComponentData[c])){
                handleErrorsToast('Por favor, preencher todos os campos!');
                return;
            }
        }

        let id = 0;
        let componentesSpare = [...formData?.componentes_spares];
        
        if(componentesSpare?.length > 0){
            id = componentesSpare[componentesSpare?.length - 1]['id_temp'] + 1;
        }
        
        let params = {
            ...spareComponentData,
            valor: formatCurrency(spareComponentData?.valor),
            id_temp: id
        }

        componentesSpare.push(params);

        setFormData(prev => ({...prev, componentes_spares: componentesSpare}));
        setSpareComponentData(INITIAL_STATE);
    }

    function removeSpareComponent(row){
        let componentes = [...formData?.componentes_spares]?.filter((item) => item?.id_temp !== row?.id_temp);

        setFormData(prev => ({...prev, componentes_spares: componentes}));
    }

    function handleValor(e) {
        let valor = parseInt(e.target.value.replace(/\D/g, ''), 10) || 0;
        setSpareComponentData(prev => ({ ...prev, [e.target.name]: valor }))
    }

    const formatCurrency = (val) => {
        const integerValue = parseInt(val, 10) || 0;
        const reais = (integerValue / 100).toFixed(2).replace('.', ',');
        return reais
    };

    return (
        <div className="row">
                <div className="col-12">
                    <h4>Cadastro de Spare Parts</h4>
                </div>
                <div className="col-12">
                    <If condition={mode !== 'view'}>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="componente">Nome Componente</label>
                                <input type="text" name="componente" id="componente" className="form-control" placeholder='...' value={spareComponentData?.componente || ''} onChange={(e)=>setSpareComponentData(prev => ({...prev, [e.target.name]: e.target.value}))} />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="valor">Valor Componente</label>
                                <input type="text" name="valor" id="valor" className="form-control" value={formatCurrency(spareComponentData?.valor)} onChange={(e) => handleValor(e)} />
                            </div>
                            <div className="col-sm-12 col-md-4 mt-1 d-flex align-items-end">
                                <button type="button" className="btn btn-primary" onClick={addSpareComponent}>Adicionar</button>
                            </div>
                        </div>
                    </If>
                    <div className="row">
                        <div className="col-12">
                            <PrimeTable
                                columns={spareColumns}
                                rows={[...formData?.componentes_spares]}
                                id={`${mode}_add_spare_primetable`}
                            ></PrimeTable>
                        </div>
                    </div>
                </div>
            </div>
    )
}