import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getShipmentById } from '../../../../services/Shipment';
import GedTable from '../Components/Ged/GedTable';
import { Dialog } from 'primereact/dialog';
import usePermissions from '../../../../hooks/usePermissions';
import { TabPanel, TabView } from 'primereact/tabview';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, getChaveStatusProduto } from '../../../../configs/GenericFunctions';
import ShipmentForm from '../Components/ShipmentForm';
import { updateEquipmentStatus } from '../../../../services/Ticket';

export default function InTransitDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, inTransitId = '', viewMode = 'view' }) {
    const initialState = {
        modal_envio: '',
        rastreamento: '',
        id_remessa: '',
        id_produto: '',
        chave_status_remessa: ''
    }

    const [formData, setFormData] = useState(initialState);
    const {checkPermission} = usePermissions();
    const {tab: tabPermissions, page: pagePermissions} = getSubComponentsPermissions('mam_logistica', 'InTransit', 'InTransitDetails');

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            id_remessa: formData?.id || '',
            id_produto: formData?.product?.id || '',
            modal_envio: formData?.modal_envio?.value || '',
            rastreamento: formData?.rastreamento || '',
            status_produto: formData?.chave_status_remessa || ''
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateEquipmentStatus(params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setModalOpen(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(inTransitId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let shipmentData = dataReturn?.data?.data;
            let status_remessa = await getChaveStatusProduto('EM TRANSITO');

            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
                urgencia_remessa: shipmentData?.urgencia_remessa || '',
                local: shipmentData?.product?.local || '',
                box: shipmentData?.product?.box || '',
                id_localidade: shipmentData?.id_localidade || '',
                modal_envio: viewMode === 'view' ? shipmentData?.modal_envio || '' : { value: shipmentData?.modal_envio || '', label: shipmentData?.modal_envio || '' },
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: shipmentData?.product?.technician?.nome_tec || '',
                chave_status_remessa: status_remessa?.value || ''
            }));

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(inTransitId)) {
            setFormData(initialState);
            getShipment();
        }
    }, [isOpen, inTransitId]);
    
    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={viewMode === 'view' ? 'Visualizar Remessa' : 'Atualizar Remessa'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header='Detalhes da NFe'>
                    <ShipmentForm formData={formData} setFormData={setFormData} parentPage='InTransitDetails' mode={viewMode} submitForm={submitForm}></ShipmentForm>
                </TabPanel>
                <TabPanel header='GED' visible={checkPermission(tabPermissions[0])}>
                    <GedTable idProduto={formData?.id_produto} viewMode={viewMode === 'edit' && checkPermission(pagePermissions[0]) ? 'edit' : 'view'} submodule='remessas' addTitle='Adicionar Foto da NFe emitida para a remessa'></GedTable>
                </TabPanel>
            </TabView>
        </Dialog>);
}