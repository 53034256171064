import React, { useState } from 'react';
import ModelosSelect from '../../../common/Selects/ModelosSelect';
import FabricanteSelect from '../../../common/Selects/FabricanteSelect';
import GenericSelect from '../../../common/Selects/GenericSelect';
import If from '../../../common/Layout/If';
import ProdutosScrapSelect from '../../../common/Selects/ProdutosScrapSelect';

export default function Form({ mode = 'create', submitForm = null, formData = {}, setFormData = null }) {

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '', id_produto_origem: '' }));
    }

    function handleModelo(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_produto_origem: '' }));
    }

    return (
        <form onSubmit={submitForm}>
            <div className="row">
                <If condition={mode === 'view'}>
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="criado_por">Criado Por</label>
                        <input type="text" name="criado_por" id="criado_por" className='form-control' value={formData?.criado_por || ''} readOnly />
                    </div>
                </If>
                <If condition={mode === 'view'}>
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="criado_em">Criado Em</label>
                        <input type="datetime-local" name="criado_em" id="criado_em" className='form-control' value={formData?.criado_em || ''} readOnly />
                    </div>
                </If>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'origem'} cols={[12, 4, 4, 4]} genericOption='OrigemComponenteOptions' title='Origem' required isDisabled={mode === 'view'}></GenericSelect>
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="componente">Nome Componente</label>
                    <input type="text" name="componente" id="componente" className="form-control" onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.componente || ''} placeholder='...' required readOnly={mode === 'view'} />
                </div>
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="valor">Valor do Componente</label>
                    <input type="number" min={0} step={0.01} name="valor" id="valor" className="form-control" placeholder='...' onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formData?.valor || ''} required={formData?.origem?.value === 'comprado'} readOnly={mode === 'view'} />
                </div>
                <If condition={formData?.origem?.value === 'comprado'}>
                    <FabricanteSelect formData={formData} setFormData={setFormData} advancedSetFormData={handleFabricante} cols={[12, 4, 4, 4]} fieldName='id_fabricante' title='Fabricante' required isDisabled={mode === 'view'}></FabricanteSelect>
                    <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value || ''} advancedSetFormData={handleModelo} required isDisabled={mode === 'view'}></ModelosSelect>
                </If>
                <If condition={formData?.origem?.value === 'canibalizado'}>
                    <ProdutosScrapSelect formData={formData} setFormData={setFormData} fieldName='id_produto_origem' title='Produto de Origem' cols={[12, 4, 4, 4]} isDisabled={mode === 'view'} required></ProdutosScrapSelect>
                </If>
                <If condition={mode !== 'view'}>
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">{mode === 'create' ? 'Cadastrar' : 'Salvar'}</button>
                    </div>
                </If>
            </div>
        </form>
    )
}