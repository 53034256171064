import { Col, FormControl, FormLabel, Row, Form, Button } from "react-bootstrap";
import Card from "../../../common/Layout/Card";
import Content from "../../../common/Layout/Content";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";
import PrimeTable from "../../../common/Layout/Table/PrimeTable";
import { useEffect, useState } from "react";
import ModalEnvioSelect from "../../../common/Selects/ModalEnvioSelect";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast, openNewTab } from "../../../configs/GenericFunctions";
import { getAllShipments } from "../../../services/Shipment";

export default function LogisticReportCostCenter() {
    const INITIAL_STATE = {
        data_inicial_postagem: '',
        data_final_postagem: '',
        modal_envio: '',
        rastreamento: '',
        numero_reversa: '',
        rastreamento_retorno: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            id: 'centro_custo_remessa',
            name: 'Centro de Custo',
        },
        {
            id: 'rastreamento_remessa',
            name: 'Rastreamento',
        },
        {
            id: 'rastreamento_retorno_remessa',
            name: 'Rastreamento Retorno',
        },
        {
            id: 'data_postagem_remessa',
            name: 'Data da Postagem'
        },
        {
            id: 'numero_reversa_remessa',
            name: 'Número da Reversa'
        },
        {
            id: 'modal_remessa',
            name: 'Modal'
        },
        {
            id: 'id_localidade_remessa',
            name: 'Id Localidade',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row?.id_chamado_remessa)}>{row.id_localidade_remessa}</Button>)
        },
        {
            id: 'nf_saida_remessa',
            name: 'NF Saída'
        },
        {
            id: 'integradora_remessa',
            name: 'Integradora'
        }
    ];

    async function getCenterCostShipments(page = 1, perPage = 100, params = {}) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllShipments(page, perPage, params);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];
            let productData = products.map((item) => ({
                id_remessa: item?.id || '',
                id_chamado_remessa: item?.id_chamado || '',
                centro_custo_remessa: item?.centro_custo || '',
                rastreamento_remessa: item?.rastreamento || '',
                rastreamento_retorno_remessa: item?.rastreamento_retorno || '',
                data_postagem_remessa: item?.data_postagem_remessa || '',
                numero_reversa_remessa: item?.numero_reversa || '',
                modal_remessa: item?.modal_envio || '',
                id_localidade_remessa: item?.ticket?.num_chamado || '',
                nf_saida_remessa: item?.nfe_saida || '',
                integradora_remessa: item?.ticket?.contract?.integrator?.nome || '' 

            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    function clearFields() {
        setFormData(INITIAL_STATE);
    }

    async function filterForm(e) {
        e.preventDefault();
        let params = {
            ...formData,
            modal_envio: formData?.modal_envio?.value || ''
        }
        getCenterCostShipments(1, 100, params);
        setPerPage(100);
        setPage(0);
    }

    const handlePaginationPrime = (newPage) => {
        let params = {
            ...formData,
            modal_envio: formData?.modal_envio?.value || ''
        }
        setPage(newPage);
        getCenterCostShipments(newPage + 1, perPage, params);
    }

    function handleRowPerPagePrime(rows) {
        let params = {
            ...formData,
            modal_envio: formData?.modal_envio?.value || ''
        }
        setPerPage(rows);
        setPage(0);
        getCenterCostShipments(1, rows, params);
    }

    useEffect(() => {
        let params = {
            ...formData,
            modal_envio: formData?.modal_envio?.value || ''
        }
        getCenterCostShipments(page + 1, perPage, params);
    }, []);

    return (
        <Content headerTitle="Relatório Centro de Custo">
            <SubHeaderSpecific subHeaderConfig="mam_logistica_relatorios"></SubHeaderSpecific>
            <Card title="Relatório Centro de Custo">
                <Row>
                    <Col sm='12'>
                        <Form onSubmit={filterForm}>
                            <Row>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="data_inicial_postagem">Data Início</FormLabel>
                                    <FormControl type="date" name="data_inicial_postagem" id="data_inicial_postagem" value={formData?.data_inicial_postagem || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="data_final_postagem">Data Fim</FormLabel>
                                    <FormControl type="date" name="data_final_postagem" id="data_final_postagem" value={formData?.data_final_postagem || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                                </Col>
                                <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName="modal_envio"></ModalEnvioSelect>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="rastreamento">Rastreamento</FormLabel>
                                    <FormControl type="text" name="rastreamento" id="rastreamento" value={formData?.rastreamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="numero_reversa">Numero da Reversa</FormLabel>
                                    <FormControl type="text" name="numero_reversa" id="numero_reversa" value={formData?.numero_reversa || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="rastreamento_retorno">Rastreamento de Retorno</FormLabel>
                                    <FormControl type="text" name="rastreamento_retorno" id="rastreamento_retorno" value={formData?.rastreamento_retorno || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <Col sm='12' className="mt-1">
                                    <Button variant="primary" type="submit" className="mr-1">Filtrar</Button>
                                    <Button variant="warning" type="button" onClick={clearFields}>Limpar Campos</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='relatorio_centro_custo_table'
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            reorderableColumns
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    )
}