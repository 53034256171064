import React, { useEffect, useState } from 'react';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import Cep from '../../../../common/Cep/Cep';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { getShipmentById, sendBulkInvoiceIssuance } from '../../../../services/Shipment';
import If from '../../../../common/Layout/If';
import AsyncChamadosSelect from '../../../../common/Selects/AsyncChamadosSelect';
import { getTicketById } from '../../../../services/Ticket';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast, getCurrentDate } from '../../../../configs/GenericFunctions';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';

export default function BulkShipmentDetails({ isOpen = false, setIsOpen = null, shipmentId = '', ticketId = '', shipmentType = '', sendModal = '', setUpdateShipment = null, setUpdateLogistic = null, handleClearRows = null }) {

    const INITIAL_STATE = {
        nome_produto: '',
        serial: '',
        modelo: '',
        local: '',
        box: '',
        id_chamado: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: { value: sendModal || '', label: sendModal || '' },
        responsavel_separacao: '',
        destinatario: '',
        data_entrega: getCurrentDate(),
        volume_nfe: { value: '1', label: '1'}
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [tableFormData, setTableFormData] = useState([]);
    const mySwal = withReactContent(Swal);
    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto'
        },
        {
            id: 'serial_produto',
            name: 'Serial'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto'
        }
    ]

    async function getShipments() {
        let shipmentIdArray = shipmentId.split(',');

        for (let id of shipmentIdArray) {
            getShipmentById(id).then((resp) => {
                let item = resp?.data?.data;
                setTableFormData(prev => [...prev, { nome_produto: item?.product?.partnumber?.modelo || '', serial_produto: item?.product?.serial || '', tipo_produto: item?.product?.nome_produto || '' }])
            }).catch(e => handleErrorsToast('Ocorreu um erro', e?.data));
        }

        if (!['', undefined, null, false].includes(ticketId)) {
            getChamadoById(ticketId, 'centro_custo');
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        const toastData = toast.loading('Atualizando dados, aguarde...');

        let params = {
            ...formData,
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            modal_envio: formData?.modal_envio?.value || '',
            box: formData?.box?.value || '',
            local: formData?.local?.value || '',
            id_chamado: formData?.id_chamado?.value || '',
            chave_tipo_remessa: shipmentType,
            ids_remessa: shipmentId.split(','),
            volume_nfe: formData?.volume_nfe?.value || '',
            responsavel_separacao: formData?.responsavel_separacao?.label || ''
        }

        let dataReturn = await sendBulkInvoiceIssuance(params);

        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data;
            let errors = [];

            for (let d in data) {
                if (!data[d]['success']) {
                    errors.push({ message: data[d]['message'] });
                }
            }

            if (errors.length > 0) {
                handleErrorsToast('Algumas remessas não foram atualizadas', {}, 'update', toastData);
                mySwal.fire({
                    title: 'Algumas remessas não foram atualizadas!',
                    text: 'Segue os erros abaixo',
                    html: (
                        <ul>
                            {errors.map((item, idx) => {
                                return <li key={idx}>Erro: {item?.message}</li>
                            })}
                        </ul>
                    )
                })
            } else {
                handleSuccessToast('Todas as remessas foram atualizadas com sucesso!', 'update', toastData);
            }
            setUpdateShipment();
            setIsOpen(false);
            setUpdateLogistic(true);
            handleClearRows();

        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data, 'update', toastData);
        }
    }

    function handleChamado(value, name) {
        setFormData(prevState => ({ ...prevState, [name]: value }));
        if (value && formData?.modal_envio?.value !== 'Em Mãos') {
            getChamadoById(value?.value);
        } else {
            setFormData(prevState => ({
                ...prevState,
                bairro: '',
                cep: '',
                cidade: '',
                uf: '',
                rua: '',
                numero: '',
                complemento: '',
                destinatario: '',
                centro_custo: ''
            }))
        }
    }

    async function getChamadoById(id) {
        let ticketData = await getTicketById(id);
        if (ticketData?.data?.response === 'success') {
            let ticket = ticketData?.data?.data;
            let address = ticket?.logistics_shipping;
            let addressNumber = address?.compl_ref_obs_endereco_logistica.match(/\d+/)[0] || address?.logradouro_endereco_logistica.match(/\d+/)[0] || '';

            setFormData(prev => ({ ...prev, id_chamado: { value: ticket?.id || '', label: `${ticket?.num_chamado || ''} - ${ticket?.status_chamado || ''}` } }))

            let final_address = {};

            if(!['', null, undefined, false].includes(address)){
                final_address = {
                    bairro: address?.bairro_endereco_logistica || '',
                    cep: address?.cep_endereco_logistica || '',
                    cidade: address?.cidade_endereco_logistica || '',
                    uf: address?.estado_endereco_logistica || '',
                    rua: address?.logradouro_endereco_logistica || '',
                    numero: addressNumber,
                    complemento: address?.compl_ref_obs_endereco_logistica || '',
                    destinatario: address?.destinatario_remessa || '',
                }
            }else if(['', null, undefined, false].includes(address) && ['envio_tecnico'].includes(shipmentType)){
                let technicianAddress = ticket?.technician;
                let technicianAddressNumber = technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || '';

                final_address = {
                    bairro: technicianAddress?.bairro_end || '',
                    cep: technicianAddress?.cep_end || '',
                    cidade: technicianAddress?.cidade_end || '',
                    uf: technicianAddress?.estado_end || '',
                    rua: technicianAddress?.logradouro_end || '',
                    numero: technicianAddressNumber,
                    complemento: technicianAddress?.compl_ref_obs_end || '',
                    destinatario: technicianAddress?.nome_tec || '',
                }
            }else if(['', null, undefined, false].includes(address) && shipmentType === 'envio_cliente'){
                let customerAddress = ticket?.customer;
                let customerAddressNumber = customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || '';
                
                final_address = {
                    bairro: customerAddress?.bairro_cliente || '',
                    cep: customerAddress?.cep_cliente || '',
                    cidade: customerAddress?.cidade_cliente || '',
                    uf: customerAddress?.estado_cliente || '',
                    rua: customerAddress?.endereco_cliente || '',
                    numero: customerAddressNumber,
                    complemento: customerAddress?.compl_ref_obs_endereco_cliente || '',
                    destinatario: '',
                }
            }

            setFormData(prevState => ({
                ...prevState,
                ...final_address,
                centro_custo: ticket?.contract?.centro_custo || '',
            }));

        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os dados do chamado!', ticketData?.data);
        }
    }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(shipmentId)) {
            setFormData(INITIAL_STATE);
            setTableFormData([]);
            getShipments();
        }
    }, [isOpen, shipmentId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Emissão de NFE'
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5>{formData?.modal_envio?.value !== 'Em Mãos' ? `Preencha todos os dados abaixo para "Emissão da NFE"` : `Preencha todos os dados abaixo para confirmar a entrega ao técnico.`}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={tableFormData}
                            needExport={false}
                            id='bulk_shipment_details'
                        ></PrimeTable>
                    </div>
                </div>
                <div className="row">
                    <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 12, 4, 4]} genericOption='UfOptions'></GenericSelect>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} uf={formData?.local?.value}></BoxSelect>
                    </If>
                    <AsyncChamadosSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='id_chamado' title='ID da Localidade\ID do Chamado' advancedSetFormData={handleChamado}></AsyncChamadosSelect>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h6>Detalhes da Remessa</h6>
                    </div>
                </div>
                <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                    <Cep
                        formData={formData}
                        setFormData={setFormData}
                        formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua' }}
                    ></Cep>
                </If>
                <div className="row">
                    <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="numero">Número</label>
                            <input type="text" name="numero" id="numero" placeholder='...' className="form-control" value={formData?.numero || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="complemento">Complemento</label>
                            <input type="text" name="complemento" id="complemento" placeholder='...' className="form-control" value={formData?.complemento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="destinatario">Destinatário</label>
                            <input type="text" name="destinatario" id="destinatario" placeholder='...' className="form-control" value={formData?.destinatario || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                        </div>
                    </If>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="dimensoes">Dimensões</label>
                        <input type="text" name="dimensoes" id="dimensoes" placeholder='...' className="form-control" value={formData?.dimensoes || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="centro_custo">Centro Custo</label>
                        <input type="text" name="centro_custo" id="centro_custo" placeholder='...' className="form-control" value={formData?.centro_custo || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="peso_kg">Peso (kg)</label>
                        <input type="number" step={"0.01"} min={"0"} name="peso_kg" id="peso_kg" placeholder='...' className="form-control" value={formData?.peso_kg || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <GenericSelect fieldName={'volume_nfe'} formData={formData} setFormData={setFormData} title={'Volume da NFe'} cols={[12, 12, 4, 4]} genericOption='VolumeNfeOptions' required={true}></GenericSelect>
                    <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='modal_envio' ></ModalEnvioSelect>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='responsavel_separacao' cols={[12, 12, 4, 4]} userType='all' title='Responsável pela Separação' userGroup='logistica_operacao'></UsuariosAtivosSelect>
                    <If condition={formData?.modal_envio?.value === 'Em Mãos'}>
                        <div className='col-md-4 col-sm-12'>
                            <label htmlFor="data_entrega">Data de entrega ao técnico</label>
                            <input type="date" name="data_entrega" id="data_entrega" className="form-control" title='Data de entrega nas mãos do técnico' value={formData?.data_entrega || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </If>
                </div>
                <div className="row mt-1">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </div>
            </form>
        </Dialog>)
}
