import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { getModules } from '../../../services/DevMode';
import { toast } from 'react-toastify';
import CreateModule from './CreateModule';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import { Button, Col, Row } from 'react-bootstrap';

export default function Modules() {

    const [data, setData] = useState([]);
    const [openCreateModule, setOpenCreateModule] = useState(false);
    // const { checkPermission } = usePermissions();
    const columns = [
        {
            id: 'id',
            name: 'ID'
        },
        {
            id: 'nome_modulo',
            name: 'Nome'
        },
        {
            id: 'descricao',
            name: 'Descrição'
        },
        {
            id: 'status',
            name: 'Status',
            body: (row) => row?.status === 1 ? 'Ativo' : 'Inativo'
        },
        {
            id: 'criado',
            name: 'Cadastrado em'
        }
    ]

    const getAllModules = async () => {
        const toastModules = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getModules();
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastModules);

            let modules = dataReturn.data.data;
            setData(modules);

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastModules);
            setData([]);
        }
    }

    useEffect(() => {
        getAllModules();
    }, [])

    return (
        <Content headerTitle='Gerenciar Módulos'>
            <CreateModule isOpen={openCreateModule} setIsOpen={() => setOpenCreateModule(!openCreateModule)} updateParent={getAllModules}></CreateModule>
            <SubHeaderSpecific subHeaderConfig='dev_mode'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <Row>
                    {/* <If condition={checkPermission('')}> */}
                    <Col sm='12'>
                        <Button variant='primary' type="button" onClick={() => setOpenCreateModule(!openCreateModule)}>Cadastrar Modulo</Button>
                    </Col>
                    {/* </If> */}
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='modules_table'
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    );
}