import React from 'react';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import { changeOwnerByTicketId } from '../../../services/Ticket';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function SuporteAnalistaResponsavelCard({id = '', originalData = {}, getTicketDetailById = null, getHistory = null}){

    async function changeTicketOwner() {

        const toastTicketOwner = toast.loading("Alterando responsável, aguarde...");

        let dataReturn = await changeOwnerByTicketId(id, { type: 'support' });
        if (dataReturn?.data?.response === 'Ticket updated successfully!') {
            let responsavel = dataReturn?.data?.data?.ticket_responsible_user;
            handleSuccessToast(`Responsável pelo chamado alterado com sucesso para ${responsavel?.nome}`, 'update', toastTicketOwner);
            getTicketDetailById();
            getHistory();
        } else {
            handleErrorsToast('Ocorreu um erro ao alterar o responsável pelo chamado!', dataReturn?.data, 'update', toastTicketOwner);
        }
    }

    return(<Card title={"Suporte - Analista Responsável"} icon={"fa-check"}>
        <div className="row">
            <div className="col-12 mb-2">
                <button className="btn btn-secondary" onClick={changeTicketOwner}>Assumir Chamado</button>
            </div>
            <div className="col-12">
                <p><b>Analista Responsável: </b>{originalData?.ticket_responsible_user?.nome || ''}</p>
            </div>
        </div>
    </Card>);
}