import { ColorPicker } from "primereact/colorpicker";
import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";

export default function TagForm({type = 'create', formData = {}, setFormData = null, submitForm = null}) {
    return (
        <Form onSubmit={submitForm}>
            <Row>
                <Col sm='6' className="mb-1">
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <FormControl type="text" name="nome" id="nome" placeholder="Nome" value={formData?.nome || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} required></FormControl>
                </Col>
                <Col sm='6' className="mb-1">
                    <FormLabel htmlFor="cor_hexadecimal" className="block">Cor</FormLabel>
                    <ColorPicker format="hex" value={formData?.cor_hexadecimal || '808080'} name="cor_hexadecimal" id="cor_hexadecimal" onChange={(e)=> setFormData(p => ({...p, [e.target.name]: e.target.value}))} required></ColorPicker>
                </Col>
                <Col sm='12'>
                    <Button type="submit" variant="primary">{type === 'create' ? 'Cadastrar' : 'Salvar'}</Button>
                </Col>
            </Row>
        </Form>
    );
}