import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTechniciansByPartnerId } from '../../services/Partners';
import { Col, FormLabel } from 'react-bootstrap';
import { getAllEmployee } from '../../services/Employee';

export default function TecnicosSelect({ formData, setFormData, fieldName = 'tecnico', cols = [12, 12, 12, 12], isDisabled = false, required = false, depends_on_representante = true, id_representante = '', advancedSetFormData = null, disponivel = 0, id_contrato = '' }) {
    const [techniciansOptions, setTechniciansOptions] = useState([]);

    async function getTechniciansByPartner(id) {
        let dataReturn = await getTechniciansByPartnerId(id, disponivel, id_contrato);
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let tecnicos = dataReturn.data.data.map((item, idx) => ({ value: item.id, label: item.nome_tec }));
            setTechniciansOptions(prevState => ([...tecnicos]));
        } else {
            setTechniciansOptions([]);
        }
    }

    async function getAllTechnicians() {
        let dataReturn = await getAllEmployee({
            'categoria': ['Técnico'],
        });

        if (dataReturn?.status === 200) {
            let tecnicos = dataReturn.data.data.map((item, idx) => ({ value: item.id, label: item.nome_tec }));
            setTechniciansOptions(tecnicos);
        } else {
            setTechniciansOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData === null) {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        } else {
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        let id = id_representante;
        if (depends_on_representante) {
            if (id !== undefined && id !== '') {
                getTechniciansByPartner(id);
            } else {
                setTechniciansOptions([]);
            }
        } else {
            getAllTechnicians();
        }

    }, [id_representante, id_contrato, depends_on_representante]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Técnicos</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={techniciansOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
                isClearable={true}
            ></Select>
        </Col>
    );
}