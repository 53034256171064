import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

export default function SearchField({ placeholder = 'Pesquise pelo nome do canal', searchFunction }) {
    const [search, setSearch] = useState('');

    useEffect(()=>{
        searchFunction(search);
    }, [search]);

    return (
        <InputGroup className="mb-3">
            <FormControl type="text" name='pesquisa' placeholder={placeholder} value={search} onChange={(e) => setSearch(e.target.value)} aria-label="Pesquisar" aria-describedby="button-addon2" />
        </InputGroup>
    );
}