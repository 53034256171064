import React from 'react';
import Card from '../../../common/Layout/Card';

export default function NovoNumeroRMACard({detailsData = {}, setDetailsData = null}) {
    return (<Card title="Novo Número RMA" icon="fa-dot-circle">
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="">Número RMA</label>
                    <input type="text" name="numero_rma" className="form-control" value={detailsData?.numero_rma || ''} onChange={e => (setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                </div>
            </div>
        </div>
    </Card>);
}