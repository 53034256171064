import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../Layout/Modal';
import ChamadosSelect from '../Selects/ChamadosSelect';
import { ChatContext } from './MainChatComponent';
import If from '../Layout/If';
import { Button, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

export default function ChannelData({ title = '', isOpen, setModalOpen, updateChannel = false, changeChannelData }) {
    const { chatState } = useContext(ChatContext);

    const initialState = useMemo(() => ({
        channel_name: '',
        channel_description: '',
        ticket_id: { value: chatState?.ticketId || '', label: '' }
    }), [chatState?.ticketId]);

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (updateChannel) {
            setFormData(prevState => (
                {
                    ...prevState,
                    channel_name: chatState?.channelData?.channel_name || '',
                    channel_description: chatState?.channelData?.channel_description || '',
                    ticket_id: { value: chatState?.channelData?.ticket_id || '', label: `${chatState?.channelData?.ticket_number || ''} - ${chatState?.channelData?.ticket_status || ''}` }
                }
            ))
        } else {
            setFormData(initialState);
        }
    }, [isOpen, updateChannel, initialState, chatState?.channelData?.channel_name, chatState?.channelData?.channel_description, chatState?.channelData?.ticket_id, chatState?.channelData?.ticket_number, chatState?.channelData?.ticket_status]);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}
        >
            <Form>
                <Row>
                    <Col sm='12'>
                        <FormLabel htmlFor="channel_name">Nome do Canal</FormLabel>
                        <FormControl type="text" name="channel_name" id="channel_name" placeholder='...' value={formData?.channel_name} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </Col>
                    <Col sm='12'>
                        <FormLabel htmlFor="channel_description">Descrição do Canal</FormLabel>
                        <FormControl type="text" name="channel_description" id="channel_description" placeholder='...' value={formData?.channel_description} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </Col>
                    <If condition={chatState?.ticketId === ''}>
                        <ChamadosSelect formData={formData} setFormData={setFormData} fieldName='ticket_id'></ChamadosSelect>
                        <span style={{ fontSize: '0.8rem' }}>Selecione apenas se deseja vincular este canal a um chamado!</span>
                    </If>
                </Row>
                <Row>
                    <Col sm='12' className="mt-1">
                        <Button type="button" className="btn btn-success float-right" onClick={() => changeChannelData(formData)}>Confirmar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}