import React from "react";
import FloatChatButton from "../Chat/FloatChatButton";
import If from "./If";
import { getSubComponentsPermissions } from "../../configs/MenuItemsConfigs";
import usePermissions from "../../hooks/usePermissions";
import { Col, Container, Row } from "react-bootstrap";

export default function Content({ headerTitle = "", showChat = true, children }) {
    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_talks', 'MainChat', 'FloatChatButton');
    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm='12'>
                            <h1 className="m-0">{headerTitle}</h1>
                        </Col>{/* /.col */}
                    </Row>{/* /.row */}
                </Container>{/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
                <Container fluid>
                    <If condition={showChat && checkPermission(pagePermissions[0])}>
                        <FloatChatButton></FloatChatButton>
                    </If>
                    {children}
                </Container>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    );
}