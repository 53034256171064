import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import CreateSparePart from './CreateSparePart';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteComponentById, getComponents } from '../../../services/Repair';
import SparePartDetails from './SparePartDetails';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import usePermissions from '../../../hooks/usePermissions';
import If from '../../../common/Layout/If';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function SpareParts() {

    const [visible, setIsVisible] = useState(false);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [id, setId] = useState('');
    const [data, setData] = useState([]);
    const { action: actionPermission, page: pagePermission } = getSubComponentsPermissions('mam_reparos', 'SpareParts', 'SpareParts');
    const { checkAllPermissions, checkPermission } = usePermissions();
    const columns = [
        {
            id: 'action_spare_part',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermission[0])}>
                            <button type="button" className="dropdown-item" onClick={() => handleDetails(row.id)}>Ver Componente</button>
                        </If>
                        <If condition={checkPermission(actionPermission[1])}>
                            <button type='button' className="dropdown-item" onClick={() => showDeleteSpareSwal(row.id)}>Deletar</button>
                        </If>
                    </div>
                </div >
            ),
            permission: checkAllPermissions(actionPermission)
        },
        {
            id: 'componente',
            name: 'Componente'
        },
        {
            id: 'valor',
            name: 'Valor'
        },
        {
            id: 'partnumber',
            name: 'Modelo'
        },
        {
            id: 'product_origin',
            name: 'Serial Produto Origem'
        },
        {
            id: 'origem',
            name: 'Origem'
        }
    ]

    async function showDeleteSpareSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja excluir este componente?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (swal.isConfirmed) {
            deleteSparePart(id);
        }
    }

    async function deleteSparePart(id) {
        let toastSpare = toast.loading('Excluindo componente, aguarde...');

        let dataReturn = await deleteComponentById(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Componente excluido com sucesso!', 'update', toastSpare);
            getSpareParts();
        } else {
            handleErrorsToast('Ocorreu um erro ao excluir o componente!', dataReturn?.data, 'update', toastSpare);
        }
    }

    async function getSpareParts() {
        let toastSpare = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getComponents();

        if (dataReturn?.data?.response === 'success') {
            let spareParts = dataReturn?.data?.data?.map(item => ({ ...item, product_origin: item?.product_origin?.serial || '', partnumber: item?.partnumber?.modelo || '' })) || [];
            setData(spareParts);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastSpare);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastSpare);
        }
    }

    function handleDetails(id) {
        setId(id);
        setDetailsVisible(true);
    }

    useEffect(() => {
        getSpareParts();
    }, []);

    return (
        <Content headerTitle='Saving - Spare Parts'>
            <CreateSparePart visible={visible} onHide={() => setIsVisible(!visible)} updateParent={getSpareParts}></CreateSparePart>
            <SparePartDetails visible={detailsVisible} onHide={() => setDetailsVisible(!detailsVisible)} id={id}></SparePartDetails>
            <Card title='Componentes'>
                <div className="row">
                    <If condition={checkPermission(pagePermission[0])}>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={() => setIsVisible(!visible)}>Cadastrar</button>
                        </div>
                    </If>
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='componentes_primetable'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    )
}