import interceptorInstance from "./Interceptor";

export async function getBoxByUf(uf){
    try{
        let data = await interceptorInstance.get(`mambox/filter?local=${uf}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createBox(params){
    try{
        let data = await interceptorInstance.post(`mambox/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}