import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import echo from '../../../services/LaravelEcho';

import MenuItem from "./MenuItem";
import { getSidenavs } from "../../../configs/MenuItemsConfigs";

import { Context } from "../../../App";
import { getPendingMessages } from "../../../services/Chat";
import notificationSound from '../../../resources/sounds/notification.mp3';
import logo from '../../../resources/imgs/logo/logo_star_maminfo.png';
import { getPendingCount } from "../../../services/EmployeeExpense";
import { handleSuccessToast, detectDeviceTypeByUserAgent, onImageError } from "../../../configs/GenericFunctions";

function SideNav({childRef}) {

    const [pendingMessages, setPendingMessages] = useState('');
    const [pendingExpenses, setPendingExpenses] = useState('');
    const userData = useContext(Context);

    function renderMenu() {
        return getSidenavs().map((item) => {
            return <MenuItem
                name={item.name}
                path='#'
                icon={item.icon}
                children={item.menu_paths}
                key={item.id}
                permission_group={item.permissions}></MenuItem>
        }
        )
    }

    async function getUserPendingMessages() {
        let dataReturn = await getPendingMessages();

        if (dataReturn?.data?.response === 'success') {
            setPendingMessages(dataReturn?.data?.data?.pending_message_count || '');
        }
    }

    async function getPendingExpenses() {
        let dataReturn = await getPendingCount();

        if (dataReturn?.data?.response === 'success') {
            setPendingExpenses(dataReturn?.data?.data?.contagem_pendente || '');
        }
    }

    useEffect(() => {
        if (userData?.userDataState?.atualizar_qtd_faturamento) {
            getPendingExpenses();
            userData?.setUserDataState(prevState => ({ ...prevState, atualizar_qtd_faturamento: false }));
        }

        if (userData?.userDataState?.id_usuario !== '') {
            getUserPendingMessages();

            const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

            webSocketChannel.listen('NewMessage', (e) => {
                if (e.notification === 'new_message') {
                    getUserPendingMessages();
                    if (e.sent_by !== userData?.userDataState?.id_usuario) {
                        let audio = new Audio(notificationSound);
                        audio.play().catch((e) => {
                            console.log(e);
                        });
                        handleSuccessToast('Você recebeu uma nova mensagem!');
                        if (Notification.permission === "granted") {
                            let device = detectDeviceTypeByUserAgent();

                            if (device === 'mobile') {
                                navigator.serviceWorker.getRegistration().then(function (registration) {
                                    if (registration) {
                                        registration.showNotification('MAMERP - Você recebeu uma nova mensagem!', { icon: logo });
                                    } else {
                                        console.log('Service Worker não registrado');
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                })
                            } else {
                                new Notification('MAMERP - Você recebeu uma nova mensagem!', { icon: logo, tag: 'notificacaoMensagem'});
                            }
                        }
                    }
                }
            })

            return () => {
                echo.leave(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);
            }
        }
    }, [userData]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-1 fixed" ref={childRef}>
            {/* Brand Logo */}
            <Link to="/" className="brand-link" style={{ padding: '.30rem .5rem' }}>
                <img src="/imgs/logo/logo_star_maminfo.png" alt="Maminfo Logo" className="brand-image" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">Maminfo | ERP</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={userData.userDataState.url_foto_perfil} className="img-circle elevation-2" alt="Usuário" onError={onImageError} />
                    </div>
                    <div className="info">
                        <Link to="/perfil/dados" className="d-block">{userData.userDataState.login}</Link>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {renderMenu()}
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    );
}

export default SideNav;
