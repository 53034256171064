import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkValue, handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import TagForm from "./Components/TagForm";
import { getTagDataById, updateTag } from "../../../services/Tags";

export default function UpdateTag({ isOpen = false, setIsOpen = null, updateParent = null, updateId = '' }) {

    const INITIAL_STATE = {
        nome: '',
        cor_hexadecimal: '808080',
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getData(id){
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getTagDataById(id);

        if(dataReturn?.status === 200){
            let data = dataReturn?.data;
            setFormData(data);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
            setFormData(INITIAL_STATE);
        }
    }

    async function submitForm(e){
        e.preventDefault();

        const toastData = toast.loading("Atualizando Tag, aguarde...");

        let dataReturn = await updateTag(updateId, formData);

        if(dataReturn?.status === 200){
            handleSuccessToast('Tag atualizada com sucesso!', 'update', toastData);
            updateParent();
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }
    
    useEffect(()=>{
        if(!checkValue(updateId)){
            getData(updateId);
        }else{
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Atualizar Tag'
            maximizable
        >
           <TagForm type="update" formData={formData} setFormData={setFormData} submitForm={submitForm}></TagForm>
        </Dialog>
    );
}