import React, { useContext, useEffect, useState } from 'react'
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { handleSuccessToast } from '../../../configs/GenericFunctions';
import { toast } from 'react-toastify';
import { getTickets } from '../../../services/Ticket';
import { Context } from '../../../App';

export default function ShowRatTickets() {
    const INITIAL_STATE = {
        data_inicial: '',
        data_final: '',
        status_chamado: [{ value: '', label: 'TODOS' }]
    }

    const arrayFields = ['status_chamado', 'contrato', 'remove_contrato', 'integradora'];
    const userData = useContext(Context);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (<Link to={'/rat/detalhes/' + row.id}><i className='fas fa-eye'></i></Link>)
        },
        {
            id: 'action_pdf_download',
            name: 'PDF Improdutivo',
            body: (row) => (<button type='button' className='btn btn-link' onClick={() => gerarPDFRATOK(row.id)}><i className='fas fa-download'></i></button>),
        },
        {
            id: 'num_chamado',
            name: 'ID Ticket Cli. / Nº Chamado',
        },
        {
            id: 'data_agendamento',
            name: 'Data Agendamento',
        },
        {
            id: 'hora_agendamento',
            name: 'Hora Agendamento',
        },
        {
            id: 'responsavel_atendimento',
            name: 'Responsavel Atendimento',
        },
        {
            id: 'status_chamado',
            name: 'Status Chamado',
        },
        {
            id: 'cliente',
            name: 'Cliente',
        },
        {
            id: 'integradora',
            name: 'Integradora',
        },
        {
            id: 'contrato',
            name: 'Contrato',
        },
        {
            id: 'tecnico',
            name: 'Técnico',
            body: (row) => (<Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}>{row.tecnico}</Link>)
        },
        {
            id: 'representante',
            name: 'Representante',
        },
        {
            id: 'doc_entregue_validacao',
            name: 'Doc Entregue / Validação',
            body: (row) => (<b style={{ color: 'red' }}>{row.doc_entregue_validacao}</b>)
        },
        {
            id: 'contato_tecnico',
            name: 'Contato Tecnico',
        },
        {
            id: 'status_tecnico',
            name: 'Status Técnico',
        },
        {
            id: 'descricao',
            name: 'Descrição',
        },
        {
            id: 'endereco_cliente',
            name: 'End. Cliente',
        },
        {
            id: 'bairro_cliente',
            name: 'Bairro Cliente',
        },
        {
            id: 'cidade_cliente',
            name: 'Cidade Cliente',
        },
        {
            id: 'estado_cliente',
            name: 'Estado Cliente',
        },
        {
            id: 'cep_cliente',
            name: 'CEP Cliente',
        },
        {
            id: 'compl_end_cliente',
            name: 'Comp. End. Cliente',
        },
        {
            id: 'contato_no_cliente',
            name: 'Contato no Cliente',
        },
        {
            id: 'tel_contato_cliente',
            name: 'Tel. Contato Cliente',
        },
        {
            id: 'ocasiao_fechamento',
            name: 'Ocasião Fechamento'
        },
        {
            id: 'motivo_improdutivo',
            name: 'Motivo Improdutivo'
        },
        {
            id: 'senha_atendimento',
            name: 'Senha Atendimento'
        }
    ];

    async function gerarPDFRATOK(id) {
        window.open(`https://mamerp.maminfo.com.br:8080/api/app/ticket/${id}/rat/?improdutivo=1`, '_blank');
    }

    const conditionRow = (row) => {
        return {
            "danger-color custom-style": row.cor_row === 'vermelho',
            "info-color custom-style": row.cor_row === 'azul',
            "warning-color custom-style": row.cor_row === 'amarelo',
            "success-color custom-style": row.cor_row === 'verde',
        }
    }

    async function getTicketsByFilter(e) {
        e.preventDefault();
        let params = {};
        
        params['data_inicial'] = formData?.data_inicial || '';
        params['data_final'] = formData?.data_final || '';
        params['status_chamado'] = formData?.status_chamado?.map((item) => (item?.value === undefined ? '' : item.value));
        params['id_tecnico'] = userData?.userDataState?.id_tecnico || '';
        params['contrato'] = [161069];

        let url = `?`;

        for (let p in params) {
            if (arrayFields.includes(p)) {
                for (let c = 0; c < params[p].length; c++) {
                    url += (`${p}=${params[p][c]}&`);
                }
            } else {
                url += (`${p}=${params[p]}&`);
            }
        }

        url = url.slice(0, -1);

        navigate(url);
    }

    async function getTicketsByParam(page, perPage) {
        const toastTicket = toast.loading("Carregando, aguarde...");
        try {
            let urlParams = new URLSearchParams(location.search);
            let params = {
                page: page,
                per_page: perPage
            }
            let booleanFields = ['gts', 'key_ticket_cliente_null']

            urlParams.forEach((item, idx) => {

                if (item !== "") {
                    if (arrayFields.includes(idx)) {
                        if (!params[idx]) {
                            params[idx] = [];
                        }
                        params[idx].push(item);
                    } else if (booleanFields.includes(idx)) {
                        params[idx] = item === "true" ? true : false;
                    } else {
                        params[idx] = item;
                    }
                }
            });
            setLoading(true);
            let dataReturn = await getTickets(params);
            if (dataReturn?.data?.response === 'success') {
                let ticketData = dataReturn?.data?.data || [];
                if (ticketData?.tickets?.length > 0) {
                    ticketData.tickets = ticketData?.tickets?.map((item, idx) => {
                        for (let i in item) {
                            item[i] = [undefined, null].includes(item[i]) ? '' : item[i]
                        }
                        return item;
                    });
                }
                setData(ticketData?.tickets);
                setPaginationTotalRows(ticketData?.pagination?.total)
            } else {
                setPaginationTotalRows(0)
                setData([]);
            }
            setLoading(false);
        } catch (e) {
            setData([]);
            setLoading(false);
        }
        handleSuccessToast('Pesquisa realizada com sucesso!', 'update', toastTicket);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
    }

    useEffect(() => {
        getTicketsByParam(page + 1, perPage);
    }, [location]);

    return (
        <Content headerTitle='Gerenciar Chamados RAT'>
            <SubHeaderSpecific subHeaderConfig='mam_rat'></SubHeaderSpecific>
            <Card hideCardHeader>
                <form onSubmit={getTicketsByFilter}>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="data_inicial">Data Início</label>
                            <input type="date" name="data_inicial" id="data_inicial" className="form-control" value={formData?.data_inicial || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                        <div className="col">
                            <label htmlFor="data_inicial">Data Fim</label>
                            <input type="date" name="data_final" id="data_final" className="form-control" value={formData?.data_final || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                        <StatusChamadoSelect formData={formData} setFormData={setFormData} cols={[]} fieldName='status_chamado' showTodos isMulti></StatusChamadoSelect>
                        <div className="col d-flex align-items-end mt-1">
                            <button type="submit" className="btn btn-primary">Filtrar Chamados</button>
                        </div>
                    </div>
                </form>
            </Card>
            <Card hideCardHeader>
                <PrimeTable
                    columns={columns}
                    rows={data}
                    serverPagination
                    handlePagination={handlePaginationPrime}
                    handleRowPerPage={handleRowPerPagePrime}
                    count={paginationTotalRows}
                    page={page}
                    rowsPerPage={perPage}
                    loading={loading}
                    customStyles={conditionRow}
                    needExport
                    id='rat_tickets_prime_table'
                ></PrimeTable>
            </Card>
        </Content>
    );
}