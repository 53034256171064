import React, { useEffect, useState } from 'react';
import BrazilMap from './BrazilMap';
import BrazilTable from './BrazilTable';

import { getAvailableTechniciansByState } from '../../../../services/Technician';
import { getCurrentDate, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { toast } from 'react-toastify';
import TechniciansChartContent from '../Components/TechniciansChartContent';

export default function TechniciansByBrazil(){
    const [data, setData] = useState([]);
    const [mapData, setMapData] = useState([]);
    let interval;
    const milliseconds = 600000; //10 minutos
    
    useEffect(()=>{
        async function getBrazilData(){
            const toastData = toast.loading('Carregando dados, aguarde...');
            let data_agendamento = getCurrentDate();
            let dataReturn = await getAvailableTechniciansByState(data_agendamento);

            if(dataReturn?.status === 200){
                let data = dataReturn?.data?.data?.contagem_tecnicos_por_estado;

                let dataToMap = data?.map(item => ({id: `BR.${item?.estado_end}`, title: 'Técnicos', value: item?.total || 0, url: `/chamados/capilaridade-tecnicos/qtd/${item?.estado_end}`}));
                let dataToTable = data?.map(item => ({pais: 'Brasil', estado: item?.estado_end, total: item.total || 0}));
                setMapData(dataToMap);
                setData(dataToTable);

                handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            }else{
                handleErrorsToast('Ocorreu um erro:', dataReturn?.data, 'update', toastData);
            }
        }

        getBrazilData();

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getBrazilData();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (<TechniciansChartContent>
        <div className="row">
            <div className="col-12 text-center">
                <h2>Capilaridade de Técnicos por Estado</h2>
                <p>Passe com o mouse por cima dos estados para conferir os valores.</p>
                <p>Clique nos campos da tabela para filtrar mais dados.</p>
                <BrazilMap data={mapData}></BrazilMap>
            </div>
            <div className="col-12">
                <BrazilTable rows={data}></BrazilTable>
            </div>
        </div>
    </TechniciansChartContent>)
}