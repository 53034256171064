import React, { useEffect, useState } from 'react';
import Card from '../../../../common/Layout/Card';
import { getEquipmentsByTicketIdAndStatus, removeEquipmentByIds, submitEquipmentReturn } from '../../../../services/Ticket';
import { toast } from 'react-toastify';
import Table from '../../../../common/Layout/Table/Table';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import If from '../../../../common/Layout/If';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import AddEquipamentoRetornoModal from './AddEquipamentoRetornoModal';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function EquipamentoRetornoCard({
    id = '',
    getHistory = null,
}) {
    
    const [equipmentsReturnData, setEquipmentsReturnData] = useState([]);
    const [showAddEquipamentoRetorno, setShowAddEquipamentoRetorno] = useState(false);

    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'EquipamentoRetornoCard');
    const equipmentsReturnColumns = [
        {
            id: 'action_equipamento_retorno',
            name: 'Ação',
            body: (row) => (
                <div className="align-items-center">
                    <button className='btn btn-secondary' onClick={() => deleteEquipment(row, 'Reversa')}><i className='fas fa-trash'></i></button>
                </div>
            )
        },
        {
            id: 'product_equipamento_retorno',
            name: 'Produto',
        },
        {
            id: 'serial_equipamento_retorno',
            name: 'Serial',
        },
        {
            id: 'status_produto_equipamento_retorno',
            name: 'Status Produto',
        },
        {
            id: 'data_reversa_equipamento_retorno',
            name: 'Data da Reversa',
        },
        {
            id: 'reversa_equipamento_retorno',
            name: 'Reversa',
        }
    ];

    async function deleteEquipment(rowData) {

        let formData = {
            id_remessa: rowData.id_remessa,
            id_produto: rowData.id_produto,
            id_equipamento_chamado: rowData.id_equipamento_chamado
        }

        const deleteToast = toast.loading("Removendo produto do chamado, aguarde...");

        let dataReturn = await removeEquipmentByIds(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast(dataReturn?.data?.data?.message || 'Sucesso', 'update', deleteToast);
            getHistory();
            getEquipmentsByIdStatus();
        } else {
            handleErrorsToast("Ocorreu um erro ao remover o produto!", dataReturn?.data, 'update', deleteToast);
        }
    }

    const getEquipmentsByIdStatus = async () => {
        let dataReturn = await getEquipmentsByTicketIdAndStatus(id, 'reversa');
        if (dataReturn?.data?.response === 'success') {
            let dadosEquipamentos = dataReturn?.data?.data;
            let dadosEquipamentosArray = dadosEquipamentos.map((item, idx) => {
                return ({
                    id_produto: item?.id_produto || '',
                    id_remessa: item?.id || '',
                    id_equipamento_chamado: item?.id || '',
                    product_equipamento_retorno: item?.product?.partnumber?.modelo || item?.partnumber?.modelo || '',
                    serial_equipamento_retorno: item?.product?.serial || '',
                    status_produto_equipamento_retorno: item?.product?.status?.valor_opcao || '',
                    data_reversa_equipamento_retorno: item?.data_retorno || '',
                    reversa_equipamento_retorno: item?.rastreamento_retorno || ''
                });
            }) || [];

            setEquipmentsReturnData(dadosEquipamentosArray);

        } else {
            setEquipmentsReturnData([]);
        }
    }

    useEffect(() => {
        getEquipmentsByIdStatus();
    }, []);

    return (<Card title="Equipamento de Retorno" icon="fa-keyboard">
        <AddEquipamentoRetornoModal isOpen={showAddEquipamentoRetorno} setIsOpen={()=>setShowAddEquipamentoRetorno(!showAddEquipamentoRetorno)} id={id} updateParent={getEquipmentsByIdStatus}></AddEquipamentoRetornoModal>
        <div className="row">
            <If condition={checkPermission(pagePermissions[0])}>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={() => setShowAddEquipamentoRetorno(prevState => !prevState)}>Adicionar Equipamento de Retorno</button>
                </div>
            </If>
            <div className="col-12">
                <PrimeTable
                    columns={equipmentsReturnColumns}
                    rows={equipmentsReturnData}
                    needExport={false}
                    id='equipamento_retorno_prime_table'
                ></PrimeTable>
            </div>
        </div>
    </Card>);
}