import React, { useEffect, useState } from 'react';
import { Options } from '../../configs/GenericOptions';
import Select from 'react-select';
import { Col, FormLabel } from 'react-bootstrap';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import usePermissions from '../../hooks/usePermissions';
import { checkValue } from '../../configs/GenericFunctions';

export default function GenericSelect({ formData, setFormData, fieldName, title = '', genericOption = 'SimNaoOptions', cols = [12, 12, 12, 12], isMulti = false, isDisabled = false, required = false, advancedSetFormData = null, isClearable = true, needPermissions = false }) {
    const [genericOptions, setGenericOptions] = useState([]);
    const { checkPermission } = usePermissions();

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData !== null) {
            advancedSetFormData(value, name);
        } else {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    }

    useEffect(()=>{
        if(needPermissions){            
            let optionsData = [...Options?.[genericOption]];
            let filteredOptions = [];
            for(let option of optionsData){
                if(checkValue(option?.permission)){
                    let hasPermission = checkPermission(option.permission);
                    
                    if(hasPermission){
                        filteredOptions.push(option);
                    }

                }else{
                    filteredOptions.push(option);
                }
            }
            setGenericOptions(filteredOptions);
        }else{
            setGenericOptions(Options?.[genericOption]);
        }
    }, [needPermissions]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            {title !== '' && (<FormLabel htmlFor={fieldName}>{title}</FormLabel>)}
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                name={fieldName}
                placeholder="Selecione..."
                options={genericOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable={isClearable}
            ></Select>
        </Col>)
}