import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { insertLendingEquipment } from '../../../../../services/Employee';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function CreateLendingEquipment({
    isOpen = false,
    setIsOpen = null,
    idTecnico = '',
    setUpdateParent = null
}) {
    const initialLendingEquipmentState = {
        item_comodato: '',
        serial_item_comodato: '',
        obs_item_comodato: '',
        id_tecnico: '',
        numero_patrimonio: ''
    }

    const [formLendingEquipment, setFormLendingEquipment] = useState(initialLendingEquipmentState);

    async function createLendingEquipment(e) {
        e.preventDefault();

        const toastData = toast.loading("Adicionando equipamento, aguarde...");

        let formData = {
            ...formLendingEquipment,
            id_tecnico: idTecnico
        }

        let dataReturn = await insertLendingEquipment(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Equipamento adicionado com sucesso!', 'update', toastData);
            setUpdateParent();
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar o equipamento!', dataReturn?.data, 'update', toastData);
        }

        setFormLendingEquipment(initialLendingEquipmentState);
    }


    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Itens de Comodato'
            maximizable
        >
            <form onSubmit={createLendingEquipment}>
                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="item_comodato">Item / Comodato</label>
                        <input type="text" name="item_comodato" id="item_comodato" className="form-control" value={formLendingEquipment?.item_comodato} onChange={(e) => setFormLendingEquipment(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="numero_patrimonio">Número Patrimônio</label>
                        <input type="text" name="numero_patrimonio" id="numero_patrimonio" className="form-control" value={formLendingEquipment?.numero_patrimonio} onChange={(e) => setFormLendingEquipment(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="serial_item_comodato">Serial</label>
                        <input type="text" name="serial_item_comodato" id="serial_item_comodato" className="form-control" value={formLendingEquipment?.serial_item_comodato} onChange={(e) => setFormLendingEquipment(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="obs_item_comodato">Observação</label>
                        <input type="text" name="obs_item_comodato" id="obs_item_comodato" className="form-control" value={formLendingEquipment?.obs_item_comodato} onChange={(e) => setFormLendingEquipment(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' />
                    </div>
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Salvar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}