import React, { useRef, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import ContratosSelect from '../../../common/Selects/ContratosSelect';
import Card from '../../../common/Layout/Card';
import modelo from '../../../resources/modelos_xls/Modelo_Acionamento.xls';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { createTicketsAcionamento } from '../../../services/MamSolutions';
import withReactContent from 'sweetalert2-react-content';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreateAcionamento() {
    const initialState = {
        contrato: '',
        arquivo: ''
    }
    const [formData, setFormData] = useState(initialState);
    const arquivoRef = useRef('');
    const mySwal = withReactContent(Swal);

    async function submitForm(e) {
        e.preventDefault();

        const toastUpdate = toast.loading('Executando upload, aguarde...');

        let params = new FormData();

        params.append('id_contrato', formData?.contrato?.value);
        params.append('file', formData?.arquivo);

        let dataReturn = await createTicketsAcionamento(params);

        if (dataReturn?.data?.response === 'success') {

            if (dataReturn?.data?.data?.errors.length > 0) {
                handleErrorsToast('', dataReturn?.data, 'update', toastUpdate);
                mySwal.fire({
                    title: dataReturn?.data?.data?.message,
                    html: (
                        <ul>
                            {dataReturn?.data?.data?.errors.map((item, idx) => {
                                return <li key={idx}>Linha: {item.Linha} | Erro: {item.Erro}</li>
                            })}
                        </ul>
                    )
                });
            } else {
                handleSuccessToast('Upload executado com sucesso!', 'update', toastUpdate);
            }

            setFormData(initialState);
            arquivoRef.current.value = '';
        } else {
            handleErrorsToast('Ocorreu um erro ao executar o upload', dataReturn?.data, 'update', toastUpdate);
        }
    }

    return (
        <Content headerTitle={'Incluir Acionamento'}>
            <SubHeaderSpecific subHeaderConfig='acionamento'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <ContratosSelect formData={formData} setFormData={setFormData} dependsOnIntegradora={true} required={true} id_integradora_string={[1].toString()}></ContratosSelect>
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <label htmlFor="">Anexar Acionamento - XLS</label>
                            <input type="file" name="arquivo" id="arquivo" ref={arquivoRef} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} className="form-control" accept='.xls, .xlsx' required />
                            <span style={{ fontSize: '0.8rem' }}>Obs: Para inclusão correta, por favor, verifique a existência de caracteres especiais (desconhecidos) ou aspas simples no arquivo a ser anexado.</span>
                        </div>
                        <div className="col-12">
                            <span style={{ fontSize: '0.8rem' }}>Download: </span><a href={modelo} className='m-1' target="_blank" download={true} rel='noreferrer'>Arquivo Modelo</a>
                            <span style={{ fontSize: '0.8rem' }} className='d-block'>Obs: O CEP deve ser preenchido para correta validação do endereço de atividade.</span>
                            <span style={{ fontSize: '0.8rem' }} className='d-block'>Obs2: A data e hora da atividade deve ser preenchida conforme arquivo modelo (Ex: Coluna AC do arquivo modelo)</span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <button type="submit" className='btn btn-primary'><i className='fas fa-upload'></i> Enviar</button>
                        </div>
                    </div>
                </form>
            </Card>
        </Content>
    );
}