import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, FormLabel } from 'react-bootstrap';
import { getAllTags } from '../../services/Tags';

export default function TagsSelect({ formData, setFormData, fieldName = 'tags', title='Tags', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, advancedSetFormData = null }) {
    const [tagsOptions, setTagsOptions] = useState([]);

    async function getTagsOptions() {
        let dataReturn = await getAllTags();

        if (dataReturn?.status === 200) {
            let tags = dataReturn.data.map((item) => {
                return { value: item.id, label:  item?.nome_upper || ''}
            });
            setTagsOptions(tags);
        } else {
            setTagsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        getTagsOptions();
    }, []);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={tagsOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}