import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import FieldOptionsForm from "./Components/FieldOptionsForm";
import { addOption } from "../../../services/Options";

export default function CreateFieldOption({ isOpen = false, setIsOpen = null, updateParent = null }) {

    const INITIAL_STATE = {
        id_modulo: '',
        id_campo: '',
        valor_opcao: '',
        chave_opcao: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        const toastData = toast.loading("Cadastrando campo, aguarde...");

        let dataReturn = await addOption(formData);

        if(dataReturn?.status === 200){
            handleSuccessToast('Campo adicionado com sucesso!', 'update', toastData);
            updateParent();
            setFormData(INITIAL_STATE);
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }
    
    useEffect(()=>{
        setFormData(INITIAL_STATE);
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Campo'
            maximizable
        >
           <FieldOptionsForm type="create" formData={formData} setFormData={setFormData} submitForm={submitForm}></FieldOptionsForm>
        </Dialog>
    );
}