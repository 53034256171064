import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import SignDetails from './SignDetails';
import AwaitingWithdrawDetails from './AwaitingWithdrawDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import If from '../../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, openNewTab } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import ShipmentFilters from '../Components/ShipmentFilters';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';

export default function AwaitingWithdraw() {

    const [data, setData] = useState([]);
    const [awaitingWithdrawId, setAwaitingWithdrawId] = useState('');
    const [openSignDetails, setOpenSignDetails] = useState(false);
    const [openAwaitingWithdrawDetails, setOpenAwaitingWithdrawDetails] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [search, setSearch] = useState('');

    const { checkAllPermissions, checkPermission } = usePermissions();
    const { action: actionPermissions } = getSubComponentsPermissions('mam_logistica', 'AwaitingWithdraw', 'AwaitingWithdraw');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openAwaitingWithdrawDetailsModal(row.id_produto)}>Ver</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openSignDetailsModal(row.id_produto)}>Assinar</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2])}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Remessa</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</Button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        },
        {
            id: 'data_solicitacao_produto',
            name: 'Data de Solicitação do Equipamento'
        }
    ];

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando remessa, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO CANCELAMENTO' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            getAwaitingWithdraw();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function getAwaitingWithdraw(page = 1, perPage = 100, search = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO RETIRADA EM MÃOS', page, perPage, search);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || '',
                data_solicitacao_produto: item?.ticket?.data_solicitacao || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    function openSignDetailsModal(id) {
        setOpenSignDetails(true);
        setAwaitingWithdrawId(id);
    }

    function openAwaitingWithdrawDetailsModal(id) {
        setOpenAwaitingWithdrawDetails(true);
        setAwaitingWithdrawId(id);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getAwaitingWithdraw(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getAwaitingWithdraw(1, rows, search);
    }

    function handleFilters(search = '') {
        setPerPage(100);
        setPage(0);
        setSearch(search);
        getAwaitingWithdraw(1, 100, search);
    }

    useEffect(() => {
        getAwaitingWithdraw();
    }, []);

    return (<Content headerTitle='Aguardando Retirada'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <SignDetails isOpen={openSignDetails} setModalOpen={() => setOpenSignDetails(!openSignDetails)} awaitingWithdrawId={awaitingWithdrawId} setUpdateProducts={() => getAwaitingWithdraw(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></SignDetails>
        <AwaitingWithdrawDetails isOpen={openAwaitingWithdrawDetails} setModalOpen={() => setOpenAwaitingWithdrawDetails(!openAwaitingWithdrawDetails)} awaitingWithdrawId={awaitingWithdrawId}></AwaitingWithdrawDetails>
        <Card title='Cadastro de Produtos - Aguardando Retirada'>
            <ShipmentFilters handleFilters={handleFilters} setSearch={setSearch}></ShipmentFilters>
            <Row>
                <Col sm='12'>
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='awaiting_withdraw'
                        reorderableColumns
                    ></PrimeTable>
                </Col>
            </Row>
        </Card>
    </Content >);
}