import { useState } from "react";
import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import ModuleOptionsSelect from "../../../../../common/Selects/ModuleOptionsSelect";
import ContratosSelect from "../../../../../common/Selects/ContratosSelect";
import FabricanteSelect from "../../../../../common/Selects/FabricanteSelect";
import ModelosSelect from "../../../../../common/Selects/ModelosSelect";
import RepresentanteSelect from "../../../../../common/Selects/RepresentanteSelect";
import TecnicosSelect from "../../../../../common/Selects/TecnicosSelect";
import AsyncChamadosSelect from "../../../../../common/Selects/AsyncChamadosSelect";

export default function ProductFilters({ handleFilters = null, setSearch = null }) {
    const INITIAL_STATE = {
        serial: '',
        id_chamado: '',
        id_localidade: '',
        id_status_produto: '',
        integradora: '',
        id_contrato: '',
        nfe_entrada: '',
        nfe_devolucao: '',
        id_fabricante: '',
        id_modelo: '',
        id_tecnico: '',
        id_representante: '',
        codigo_sap: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    function submitForm(e) {
        e.preventDefault();

        let params = {
            ...formData,
            id_chamado: formData?.id_chamado?.value || '',
            id_status_produto: formData?.id_status_produto?.value || '',
            id_contrato: formData?.id_contrato?.value || '',
            id_fabricante: formData?.id_fabricante?.value || '',
            id_modelo: formData?.id_modelo?.value || '',
            id_representante: formData?.id_representante?.value || '',
            id_tecnico: formData?.id_tecnico?.value || ''
        }

        handleFilters(params);
    }

    function handleRepresentante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_tecnico: '' }))
    }

    function clearFields() {
        setFormData(INITIAL_STATE);
        setSearch(INITIAL_STATE);
    }

    return (
        <Row>
            <Col className="col-12">
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="serial">Serial</FormLabel>
                            <FormControl type="text" name="serial" id="serial" placeholder='...' value={formData?.serial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                        </Col>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="id_localidade">ID Localidade</FormLabel>
                            <FormControl type="text" name="id_localidade" id="id_localidade" placeholder='...' value={formData?.id_localidade || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                        </Col>
                        <AsyncChamadosSelect cols={[12, 3, 3, 3]} formData={formData} setFormData={setFormData} fieldName="id_chamado" title="Número do Chamado"></AsyncChamadosSelect>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor='codigo_sap'>Código SAP</FormLabel>
                            <FormControl type='text' name='codigo_sap' id='codigo_sap' placeholder='...' value={formData?.codigo_sap || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                        </Col>
                        <ModuleOptionsSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'}></ModuleOptionsSelect>
                        <ContratosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} dependsOnIntegradora={false} fieldName='id_contrato' selectAllContracts={false}></ContratosSelect>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="nfe_entrada">NFe Entrada</FormLabel>
                            <FormControl type="text" name="nfe_entrada" id="nfe_entrada" placeholder='...' value={formData?.nfe_entrada || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                        </Col>
                        <Col sm='12' md='3'>
                            <FormLabel htmlFor="nfe_devolucao">NFe Devolução</FormLabel>
                            <FormControl type="text" name="nfe_devolucao" id="nfe_devolucao" placeholder='...' value={formData?.nfe_devolucao || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                        </Col>
                        <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_fabricante' title='Fabricante'></FabricanteSelect>
                        <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value}></ModelosSelect>
                        <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_representante' advancedSetFormData={handleRepresentante}></RepresentanteSelect>
                        <TecnicosSelect formData={formData} setFormData={setFormData} fieldName='id_tecnico' cols={[12, 3, 3, 3]} dependsOnRepresentante={true} id_representante={formData?.id_representante?.value || ''}></TecnicosSelect>
                        <Col sm='12'>
                            <Button type="button" variant='warning' className="m-1" onClick={clearFields}>Limpar Campos</Button>
                            <Button type='submit' className='m-1'>Pesquisar</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}