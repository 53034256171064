import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteLendingEquipmentById, getLendingEquipmentsByEmployeeId } from '../../../../../services/Employee';
import CreateLendingEquipment from './CreateLendingEquipment';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import If from '../../../../../common/Layout/If';
import usePermissions from '../../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function LendingEquipmentTable({ updateData = false, id = '', type = 'technician' }) {

    const [lendingEquipmentData, setLendingEquipmentData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'TechnicianDetails', 'LendingEquipmentTable');
    const { action: actionPermissionsEmployee, page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'EmployeeDetails', 'LendingEquipmentTable');

    const itensComodatoColumns = [
        {
            id: 'item_comodato_comodato',
            name: 'Item/Comodato',
        },
        {
            id: 'serial_comodato',
            name: 'Serial'
        },
        {
            id: 'numero_patrimonio',
            name: 'Número Patrimônio'
        },
        {
            id: 'observacao_comodato',
            name: 'Observação',
        },
        {
            id: 'action_comodato',
            name: 'Ação',
            body: (row) => (
                <If condition={checkPermission(type === 'technician' ? actionPermissions[0] : actionPermissionsEmployee[0])}>
                    <div className='align-items-center'>
                        <button type="button" className='btn btn-warning' onClick={() => showDeleteLendingEquipmentSwal(row.id_comodato)} disabled={updateData}><i className='fas fa-trash'></i></button>
                    </div>
                </If>
            ),
            permission: checkAllPermissions(type === 'technician' ? actionPermissions : actionPermissionsEmployee)
        }
    ];

    async function showDeleteLendingEquipmentSwal(id) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente remover este equipamento?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            deleteLendingEquipment(id);
        }
    }

    async function deleteLendingEquipment(id) {
        const toastData = toast.loading("Removendo equipamento, aguarde...");

        let dataReturn = await deleteLendingEquipmentById(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Equipamentos removidos!', 'update', toastData);
            getLendingEquipments();
        } else {
            handleErrorsToast('Ocorreu um erro ao remover os equipamentos!', dataReturn?.data, 'update', toastData);
        }
    }

    async function getLendingEquipments() {
        const toastData = toast.loading("Carregando equipamentos, aguarde...");

        let dataReturn = await getLendingEquipmentsByEmployeeId(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Equipamentos encontrados!', 'update', toastData);
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    id_comodato: item?.id || '',
                    item_comodato_comodato: item?.item_comodato || '',
                    serial_comodato: item?.serial_item_comodato || '',
                    observacao_comodato: item?.obs_item_comodato || '',
                    numero_patrimonio: item?.numero_patrimonio || ''
                })
            });

            setLendingEquipmentData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os equipamentos!', dataReturn?.data, 'update', toastData);
        }
    }

    useEffect(() => {
        getLendingEquipments();
    }, [id]);

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Itens e Comodatos</h5>
                <If condition={checkPermission(type === 'technician' ? pagePermissions[0] : pagePermissionsEmployee[0])}>
                    <button type="button" className="btn btn-primary" onClick={() => setIsOpen(!isOpen)} disabled={updateData}>Cadastrar</button>
                </If>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={itensComodatoColumns}
                    rows={lendingEquipmentData}
                    id='itens_comodato'
                ></PrimeTable>
            </div>
            <CreateLendingEquipment idTecnico={id} isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} setUpdateParent={getLendingEquipments}></CreateLendingEquipment>
        </div>);
}