import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getScrapProducts } from '../../services/Product';
import { Col, FormLabel } from 'react-bootstrap';

export default function ProdutosScrapSelect({ formData, setFormData, fieldName = 'produtos', title='Produtos', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, reRender = false }) {
    const [productsOptions, setProductsOptions] = useState([]);

    async function getAllAvailableProducts() {
        let dataReturn = await getScrapProducts();

        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length > 0) {
            let products = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: `${item?.partnumber?.manufacturer?.nome_fabricante || ''} - ${item?.partnumber?.modelo || ''} - ${item?.nome_produto || ''} - ${item?.serial || ''}` }
            });
            setProductsOptions(products);
        } else {
            setProductsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getAllAvailableProducts();
    }, [reRender]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}(Fabricante - Nome - Tipo - Serial)</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={productsOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}