import React, { useEffect, useState } from 'react';
import { createProduct } from '../../../../services/Product';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import Form from '../Components/Form';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function CreateProduct({ isOpen = false, setModalOpen = null, setUpdateProducts = null, setUpdateLogistic = null }) {
    const initialState = {
        nome_produto: '',
        serial: '',
        id_modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        mac: '',
        imei: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        patrimonio: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: '',
        id_localidade: '',
        numero_rma: '',
        proveniente_rma: 'nao',
        id_produto_rma: '',
        numero_reversa: '',
        serial_defeituoso: '',
        nf_retorno: '',
        rastreamento_retorno: '',
        data_reversa: '',
        data_postagem_retorno: '',
        data_retorno: '',
        data_expiracao_reversa: '',
        id_usuario_gestor: '',
        data_informada_gestao: '',
        descricao_interna: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);

    async function insertProduct(e) {
        e.preventDefault();

        let formData = {
            ...formCadastroProduto,
            id_modelo: formCadastroProduto?.id_modelo?.value || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            valorado: formCadastroProduto.valorado?.value || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || '',
            id_produto_rma: formCadastroProduto?.id_produto_rma?.value || '',
            id_usuario_gestor: formCadastroProduto?.id_usuario_gestor?.value || '',
            serial: formCadastroProduto?.serial?.replace(/^0+/, ""),
            serial_defeituoso: formCadastroProduto?.serial_defeituoso?.replace(/^0+/, ""),
        }

        const toastCreateProduct = toast.loading("Cadastrando produto, aguarde...");

        let dataReturn = await createProduct(formData);

        if (dataReturn?.data?.data?.message === 'Produto criado com sucesso!') {
            handleSuccessToast('Produto cadastrado com sucesso!', 'update', toastCreateProduct);
            setFormCadastroProduto(initialState);
            setUpdateProducts();
            setUpdateLogistic(true);
        } else if (dataReturn?.data?.message === 'Já existe um produto com esse número de série.') {
            handleErrorsToast('Este serial já existe!', dataReturn?.data, 'update', toastCreateProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao criar o produto!', dataReturn?.data, 'update', toastCreateProduct);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormCadastroProduto(initialState);
        }
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header='Adicionar Produto'
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <Form formData={formCadastroProduto} setFormData={setFormCadastroProduto} mode='create' submitForm={insertProduct}></Form>
        </Dialog>);
}