import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { changePartnerStatusById, getPartnersByStatus } from '../../../services/Partners';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../hooks/usePermissions';
import If from '../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function ShowPartners() {

    const { status } = useParams();
    const [partnersData, setPartnersData] = useState([]);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'ShowPartners', 'ShowPartners');
    const columns = [
        {
            id: 'action_representante',
            name: 'Ver',
            body: (row) => (
                <Link to={'/cadastros/representantes/detalhes/' + row.id_representante}><i className='fas fa-eye'></i></Link>
            ),
        },
        {
            id: 'nome_representante',
            name: 'Nome',
        },
        {
            id: 'orgao_representante',
            name: 'Órgão/Inscrição Municipal',
        },
        {
            id: 'inscricao_estadual_representante',
            name: 'I.E',
        },
        {
            id: 'cnpj_representante',
            name: 'CNPJ',
        },
        {
            id: 'endereco_representante',
            name: 'Endereço',
        },
        {
            id: 'contato_representante',
            name: 'Contato',
        },
        {
            id: 'status_representante',
            name: 'Status',
        },
        {
            id: 'action_activate_deactivate_representante',
            name: 'Ação',
            body: (row) => (
                <If condition={checkPermission(actionPermissions[0])}>
                    <div className='align-items-center'>
                        <button type="button" className='btn btn-primary' onClick={() => { showChangePartnerStatusSwal(row.id_representante, row.status_representante) }}>{row.status_representante === 'Ativo' ? 'Inativar' : 'Ativar'}</button>
                    </div>
                </If>),
            permission: checkAllPermissions(actionPermissions)
        },
    ];

    async function getPartners(status = 1) {
        const toastPartnersData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getPartnersByStatus(status);
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastPartnersData);
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    id_representante: item?.id || '',
                    nome_representante: item?.nome_representante || '',
                    orgao_representante: item?.orgao_representante || '',
                    inscricao_estadual_representante: item?.ie_representante || '',
                    cnpj_representante: item?.cnpj_representante || '',
                    endereco_representante: `${item?.logradouro_end || ''}, ${item?.bairro_end || ''}, ${item?.cidade_end || ''} - ${item?.estado_end || ''}`,
                    contato_representante: `${item?.nome_responsavel || ''} ${item?.email_responsavel || ''} ${item?.telefone_fixo_responsavel || ''} ${item?.celular_responsavel || ''}`,
                    status_representante: item?.ativo === 1 ? 'Ativo' : 'Inativo',
                    representante_confirmado: item?.representante_confirmado
                })
            })
            setPartnersData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastPartnersData);
            setPartnersData([]);
        }
    }

    async function showChangePartnerStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status deste representante?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changePartnerByStatus(id, status);
        }
    }

    async function changePartnerByStatus(id, status) {

        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await changePartnerStatusById(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Status alterado com sucesso!', 'update', toastChangeStatus);
            let statusData = status === 'Ativo' ? 1 : 0;
            getPartners(statusData);
        } else {
            handleErrorsToast('Ocorreu um erro ao alterar os dados!', dataReturn?.data, 'update', toastChangeStatus);
        }
    }

    useEffect(() => {
        let statusData = 1;

        if (status === 'inativo') {
            statusData = 0
        }

        getPartners(statusData);
    }, [status])

    return (
        <Content headerTitle="Representantes">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className='col-12'>
                        <If condition={checkPermission(pagePermissions[0])}>
                            <Link to="/cadastros/representantes/cadastrar" role='button' className='btn btn-primary m-1'>Cadastrar Representante</Link>
                        </If>
                        <If condition={checkPermission(pagePermissions[1])}>
                            <Link to="/cadastros/representantes/dados" role='button' className='btn btn-success m-1'>Representantes Ativos</Link>
                            <Link to="/cadastros/representantes/dados/inativo" role='button' className='btn btn-warning m-1'>Representantes Inativos</Link>
                        </If>
                    </div>
                </div>
                <PrimeTable
                    columns={columns}
                    rows={partnersData}
                    needExport={checkPermission(pagePermissions[2])}
                    id='show_partners'
                ></PrimeTable>
            </Card>
        </Content>
    )
}