import React, { useEffect, useState } from 'react';
import Modal from '../Layout/Modal';
import { getUserDataById } from '../../services/Users';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast, onImageError } from '../../configs/GenericFunctions';
import { Card, CardBody, CardHeader } from 'react-bootstrap';


export default function ContactDetails({ isOpen, setModalOpen, contactId = '' }) {
    const initialState = {
        id: '',
        name: '',
        img: '',
        email: '',
        tel: ''
    }

    const [contactData, setContactData] = useState(initialState);

    async function getContactData(){
        const toastContact = toast.loading("Carregando dados, aguarde...");
        let contact = await getUserDataById(contactId);
        if(contact?.data?.response === 'success'){
            handleSuccessToast('Dados encontrados!', 'update', toastContact);
            setContactData(contact?.data?.data);
        }else{
            handleErrorsToast('Ocorreu um erro ao procurar os dados!', contact?.data, 'update', toastContact);
        }
    }    
    
    useEffect(() => {
        if(contactId !== ''){
            getContactData();
        }
    }, [contactId]);

    return (
        <Modal
            title={'Detalhes do Contato'}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <div style={{ minWidth: '50vw' }}>
                <Card style={{ backgroundColor: '#F4F6F9' }}>
                    <CardHeader className="flex justify-content-center">
                        <img src={contactData?.url_foto_perfil || ''} className="border-circle mr-1 cursor-pointer w-9rem h-9rem" alt="Foto Contato" onError={onImageError} />
                    </CardHeader>
                    <CardBody className="text-center">
                        <h6 className='inline'>{contactData?.nome}</h6>
                    </CardBody>
                </Card>
                <Card style={{ backgroundColor: '#F4F6F9' }}>
                    <CardHeader className="text-center">
                        <h6 className='inline'>Detalhes</h6>
                    </CardHeader>
                    <CardBody>
                        <p><b>Email: </b>{contactData?.email || 'Não Cadastrado'}</p>
                        <p><b>Telefone: </b>{contactData?.telefone || 'Não Cadastrado'}</p>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    )
}