import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import If from '../../../../common/Layout/If';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { InputText } from 'primereact/inputtext';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { createBulkShipment } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';

export default function BulkSelectSerial({ isOpen = false, setModalOpen = null, setUpdateProducts = null, setUpdateLogistic = null }) {
    const INITIAL_STATE = {
        urgencia_remessa: '',
        tipo_remessa: '',
        id_tecnico: '',
        modal_envio: '',
        responsavel_separacao: '',
        local: '',
        box: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [productsField, setProductsField] = useState([{ id: 1, fabricante: "", nome_produto: "", produto: "" }]);

    async function submitForm(e) {
        e.preventDefault();

        let ids_produto = [];
        for (let f in formData) {
            if (f.includes('id_produto_')) {
                ids_produto.push(formData[f]?.value);
            }
        }

        let params = {
            ids_produto: ids_produto,
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            chave_tipo_remessa: formData?.tipo_remessa?.value || '',
            id_tecnico: formData?.tipo_remessa?.value === 'envio_tecnico' ? formData?.id_tecnico?.value : '',
            modal_envio: formData?.modal_envio?.value || '',
            responsavel_separacao: formData?.responsavel_separacao?.label || '',
            local: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.local?.value : '',
            box: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.box?.value : ''
        }

        const toastData = toast.loading('Cadastrando remessa, aguarde...');

        let dataReturn = await createBulkShipment(params);

        if(dataReturn?.status === 201){
            handleSuccessToast('Remessas criadas com sucesso!', 'update', toastData);
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
        }else{
            handleErrorsToast("Ocorreu um erro", dataReturn?.data, 'update', toastData);
        }
    }

    function addProduct() {

        let productKey = productsField[productsField.length - 1]?.id + 1;

        setProductsField(prev => ([...prev, { id: productKey, fabricante: "", nome_produto: "", produto: "" }]));
    }

    function removeProduct(id) {

        let productsLength = productsField.length;

        if (productsLength === 1) {
            handleErrorsToast('Você deve enviar pelo menos um produto');
            return;
        }

        setProductsField(prev => prev.filter(item => item.id !== id));
        let fields = formData;

        delete fields?.[`fabricante_${id}`];
        delete fields?.[`nome_produto_${id}`];
        delete fields?.[`id_produto_${id}`];

        setFormData(fields);
    }

    function handleFabricante(value, name) {
        let id = name.split("_")[1];
        setFormData(prev => ({ ...prev, [name]: value, [`nome_produto_${id}`]: '', [`id_produto_${id}`]: '' }));
    }

    function handleNomeProduto(value, name) {
        let id = name.split("_")[2];
        setFormData(prev => ({ ...prev, [name]: value, [`id_produto_${id}`]: '' }));
    }

    function handleIdProduto(value, name){
        let ids_produto = [];

        for (let f in formData) {
            if (f.includes('id_produto_')) {
                ids_produto.push(formData[f]);
            }
        }

        ids_produto = ids_produto.filter((item) => {
            return item?.value === value?.value
        });

        if(ids_produto.length > 0){
            handleErrorsToast('Este produto já foi selecionado!');
            return;
        }

        setFormData(prev => ({ ...prev, [name]: value}));
    }

    useEffect(()=>{
        if(isOpen){
            setFormData(INITIAL_STATE);
            setProductsField([{ id: 1, fabricante: "", nome_produto: "", produto: "" }]);
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            maximizable
            resizable={false}
            header='Selecionar Seriais e Criar Remessa em Lote'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <GenericSelect fieldName={'urgencia_remessa'} formData={formData} setFormData={setFormData} title={'Urgência da Remessa'} cols={[12, 6, 4, 4]} genericOption='UrgenciaRemessaOptions' required={true}></GenericSelect>
                    <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'tipo_remessa'} fieldName={'tipo_remessa'} module={'produtos'} title={'Tipo Remessa'} cols={[12, 6, 3, 3]} responseValueField='chave_opcao'></ModuleOptionsSelect>
                    <If condition={formData?.tipo_remessa?.value === 'movimentacao_interna'}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 6, 2, 2]} genericOption='UfOptions' required={true}></GenericSelect>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 6, 2, 2]} required={true} uf={formData?.local?.value}></BoxSelect>
                    </If>
                    <If condition={formData?.tipo_remessa?.value === 'envio_tecnico'}>
                        <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='representante' required={true}></RepresentanteSelect>
                        <TecnicosSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='id_tecnico' id_representante={formData?.representante?.value || ''} required={true}></TecnicosSelect>
                    </If>
                    <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 6, 3, 3]} fieldName='modal_envio' required={true}></ModalEnvioSelect>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='responsavel_separacao' cols={[12, 6, 3, 3]} userType='all' required title='Responsável pela Separação' userGroup='logistica_operacao'></UsuariosAtivosSelect>
                    <div className="col-12">
                        <div className="row">
                            <h6>Produtos</h6>
                        </div>
                        {
                            productsField.map((item) => (
                                <div className="row" key={`product_row_${item.id}`}>
                                    <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName={`fabricante_${item.id}`} advancedSetFormData={handleFabricante} required></FabricanteSelect>
                                    <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName={`nome_produto_${item.id}`} id_fabricante={formData?.[`fabricante_${item.id}`]?.value || ''} advancedSetFormData={handleNomeProduto} required></ModelosSelect>
                                    <AsyncProdutosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName={`id_produto_${item.id}`} id_modelo={formData?.[`nome_produto_${item.id}`]?.value || ''} required advancedSetFormData={handleIdProduto}></AsyncProdutosSelect>
                                    <div className="col-sm-12 col-md-1 d-flex align-content-end mt-5">
                                        <button type="button" className='btn btn-danger' onClick={() => removeProduct(item.id)}>-</button>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="row">
                            <div className="col-12">
                                <button className='btn btn-primary' type='button' onClick={addProduct}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar Remessa</button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}