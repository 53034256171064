import React from 'react';
import Card from '../../../common/Layout/Card';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import GenericSelect from '../../../common/Selects/GenericSelect';
import If from '../../../common/Layout/If';
import usePermissions from '../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import TagsSelect from '../../../common/Selects/TagsSelect';
import { attachTag, detachTag } from '../../../services/Tags';
import { Badge } from 'primereact/badge';

export default function StatusCard({
    originalData = {},
    detailsData = {},
    setDetailsData = null,
    showUpdateBtn = false }) {
    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'StatusCard');

    async function handleTags(value, name){
        
        setDetailsData(p => {
            let tags = [...p[name]];
            let tagsIds = tags?.map(item => item.value);
            let valueIds = value?.map(item => item.value);

            let filterValuesToInsert = value?.filter(item => !tagsIds?.includes(item.value));
            let filterTagsToDelete = tags?.filter(item => !valueIds?.includes(item.value));

            if(checkPermission(pagePermissions[4]) && filterValuesToInsert?.length > 0){
                for(let i = 0; i < filterValuesToInsert?.length; i++){
                    attachTag(filterValuesToInsert[i]?.value, {
                        tickets: [detailsData?.id_chamado]
                    });
                }
                tags = value;
            }

            if(checkPermission(pagePermissions[5]) && filterTagsToDelete?.length > 0){
                for(let i = 0; i < filterTagsToDelete?.length; i++){
                    detachTag(filterTagsToDelete[i]?.value, detailsData?.id_chamado);
                }
                tags = value;
            }

            return {...p, [name]: tags}
        });
    }


    return (
        <Card title={`Status`} icon={'fa-dot-circle'}>
            <p><b>Tags: </b>{!showUpdateBtn && (
                originalData?.ticket_tags?.map((item) => (<Badge key={`${item?.id_tag}_tag`} style={{backgroundColor: `#${item?.cor_hexadecimal}`}} value={item?.nome || ''}></Badge>))
            )}</p>
            <If condition={showUpdateBtn && checkPermission(pagePermissions[3])}>
                <TagsSelect
                    formData={detailsData}
                    setFormData={setDetailsData}
                    fieldName='ticket_tags'
                    isMulti
                    advancedSetFormData={handleTags}
                ></TagsSelect>
            </If>
            <p><b>Status: </b>{!showUpdateBtn && (<span style={{ color: 'green', fontWeight: 'bold' }}>{originalData?.status_chamado}</span>)}</p>
            <If condition={showUpdateBtn && checkPermission(pagePermissions[0])}>
                <StatusChamadoSelect
                    formData={detailsData}
                    setFormData={setDetailsData}
                    fieldName='status_chamado'
                    showTodos={false}
                ></StatusChamadoSelect>
            </If>
            <If condition={!showUpdateBtn}>
                <p><b>Status Atendimento Técnico: </b>{originalData?.status_tec_atendimento}</p>
            </If>
            <If condition={originalData?.status_chamado === "Fechado"}>
                <p><b>Ocasião: </b>{originalData?.ocasiao_fechamento}</p>
            </If>
            <If condition={(originalData?.status_chamado === "Fechado" && originalData?.ocasiao_fechamento === "Improdutivo")}>
                <p><b>Motivo Improdutivo: </b>{originalData?.motivo_improdutivo}</p>
            </If>
            <If condition={!showUpdateBtn}>
                <p><b>Urgência/Prioridade: </b>{originalData?.urgencia} - {originalData?.prioridade}</p>
            </If>
            <If condition={(showUpdateBtn)}>
                <div className='row'>
                    <If condition={checkPermission(pagePermissions[1])}>
                        <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'urgencia'} title={'Urgência'} genericOption='UrgenciaOptions' cols={[6, 6, 6, 6]} required={true}></GenericSelect>
                    </If>
                    <If condition={checkPermission(pagePermissions[2])}>
                        <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'prioridade'} title={'Prioridade'} genericOption='PrioridadeOptions' cols={[6, 6, 6, 6]} required={true}></GenericSelect>
                    </If>
                </div>
            </If>
            <p><b>Data e Hora do Fechamento: </b>{originalData?.data_encerramento || ''} {originalData?.hora_encerramento || ''}</p>
            <p><b>Documentação Entregue: </b>{originalData?.documentacao_entregue || ''}</p>
            <p><b>Pagamento: </b>{originalData?.liberar_pagamento || ''}</p>
            <p><b>Envolve Logística: </b>{originalData?.envolve_logistica || ''}</p>
            <p><b>Responsável Suporte: </b>{originalData?.support_responsible_user?.nome || ''}</p>
            <p><b>Responsável Chamado: </b>{originalData?.ticket_responsible_user?.nome || ''}</p>
            <p><b>Responsável Parcerias: </b>{originalData?.partnership_responsible_user?.nome || ''}</p>
            <p><b>Aberto por: </b>{originalData?.user?.nome || ''}</p>
        </Card>
    );
}