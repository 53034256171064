import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import { createDashboard } from "../../../services/DevMode";

export default function CreateMetabase({ isOpen = false, setIsOpen = null, updateParent = null }) {

    const INITIAL_STATE = {
        nome: '',
        slug: '',
        resource_type: '',
        resource_id: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        const toastData = toast.loading("Cadastrando Resource, aguarde...");

        let dataReturn = await createDashboard(formData);

        if(dataReturn?.status === 201){
            handleSuccessToast('Resource adicionado com sucesso!', 'update', toastData);
            updateParent();
            setFormData(INITIAL_STATE);
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }
    
    useEffect(()=>{
        setFormData(INITIAL_STATE);
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Resource'
            maximizable
        >
            <Form onSubmit={submitForm}>
                <Row>
                    <Col sm='12' md='6' className="mb-1">
                        <FormLabel htmlFor="nome">Nome</FormLabel>
                        <FormControl type="text" name="nome" id="nome" placeholder="Nome" value={formData?.nome || '' } onChange={(e) => setFormData(p => ({...p, [e.target.name]: e.target.value}))} required></FormControl>
                    </Col>
                    <Col sm='12' md='6' className="mb-1">
                        <FormLabel htmlFor="slug">Slug</FormLabel>
                        <FormControl type="text" name="slug" id="slug" placeholder="Slug" value={formData?.slug || '' } onChange={(e) => setFormData(p => ({...p, [e.target.name]: e.target.value}))} required></FormControl>
                    </Col>
                    <Col sm='12' md='6' className="mb-1">
                        <FormLabel htmlFor="resource_type">Resource Type</FormLabel>
                        <FormControl type="text" name="resource_type" id="resource_type" placeholder="Resource Type" value={formData?.resource_type || '' } onChange={(e) => setFormData(p => ({...p, [e.target.name]: e.target.value}))} required></FormControl>
                    </Col>
                    <Col sm='12' md='6' className="mb-1">
                        <FormLabel htmlFor="resource_id">Resource Id</FormLabel>
                        <FormControl type="text" name="resource_id" id="resource_id" placeholder="Resource Id" value={formData?.resource_id || '' } onChange={(e) => setFormData(p => ({...p, [e.target.name]: e.target.value}))} required></FormControl>
                    </Col>
                    <Col sm='12'>
                        <Button type="submit" variant="primary">Cadastrar</Button>
                    </Col>
                </Row>
            </Form>
        </Dialog>
    );
}