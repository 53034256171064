import React, { useEffect, useState } from 'react';
import { confirmReception, getShipmentById } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function MarkAsReceived({ isOpen = false, setModalOpen = null, setUpdateProducts = null, inTransitId = '', setUpdateLogistic = null }) {

    const INITIAL_STATE = {
        nome_produto: '',
        serial: '',
        modelo: '',
        data_recebimento: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(inTransitId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let shipmentData = dataReturn?.data?.data;

            setFormData(prevState => ({
                ...prevState,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
            }));

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    async function submitForm(e){
        e.preventDefault();

        let params = {
            ...formData
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await confirmReception(inTransitId, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true)
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastProducts);
        }
    }

    useEffect(()=>{
        if(isOpen && !['', undefined, null].includes(inTransitId)){
            setFormData(INITIAL_STATE);
            getShipment();
        }
    }, [isOpen, inTransitId]);

    return (<Dialog
        visible={isOpen}
        onHide={setModalOpen}
        header='Marcar como Recebido'
        maximized={true}
        draggable={false}
        resizable={false}
        >
        <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5>Confirmar entrega da remessa:</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Nome Produto</label>
                    <input type="text" name="modelo" id="modelo" className="form-control" placeholder='Nome Produto' value={formData?.modelo || ''} readOnly />
                </div>
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Serial</label>
                    <input type="text" name="serial" id="serial" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                </div>
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Tipo Produto</label>
                    <input type="text" name="nome_produto" id="nome_produto" className="form-control" placeholder='Tipo Produto' value={formData?.nome_produto || ''} readOnly />
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5>Informe a data e horário de entrega</h5>
                </div>
                <div className="col-12">
                    <input type="datetime-local" name="data_recebimento" id="data_recebimento" className='form-control' value={formData?.data_recebimento || ''} onChange={(e)=>setFormData((prevState)=> ({...prevState, [e.target.name]: e.target.value}))} required/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-1">
                    <button type="submit" className="btn btn-primary">Confirmar Recebimento</button>
                </div>
            </div>
        </form>
    </Dialog>)

}