import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { getSubModules } from '../../../services/DevMode';
import { toast } from 'react-toastify';
import If from '../../../common/Layout/If';
import usePermissions from '../../../hooks/usePermissions';
import CreateSubmodule from './CreateSubmodule';
import ModulosSelect from '../../../common/Selects/ModulosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function Submodules() {

    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [openCreateSubmodule, setOpenCreateSubmodule] = useState(false);
    const { checkPermission } = usePermissions();
    const columns = [
        {
            id: 'id',
            name: 'ID'
        },
        {
            id: 'nome_submodulo',
            name: 'Nome'
        },
        {
            id: 'descricao',
            name: 'Descrição'
        },
        {
            id: 'status',
            name: 'Status',
            body: (row) => row?.status === 1 ? 'Ativo' : 'Inativo'
        },
        {
            id: 'criado',
            name: 'Cadastrado em'
        }
    ]

    const getAllSubmodules = async (value, name) => {
        setSearchData(prev => ({ ...prev, [name]: value }));
        const toastModules = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getSubModules(value?.value);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastModules);

            let modules = dataReturn.data.data;
            setData(modules);

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastModules);
            setData([]);
        }
    }

    return (
        <Content headerTitle='Gerenciar Submódulos'>
            <CreateSubmodule isOpen={openCreateSubmodule} setIsOpen={() => setOpenCreateSubmodule(!openCreateSubmodule)}></CreateSubmodule>
            <SubHeaderSpecific subHeaderConfig='dev_mode'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <If condition={checkPermission('')}>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={() => setOpenCreateSubmodule(!openCreateSubmodule)}>Cadastrar Submódulo</button>
                        </div>
                    </If>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Filtro</h3>
                    </div>
                    <ModulosSelect formData={searchData} setFormData={setSearchData} fieldName='id_modulo' advancedSetFormData={getAllSubmodules}></ModulosSelect>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='submodules_table'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content >
    );
}