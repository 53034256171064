import React from "react";

function Footer() {
    return (
        <footer className="main-footer">
            <p className="text-center">&copy;<strong>Maminfo Soluções e Serviços em Tecnologia S.A.</strong> - Todos os direitos reservados - Versão 2.0</p>
            <p className="text-center">Desenvolvido por: Vitor Pedroso e Luiz Magalhães</p>
        </footer>
    );
}

export default Footer;