import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getModelsByManufacturers } from '../../services/ProductPartnumber';
import { Col, FormLabel } from 'react-bootstrap';

export default function ModelosSelect({ formData, setFormData, fieldName = 'modelo', title = 'Nome do Produto', cols = [12, 12, 12, 12], isDisabled = false, required = false, advancedSetFormData = null, id_fabricante = '', only_available = '', show_qty = false }) {
    const [modeloOptions, setModeloOptions] = useState([]);

    async function getModels(id) {
        let dataReturn = await getModelsByManufacturers(id, only_available);

        if (dataReturn?.data?.response === 'success') {
            let partnumbers = dataReturn.data.data?.partnumber?.map((item, idx) => {
                return show_qty ? { value: item.id, label: `${item.modelo} - ${item.available_quantity}` } : { value: item.id, label: item.modelo }
            });
            setModeloOptions(partnumbers);
        } else {
            setModeloOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData === null) {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        } else {
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id_fabricante)) {
            getModels(id_fabricante);
        }else{
            setModeloOptions([]);
        }
    }, [id_fabricante]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={modeloOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}