import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllUsers } from '../../services/Users';
import { getAllSupportUsers } from '../../services/SupportGroup';
import { getAllPartnerUsers } from '../../services/PartnerGroup';
import { Col, FormLabel } from 'react-bootstrap';

export default function UsuariosAtivosSelect({ formData, setFormData, fieldName = 'usuarios', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, title = 'Responsável Atendimento', userType='all', userGroup = '' }) {
    const [usuariosOptions, setUsuariosOptions] = useState([]);

    async function getActivatedUsers(){
        let dataReturn = await getAllUsers(1, userGroup);

        if(dataReturn?.data?.response === 'success'){
            let usuarios = dataReturn?.data?.data.map((item, idx)=>{
                return ({value: item.id, label: item.nome});
            });

            setUsuariosOptions(usuarios);
        }else{
            setUsuariosOptions([]);
        }
    }

    async function getSupportUsers(){
        let dataReturn = await getAllSupportUsers();

        if(dataReturn?.data?.response === 'success'){
            let supportUsers = dataReturn.data.data.map((item, idx) => {
                return { value: item.id_usuario, label: `${item.nome_grupo}` }
            });

            setUsuariosOptions(supportUsers);
        }else{
            setUsuariosOptions([]);
        }
    }

    async function getPartnerUsers(){
        let dataReturn = await getAllPartnerUsers();

        if(dataReturn?.data?.response === 'success'){
            let partnerUsers = dataReturn.data.data.map((item, idx)=>{
                return ({value: item.id_usuario, label: item.nome_grupo})
            });     

            setUsuariosOptions(partnerUsers);
        }else{
            setUsuariosOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        
        switch(userType){
            case 'all':
                getActivatedUsers();
                return;
            case 'support':
                getSupportUsers();
                return;
            case 'partners':
                getPartnerUsers();
                return;
            default:
                getActivatedUsers();
        }
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}    
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={usuariosOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable={true}
            ></Select>
        </Col>
    );
}