import React, { createContext, useContext, useEffect, useState } from 'react';
import MessagesChat from './MessagesChat';
import ChannelsChat from './ChannelsChat';
import useChat from '../../hooks/useChat';
import { toast } from 'react-toastify';
import { getChannelDataByChannelId } from '../../services/Chat';
import { Context } from '../../App';
import { useParams } from 'react-router-dom';
import { getExternalMessages } from '../../services/ExternalChat';
import If from '../Layout/If';
import { handleErrorsToast } from '../../configs/GenericFunctions';

const initialState = {
    channelId: '',
    ticketId: '',
    channelData: {},
    messages: [],
    members: [],
    externalMembers: [],
    updateChat: false,
    updateMode: 'full',
    page: 1,
    moreMessages: false
}

export const ChatContext = createContext(initialState);

export default function MainChatComponent({ channelIdValue = '', ticketId = '', setChannelId=null }) {
    const userData = useContext(Context);
    const { getChatTypeByRoute } = useChat();
    const { id } = useParams();
    const [chatState, setChatState] = useState({
        ...initialState,
        channelId: getChatTypeByRoute() === 'externo' ? id : channelIdValue,
        ticketId: ticketId,
    });
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 1024);
    const [showMessage, setShowMessage] = useState(true);
    const [showContacts, setShowContacts] = useState(true);
    const { getChannelName } = useChat();

    const MAIN_STYLE = {
        minHeight: '450px',
        height: ['app', 'externo'].includes(getChatTypeByRoute()) ? '100vh' : '80vh',
        width: '100%'
    }

    const CONTACT_STYLE = {
        marginRight: '10px',
        height: '100%',
        width: isTablet ? '30%' : '100%'
    }

    const MESSAGES_STYLE = {
        height: '100%',
        width: isTablet && getChatTypeByRoute() !== 'externo' ? '70%' : '100%'
    }

    function openChat() {

        setShowMessage(true);

        if (window.innerWidth >= 1024) {
            setShowContacts(true);
        } else {
            setShowContacts(false);
        }
    }

    function openContacts() {
        setShowContacts(true);

        if (window.innerWidth >= 1024) {
            setShowMessage(true);
        } else {
            setShowMessage(false);
            if(setChannelId !== null){
                setChannelId('');
            }
            setChatState(prevState => ({ ...prevState, channelId: '' }));
        }
    }

    async function getChannelData(mode = 'full') {
        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await getExternalMessages(chatState?.channelId, chatState?.limit);
        } else {
            dataReturn = await getChannelDataByChannelId(chatState?.channelId, chatState?.limit);
        }

        if (dataReturn?.data?.response === 'success') {
            let messageData = dataReturn?.data?.data;

            if (mode === 'full') {
                setChatState(prevState => ({
                    ...prevState,
                    channelData: {
                        ...messageData,
                        worked_channel_name: getChannelName(messageData),
                    },
                    messages: [...messageData.messages],
                    members: messageData?.users?.map((item, idx) => {
                        if (item.id === userData?.userDataState?.id_usuario) {
                            return { ...item, name: 'Eu' };
                        }
                        return item;
                    }),
                    externalMembers: messageData?.external_users,
                    updateMode: 'full',
                    updateChat: false,
                }));
            }

            if (mode === 'externalMembers') {
                setChatState(prevState => ({
                    ...prevState,
                    channelData: {
                        ...prevState.channelData,
                        external_users: messageData?.external_users
                    },
                    externalMembers: messageData?.external_users,
                    updateMode: 'full',
                    updateChat: false,
                }));
            }


        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os dados do canal!', dataReturn?.data);
        }
    }

    async function getMoreMessages() {
        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await getExternalMessages(chatState?.channelId, chatState?.page);
        } else {
            dataReturn = await getChannelDataByChannelId(chatState?.channelId, chatState?.page);
        }

        if (dataReturn?.data?.response === 'success') {
            let messageData = dataReturn?.data?.data;
            
            if(messageData?.messages?.length === 0){
                let page = chatState?.page - 1;
                setChatState(prevState => ({
                    ...prevState,
                    page: page === 0 ? 1 : page,
                    moreMessages: false
                }));
                return;
            }

            setChatState(prevState => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...messageData,
                },
                messages: [...messageData.messages, ...prevState.messages],
                moreMessages: false
            }));

        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os dados do canal!', dataReturn?.data);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (chatState?.updateChat) {
            getChannelData(chatState?.updateMode);
        }
    }, [chatState?.updateChat]);

    useEffect(()=>{
        let moreMessages = chatState?.moreMessages;

        if(moreMessages){
            getMoreMessages();
        }
    },[chatState?.moreMessages])

    useEffect(() => {

        if (!isTablet && chatState?.channelId === '') {
            setShowContacts(true);
            setShowMessage(false);
        } else if (!isTablet && chatState?.channelId !== '') {
            setShowContacts(false);
            setShowMessage(true);
        } else {
            setShowMessage(true);
            setShowContacts(true);
        }
    }, [isTablet, chatState?.channelId]);

    useEffect(() => {
        if (chatState?.channelId !== '') {
            getChannelData();
        } else {
            setChatState({
                ...initialState,
                channelId: channelIdValue,
                ticketId: ticketId
            });
        }
    }, [chatState?.channelId]);

    return (
        <ChatContext.Provider value={{ chatState, setChatState }}>
            <div className="flex flex-row" style={MAIN_STYLE}>
                <If condition={(showContacts && getChatTypeByRoute() !== 'externo')}>
                    <ChannelsChat style={CONTACT_STYLE} openChat={openChat}></ChannelsChat>
                </If>
                <If condition={showMessage}>
                    <MessagesChat style={MESSAGES_STYLE} openContacts={openContacts} isTablet={isTablet}></MessagesChat>
                </If>
            </div>
        </ChatContext.Provider>
    )
}