import interceptorInstance from "./Interceptor";

export async function getFilesBySubmodule(submodule, id){
    try{
        let data = await interceptorInstance.get(`ged/${submodule}/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getFilesBySubmoduleMultipleIds(submodule, params){
    try{
        let data = await interceptorInstance.get(`ged/${submodule}`, {params: {
            ...params
        }});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function addGed(productId, params){
    try{
        let data = await interceptorInstance.post(`ged/upload/${productId}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function bulkAddGed(params){
    try{
        let data = await interceptorInstance.post(`ged/bulk-upload`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteGed(productId){
    try{
        let data = await interceptorInstance.delete(`ged/delete/${productId}`);
        return data;
    }catch(e){
        return e.response;
    }
}