import interceptorInstance from "./Interceptor";

export async function getSummary(data_inicial, data_final, id_representante){
    try{
        let data = await interceptorInstance.get(`employee-expense/summary`, {
            params: {
                data_inicial: data_inicial,
                data_final: data_final,
                id_representante: id_representante
            }
        });
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createExpense(params){
    try{
        let data = await interceptorInstance.post(`employee-expense/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getExpenseByTechnicianId(id, data_inicial, data_final, status = ''){
    try{    
        let statusFinal = status !== '' ? `&status_reembolso=${status}` : '';
        let data = await interceptorInstance.get(`employee-expense/filter/employee/${id}?data_inicial=${data_inicial}&data_final=${data_final}${statusFinal}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getExpenseByExpenseId(id){
    try{
        let data = await interceptorInstance.get(`employee-expense/search/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateExpenseById(id, params){
    try{
        let data = await interceptorInstance.patch(`employee-expense/status/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPendingCount(){
    try{
        let data = await interceptorInstance.get(`employee-expense/count-pending`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getExpenseByTicketId(id, data_inicial, data_final, status = ''){
    try{    
        let statusFinal = status !== '' ? `&status_reembolso=${status}` : '';
        let data = await interceptorInstance.get(`employee-expense/filter/ticket/${id}?data_inicial=${data_inicial}&data_final=${data_final}${statusFinal}`,);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPendingExpenseCountByTicketId(id){
    try{
        let data = await interceptorInstance.get(`employee-expense/count-pending/ticket/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getExpensesByStatusTechnician(data_inicial, data_final, status, id_tecnico){
    try{
        let data = await interceptorInstance.get(`employee-expense/expenses/employee/${id_tecnico}`, {
            params: {
                data_inicial: data_inicial,
                data_final: data_final,
                status_reembolso: status,
            }
        });
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getExpensesByTechnicianIdTicketId({ticketId = '', technicianId = '', data_inicial = '', data_final = '', status = ''}){
    try{    
        let data = await interceptorInstance.get(`employee-expense/filter/expense/${ticketId}/${technicianId}?data_inicial=${data_inicial}&data_final=${data_final}&status_reembolso=${status}`,);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPartnersByDateRange(data_inicial, data_final){
    try{
        let data = await interceptorInstance.get(`employee-expense/partners-date-range`, {
            params: {
                data_inicial: data_inicial,
                data_final: data_final
            }
        });
        return data;
    }catch(e){
        return e.response;
    }
}