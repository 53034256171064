import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { insertLeave } from '../../../../../services/EmployeeLeave';
import GenericSelect from '../../../../../common/Selects/GenericSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function CreateLeaves({
    isOpen = false,
    setIsOpen = null,
    idTecnico = '',
    setUpdateParent = null
}) {
    const initialAfastamentoState = {
        tipo_afastamento: '',
        data_inicial: '',
        data_final: '',
        arquivo: ''
    }

    const [formAfastamento, setFormAfastamento] = useState(initialAfastamentoState);
    const arquivoRef = useRef();

    async function submitLeaves(e) {
        e.preventDefault();

        let formData = new FormData();

        formData.append('tipo_afastamento', formAfastamento?.tipo_afastamento?.value || '');
        formData.append('data_inicial', formAfastamento?.data_inicial);
        formData.append('data_final', formAfastamento?.data_final);
        formData.append('id_tecnico', idTecnico);
        formData.append('arquivo', formAfastamento?.arquivo);

        const toastCreateLeave = toast.loading('Cadastrando afastamento, aguarde...');

        let dataReturn = await insertLeave(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Afastamento cadastrado com sucesso!', 'update', toastCreateLeave);
            setUpdateParent();
            setFormAfastamento(initialAfastamentoState);
            arquivoRef.current.value = '';
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar o afastamento!', dataReturn?.data, 'update', toastCreateLeave);
        }
    }

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastro de Afastamento'
            maximizable
        >
            <form onSubmit={submitLeaves}>
                <div className="row">
                    <GenericSelect formData={formAfastamento} setFormData={setFormAfastamento} fieldName={'tipo_afastamento'} title={'Motivo Afastamento'} cols={[12, 12, 3, 3]} genericOption='TipoAfastamentoOptions' required={true}></GenericSelect>
                    <div className="col-md-12 col-lg-3">
                        <label htmlFor="data_inicial">Data de Início</label>
                        <input type="date" name="data_inicial" id="data_inicial" className="form-control" value={formAfastamento?.data_inicial} onChange={(e) => setFormAfastamento(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <label htmlFor="data_final">Data de Término</label>
                        <input type="date" name="data_final" id="data_final" className="form-control" value={formAfastamento?.data_final} onChange={(e) => setFormAfastamento(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <label htmlFor="arquivo">Anexo</label>
                        <input type="file" name="arquivo" id="arquivo" ref={arquivoRef} accept='.png, .pdf, .jpg, .jpeg' onChange={(e) => setFormAfastamento(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} className='form-control' />
                    </div>
                    <div className="col-md-12 mt-1">
                        <button type="submit" className="btn btn-primary ">Salvar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}