import { Col, FormLabel } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { getAllEmployee } from "../../services/Employee";

export default function AsyncTecnicosSelect({ formData, setFormData, fieldName = 'tecnico', cols = [12, 12, 12, 12], isDisabled = false, required = false, isMulti=false, advancedSetFormData = null }) {

    async function getAllTechnicians(inputValue) {
        if(inputValue?.length < 4){
            return;
        }

        let dataReturn = await getAllEmployee({
            'categoria': ['Técnico'],
            'nome_tec': inputValue
        });

        if (dataReturn?.status === 200) {
            let tecnicos = dataReturn.data.data.map((item, idx) => ({ value: item.id, label: item.nome_tec })) || [];
            return tecnicos;
        }
    }

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData === null) {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        } else {
            advancedSetFormData(value, name);
        }
    }

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Técnicos</FormLabel>
            <AsyncSelect
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                cacheOptions={true}
                loadOptions={getAllTechnicians}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                required={required}
                isDisabled={isDisabled}
                isMulti={isMulti}
                isClearable
                noOptionsMessage={() => ('Sem dados')}
                placeholder="Digite no mínimo 4 caracteres"
            ></AsyncSelect>
        </Col>
    )
}