import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkValue, handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import FieldOptionsForm from "./Components/FieldOptionsForm";
import { getOptionById, updateOptionById } from "../../../services/Options";

export default function UpdateFieldOption({ isOpen = false, setIsOpen = null, updateParent = null, updateId = '' }) {

    const INITIAL_STATE = {
        id_modulo: '',
        id_campo: '',
        valor_opcao: '',
        chave_opcao: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getData(id){
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getOptionById(id);

        if(dataReturn?.status === 200){
            let data = dataReturn?.data?.data;
            setFormData(data);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
            setFormData(INITIAL_STATE);
        }
    }

    async function submitForm(e){
        e.preventDefault();

        const toastData = toast.loading("Atualizando Tag, aguarde...");

        let dataReturn = await updateOptionById(updateId, formData);

        if(dataReturn?.status === 200){
            handleSuccessToast('Tag atualizada com sucesso!', 'update', toastData);
            updateParent();
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }
    
    useEffect(()=>{
        if(!checkValue(updateId)){
            getData(updateId);
        }else{
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Atualizar Campo'
            maximizable
        >
           <FieldOptionsForm type="update" formData={formData} setFormData={setFormData} submitForm={submitForm}></FieldOptionsForm>
        </Dialog>
    );
}