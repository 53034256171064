import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import CanceledShipmentDetails from './CanceledShipmentDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import If from '../../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import ProductDetails from '../Components/ProductDetails/ProductDetails';

export default function CanceledShipments() {
    const [data, setData] = useState([]);
    const [productReceivedId, setProductReceivedId] = useState('');
    const [openProductsReceivedDetails, setOpenProductsReceivedDetails] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions } = getSubComponentsPermissions('mam_logistica', 'CanceledShipments', 'CanceledShipments');

    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [productId, setProductId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermissions[0])}>
                            <button type="button" className="dropdown-item" onClick={() => openProductsReceivedModal(row.id_remessa)}>Ver</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[1])}>
                            <button type="button" className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'view')}>Ver Produto</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[2])}>
                            <button type="button" className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'edit')}>Editar Produto</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        },
        {
            id: 'dact_produto',
            name: 'DACT'
        },
        {
            id: 'valor_dact_produto',
            name: 'Valor DACT'
        }
    ];

    async function getCanceledShipments(page = 1, perPage = 100) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");
        // const data_inicial = getCurrentDate(0, -1, 0);
        // const data_final = getCurrentDate(1);
        let dataReturn = await getShipmentsByStatus('CANCELADA', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_remessa: item?.id || '',
                id_produto: item?.id_produto || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                dact_produto: item?.dact || '',
                valor_dact_produto: item?.valor_dact || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    function openProductsReceivedModal(id) {
        setProductReceivedId(id);
        setOpenProductsReceivedDetails(true);
    }

    function openProductModal(id, mode) {
        setProductId(id);
        setViewMode(mode);
        setIsUpdateProductModalOpen(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getCanceledShipments(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getCanceledShipments(1, rows);
    }

    useEffect(() => {
        getCanceledShipments();
    }, []);

    return (<Content headerTitle='Remessas Canceladas'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica'></SubHeaderSpecific>
        <CanceledShipmentDetails isOpen={openProductsReceivedDetails} setModalOpen={() => setOpenProductsReceivedDetails(!openProductsReceivedDetails)} productReceivedId={productReceivedId}></CanceledShipmentDetails>
        <ProductDetails isOpen={isUpdateProductModalOpen} setModalOpen={() => setIsUpdateProductModalOpen(!isUpdateProductModalOpen)} productId={productId} viewMode={viewMode} setUpdateProducts={() => getCanceledShipments(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic} queue={'products'} parentPage='CanceledShipments'></ProductDetails>
        <Card title='Cadastro de Produtos - Remessas Canceladas'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
                id='products_received'
                reorderableColumns
            ></PrimeTable>
        </Card>
    </Content>);
}