import React, { useEffect, useRef, useState } from 'react';
import { deleteAttachmentByAttachmentId, getAttachmentsByTicketId, getHistoryImagesByTicketId, uploadAttachmentByTicketId } from '../../../services/Ticket';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Card from '../../../common/Layout/Card';
import If from '../../../common/Layout/If';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function AttachmentData({ id = '', detailsData = {}, originalData = {}, updateData = false, setUpdateData = null, setImgArray = null, setImgThumbArray = null }) {
    const [attachmentData, setAttachmentData] = useState([]);
    const [attachmentFilesToUpload, setAttachmentFilesToUpload] = useState('');
    const [attachmentFormData, setAttachmentoFormData] = useState([]);
    const attachmentRef = useRef(null);
    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'AttachmentData');
    const attachmentColumns = [
        {
            id: 'arquivo_anexo_attachment',
            name: 'Arquivo Anexo',
            body: row => <a role='button' href={`${row.url_anexo}`} target='_blank' rel='noreferrer' download={true}>{row.arquivo_anexo_attachment}</a>,
        },
        {
            id: 'action_attachment',
            name: 'Ações',
            body: (row) => (
                <div className='align-items-center'>
                    <button className='btn btn-danger' onClick={() => { showDeleteAttachmentSwal(row.id_anexo) }}><i className='fas fa-trash'></i></button>
                </div>
            ),
        }
    ];

    async function submitAttachmentForm(e) {
        e.preventDefault();

        const formData = new FormData();

        for (let attachment of attachmentFormData) {
            formData.append('attachments[]', attachment)
        }

        const toastUpdate = toast.loading("Executando upload, aguarde...");

        let dataReturn = await uploadAttachmentByTicketId(id, formData);

        if (dataReturn?.data.response === 'success') {
            handleSuccessToast('Upload executado com sucesso!', 'update', toastUpdate);
            getHistoryImages();
            getAttachmentsById();
        } else {
            handleErrorsToast('Ocorreu um erro ao executar o upload!', dataReturn?.data, 'update', toastUpdate);
        }

        attachmentRef.current.value = "";
        setAttachmentFilesToUpload('');
        setAttachmentoFormData([]);
    }

    const getHistoryImages = async () => {
        let dataReturn = await getHistoryImagesByTicketId(id);

        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length > 0) {
            let imgData = dataReturn?.data?.data;
            let imgArray = imgData.map((item, idx) => {
                return (<div className={`carousel-item ${idx === 0 ? 'active' : ''} item`} key={`image_${idx}`} data-slide-number={idx}>
                    <div className="d-block w-100">
                        <img src={item['url_img']} alt={`image_${idx}`} width={'100%'} height={'auto'} />
                    </div>
                </div>)
            });

            let imgThumbArray = imgData.map((item, idx) => {
                return (<li className='list-inline-item' key={`thumb_${idx}`}>
                    <button className="btn btn-link thumbnail" id={`carousel-selector-${idx}`} data-slide-to={idx} data-target="#imgCarousel" style={{ cursor: 'pointer' }}>
                        <img src={item['url_img']} alt={`image_${idx}`} width={'200px'} height={'200px'} className='img-thumbnail' />
                    </button>
                </li>)
            });
            setImgArray(imgArray);
            setImgThumbArray(imgThumbArray);
        } else {
            setImgArray([]);
        }
    }

    async function handleAttachmentFiles(e) {

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        const attachments = [...attachmentFormData];

        chosenFiles.forEach((file) => {
            if (attachments.findIndex((f) => f.name === file.name) === -1) {
                attachments.push(file);
            }
        })

        setAttachmentoFormData(attachments);

        let fileNames = attachments.map((item, idx) => item.name);

        setAttachmentFilesToUpload(fileNames.join(', '));
    }

    const getAttachmentsById = async () => {
        let dataReturn = await getAttachmentsByTicketId(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let attachmentData = dataReturn?.data?.data;

            let attachmentTable = attachmentData.map((item, idx) => {
                return ({ url_anexo: item.download_url, id_anexo: item.id, arquivo_anexo_attachment: item.name })
            })

            setAttachmentData(attachmentTable);
        } else {
            setAttachmentData([]);
        }
    }

    async function showDeleteAttachmentSwal(id) {
        let response = await Swal.fire({
            titleText: 'Deseja deletar este anexo?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            deleteAttachmentById(id);
        }
    }

    async function deleteAttachmentById(id) {
        const toastDelete = toast.loading("Excluindo anexo, aguarde...");

        let dataReturn = await deleteAttachmentByAttachmentId(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Anexo excluido com sucesso!', 'update', toastDelete);
            getHistoryImages();
            getAttachmentsById();
        } else {
            handleErrorsToast('Falha ao excluir anexo!', dataReturn?.data, 'update', toastDelete);
        }
    }

    async function gerarPDFRATOK() {
        // const num_chamado = detailsData?.num_chamado.replace(" ", "+");
        const id = originalData?.id;
        window.open(`https://mamerp.maminfo.com.br:8080/api/app/rat/gerar-pdf/${id}`, '_blank');
    }

    useEffect(() => {
        getAttachmentsById();
        getHistoryImages();
    }, []);

    useEffect(() => {
        if (updateData) {
            getAttachmentsById();
            getHistoryImages();
            setUpdateData(false);
        }
    }, [updateData])

    return (<Card title={`Anexos (${attachmentData.length})`} icon={"fa-sticky-note"} collapseId={"attachmentsBody"}>
        <div className="row">
            <div className="col-12">
                <form onSubmit={submitAttachmentForm} encType='multipart/form-data'>
                    <div className="row">
                        <div className="col-12">
                            <div className='form-group'>
                                <input type='file' ref={attachmentRef} name="anexos_detalhes" id="anexosDetalhes" onChange={handleAttachmentFiles} multiple></input>
                            </div>
                        </div>
                        <div className='col-12'>
                            <p>
                                {attachmentFilesToUpload}
                            </p>
                        </div>
                        <div className="col-6">
                            <button type='submit' className='btn btn-primary' disabled={attachmentFilesToUpload === '' ? true : false}><i className="fas fa-cloud-upload-alt"></i> Adicionar anexo(s) ao pedido</button>
                        </div>
                        <If condition={["Aguardando Fechamento", "Fechado", "Pendente Fechamento"].includes(originalData?.status_chamado) && checkPermission(pagePermissions[0])}>
                            <div className="col-6">
                                <button type="button" className="btn btn-primary ml-1" onClick={gerarPDFRATOK}><i className="fas fa-cloud-upload-alt"></i>Gerar RAT</button>
                            </div>
                        </If>
                    </div>
                </form>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <PrimeTable
                    columns={attachmentColumns}
                    rows={attachmentData}
                    needExport={false}
                    id='attachmentPrimeTable'
                ></PrimeTable>
            </div>
        </div>
    </Card>);
}