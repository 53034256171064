import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllActivities } from '../../services/Ticket';
import If from '../Layout/If';
import { Col, FormLabel } from 'react-bootstrap';

export default function TiposAtividadesOptions({ formData, setFormData, fieldName = 'tipos_atividades', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, title='' }) {
    const [tipoAtividadeOptions, setTipoAtividadeOptions] = useState([]);

    async function getActivities() {
        let dataReturn = await getAllActivities();
        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length) {
            let tiposAtividades = dataReturn?.data?.data.map((item, idx) => {
                return { value: item.atividade, label: item.atividade };
            });
            setTipoAtividadeOptions(tiposAtividades);
        } else {
            setTipoAtividadeOptions([]);
        }
    }


    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getActivities();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <If condition={title !== ''}>
                <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            </If>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}                
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={tipoAtividadeOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}