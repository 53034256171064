import React, { useEffect, useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import PrimeTable from '../../common/Layout/Table/PrimeTable';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import CreateUserGroup from './CreateUserGroup';
import { toast } from 'react-toastify';
import { getAllGroups } from '../../services/Group';
import UserGroupDetails from './UserGroupDetails';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';

export default function UserGroup() {

    const [data, setData] = useState([]);
    const [openCreateUserGroup, setOpenCreateUserGroup] = useState(false);
    const [openUpdateUserGroup, setOpenUpdateUserGroup] = useState(false);
    const [idGrupo, setIdGrupo] = useState('');
    const [viewMode, setViewMode] = useState('view');

    const columns = [
        {
            id: 'action',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type='button' className="dropdown-item" onClick={() => handleOpenUpdate(row.id, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={() => handleOpenUpdate(row.id, 'edit')}>Editar</button>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_grupo',
            name: 'Nome do Grupo'
        },
        {
            id: 'descricao',
            name: 'Descrição'
        },
        {
            id: 'permissoes',
            name: 'Permissões',
        }
    ]

    async function getUserGroups() {
        let toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllGroups();

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            let data = dataReturn?.data?.data?.map(item => {
                return {
                    ...item,
                    permissoes: item?.permissions?.map((item) => item.descricao).join(', ') || ''
                }
            });
            setData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastData);
        }
    }

    function handleOpenUpdate(id, viewMode){
        setIdGrupo(id);
        setViewMode(viewMode);
        setOpenUpdateUserGroup(true);
    }

    useEffect(() => {
        getUserGroups();
    }, [])

    return (
        <Content headerTitle='Gerenciar Grupos de Usuário'>
            <CreateUserGroup isOpen={openCreateUserGroup} setIsOpen={() => setOpenCreateUserGroup(!openCreateUserGroup)} updateParent={getUserGroups}></CreateUserGroup>
            <UserGroupDetails isOpen={openUpdateUserGroup} setIsOpen={() => setOpenUpdateUserGroup(!openUpdateUserGroup)} idUserGroup={idGrupo || ''} updateParent={getUserGroups} viewMode={viewMode}></UserGroupDetails>
            <SubHeaderSpecific subHeaderConfig='mam_usuarios'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={() => setOpenCreateUserGroup(!openCreateUserGroup)}>Cadastrar Grupo</button>
                    </div>
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='user_group'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    );
}