import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getModules } from '../../services/DevMode';
import { Col, FormLabel } from 'react-bootstrap';

export default function ModulosSelect({ formData, setFormData, fieldName = 'id_modulo', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, advancedSetFormData = null }) {
    const [modules, setModules] = useState([]);

    async function getAllModules() {
        let dataReturn = await getModules();
        if (dataReturn?.data?.response === 'success') {
            let groups = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.nome_modulo }
            }) || [];
            setModules(groups);
        } else {
            setModules([]);
        }
    }


    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        getAllModules();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Módulo</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={modules}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}