import React, { useContext, useEffect, useRef, useState } from 'react';
import WhatsAppImg from '../../../resources/imgs/whatspng.png';
import WhatsAppWebImg from '../../../resources/imgs/zap_web.png';
import { createRatReport, createStepByTicketId, getRatReportByTicketId, getSteps, updateRatReport } from '../../../services/Rat';
import { getCurrentDate, getCurrentTime, handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import If from '../../../common/Layout/If';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../App';
import { InputMask } from 'primereact/inputmask';

export default function RatSteps({ step = 1, ticket_id = '', ticket = '', contract_id = '', partner_name = '' }) {

    const INITIAL_STATE = {
        chamado: '',
        id_etapa: '',
        descricao_etapa: '',
        minutos_concluir: '',
        observacao_etapa: '',
        dados_basicos_contato: '',
        dados_basicos_telefone: '',
        realizado_por: '',
        file_foto_waze: '',
        file_rede_int_foto_fachada: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [reportId, setReportId] = useState('');
    const userData = useContext(Context);
    const fotoWazeRef = useRef('');
    const fotoFachadaRef = useRef('');
    const navigate = useNavigate();

    async function getRatSteps() {
        let dataReturn = await getSteps();

        if (dataReturn?.status === 200) {
            let stepsData = dataReturn?.data?.data || [];
            let data = stepsData.filter(item => item.id_etapa === step);
            if (data.length > 0) {
                setFormData({ chamado: ticket, ...data[0] });
            } else {
                handleErrorsToast('Etapa não encontrada');
                navigate('/rat/chamados');
            }
        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
            navigate('/rat/chamados');
        }
    }

    async function createStep(e) {
        e.preventDefault();

        const toastData = toast.loading('Cadastrando etapa, aguarde...');
        let dataReturn = await createStepByTicketId({ id_chamado: ticket_id });

        if (dataReturn?.status === 201) {
            let createReportReturn = await createOrUpdateStep();

            if (createReportReturn === 'ok') {
                handleSuccessToast('Etapa cadastrada com sucesso!', 'update', toastData);
                navigate('/rat/chamados');
            } else {
                handleErrorsToast('Ocorreu um erro ao cadastrar a etapa', createReportReturn, 'update', toastData);
            }

        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data, 'update', toastData);
        }
    }

    async function updateReport() {
        let params = new FormData();

        params.append('id_tecnico', userData?.userDataState?.id_tecnico);
        params.append('id_contrato', contract_id);
        params.append('id_chamado', ticket_id);
        params.append('realizado_por', userData?.userDataState?.nome);
        params.append('file_rede_int_foto_fachada', formData?.file_rede_int_foto_fachada);
        params.append('dados_basicos_telefone', formData?.dados_basicos_telefone);
        params.append('dados_basicos_contato', formData?.dados_basicos_contato);

        let dataReturn = await updateRatReport(reportId, params);
        if (dataReturn?.status === 200) {
            fotoFachadaRef.current.value = '';
            return 'ok';
        } else {
            return dataReturn?.data;
        }
    }

    async function createOrUpdateStep() {

        let dataReturn = 'ok';
        if (step === 1) {
            dataReturn = await createReport();
        }

        if (step === 2) {
            dataReturn = await updateReport();
        }

        return dataReturn;
    }

    async function createReport() {
        let params = new FormData();
        params.append('minutos_concluir_etapa_1', formData?.minutos_concluir);
        params.append('id_tecnico', userData?.userDataState?.id_tecnico);
        params.append('data_hora_inicio_atendimento', `${getCurrentDate()} ${getCurrentTime()}`);
        params.append('id_contrato', contract_id);
        params.append('id_chamado', ticket_id);
        params.append('realizado_por', userData?.userDataState?.nome);
        params.append('empresa_parceira', partner_name);
        params.append('file_foto_waze', formData?.file_foto_waze);

        let dataReturn = await createRatReport(params);
        if (dataReturn?.status === 201) {
            fotoWazeRef.current.value = '';
            return 'ok';
        } else {
            return dataReturn?.data;
        }
    }

    async function getReport() {
        let dataReturn = await getRatReportByTicketId(ticket_id);

        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data;
            setReportId(data?.id);
        }else{
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
            navigate('/rat/chamados');
        }
    }

    useEffect(() => {
        getRatSteps();
    }, [ticket]);

    useEffect(() => {
        if (step === 2) {
            getReport();
        }
    }, [step]);

    return (
        <form onSubmit={createStep}>
            <div className="row">
                <div className="col-12">
                    <h5>Chamado</h5>
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="chamado">Chamado</label>
                    <input type="text" className='form-control' name='chamado' id='chamado' value={formData?.chamado || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="id_etapa">Número Etapa</label>
                    <input type="text" className='form-control' name='id_etapa' id='id_etapa' value={formData?.id_etapa || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="descricao_etapa">Descrição Etapa</label>
                    <input type="text" className='form-control' name='descricao_etapa' id='descricao_etapa' value={formData?.descricao_etapa || ''} readOnly />
                </div>
                <div className="col-sm-12 col-md-3">
                    <label htmlFor="minutos_concluir">Minutos para Concluir</label>
                    <input type="text" className='form-control' name='minutos_concluir' id='minutos_concluir' value={formData?.minutos_concluir || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} readOnly={step !== 1} />
                </div>
            </div>
            <If condition={step === 2}>
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <label htmlFor="dados_basicos_contato">Contato</label>
                        <input type="text" className='form-control' name='dados_basicos_contato' id='dados_basicos_contato' value={formData?.dados_basicos_contato || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <label htmlFor="dados_basicos_telefone">Fone/Fax</label>
                        <InputMask id='dados_basicos_telefone' name='dados_basicos_telefone' className='form-control' value={formData?.dados_basicos_telefone || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} mask='(99) 99999999?9'></InputMask>
                    </div>
                </div>
            </If>
            <hr />
            <div className="row">
                <div className="col-12">
                    <h5>Etapas</h5>
                </div>
                <div className="col-12">
                    <label htmlFor="observacao_etapa">Observações</label>
                    <textarea name="observacao_etapa" id="observacao_etapa" className='form-control' rows={7} value={formData?.observacao_etapa} readOnly></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                        <a href="https://wa.me/5511982669886" target="_blank">
                            <img src={WhatsAppImg} alt="Whats" />WhatsApp Fone</a>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                        <a href="https://web.whatsapp.com/send?phone=5511982669886" target="_blank">
                            <img src={WhatsAppWebImg} alt="Whats" />WhatsApp Web</a>
                    </div>
                </div>
            </div>
            <If condition={step === 2}>
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <label htmlFor="file_foto_waze">Foto da Fachada</label>
                        <input type="file" ref={fotoFachadaRef} className='form-control' id='file_rede_int_foto_fachada' name='file_rede_int_foto_fachada' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} accept='image/*' required />
                    </div>
                </div>
            </If>
            <If condition={step === 1}>
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <label htmlFor="file_foto_waze">Foto do Waze</label>
                        <input type="file" ref={fotoWazeRef} className='form-control' id='file_foto_waze' name='file_foto_waze' onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} accept='image/*' required />
                    </div>
                </div>
            </If>
            <div className="row mt-1">
                <div className="col-12">
                    <button type='submit' className='btn btn-primary'>Cadastrar</button>
                </div>
            </div>
        </form>
    )
}