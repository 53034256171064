import React, { useEffect, useState } from 'react';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import If from '../../../../common/Layout/If';
import ComponentesSelect from '../../../../common/Selects/ComponentesSelect';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { getComponentById } from '../../../../services/Repair';
import { toast } from 'react-toastify';
import { handleErrorsToast } from '../../../../configs/GenericFunctions';

export default function AddComponents({ mode = 'view', formData = {}, setFormData = null }) {

    const INITIAL_STATE = {
        origem: '',
        componente: '',
        valor: '',
        id_componente: ''
    }

    const [addComponentData, setAddComponentData] = useState(INITIAL_STATE);

    const addColumns = [
        {
            id: 'action_add_component',
            name: 'Excluir',
            body: (row) => (
                <If condition={!row?.from_database}>
                    <button type='button' className='btn btn-danger' onClick={() => removeComponent(row)}><i className='fas fa-trash'></i></button>
                </If>
            ),
            permission: mode !== 'view'
        },
        {
            id: 'componente',
            name: 'Componente'
        },
        {
            id: 'valor',
            name: 'Valor',
        },
        {
            id: 'origem',
            name: 'Origem Componente'
        }
    ]

    function handleAddComponent() {
        if (['', undefined, null].includes(addComponentData?.origem)) {
            handleErrorsToast('Por favor, selecione a origem do componente!');
            return;
        }

        let id_temp = 0;
        let componentesExistentes = [...formData?.componentes_existentes];
        let componentesNovos = [...formData?.componentes_novos_comprados];
        let componentes = [...componentesExistentes, ...componentesNovos].sort((a, b) => a?.id_temp - b?.id_temp);

        if (componentes?.length > 0) {
            id_temp = componentes[componentes.length - 1]?.id_temp + 1
        }

        if (addComponentData?.origem?.value === 'canibalizado') {

            if (addComponentData?.id_componente === '') {
                handleErrorsToast('Por favor, selecione um componente!');
                return;
            }

            if (componentes.find(item => item?.id === addComponentData?.id_componente?.value) !== undefined) {
                handleErrorsToast('Este componente já foi selecionado!');
                return;
            }

            let params = {
                ...addComponentData,
                id_temp: id_temp,
                origem: addComponentData?.origem?.value || '',
                id: addComponentData?.id_componente?.value || '',
            }

            componentesExistentes.push(params);

            setFormData(prev => ({ ...prev, componentes_existentes: [...componentesExistentes] }));
        }

        if (addComponentData?.origem?.value === 'comprado') {

            let componentsData = { ...addComponentData }

            delete componentsData?.id_componente;

            for (let c in componentsData) {
                if (['', undefined, null].includes(componentsData[c])) {
                    handleErrorsToast('Por favor, preencha todos os campos!');
                    return;
                }
            }

            let params = {
                ...addComponentData,
                valor: formatCurrency(addComponentData.valor),
                id_temp: id_temp,
                origem: addComponentData?.origem?.value || '',
            }

            componentesNovos.push(params);

            setFormData(prev => ({ ...prev, componentes_novos_comprados: [...componentesNovos] }));
        }

        setAddComponentData(INITIAL_STATE);

    }

    function removeComponent(row) {
        if (row.origem === 'canibalizado') {
            let componentes = [...formData?.componentes_existentes].filter((item) => item.id_temp !== row.id_temp);

            setFormData(prev => ({ ...prev, componentes_existentes: componentes }));
        }

        if (row.origem === 'comprado') {
            let componentes = [...formData?.componentes_novos_comprados].filter((item) => item.id_temp !== row.id_temp);

            setFormData(prev => ({ ...prev, componentes_novos_comprados: componentes }));
        }
    }

    async function handleComponenteCanibalizado(value, name) {

        let dataReturn = await getComponentById(value?.value);

        if (dataReturn?.data?.response === 'success') {
            let componentData = dataReturn?.data?.data || {};
            setAddComponentData(prev => ({ ...prev, [name]: value, componente: componentData?.componente || '', valor: componentData?.valor || '' }));
        } else {
            setAddComponentData(prev => ({ ...INITIAL_STATE, origem: prev?.origem }));
            handleErrorsToast('Ocorreu um erro ao buscar os dados do componente!');
        }
    }

    function handleOrigem(value, name) {
        setAddComponentData({ ...INITIAL_STATE, [name]: value });
    }

    function handleValor(e) {
        let valor = parseInt(e.target.value.replace(/\D/g, ''), 10) || 0;
        setAddComponentData(prev => ({ ...prev, [e.target.name]: valor }))
    }

    const formatCurrency = (val) => {
        const integerValue = parseInt(val, 10) || 0;
        const reais = (integerValue / 100).toFixed(2).replace('.', ',');
        return reais
    };

    return (
        <div className="row">
            <div className="col-12">
                <h4>Componentes Usados</h4>
            </div>
            <div className="col-12">
                <If condition={mode !== 'view'}>
                    <div className="row">
                        <GenericSelect formData={addComponentData} setFormData={setAddComponentData} fieldName={'origem'} cols={[12, 3, 3, 3]} genericOption='OrigemComponenteOptions' title='Origem do Componente' advancedSetFormData={handleOrigem}></GenericSelect>
                        <If condition={addComponentData?.origem?.value === 'comprado'}>
                            <div className="col-sm-12 col-md-3">
                                <label htmlFor="componente">Nome Componente</label>
                                <input type="text" name="componente" id="componente" className="form-control" placeholder='...' value={addComponentData?.componente || ''} onChange={(e) => setAddComponentData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label htmlFor="valor">Valor Componente</label>
                                <input type="text" name="valor" id="valor" className="form-control" value={formatCurrency(addComponentData?.valor)} onChange={(e) => handleValor(e)} />
                            </div>
                        </If>
                        <If condition={addComponentData?.origem?.value === 'canibalizado'}>
                            <ComponentesSelect formData={addComponentData} setFormData={setAddComponentData} cols={[12, 6, 6, 6]} fieldName='id_componente' id_modelo={formData?.id_modelo?.value || ''} advancedSetFormData={handleComponenteCanibalizado}></ComponentesSelect>
                        </If>
                        <div className="col-sm-12 col-md-3 mt-1 d-flex align-items-end">
                            <button type="button" className="btn btn-primary" onClick={handleAddComponent}>Adicionar</button>
                        </div>
                    </div>
                </If>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={addColumns}
                            rows={[...formData?.componentes_existentes, ...formData?.componentes_novos_comprados]}
                            id={`${mode}_add_components_primetable`}
                        ></PrimeTable>
                    </div>
                </div>
            </div>
        </div>
    )
}