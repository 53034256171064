import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { setDefaults } from 'react-geocode';
import { getAllRoutesOpen } from '../../../services/AppRoute';
import Car from '../../../resources/imgs/maps/carro3.png';
import If from '../../../common/Layout/If';
import './TechniciansMonitoring.css';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { handleErrorsToast, openNewTab } from '../../../configs/GenericFunctions';

export default function TechniciansMonitoring() {
    const [center] = useState({ lat: -22.820069, lng: -47.035386 });
    const [markers, setMarkers] = useState([]);
    const milliseconds = 20000;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_MAPS_KEY || "AIzaSyB_aOhT30y3hevB0dXMxRrnyhVQHnpqSvE"
    });

    setDefaults({
        key: process.env.REACT_APP_API_MAPS_KEY || "AIzaSyB_aOhT30y3hevB0dXMxRrnyhVQHnpqSvE",
        language: 'pt-BR',
        region: 'br'
    })

    function handleInfoWindow(id) {
        setMarkers(prevState => prevState.map(item => item.id === id ? { ...item, showInfo: !item.showInfo } : item));
    }

    useEffect(() => {
        async function getAllOpen() {
            let dataReturn = await getAllRoutesOpen();
    
            if (dataReturn?.data?.response === 'success') {
                let markerMap = dataReturn?.data?.data?.map((item, idx) => ({
                    ...item,
                    showInfo: true
                })) || [];
    
                setMarkers(markerMap);
            } else {
                handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data);
            }
        }

        let interval = setInterval(() => {
            getAllOpen();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        }

    }, []);

    return (<Content headerTitle='Monitoramento dos técnicos em deslocamento'>
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <div style={{ height: '100vh' }}>
                <If condition={isLoaded}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '10px' }}
                        center={center}
                        zoom={4}
                    >
                        {
                            markers.map(item => (
                                <Marker
                                    position={{
                                        lat: parseFloat(item?.latitude),
                                        lng: parseFloat(item?.longitude)
                                    }}
                                    options={{
                                        title: 'Chamado Nº ' + item?.ticket?.num_chamado + '. Clique no número do chamado para ir até ele!',
                                        label: {
                                            className: 'map-marker',
                                        },
                                        icon: {
                                            url: Car,
                                            scaledSize: { width: 40, height: 40 }
                                        }
                                    }}
                                    onClick={() => handleInfoWindow(item?.id)}
                                    key={item?.id}
                                >
                                    <If condition={item?.showInfo}>
                                        <InfoWindow
                                            position={{
                                                lat: parseFloat(item?.latitude),
                                                lng: parseFloat(item?.longitude)
                                            }}
                                            options={{
                                                pixelOffset: { width: 0, height: -40 } // Ajuste o deslocamento vertical do InfoWindow
                                            }}
                                            onCloseClick={() => handleInfoWindow(item?.id)}
                                        >
                                            <div>
                                                <p><b style={{ fontWeight: 'bold' }}>Chamado: </b><span onClick={() => openNewTab('/chamados/detalhes-chamado/' + item?.id_chamado)} className='ticket'>{item?.ticket?.num_chamado || ''}</span></p>
                                                <p><b style={{ fontWeight: 'bold' }}>Técnico: </b>{item?.technician?.nome_tec || ''}</p>
                                            </div>
                                        </InfoWindow>
                                    </If>
                                </Marker>
                            )) || <></>
                        }
                    </GoogleMap>
                </If>
                <If condition={!isLoaded}>
                    Carregando...
                </If>
            </div>
        </Card>
    </Content>)
}