import React, { useContext } from 'react';
import { Context } from '../App';
import { useLocation } from 'react-router-dom';
import { onImageError } from '../configs/GenericFunctions';

export default function useChat(){
    const userData = useContext(Context);
    const location = useLocation();

    function getChannelName(item){
        let channelName = '';

        if([null, ''].includes(item.ticket_number) && [null, ''].includes(item.channel_name)){
            let participants = item.users.map((item)=>{
                if(item.id === userData?.userDataState?.id_usuario){
                    return 'Eu';
                }
                return item.name;
            }) || [];
            channelName = participants.join(", ");
        }else if([null, ''].includes(item.ticket_number) && ![null, ''].includes(item.channel_name)){
            channelName = item.channel_name;
        }else if(![null, ''].includes(item.ticket_number) && [null, ''].includes(item.channel_name)){
            channelName = item.ticket_number;
        }else{
            channelName = `${item?.ticket_number} - ${item?.channel_name}`;
        }
        return channelName;
    }

    function getChannelImage(users, size1 = 60, size2 = 29){

        return (users.map((item, idx) => {
            let size = users.length < 3 ? size1 : size2
            return (<img src={item.profile_picture_url || ''} alt={item._id + "_image"} width={size} height={size} onError={onImageError} key={idx}/>)
        }));
    }

    function getChatTypeByRoute(){
        if(location.pathname.includes('/app-chat')){
            return 'app';
        }else if(location.pathname.includes('/external-chat')){
            return 'externo';
        }else{
            return 'padrao';
        }
    }

    return {
        getChannelName,
        getChannelImage,
        getChatTypeByRoute
    }
}