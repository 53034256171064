import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getComponents } from '../../services/Repair';
import { Col, FormLabel } from 'react-bootstrap';

export default function ComponentesSelect({ formData, setFormData, fieldName = 'componente', cols = [12, 12, 12, 12], isDisabled = false, required = false, advancedSetFormData = null, id_modelo = '', usado = '0' }) {
    const [componentesOptions, setComponentesOptions] = useState([]);

    async function getComponentsById(id) {
        let dataReturn = await getComponents(id, usado);
        if (dataReturn?.data?.response === 'success') {
            let components = dataReturn.data.data?.map((item, idx) => {
                return { value: item.id, label: item.componente }
            });
            setComponentesOptions(components);
        } else {
            setComponentesOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if (advancedSetFormData === null) {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        } else {
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id_modelo)) {
            getComponentsById(id_modelo);
        }else{
            setComponentesOptions([]);
        }
    }, [id_modelo]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Componente</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={componentesOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}