import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { insertPartnerValues } from '../../../../../services/PartnerValues';
import IntegradoraSelect from '../../../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../../../common/Selects/ContratosSelect';
import ValoresContratosSelect from '../../../../../common/Selects/ValoresContratosSelect';
import If from '../../../../../common/Layout/If';
import { getContractValuesById } from '../../../../../services/ContractValues';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function CreateParnerValues({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    id_representante = '',
}) {

    const INITIAL_STATE = {
        id_contrato: '',
        integradora: '',
        id_representante: id_representante,
        id_contrato_valor_comercial: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: '',
        grupo: '',
        descricao_valor_alto: ''
    }

    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);
    const [originalData, setOriginalData] = useState(INITIAL_STATE);
    const [openValueDescription, setOpenValueDescription] = useState(false);

    async function submitForm(e) {
        e.preventDefault();

        const toastValues = toast.loading("Cadastrando dados, aguarde...");

        let params = {
            ...formValoresTabela,
            aprovado: 'pendente',
            id_contrato_valor_comercial: formValoresTabela?.id_contrato_valor_comercial?.value || ''
        }

        let dataReturn = await insertPartnerValues(params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastValues);
            setFormValoresTabela(INITIAL_STATE);
            setOriginalData(INITIAL_STATE);
            setOpenValueDescription(false)
            updateParent();
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar os dados!', dataReturn?.data, 'update', toastValues);
        }
    }

    async function handleAdvancedSetFormData(value, name) {
        setFormValoresTabela(prevState => ({ ...prevState, [name]: value }));

        if (name === 'id_contrato_valor_comercial') {
            getContractValuesData(value.value);
        }

        if (name === 'integradora') {
            setFormValoresTabela(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora }));
            setOriginalData(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora }));
        }

        if (name === 'id_contrato') {
            setFormValoresTabela(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora, id_contrato: prevState?.id_contrato }));
            setOriginalData(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora, id_contrato: prevState?.id_contrato }));

        }
    }

    async function getContractValuesData(id) {

        let dataReturn = await getContractValuesById(id);
        if (dataReturn?.data?.response === 'success') {
            setFormValoresTabela(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
                descricao_valor_alto: ''
            }));
            setOriginalData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
                descricao_valor_alto: ''
            }));
            setOpenValueDescription(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados dos valores do contrato!', dataReturn?.data);
        }
    }

    function handleValues(value, name) {
        let valueDescription = enableValueDescription(value);
        setFormValoresTabela(prevState => ({ ...prevState, [name]: value, grupo: valueDescription, descricao_valor_alto: valueDescription === '' ? '' : prevState?.descricao_valor_alto }));
    }

    function enableValueDescription(value) {
        let fields = ['valor_chamado', 'valor_fora_hr_comercial', 'valor_chamado_improdutivo', 'valor_ha_diurna', 'valor_ha_noturna', 'valor_km_apos_51'];

        for (let f of fields) {
            if (value > originalData[f]) {
                let validationMode = value > (originalData[f] * 1.1) ? 'diretoria' : 'gerente_operacoes';
                setOpenValueDescription(true);
                return validationMode;
            }
        }

        setOpenValueDescription(false);
        return '';
    }

    return (<Dialog
        visible={isOpen}
        onHide={setIsOpen}
        header={'Cadastrar Valores'}
        maximizable
        >
        <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Valores Contrato</h5>
                </div>
                <IntegradoraSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} fieldName='integradora' required={true} advancedSetFormData={handleAdvancedSetFormData}></IntegradoraSelect>
                <ContratosSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} dependsOnIntegradora={true} fieldName='id_contrato' required={true} advancedSetFormData={handleAdvancedSetFormData} id_integradora_string={[formValoresTabela?.integradora?.value].toString()}></ContratosSelect>
                <ValoresContratosSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} fieldName='id_contrato_valor_comercial' id_contrato={formValoresTabela?.id_contrato?.value || ''} required={true} advancedSetFormData={handleAdvancedSetFormData}></ValoresContratosSelect>
                <div className="col-md-12 col-lg-6">
                    <label htmlFor="escopo">Escopo</label>
                    <input type="text" name="escopo" id="escopo" className="form-control" value={formValoresTabela?.escopo || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={true} placeholder='...' required={true} />
                </div>
                <div className="col-md-12 col-lg-6">
                    <label htmlFor="observacoes">Observações Diversas</label>
                    <input type="text" name="observacoes" id="observacoes" className="form-control" value={formValoresTabela?.observacoes || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_chamado">R$ Chamado (R$ {originalData?.valor_chamado || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial (R$ {originalData?.valor_fora_hr_comercial || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_chamado_improdutivo">Chamado Improdutivo (R$ {originalData?.valor_chamado_improdutivo || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_chamado_improdutivo" id="valor_chamado_improdutivo" className='form-control' value={formValoresTabela?.valor_chamado_improdutivo || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m (R$ {originalData?.valor_ha_diurna || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m (R$ {originalData?.valor_ha_noturna || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <div className="col-md-12 col-lg-2">
                    <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km (R$ {originalData?.valor_km_apos_51 || '0,00'})</label>
                    <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} required={true} />
                </div>
                <If condition={openValueDescription}>
                    <div className="col-12">
                        <label htmlFor="descricao_valor_alto">Motivo da Alteração dos Valores</label>
                        <textarea name="descricao_valor_alto" id="descricao_valor_alto" rows="10" className="form-control" value={formValoresTabela?.descricao_valor_alto || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required></textarea>
                    </div>
                </If>
                <div className="col-12 d-flex align-items-center mt-1">
                    <button type="submit" className='btn btn-primary'>Cadastrar</button>
                </div>
            </div>
        </form>
    </Dialog>);
}