import React, { useContext, useEffect, useState } from 'react';
import { getContactsList } from '../../services/Chat';
import Modal from '../Layout/Modal';
import { toast } from 'react-toastify';
import { Context } from '../../App';
import SearchField from './SearchField';
import { ChatContext } from './MainChatComponent';
import { handleErrorsToast, handleSuccessToast, onImageError } from '../../configs/GenericFunctions';
import { Button, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';


export default function Contacts({ isOpen, setModalOpen, title = '', updateChannel=false, setMembers }) {

    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const userData = useContext(Context);
    const {chatState} = useContext(ChatContext);

    function addSelectedContactToArray(newContact) {
        setSelectedContacts(prevState => {
            if (prevState.some(contact => contact.id === newContact.id)) {
                return prevState.filter(contact => contact.id !== newContact.id);
            } else {
                return [...prevState, newContact];
            }
        });
    }

    async function getContacts() {
        const toastContacts = toast.loading("Carregando contatos, aguarde...");

        let contactsArray = await getContactsList();
        if (contactsArray?.data?.response === 'success') {
            handleSuccessToast('Dados encontrados!', 'update', toastContacts);

            let contactsData = contactsArray?.data?.data.filter(item => item.id !== userData?.userDataState?.id_usuario) || [];

            if(updateChannel){
                let currentMembers = chatState?.members.map((item, idx)=>{
                    return item.id;
                });
                contactsData = contactsData.filter(item => !currentMembers.includes(item.id));
            }

            setContacts(contactsData);
            setFilteredContacts(contactsData);
        } else {
            handleErrorsToast('Ocorreu um erro ao encontrar os dados!', contactsArray?.data, 'update', toastContacts);
        }
    }

    function getContactListComponent(){
        return (filteredContacts.map((item, idx) => {
            return (
                <ListGroupItem className={`${selectedContacts.includes(item) ? 'active hover:bg-blue-500  hover:border-blue-500' : 'hover:bg-gray-200'} cursor-pointer `} aria-current="true" onClick={() => addSelectedContactToArray(item)} key={item.id}>
                    <Row>
                        <Col xs='12' className="text-collapse">
                            <img src={item?.url_foto_perfil || ''} className="border-circle mr-1 w-3rem h-3rem" alt={item.id + "_image"} onError={onImageError} />
                            <span><b>{item?.nome} {item?.cliente === 1 ? '[CLIENTE]' : ''}</b></span>
                        </Col>
                    </Row>
                </ListGroupItem>)
        }))
    }

    function searchContact(search){
        let contactsList = contacts.filter(item => item.nome.toLowerCase().includes(search.toLowerCase()));

        setFilteredContacts(contactsList);
    }

    useEffect(() => {
        if(isOpen){
            getContacts();
        }
        setSelectedContacts([]);
    }, [isOpen])

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <div style={{ minWidth: '50vw' }}>
                <Button type="button" variant='success' size='lg' style={{ position: 'fixed', bottom: 95, right: 35, zIndex: '1000', opacity: '0.8' }} onClick={() => setMembers(selectedContacts)}><i className='fas fa-check'></i></Button>
                <CardHeader>
                    <Row>
                        <Col>
                           <SearchField placeholder='Pesquise pelo nome do contato' searchFunction={searchContact}></SearchField>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{ overflowY: 'auto' }}>
                    <Row>
                        <Col>
                            <ListGroup>
                                {
                                    getContactListComponent()
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </CardBody>
            </div>
        </Modal>
    )
}