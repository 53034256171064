import React, { useEffect, useState } from 'react';
import { getEquipmentsByTicketId, sendBulkShipment } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import AsyncChamadosSelect from '../../../../common/Selects/AsyncChamadosSelect';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import Swal from 'sweetalert2';
import { getTickets } from '../../../../services/Ticket';

export default function BulkPendingTicketsDetails({ isOpen = false, setIsOpen = null, setUpdateShipment = null, setUpdateLogistic = null, numero_chamado = '' }) {

    const INITIAL_STATE = {
        numero_chamado: '',
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [tableFormData, setTableFormData] = useState([]);
    const [quantProdutos, setQuantProdutos] = useState(0);
    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto - Serial',
            filter: false,
            body: (row) => {
                return <AsyncProdutosSelect formData={row} setFormData={setTableFormData} fieldName='tipo_produto' id_modelo={row?.partnumber?.id || ''} cols={[12, 6, 6, 6]} advancedSetFormData={(value, name) => handleProdutos(value, name, row)}></AsyncProdutosSelect>
            }
        },
    ];

    async function submitForm(e) {
        e.preventDefault();

        let params = []

        for (let c of tableFormData) {
            if (!['', null, undefined].includes(c?.tipo_produto))
                params.push({
                    id_remessa: c?.id,
                    id_produto: c?.tipo_produto?.value
                });
        }

        if (params.length < tableFormData.length) {
            showPartialUpdateSwal(params);
        } else {
            bulkPendingTicketsUpdate(params);
        }

    }

    async function showPartialUpdateSwal(params) {
        let swal = await Swal.fire({
            title: 'As remessas sem produtos não serão atualizadas, deseja continuar?',
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showConfirmButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showCancelButton: true
        });

        if (swal.isConfirmed) {
            bulkPendingTicketsUpdate(params);
        }
    }

    async function bulkPendingTicketsUpdate(params) {

        const toastData = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await sendBulkShipment(params);

        if (dataReturn?.status === 200) {
            let message = params.length === tableFormData.length ? 'Todas remessas para este id localidade foram atualizadas' : 'As remessas para este id localidade foram atualizadas parcialmente';
            handleSuccessToast(message, 'update', toastData);
            setUpdateShipment();
            setUpdateLogistic(true);
            setQuantProdutos(0);
            setFormData(INITIAL_STATE);
            setTableFormData([]);
        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data, 'update', toastData);
        }
    }

    async function handleProdutos(value, name, item) {
        let productExists = tableFormData.filter(item => item?.tipo_produto?.value === value?.value);

        if (productExists?.length === 0 || value === null) {
            setTableFormData(prev => prev
                .map(currentRow =>
                    currentRow.id === item.id
                        ? { ...currentRow, [name]: value } : currentRow
                ));

            let productQuant = value === null ? quantProdutos - 1 : quantProdutos + 1;

            setQuantProdutos(productQuant);
        } else {
            handleErrorsToast("Este produto já foi selecionado!");
        }
    }

    async function getTicketPendingShipments(value, name) {
        setFormData(prev => ({ ...prev, [name]: value }));

        let num_chamado = value?.label?.split(' - ')[0] || '';

        if (!['', undefined, null, false].includes(num_chamado)) {

            const toastData = toast.loading("Carregando dados, aguarde...");

            let dataReturn = await getEquipmentsByTicketId(num_chamado);
            if (dataReturn?.status === 200) {
                let data = dataReturn?.data?.data;

                let fields = data.map((item) => ({
                    ...item,
                    nome_produto: item?.partnumber?.modelo
                }));

                setTableFormData(fields);
                handleSuccessToast("Dados encontrados!", 'update', toastData);
            } else {
                setTableFormData([]);
                setFormData(INITIAL_STATE);
                setQuantProdutos(0);
                handleErrorsToast("Ocorreu um erro", dataReturn?.data, 'update', toastData);
            }
        } else {
            setTableFormData([]);
            setFormData(INITIAL_STATE);
            setQuantProdutos(0);
        }
    }

    async function getTicket() {
        let params = {
            data_inicial: '2023-01-01',
            num_chamado: numero_chamado
        }

        let dataReturn = await getTickets(params);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data?.tickets;
            if(data?.length > 0){
                getTicketPendingShipments({ value: data[0]?.id || '', label: `${data[0]?.num_chamado || ''} - ${data[0]?.status_chamado || ''}`}, 'numero_chamado');
            }
        }
    }

    useEffect(() => {
        if (isOpen) {
            if (!['', null, undefined].includes(numero_chamado)) {
                getTicket();
            }else{
                setFormData(INITIAL_STATE);
                setTableFormData([]);
            }
            setQuantProdutos(0);
        }
    }, [isOpen, numero_chamado]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Selecionar Produtos em Massa'
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5>Procure as remessas pelo ID Localidade/Nº Chamado!</h5>
                    </div>
                </div>
                <div className="row">
                    <AsyncChamadosSelect formData={formData} setFormData={setFormData} title='ID Localidade\Nº Chamado' fieldName='numero_chamado' advancedSetFormData={getTicketPendingShipments}></AsyncChamadosSelect>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={tableFormData}
                            needExport={false}
                            id='bulk_pending_table'
                        ></PrimeTable>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" disabled={quantProdutos === 0}>Enviar</button>
                    </div>
                </div>
            </form>
        </Dialog>)
}
