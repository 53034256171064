import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import { assignPartnerTicket } from '../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import ChamadosSelect from '../../common/Selects/ChamadosSelect';
import UsuariosAtivosSelect from '../../common/Selects/UsuariosAtivosSelect';
import AsyncChamadosSelect from '../../common/Selects/AsyncChamadosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';

export default function ResponsavelParceria() {
    const initialState = {
        responsavel_parceria: '',
        chamados_nao_designados: '',
        chamados_todos_dia: '',
        procurar_todos_chamados: '',
    }

    const [responsavelParceriaForm, setResponsavelParceriaForm] = useState(initialState);
    const [updateData, setUpdateData] = useState(false);

    async function submitAssignForm(e) {
        e.preventDefault();
        let chamadosArray = [...responsavelParceriaForm?.chamados_nao_designados, ...responsavelParceriaForm?.chamados_todos_dia, ...responsavelParceriaForm?.procurar_todos_chamados];

        if (chamadosArray.length === 0) {
            handleErrorsToast('Por favor, selecione pelo menos um chamado');
            return;
        }

        let ids_chamado = chamadosArray.filter((item, idx) => chamadosArray.findIndex(item2 => item?.value === item2?.value) === idx).map((item, idx) => {
            return item?.value
        });

        let formData = {
            ids_chamado: ids_chamado,
            id_responsavel: responsavelParceriaForm?.responsavel_parceria?.value || ''
        }

        const ticketToast = toast.loading("Atualizando chamados, aguarde...");

        let dataReturn = await assignPartnerTicket(formData);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Chamado atualizado com sucesso!', 'update', ticketToast);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o chamado!', dataReturn?.data, 'update', ticketToast);
        }
        setResponsavelParceriaForm(initialState);
        setUpdateData(true);
    }

    return (<Content headerTitle="Chamados - Responsável Parceria">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitAssignForm}>
                <div className='row'>
                    <UsuariosAtivosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='responsavel_parceria'
                        required={true}
                        title='Responsável Parceria'
                        userType='partners'
                    ></UsuariosAtivosSelect>
                    <ChamadosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='chamados_nao_designados'
                        isMulti={true}
                        title='Chamados Não Designados (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Parceria)'
                        type='partner unassigned'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <ChamadosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='chamados_todos_dia'
                        isMulti={true}
                        title='Chamados Todos do dia (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Parceria)'
                        type='today'
                        today_field='usuario_responsavel_parceria'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <AsyncChamadosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='procurar_todos_chamados'
                        isMulti={true}
                        title='Procurar Todos Chamados (Digite no mínimo 4 caracteres)'
                    ></AsyncChamadosSelect>
                    <div className="col-12 mt-1">
                        <button type='submit' className="btn btn-primary">Designar responsável ao(s) chamado(s)</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}