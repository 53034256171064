import React, { useEffect, useRef } from 'react';
import anychart from 'anychart';
import { getBrazilGeoMap } from '../../services/AnyMaps';
import { useNavigate } from 'react-router-dom';

export default function AnyMap({data = []}) {
    const navigate = useNavigate();
    useEffect(() => {
        const loadGeoData = async () => {
            let dataReturn = await getBrazilGeoMap();

            if (dataReturn?.status === 200) {
                let getDataText = dataReturn?.data;

                const container = document.getElementById('map-br-container');
                const existingMap = container?.anychartMap;

                if(existingMap){
                    existingMap.dispose();
                }

                const map = anychart.map();
                map.geoData(getDataText);

                const dataSet = anychart.data.set(data);

                const series = map.choropleth(dataSet);
                series.colorScale(anychart.scales.linearColor('#bbdefb', '#1565c0'));
                series.tooltip().format("{%title}: {%value}");
                series.listen('pointClick', (e)=>{
                    let url = e.iterator.get('url');
                    if(!['', undefined, null, false].includes(url)){
                        navigate(url);
                    }
                })

                map.container('map-br-container');
                map.draw();

                container.anychartMap = map;
            }
        }

        if (data.length > 0) {
            loadGeoData();
        }
    }, [data]);

    return (<>
        <div id='map-br-container' style={{ width: '100%', height: '1000px' }}></div>
    </>)
}