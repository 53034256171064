import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTypesOfContract } from '../../services/Contracts';
import { Col, FormLabel } from 'react-bootstrap';

export default function TiposContratosSelect({ formData, setFormData, fieldName = 'tipo', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [contractTypeOptions, setContractTypeOptions] = useState([]);

    async function getContractTypes() {
        let dataReturn = await getTypesOfContract();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let dados = dataReturn?.data?.data.map((item, idx) => {
                return ({ value: item.nome, label: item.nome });
            })
            setContractTypeOptions(dados);
        } else {
            setContractTypeOptions([]);
        }
    }


    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getContractTypes();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Tipo de Contrato</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}                
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={contractTypeOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}