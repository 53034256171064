import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import ContratosSelect from '../../../../common/Selects/ContratosSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import modelo from '../../../../resources/modelos_xls/Modelo_Produtos.xlsx'
import { toast } from 'react-toastify';
import { bulkCreate } from '../../../../services/Product';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function BulkCreate({ isOpen = false, setModalOpen = null, setUpdateProducts=null }) {
    const INITIAL_STATE = {
        id_contrato: '',
        fabricante: '',
        id_modelo: '',
        file: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const fileRef = useRef();
    const mySwal = withReactContent(Swal);

    async function submitForm(e){
        e.preventDefault();

        let params = new FormData();
        params.append('file', formData?.file);
        params.append('id_contrato', formData?.id_contrato?.value);
        params.append('id_modelo', formData?.id_modelo?.value);

        const toastData = toast.loading('Executando upload, aguarde...');

        let dataReturn = await bulkCreate(params);

        if(dataReturn?.status === 200){
            let productData = dataReturn?.data;

            if(productData?.duplicate_serials?.length > 0 || productData?.errors?.length > 0){
                mySwal.fire({
                    title: 'Importação concluída, porém ocorreram alguns erros:',
                    html: (
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <h6>Erros de importação</h6>
                                    {
                                        productData.errors.map((item, idx)=>(
                                            <div key={`${item.row}_${idx}`}>
                                                <h6>Linha {item.row}</h6>  
                                                <p>{item.errors.toString()}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-12">
                                    <h6>Seriais duplicados que não foram inseridos</h6>
                                    <p>{productData?.duplicate_serials?.toString()}</p>
                                </div>
                            </div>
                        </>
                    ),
                });
            }
            
            setFormData(INITIAL_STATE);
            fileRef.current.value = "";
            setUpdateProducts();
            handleSuccessToast('Importação concluída com sucesso!', 'update', toastData);
        }else{
            handleErrorsToast('Ocorreu um erro', dataReturn?.data, 'update', toastData);
        }
    }

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            maximizable
            resizable={false}
            header='Criar Produtos em Lote'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <ContratosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='id_contrato' required selectAllContracts={false}></ContratosSelect>
                    <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='fabricante' required title='Fabricante'></FabricanteSelect>
                    <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='id_modelo' id_fabricante={formData?.fabricante?.value || ''} required></ModelosSelect>
                    <div className="col-12">
                        <label htmlFor="file">Arquivo</label>
                        <input type="file" name="file" id="file" className='form-control' ref={fileRef} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.files[0] }))} accept='.xls, .xlsx' required />
                        <p style={{ fontSize: '0.8rem' }}>Obs: Para inclusão correta, por favor, verifique a existência de caracteres especiais (desconhecidos) ou aspas simples no arquivo a ser anexado.</p>
                        <p style={{ fontSize: '0.8rem' }}>Obs2: Deixe todos as linhas a serem adicionadas na primeira planilha.</p>
                        <p style={{ fontSize: '0.8rem' }}>Obs3: O documento não pode ter mais do que uma planilha.</p>
                    </div>
                    <div className="col-12">
                        <a href={modelo} className='m-1' target="_blank" download={true}>Modelo - Download</a>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary"><i className='fas fa-upload'></i> Enviar</button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}