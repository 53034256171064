import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllProductsParams } from '../../services/Product';
import { Col, FormLabel } from 'react-bootstrap';
import { checkValue } from '../../configs/GenericFunctions';

const INITIAL_PARAMS = {
    remove_id_contrato: '',
    id_modelo: '',
    id_status_produto: ''
}

export default function TodosProdutosSelect({ formData, setFormData, fieldName = 'produtos', title='Produtos', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, params = {...INITIAL_PARAMS}, advancedSetFormData = null }) {
    const [productsOptions, setProductsOptions] = useState([]);

    async function getProducts() {
        let dataReturn = await getAllProductsParams(1, 1000, params);
        
        if (dataReturn?.status === 200) {
            let products = dataReturn?.data?.data?.data?.map((item) => {
                return { value: item.id, label: `${item?.nome_produto || ''} - ${item?.serial || ''} - ${item?.technician?.nome_tec || 'SEM TÉCNICO'}` }
            });
            setProductsOptions(products);
        } else {
            setProductsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData !== null){
            advancedSetFormData(value, name);
        }else{
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    }

    useEffect(() => {
        if(!checkValue(params?.id_modelo)){
            getProducts();
        }
    }, [params?.id_modelo, params?.id_status_produto, params?.remove_id_contrato]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>{title}</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={productsOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}