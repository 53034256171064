import React, { useEffect, useRef, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeeById, updateEmployeeById } from '../../../../services/Employee';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import Form from '../Components/Form';

export default function TechnicianDetails({ type = "technician" }) {

    const initialState = {
        representantes: [],
        nome_tec: '',
        rg_tec_orgao_expedidor: '',
        rg_tec: '',
        cpf_cnpj: '',
        data_nascimento: '',
        estado_civil: '',
        numero_cnh: '',
        cnh_img: '',
        nome_mae: '',
        data_validade_cnh: '',
        data_validade_aso: '',
        data_exame_periodico: '',
        logradouro_end: '',
        numero_complemento_endereco: '',
        bairro_end: '',
        cidade_end: '',
        estado_end: '',
        cep_end: '',
        telefone_fixo_tec: '',
        telefone_recados: '',
        celular_tec: '',
        celular_tec_pessoal: '',
        login_tec: '',
        senha_tec: '',
        email_tec: '',
        email_tec_pessoal: '',
        veiculo: '',
        cor_veiculo: '',
        modelo_veiculo: '',
        placa_veiculo: '',
        banco: '',
        tipo_conta_banco: '',
        agencia_banco: '',
        num_conta_banco: '',
        titular_banco2: '',
        cpf_titular2: '',
        desc_base: '',
        desc_habilidades: '',
        descricao: '',
        ativo: '',
        credenciado: '',
        modal_contratacao: '',
        categoria: "Técnico",
        id_representante: [],
        cnpj: '',
        id_integradora: [],
        id_contrato: []
    }

    const { id } = useParams();
    const [updateData, setUpdataData] = useState(false);
    const [formTechnicianData, setFormTechnicianData] = useState(initialState);
    // const { checkPermission } = usePermissions();
    // const { page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'TechnicianDetails', 'TechnicianDetails');
    // const { page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'EmployeeDetails', 'EmployeeDetails');
    const [showPwd, setShowPwd] = useState(false);
    const navigate = useNavigate();
    const requiredFields = [
        { name: 'nome_tec', field: 'Nome' },
        { name: 'numero_complemento_endereco', field: 'Número' },
        { name: 'logradouro_end', field: 'Logradouro' },
        { name: 'estado_end', field: 'Estado' },
        { name: 'cidade_end', field: 'Cidade' },
        { name: 'bairro_end', field: 'Bairro' },
        { name: 'cep_end', field: 'CEP' },
        { name: 'id_representante', field: 'Representante' },
        { name: 'modal_contratacao', field: 'Modal Contratação' },
        { name: 'categoria', field: 'Categoria' }
    ];

    // function showPassword() {
    //     if (showPwd) {
    //         setShowPwd(false);
    //     } else {
    //         setShowPwd(true);
    //     }
    // }

    async function submitForm(e) {
        e.preventDefault();

        let representantes;
        let modal_contratacao;
        let categoria;
        let errors = {
            errors: {}
        };
        if (type === 'technician') {
            representantes = formTechnicianData?.id_representante?.map(item => item.value) || [];
            modal_contratacao = representantes.includes(1) ? 'CLT' : 'PJ';
            categoria = 'Técnico';
        }

        if (type === 'employee') {
            representantes = modal_contratacao === 'CLT' ? [1] : [845];
            modal_contratacao = formTechnicianData?.modal_contratacao?.value || '';
            categoria = formTechnicianData?.categoria?.value || '';
        }

        let id_usuario_gestor = representantes.includes(1) ? '' : formTechnicianData?.id_usuario_gestor?.value || '';

        let formData = {
            ...formTechnicianData,
            representantes: representantes,
            nome_tec: formTechnicianData?.nome_tec || '',
            rg_tec_orgao_expedidor: formTechnicianData?.rg_tec_orgao_expedidor || '',
            rg_tec: formTechnicianData?.rg_tec || '',
            cpf_cnpj: formTechnicianData?.cpf_cnpj || '',
            data_nascimento: formTechnicianData?.data_nascimento || '',
            estado_civil: formTechnicianData?.estado_civil?.value || '',
            numero_cnh: formTechnicianData?.numero_cnh || '',
            cnh_img: formTechnicianData?.cnh_img || '',
            nome_mae: formTechnicianData?.nome_mae || '',
            data_validade_cnh: formTechnicianData?.data_validade_cnh || '',
            data_validade_aso: formTechnicianData?.data_validade_aso || '',
            data_exame_periodico: formTechnicianData?.data_exame_periodico || '',
            logradouro_end: formTechnicianData?.logradouro_end || '',
            numero_complemento_endereco: formTechnicianData?.numero_complemento_endereco || '',
            bairro_end: formTechnicianData?.bairro_end || '',
            cidade_end: formTechnicianData?.cidade_end || '',
            estado_end: formTechnicianData?.estado_end || '',
            cep_end: formTechnicianData?.cep_end || '',
            telefone_fixo_tec: formTechnicianData?.telefone_fixo_tec || '',
            telefone_recados: formTechnicianData?.telefone_recados || '',
            celular_tec: formTechnicianData?.celular_tec || '',
            celular_tec_pessoal: formTechnicianData?.celular_tec_pessoal || '',
            login_tec: formTechnicianData?.login_tec || '',
            senha_tec: formTechnicianData?.senha_tec || '',
            email_tec: formTechnicianData?.email_tec || '',
            email_tec_pessoal: formTechnicianData?.email_tec_pessoal || '',
            veiculo: formTechnicianData?.veiculo || '',
            cor_veiculo: formTechnicianData?.cor_veiculo || '',
            modelo_veiculo: formTechnicianData?.modelo_veiculo || '',
            placa_veiculo: formTechnicianData?.placa_veiculo || '',
            banco: formTechnicianData?.banco || '',
            tipo_conta_banco: formTechnicianData?.tipo_conta_banco || '',
            agencia_banco: formTechnicianData?.agencia_banco || '',
            num_conta_banco: formTechnicianData?.num_conta_banco || '',
            titular_banco2: formTechnicianData?.titular_banco2 || '',
            cpf_titular2: formTechnicianData?.cpf_titular2 || '',
            desc_base: formTechnicianData?.desc_base || '',
            desc_habilidades: formTechnicianData?.desc_habilidades || '',
            descricao: formTechnicianData?.descricao || '',
            ativo: formTechnicianData?.ativo || 1,
            credenciado: 1,
            modal_contratacao: modal_contratacao,
            categoria: categoria,
            senha_tecnico: formTechnicianData?.senha_tec || '',
            id_usuario_gestor: id_usuario_gestor,
            contratos: formTechnicianData?.id_contrato?.map((item) => item.value)
        }

        for (let field of requiredFields) {
            if (formData[field.name] === '') {
                errors.errors[field.name] = [];
                errors.errors[field.name].push(`Por favor, preencha os dados do ${field.field}`);
            }
        }

        if (Object.keys(errors.errors).length > 0) {
            handleErrorsToast('Ocorreu um erro', errors);
            return;
        }

        const toastUpdateForm = toast.loading("Atualizando, aguarde...");

        let dataReturn = await updateEmployeeById(id, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastUpdateForm);
            getEmployee();
            setUpdataData(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastUpdateForm);
        }
    }

    async function getEmployee() {
        const toastEmployee = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getEmployeeById(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data !== null) {
            handleSuccessToast('Dados encontrados!', 'update', toastEmployee);
            let employeeData = dataReturn?.data?.data;

            let partners = employeeData.partners.map((item) => {
                return ({ value: item?.id, label: item?.nome_representante });
            });

            let id_contrato = employeeData?.contracts?.map((item) => {
                return ({ value: item?.id, label: item?.contrato});
            });

            let id_integradora = employeeData?.contracts?.map((item) => {
                return ({ value: item?.integrator?.id, label: item?.integrator?.nome})
            });
            
            id_integradora = Array.from(new Set(id_integradora.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
            let login_tec = employeeData.tech_user.length > 0 ? employeeData.tech_user[0].login : '';

            setFormTechnicianData({
                ...employeeData,
                estado_civil: { value: employeeData?.estado_civil, label: employeeData?.estado_civil },
                senha_tec: '',
                categoria: type === 'technician' ? 'Técnico' : { value: employeeData?.categoria, label: employeeData?.categoria },
                login_tec: login_tec,
                id_representante: [...partners],
                id_usuario_gestor: { value: employeeData?.manager?.id || '', label: employeeData?.manager?.nome || '' },
                modal_contratacao: type === 'technician' ? employeeData?.modal_contratacao || '' : { value: employeeData?.modal_contratacao || '', label: employeeData?.modal_contratacao || '' },
                id_contrato: id_contrato,
                id_integradora: id_integradora
            });

        } else if (dataReturn?.data?.data === null) {
            handleErrorsToast('Este cadastro não existe!', dataReturn?.data, 'update', toastEmployee);
            navigate(type === 'technician' ? "/cadastros/tecnicos/dados" : '/cadastros/funcionarios/dados');
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastEmployee);
            navigate(type === 'technician' ? "/cadastros/tecnicos/dados" : '/cadastros/funcionarios/dados');
        }
    }

    useEffect(() => {
        getEmployee();
    }, []);

    return (
        <Content headerTitle={`Detalhes ${type === 'technician' ? "Técnico" : "Funcionário"}`}>
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <Form type={type} formTechnicianData={formTechnicianData} id={id} page='update' setFormTechnicianData={setFormTechnicianData} submitForm={submitForm} updateData={updateData} setUpdataData={setUpdataData}></Form>
            </Card>
        </Content >
    );
}