import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { changeShipmentStatus, getEquipmentsByTicketId, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import PendingTicketsDetails from './PendingTicketsDetails';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { getTicketQuant, handleErrorsToast, handleSuccessToast, openNewTab, showBulkShipmentMessage } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import BulkPendingTicketsDetails from './BulkPendingTicketsDetails';
import { Button, Col, DropdownButton, DropdownItem, Form, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function PendingTickets() {
    const INITIAL_STATE = {
        data_inicio: '',
        data_fim: ''
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [numeroChamado, setNumeroChamado] = useState('');
    const { checkPermission, checkAllPermissions } = usePermissions();
    const [pendingTicketId, setPendingTicketId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [openPendingTicketModal, setOpenPendingTicketModal] = useState(false);
    const [openBulkPendingTicketModal, setOpenBulkPendingTicketModal] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_logistica', 'PendingTickets', 'PendingTickets');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[0])}>
                        <DropdownItem onClick={() => openPendingTicketsDetailsModal(row, 'view')}>Ver</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <DropdownItem onClick={() => openPendingTicketsDetailsModal(row, 'edit')}>Editar</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2])}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Remessa</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'data_criacao_produto',
            name: 'Data de Criação'
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</Button>)
        },
        {
            id: 'status_chamado_produto',
            name: 'Status Chamado'
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        }
    ];

    async function getPendingProducts(page = 1, perPage = 100, search = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('PRODUTO PENDENTE', page, perPage, search);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                fabricante_produto: item?.partnumber?.manufacturer?.nome_fabricante || '',
                nome_produto: item?.partnumber?.modelo || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                status_chamado_produto: item?.ticket?.status_chamado || '',
                id_chamado_produto: item?.ticket?.id || '',
                urgencia_produto: item?.urgencia_remessa || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || '',
                data_criacao_produto: item?.created_at_br || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    async function openPendingTicketsDetailsModal(row, mode) {
        if (mode === 'view') {
            handleSingleShipmentModal(row, mode);
            return;
        }

        let quantProdutos = await getTicketQuant(row.id_localidade_produto);

        if (quantProdutos === 1) {
            handleSingleShipmentModal(row, mode);
        } else {
            showBulkShipmentMessage(() => handleSingleShipmentModal(row, mode), () => handleBulkShipmentModal(row));
        }
    }

    function handleSingleShipmentModal(row, mode) {
        setPendingTicketId(row.id_produto);
        setOpenPendingTicketModal(true);
        setViewMode(mode);
    }

    function handleBulkShipmentModal(row) {
        setNumeroChamado(row.id_localidade_produto);
        setOpenBulkPendingTicketModal(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getPendingProducts(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getPendingProducts(1, rows, search);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            getPendingProducts();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a emissão!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function submitForm(e) {
        e.preventDefault();
        let params = '';

        for (let fd in formData) {
            if (!['', undefined, null].includes(formData[fd]) || !['', undefined, null].includes(formData[fd]?.value)) {
                params += `&${fd}=${formData[fd]?.value || formData[fd]}`;
            }
        }

        getPendingProducts(1, 100, params);
        setPerPage(100);
        setPage(0);
        setSearch(params);
    }

    function clearForm() {
        setFormData(INITIAL_STATE);
        setSearch('');
    }

    useEffect(() => {
        getPendingProducts();
    }, []);

    return (<Content headerTitle='Chamados Pendentes'>
        <PendingTicketsDetails isOpen={openPendingTicketModal} setIsOpen={() => setOpenPendingTicketModal(!openPendingTicketModal)} shipmentId={pendingTicketId} mode={viewMode} setUpdateLogistic={setUpdateLogistic} setUpdateShipment={() => getPendingProducts(page + 1, perPage, search)}></PendingTicketsDetails>
        <BulkPendingTicketsDetails isOpen={openBulkPendingTicketModal} setIsOpen={() => setOpenBulkPendingTicketModal(!openBulkPendingTicketModal)} setUpdateLogistic={setUpdateLogistic} setUpdateShipment={() => getPendingProducts(page + 1, perPage, search)} numero_chamado={numeroChamado}></BulkPendingTicketsDetails>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' updateLogistic={updateLogistic} setUpdateLogistic={setUpdateLogistic}></SubHeaderSpecific>
        <Card title='Cadastro de Produtos - Chamados Pendentes'>
            <Row>
                <If condition={checkAllPermissions(pagePermissions)}>
                    <Col sm='12' className='flex justify-content-start flex-wrap'>
                        <DropdownButton className='inline m-1' id='dropdown-pending-tickets' title='Ações'>
                            <If condition={checkPermission(pagePermissions[0])}>
                                <DropdownItem onClick={() => setOpenBulkPendingTicketModal(true)}>Selecionar Produtos em Massa</DropdownItem>
                            </If>
                        </DropdownButton>
                    </Col>
                </If>
                <Col sm='12'>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col sm='12' md='3'>
                                <FormLabel htmlFor="data_inicio">Data Início</FormLabel>
                                <FormControl type="date" name="data_inicio" id="data_inicio" value={formData?.data_inicio || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                            </Col>
                            <Col sm='12' md='3'>
                                <FormLabel htmlFor="data_fim">Data Fim</FormLabel>
                                <FormControl type="date" name="data_fim" id="data_fim" value={formData?.data_fim || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                            </Col>
                            <Col sm='12' className='mt-1'>
                                <Button type='button' variant='warning' onClick={clearForm} className='mr-1'>Limpar Campos</Button>
                                <Button type='submit' variant='primary'>Pesquisar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col sm='12'>
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='pending_tickets'
                        reorderableColumns
                    ></PrimeTable>
                </Col>
            </Row>
        </Card>
    </Content>);
}