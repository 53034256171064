import React, { useContext, useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import { createTicket, getTechnicianDisponibility } from '../../../services/Ticket';
import { withMask } from 'use-mask-input';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getTechnicianById } from '../../../services/Technician';
import { useNavigate } from 'react-router-dom';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import If from '../../../common/Layout/If';
// import ValoresRepresentanteSelect from '../../../common/Selects/ValoresRepresentantesSelect';
import TiposAtividadesOptions from '../../../common/Selects/TiposAtividadesOptions';
import GenericSelect from '../../../common/Selects/GenericSelect';
import Cep from '../../../common/Cep/Cep';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import ModalEnvioSelect from '../../../common/Selects/ModalEnvioSelect';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../common/Selects/TecnicosSelect';
import IntegradoraSelect from '../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../common/Selects/ContratosSelect';
import EnvolveLogisticaSelect from '../../../common/Selects/EnvolveLogisticaSelect';
import BestTechnician from '../Components/BestTechnician';
import ProductsTable from './Products/Products';
import { handleErrorsToast, handleSuccessToast, getCurrentDate, getCurrentTime, copyToClipboard } from '../../../configs/GenericFunctions';
import { Context } from '../../../App';
import usePermissions from '../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../configs/MenuItemsConfigs';
// import { getContractById } from '../../../services/Contracts';

export default function CreateTicket() {

    const initialState = {
        data_solicitacao: getCurrentDate(),
        hora_solicitacao: getCurrentTime(),
        data_agendamento: getCurrentDate(),
        hora_agendamento: getCurrentTime(),
        id_ticket_cliente: '',
        tipo_atividade: { value: 'Ativação', label: 'Ativação' },
        urgencia: { value: 'Agendado', label: 'Agendado' },
        prioridade: { value: 'Baixa', label: 'Baixa' },
        envolve_logistica: { value: 'Não', label: 'Não' },
        status_chamado: { value: 'Agendado', label: 'Agendado' },
        informacao_adicional: '',
        info_adicional_circuito: '',
        descricao: '',
        nome_cliente: '',
        nome_contato_cliente: '',
        telefone_contato_cliente: '',
        email_contato_cliente: '',
        endereco_cliente: '',
        numero_complemento: '',
        cep_endereco_cliente: '',
        bairro_endereco_cliente: '',
        estado_endereco_cliente: '',
        cidade_endereco_cliente: '',
        destinatario_remessa: '',
        logradouro_endereco_logistica: '',
        compl_ref_obs_endereco_logistica: '',
        cep_endereco_logistica: '',
        bairro_endereco_logistica: '',
        estado_endereco_logistica: '',
        cidade_endereco_logistica: '',
        logradouro_endereco_partida: '',
        numero_endereco_partida: '',
        compl_ref_obs_endereco_partida: '',
        cep_endereco_partida: '',
        bairro_endereco_partida: '',
        estado_endereco_partida: '',
        cidade_endereco_partida: '',
        id_representante: '',
        id_tecnico: '',
        status_tec_atendimento: '',
        id_contrato: '',
        id_integradora: '',
        nome_solicitante: '',
        km_tecnico: '',
        numero_rma: '',
        servicos_contrato_array: [],
        local_partida_tecnico: '',
        tem_conflito_chamado: false,
        remessas: [],
        modal_envio: '',
        id_representante_valor_comercial: '',
        os_ba: ''
    }

    const tipoAtividadesLockValues = ["Teste RMA", "Teste Interno"];

    const [createTicketForm, setCreateTicketForm] = useState(initialState);
    const [productData, setProductData] = useState([]);
    const [statusChamadoDisabled, setStatusChamadoDisabled] = useState(false);
    const [cadastrarBtnDisabled, setCadastrarBtnDisabled] = useState(false);
    const [disableBestTechnician, setDisableBestTechnician] = useState(true);
    const [openBestTechnician, setOpenBestTechnician] = useState(false);
    const { checkPermission } = usePermissions();
    const { form: formPermissions } = getSubComponentsPermissions('mam_chamados', 'CreateTicket', 'CreateTicket');
    
    const mySwal = withReactContent(Swal);
    const userData = useContext(Context);

    const navigate = useNavigate();

    function hideFields(field) {
        function byStatusChamado(status = []) {
            return status.includes(createTicketForm?.status_chamado?.value) ? false : true
        }

        function byTipoAtividade(tiposAtividades = []) {
            return tiposAtividades.includes(createTicketForm?.tipo_atividade?.value) ? false : true
        }

        function byEnvolveLogistica(envolveLogistica = []) {
            return envolveLogistica.includes(createTicketForm?.envolve_logistica?.value) ? false : true;
        }

        function circuito(){
            return checkPermission(formPermissions[0]) &&  
            !['Teste RMA', 'Teste Interno'].includes(createTicketForm?.tipo_atividade?.value) &&
            !(['Logística', 'Logística - Retorno'].includes(createTicketForm?.tipo_atividade?.value) && ['Sim - Envio Técnico'].includes(createTicketForm?.envolve_logistica?.value))
            ? true : false;
        }

        function custom1() {
            return ["Teste RMA", "Teste Interno"].includes(createTicketForm?.tipo_atividade?.value)
                ||
                (["Logística", "Logística - Retorno"].includes(createTicketForm?.tipo_atividade?.value) && ["Sim - Envio Técnico"].includes(createTicketForm?.envolve_logistica?.value))
                ? false : true;
        }

        function custom2() {
            return ["Teste RMA", "Teste Interno"].includes(createTicketForm?.tipo_atividade?.value)
                ||
                (["Logística", "Logística - Retorno"].includes(createTicketForm?.tipo_atividade?.value) && ["Sim - Envio Técnico"].includes(createTicketForm?.envolve_logistica?.value))
                ||
                userData?.userDataState?.cliente
                ? false : true;
        }

        function custom3() {
            return ["Teste RMA", "Teste Interno"].includes(createTicketForm?.tipo_atividade?.value)
                ||
                userData?.userDataState?.cliente
                ? false : true;
        }

        function byIsCliente() {
            return userData?.userDataState?.cliente ? false : true;
        }

        function osBa(){
            return ['Oi S/A'].includes(createTicketForm?.id_integradora?.label) &&  
            !['Teste RMA', 'Teste Interno'].includes(createTicketForm?.tipo_atividade?.value) &&
            !(['Logística', 'Logística - Retorno'].includes(createTicketForm?.tipo_atividade?.value) && ['Sim - Envio Técnico'].includes(createTicketForm?.envolve_logistica?.value))
            ? true : false;
        }

        let fields = {
            id_ticket_cliente: true,
            tipo_atividade: true,
            data_solicitacao: true,
            hora_solicitacao: true,
            urgencia: true,
            prioridade: true,
            data_agendamento: byStatusChamado(['Agendar']),
            hora_agendamento: byStatusChamado(['Agendar']),
            envolve_logistica: byIsCliente(),
            status_chamado: true,
            nome_solicitante: true,
            id_integradora: byTipoAtividade(["Teste RMA", "Teste Interno"]),
            id_contrato: byTipoAtividade(["Teste RMA", "Teste Interno"]),
            info_adicional_circuito: circuito(),
            informacao_adicional: custom1(),
            nome_cliente: custom1(),
            nome_contato_cliente: custom1(),
            telefone_contato_cliente: custom1(),
            email_contato_cliente: custom1(),
            endereco_cliente: custom1(),
            id_representante: custom3(),
            id_tecnico: custom3(),
            km_tecnico: custom2(),
            local_partida_tecnico: true,
            endereco_ponto_partida: custom2(),
            endereco_logistica: byEnvolveLogistica(["Não", "Sim - Retirada"]),
            produtos_logistica: byIsCliente(),
            descricao: true,
            header_integradora: byTipoAtividade(["Teste RMA", "Teste Interno"]),
            header_cliente: custom1(),
            header_tecnico: custom3(),
            header_ponto_partida: custom2(),
            data_desejada: true,
            hora_desejada: true,
            os_ba: osBa(),
            protocolo_anatel: true
        }
        let fieldValue = fields[field];

        return typeof fieldValue === 'function' ? fieldValue() : fieldValue;
    }

    const handleFormInputs = (e) => {
        setCreateTicketForm({ ...createTicketForm, [e.target.name]: e.target.value });
    }

    const handleTechnician = async (value, name) => {
        if ([undefined, null, ''].includes(value)) {
            setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
            return;
        }

        if (createTicketForm.status_chamado?.value === 'Agendar' || (['Logística', 'Logística - Retorno'].includes(createTicketForm?.tipo_atividade?.value) && ['Sim - Envio Técnico'].includes(createTicketForm?.envolve_logistica?.value))) {
            setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: false });
            return;
        }

        await checkTechnicianDisponibility(value, name);
    }

    async function checkTechnicianDisponibility(value, name) {

        if (createTicketForm?.data_agendamento === '' || createTicketForm?.hora_agendamento === '') {
            setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
            handleErrorsToast('Preencha a data e hora do agendamento!');
            return;
        }

        let params = {
            id_tecnico: parseInt(value?.value),
            data_agendamento: createTicketForm.data_agendamento,
            hora_agendamento: createTicketForm.hora_agendamento
        }

        let dataReturn = await getTechnicianDisponibility(params);

        if (dataReturn?.data?.response === 'Nenhum atendimento conflitante encontrado para o técnico.' || value?.label === 'Maminfo Soluções') {

            setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: false });

        } else if (dataReturn?.data?.response === 'Técnico já possui atendimentos para esse dia e período.') {

            let listComponent = dataReturn.data.data.tickets.map((item, idx) => {
                return (
                    <div key={idx}>
                        <p><b>Número do Chamado:</b> {item.num_chamado}</p>
                        <p><b>Data/Hora Agendamento:</b> {item.data_agendamento} {item.hora_agendamento}</p>
                        <hr></hr>
                    </div>)
            });
            handleErrorsToast(dataReturn?.data?.response);

            let response = await mySwal.fire({
                titleText: `Atenção, este técnico possui chamados!`,

                html: (
                    <>
                        <p>Deseja alocar este técnico e alterar o status do chamado para Aprovação do Responsável?</p>
                        <h6 style={{ textTransform: 'capitalize' }}>Técnico: {value.label}</h6>
                        <div style={{ textAlign: 'left' }}>{listComponent}</div>
                    </>),
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonColor: 'red',
                confirmButtonText: `Sim`,
                cancelButtonText: `Não`,
            });

            if (response.isConfirmed) {
                setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: true });
            } else {
                setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
            }

        } else {
            handleErrorsToast('Ocorreu um erro ao buscar a disponibilidade do técnico!', dataReturn?.data);
            setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        let values = {
            ...createTicketForm,
            envolve_logistica: createTicketForm.envolve_logistica?.value,
            prioridade: createTicketForm.prioridade?.value,
            status_chamado: createTicketForm.status_chamado?.value,
            tipo_atividade: createTicketForm.tipo_atividade?.value,
            urgencia: createTicketForm.urgencia?.value,
            id_representante_valor_comercial: createTicketForm?.id_representante_valor_comercial?.value || ''
        };

        if (tipoAtividadesLockValues.includes(tipoAtividade)) {
            values = {
                ...values,
                id_integradora: 28,
                id_contrato: 44438,
                nome_cliente: "Maminfo",
                nome_contato_cliente: "Interno Maminfo",
                telefone_contato_cliente: "(19) 3751-3100",
                cep_endereco_cliente: "13098335",
                estado_endereco_cliente: "SP",
                cidade_endereco_cliente: "Campinas",
                bairro_endereco_cliente: "Loteamento Alphaville Campinas",
                endereco_cliente: "Rua Açu",
                numero_complemento: "10",
                id_representante: 188,
                id_tecnico: 78533,
                km_tecnico: 1.0,
                local_partida_tecnico: 'Casa do Técnico',
                cep_endereco_partida: "13098335",
                estado_endereco_partida: "SP",
                cidade_endereco_partida: "Campinas",
                bairro_endereco_partida: "Loteamento Alphaville Campinas",
                logradouro_endereco_partida: "Rua Açu",
                compl_ref_obs_endereco_partida: "10",
                fabricante: ""
            }
        } else if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            let cepClienteValidationFields = ["estado_endereco_cliente", "cidade_endereco_cliente"];
            let cepLocalPartidaTecnico = ["estado_endereco_partida", "cidade_endereco_partida", "bairro_endereco_partida", "logradouro_endereco_partida", "compl_ref_obs_endereco_partida"];
            let cepEnderecoLogistica = ["estado_endereco_logistica", "cidade_endereco_logistica"];

            values = {
                ...values,
                id_integradora: createTicketForm.id_integradora?.value || '',
                id_contrato: createTicketForm.id_contrato?.value || '',
                id_representante: createTicketForm.id_representante?.value || '',
                id_tecnico: createTicketForm.id_tecnico?.value || '',
                local_partida_tecnico: createTicketForm.local_partida_tecnico?.value || ''
            };

            if (!["Logística", "Logística - Retorno"].includes(createTicketForm?.tipo_atividade?.value) || !["Sim - Envio Técnico"].includes(createTicketForm?.envolve_logistica?.value)) {
                for (let v of cepClienteValidationFields) {
                    if (values[v] === '') {
                        handleErrorsToast('Preencha o CEP do Cliente corretamente!');
                        return;
                    }
                }

                if (values.cep_endereco_partida !== '') {
                    for (let v of cepLocalPartidaTecnico) {
                        if (values[v] === '') {
                            handleErrorsToast('Preencha corretamente os dados de partida!');
                            return;
                        }
                    }
                }
            } else {
                values = {
                    ...values,
                    nome_cliente: "",
                    nome_contato_cliente: "",
                    telefone_contato_cliente: "",
                    cep_endereco_cliente: "",
                    estado_endereco_cliente: "",
                    cidade_endereco_cliente: "",
                    bairro_endereco_cliente: "",
                    endereco_cliente: "",
                    numero_complemento: "",
                    km_tecnico: "",
                    local_partida_tecnico: '',
                    cep_endereco_partida: "",
                    estado_endereco_partida: "",
                    cidade_endereco_partida: "",
                    bairro_endereco_partida: "",
                    logradouro_endereco_partida: "",
                    compl_ref_obs_endereco_partida: ""
                }
            }


            if (!["Não", "Sim - Retirada"].includes(values.envolve_logistica)) {
                for (let v of cepEnderecoLogistica) {
                    if (values[v] === '') {
                        handleErrorsToast('Preencha o CEP da Logística corretamente!');
                        return;
                    }
                }
            }

            if (values.envolve_logistica === 'Sim - Retirada') {
                values = {
                    ...values,
                    destinatario_remessa: 'Local de Retirada',
                    logradouro_endereco_logistica: 'Rua Açu',
                    compl_ref_obs_endereco_logistica: '10',
                    cep_endereco_logistica: '13098335',
                    bairro_endereco_logistica: 'Lot. Alphaville Campina',
                    estado_endereco_logistica: 'SP',
                    cidade_endereco_logistica: 'Campinas',
                }
            }

            if (values.tem_conflito_chamado) {
                values = { ...values, status_chamado: 'Aprovação do Responsável' }
            }
        }

        if (userData?.userDataState?.cliente) {
            values = {
                ...values,
                id_representante: 188,
                id_tecnico: 78533,
                km_tecnico: 1.0,
                local_partida_tecnico: 'Casa do Técnico',
                cep_endereco_partida: "13098335",
                estado_endereco_partida: "SP",
                cidade_endereco_partida: "Campinas",
                bairro_endereco_partida: "Loteamento Alphaville Campinas",
                logradouro_endereco_partida: "Rua Açu",
                compl_ref_obs_endereco_partida: "10",
                fabricante: "",
                status_chamado: 'Agendar'
            }
        }

        if (values.id_tecnico !== undefined) {
            values = { ...values, status_tec_atendimento: 'Aguardando aceite' }
        }

        if (values.id_tecnico === '' || values.id_tecnico === 'A definir') {
            values = { ...values, status_tec_atendimento: 'A definir' }
        }

        values.km_tecnico = +values.km_tecnico;

        values.remessas = productData.map((item, idx) => {
            let modalEnvio = createTicketForm?.envolve_logistica?.value === 'Sim - Retirada' ? "Em Mãos" : createTicketForm?.modal_envio?.value;
            return ({
                id_produto: item?.id_produto?.value || '',
                id_modelo: item?.id_modelo?.value,
                status: 'aguardando_envio',
                categoria_envio: 'Remessa',
                modal_envio: modalEnvio,
            });
        });

        const toastTicket = toast.loading("Carregando, aguarde...");
        setCadastrarBtnDisabled(true);
        let dataReturn = await createTicket(values);

        if (dataReturn?.status === 201) {
            handleSuccessToast('Chamado criado com sucesso!', 'update', toastTicket);
            setCadastrarBtnDisabled(false);

            let mensagem = values.tem_conflito_chamado === false ? 'Chamado Criado com Sucesso!' : 'Chamado Criado para Aprovação do Responsável!';

            copyToClipboard(dataReturn?.data?.data?.num_chamado);

            let response = await mySwal.fire({
                titleText: mensagem,
                html: (
                    <>
                        <h2>Ticket: {dataReturn?.data?.data?.num_chamado}</h2>
                        <div style={{ textAlign: 'left' }}>
                            <p>Ticket copiado com sucesso!</p>
                            <p>Deseja voltar para Chamados ou abrir um novo chamado?</p>
                        </div>
                    </>),
                showConfirmButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                cancelButtonColor: 'red',
                confirmButtonText: `Abrir Novo Chamado`,
                cancelButtonText: `Voltar Para Visualização de Chamados`,
                didClose: () => {
                    window.scrollTo(0, 0);
                }
            });

            if (response.isConfirmed) {
                setCreateTicketForm(initialState);
            } else {
                navigate("/chamados/dados/all");
            }
        } else {
            handleErrorsToast('Ocorreu um erro ao criar o chamado', dataReturn?.data, 'update', toastTicket);
            setCadastrarBtnDisabled(false);
        }
    }

    async function getTechnicianDataById(id) {
        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === "success") {
            let dataTech = dataReturn?.data?.data;
            let cepTech = dataTech?.cep_end?.replace(/[^0-9a-zA-Z]+/g, "");

            setCreateTicketForm({
                ...createTicketForm,
                cep_endereco_partida: cepTech || '',
                bairro_endereco_partida: dataTech.bairro_end || '',
                cidade_endereco_partida: dataTech.cidade_end || '',
                estado_endereco_partida: dataTech.estado_end || '',
                logradouro_endereco_partida: dataTech.logradouro_end || '',
                compl_ref_obs_endereco_partida: dataTech.numero_complemento_endereco || ''
            });
        }
    }

    async function setLogisticAddressByTechnicianId(id) {
        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === "success") {
            let dataTech = dataReturn?.data?.data;
            let cepTech = dataTech?.cep_end?.replace(/[^0-9a-zA-Z]+/g, "") || '';

            setCreateTicketForm(prevState => ({
                ...prevState,
                destinatario_remessa: dataTech.nome_tec || '',
                cep_endereco_logistica: cepTech || '',
                bairro_endereco_logistica: dataTech.bairro_end || '',
                cidade_endereco_logistica: dataTech.cidade_end || '',
                estado_endereco_logistica: dataTech.estado_end || '',
                logradouro_endereco_logistica: dataTech.logradouro_end || '',
                compl_ref_obs_endereco_logistica: dataTech.numero_complemento_endereco || ''
            }));
        }
    }

    function handlePartner(value, name) {
        setCreateTicketForm(prevState => ({
            ...prevState,
            [name]: value,
            id_tecnico: '',
            id_representante_valor_comercial: ''
        }))
    }

    function handleIntegrator(value, name) {
        let tipoAtividade = createTicketForm.tipo_atividade?.value;
        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            setCreateTicketForm(prevState => ({
                ...prevState,
                [name]: value,
                id_contrato: ''
            }))
        }
    }

    async function handleContract(value, name) {
        setCreateTicketForm(prev => ({ ...prev, 
            [name]: value,
            id_tecnico: '',
            id_representante_valor_comercial: ''
        }));
    }

    useEffect(() => {
        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            setCreateTicketForm(p => ({
                ...p,
                status_chamado: {
                    value: 'Agendado',
                    label: 'Agendado'
                }
            }));
            setStatusChamadoDisabled(false);
        } else {
            setCreateTicketForm(p => ({
                ...p,
                info_adicional_circuito: '',
                status_chamado: {
                    value: 'Suporte interno (Testes)',
                    label: 'Suporte interno (Testes)'
                }
            }));
            setStatusChamadoDisabled(true);
        }

    }, [createTicketForm?.tipo_atividade?.value]);

    useEffect(() => {
        let statusChamado = createTicketForm.status_chamado?.value;

        if (statusChamado === "Agendar") {
            setCreateTicketForm({ ...createTicketForm, tem_conflito_chamado: false });
        }

    }, [createTicketForm.status_chamado]);

    useEffect(() => {
        let isAgendamentoPreenchido = createTicketForm?.data_agendamento !== '' || createTicketForm?.hora_agendamento !== '';
        let isStatusChamadoValido = createTicketForm.status_chamado?.value !== "Agendar";
        let isTipoAtividadeValido = !tipoAtividadesLockValues.includes(createTicketForm.tipo_atividade?.value);
        let isLogistica = !['Logística', 'Logística - Retorno'].includes(createTicketForm.tipo_atividade?.value) || !['Sim - Envio Técnico'].includes(createTicketForm?.envolve_logistica?.value);
        let condicional = isAgendamentoPreenchido && isStatusChamadoValido && isTipoAtividadeValido && isLogistica;

        if (condicional) {
            handleTechnician(createTicketForm.id_tecnico, 'id_tecnico');
        }
    }, [createTicketForm.hora_agendamento, createTicketForm.data_agendamento, createTicketForm.tipo_atividade, createTicketForm.envolve_logistica]);

    useEffect(() => {
        let id_tecnico = createTicketForm.id_tecnico?.value;
        let local_partida = createTicketForm.local_partida_tecnico?.value;
        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            if (id_tecnico !== undefined && local_partida === "Casa do Técnico") {
                getTechnicianDataById(id_tecnico);
            }

            if (id_tecnico === undefined || ["Outro", ""].includes(local_partida)) {
                setCreateTicketForm({
                    ...createTicketForm,
                    cep_endereco_partida: '',
                    bairro_endereco_partida: '',
                    cidade_endereco_partida: '',
                    estado_endereco_partida: '',
                    logradouro_endereco_partida: '',
                    compl_ref_obs_endereco_partida: ''
                })
            }
        }
    }, [createTicketForm.local_partida_tecnico, createTicketForm.id_tecnico]);

    useEffect(() => {
        let envolve_logistica = createTicketForm.envolve_logistica?.value;

        if (envolve_logistica === 'Sim - Envio Cliente') {
            setCreateTicketForm(prevState => ({
                ...prevState,
                destinatario_remessa: '',
                cep_endereco_logistica: createTicketForm.cep_endereco_cliente,
                estado_endereco_logistica: createTicketForm.estado_endereco_cliente,
                cidade_endereco_logistica: createTicketForm.cidade_endereco_cliente,
                bairro_endereco_logistica: createTicketForm.bairro_endereco_cliente,
                logradouro_endereco_logistica: createTicketForm.endereco_cliente,
                compl_ref_obs_endereco_logistica: createTicketForm?.numero_complemento
            }));
        } else if (envolve_logistica === 'Sim - Envio Técnico') {
            let idTecnico = createTicketForm?.id_tecnico?.value

            if (idTecnico !== undefined) {
                setLogisticAddressByTechnicianId(idTecnico);
            }
        } else {
            setCreateTicketForm(prevState => ({
                ...prevState,
                destinatario_remessa: '',
                cep_endereco_logistica: '',
                estado_endereco_logistica: '',
                cidade_endereco_logistica: '',
                bairro_endereco_logistica: '',
                logradouro_endereco_logistica: '',
                compl_ref_obs_endereco_logistica: '',
                modal_envio: ''
            }));
        }

    }, [createTicketForm.envolve_logistica, createTicketForm.id_tecnico, createTicketForm.cep_endereco_cliente, createTicketForm.estado_endereco_cliente, createTicketForm.cidade_endereco_cliente, createTicketForm.bairro_endereco_cliente, createTicketForm.endereco_cliente, createTicketForm.numero_complemento]);

    useEffect(()=>{ 
        if(userData?.userDataState?.cliente){
            setCreateTicketForm(prev => ({...prev, status_chamado: { value: 'Agendar', label: 'Agendar'}, tem_conflito_chamado: false}));
            setStatusChamadoDisabled(true);
        }
    }, [userData?.userDataState]);

    useEffect(() => {
        let formData = {
            data_agendamento: createTicketForm.data_agendamento || '',
            hora_agendamento: createTicketForm.hora_agendamento || '',
            logradouro_cliente: createTicketForm.endereco_cliente || '',
            bairro_cliente: createTicketForm.bairro_endereco_cliente || '',
            cidade_cliente: createTicketForm.cidade_endereco_cliente || '',
            estado_cliente: createTicketForm.estado_endereco_cliente || '',
            cep_cliente: createTicketForm.cep_endereco_cliente || '',
            numero_complemento: createTicketForm.numero_complemento || ''
        }

        for (let key in formData) {
            if (formData[key] === '') {
                setDisableBestTechnician(true);
                return;
            }
        }

        setDisableBestTechnician(false);

    }, [createTicketForm.data_agendamento, createTicketForm.hora_agendamento, createTicketForm.endereco_cliente, createTicketForm.bairro_endereco_cliente, createTicketForm.cidade_endereco_cliente, createTicketForm.estado_endereco_cliente, createTicketForm.cep_endereco_cliente, createTicketForm.numero_complemento]);

    return (
        <div>
            <Content headerTitle="Abertura de Chamados">
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='card-header'><h6><i className='fas fa-calendar-check'></i> Agendamento</h6></div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="id_ticket_cliente">ID / Ticket Cliente</label>
                                        <input type="text" className="form-control" name="id_ticket_cliente" id="id_ticket_cliente" value={createTicketForm.id_ticket_cliente} onChange={handleFormInputs} placeholder='...' />
                                    </div>
                                </div>
                                <TiposAtividadesOptions formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} fieldName='tipo_atividade' title='Tipo da Atividade' required></TiposAtividadesOptions>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="data_solicitacao">Data da Solicitação</label>
                                        <input type="date" className="form-control" name="data_solicitacao" id="data_solicitacao" value={createTicketForm.data_solicitacao} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="hora_solicitacao">Hora da Solicitação</label>
                                        <input type="time" className="form-control" name="hora_solicitacao" id="hora_solicitacao" value={createTicketForm.hora_solicitacao} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="data_desejada">Data Desejada</label>
                                        <input type="date" className="form-control" name="data_desejada" id="data_desejada" value={createTicketForm.data_desejada || ''} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="hora_desejada">Hora Desejada</label>
                                        <input type="time" className="form-control" name="hora_desejada" id="hora_desejada" value={createTicketForm.hora_desejada || ''} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <GenericSelect fieldName={'urgencia'} formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} genericOption='UrgenciaOptions' title='Urgência' required={true}></GenericSelect>
                                <GenericSelect fieldName={'prioridade'} formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} genericOption='PrioridadeOptions' title='Prioridade' required={true}></GenericSelect>
                                <If condition={hideFields('data_agendamento')}>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label htmlFor="data_agendamento">Data do Agendamento</label>
                                            <input type="date" className="form-control" name="data_agendamento" id="data_agendamento" value={createTicketForm.data_agendamento} onChange={handleFormInputs} required />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('hora_agendamento')}>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label htmlFor="hora_agendamento">Hora do Agendamento</label>
                                            <input type="time" className="form-control" name="hora_agendamento" id="hora_agendamento" value={createTicketForm.hora_agendamento} onChange={handleFormInputs} required />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('envolve_logistica')}>
                                    <EnvolveLogisticaSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} fieldName='envolve_logistica' required={true}></EnvolveLogisticaSelect>
                                </If>
                                <StatusChamadoSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} fieldName='status_chamado' required={true} isDisabled={statusChamadoDisabled} showTodos={false}></StatusChamadoSelect>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="nome_solicitante">Nome do Solicitante</label>
                                        <input type="text" className="form-control" name="nome_solicitante" id="nome_solicitante" value={createTicketForm.nome_solicitante} onChange={handleFormInputs} placeholder='...' required/>
                                    </div>
                                </div>
                            </div>
                            <If condition={hideFields('header_integradora')}>
                                <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Integradora / Contrato</h6></div>
                            </If>
                            <div className='row'>
                                <If condition={hideFields('id_integradora')}>
                                    <IntegradoraSelect
                                        formData={createTicketForm}
                                        setFormData={setCreateTicketForm}
                                        cols={[12, 4, 4, 4]}
                                        fieldName='id_integradora'
                                        advancedSetFormData={handleIntegrator}
                                        required={true}
                                    ></IntegradoraSelect>
                                </If>
                                <If condition={hideFields('id_contrato')}>
                                    <ContratosSelect
                                        formData={createTicketForm}
                                        setFormData={setCreateTicketForm}
                                        cols={[12, 4, 4, 4]}
                                        dependsOnIntegradora={true}
                                        id_integradora_string={[createTicketForm?.id_integradora?.value].toString()}
                                        fieldName='id_contrato'
                                        advancedSetFormData={handleContract}
                                    ></ContratosSelect>
                                </If>
                                <If condition={hideFields('info_adicional_circuito')}>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="info_adicional_circuito">Circuito - Contrato</label>
                                            <input type="text" className="form-control" name="info_adicional_circuito" id="info_adicional_circuito" value={createTicketForm.info_adicional_circuito} onChange={handleFormInputs} placeholder='Circuito - Contrato' required={createTicketForm?.id_integradora?.label === 'Oi S/A'}/>
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('informacao_adicional')}>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="informacao_adicional">Informação Adicional</label>
                                            <input type="text" className="form-control" name="informacao_adicional" id="informacao_adicional" value={createTicketForm.informacao_adicional} onChange={handleFormInputs} placeholder='PEP, Observações, etc...' />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('os_ba')}>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="os_ba">OS/BA</label>
                                            <input type="text" className="form-control" name="os_ba" id="os_ba" value={createTicketForm?.os_ba} onChange={handleFormInputs} placeholder='...' required/>
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <If condition={hideFields('header_cliente')}>
                                <div className='card-header'><h6><i className='fas fa-building'></i> Cliente</h6></div>
                            </If>
                            <div className="row">
                                <If condition={hideFields('nome_cliente')}>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="nome_cliente">Cliente</label>
                                            <input type="text" className="form-control" name="nome_cliente" id="nome_cliente" value={createTicketForm.nome_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('nome_contato_cliente')}>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="nome_contato_cliente">Nome Contato</label>
                                            <input type="text" className="form-control" name="nome_contato_cliente" id="nome_contato_cliente" value={createTicketForm.nome_contato_cliente} onChange={handleFormInputs} placeholder='...' />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('telefone_contato_cliente')}>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="telefone_contato_cliente">Telefone</label>
                                            <input type="tel" className="form-control" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} name="telefone_contato_cliente" id="telefone_contato_cliente" value={createTicketForm.telefone_contato_cliente} onChange={handleFormInputs} placeholder='...' />
                                        </div>
                                    </div>
                                </If>
                                <If condition={hideFields('email_contato_cliente')}>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="email_contato_cliente">E-mail</label>
                                            <input type="email" className="form-control" name="email_contato_cliente" id="email_contato_cliente" value={createTicketForm.email_contato_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <If condition={hideFields('header_cliente')}>
                                <div className='card-header'><h6><i className='fas fa-map-marker'></i> Endereço do Cliente</h6></div>
                            </If>
                            <If condition={hideFields('endereco_cliente')}>
                                <Cep
                                    formData={createTicketForm}
                                    setFormData={setCreateTicketForm}
                                    formDataNames={{
                                        bairro: 'bairro_endereco_cliente',
                                        cep: 'cep_endereco_cliente',
                                        cidade: 'cidade_endereco_cliente',
                                        complemento: 'numero_complemento',
                                        estado: 'estado_endereco_cliente',
                                        logradouro: 'endereco_cliente'
                                    }}
                                    hasComplemento={true}
                                ></Cep>
                            </If>
                            <If condition={hideFields('header_tecnico')}>
                                <div className='card-header'><h6><i className='fas fa-user'></i> Técnico | <button type='button' className='btn btn-primary' onClick={() => setOpenBestTechnician(prev => !prev)} disabled={disableBestTechnician}>{disableBestTechnician === true ? 'Preencha corretamente os campos acima para ver os melhores técnicos' : 'Ver Melhores Técnicos'}</button></h6></div>
                            </If>
                            <div className="row">
                                <If condition={hideFields('id_representante')}>
                                    <RepresentanteSelect
                                        formData={createTicketForm}
                                        setFormData={setCreateTicketForm}
                                        fieldName='id_representante'
                                        required={true}
                                        advancedSetFormData={handlePartner}
                                        cols={[12, 4, 4, 4]}
                                    ></RepresentanteSelect>
                                </If>
                                <If condition={hideFields('id_tecnico')}>
                                    <TecnicosSelect
                                        formData={createTicketForm}
                                        setFormData={setCreateTicketForm}
                                        cols={[12, 4, 4, 4]}
                                        fieldName='id_tecnico'
                                        id_representante={createTicketForm?.id_representante?.value}
                                        required={true}
                                        advancedSetFormData={handleTechnician}
                                        disponivel={1}
                                        id_contrato={createTicketForm?.id_contrato?.value || ''}
                                    ></TecnicosSelect>
                                </If>
                                {/* <ValoresRepresentanteSelect formData={createTicketForm} setFormData={setCreateTicketForm} title='Escopo' fieldName='id_representante_valor_comercial' id_representante={createTicketForm.id_representante?.value} cols={[12, 4, 4, 4]} required={true}></ValoresRepresentanteSelect> */}
                                <If condition={hideFields('km_tecnico')}>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="km_tecnico">Km do Técnico para o Contrato</label>
                                            <input type="number" className="form-control" name="km_tecnico" id="km_tecnico" value={createTicketForm.km_tecnico} onChange={handleFormInputs} placeholder='...' />
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <If condition={hideFields('header_ponto_partida')}>
                                <div className='card-header'><h6><i className='fas fa-map-marker'></i> Ponto de Partida</h6></div>
                            </If>
                            <If condition={hideFields('endereco_ponto_partida')}>
                                <div className="row">
                                    <GenericSelect formData={createTicketForm} setFormData={setCreateTicketForm} fieldName={'local_partida_tecnico'} cols={[12, 12, 12, 12]} genericOption='PontoPartidaOptions' title='Selecione o local de partida do técnico'></GenericSelect>
                                </div>
                                <Cep
                                    formData={createTicketForm}
                                    setFormData={setCreateTicketForm}
                                    formDataNames={{
                                        bairro: 'bairro_endereco_partida',
                                        cep: 'cep_endereco_partida',
                                        cidade: 'cidade_endereco_partida',
                                        complemento: 'compl_ref_obs_endereco_partida',
                                        estado: 'estado_endereco_partida',
                                        logradouro: 'logradouro_endereco_partida'
                                    }}
                                    hasComplemento={true}
                                    required={true}
                                ></Cep>
                            </If>
                            <If condition={hideFields('endereco_logistica')}>
                                <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Logística - Endereço de Envio</h6></div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className='form-group'>
                                            <label htmlFor="destinatario_remessa">Destinatário</label>
                                            <input type="text" className="form-control" name="destinatario_remessa" id="destinatario_remessa" value={createTicketForm.destinatario_remessa} onChange={handleFormInputs} placeholder='Destinatário Remessa' required />
                                        </div>
                                    </div>
                                    <ModalEnvioSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 4, 4, 4]} fieldName='modal_envio' required={true}></ModalEnvioSelect>
                                </div>
                                <Cep
                                    formData={createTicketForm}
                                    setFormData={setCreateTicketForm}
                                    formDataNames={{
                                        bairro: 'bairro_endereco_logistica',
                                        cep: 'cep_endereco_logistica',
                                        cidade: 'cidade_endereco_logistica',
                                        complemento: 'compl_ref_obs_endereco_logistica',
                                        estado: 'estado_endereco_logistica',
                                        logradouro: 'logradouro_endereco_logistica'
                                    }}
                                    hasComplemento={true}
                                    required
                                ></Cep>
                            </If>
                            <If condition={hideFields('produtos_logistica')}>
                                <ProductsTable createTicketForm={createTicketForm} setCreateTicketForm={setCreateTicketForm} productData={productData} setProductData={setProductData} num_chamado={createTicketForm?.id_ticket_cliente || ''} envolve_logistica={createTicketForm?.envolve_logistica?.value || ''} id_tecnico={createTicketForm?.id_tecnico?.value || ''}></ProductsTable>
                            </If>
                            <If condition={hideFields('descricao')}>
                                <div className='card-header'><h6><i className='fas fa-sticky-note'></i> Detalhamento</h6></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor='descricao'>Descrição do Chamado</label>
                                            <textarea className='form-control' rows={'10'} placeholder='...' id='descricao' value={createTicketForm.descricao} onChange={handleFormInputs} name='descricao' required></textarea>
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <button type='submit' className='btn btn-primary float-right' disabled={cadastrarBtnDisabled}>Cadastrar</button>
                        </form>
                    </div>
                </div>
            </Content>
            <BestTechnician isOpen={openBestTechnician} setIsOpen={() => setOpenBestTechnician(!openBestTechnician)} createTicketForm={createTicketForm} setCreateTicketForm={setCreateTicketForm}></BestTechnician>
        </div>);
}