import React, { useContext, useEffect, useState } from 'react';
import GenericSelect from '../Selects/GenericSelect';
import { getExpenseByExpenseId, updateExpenseById } from '../../services/EmployeeExpense';
import { toast } from 'react-toastify';
import CustomMessageData from '../Layout/CustomMessageData';
import { Context } from '../../App';
import If from '../Layout/If';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';
import { Button, Col, Form, FormControl, FormLabel, InputGroup, Row } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

export default function ApproveCostsModal({ isOpen = false, setModalOpen = null, costId = '', mode = 'view', setUpdateNewData }) {
    const INITIAL_STATE = {
        status_reembolso: '',
        data_reembolso: '',
        detalhes_aprovacao: '',
        url_anexo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const userData = useContext(Context);

    async function getCostDataById() {

        const toastForm = toast.loading("Carregando dados, aguarde...");
        let dataReturn = await getExpenseByExpenseId(costId);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data !== null) {
            handleSuccessToast('Dados encontrados!', 'update', toastForm);
            setFormData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                status_reembolso: { value: dataReturn?.data?.data?.status_reembolso || '', FormLabel: dataReturn?.data?.data?.status_reembolso || '' }
            }));
        } else if (dataReturn?.data?.data === null) {
            handleErrorsToast('Esta nota não existe!', {}, 'update', toastForm);
            setModalOpen(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao procurar os dados!', dataReturn?.data, 'update', toastForm);
            setModalOpen(false);
        }

    }

    async function submitForm(e) {
        e.preventDefault();

        const toastForm = toast.loading("Atualizando status, aguarde...");

        let params = {
            ...formData,
            status_reembolso: formData?.status_reembolso?.value,
        }

        let dataReturn = await updateExpenseById(costId, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastForm);
            setUpdateNewData();
            setModalOpen(false);
            userData?.setUserDataState(prevState => ({ ...prevState, atualizar_qtd_faturamento: true }));
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastForm);
        }
    }

    useEffect(() => {
        if (costId !== '') {
            getCostDataById();
        }
    }, [costId])

    return (<Dialog
        visible={isOpen}
        onHide={setModalOpen}
        header='Detalhes Nota'
        maximizable
    >
        <Row>
            <Col sm='12' md='4' className="flex align-items-center">
                <If condition={formData?.url_anexo === ''}>
                    <CustomMessageData message='Sem Imagem'></CustomMessageData>
                </If>
                <If condition={formData?.url_anexo !== ''}>
                    <img src={formData?.url_anexo || ''} alt="details_img" style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }} />
                </If>
            </Col>
            <Col sm='12' md='8'>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col sm='12'>
                            <FormLabel htmlFor="">Nome</FormLabel>
                            <FormControl type="text"  disabled={true} value={formData?.employee?.nome_tec || ''} />
                        </Col>
                        <Col sm='12' md='6'>
                            <FormLabel htmlFor="">Tipo Despesa</FormLabel>
                            <FormControl type="text" disabled={true} value={formData?.tipo_despesa || ''} />
                        </Col>
                        <Col sm='12' md='6'>
                            <FormLabel htmlFor="">Valor</FormLabel>
                            <InputGroup>
                                <InputGroupText>R$</InputGroupText>
                                <FormControl type="number" disabled={true} value={formData?.valor || '0.00'} />
                            </InputGroup>
                        </Col>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'status_reembolso'} title={'Status Reembolso'} cols={[12, 6, 6, 6]} genericOption={'StatusAprovacaoOptions'} required={true} isDisabled={mode === 'view'}></GenericSelect>
                        <If condition={(formData?.status_reembolso?.value === 'aprovado' || formData?.status_reembolso === 'aprovado')}>
                            <Col sm='12' md='6'>
                                <FormLabel htmlFor="data_reembolso">Data do Reembolso</FormLabel>
                                <FormControl type="date" name="data_reembolso" id="data_reembolso" value={formData?.data_reembolso || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} disabled={mode === 'view'} />
                            </Col>
                        </If>
                        <Col sm='12'>
                            <FormLabel htmlFor="detalhes_aprovacao">Detalhes</FormLabel>
                            <FormControl as='textarea' name="detalhes_aprovacao" id="detalhes_aprovacao" rows="10" placeholder='...' value={formData?.detalhes_aprovacao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} disabled={mode === 'view'}></FormControl>
                        </Col>
                    </Row>
                    <If condition={mode === 'edit'}>
                        <Row>
                            <Col sm='12'>
                                <Button variant='warning' type="submit" className="mt-1 float-right">Lançar Dados</Button>
                            </Col>
                        </Row>
                    </If>
                </Form>
            </Col>
        </Row>
    </Dialog>)
}