import React, { useEffect, useState } from 'react';
import { getProductByProductId, updateProductData } from '../../../../services/Product';
import { toast } from 'react-toastify';
import GedTable from '../Components/Ged/GedTable';
import Timeline from '../Components/Timeline';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import Form from '../Components/Form';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { changeShipmentStatus } from '../../../../services/Shipment';

export default function AwaitingCancelProductDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, productId = '', shipmentId = '', viewMode = 'view', setUpdateLogistic = null}) {
    const initialState = {
        nome_produto: '',
        serial: '',
        id_modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        mac: '',
        imei: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        patrimonio: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: '',
        id_localidade: '',
        numero_rma: '',
        proveniente_rma: 'nao',
        id_produto_rma: '',
        numero_reversa: '',
        serial_defeituoso: '',
        nf_retorno: '',
        rastreamento_retorno: '',
        data_reversa: '',
        data_postagem_retorno: '',
        data_retorno: '',
        data_expiracao_reversa: '',
        id_usuario_gestor: '',
        data_informada_gestao: '',
        descricao_interna: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);
    const [updateParent, setUpdateParent] = useState(false);

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            ...formCadastroProduto,
            id_modelo: formCadastroProduto.id_modelo?.value || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            valorado: formCadastroProduto.valorado?.value || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || '',
            id_produto_rma: formCadastroProduto?.id_produto_rma?.value || '',
            id_usuario_gestor: formCadastroProduto?.id_usuario_gestor?.value || ''
        }

        const toastCreateProduct = toast.loading("Alterando dados do produto, aguarde...");

        let dataReturn = await updateProductData(productId, formData);
        if (dataReturn?.data?.data?.message === 'Produto atualizado com sucesso!') {
            handleSuccessToast('Produto atualizado com sucesso!', 'update', toastCreateProduct);
            if(viewMode === 'update-internal-description'){
                setUpdateProducts();
                setUpdateParent(true);
                setUpdateLogistic(true);
                return;
            }

            if(viewMode === 'update-cancel-product'){
                cancelShipment(shipmentId);
                return;
            }

        } else if (dataReturn?.data?.message === 'Já existe um produto com esse número de série.') {
            handleErrorsToast('Esse serial já existe!', dataReturn?.data, 'update', toastCreateProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o produto!', dataReturn?.data, 'update', toastCreateProduct);
        }
    }

    async function getProductDataById() {
        const toastProducts = toast.loading('Carregando dados do Produto, aguarde...');

        let dataReturn = await getProductByProductId(productId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let product = dataReturn?.data?.data;

            let tipoTecnico = '';
            if (product?.technician?.id_representante !== null) {
                tipoTecnico = product?.technician?.id_representante === 1 ? 'CLT' : 'Parceiro'
            }
            setFormCadastroProduto(prevState => (
                {
                    ...prevState,
                    ...product,
                    kit_spare: { value: product?.kit_spare || '', label: product?.kit_spare || '' },
                    id_fabricante: { value: product?.partnumber?.manufacturer?.id || '', label: product?.partnumber?.manufacturer?.nome_fabricante || '' },
                    id_integradora: { value: product?.id_integradora || '', label: product?.contract?.integrator?.nome || '' },
                    id_contrato: { value: product?.id_contrato || '', label: product?.contract?.contrato || '' },
                    valorado: { value: product?.valorado || '', label: product?.valorado || '' },
                    local: { value: product?.local || '', label: product?.local || '' },
                    box: { value: product?.box || '', label: product?.box || '' },
                    id_corredor: { value: product?.id_corredor || '', label: product?.hallway?.valor_opcao || '' },
                    id_estante: { value: product?.id_estante || '', label: product?.stand?.valor_opcao || '' },
                    id_prateleira: { value: product?.id_prateleira || '', label: product?.shelf?.valor_opcao || '' },
                    id_representante: { value: product?.technician?.partner?.id || '', label: product?.technician?.partner?.nome_representante || '' },
                    id_tecnico: { value: product?.id_tecnico || '', label: product?.technician?.nome_tec || '' },
                    id_status_produto: { value: product?.status?.id || '', label: product?.status?.valor_opcao || '' },
                    id_modelo: { value: product?.id_modelo || '', label: product?.modelo || '' },
                    tipo_tecnico: tipoTecnico,
                    proveniente_rma: [null, undefined, ''].includes(product?.rma_product) ? 'nao' : 'sim',
                    id_produto_rma: { value: product?.rma_product?.id || '', label: `${product?.rma_product?.partnumber?.manufacturer?.nome_fabricante || ''} - ${product?.rma_product?.partnumber?.modelo || ''} - ${product?.rma_product?.nome_produto || ''} - ${product?.rma_product?.serial || ''}`},
                    id_usuario_gestor: { value: product?.manager?.id || '', label: product?.manager?.nome || ''}
                }
            ));
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando remessa, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'CANCELADA' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setUpdateParent(true);
            setUpdateLogistic(true);
            setModalOpen(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(productId)) {
            setFormCadastroProduto(initialState);
            getProductDataById();
        }
    }, [isOpen, productId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={viewMode === 'view' ? 'Visualizar Produto' : 'Atualizar Produto'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header='Detalhes do Produto'>
                    <Form formData={formCadastroProduto} setFormData={setFormCadastroProduto} mode={viewMode} submitForm={submitForm}></Form>
                </TabPanel>
                <TabPanel header='GED'>
                    <GedTable idProduto={productId} submodule='produtos' viewMode={'view'}></GedTable>
                </TabPanel>
                <TabPanel header='Linha do Tempo'>
                    <Timeline idProduto={productId} setUpdateTimeline={setUpdateParent} updateTimeline={updateParent}></Timeline>
                </TabPanel>
            </TabView>
        </Dialog >);
}