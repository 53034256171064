import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getBoxByUf } from '../../services/MamBox';
import { Col, FormLabel } from 'react-bootstrap';

export default function BoxSelect({ formData, setFormData, fieldName, uf = '', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [boxOptions, setBoxOptions] = useState([]);

    async function getBoxOptions(){
        let dataReturn = await getBoxByUf(uf);

        if(dataReturn?.data?.response === 'success'){
            let data = dataReturn?.data?.data?.map((item, idx)=>({value: item.box, label: item.box})) || [];

            setBoxOptions(data);
        }else{
            setBoxOptions([])
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if(uf !== ''){
            getBoxOptions();
        }
    }, [uf])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Estoque</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={boxOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable
            ></Select>
        </Col>
    );
}