import React, { useState } from 'react';
import ImgMessageCarousel from './ImgMessageCarousel';
import If from '../Layout/If';
import { Col, Row } from 'react-bootstrap';

export default function Message({ message, status = '', sentName = '', type = 'user', messageType = 'text', date = '', cliente = 'nao' }) {
    const [isImgCarouselOpen, setIsImgCarouselOpen] = useState(false);

    const float = type === 'user' ? 'right' : 'left'
    return (
        <Row className="m-1 max-w-full">
            <ImgMessageCarousel isOpen={isImgCarouselOpen} setIsOpen={() => { setIsImgCarouselOpen(!isImgCarouselOpen) }} imgSrc={message}></ImgMessageCarousel>
            <Col>
                <div className={`${type === 'user' ? "bg-primary" : "bg-secondary"} p-2 max-w-full`} style={{ borderRadius: '10px', float: float}}>
                    <If condition={type === 'other'}>
                        <h6 style={{ marginBottom: '0', fontSize: '0.6em'}} className={cliente === 'sim' ? 'text-warning' : ''}>{sentName}{cliente === 'sim' ? " [CLIENTE]" : ""}</h6>
                        <hr></hr>
                    </If>
                    <If condition={messageType === 'text'}>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{message}</p>
                    </If>
                    <If condition={messageType !== 'text'}>
                        <img alt='message_img' src={message} width={250} style={{ cursor: 'pointer', maxWidth: '100%', borderRadius: '5px' }} onClick={() => {
                            setIsImgCarouselOpen(!isImgCarouselOpen);
                        }}></img>
                    </If>
                    <hr style={{ padding: '0px', margin: '2px' }}></hr>
                    <p style={{ fontSize: '0.8rem', padding: '0px', margin: '0px' }} className='flex align-items-center justify-content-end'><small style={{ fontSize: '0.5rem', opacity: '0.7' }}>{date}</small><span className="badge badge-warning ml-2">{status}</span></p>
                </div>
            </Col>
        </Row>)
}