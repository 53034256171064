import React, { useEffect } from 'react';
import anychart from 'anychart';
import { useNavigate } from 'react-router-dom';

export default function AnyPie({data = []}){
        const navigate = useNavigate();
        useEffect(() => {
            function loadPieChart(){
                const container = document.getElementById('pie-container');
                const existingMap = container?.anychartPie;

                if(existingMap){
                    existingMap.dispose();
                }

                let label = anychart.standalones.label();
                label.text(data[0]?.estado);
                label.width('100%');
                label.height('100%');
                label.hAlign('center');
                label.vAlign('middle');

                const pie = anychart.pie(data);
                pie.innerRadius('30%');
                pie.center().content(label);
                pie.labels().position('outside');
                pie.tooltip().format('Técnicos: {%value}');

                pie.listen('pointClick', (e)=>{
                    let url = e.iterator.get('url');
                    if(!['', undefined, null, false].includes(url)){
                        navigate(url);
                    }
                });
                
                pie.container('pie-container');
                pie.draw();

                container.anychartPie = pie;
            }

            if(data.length > 0){
                loadPieChart();
            }
        }, [data]);
    
        return (<>
            <div id='pie-container' style={{ width: '100%', height: '1000px' }}></div>
        </>)
}   