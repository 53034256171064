import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { submitEquipmentReturn } from '../../../../services/Ticket';
import { Dialog } from 'primereact/dialog';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function AddEquipamentoRetornoModal({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    id = '',
}) {
    const initialEquipamentoRetornoState = {
        equipamento_retorno_spare_select: '',
        equipamento_retorno_spare: '',
        equipamento_retorno: '',
        serial_equipamento_retorno: '',
        id_modelo: ''
    }
    const [equipamentoRetornoData, setEquipamentoRetornoData] = useState(initialEquipamentoRetornoState);

    async function submitEquipmentReturnForm(e) {
        e.preventDefault();

        let formData = {
            nome_produto: equipamentoRetornoData?.equipamento_retorno,
            status_produto: equipamentoRetornoData?.equipamento_retorno_spare?.value,
            tipo: 'Reversa',
            serial: equipamentoRetornoData?.serial_equipamento_retorno?.replace(/^0+/, ""),
            id_modelo: equipamentoRetornoData?.id_modelo?.value || ''
        }

        const toastEquipmentReturn = toast.loading("Atualizando equipamentos de retorno, aguarde...");

        let dataReturn = await submitEquipmentReturn(id, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Equipamentos de retorno atualizados com sucesso!", "update", toastEquipmentReturn);
            updateParent();
            setEquipamentoRetornoData(initialEquipamentoRetornoState);
        } else {
            handleErrorsToast("Ocorreu um erro ao adicionar os equipamentos de retorno!", dataReturn?.data, 'update', toastEquipmentReturn);
        }
    }

    function handleFabricante(value, name) {
        setEquipamentoRetornoData(prev => ({ ...prev, [name]: value, id_modelo: '' }));
    }

    useEffect(() => {
        setEquipamentoRetornoData(initialEquipamentoRetornoState);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Produto'
            maximizable={true}
        >
            <form onSubmit={submitEquipmentReturnForm}>
                <div className='row'>
                    <GenericSelect formData={equipamentoRetornoData} fieldName={'equipamento_retorno_spare'} title='Gerar Reversa ou Spare Técnico?' setFormData={setEquipamentoRetornoData} cols={[4, 4, 4, 4]} genericOption='EquipamentoRetorno'></GenericSelect>
                    <FabricanteSelect formData={equipamentoRetornoData} setFormData={setEquipamentoRetornoData} cols={[4, 4, 4, 4]} fieldName='id_fabricante' required advancedSetFormData={handleFabricante}></FabricanteSelect>
                    <ModelosSelect formData={equipamentoRetornoData} setFormData={setEquipamentoRetornoData} cols={[4, 4, 4, 4]} fieldName='id_modelo' id_fabricante={equipamentoRetornoData?.id_fabricante?.value || ''} required></ModelosSelect>
                    <div className="col-4">
                        <label htmlFor="">Equipamento de Retorno</label>
                        <input type="text" name="equipamento_retorno" className="form-control" placeholder='Equipamento de Retorno' value={equipamentoRetornoData?.equipamento_retorno || ''} onChange={(e) => setEquipamentoRetornoData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-4">
                        <label htmlFor="">Serial Equipamento de Retorno</label>
                        <input type="text" name="serial_equipamento_retorno" className="form-control" placeholder='Serial Equipamento de Retorno' value={equipamentoRetornoData?.serial_equipamento_retorno || ''} onChange={(e) => setEquipamentoRetornoData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-4 d-flex align-items-end">
                        <button type="submit" className="btn btn-secondary">Add. Equipamento de Retorno</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}