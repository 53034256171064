import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import IntegradoraSelect from "../../../../common/Selects/IntegradoraSelect";
import ContratosSelect from "../../../../common/Selects/ContratosSelect";
import ModuleOptionsSelect from "../../../../common/Selects/ModuleOptionsSelect";
import GenericSelect from "../../../../common/Selects/GenericSelect";
import { useState } from "react";
import AsyncModelosSelect from "../../../../common/Selects/AsyncModelosSelect";
import AsyncChamadosSelect from "../../../../common/Selects/AsyncChamadosSelect";
import AsyncTecnicosSelect from "../../../../common/Selects/AsyncTecnicosSelect";

export default function Filters({ filterForm = null, setFilters }) {

    const INITIAL_STATE = {
        id_modelo: '',
        serial: '',
        id_integradora: '',
        id_contrato: '',
        id_tecnico: '',
        id_status_produto: '',
        id_chamado: '',
        rastreamento: '',
        local: '',
        id_localidade: '',
        nfe_entrada: '',
        nfe_saida: '',
        codigo_sap: '',
        circuito: '',
        os_ba: '',
        protocolo_anatel: '',
        data_inicial_agendamento: '',
        data_final_agendamento: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    function clearFields() {
        setFormData(INITIAL_STATE);
        setFilters(INITIAL_STATE);
    }

    function handleIntegradora(value, name) {
        setFormData(p => ({ ...p, [name]: value, id_contrato: '' }));
    }

    function filterProducts(e) {
        e.preventDefault();
        let params = {
            ...formData,
            id_modelo: formData?.id_modelo?.value || '',
            local: formData?.local?.value || '',
            id_integradora: formData?.id_integradora?.value || '',
            id_contrato: formData?.id_contrato?.value || '',
            id_status_produto: formData?.id_status_produto?.value || ''
        }

        filterForm(params)
    }

    return (
        <Form onSubmit={filterProducts}>
            <Row>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="data_inicial_agendamento">Data Início Aplicação</FormLabel>
                    <FormControl type="date" name="data_inicial_agendamento" id="data_inicial_agendamento" value={formData?.data_inicial_agendamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="data_final_agendamento">Data Fim Aplicação</FormLabel>
                    <FormControl type="date" name="data_final_agendamento" id="data_final_agendamento" value={formData?.data_final_agendamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                </Col>
                <AsyncModelosSelect cols={[12, 3, 3, 3]} fieldName="id_modelo" formData={formData} setFormData={setFormData} title="Nome do Produto"></AsyncModelosSelect>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="serial">Serial</FormLabel>
                    <FormControl type="text" name="serial" id="serial" value={formData?.serial || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <AsyncChamadosSelect cols={[12, 3, 3, 3]} fieldName="id_chamado" formData={formData} setFormData={setFormData} title="Número do Chamado"></AsyncChamadosSelect>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="rastreamento">Rastreamento</FormLabel>
                    <FormControl type="text" name="rastreamento" id="rastreamento" value={formData?.rastreamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="id_localidade">Id da Localidade</FormLabel>
                    <FormControl type="text" name="id_localidade" id="id_localidade" value={formData?.id_localidade || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="nfe_entrada">NFe Entrada</FormLabel>
                    <FormControl type="text" name="nfe_entrada" id="nfe_entrada" value={formData?.nfe_entrada || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="nfe_saida">NFe Saída</FormLabel>
                    <FormControl type="text" name="nfe_saida" id="nfe_saida" value={formData?.nfe_saida || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="codigo_sap">Código SAP</FormLabel>
                    <FormControl type="text" name="codigo_sap" id="codigo_sap" value={formData?.codigo_sap || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <IntegradoraSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName="id_integradora" advancedSetFormData={handleIntegradora}></IntegradoraSelect>
                <ContratosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} dependsOnIntegradora id_integradora_string={[formData?.id_integradora?.value].toString() || ''} fieldName="id_contrato" fieldTitle="Projeto"></ContratosSelect>
                <AsyncTecnicosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName="id_tecnico"></AsyncTecnicosSelect>
                <ModuleOptionsSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'}></ModuleOptionsSelect>
                <GenericSelect formData={formData} setFormData={setFormData} fieldName={'local'} cols={[12, 3, 3, 3]} genericOption="UfOptions" title="UF"></GenericSelect>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="circuito">Circuito</FormLabel>
                    <FormControl type="text" name="circuito" id="circuito" value={formData?.circuito || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="os_ba">OS/BA</FormLabel>
                    <FormControl type="text" name="os_ba" id="os_ba" value={formData?.os_ba || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12' md='3'>
                    <FormLabel htmlFor="protocolo_anatel">Protocolo Anatel</FormLabel>
                    <FormControl type="text" name="protocolo_anatel" id="protocolo_anatel" value={formData?.protocolo_anatel || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                </Col>
                <Col sm='12 mt-1'>
                    <Button variant="primary" type="submit" className="mr-1">Filtrar</Button>
                    <Button variant="warning" type="button" onClick={clearFields}>Limpar Campos</Button>
                </Col>
            </Row>
        </Form>
    )
}