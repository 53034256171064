import { Button, Col, Row } from "react-bootstrap";
import Card from "../../../common/Layout/Card";
import Content from "../../../common/Layout/Content";
import PrimeTable from "../../../common/Layout/Table/PrimeTable";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllDashboards } from "../../../services/DevMode";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import If from "../../../common/Layout/If";
import CreateMetabase from "./CreateMetabase";

export default function MetabaseManager(){
    const columns = [
        {
            id: 'nome',
            name: 'Nome'
        },
        {
            id: 'slug',
            name: 'Slug'
        },
        {
            id: 'resource_type',
            name: 'Resource Type'
        },
        {
            id: 'resource_id',
            name: 'Resource Id'
        },
        {
            id: 'ativo',
            name: 'Ativo'
        },
        {
            id: 'created_at',
            name: 'Criado em'
        },
        {
            id: 'updated_at',
            name: 'Atualizado em'
        }
    ];

    const [data, setData] = useState([]);
    const [openCreateResource, setOpenCreateResource] = useState(false);

    async function getDashboards(){
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllDashboards();

        if(dataReturn?.status === 200){
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);

            let dashboards = dataReturn?.data?.data;

            setData(dashboards);
        }else{
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastData);
            setData([]);
        }
    }

    useEffect(()=>{
        getDashboards();
    }, []);

    return (
        <Content headerTitle="Gerenciar Metabase">
            <If condition={openCreateResource}>
                <CreateMetabase isOpen={openCreateResource} setIsOpen={()=>setOpenCreateResource(!openCreateResource)} updateParent={getDashboards}></CreateMetabase>
            </If>
            <SubHeaderSpecific subHeaderConfig='dev_mode'></SubHeaderSpecific>
            <Card hideCardHeader>
                <Row>
                    <Col>
                        <Button variant='primary' type="button" onClick={() => setOpenCreateResource(!openCreateResource)}>Cadastrar Resource</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='metabase_table'
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    );
}