import React, { useContext, useEffect, useState } from 'react';
import styles from './ChatDetails.module.css';
import Modal from '../Layout/Modal';
import { createChannel, removeExternalUser, renewExpirationDate, renewPassword, updateChannelDetails, updateChannelMembers, updateChannelTicket } from '../../services/Chat';
import { Context } from '../../App';
import ContactDetails from './ContactDetails';
import Contacts from './Contacts';
import Swal from 'sweetalert2';
import ChannelData from './ChannelData';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import SearchField from './SearchField';
import useChat from '../../hooks/useChat';
import { ChatContext } from './MainChatComponent';
import { getTicketById } from '../../services/Ticket';
import { createNotification } from '../../services/Notifications';
import ExternalContacts from './ExternalContacts';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import usePermissions from '../../hooks/usePermissions';
import If from '../Layout/If';
import { handleErrorsToast, handleSuccessToast, openNewTab, onImageError } from '../../configs/GenericFunctions';
import { getTechnicianUser } from '../../services/Technician';
import { Button, Card, CardBody, CardHeader, Col, DropdownButton, DropdownItem, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { TabPanel, TabView } from 'primereact/tabview';

export default function ChatDetails({ isOpen, setModalOpen }) {

    const { getChannelImage } = useChat();
    const userData = useContext(Context);
    const { chatState, setChatState } = useContext(ChatContext);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [externalFilteredMembers, setExternalFilteredMembers] = useState([]);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isContactsListOpen, setIsContactsListOpen] = useState(false);
    const [isExternalContactListOpen, setIsExternalContactListOpen] = useState(false);
    const [isChannelDataOpen, setIsChannelDataOpen] = useState(false);
    const [contactDetailsId, setContactDetailsId] = useState('');
    const mySwal = withReactContent(Swal);

    const { checkPermission, checkAllPermissions } = usePermissions();
    const { page: pagePermissions, add_button: addButtonPermissions, contact: contactPermissions, external_contact: externalContactPermissions } = getSubComponentsPermissions('mam_talks', 'MainChat', 'ChatDetails');

    function renderChannelMembers() {
        return (filteredMembers.map((item) => {
            let cliente = item?.cliente === 1 ? 'sim' : 'nao';
            return (
                <ListGroupItem
                    action
                    className='cursor-pointer'
                    onClick={() => {
                        if (item.id === userData?.userDataState?.id_usuario && !checkPermission(contactPermissions[2])) {
                            return;
                        }
                        showChannelMemberOptionsSwal(item.id, item.name)
                    }}
                    key={item.id}
                >
                    <Row>
                        <Col className='text-collapse'>
                            <img src={item.profile_picture_url || ''} className="border-circle mr-1" alt={item.id + "_image"} width={40} height={40} onError={onImageError} />
                            <span><b>{item.name} {cliente === 'sim' ? ' [CLIENTE]' : ''}</b></span>
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        }));
    }

    function renderExternalChannelMembers() {
        return (externalFilteredMembers.map((item) => {
            let expireIn = new Date(item?.expire_in);
            let isExpired = Date.now() > expireIn;
            let splittedExpireIn = item?.expire_in?.split(" ");
            let expireInData = new Date(splittedExpireIn[0].split("-")).toLocaleDateString("pt-BR");

            return (
                <ListGroupItem
                    action
                    className={`cursor-pointer ${isExpired ? 'text-red-500' : ''}`}
                    onClick={() => {
                        if (checkAllPermissions(externalContactPermissions)) {
                            showChannelExternalMembersOptionsSwal(item.id, item.name)
                        }
                    }
                    }
                    key={item.id}
                >
                    <Row>
                        <Col className='text-collapse'>
                            <img src={''} className="border-circle mr-1" alt={item.id + "_image"} width={40} height={40} onError={onImageError} />
                            <span><b>{item.name}</b> - {item.email} - {isExpired ? 'Acesso Expirado' : `Expira em: ${expireInData}`}</span>
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        }));
    }

    async function showAddNewMemberToChatSwal(members) {
        let swal = await mySwal.fire({
            text: 'Deseja adicionar os membros selecionados ao grupo?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
        });

        if (swal.isConfirmed) {
            addNewMemberToChat(members);
        }
    }

    async function addNewMemberToChat(members) {
        const toastMembers = toast.loading("Adicionando membros, aguarde...");

        let formData = {
            action: 'add',
            user_id: members.map((item, idx) => {
                return item.id
            })
        }

        let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Membros adicionados com sucesso!', 'update', toastMembers);
            delete dataReturn?.data?.data?.external_users;

            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                members: dataReturn?.data?.data?.users?.map((item, idx) => {
                    if (item.id === userData?.userDataState?.id_usuario) {
                        return { ...item, name: 'Eu' };
                    }
                    return item;
                })
            }))
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar os dados!', dataReturn?.data, 'update', toastMembers);
        }

        setIsContactsListOpen(!isContactsListOpen);
    }

    async function createNewChannel(id) {
        let formData = {
            type: 'channel',
            users: [userData?.userDataState?.id_usuario, id],
            ticket_id: chatState?.ticketId || null,
            channel_description: '',
            channel_name: ''
        }

        const toastChannel = toast.loading("Criando canal, aguarde...");

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Canal criado!', 'update', toastChannel);
            setChatState(prevState => ({
                ...prevState,
                channelId: dataReturn?.data?.data?._id
            }))
            setModalOpen(false);
            mySwal.close();
        } else {
            handleErrorsToast('Ocorreu um erro ao criar o canal!', dataReturn?.data, 'update', toastChannel);
        }
    }

    async function removeMemberFromChat(id, type = 'other') {
        const toastMembers = toast.loading("Removendo membro, aguarde...");

        let formData = {
            action: 'remove',
            user_id: [id]
        }

        let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Membro removido com sucesso!', 'update', toastMembers);
            mySwal.close();

            if (type === 'other') {
                delete dataReturn?.data?.data?.external_users;
                setChatState((prevState) => ({
                    ...prevState,
                    channelData: {
                        ...prevState.channelData,
                        ...dataReturn?.data?.data
                    },
                    members: dataReturn?.data?.data?.users?.map((item, idx) => {
                        if (item.id === userData?.userDataState?.id_usuario) {
                            return { ...item, name: 'Eu' };
                        }
                        return item;
                    })
                }))
            } else {
                setChatState(prevState => ({
                    ...prevState,
                    channelId: ''
                }));
            }
        } else {
            handleErrorsToast('Ocorreu um erro ao remover membro!', dataReturn?.data, 'update', toastMembers);
        }
    }

    async function removeExternalMembersFromChat(id) {
        const toastRemove = toast.loading("Removendo membro, aguarde...");

        let params = {
            user_id: [id]
        };

        let dataReturn = await removeExternalUser(chatState?.channelId, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Membro removido com sucesso!', 'update', toastRemove);

            delete dataReturn?.data?.data?.users;
            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                externalMembers: dataReturn?.data?.data?.external_users
            }));
            mySwal.close();
        } else {
            handleErrorsToast('Ocorreu um erro ao remover o membro!', dataReturn?.data, 'update', toastRemove);
        }
    }

    async function changeChatDetails(details) {
        const toastChangeDetails = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updateChannelDetails(chatState?.channelId, details);
        let ticketDataReturn;
        if (details?.ticket_id?.value !== chatState?.channelData?.ticket_id) {
            ticketDataReturn = await updateChannelTicket(chatState?.channelId, {
                ticket_id: details?.ticket_id?.value
            })
        }

        if (dataReturn?.data?.response === 'success' || ticketDataReturn?.data?.response === 'success') {
            let updatedData = ticketDataReturn?.data?.response === 'success' ? ticketDataReturn?.data?.data : dataReturn?.data?.data;
            handleSuccessToast('Dados atualizados!', 'update', toastChangeDetails);
            setIsChannelDataOpen(!isChannelDataOpen);
            setChatState(prevState => ({
                ...prevState,
                ...updatedData,
            }))
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastChangeDetails);
        }
    }

    async function showChannelMemberOptionsSwal(id, name) {
        await mySwal.fire({
            title: 'O que deseja fazer?',
            html: (<>
                <If condition={id !== userData?.userDataState?.id_usuario}>
                    <Row>
                        <Col xs='12' className="mt-1">
                            <Button type="button" variant='primary' className='w-100' onClick={() => showContactDetails(id)}>Ver Dados do {name}</Button>
                        </Col>
                        <If condition={checkPermission(contactPermissions[1])}>
                            <Col xs='12' className="mt-1">
                                <Button type="button" variant='primary' className='w-100' onClick={() => createNewChannel(id)}>Criar novo canal com {name}</Button>
                            </Col>
                        </If>
                        <If condition={checkPermission(contactPermissions[0])}>
                            <Col xs='12' className="mt-1">
                                <Button type="button" variant='danger' className='w-100' onClick={() => removeMemberFromChat(id)}>Remover {name} do Grupo</Button>
                            </Col>
                        </If>
                    </Row>
                </If>
                <If condition={id === userData?.userDataState?.id_usuario}>
                    <Row>
                        <If condition={checkPermission(contactPermissions[2])}>
                            <Col className="mt-1">
                                <Button type="button" variant='danger' className='w-100' onClick={() => removeMemberFromChat(id, 'myself')}>Sair do Grupo</Button>
                            </Col>
                        </If>
                    </Row>
                </If>

            </>),
            showConfirmButton: false
        })
    }

    async function showChannelExternalMembersOptionsSwal(id, name) {
        await mySwal.fire({
            title: 'O que deseja fazer?',
            html: (<>
                <Row>
                    <If condition={checkPermission(externalContactPermissions[1])}>
                        <Col xs='12' className="mt-1">
                            <Button type="button" variant='primary' className="w-100" onClick={() => renewPasswordByUserId(id)}>Renovar Senha</Button>
                        </Col>
                    </If>
                    <If condition={checkPermission(externalContactPermissions[2])}>
                        <Col xs='12' className="mt-1">
                            <Button type="button" variant='primary' className="w-100" onClick={() => renewExpireDateByUserId(id)}>Renovar Data de Expiração</Button>
                        </Col>
                    </If>
                    <If condition={checkPermission(externalContactPermissions[0])}>
                        <Col xs='12' className="mt-1">
                            <Button type="button" variant='danger' className="w-100" onClick={() => removeExternalMembersFromChat(id)}>Remover {name} do Grupo</Button>
                        </Col>
                    </If>
                </Row>
            </>),
            showConfirmButton: false
        })
    }

    async function renewPasswordByUserId(id) {
        const toastPassword = toast.loading("Renovando senha, aguarde...");

        let dataReturn = await renewPassword(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Senha renovada com sucesso!', 'update', toastPassword);
            mySwal.close();
        } else {
            handleErrorsToast('Ocorreu um erro ao renovar a senha!', dataReturn?.data, 'update', toastPassword);
        }
    }

    async function renewExpireDateByUserId(id) {
        const toastExpireDate = toast.loading('Renovando data de expiração, aguarde...');

        let dataReturn = await renewExpirationDate(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Data de expiração renovada com sucesso!', 'update', toastExpireDate);
            setChatState(prevState => ({ ...prevState, updateMode: 'externalMembers', updateChat: true }));
            mySwal.close();
        } else {
            handleErrorsToast('Ocorreu um erro ao renovar a data de expiração!', dataReturn?.data, 'update', toastExpireDate);
        }
    }

    function showContactDetails(id) {
        mySwal.close();
        setContactDetailsId(id);
        setIsContactOpen(prevState => !prevState);
    }

    function renderChannelImage() {
        let channelImg = getChannelImage(chatState?.channelData?.users || [], 100, 49);

        return (
            <div className={`${chatState?.channelData?.users?.length < 3 ? '' : 'flex-wrap'} ${styles.chat_image}`}>
                {channelImg}
            </div>)
    }

    function searchMembers(search = '') {
        let filteredMembers = chatState?.members;
        if (search !== '') {
            filteredMembers = chatState?.members?.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        }
        setFilteredMembers(filteredMembers);
    }

    function searchExternalMembers(search = '') {
        let externalFilteredMembers = chatState?.externalMembers;

        if (search !== '') {
            externalFilteredMembers = chatState?.externalMembers?.filter(item => (item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase())));
        }
        setExternalFilteredMembers(externalFilteredMembers);
    }

    async function scalateMemberToChannel(type) {
        const toastMembers = toast.loading("Adicionando membros, aguarde...");
        let ticketId = chatState?.ticketId || chatState?.channelData?.ticket_id;
        let ticketData = await getTicketById(ticketId);

        if (ticketData?.data?.response === 'success') {
            let data = ticketData?.data?.data;

            if (type === 'technician_responsible_user') {
                let dataReturn = await getTechnicianUser(data?.technician?.id);

                if (dataReturn?.status === 200) {
                    data[type] = dataReturn?.data?.data;
                } else {
                    handleErrorsToast('Este técnico não tem usuário!', {}, 'update', toastMembers);
                    return;
                }
            }

            let membersId = chatState?.members?.map((item) => item.id);


            if ([undefined, null, ''].includes(data?.[type]?.id)) {
                handleErrorsToast('Responsável não encontrado!', {}, 'update', toastMembers);
                return;
            }


            if (data?.[type]?.id === userData?.userDataState?.id_usuario) {
                handleErrorsToast('Você não pode adicionar a si mesmo!', {}, 'update', toastMembers);
                return;
            }

            if (membersId.includes(data?.[type]?.id)) {
                let formData = {
                    type: 'danger',
                    users: [data?.[type]?.id],
                    module_id: data?.id,
                    module: 'ticket',
                    event: 'help'
                }

                await createNotification(formData);
                handleErrorsToast('Este usuário já está no canal', {}, 'update', toastMembers);
                return;
            }


            let formData = {
                action: 'add',
                user_id: [data?.[type]?.id]
            }

            let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

            if (dataReturn?.data?.response === 'success') {
                setChatState((prevState) => ({
                    ...prevState,
                    channelData: {
                        ...prevState.channelData,
                        ...dataReturn?.data?.data
                    },
                    members: dataReturn?.data?.data?.users?.map((item, idx) => {
                        if (item.id === userData?.userDataState?.id_usuario) {
                            return { ...item, name: 'Eu' };
                        }
                        return item;
                    })
                }))

                let formData = {
                    type: 'danger',
                    users: [data?.[type]?.id],
                    module_id: data?.id,
                    module: 'ticket',
                    event: 'help'
                }

                await createNotification(formData);
                handleSuccessToast('Membro adicionado com sucesso!', 'update', toastMembers);
                return;
            } else {
                handleErrorsToast('Ocorreu um erro ao adicionar membro!', dataReturn?.data, 'update', toastMembers);
                return;
            }

        } else {
            handleErrorsToast('Ocorreu um erro ao procurar os dados do chamado!', ticketData?.data, 'update', toastMembers);
            return;
        }
    }

    useEffect(() => {
        searchMembers();
    }, [chatState?.members]);

    useEffect(() => {
        searchExternalMembers();
    }, [chatState?.externalMembers]);

    return (
        <Modal
            title={'Dados do Canal'}
            isOpen={isOpen}
            setModalOpen={setModalOpen}
        >
            <ContactDetails
                isOpen={isContactOpen}
                setModalOpen={() => setIsContactOpen(!isContactOpen)}
                contactId={contactDetailsId}
            ></ContactDetails>
            <Contacts
                isOpen={isContactsListOpen}
                setModalOpen={() => setIsContactsListOpen(!isContactsListOpen)}
                updateChannel={true}
                title='Adicionar Membro ao Grupo'
                setMembers={showAddNewMemberToChatSwal}
            ></Contacts>
            <ExternalContacts
                title='Adicionar Participante Externo'
                isOpen={isExternalContactListOpen}
                setModalOpen={() => setIsExternalContactListOpen(!isExternalContactListOpen)}
            ></ExternalContacts>
            <ChannelData
                isOpen={isChannelDataOpen}
                setModalOpen={() => setIsChannelDataOpen(!isChannelDataOpen)}
                title='Alterar Dados do Canal'
                updateChannel={true}
                changeChannelData={changeChatDetails}
            ></ChannelData>
            <div style={{ minWidth: '50vw' }}>
                <Card style={{ backgroundColor: '#F4F6F9' }}>
                    <CardHeader className="flex justify-content-center">
                        {renderChannelImage()}
                    </CardHeader>
                    <CardBody className="text-center">
                        <h6 className='inline'>{chatState?.channelData?.worked_channel_name}</h6>
                        <If condition={checkPermission(pagePermissions[0])}>
                            <Button type="button" variant='success' className="float-right" onClick={() => setIsChannelDataOpen(!isChannelDataOpen)}><i className='fas fa-pen'></i></Button>
                        </If>
                    </CardBody>
                </Card>
                <Card style={{ backgroundColor: '#F4F6F9' }}>
                    <CardHeader className="text-center">
                        <h6 className='d-inline'>Detalhes</h6>
                        <If condition={checkPermission(pagePermissions[0])}>
                            <Button type="button" variant='success' className="float-right" onClick={() => setIsChannelDataOpen(!isChannelDataOpen)}><i className='fas fa-pen'></i></Button>
                        </If>
                    </CardHeader>
                    <CardBody>
                        <p><b>Criado em: </b>{chatState?.channelData?.created_at}</p>
                        <p><b>Atualizado em: </b>{chatState?.channelData?.updated_at}</p>
                        <p className='flex'><b>Chamado Associado: </b>{chatState?.channelData?.ticket_id ? <Button variant='link' className='ml-1 p-0' onClick={() => openNewTab(`/chamados/detalhes-chamado/${chatState?.channelData?.ticket_id}`)}>{chatState?.channelData?.ticket_number}</Button> : 'Não Associado'}</p>
                        <p><b>Descrição: </b>{chatState?.channelData?.channel_description || 'Sem Descrição'}</p>
                    </CardBody>
                </Card>
                <Card style={{ backgroundColor: '#F4F6F9' }}>
                    <CardHeader className="text-center">
                        <h6 className='d-inline'>Participantes: {(chatState?.channelData?.users?.length + chatState?.channelData?.external_users?.length) || 0}</h6>
                        <If condition={checkAllPermissions(addButtonPermissions)}>
                            <DropdownButton title={
                                <span>
                                    <i className='fas fa-plus'></i>
                                </span>
                            } variant='success' className='float-right'>
                                <If condition={checkPermission(addButtonPermissions[0])}>
                                    <DropdownItem onClick={() => setIsContactsListOpen(!isContactsListOpen)}>Adicionar Participante</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[1])}>
                                    <DropdownItem onClick={() => setIsExternalContactListOpen(!isExternalContactListOpen)}>Adicionar Participante Externo</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[2])}>
                                    <DropdownItem className={`${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "hidden" : "block"}`} onClick={() => scalateMemberToChannel('user')}>Escalar Acionamento Neste Chat</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[3])}>
                                    <DropdownItem className={`${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "hidden" : "block"}`} onClick={() => scalateMemberToChannel('support_responsible_user')}>Escalar Suporte Neste Chat</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[4])}>
                                    <DropdownItem className={`${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "hidden" : "block"}`} onClick={() => scalateMemberToChannel('partnership_responsible_user')}>Escalar Parceria Neste Chat</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[5])}>
                                    <DropdownItem className={`${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "hidden" : "block"}`} onClick={() => scalateMemberToChannel('ticket_responsible_user')}>Escalar Resp. Chamado Neste Chat</DropdownItem>
                                </If>
                                <If condition={checkPermission(addButtonPermissions[6])}>
                                    <DropdownItem className={`${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "hidden" : "block"}`} onClick={() => scalateMemberToChannel('technician_responsible_user')}>Escalar Técnico Neste Chat</DropdownItem>
                                </If>
                            </DropdownButton>
                        </If>
                    </CardHeader>
                    <CardBody>
                        <TabView pt={
                            {
                                panelContainer: {
                                    style: {
                                        padding: '0'
                                    }
                                }
                            }
                        }>
                            <TabPanel header={`Participantes MAMINFO - ${chatState?.channelData?.users?.length}`} pt={
                                {
                                    content: {
                                        style: {
                                            padding: '0',
                                            backgroundColor: '#F4F6F9'
                                        }
                                    }
                                }
                            }>
                                <Row className='mt-1'>
                                    <Col>
                                        <SearchField placeholder='Pesquise o nome do contato' searchFunction={searchMembers}></SearchField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {
                                                renderChannelMembers()
                                            }
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </TabPanel>
                            <TabPanel header={`Participantes Externos - ${chatState?.channelData?.external_users?.length}`}
                                pt={
                                    {
                                        content: {
                                            style: {
                                                padding: '0',
                                                backgroundColor: '#F4F6F9'
                                            }
                                        }
                                    }
                                }>
                                <Row className='mt-1'>
                                    <Col>
                                        <SearchField placeholder='Pesquise o nome do contato' searchFunction={searchExternalMembers}></SearchField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {
                                                renderExternalChannelMembers()
                                            }
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </TabPanel>
                        </TabView>
                    </CardBody>
                </Card>
            </div>
        </Modal >
    )
}