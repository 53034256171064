import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getLogisticsInvolvementValues } from '../../services/Ticket';
import { Col, FormLabel } from 'react-bootstrap';

export default function EnvolveLogisticaSelect({ formData, setFormData, fieldName = 'envolve_logistica', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, filterOptions = ''}) {
    const [envolveLogisticaOptions, setEnvolveLogisticaOptions] = useState([]);

    const getLogisticsInvolvement = async () => {
        let dataReturn = await getLogisticsInvolvementValues();

        if (dataReturn?.data?.response === 'success') {
            let logisticsInvolvementValues = dataReturn?.data?.data;

            let logisticsInvolvementArray = logisticsInvolvementValues.map((item, idx) => {
                return ({ value: item.descricao, label: item.descricao });
            });

            logisticsInvolvementArray = filterOptions !== '' ? logisticsInvolvementArray.filter(item => !filterOptions.split(',').includes(item.value)) : logisticsInvolvementArray;

            setEnvolveLogisticaOptions(logisticsInvolvementArray);
        } else {
            setEnvolveLogisticaOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getLogisticsInvolvement();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Envolve Logística (Envio ou Retirada de eq.)</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={envolveLogisticaOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}