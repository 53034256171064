import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getShippingModeData } from '../../services/Ticket';
import { Col, FormLabel } from 'react-bootstrap';

export default function ModalEnvioSelect({ formData, setFormData, fieldName = 'modal_envio', cols = [12, 12, 12, 12], isMulti = false, isDisabled = false, required = false }) {
    const [shippingModeOptions, setShippingModeOptions] = useState([]);

    async function getShippingMode() {
        let dataReturn = await getShippingModeData();

        if (dataReturn?.data?.response === 'success') {
            let shippingMode = dataReturn.data.data;
            let shippingModeArray = shippingMode.map((item, idx) => {
                return ({ value: item.modal, label: item.modal });
            });

            setShippingModeOptions(shippingModeArray);
        } else {
            setShippingModeOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getShippingMode();
    }, [])

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Modal de Envio</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={shippingModeOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}