import React, { useContext, useEffect, useState } from 'react';
import Content from '../../common/Layout/Content';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../common/Layout/Card';
import { toast } from 'react-toastify';
import { enableDisableUser, getAllUsers } from '../../services/Users';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Context } from '../../App';
import If from '../../common/Layout/If';
import PrimeTable from '../../common/Layout/Table/PrimeTable';
import usePermissions from '../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';

export default function ShowUsers() {

    const { status } = useParams();
    const userData = useContext(Context);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_usuarios', 'ShowUsers', 'ShowUsers');

    const columns = [
        {
            id: 'action_gerenciar_usuarios',
            name: 'Ações',
            body: (row) => (
                <If condition={userData?.userDataState?.login !== row.login_gerenciar_usuarios}>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                        <div className="dropdown-menu">
                            <If condition={checkPermission(actionPermissions[0])}>
                                <Link to={`/usuarios/detalhes/${row.id_gerenciar_usuarios}`} className='dropdown-item'>Visualizar</Link>
                            </If>
                            <If condition={checkPermission(actionPermissions[1])}>
                                <button type='button' onClick={() => showChangeContractStatusSwal(row?.id_gerenciar_usuarios, row?.ativo_gerenciar_usuarios)} className='dropdown-item'>{row?.ativo_gerenciar_usuarios === 'Ativo' ? 'Desativar' : 'Ativar'}</button>
                            </If>
                        </div>
                    </div>
                </If>),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_completo_gerenciar_usuarios',
            name: 'Nome Completo'
        },
        {
            id: 'email_gerenciar_usuarios',
            name: 'Email'
        },
        {
            id: 'telefone_celular_gerenciar_usuarios',
            name: 'Telefone Celular'
        },
        {
            id: 'login_gerenciar_usuarios',
            name: 'Login'
        },
        {
            id: 'grupos_acesso_gerenciar_usuarios',
            name: 'Grupos de Acesso'
        },
        {
            id: 'observacao_gerenciar_usuarios',
            name: 'Observação'
        },
        {
            id: 'data_registro_gerenciar_usuarios',
            name: 'Data Registro'
        },
        {
            id: 'cliente_usuarios',
            name: 'Cliente?'
        },
        {
            id: 'ativo_gerenciar_usuarios',
            name: 'Usuário Ativo'
        },
        {
            id: 'status_gerenciar_usuarios',
            name: 'Status'
        }
    ];

    const [data, setData] = useState([]);

    async function getUsers(status) {
        const toastUsers = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllUsers(status);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados encontrados!', 'update', toastUsers);
            let data = dataReturn?.data?.data.map((item, idx) => {
                let gruposAcesso = item?.groups?.map((item, idx) => {
                    return item?.descricao;
                }) || [];

                gruposAcesso = gruposAcesso.join(", ");
                let ativo_gerenciar_usuarios = item?.ativo === 1 ? 'Ativo' : 'Inativo';
                return ({
                    id_gerenciar_usuarios: item?.id || '',
                    nome_completo_gerenciar_usuarios: item?.nome || '',
                    email_gerenciar_usuarios: item?.email || '',
                    telefone_celular_gerenciar_usuarios: item?.telefone || '',
                    login_gerenciar_usuarios: item?.login || '',
                    grupos_acesso_gerenciar_usuarios: gruposAcesso || '',
                    observacao_gerenciar_usuarios: item?.observacao || '',
                    data_registro_gerenciar_usuarios: item?.data_registro || '',
                    ativo_gerenciar_usuarios: ativo_gerenciar_usuarios,
                    status_gerenciar_usuarios: item?.status_colaborador || '',
                    cliente_usuarios: item?.cliente === 1 ? 'SIM' : 'NÃO'
                })
            });

            setData(data);
        } else {
            handleErrorsToast('Sem dados!', dataReturn?.data, 'update', toastUsers);
            setData([]);
        }
    }

    async function showChangeContractStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status deste usuário?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeStatus(id, status);
        }
    }

    async function changeStatus(id, status) {
        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await enableDisableUser(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Status alterado com sucesso!", 'update', toastChangeStatus);
            let statusData = status === 'Ativo' ? 1 : 0;
            getUsers(statusData);
        } else {
            handleErrorsToast('Ocorreu um erro ao alterar o status!', dataReturn?.data, 'update', toastChangeStatus);
        }
    }

    useEffect(() => {
        let statusData = status === 'inativo' ? 0 : 1
        getUsers(statusData);
    }, [status]);

    return (
        <Content headerTitle='Gerenciar Usuários'>
            <SubHeaderSpecific subHeaderConfig='mam_usuarios'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <If condition={checkPermission(pagePermissions[0])}>
                        <div className="col-12">
                            <Link to='/usuarios/dados/ativo' className='btn btn-success'>Usuários Ativos</Link>
                            <Link to='/usuarios/dados/inativo' className='btn btn-warning m-1'>Usuários Inativos</Link>
                        </div>
                    </If>
                    <div className="col-12">
                        <PrimeTable
                            rows={data}
                            columns={columns}
                            id='gerenciar_usuarios_table'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    );
}