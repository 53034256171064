import { Col, FormControl, FormLabel, Row, Form, Button } from "react-bootstrap";
import Card from "../../../common/Layout/Card";
import Content from "../../../common/Layout/Content";
import SubHeaderSpecific from "../../../common/Layout/SubHeader/SubHeaderSpecific";
import PrimeTable from "../../../common/Layout/Table/PrimeTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast, openNewTab } from "../../../configs/GenericFunctions";
import { getAllShipments } from "../../../services/Shipment";
import AsyncChamadosSelect from "../../../common/Selects/AsyncChamadosSelect";
import ModuleOptionsSelect from "../../../common/Selects/ModuleOptionsSelect";

export default function LogisticReportOi() {
    const INITIAL_STATE = {
        data_inicial_agendamento: '',
        data_final_agendamento: '',
        codigo_sap: '',
        os_ba: '',
        info_adicional_circuito: '',
        id_integradora: '50',
        id_chamado: '',
        id_status_produto: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);

    const columns = [
        {
            id: 'status_produto',
            name: 'Status Produto',
        },
        {
            id: 'codigo_sap_produto',
            name: 'Código SAP',
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto'
        },
        {
            id: 'serial_produto',
            name: 'Serial'
        },
        {
            id: 'nfe_entrada_produto',
            name: 'NFe Entrada'
        },
        {
            id: 'local_produto',
            name: 'Local'
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'numero_chamado_produto',
            name: 'Número do Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado)}>{row.numero_chamado_produto}</Button>)
        },
        {
            id: 'os_ba_produto',
            name: 'OS/BA'
        },
        {
            id: 'circuito_produto',
            name: 'Circuito'
        },
        {
            id: 'data_aplicacao_produto',
            name: 'Data Aplicação'
        },
        {
            id: 'protocolo_anatel_produto',
            name: 'Protocolo Anatel'
        }
    ]

    function clearFields() {
        setFormData(INITIAL_STATE);
    }

    async function filterForm(e) {
        e.preventDefault();
        let params = {
            ...formData,
            id_chamado: formData?.id_chamado?.value || '',
            id_status_produto: formData?.id_status_produto?.value || ''
        }
        getOiShipments(1, 100, params);
        setPerPage(100);
        setPage(0);
    }

    async function getOiShipments(page = 1, perPage = 100, params = {id_integradora: '50'}) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllShipments(page, perPage, params);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];
            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                id_chamado: item?.ticket?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.partnumber?.modelo || '',
                serial_produto: item?.product?.serial || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || '',
                status_produto: item?.product?.status?.valor_opcao || '',
                codigo_sap_produto: item?.product?.codigo_sap || '',
                nfe_entrada_produto: item?.product?.nfe_entrada || '',
                local_produto: item?.product?.local || '',
                data_aplicacao_produto: item?.ticket?.data_agendamento || '',
                protocolo_anatel_produto: item?.ticket?.protocolo_anatel || '',
                os_ba_produto: item?.ticket?.os_ba || '',
                circuito_produto: item?.ticket?.info_adicional_circuito || '',
                numero_chamado_produto: item?.ticket?.num_chamado || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    const handlePaginationPrime = (newPage) => {
        let params = {
            ...formData,
            id_chamado: formData?.id_chamado?.value || ''
        }
        setPage(newPage);
        getOiShipments(newPage + 1, perPage, params);
    }

    function handleRowPerPagePrime(rows) {
        let params = {
            ...formData,
            id_chamado: formData?.id_chamado?.value || ''
        }
        setPerPage(rows);
        setPage(0);
        getOiShipments(1, rows, params);
    }

    useEffect(() => {
        let params = {
            ...formData,
            id_chamado: formData?.id_chamado?.value || ''
        }
        getOiShipments(page + 1, perPage, params);
    }, []);

    return (
        <Content headerTitle="Relatório Oi">
            <SubHeaderSpecific subHeaderConfig="mam_logistica_relatorios"></SubHeaderSpecific>
            <Card title="Relatório Oi">
                <Row>
                    <Col sm='12'>
                        <Form onSubmit={filterForm}>
                            <Row>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="data_inicial_agendamento">Data Início</FormLabel>
                                    <FormControl type="date" name="data_inicial_agendamento" id="data_inicial_agendamento" value={formData?.data_inicial_agendamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="data_final_agendamento">Data Fim</FormLabel>
                                    <FormControl type="date" name="data_final_agendamento" id="data_final_agendamento" value={formData?.data_final_agendamento || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))}></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="os_ba">OS/BA Atividade</FormLabel>
                                    <FormControl type="text" name="os_ba" id="os_ba" value={formData?.os_ba || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="codigo_sap">Código SAP</FormLabel>
                                    <FormControl type="text" name="codigo_sap" id="codigo_sap" value={formData?.codigo_sap || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <Col sm='12' md='3'>
                                    <FormLabel htmlFor="info_adicional_circuito">Circuito</FormLabel>
                                    <FormControl type="text" name="info_adicional_circuito" id="info_adicional_circuito" value={formData?.info_adicional_circuito || ''} onChange={(e) => setFormData(p => ({ ...p, [e.target.name]: e.target.value }))} placeholder="..."></FormControl>
                                </Col>
                                <ModuleOptionsSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'}></ModuleOptionsSelect>
                                <AsyncChamadosSelect formData={formData} setFormData={setFormData} cols={[12, 3, 3, 3]} fieldName='id_chamado' title='Número do Chamado'></AsyncChamadosSelect>
                                <Col sm='12' className="mt-1">
                                    <Button variant="primary" type="submit" className="mr-1">Filtrar</Button>
                                    <Button variant="warning" type="button" onClick={clearFields}>Limpar Campos</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm='12'>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            id='relatorio_oi_table'
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            reorderableColumns
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
        </Content>
    )
}