import interceptorInstance from "./Interceptor";

export async function getAllTags(){
    try{
        let data = await interceptorInstance.get('tags');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createTag(params){
    try{
        let data = await interceptorInstance.post('tags', params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function removeTag(id){
    try{
        let data = await interceptorInstance.delete(`tags/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getTagDataById(id){
    try{    
        let data = await interceptorInstance.get(`tags/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateTag(id, params){
    try{
        let data = await interceptorInstance.put(`tags/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function attachTag(id, params){
    try{
        let data = await interceptorInstance.post(`tags/${id}/attach-tickets`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function detachTag(id, ticketId){
    try{
        let data = await interceptorInstance.delete(`tags/${id}/detach-ticket/${ticketId}`);
        return data;
    }catch(e){
        return e.response;
    }
}