import React, { useEffect, useState } from 'react';
import If from './If';
import { Card, CardBody } from 'react-bootstrap';

export default function Modal({ isOpen, setModalOpen, title = '', customStyle = false, allowClose = true, children }) {
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 768);

    const MODAL_STYLE = customStyle || {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isTablet ? '' : '90vw',
        maxWidth: '90vw',
        height: 'auto',
        maxHeight: '90vh', // Adjust this value as needed
    }

    const BACKGROUND_STYLE = {
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgb(0,0,0, 0.7)',
        zIndex: '5000'
    }

    const CARD_BODY_STYLE = {
        maxHeight: MODAL_STYLE?.maxHeight || '90vh', // Adjust this value to leave space for header and footer
        overflow: 'auto',
    }

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isOpen) {
        return (
            <div style={BACKGROUND_STYLE}>
                <Card style={MODAL_STYLE}>
                    <h5 className='card-header text-collapse'>{title}
                        <If condition={allowClose}>
                            <span className='float-right' style={{ cursor: 'pointer', opacity: '0.5' }} onClick={setModalOpen}>x</span>
                        </If>
                    </h5>
                    <CardBody style={CARD_BODY_STYLE}>
                        {children}
                    </CardBody>
                </Card>
            </div>
        )
    }

    return null;
}