import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { createRentedVehicle } from '../../../../../services/Employee';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function CreateVehicle({
    isOpen = false,
    setIsOpen = null,
    technicianId = '',
    setUpdateParent = null
}) {
    const INITIAL_STATE = {
        veiculo_locado: '',
        modelo_veiculo_locado: '',
        placa_veiculo_locado: '',
        cor_veiculo_locado: '',
        data_locacao_locado: '',
        data_expiracao_locado: '',
        autonomia_locado: ''
    }

    const [formRentedVehicles, setFormRentedVehicles] = useState(INITIAL_STATE);

    async function createVehicle(e) {
        e.preventDefault();

        let formData = {
            id_tecnico: technicianId,
            veiculo: formRentedVehicles?.veiculo_locado,
            modelo_veiculo: formRentedVehicles?.modelo_veiculo_locado,
            placa_veiculo: formRentedVehicles?.placa_veiculo_locado,
            cor_veiculo: formRentedVehicles?.cor_veiculo_locado,
            data_locacao: formRentedVehicles?.data_locacao_locado,
            data_expiracao_locacao: formRentedVehicles?.data_expiracao_locado,
            autonomia: formRentedVehicles?.autonomia_locado
        }

        if (new Date(formRentedVehicles?.data_locacao_locado) > new Date(formRentedVehicles?.data_expiracao_locado)) {
            handleErrorsToast('A data de locação não pode ser maior que a data de expiração!');
            return;
        }

        const toastVehicle = toast.loading("Cadastrando veículo, aguarde...");

        let dataReturn = await createRentedVehicle(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Veículo cadastrado com sucesso!', 'update', toastVehicle);
            setFormRentedVehicles(INITIAL_STATE);
            setUpdateParent();
        } else if (dataReturn?.data?.message === 'Você já tem um veículo locado!') {
            handleErrorsToast('', dataReturn?.data, 'update', toastVehicle);
        } else {
            handleErrorsToast('Ocorreu um erro ao cadastrar o veículo!', dataReturn?.data, 'update', toastVehicle);
        }
    }

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Itens de Comodato'
            maximizable={true}
        >
            <form onSubmit={createVehicle}>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="veiculo_locado">Veículo</label>
                        <input type="text" name="veiculo_locado" id="veiculo_locado" className="form-control" value={formRentedVehicles?.veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="modelo_veiculo">Modelo</label>
                        <input type="text" name="modelo_veiculo_locado" id="modelo_veiculo_locado" className="form-control" value={formRentedVehicles?.modelo_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="cor_veiculo_locado">Cor</label>
                        <input type="text" name="cor_veiculo_locado" id="cor_veiculo_locado" className="form-control" value={formRentedVehicles?.cor_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="placa_veiculo_locado">Placa</label>
                        <input type="text" name="placa_veiculo_locado" id="placa_veiculo_locado" className="form-control" value={formRentedVehicles?.placa_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="autonomia_locado">Autonomia</label>
                        <input type="number" min={'0'} step={'0.01'} name="autonomia_locado" id="autonomia_locado" className="form-control" value={formRentedVehicles?.autonomia_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="data_locacao_locado">Data de Locação</label>
                        <input type="date" name="data_locacao_locado" id="data_locacao_locado" className="form-control" value={formRentedVehicles?.data_locacao_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="data_expiracao_locado">Data de Expiração</label>
                        <input type="date" name="data_expiracao_locado" id="data_expiracao_locado" className="form-control" value={formRentedVehicles?.data_expiracao_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                    </div>
                    <div className="col-lg-2 col-md-12 d-flex align-items-end mt-1">
                        <button type="submit" className="btn btn-primary">Salvar</button>
                    </div>
                </div>
            </form>

        </Dialog>
    );
}