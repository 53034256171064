import React, { useEffect, useState } from 'react';
import If from '../../../../../common/Layout/If';
import AddGed from './AddGed';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteGed, getFilesBySubmodule, getFilesBySubmoduleMultipleIds } from '../../../../../services/Ged';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';
import { DropdownButton, DropdownItem } from 'react-bootstrap';

export default function GedTable({
    submodule = '',
    viewMode = 'view',
    idProduto = '',
    addTitle = '',
    multiple = false,
    idsProduto = []
}) {
    const [addGedOpen, setAddGedOpen] = useState(false);
    const [gedTableData, setGedTableData] = useState([]);

    const columns = [
        {
            id: 'id_ged',
            name: 'ID',
        },
        {
            id: 'action_ged',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={viewMode === 'edit'}>
                        <DropdownItem onClick={() => showDeleteGed(row.id_ged)}>Deletar</DropdownItem>
                    </If>
                    <DropdownItem role='button' href={`${row?.url_ged}`} className='dropdown-item' target='_blank' download={true}>Download</DropdownItem>
                </DropdownButton>
            ),
        },
        {
            id: 'titulo_ged',
            name: 'Título',
        },
        {
            id: 'nome_arquivo_ged',
            name: 'Nome Arquivo',
        },
        {
            id: 'data_modificacao_ged',
            name: 'Data Modificação',
        },
        {
            id: 'responsavel_ged',
            name: 'Responsável',
        }
    ];

    const columnsMultiple = [
        {
            id: 'action_ged',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={viewMode === 'edit'}>
                        <DropdownItem onClick={() => deleteMultipleGedData(row?.ids_ged)}>Deletar</DropdownItem>
                    </If>
                    <DropdownItem role='button' href={`${row?.url_ged}`} className='dropdown-item' target='_blank' download={true}>Download</DropdownItem>
                </DropdownButton>
            )
        },
        {
            id: 'produtos_ged',
            name: 'Produtos'
        },
        {
            id: 'titulo_ged',
            name: 'Título',
        },
        {
            id: 'nome_arquivo_ged',
            name: 'Nome Arquivo',
        },
        {
            id: 'data_modificacao_ged',
            name: 'Data Modificação',
        },
        {
            id: 'responsavel_ged',
            name: 'Responsável',
        }
    ];

    async function showDeleteGed(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente excluir o documento?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            deleteGedData(id);
        }
    }

    async function deleteGedData(id) {
        const toastProducts = toast.loading("Excluindo documento, aguarde...");

        let dataReturn = await deleteGed(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Documento excluido com sucesso!', 'update', toastProducts);
            getGedData();
        } else {
            handleErrorsToast('Ocorreu um erro ao excluir o documento!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function deleteMultipleGedData(ids) {
        const toastProducts = toast.loading("Excluindo documento, aguarde...");
        let count = 0;
        for (let id of ids) {
            let dataReturn = await deleteGed(id);
            if (dataReturn?.status === 200) {
                count++;
            }
        }

        if(count === ids.length){
            handleSuccessToast('Todos os documentos foram excluidos com sucesso!', 'update', toastProducts);
        }else{
            handleErrorsToast('Alguns documentos não foram excluidos!', {}, 'update', toastProducts);
        }
        getMultipleGedData();
    }

    async function getGedData() {
        let dataReturn = await getFilesBySubmodule(submodule, idProduto);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data?.map((item) => ({
                id_ged: item?.id || '',
                url_ged: item?.url_arquivo || '',
                titulo_ged: item?.titulo || '',
                nome_arquivo_ged: item?.nome_original || '',
                data_modificacao_ged: item?.atualizado || '',
                responsavel_ged: item?.user?.nome || ''
            }));
            setGedTableData(data);
        }
    }

    async function getMultipleGedData() {
        let dataReturn = await getFilesBySubmoduleMultipleIds(submodule, {
            registro_ids: idsProduto
        });

        if (dataReturn?.status === 200) {
            let finalArray = [];
            let data = dataReturn?.data?.data || [];

            finalArray = Object.values(
                data.reduce((acc, item) => {
                    if (!acc[item.caminho]) {
                        acc[item.caminho] = { ...item, registros: [item.registro_id], produtos: [{ id: item?.product?.id || '', serial: item?.product?.serial || '', id_ged: item?.id || '' }] }
                    } else {
                        acc[item.caminho].registros.push(item.registro_id);
                        acc[item.caminho].produtos.push({ id: item?.product?.id || '', serial: item?.product?.serial || '', id_ged: item?.id || '' });
                    }
                    return acc;
                }, {})
            );

            finalArray = finalArray.map((item) => ({
                id_ged: item?.id || '',
                url_ged: item?.url_arquivo || '',
                titulo_ged: item?.titulo || '',
                nome_arquivo_ged: item?.nome_original || '',
                data_modificacao_ged: item?.atualizado || '',
                responsavel_ged: item?.user?.nome || '',
                produtos_ged: item?.produtos?.map((item => item.serial)).join(', ') || '',
                ids_ged: item?.produtos?.map((item => item.id_ged)) || []
            }));
            setGedTableData(finalArray);
        }
    }

    useEffect(() => {
        if (idProduto !== '' && !multiple) {
            getGedData();
        } else if (idsProduto.length > 0 && multiple) {
            getMultipleGedData();
        }
    }, [idProduto, multiple]);

    return (
        <>
            <If condition={viewMode === 'edit'}>
                <AddGed isOpen={addGedOpen} setModalOpen={() => setAddGedOpen(!addGedOpen)} productId={idProduto} setUpdateData={multiple ? getMultipleGedData : getGedData} submodule={submodule} multiple={multiple} productIds={idsProduto}></AddGed>
                <div>
                    <h5>{addTitle}</h5>
                    <button type="button" className="btn btn-primary text-center" onClick={() => setAddGedOpen(!addGedOpen)}><i className='fas fa-plus'></i>Adicionar</button>
                </div>
                <hr></hr>
            </If>
            <div>
                <h5>Lista de Documentos</h5>
                <PrimeTable
                    columns={!multiple ? columns : columnsMultiple}
                    rows={gedTableData}
                    id='lista_documentos_primetable'
                ></PrimeTable>
            </div>
        </>
    )
}