import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Cep from '../../../../common/Cep/Cep';
import { confirmInvoiceIssuance, getShipmentById } from '../../../../services/Shipment';
import If from '../../../../common/Layout/If';
import GedTable from '../Components/Ged/GedTable';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { getCurrentDate, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function InvoiceDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, invoiceId = '', viewMode = 'view', setUpdateLogistic = null }) {

    const initialState = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        local: '',
        box: '',
        id_representante: '',
        id_tecnico: '',
        id_localidade: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: '',
        responsavel_separacao: '',
        destinatario: '',
        data_postagem_remessa: '',
        prazo_entrega: ''
    }

    const [formData, setFormData] = useState(initialState);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(true);
    const { checkPermission } = usePermissions();
    const { tab: tabPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_logistica', 'Invoice', 'InvoiceDetails');

    async function submitForm(e) {
        e.preventDefault();

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await confirmInvoiceIssuance(invoiceId, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);

        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(invoiceId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let shipmentData = dataReturn?.data?.data;
            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
                local: shipmentData?.product?.local || '',
                box: shipmentData?.product?.box || '',
                id_localidade: shipmentData?.id_localidade || '',
                modal_envio: shipmentData?.modal_envio || '',
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: shipmentData?.product?.technician?.nome_tec || '',
            }));
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    function handleDataPostagem(e) {
        let field1 = e.target.name === 'prazo_entrega' ? e.target.value : formData['prazo_entrega'];
        let field2 = e.target.name === 'data_postagem_remessa' ? e.target.value : formData['data_postagem_remessa'];
        if (field1 < field2) {
            handleErrorsToast('A data de entrega não pode ser menor que a data de postagem!');
            return;
        }

        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(invoiceId)) {
            setFormData(initialState);
            setShowInvoiceDetails(false);
            getShipment();
        }
    }, [isOpen, invoiceId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={viewMode === 'view' ? 'Visualizar Remessa' : 'Atualizar Remessa'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header='Detalhes da NFe'>
                    <form onSubmit={submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <h5>{formData?.modal_envio?.value !== 'Em Mãos' ? `Preencha todos os dados da NFe` : `Suba o arquivo assinado pelo técnico para confirmar a entrega`}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 mt-1">
                                <label>Nome do Produto</label>
                                <input type="text" className="form-control" placeholder='Nome do Produto' value={formData?.modelo || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12 mt-1">
                                <label>Serial</label>
                                <input type="text" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label>Tipo de Produto</label>
                                <input type="text" className="form-control" placeholder='Tipo de Produto' value={formData?.nome_produto || ''} readOnly />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-12">
                                <h6 onClick={() => setShowInvoiceDetails(prevState => !prevState)} style={{ cursor: 'pointer' }}>Detalhes da Remessa (Clique para {showInvoiceDetails ? 'Esconder' : 'Revelar'})</h6>
                            </div>
                        </div>
                        <div style={{ display: `${showInvoiceDetails ? 'block' : 'none'}` }}>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="urgencia_remessa">Urgência Remessa</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.urgencia_remessa || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="local">Local</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.local || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="box">Estoque</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.box || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="id_tecnico">Técnico</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.id_tecnico || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="">ID da Localidade\ID do Chamado</label>
                                    <input type="text" className='form-control' value={formData?.ticket?.num_chamado || ''} readOnly={true} />
                                </div>
                            </div>
                            <Cep
                                formData={formData}
                                setFormData={setFormData}
                                formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua' }}
                                required={true}
                                readOnly={true}
                            ></Cep>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="numero">Número</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.numero || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="complemento">Complemento</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.complemento || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="destinatario">Destinatário</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.destinatario || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="dimensoes">Dimensões</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.dimensoes || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="centro_custo">Centro Custo</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.centro_custo || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="peso_kg">Peso (kg)</label>
                                    <input type="number" step={"0.01"} min={"0"} placeholder='...' className="form-control" value={formData?.peso_kg || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="modal_envio">Modal de Envio</label>
                                    <input type="text" placeholder='...' className="form-control" value={formData?.modal_envio || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="responsavel_separacao">Responsável pela Separação</label>
                                    <input type="text" className="form-control" placeholder='...' value={formData?.responsavel_separacao || ''} readOnly={true} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <h6>Detalhes da NFe</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="rastreamento">Código de Rastreio</label>
                                <input type="text" name="rastreamento" id="rastreamento" className="form-control" placeholder='Rastreamento' value={formData?.rastreamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="nfe_saida">NFe de Saída</label>
                                <input type="text" name="nfe_saida" id="nfe_saida" className="form-control" placeholder='NFe de Saída' value={formData?.nfe_saida || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="valor_equipamento_nf">Valor do Equipamento na NFe</label>
                                <input type="number" name="valor_equipamento_nf" id="valor_equipamento_nf" className="form-control" placeholder='Valor do Equipamento NFe' value={formData?.valor_equipamento_nf || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="volume_nfe">Volume da NFe</label>
                                <input type="number" name="volume_nfe" id="volume_nfe" className="form-control" placeholder='Volume da NFe' value={formData?.volume_nfe || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="prazo_entrega">Prazo de Entrega</label>
                                <input type="date" name="prazo_entrega" id="prazo_entrega" min={getCurrentDate(-1)} className="form-control" placeholder='Prazo de Entrega' value={formData?.prazo_entrega || ''} onChange={(e) => handleDataPostagem(e)} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="data_postagem_remessa">Data da Postagem</label>
                                <input type="date" name="data_postagem_remessa" id="data_postagem_remessa" min={getCurrentDate(-1)} className="form-control" placeholder='Data da Postagem' value={formData?.data_postagem_remessa || ''} onChange={(e) => handleDataPostagem(e)} required readOnly={viewMode === 'view'} />
                            </div>
                        </div>
                        <If condition={viewMode === 'edit'}>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                                </div>
                            </div>
                        </If>
                    </form>
                </TabPanel>
                <TabPanel header='GED' visible={checkPermission(tabPermissions[0])}>
                    <GedTable addTitle='Novo Documento' idProduto={formData?.id_produto} submodule='remessas' viewMode={viewMode === 'edit' && checkPermission(pagePermissions[0]) ? 'edit' : 'view'}></GedTable>
                </TabPanel>
            </TabView>
        </Dialog>);
}