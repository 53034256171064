import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import If from '../../../../common/Layout/If';
import { getProductByProductId, updateProductData } from '../../../../services/Product';
import { toast } from 'react-toastify';
import { TabPanel, TabView } from 'primereact/tabview';
import GedTable from '../Components/Ged/GedTable';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function ProductRMADetails({ visible = false, onHide = null, id_produto = '', viewMode = 'view', updateParent = null, setUpdateLogistic = null }) {
    const INITIAL_STATE = {
        nome_produto: '',
        serial: '',
        modelo: '',
        id_status_produto: '',
        fabricante: '',
        numero_rma: '',
        proveniente_rma: 'nao',
        id_produto_rma: '',
        data_entrada_rma: '',
        data_solicitacao_coleta: '',
        data_coleta: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(INITIAL_STATE);
    const { checkPermission } = usePermissions();
    const { tab: tabPermissions, page: pagePermissions} = getSubComponentsPermissions('mam_logistica', 'ShowRMA', 'ProductRMADetails');

    async function submitForm(e) {
        e.preventDefault();

        const toastSubmit = toast.loading('Atualizando dados, aguarde...');

        let params = {
            ...formCadastroProduto,
            id_status_produto: formCadastroProduto?.id_status_produto?.value || ''
        }

        let dataReturn = await updateProductData(id_produto, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastSubmit);
            updateParent();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastSubmit);
        }

    }

    async function getProductData(id) {
        let dataReturn = await getProductByProductId(id);

        if (dataReturn?.data?.response === 'success') {
            let product = dataReturn?.data?.data;
            setFormCadastroProduto({
                ...product,
                fabricante: product?.partnumber?.manufacturer?.nome_fabricante || '',
                id_status_produto: { value: product?.status?.id || '', label: product?.status?.valor_opcao || '' },
            });
        } else {
            handleErrorsToast('Ocorreu um erro ao encontrar os dados do produto', dataReturn?.data);
            onHide(false);
        }
    }

    useEffect(() => {
        if (visible && !['', null, undefined].includes(id_produto)) {
            getProductData(id_produto);
        } else {
            setFormCadastroProduto(INITIAL_STATE)
        }

    }, [visible, id_produto])

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header={viewMode === 'view' ? 'Visualizar Produto - RMA' : 'Atualizar Produto - RMA'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header='Informações do Produto'>
                <form onSubmit={submitForm}>
                    <div className='row'>
                        <div className="col-sm-12">
                            <h5>Informações Básicas</h5>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <label htmlFor="fabricante">Fabricante</label>
                            <input type="text" name="fabricante" id="fabricante" className="form-control" value={formCadastroProduto?.fabricante || ''} disabled />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <label htmlFor="modelo">Nome do Produto</label>
                            <input type="text" name="modelo" id="modelo" className="form-control" value={formCadastroProduto?.modelo || ''} disabled />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <label htmlFor="nome_produto">Tipo de Produto</label>
                            <input type="text" name="nome_produto" id="nome_produto" className="form-control" placeholder="Tipo de Produto" value={formCadastroProduto?.nome_produto || ''} disabled />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <label htmlFor="serial">Serial</label>
                            <input type="text" name="serial" id="serial" className="form-control" placeholder="Serial" value={formCadastroProduto?.serial || ''} disabled />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5>Dados RMA</h5>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="numero_rma">Número de RMA</label>
                            <input type="text" name="numero_rma" id="numero_rma" className='form-control' placeholder='Número de RMA' onChange={(e) => setFormCadastroProduto(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formCadastroProduto?.numero_rma || ''} readOnly={viewMode === 'view'} />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="nfe_devolucao">NFe Devolução</label>
                            <input type="text" name="nfe_devolucao" id="nfe_devolucao" className='form-control' placeholder='NFe Devolução' onChange={(e) => setFormCadastroProduto(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formCadastroProduto?.nfe_devolucao || ''} required readOnly={viewMode === 'view'} />
                        </div>
                        <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} cols={[12, 4, 4, 4]} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'} required={true} isDisabled={viewMode === 'view'}></ModuleOptionsSelect>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="data_entrada_rma">Data Entrada RMA</label>
                            <input type="date" name="data_entrada_rma" id="data_entrada_rma" className='form-control' onChange={(e) => setFormCadastroProduto(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formCadastroProduto?.data_entrada_rma || ''} required readOnly={viewMode === 'view'} />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="data_solicitacao_coleta">Data Solicitação Coleta</label>
                            <input type="date" name="data_solicitacao_coleta" id="data_solicitacao_coleta" className='form-control' onChange={(e) => setFormCadastroProduto(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formCadastroProduto?.data_solicitacao_coleta || ''} required readOnly={viewMode === 'view'} />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="data_coleta">Data Coleta</label>
                            <input type="date" name="data_coleta" id="data_coleta" className='form-control' onChange={(e) => setFormCadastroProduto(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={formCadastroProduto?.data_coleta || ''} required readOnly={viewMode === 'view'} />
                        </div>
                    </div>
                    <If condition={viewMode === 'edit'}>
                        <div className="row">
                            <div className="col-12 mt-1">
                                <button type="submit" className='btn btn-secondary float-right'>Salvar</button>
                            </div>
                        </div>
                    </If>
                </form>
                </TabPanel>
                <TabPanel header='GED' visible={checkPermission(tabPermissions[0])}>
                    <GedTable addTitle='Arquivos do Produto' idProduto={id_produto} submodule='produtos' viewMode={viewMode === 'edit' && checkPermission(pagePermissions[0]) ? 'edit' : 'view'}></GedTable>
                </TabPanel>
            </TabView>
        </Dialog >)

}