import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import { assignSupportTicket } from '../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import UsuariosAtivosSelect from '../../common/Selects/UsuariosAtivosSelect';
import ChamadosSelect from '../../common/Selects/ChamadosSelect';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';
import AsyncChamadosSelect from '../../common/Selects/AsyncChamadosSelect';

export default function SupportResponsible() {
    const initialState = {
        responsavel_suporte: '',
        todos_chamados_dia: [],
        procurar_todos_chamados: [],
    }

    const [formData, setFormData] = useState(initialState);
    const [updateData, setUpdateData] = useState(false);

    async function submitAssignForm(e) {
        e.preventDefault();

        let chamadosArray = [...formData?.todos_chamados_dia, ...formData?.procurar_todos_chamados];

        if (chamadosArray?.length === 0) {
            handleErrorsToast('Por favor, selecione pelo menos um chamado');
            return;
        }

        let params = {
            ids_chamado: chamadosArray?.filter((item, idx) => chamadosArray.findIndex(item2 => item?.value === item2?.value) === idx)?.map((item) => (item?.value)),
            id_responsavel: formData?.responsavel_suporte?.value || ''
        }

        const ticketToast = toast.loading("Atualizando chamados, aguarde...");

        let dataReturn = await assignSupportTicket(params);

        if (dataReturn?.status === 200) {
            handleSuccessToast('Chamado atualizado com sucesso!', 'update', ticketToast);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o chamado!', dataReturn?.data, 'update', ticketToast);
        }
        setFormData(initialState);
        setUpdateData(true);
    }

    return (<Content headerTitle="Chamados - Responsável Suporte">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitAssignForm}>
                <div className='row'>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} title='Responsável Suporte' fieldName='responsavel_suporte' required={true} userType='support'></UsuariosAtivosSelect>
                    <ChamadosSelect
                        formData={formData}
                        setFormData={setFormData}
                        fieldName='todos_chamados_dia'
                        isMulti={true}
                        title='Chamados Todos do dia (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Suporte)'
                        type='today'
                        today_field='usuario_responsavel_suporte'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <AsyncChamadosSelect
                        formData={formData}
                        setFormData={setFormData}
                        fieldName='procurar_todos_chamados'
                        isMulti={true}
                        title='Procurar Todos Chamados (Digite no mínimo 4 caracteres)'
                    ></AsyncChamadosSelect>
                    <div className="col-12 mt-1">
                        <button type='submit' className="btn btn-primary">Designar responsável ao(s) chamado(s)</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}