import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import InvoiceDetails from './InvoiceDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { checkValue, getShipmentsByTicketId, handleErrorsToast, handleSuccessToast, openNewTab, showBulkShipmentMessage } from '../../../../configs/GenericFunctions';
import ShipmentFilters from '../Components/ShipmentFilters';
import BulkInvoiceDetails from './BulkInvoiceDetails';
import { Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';

export default function Invoice() {
    const [data, setData] = useState([]);
    const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [search, setSearch] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const [openBulkInvoiceDetails, setOpenBulkInvoiceDetails] = useState(false);
    const [bulkInvoiceId, setBulkInvoiceId] = useState('');

    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, acoes_em_massa: acoesEmMassaPermissions } = getSubComponentsPermissions('mam_logistica', 'Invoice', 'Invoice');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermissions[0])}>
                            <button type="button" className="dropdown-item" onClick={() => openInvoiceDetailsModal(row, 'view')}>Ver</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[1])}>
                            <button type='button' className="dropdown-item" onClick={() => openInvoiceDetailsModal(row, 'edit')}>Editar</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[2])}>
                            <button type='button' className="dropdown-item" onClick={() => showReturnStatusSwal(row.id_produto)}>Cancelar Emissão de NFe</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[3])}>
                            <button type='button' className="dropdown-item" onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Remessa</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<button type='button' className='btn btn-link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'cep_produto',
            name: 'CEP',
        },
        {
            id: 'uf_produto',
            name: 'UF',
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
        },
        {
            id: 'rua_produto',
            name: 'Rua',
        },
        {
            id: 'numero_produto',
            name: 'Número',
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
        },
    ];

    async function getInvoiceByStatus(page = 1, perPage = 100, search = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO NFE SAÍDA', page, perPage, search);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.modelo || '',
                tipo_produto: item?.product?.nome_produto || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                chave_tipo_remessa_produto: item?.chave_tipo_remessa || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    async function showReturnStatusSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a emissão da NFe?',
            text: 'O status da remessa retornará para Peças a Enviar!',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            returnStatus(id);
        }
    }

    async function returnStatus(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO ENVIO' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Emissão cancelada com sucesso!", 'update', toastProducts);
            getInvoiceByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a emissão!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO CANCELAMENTO' });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Emissão cancelada com sucesso!", 'update', toastProducts);
            getInvoiceByStatus();
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a emissão!', dataReturn?.data, 'update', toastProducts);
        }
    }

    async function openInvoiceDetailsModal(row, mode) {
        if (mode === 'view' || checkValue(row.id_chamado_produto)) {
            handleSingleShipmentModal(row, mode);
            return;
        }

        let product = await getShipmentsByTicketId('AGUARDANDO NFE SAÍDA', `&id_chamado=${row.id_chamado_produto}`);

        if (product?.length < 2) {
            handleSingleShipmentModal(row, mode);
        } else {
            showBulkShipmentMessage(() => handleSingleShipmentModal(row, mode), () => handleBulkInvoiceModal(product));
        }
    }

    function handleSingleShipmentModal(row, mode) {
        setInvoiceId(row.id_produto);
        setViewMode(mode);
        setOpenInvoiceDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getInvoiceByStatus(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getInvoiceByStatus(1, rows, search);
    }

    function handleFilters(search = '') {
        setPerPage(100);
        setPage(0);
        setSearch(search);
        getInvoiceByStatus(1, 100, search);
    }

    function handleBulkInvoiceModal(selectedRowsParam = []) {
        let shipmentIdArray = [];
        let chamado = '';
        let tipo_remessa = '';
        let modal_envio = '';

        let selectedRowsData = selectedRowsParam?.length > 0 ? selectedRowsParam : selectedRows;

        for (let i = 0; i < selectedRowsData.length; i++) {
            if (i === 0) {
                chamado = selectedRowsData[i]?.id_chamado_produto;
                tipo_remessa = selectedRowsData[i]?.tipo_remessa_produto;
                modal_envio = selectedRowsData[i]?.modal_envio_produto;
                shipmentIdArray.push(selectedRowsData[i]?.id_produto);
                continue;
            }

            if (chamado !== selectedRowsData[i]?.id_chamado_produto) {
                handleErrorsToast('Todas as remessas devem pertencer ao mesmo chamado!');
                return;
            }

            if (tipo_remessa !== selectedRowsData[i]?.tipo_remessa_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo tipo!');
                return;
            }

            if (modal_envio !== selectedRowsData[i]?.modal_envio_produto) {
                handleErrorsToast('Todas as remessas devem ter o mesmo modal de envio!');
                return;
            }

            shipmentIdArray.push(selectedRowsData[i]?.id_produto);
        }

        setBulkInvoiceId(shipmentIdArray.toString());
        setOpenBulkInvoiceDetails(true);
    }

    useEffect(() => {
        getInvoiceByStatus();
    }, []);

    return (<Content headerTitle='Emissão de NFE'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <InvoiceDetails isOpen={openInvoiceDetails} setModalOpen={() => setOpenInvoiceDetails(!openInvoiceDetails)} invoiceId={invoiceId} setUpdateProducts={() => getInvoiceByStatus(page + 1, perPage, search)} viewMode={viewMode} setUpdateLogistic={setUpdateLogistic}></InvoiceDetails>
        <BulkInvoiceDetails handleClearRows={() => setSelectedRows([])} isOpen={openBulkInvoiceDetails} setModalOpen={() => setOpenBulkInvoiceDetails(!openBulkInvoiceDetails)} invoiceId={bulkInvoiceId} setUpdateLogistic={setUpdateLogistic} setUpdateProducts={() => getInvoiceByStatus(page + 1, perPage, search)}></BulkInvoiceDetails>
        <Card title='Cadastro de Produtos - Emissão de NFE'>
            <If condition={selectedRows?.length > 0 && checkAllPermissions(acoesEmMassaPermissions)}>
                <Row>
                    <Col>
                        <DropdownButton title='Ações em Massa' className='m-1'>
                            <If condition={checkPermission(acoesEmMassaPermissions[0])}>
                                <DropdownItem onClick={handleBulkInvoiceModal}>Editar NFe em massa</DropdownItem>
                            </If>
                        </DropdownButton>
                    </Col>
                </Row>
            </If>
            <ShipmentFilters handleFilters={handleFilters} setSearch={setSearch}></ShipmentFilters>
            <Row>
                <Col>
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='invoice'
                        reorderableColumns
                        selectableRows={checkAllPermissions(acoesEmMassaPermissions) ? true : false}
                        selectedRows={checkAllPermissions(acoesEmMassaPermissions) ? selectedRows : null}
                        handleSelect={checkAllPermissions(acoesEmMassaPermissions) ? setSelectedRows : null}
                    ></PrimeTable>
                </Col>
            </Row>
        </Card>
    </Content>)
}