import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getEmbedDashboard } from "../../services/DevMode";
import { checkValue, handleErrorsToast } from "../../configs/GenericFunctions";
import Card from "../Layout/Card";

export default function Dashboard({ slug_dashboard = '' }) {

    const [iframeUrl, setIframeUrl] = useState('');

    async function getIframe() {
        let dataReturn = await getEmbedDashboard(slug_dashboard);

        if (dataReturn?.status === 200) {
            setIframeUrl(dataReturn?.data?.data?.iframe_url + '&refresh=60');
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar o dashboard');
        }
    }
    useEffect(() => {
        if (!checkValue(slug_dashboard)) {
            getIframe();
        }
    }, [slug_dashboard]);

    return (
        <Card hideCardHeader>
            <Row>
                <Col className="h-screen" sm='12'>
                    <iframe
                        src={iframeUrl}
                        frameBorder={0}
                        className="w-full h-full"
                    />
                </Col>
            </Row>
        </Card>
        );
}