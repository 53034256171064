import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { getShipmentsByDateAndStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import ProductsReceivedDetails from './ProductReceivedDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import If from '../../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, getCurrentDate } from '../../../../configs/GenericFunctions';

export default function ProductsReceived() {
    const INITIAL_STATE = {
        data_inicial: '',
        data_final: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [data, setData] = useState([]);
    const [productReceivedId, setProductReceivedId] = useState('');
    const [openProductsReceivedDetails, setOpenProductsReceivedDetails] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions } = getSubComponentsPermissions('mam_logistica', 'ProductsReceived', 'ProductsReceived');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermissions[0])}>
                            <button type="button" className="dropdown-item" onClick={() => openProductsReceivedModal(row.id_produto)}>Ver</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        }
    ];

    async function getAwaitingWithdraw(page = 1, perPage = 100, data_inicial = '', data_final = '') {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");
        const data_inicio = data_inicial || getCurrentDate(0, -1, 0);
        const data_fim = data_final || getCurrentDate(1);
        let dataReturn = await getShipmentsByDateAndStatus({ data_inicial: data_inicio, data_final: data_fim, page: page, perPage: perPage });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    function submitForm(e){
        e.preventDefault();

        getAwaitingWithdraw(1, 100, formData?.data_inicial, formData?.data_final);
        setPerPage(100);
        setPage(0);
    }

    function clearForm(){
        setFormData(INITIAL_STATE);
    }

    function openProductsReceivedModal(id) {
        setProductReceivedId(id);
        setOpenProductsReceivedDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getAwaitingWithdraw(newPage + 1, perPage, formData?.data_inicial, formData?.data_final);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getAwaitingWithdraw(1, rows, formData?.data_inicial, formData?.data_final);
    }

    useEffect(() => {
        getAwaitingWithdraw();
    }, []);

    return (<Content headerTitle='Produtos Recebidos'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica'></SubHeaderSpecific>
        <ProductsReceivedDetails isOpen={openProductsReceivedDetails} setModalOpen={() => setOpenProductsReceivedDetails(!openProductsReceivedDetails)} productReceivedId={productReceivedId}></ProductsReceivedDetails>
        <Card title='Cadastro de Produtos - Produtos Recebidos'>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submitForm}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="data_inicial">Data Inicial</label>
                                <input type="date" name="data_inicial" id="data_inicial" className="form-control" value={formData?.data_inicial || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="data_final">Data Final</label>
                                <input type="date" name="data_final" id="data_final" className="form-control" value={formData?.data_final || ''} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <button type="button" className="btn btn-warning m-1" onClick={clearForm}>Limpar Campos</button>
                                <button type="submit" className="btn btn-primary m-1">Pesquisar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                        id='products_received'
                        reorderableColumns
                    ></PrimeTable>
                </div>
            </div>
        </Card>
    </Content>);
}