import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import Cep from "../../../../common/Cep/Cep";
import If from "../../../../common/Layout/If";
import { getCurrentDate, handleErrorsToast, onImageError } from "../../../../configs/GenericFunctions";
import React from "react";
import GenericSelect from "../../../../common/Selects/GenericSelect";
import UsuariosAtivosSelect from "../../../../common/Selects/UsuariosAtivosSelect";
import BoxSelect from "../../../../common/Selects/BoxSelect";
import AsyncChamadosSelect from "../../../../common/Selects/AsyncChamadosSelect";
import { getTicketById } from "../../../../services/Ticket";
import ModalEnvioSelect from "../../../../common/Selects/ModalEnvioSelect";
import ModuleOptionsSelect from "../../../../common/Selects/ModuleOptionsSelect";
import ReactSignatureCanvas from 'react-signature-canvas';
import { withMask } from "use-mask-input";
import AsyncTecnicosSelect from "../../../../common/Selects/AsyncTecnicosSelect";

export default function ShipmentForm({ formData = {}, setFormData = null, title = 'Detalhes da Remessa', mode = 'view', parentPage = '', submitForm = null, sign = null, setSign = null, url = '', setUrl = null }) {
    function handleClear() {
        sign.clear();
        setUrl("");
    }

    function handleDataPostagemChange(e) {
        let field1 = e.target.name === 'prazo_entrega' ? e.target.value : formData['prazo_entrega'];
        let field2 = e.target.name === 'data_postagem_remessa' ? e.target.value : formData['data_postagem_remessa'];
        if (field1 < field2) {
            handleErrorsToast('A data de entrega não pode ser menor que a data de postagem!');
            return;
        }

        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    }

    function handleChange(e) {
        setFormData(p => ({ ...p, [e.target.name]: e.target.value }));
    }

    //Mostra ou esconde os campos
    function handleFields(field) {

        function handleGeneric() {
            if (!['ShipmentDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleGenericSelect() {
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'ShipmentDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleModalEnvioSelect(){
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'ShipmentDetails', 'InTransitDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleUrgenciaRemessa() {
            if (!['AwaitingCancelShipmentDetails', 'ShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleTipoRemessa() {
            if (!['view'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'CanceledShipmentDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleTipoRemessaSelect() {
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleEndereco() {
            if (!['ShipmentDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (['ShipmentDetails'].includes(parentPage) && [formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleIdChamado() {
            if (!['ProductDetails', 'CanceledShipmentDetails', 'ShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'InTransitDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleIdChamadoSelect() {
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'ShipmentDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        function handleLocal() {
            if (!['ProductDetails', 'CanceledShipmentDetails', 'ShipmentDetails', 'InvoiceDetails'].includes(parentPage)) {
                return false;
            }

            if (['ShipmentDetails'].includes(parentPage) && [formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            if (!['InvoiceDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleLocalSelect() {
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'ShipmentDetails'].includes(parentPage)) {
                return false;
            }

            if (['ShipmentDetails'].includes(parentPage) && [formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleDataEntrega() {
            if (!['ShipmentDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (![formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleIdTecnico() {
            if (!['AwaitingCancelShipmentDetails', 'ShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleModalEnvio() {
            if (!['AwaitingCancelShipmentDetails', 'ShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'PendingDactDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleResponsavelSeparacao() {
            if (!['AwaitingCancelShipmentDetails', 'ShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleVolumeNfe() {
            if (!['AwaitingCancelShipmentDetails', 'ShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (!['InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage) && ['update', 'edit'].includes(mode)) {
                return false;
            }

            return true;
        }

        function handleVolumeNfeSelect() {
            if (!['update', 'edit'].includes(mode)) {
                return false;
            }

            if (!['ProductDetails', 'ShipmentDetails'].includes(parentPage)) {
                return false;
            }

            if (['ShipmentDetails'].includes(parentPage) && [formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleDact() {
            if (!['AwaitingCancelShipmentDetails', 'ProductDetails', 'CanceledShipmentDetails', 'PendingDactDetails', 'InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return false;
            }

            if (![formData?.modal_envio?.value, formData?.modal_envio].includes('Azul')) {
                return false;
            }

            return true;
        }

        function handleAssinatura() {
            if (!['AwaitingCancelShipmentDetails', 'ProductDetails', 'CanceledShipmentDetails', 'SignDetails'].includes(parentPage)) {
                return false;
            }

            if (![formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleAssinaturaImg() {
            if (mode !== 'view') {
                return false;
            }

            if (!['AwaitingCancelShipmentDetails', 'ProductDetails', 'CanceledShipmentDetails'].includes(parentPage)) {
                return false;
            }

            if (![formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos')) {
                return false;
            }

            return true;
        }

        function handleAssinaturaCanvas() {
            if (!['SignDetails'].includes(parentPage)) {
                return false;
            }

            return true;
        }

        let fields = {
            fabricante: ['ProductDetails'].includes(parentPage) ? true : false,
            modelo: true,
            serial: true,
            nome_produto: true,

            urgencia_remessa: handleUrgenciaRemessa(),
            urgencia_remessa_select: handleGenericSelect(),

            id_tecnico: handleIdTecnico(),
            id_tecnico_select: handleGenericSelect(),

            status_remessa: true,

            tipo_remessa: handleTipoRemessa(),
            tipo_remessa_select: handleTipoRemessaSelect(),

            endereco: handleEndereco(),
            destinatario: handleEndereco(),
            dimensoes: handleGeneric(),
            centro_custo: handleGeneric(),
            peso_kg: handleGeneric(),

            modal_envio: handleModalEnvio(),
            modal_envio_select: handleModalEnvioSelect(),

            responsavel_separacao: handleResponsavelSeparacao(),
            responsavel_separacao_select: handleGenericSelect(),

            data_recebido: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InTransitDetails'].includes(parentPage) ? true : false,
            rastreamento: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage) ? true : false,
            nfe_saida: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage) ? true : false,
            prazo_entrega: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage) ? true : false,
            valor_equipamento_nf: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage) ? true : false,

            volume_nfe: handleVolumeNfe(),
            volume_nfe_select: handleVolumeNfeSelect(),

            data_postagem_remessa: ['PendingDactDetails', 'AwaitingCancelShipmentDetails', 'ProductDetails', 'AwaitingWithdrawDetails', 'CanceledShipmentDetails', 'InvoiceDetails', 'InTransitDetails'].includes(parentPage) ? true : false,
            dact: handleDact(),

            assinatura: handleAssinatura(),
            assinatura_img: handleAssinaturaImg(),
            assinatura_canvas: handleAssinaturaCanvas(),

            local: handleLocal(),
            local_select: handleLocalSelect(),

            box: handleLocal(),
            box_select: handleLocalSelect(),

            id_chamado: handleIdChamado(),
            id_chamado_select: handleIdChamadoSelect(),

            data_entrega: handleDataEntrega()
        }

        let fieldValue = fields[field];

        return typeof fieldValue === 'function' ? fieldValue() : fieldValue;
    }

    //Torna os campos requeridos
    function handleRequired(field) {

        function handleShipmentDetails() {
            if (['ShipmentDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleShipmentInvoice() {
            if (['ShipmentDetails', 'InvoiceDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleInvoiceDetails() {
            if (['InvoiceDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleInvoiceInTransitDetails(){
            if(['InvoiceDetails', 'InTransitDetails'].includes(parentPage)){
                return true;
            }

            return false;
        }

        function handleShipmentInTransitDetails(){
            if(['ShipmentDetails', 'InTransitDetails'].includes(parentPage)){
                return true;
            }

            return false;
        }

        function handleSignDetails() {
            if (parentPage === 'SignDetails') {
                return true;
            }

            return false;
        }

        function handlePendingDactDetails(){
            if (parentPage === 'PendingDactDetails'){
                return true;
            }

            return false;
        }

        let fields = {
            fabricante: false,
            modelo: false,
            serial: false,
            nome_produto: false,
            urgencia_remessa_select: handleShipmentDetails(),
            id_tecnico_select: handleShipmentDetails(),
            status_remessa: false,
            tipo_remessa_select: false,
            endereco: handleShipmentInvoice(),
            destinatario: handleShipmentInvoice(),
            dimensoes: handleShipmentDetails(),
            centro_custo: handleShipmentDetails(),
            peso_kg: handleShipmentDetails(),
            modal_envio_select: handleShipmentInTransitDetails(),
            responsavel_separacao_select: handleShipmentDetails(),
            data_recebido: false,
            rastreamento: handleInvoiceInTransitDetails(),
            nfe_saida: handleInvoiceDetails(),
            prazo_entrega: handleInvoiceDetails(),
            valor_equipamento_nf: handleInvoiceDetails(),
            volume_nfe_select: handleInvoiceDetails(),
            data_postagem_remessa: handleInvoiceDetails(),
            dact: handlePendingDactDetails(),
            valor_dact: handlePendingDactDetails(),
            assinatura: false,
            local_select: false,
            box_select: handleShipmentDetails(),
            id_chamado_select: false,
            data_entrega: handleShipmentDetails(),
            nome_recebedor: handleSignDetails(),
            cpf_recebedor: handleSignDetails()
        }
        let fieldValue = fields[field];

        return typeof fieldValue === 'function' ? fieldValue() : fieldValue;
    }

    //Torna os campos apenas leitura
    function handleReadOnly(field) {

        function handleInvoiceInTransitPendingDactDetails() {
            if (mode === 'view') {
                return true;
            }

            if (['InvoiceDetails', 'InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleInTransitPendingDact() {
            if (mode === 'view') {
                return true;
            }

            if (['InTransitDetails', 'PendingDactDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleInTransit() {
            if (mode === 'view') {
                return true;
            }

            if (['InTransitDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handlePendingDact() {
            if (mode === 'view') {
                return true;
            }

            if (['PendingDactDetails'].includes(parentPage)) {
                return true;
            }

            return false;
        }

        function handleAssinatura() {
            if (parentPage === 'SignDetails') {
                return false;
            }

            return true;
        }

        let fields = {
            fabricante: mode === 'view' ? true : false,
            modelo: mode === 'view' ? true : false,
            serial: mode === 'view' ? true : false,
            nome_produto: mode === 'view' ? true : false,
            urgencia_remessa: mode === 'view' ? true : false,
            urgencia_remessa_select: mode === 'view' ? true : false,
            id_tecnico: mode === 'view' ? true : false,
            id_tecnico_select: mode === 'view' ? true : false,
            status_remessa: mode === 'view' ? true : false,
            tipo_remessa: mode === 'view' ? true : false,
            tipo_remessa_select: mode === 'view' ? true : false,
            endereco: handleInvoiceInTransitPendingDactDetails(),
            destinatario: handleInvoiceInTransitPendingDactDetails(),
            dimensoes: handleInvoiceInTransitPendingDactDetails(),
            centro_custo: handleInvoiceInTransitPendingDactDetails(),
            peso_kg: handleInvoiceInTransitPendingDactDetails(),
            modal_envio_select:  mode === 'view' ? true : false,
            modal_envio: mode === 'view' ? true : false,
            responsavel_separacao: mode === 'view' ? true : false,
            responsavel_separacao_select: mode === 'view' ? true : false,
            data_recebido: handleInvoiceInTransitPendingDactDetails(),
            rastreamento: handlePendingDact(),
            nfe_saida: handleInTransitPendingDact(),
            prazo_entrega: handleInTransitPendingDact(),
            valor_equipamento_nf: handleInTransitPendingDact(),
            volume_nfe: mode === 'view' ? true : false,
            volume_nfe_select: mode === 'view' ? true : false,
            data_postagem_remessa: handleInTransitPendingDact(),
            dact: handleInTransit(),
            valor_dact: handleInTransit(),
            assinatura: mode === 'view' ? true : false,
            local: mode === 'view' ? true : false,
            local_select: mode === 'view' ? true : false,
            box: mode === 'view' ? true : false,
            box_select: mode === 'view' ? true : false,
            id_chamado: mode === 'view' ? true : false,
            id_chamado_select: mode === 'view' ? true : false,
            data_entrega: mode === 'view' ? true : false,
            nome_recebedor: handleAssinatura(),
            cpf_recebedor: handleAssinatura()
        }
        let fieldValue = fields[field];

        return typeof fieldValue === 'function' ? fieldValue() : fieldValue;
    }

    function handleChamado(value, name) {
        setFormData(prevState => ({ ...prevState, [name]: value }));

        if (value && formData?.modal_envio?.value !== 'Em Mãos') {
            getChamadoById(value?.value);
        } else {
            setFormData(prevState => ({
                ...prevState,
                bairro: '',
                cep: '',
                cidade: '',
                uf: '',
                rua: '',
                numero: '',
                complemento: '',
                destinatario: '',
                centro_custo: ''
            }))
        }
    }

    async function getChamadoById(id, mode = 'all') {
        let ticketData = await getTicketById(id);
        if (ticketData?.data?.response === 'success') {
            let ticket = ticketData?.data?.data;
            let address = ticket?.logistics_shipping;
            let addressNumber = address?.compl_ref_obs_endereco_logistica.match(/\d+/)[0] || address?.logradouro_endereco_logistica.match(/\d+/)[0] || '';

            if (mode === 'all') {
                let final_address = {};

                if (!['', null, undefined, false].includes(address)) {
                    final_address = {
                        bairro: address?.bairro_endereco_logistica || '',
                        cep: address?.cep_endereco_logistica || '',
                        cidade: address?.cidade_endereco_logistica || '',
                        uf: address?.estado_endereco_logistica || '',
                        rua: address?.logradouro_endereco_logistica || '',
                        numero: addressNumber,
                        complemento: address?.compl_ref_obs_endereco_logistica || '',
                        destinatario: address?.destinatario_remessa || '',
                    }
                }
                else if (['', null, undefined, false].includes(address) && ['envio_tecnico'].includes(formData?.chave_tipo_remessa)) {
                    let technicianAddress = ticket?.technician;
                    let technicianAddressNumber = technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || '';

                    final_address = {
                        bairro: technicianAddress?.bairro_end || '',
                        cep: technicianAddress?.cep_end || '',
                        cidade: technicianAddress?.cidade_end || '',
                        uf: technicianAddress?.estado_end || '',
                        rua: technicianAddress?.logradouro_end || '',
                        numero: technicianAddressNumber,
                        complemento: technicianAddress?.compl_ref_obs_end || '',
                        destinatario: technicianAddress?.nome_tec || '',
                    }
                } else if (['', null, undefined, false].includes(address) && formData?.chave_tipo_remessa === 'envio_cliente') {
                    let customerAddress = ticket?.customer;
                    let customerAddressNumber = customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || '';

                    final_address = {
                        bairro: customerAddress?.bairro_cliente || '',
                        cep: customerAddress?.cep_cliente || '',
                        cidade: customerAddress?.cidade_cliente || '',
                        uf: customerAddress?.estado_cliente || '',
                        rua: customerAddress?.endereco_cliente || '',
                        numero: customerAddressNumber,
                        complemento: customerAddress?.compl_ref_obs_endereco_cliente || '',
                        destinatario: '',
                    }
                }

                setFormData(prevState => ({
                    ...prevState,
                    ...final_address,
                    centro_custo: ticket?.contract?.centro_custo || '',
                }));

            }

            if (mode === 'centro_custo') {
                setFormData(prevState => ({
                    ...prevState,
                    centro_custo: ticket?.contract?.centro_custo || ''
                }))
            }

        } else {
            handleErrorsToast('Ocorreu um erro ao buscar os dados do chamado!', ticketData?.data);
        }
    }

    function handleLocal(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, box: '' }))
    }

    function handleValues(param){
        let value = param?.label || param || '';
        
        if(typeof value === 'undefined'){
            return '';
        }

        if(typeof value === 'object' && value?.label === ''){
            return '';
        }

        if(value === ''){
            return '';
        }

        return value;
    }

    return (
        <Form onSubmit={submitForm}>
            <Row>
                <Col>
                    <h5>{title}</h5>
                </Col>
            </Row>
            <Row>
                <If condition={handleFields('fabricante')}>
                    <Col sm='12' md='4' className="mt-1">
                        <FormLabel htmlFor="fabricante">Fabricante</FormLabel>
                        <FormControl type="text" name="fabricante" id="fabricante" placeholder='Fabricante' value={formData?.fabricante || ''} readOnly />
                    </Col>
                </If>
                <If condition={handleFields('modelo')}>
                    <Col sm='12' md='4' className="mt-1">
                        <FormLabel htmlFor="modelo">Nome Produto</FormLabel>
                        <FormControl type="text" name="modelo" id="modelo" placeholder='Nome Produto' value={formData?.modelo || ''} readOnly />
                    </Col>
                </If>
                <If condition={handleFields('serial')}>
                    <Col sm='12' md='4' className="mt-1">
                        <FormLabel htmlFor="serial">Serial</FormLabel>
                        <FormControl type="text" name="serial" id="serial" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                    </Col>
                </If>
                <If condition={handleFields('nome_produto')}>
                    <Col sm='12' md='4' className="mt-1">
                        <FormLabel htmlFor="nome_produto">Tipo Produto</FormLabel>
                        <FormControl type="text" name="nome_produto" id="nome_produto" placeholder='Tipo Produto' value={formData?.nome_produto || ''} readOnly />
                    </Col>
                </If>
            </Row>
            <If condition={parentPage !== 'SignDetails'}>
                <hr></hr>
                <Row>
                    <Col>
                        <h6>Detalhes da Remessa</h6>
                    </Col>
                </Row>
                <Row>
                    <If condition={handleFields('local')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="local">UF</FormLabel>
                            <FormControl type="text" name="local" id="local" placeholder='...' value={handleValues(formData?.local)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('local_select')}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 4, 4, 4]} genericOption='UfOptions' advancedSetFormData={handleLocal} required={handleRequired('local_select')}></GenericSelect>
                    </If>
                    <If condition={handleFields('box')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="box">Estoque</FormLabel>
                            <FormControl type="text" name="box" id="box" placeholder='...' value={handleValues(formData?.box)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('box_select')}>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} uf={formData?.local?.value} required={handleRequired('box_select')}></BoxSelect>
                    </If>
                    <If condition={handleFields('urgencia_remessa')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="urgencia_remessa">Urgência Remessa</FormLabel>
                            <FormControl type="text" name="urgencia_remessa" id="urgencia_remessa" placeholder='...' value={handleValues(formData?.urgencia_remessa)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('urgencia_remessa_select')}>
                        <GenericSelect fieldName={'urgencia_remessa'} formData={formData} setFormData={setFormData} title={'Urgência da Remessa'} cols={[12, 12, 4, 4]} genericOption='UrgenciaRemessaOptions' required={handleRequired('urgencia_remessa_select')}></GenericSelect>
                    </If>
                    <If condition={handleFields('id_tecnico')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="id_tecnico">Técnico</FormLabel>
                            <FormControl type="text" name="id_tecnico" id="id_tecnico" placeholder='...' value={handleValues(formData?.id_tecnico)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('id_tecnico_select')}>
                        <AsyncTecnicosSelect formData={formData} setFormData={setFormData} fieldName="id_tecnico" cols={[12, 3, 3, 3]} required={handleRequired('id_tecnico_select')}></AsyncTecnicosSelect>
                    </If>
                    <If condition={handleFields('tipo_remessa')}>
                        <Col sm='12' md='4'>
                            <FormLabel html='tipo_remessa'>Tipo Remessa</FormLabel>
                            <FormControl type="text" name="tipo_remessa" id="tipo_remessa" placeholder='...' value={handleValues(formData?.tipo_remessa)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('tipo_remessa_select')}>
                        <ModuleOptionsSelect formData={formData} setFormData={setFormData} field={'tipo_remessa'} fieldName={'tipo_remessa'} module={'produtos'} title={'Tipo Remessa'} cols={[12, 4, 4, 4]} responseValueField='chave_opcao' required={handleRequired('tipo_remessa_select')}></ModuleOptionsSelect>
                    </If>
                    <If condition={handleFields('status_remessa')}>
                        <Col sm='12' md='4'>
                            <FormLabel html='status_remessa'>Status Remessa</FormLabel>
                            <FormControl type="text" name="status_remessa" id="status_remessa" placeholder='...' value={formData?.status_remessa || ''} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('id_chamado')}>
                        <Col sm='12' md='4'>
                            <FormLabel html='id_chamado'>ID da Localidade\ID do Chamado</FormLabel>
                            <FormControl type="text" name="id_chamado" id="id_chamado" placeholder='...' value={handleValues(formData?.id_chamado)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('id_chamado_select')}>
                        <AsyncChamadosSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='id_chamado' title='ID da Localidade\ID do Chamado' advancedSetFormData={handleChamado} required={handleRequired('id_chamado_select')}></AsyncChamadosSelect>
                    </If>
                </Row>
                <If condition={handleFields('endereco')}>
                    <Cep
                        formData={formData}
                        setFormData={setFormData}
                        formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua', numero: 'numero', complemento: 'complemento' }}
                        hasComplemento
                        hasNumero
                        readOnly={handleReadOnly('endereco')}
                        required={handleRequired('endereco')}
                    ></Cep>
                </If>
                <Row>
                    <If condition={handleFields('destinatario')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="destinatario">Destinatário</FormLabel>
                            <FormControl type="text" name="destinatario" id="destinatario" placeholder='...' value={formData?.destinatario || ''} onChange={handleChange} readOnly={handleReadOnly('destinatario')} required={handleRequired('destinatario')} />
                        </Col>
                    </If>
                    <If condition={handleFields('dimensoes')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="dimensoes">Dimensões</FormLabel>
                            <FormControl type="text" name="dimensoes" id="dimensoes" placeholder='...' value={formData?.dimensoes || ''} onChange={handleChange} readOnly={handleReadOnly('dimensoes')} required={handleRequired('dimensoes')} />
                        </Col>
                    </If>
                    <If condition={handleFields('centro_custo')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="centro_custo">Centro Custo</FormLabel>
                            <FormControl type="text" name="centro_custo" id="centro_custo" placeholder='...' value={formData?.centro_custo || ''} onChange={handleChange} readOnly={handleReadOnly('centro_custo')} required={handleRequired('centro_custo')} />
                        </Col>
                    </If>
                    <If condition={handleFields('peso_kg')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="peso_kg">Peso (kg)</FormLabel>
                            <FormControl type="number" step={"0.01"} min={"0"} name="peso_kg" id="peso_kg" placeholder='...' value={formData?.peso_kg || ''} onChange={handleChange} readOnly={handleReadOnly('peso_kg')} required={handleRequired('peso_kg')} />
                        </Col>
                    </If>
                    <If condition={handleFields('modal_envio')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="modal_envio">Modal de Envio</FormLabel>
                            <FormControl type="text" name="modal_envio" id="modal_envio" placeholder='...' value={handleValues(formData?.modal_envio)} onChange={handleChange} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('modal_envio_select')}>
                        <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} fieldName='modal_envio' required={handleRequired('modal_envio')}></ModalEnvioSelect>
                    </If>
                    <If condition={handleFields('responsavel_separacao')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="responsavel_separacao">Responsável pela Separação</FormLabel>
                            <FormControl type="text" name="responsavel_separacao" id="responsavel_separacao" placeholder='...' value={handleValues(formData?.responsavel_separacao)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('responsavel_separacao_select')}>
                        <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='responsavel_separacao' cols={[12, 4, 4, 4]} userType='all' required={handleRequired('responsavel_separacao_select')} title='Responsável pela Separação' userGroup='logistica_operacao'></UsuariosAtivosSelect>
                    </If>
                    <If condition={handleFields('data_recebido')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="data_recebido">Data de Recebimento</FormLabel>
                            <FormControl type="date" name="data_recebido" id="data_recebido" placeholder='Data de Recebimento' value={formData?.data_recebido || ''} onChange={handleChange} readOnly={handleReadOnly('data_recebido')} required={handleRequired('data_recebido')} />
                        </Col>
                    </If>
                    <If condition={handleFields('data_entrega')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="data_entrega">Data de entrega ao técnico</FormLabel>
                            <FormControl type="date" name="data_entrega" id="data_entrega" title='Data de entrega nas mãos do técnico' value={formData?.data_entrega || ''} onChange={handleChange} readOnly={handleReadOnly('data_entrega')} required={handleRequired('data_entrega')} />
                        </Col>
                    </If>
                </Row>
                <If condition={(parentPage !== 'ShipmentDetails' && ![formData?.modal_envio, formData?.modal_envio?.value].includes('Em Mãos'))}>
                    <hr></hr>
                    <Row>
                        <Col>
                            <h6>Detalhes da NFe</h6>
                        </Col>
                    </Row>
                </If>
                <Row>
                    <If condition={handleFields('rastreamento')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="rastreamento">Código de Rastreio</FormLabel>
                            <FormControl type="text" name="rastreamento" id="rastreamento" placeholder='Rastreamento' value={formData?.rastreamento || ''} onChange={handleChange} readOnly={handleReadOnly('rastreamento')} required={handleRequired('rastreamento')} />
                        </Col>
                    </If>
                    <If condition={handleFields('nfe_saida')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="nfe_saida">NFe de Saída</FormLabel>
                            <FormControl type="text" name="nfe_saida" id="nfe_saida" placeholder='NFe de Saída' value={formData?.nfe_saida || ''} onChange={handleChange} readOnly={handleReadOnly('nfe_saida')} required={handleRequired('nfe_saida')} />
                        </Col>
                    </If>
                    <If condition={handleFields('valor_equipamento_nf')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="valor_equipamento_nf">Valor do Equipamento na NFe</FormLabel>
                            <FormControl type="number" name="valor_equipamento_nf" id="valor_equipamento_nf" placeholder='Valor do Equipamento NFe' value={formData?.valor_equipamento_nf || ''} onChange={handleChange} readOnly={handleReadOnly('valor_equipamento_nf')} required={handleRequired('valor_equipamento_nf')} />
                        </Col>
                    </If>
                </Row>
                <Row>
                    <If condition={handleFields('volume_nfe')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="volume_nfe">Volume da NFe</FormLabel>
                            <FormControl type="number" name="volume_nfe" id="volume_nfe" placeholder='Volume da NFe' value={handleValues(formData?.volume_nfe)} readOnly />
                        </Col>
                    </If>
                    <If condition={handleFields('volume_nfe_select')}>
                        <GenericSelect fieldName={'volume_nfe'} formData={formData} setFormData={setFormData} title={'Volume da NFe'} cols={[12, 4, 4, 4]} genericOption='VolumeNfeOptions' required={handleRequired('volume_nfe_select')}></GenericSelect>
                    </If>
                    <If condition={handleFields('prazo_entrega')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="prazo_entrega">Prazo de Entrega</FormLabel>
                            <FormControl type="date" name="prazo_entrega" id="prazo_entrega" min={getCurrentDate(-1)} placeholder='Prazo de Entrega' value={formData?.prazo_entrega || ''} onChange={handleDataPostagemChange} readOnly={handleReadOnly('prazo_entrega')} required={handleRequired('prazo_entrega')} />
                        </Col>
                    </If>
                    <If condition={handleFields('data_postagem_remessa')}>
                        <Col sm='12' md='4'>
                            <FormLabel htmlFor="data_postagem_remessa">Data da Postagem</FormLabel>
                            <FormControl type="date" name="data_postagem_remessa" min={getCurrentDate(-1)} id="data_postagem_remessa" placeholder='Data da Postagem' value={formData?.data_postagem_remessa || ''} onChange={handleDataPostagemChange} readOnly={handleReadOnly('data_postagem_remessa')} required={handleRequired('data_postagem_remessa')} />
                        </Col>
                    </If>
                </Row>
                <If condition={handleFields('dact')}>
                    <Row>
                        <Col sm='12' md='6'>
                            <FormLabel htmlFor="dact">DACT</FormLabel>
                            <FormControl type="text" name="dact" id="dact" placeholder='DACT' value={formData?.dact || ''} onChange={handleChange} readOnly={handleReadOnly('dact')} required={handleRequired('dact')} />
                        </Col>
                        <Col sm='12' md='6'>
                            <FormLabel htmlFor="valor_dact">Valor DACT</FormLabel>
                            <FormControl type="number" min={0} step={0.01} name="valor_dact" id="valor_dact" placeholder='Valor DACT' value={formData?.valor_dact || ''} onChange={handleChange} readOnly={handleReadOnly('valor_dact')} required={handleRequired('valor_dact')} />
                        </Col>
                    </Row>
                </If>
            </If>
            <If condition={handleFields('assinatura')}>
                <hr></hr>
                <Row>
                    <Col>
                        <h6>Dados de Assinatura</h6>
                    </Col>
                </Row>
                <Row>
                    <Col sm='12' md='6'>
                        <FormLabel htmlFor="nome_recebedor">Nome Recebedor</FormLabel>
                        <FormControl type="text" name="nome_recebedor" id="nome_recebedor" placeholder='Nome Recebedor' value={formData?.nome_recebedor || ''} onChange={handleChange} readOnly={handleReadOnly('nome_recebedor')} required={handleRequired('nome_recebedor')} />
                    </Col>
                    <Col sm='12' md='6'>
                        <FormLabel htmlFor="cpf_recebedor">CPF Recebedor</FormLabel>
                        <FormControl type="text" name="cpf_recebedor" id="cpf_recebedor" placeholder='CPF Recebedor' ref={withMask(['999.999.999-99'])} value={formData?.cpf_recebedor || ''} onChange={handleChange} readOnly={handleReadOnly('cpf_recebedor')} required={handleRequired('cpf_recebedor')} />
                    </Col>
                    <Col sm='12'>
                        <FormLabel htmlFor="">Assinatura</FormLabel>
                    </Col>
                    <Col sm='12' style={{ backgroundColor: '#E9ECEF', padding: '10px', borderRadius: '15px' }}>
                        <If condition={handleFields('assinatura_img')}>
                            <img src={formData?.url_assinatura} alt="Sign" style={{ width: '100%', height: '250px', borderRadius: '10px' }} onError={(e) => onImageError(e, 'sign')} />
                        </If>
                        <If condition={handleFields('assinatura_canvas')}>
                            <ReactSignatureCanvas
                                penColor='black'
                                minWidth={1}
                                canvasProps={{ className: 'sigCanvas', style: { width: '100%', height: '250px', borderRadius: '10px' } }}
                                backgroundColor='rgb(255, 255, 255)'
                                ref={(data) => setSign(data)}
                                onEnd={() => setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))}
                            ></ReactSignatureCanvas>
                        </If>
                    </Col>
                </Row>
            </If>
            <If condition={mode !== 'view'}>
                <Row>
                    <Col sm='12' className="mt-1">
                        <If condition={parentPage === 'SignDetails'}>
                            <Button type="button" variant="warning" className="m-1" onClick={handleClear}>Limpar Assinatura</Button>
                        </If>
                        <Button variant="primary" type="submit">Enviar</Button>
                    </Col>
                </Row>
            </If>
        </Form>
    );
}