import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import Form from './Form';
import { toast } from 'react-toastify';
import { createComponent } from '../../../services/Repair';
import { getProductByProductId } from '../../../services/Product';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function CreateSparePart({visible = false, onHide = null, updateParent = null}){

    const INITIAL_STATE = {
        id_modelo: '',
        id_produto_origem: '',
        origem: '',
        componente: '',
        valor: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        let toastForm = toast.loading("Cadastrando Spare Parts, aguarde...");

        //Procurar dados em caso de canibalizado
        if(formData?.origem?.value === 'canibalizado'){
            let dataReturn = await getProductByProductId(formData?.id_produto_origem?.value);

            if(dataReturn?.data?.response === 'success'){
                let partnumber = dataReturn?.data?.data?.partnumber;
                formData['id_modelo'] = { value: partnumber?.id || '', label: partnumber?.modelo || '' };
            }
        }

        let params = {
            ...formData,
            origem: formData?.origem?.value || '',
            id_produto_origem: formData?.id_produto_origem?.value || '',
            id_modelo: formData?.id_modelo?.value || ''
        }

        let dataReturn = await createComponent(params);

        if(dataReturn?.data?.response === 'success'){
            handleSuccessToast('Componente adicionado com sucesso!', 'update', toastForm);
            setFormData(INITIAL_STATE);
            updateParent();
        }else{
            handleErrorsToast('Ocorreu um erro ao adicionar o componente', dataReturn?.data, 'update', toastForm);
        }
    }

    useEffect(()=>{
        setFormData(INITIAL_STATE);
    }, [visible]);

    return (<Dialog
        visible={visible}
        onHide={onHide}
        header='Cadastrar Componente'
        maximizable
    >
        <Form mode='create' submitForm={submitForm} formData={formData} setFormData={setFormData}></Form>
    </Dialog>)

}   