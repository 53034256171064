import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import AwaitingCancelShipmentDetails from './AwaitingCancelShipmentDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import If from '../../../../common/Layout/If';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast, openNewTab } from '../../../../configs/GenericFunctions';
import Swal from 'sweetalert2';
import AwaitingCancelProductDetails from './AwaitingCancelProductDetails';

export default function AwaitingCancel() {

    const [data, setData] = useState([]);
    const [awaitingCancelShipmentId, setAwaitingCancelShipmentId] = useState('');
    const [awaitingCancelProductId, setAwaitingCancelProductId] = useState('');
    const [awaitingCancelShipmentDetails, setOpenAwaitingCancelShipmentDetails] = useState(false);
    const [awaitingCancelProductDetails, setOpenAwaitingCancelProductDetails] = useState(false);
    const [viewProductMode, setViewProductMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const { checkAllPermissions, checkPermission } = usePermissions();
    const { action: actionPermissions } = getSubComponentsPermissions('mam_logistica', 'AwaitingCancel', 'AwaitingCancel');

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermissions[0])}>
                            <button type="button" className="dropdown-item" onClick={() => openAwaitingCancelShipmentDetails(row.id_remessa)}>Ver Remessa</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[1])}>
                            <button type="button" className="dropdown-item" onClick={() => openAwaitingCancelProductDetails(row)}>Ver Produto</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[2])}>
                            <button type="button" className="dropdown-item" onClick={() => openAwaitingCancelProductDetails(row, 'update-internal-description')}>Atualizar Produto</button>
                        </If>
                        <If condition={checkPermission(actionPermissions[3])}>
                            <button type="button" className="dropdown-item" onClick={() => showCancelShipmentSwal(row)}>Confirmar Cancelamento</button>
                        </If>
                    </div>
                </div>
            ),
            permission: checkAllPermissions(actionPermissions)
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<button type='button' className='btn btn-link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.id_localidade_produto}</button>)
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico'
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        }
    ];

    async function showCancelShipmentSwal(row) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            openAwaitingCancelProductDetails(row, 'update-cancel-product');
        }
    }



    async function getAwaitingCancel(page = 1, perPage = 100) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO CANCELAMENTO', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item) => ({
                id_remessa: item?.id || '',
                id_produto: item?.product?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || '',
                tecnico_produto: item?.ticket?.technician?.nome_tec || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setData([]);
        }
        setLoading(false);
    }

    function openAwaitingCancelShipmentDetails(id) {
        setOpenAwaitingCancelShipmentDetails(true);
        setAwaitingCancelShipmentId(id);
    }

    function openAwaitingCancelProductDetails(row, viewMode = 'view') {
        setOpenAwaitingCancelProductDetails(true);
        setAwaitingCancelProductId(row?.id_produto);
        setAwaitingCancelShipmentId(row?.id_remessa);
        setViewProductMode(viewMode);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getAwaitingCancel(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getAwaitingCancel(1, rows);
    }

    useEffect(() => {
        getAwaitingCancel();
    }, []);

    return (<Content headerTitle='Aguardando Cancelamento'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <AwaitingCancelShipmentDetails isOpen={awaitingCancelShipmentDetails} setModalOpen={() => setOpenAwaitingCancelShipmentDetails(!awaitingCancelShipmentDetails)} awaitingCancelShipmentId={awaitingCancelShipmentId}></AwaitingCancelShipmentDetails>
        <AwaitingCancelProductDetails isOpen={awaitingCancelProductDetails} setModalOpen={() => setOpenAwaitingCancelProductDetails(!awaitingCancelProductDetails)} productId={awaitingCancelProductId} viewMode={viewProductMode} setUpdateLogistic={setUpdateLogistic} setUpdateProducts={() => getAwaitingCancel(page + 1, perPage)} shipmentId={awaitingCancelShipmentId}></AwaitingCancelProductDetails>
        <Card title='Cadastro de Produtos - Aguardando Cancelamento'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
                id='awaiting_cancel'
                reorderableColumns
            ></PrimeTable>
        </Card>
    </Content>);
}