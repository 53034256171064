import React, { useRef, useState } from 'react';
// import Fab from '@mui/material/Fab';
import { Link, useNavigate } from 'react-router-dom';
import GenericSelect from '../Selects/GenericSelect';
import { FormControl, InputGroup } from 'react-bootstrap';

export default function FloatSearchButton({link = '/chamados/dados/all?data_inicial=2023-01-01&'}) {
    const INITIAL_STATE = {
        search_input: '',
        select_input: {value: 'num_chamado', label: 'Nº Chamado'}
    }
    const [searchDivState, setSearchDivState] = useState(false);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const navigate = useNavigate();
    
    const fabRef = useRef();
    const inputRef = useRef(null);
    const searchDivRef = useRef(null);

    function handleSearchDiv(){
        if(searchDivState){
            fabRef.current.style.opacity = 0.5;
            setFormData(INITIAL_STATE);
            searchDivRef.current.style.zIndex = '-1';
            setSearchDivState(false);
        }

        if(!searchDivState){
            fabRef.current.style.opacity = 1;
            setSearchDivState(true);
            searchDivRef.current.style.zIndex = '9999';
            setTimeout(()=>{
                inputRef.current.focus();
            }, 50);
        }
    }

    function handleKeyUp(e) {
        if (e.key === 'Enter') {
            navigate(`${link}${formData?.select_input?.value}=${formData?.search_input}`);
            handleSearch();
        }
    }

    function handleSearch(){
        handleSearchDiv();
    }

    return (
        <div>
            <div ref={fabRef} onClick={()=>handleSearchDiv()} className='bg-blue-300 z-3 text-0 border-circle cursor-pointer flex align-items-center justify-content-center shadow-1 hover:bg-blue-500' style={{ position: 'fixed', top: 125, right: 10, width: '40px', height: '40px'}}>
                <i className='fas fa-search'></i>
            </div>
            {/* <Fab ref={fabRef} size='small' color="primary" aria-label="pesquisar" sx={{ position: 'fixed', top: 125, right: 10, opacity: 0.5, '&:hover': { opacity: 1}}} onClick={()=>handleSearchDiv()}>
                <i className='fas fa-search'></i>
            </Fab> */}
            <div style={{ position: 'fixed', top: 125, right: 60 }} className={searchDivState ? 'block' : 'hidden'} ref={searchDivRef}>
                <InputGroup>
                    <GenericSelect 
                        formData={formData} 
                        setFormData={setFormData} 
                        cols={[4, 4, 4, 4]} 
                        fieldName={'select_input'} 
                        genericOption='FiltersOptions' 
                        required 
                        title='' 
                        isClearable={false} 
                        needPermissions
                    ></GenericSelect>
                    <FormControl ref={inputRef} placeholder={`${formData?.select_input?.label}`} name='search_input' value={formData?.search_input || ''} onChange={(e)=>setFormData(prev => ({...prev, [e.target.name]: e.target.value}))} onKeyUp={handleKeyUp}></FormControl>
                    <Link to={`${link}${formData?.select_input?.value}=${formData?.search_input}`} type='button' className='btn btn-primary ml-2' onClick={()=>handleSearch()}>Pesquisar</Link>
                </InputGroup>
            </div>
        </div>);
}