import React, { useEffect, useState } from 'react';
import Card from '../../../../common/Layout/Card';
import If from '../../../../common/Layout/If';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import CreateProduct from './CreateProduct';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import Swal from 'sweetalert2';
import { checkValue, handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { toast } from 'react-toastify';
import { changeShipmentStatus } from '../../../../services/Shipment';
import UpdateProduct from './UpdateProduct';
import UpdateShipmentStatus from './UpdateShipmentStatus';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';

export default function EquipamentoRemessaCard({
    showUpdateBtn = false,
    detailsData = {},
    getHistory = null,
    id = '',
    equipmentsData = [],
    getEquipmentsByIdStatus = null,
    setConfirmedShipments = null,
    originalData = {},
    confirmedShipments = []
}) {

    const [updateShipmentMode, setUpdateShipmentMode] = useState('edit');
    const [isAddEquipmentOpen, setIsAddEquipmentOpen] = useState(false);
    const [isUpdateShipmentOpen, setIsUpdateEquipmentOpen] = useState(false);
    const [isUpdateShipmentStatusOpen, setIsUpdateShipmentStatusOpen] = useState(false);
    const [dadosRemessa, setDadosRemessa] = useState(null);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { page: pagePermissions, action: actionPermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'EquipamentoRemessaCard');

    const equipmentsColumns = [
        {
            id: 'action_equipamento_remessa',
            name: 'Ação',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(actionPermissions[1])}>
                        <If condition={detailsData?.status_chamado?.value === 'Fechado' && originalData?.status_chamado !== 'Fechado'}>
                            <DropdownItem onClick={() => showUpdateShipmentStatus(row, 'confirm')}>Confirmar Remessa</DropdownItem>
                        </If>
                        <DropdownItem onClick={() => showUpdateShipmentStatus(row, 'edit')}>Alterar Status Remessa</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[2]) && row.status_remessa === "PRODUTO PENDENTE"}>
                        <DropdownItem onClick={() => showUpdateProductShipment(row)}>Alterar Produto da Remessa</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[0]) && !['CANCELADA', 'AGUARDANDO CANCELAMENTO', 'REMESSA ENTREGUE AO DESTINATÁRIO'].includes(row.status_remessa)}>
                        <DropdownItem onClick={() => showCancelShipmentSwal(row)}>Cancelar Remessa</DropdownItem>
                    </If>
                </DropdownButton>
            ),
            permission: checkAllPermissions(actionPermissions) && showUpdateBtn
        },
        {
            id: 'part_number_equipamento_remessa',
            name: 'Nome Produto',
        },
        {
            id: 'serial_equipamento_remessa',
            name: 'Serial',
        },
        {
            id: 'tipo_produto_remessa',
            name: 'Tipo Produto',
        },
        {
            id: 'fabricante_remessa',
            name: 'Fabricante'
        },
        {
            id: 'modal_envio_equipamento_remessa',
            name: 'Modal Envio',
        },
        {
            id: 'data_postagem_equipamento_remessa',
            name: 'Data da Postagem',
        },
        {
            id: 'rastreamento_equipamento_remessa',
            name: 'Rastreamento',
        },
        {
            id: 'status_remessa',
            name: 'Status Remessa'
        },
        {
            id: 'status_transportadora_equipamento_remessa',
            name: 'Status Transportadora',
        },
        {
            id: 'prazo_entrega_equipamento_remessa',
            name: 'Prazo de Entrega',
        }
    ];

    function updateThis() {
        getEquipmentsByIdStatus();
        getHistory();
    }

    async function showCancelShipmentSwal(row) {
        let mySwal = await Swal.fire({
            title: 'Deseja cancelar a remessa?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (mySwal.isConfirmed) {
            cancelShipment(row.id, row.status_remessa);
        }
    }

    async function showUpdateProductShipment(row) {
        setDadosRemessa(row);
        setIsUpdateEquipmentOpen(true);
    }

    async function showUpdateShipmentStatus(row, mode) {
        setDadosRemessa(row);
        setIsUpdateShipmentStatusOpen(true);
        setUpdateShipmentMode(mode);
    }

    async function cancelShipment(id, status) {
        const toastProducts = toast.loading("Cancelando remessa, aguarde...");

        let status_remessa = ['AGUARDANDO ENVIO', 'PRODUTO PENDENTE'].includes(status) ? 'CANCELADA' : 'AGUARDANDO CANCELAMENTO';

        let dataReturn = await changeShipmentStatus(id, { status_remessa: status_remessa });

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Remessa cancelada com sucesso!', 'update', toastProducts);
            getEquipmentsByIdStatus();
        } else {
            handleErrorsToast('Ocorreu um erro ao cancelar a remessa!', dataReturn?.data, 'update', toastProducts);
        }
    }

    const conditionRow = (row) => {
        return {
            "bg-red-200": !confirmedShipments.includes(row?.id_remessa) && detailsData?.status_chamado?.value === 'Fechado' && originalData?.status_chamado !== 'Fechado',
        }
    }

    function confirmShipment(id_remessa) {
        setConfirmedShipments(p => {
            let equipments = [...p];
            let hasEquipment = equipments.find(item => item === id_remessa);

            if (checkValue(hasEquipment)) {
                equipments.push(id_remessa);
            }

            return equipments;
        });
    }

    useEffect(() => {
        getEquipmentsByIdStatus();
    }, []);

    return (<Card title="Equipamento Remessa" icon="fa-keyboard">
        <CreateProduct
            id_chamado={id}
            num_chamado={detailsData?.num_chamado}
            isOpen={isAddEquipmentOpen}
            setIsOpen={() => setIsAddEquipmentOpen(!isAddEquipmentOpen)}
            updateParent={updateThis}
            detailsData={detailsData}
            equipmentsData={equipmentsData}
        ></CreateProduct>
        <UpdateProduct
            dados_remessa={dadosRemessa}
            isOpen={isUpdateShipmentOpen}
            setIsOpen={() => setIsUpdateEquipmentOpen(!isUpdateShipmentOpen)}
            updateParent={updateThis}
        ></UpdateProduct>
        <UpdateShipmentStatus
            dados_remessa={dadosRemessa}
            isOpen={isUpdateShipmentStatusOpen}
            setIsOpen={() => setIsUpdateShipmentStatusOpen(!isUpdateShipmentStatusOpen)}
            updateParent={updateThis}
            mode={updateShipmentMode}
            setMode={setUpdateShipmentMode}
            confirmShipment={confirmShipment}
        ></UpdateShipmentStatus>
        <Row>
            <If condition={(showUpdateBtn && checkPermission(pagePermissions[0]))}>
                <Col xs='6'>
                    <Button variant='primary' onClick={() => setIsAddEquipmentOpen(!isAddEquipmentOpen)}>Adicionar Equipamento de Remessa/Spare</Button>
                </Col>
            </If>
        </Row>
        <Row>
            <Col xs='12'>
                <PrimeTable
                    columns={equipmentsColumns}
                    rows={equipmentsData}
                    id='equipment_prime_table'
                    needExport={false}
                    customStyles={conditionRow}
                ></PrimeTable>
            </Col>
        </Row>
    </Card>);
}