import interceptorInstance from "./Interceptor";

export async function getExternalMessages(id, page = 1){
    try{
        let data = await interceptorInstance.get(`external-chat/channel/${id}/message?page=${page}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function sendExternalMessages(id, params){
    try{
        let data = await interceptorInstance.post(`external-chat/channel/${id}/message`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function seenExternalMessage(id, params){
    try{
        let data = await interceptorInstance.patch(`external-chat/message/${id}/seen`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function seenBatchExternalMessages(params){
    try{
        let data = await interceptorInstance.patch(`external-chat/message/seen-batch`, params);
        return data;
    }catch(e){
        return e.response;
    }
}