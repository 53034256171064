import interceptorInstance from "./Interceptor";

export async function getOptionsByModuleField(module, field){
    try{
        let data = await interceptorInstance.get(`options/${module}/${field}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllOptions(){
    try{
        let data = await interceptorInstance.get('options/');
        return data;
    }catch(e){
        return e.response;
    }
}

export async function removeOption(id){
    try{
        let data = await interceptorInstance.delete(`options/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function addOption(params){
    try{
        let data = await interceptorInstance.post(`options/`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getOptionById(id){
    try{
        let data = await interceptorInstance.get(`options/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function activeDeactiveOption(id, params){
    try{
        let data = await interceptorInstance.patch(`options/active-deactive/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateOptionById(id, params){
    try{
        let data = await interceptorInstance.put(`options/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

