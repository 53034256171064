import React, { useEffect, useState } from 'react';

import styles from './TechniciansByCities.module.css';

import TechniciansChartContent from './Components/TechniciansChartContent';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';
import { Link, useParams } from 'react-router-dom';
import { getCurrentDate, handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import { getTechnicianByStateFilter, getTechniciansByCityAndState } from '../../../services/Technician';
import { toast } from 'react-toastify';
import If from '../../../common/Layout/If';
import { Col, Row } from 'react-bootstrap';

export default function TechniciansByCities() {
    const { estado, cidade } = useParams();
    const [data, setData] = useState([]);
    let interval;
    const milliseconds = 600000; //10 minutos
    const columns = [
        {
            id: 'pais',
            name: 'País',
            body: (row) => (<Link to={'/chamados/capilaridade-tecnicos'}>{row?.pais}</Link>)
        },
        {
            id: 'estado',
            name: 'Estado',
            body: (row) => (<Link to={'/chamados/capilaridade-tecnicos/qtd/' + row?.estado}>{row?.estado}</Link>)
        },
        {
            id: 'cidade',
            name: 'Cidade',
            body: (row) => (<Link to={'/chamados/capilaridade-tecnicos/tecnicos/' + row?.estado + '/' + row?.cidade}>{row?.cidade}</Link>)
        },
        {
            id: 'representante',
            name: 'Representante',
            body: (row) => {
                if (row?.representante?.length > 0) {
                    return  row?.representante?.map((item, idx) => (
                        <div key={`${item?.nome_representante}_${item?.id}_${idx}`} className={`m-1 ${styles.contracts_partners}`}>
                            <Link to={'/cadastros/representantes/detalhes/' + item?.id}>{item?.nome_representante}</Link>
                        </div>))
                }else{
                    return "Todos"
                }
            }
        },
        {
            id: 'tecnico',
            name: 'Técnico',
            body: (row) => (<Link to={'/cadastros/tecnicos/detalhes/' + row?.id_tecnico}>{row?.tecnico}</Link>)
        },
        {
            id: 'contratos',
            name: 'Contratos',
            body: (row) => {
                if (row?.contratos?.length > 0) {
                    return row?.contratos?.map((item, idx) => (
                        <div key={`${item?.contrato}_${item?.id}_${idx}`} className={`m-1 ${styles.contracts_partners}`}>
                            <Link to={'/cadastros/contratos/detalhes/' + item?.id}>{item?.contrato}</Link>
                        </div>));
                }else{
                    return "Todos"
                }
            }
        }
    ];

    useEffect(() => {
        async function getTechniciansByStateAndCity() {
            const toastData = toast.loading('Carregando dados, aguarde...');

            let data_agendamento = getCurrentDate();

            let dataReturn;
            if (['', undefined, null, false, 'all'].includes(cidade)) {
                dataReturn = await getTechnicianByStateFilter(data_agendamento, estado);
            } else {
                dataReturn = await getTechniciansByCityAndState(data_agendamento, estado, cidade);
            }
            if (dataReturn?.status === 200) {
                let techniciansData = dataReturn?.data?.data?.tecnicos_disponiveis || [];

                let dataToTable = techniciansData?.map(item => ({
                    pais: 'Brasil',
                    estado: item?.estado_end, cidade: item?.cidade_end || '',
                    id_tecnico: item?.id || '',
                    tecnico: item?.nome_tec || '',
                    representante: item?.partners?.map(item => ({ id: item?.id || '', nome_representante: item?.nome_representante || '' })) || [],
                    id_representante: item?.id_representante || '',
                    contratos: item?.contracts?.map(item => ({ id: item?.id || '', contrato: item?.contrato || '' })) || []
                }))
                setData(dataToTable);
                handleSuccessToast('Dados carregados com sucesso!', 'update', toastData);
            } else {
                handleErrorsToast('Ocorreu um erro:', dataReturn?.data, 'update', toastData);
            }
        }

        getTechniciansByStateAndCity();

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getTechniciansByStateAndCity();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };
    }, [estado, cidade]);
    return (<TechniciansChartContent>
        <Row>
            <Col sm='12'>
                <If condition={['', undefined, null, false, 'all'].includes(cidade)}>
                    <Link to={'/chamados/capilaridade-tecnicos'} role='button' className='btn btn-warning'>Ver todas os Estados</Link>
                </If>
                <If condition={!['', undefined, null, false, 'all'].includes(cidade)}>
                    <Link to={'/chamados/capilaridade-tecnicos/qtd/' + estado} role='button' className='btn btn-warning'>Ver todas as cidades</Link>
                </If>
            </Col>
            <Col sm='12' className="text-center">
                <If condition={['', undefined, null, false, 'all'].includes(cidade)}>
                    <h2>Técnicos por Estado - {estado}</h2>
                </If>
                <If condition={!['', undefined, null, false, 'all'].includes(cidade)}>
                    <h2>Técnicos por Cidade e Estado - {cidade}-{estado}</h2>
                </If>
            </Col>
            <Col sm='12'>
                <PrimeTable
                    columns={columns}
                    rows={data}
                    id='technicians_city_table'
                ></PrimeTable>
            </Col>
        </Row>

    </TechniciansChartContent>)
}