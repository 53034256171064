import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getActiveContractValues, getContractsValuesByContractId } from '../../services/ContractValues';
import { Col, FormLabel } from 'react-bootstrap';

export default function ValoresContratosSelect({ formData, setFormData, fieldName = 'valor_contrato', cols = [12, 12, 12, 12], isDisabled = false, required = false, id_contrato = '', advancedSetFormData=null }) {
    const [valoresContratoOptions, setValoresContratoOptions] = useState([]);

    async function getAllValoresContratoByIdContrato(id) {
        let dataReturn = await getActiveContractValues(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let contracts = dataReturn.data.data.map((item, idx) => {
                return { value: item?.id, label: item?.escopo }
            });

            setValoresContratoOptions(contracts);
        } else {
            setValoresContratoOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        if(!['', undefined, null].includes(id_contrato)){
            getAllValoresContratoByIdContrato(id_contrato);
        }
    }, [id_contrato]);

    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Valores Contrato</FormLabel>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[...valoresContratoOptions]}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </Col>
    );
}