import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleErrorsToast, handleSuccessToast } from "../../../configs/GenericFunctions";
import TagForm from "./Components/TagForm";
import { createTag } from "../../../services/Tags";

export default function CreateTag({ isOpen = false, setIsOpen = null, updateParent = null }) {

    const INITIAL_STATE = {
        nome: '',
        cor_hexadecimal: '808080',
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e){
        e.preventDefault();

        const toastData = toast.loading("Cadastrando Tag, aguarde...");

        let dataReturn = await createTag(formData);

        if(dataReturn?.status === 201){
            handleSuccessToast('Tag adicionado com sucesso!', 'update', toastData);
            updateParent();
            setFormData(INITIAL_STATE);
        }else{
            handleErrorsToast('Ocorreu um ou mais erros!', dataReturn?.data, 'update', toastData);
        }
    }
    
    useEffect(()=>{
        setFormData(INITIAL_STATE);
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Tag'
            maximizable
        >
           <TagForm type="create" formData={formData} setFormData={setFormData} submitForm={submitForm}></TagForm>
        </Dialog>
    );
}