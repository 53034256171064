import React, { useContext, useEffect, useRef, useState } from 'react';
import maminfoLogo from '../../resources/imgs/logo/maminfo.png';
import { Context } from '../../App';
import Message from './Message';
import { getChannelsByTicketId, seenBatchMessages, sendMessageByChannelId, updateChannelMembers, updateChannelTypeById } from '../../services/Chat';
import ChatDetails from './ChatDetails';
import echo from '../../services/LaravelEcho';
import { Fab } from '@mui/material';
import useChat from '../../hooks/useChat';
import { ChatContext } from './MainChatComponent';
import { seenBatchExternalMessages, sendExternalMessages } from '../../services/ExternalChat';
import If from '../Layout/If';
import usePermissions from '../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import { handleErrorsToast, openNewTab } from '../../configs/GenericFunctions';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormControl, InputGroup, Nav, NavItem, NavLink, Row } from 'react-bootstrap';

export default function MessagesChat({ style, openContacts, isTablet }) {
    const initialState = {
        message: ''
    }

    const { getChannelImage, getChatTypeByRoute } = useChat();
    const userData = useContext(Context);
    const { chatState, setChatState } = useContext(ChatContext);
    const [messageToSend, setMessageToSend] = useState(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const [scrollOnBottom, setScrollOnBottom] = useState(true);
    const [scrollOnTop, setScrollOnTop] = useState(false);
    const [firstMessageLoad, setFirstMessageLoad] = useState(true);
    const [tabsChannels, setTabsChannels] = useState([]);
    const [currentScrollHeight, setCurrentScrollHeight] = useState(0);
    const [disableEnter, setDisableEnter] = useState(false);

    const messageRef = useRef();
    const chatRef = useRef();
    const fabRef = useRef();
    const imgRef = useRef();

    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_talks', 'MainChat', 'MessagesChat');

    const STYLE = {
        ...style,
        backgroundColor: chatState?.channelId === '' ? '#222D32' : ''
    }

    function renderMessages() {
        return (chatState?.messages?.map((item, idx) => {
            let message = item.message_type === 'text' ? item.message : item.file_url;
            let cliente = item?.sent_by?.cliente === 1 ? 'sim' : 'nao';
            if (item?.sent_by?.id === userData?.userDataState?.id_usuario) {
                let status = item?.seen_by.length > 1 ? 'Visto' : '...'
                return <Message message={message} type='user' status={status} sentName={item?.sent_by?.name} key={idx} messageType={item?.message_type} date={item?.created_at} cliente={cliente}></Message>
            }
            return (
                <Message message={message} type='other' sentName={item?.sent_by?.name} key={idx} messageType={item?.message_type} date={item?.created_at} cliente={cliente}></Message>
            )
        }) || []);
    }

    function renderMessageHeader() {
        let channelImg = getChannelImage(chatState?.channelData?.users || [], 40, 19);

        return (
            <div className="flex flex-row align-items-center">
                <If condition={!isTablet && getChatTypeByRoute() !== 'externo'}>
                    <i className='fas fa-arrow-left mr-1' style={{ cursor: 'pointer' }} onClick={() => {
                        if (getChatTypeByRoute() !== 'externo') {
                            openContacts()
                        }
                    }}></i>
                </If>
                <div className={`flex flex-row ${chatState?.channelData?.users?.length < 3 ? '' : 'flex-wrap'} justify-content-center align-items-center`} style={{
                    border: '1px solid gray',
                    borderRadius: '20px',
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    marginRight: '10px',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        if (getChatTypeByRoute() !== 'externo') {
                            openContacts()
                        }
                    }}>
                    {channelImg}
                </div>
                <div className="text-truncate">
                    <span><b>{chatState?.channelData?.worked_channel_name}</b></span>
                </div>
            </div>)
    }

    async function changeChannelType(newType = 'channel') {
        let formData = {
            type: newType,
            user_id: [userData?.userDataState?.id_usuario],
            action: 'add'
        }
        let dataReturn = await updateChannelTypeById(chatState?.channelId, formData);
        let updateMembersDataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success' && updateMembersDataReturn?.data?.response === 'success') {
            let updateMembersData = updateMembersDataReturn?.data?.data;
            setChatState(prevState => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    type: updateMembersData?.type || '',
                    users: updateMembersData?.users || []
                }
            }));
        }
    }

    async function sendMessage() {
        setDisableEnter(true);
        let formData = new FormData();
        formData.append('message', messageToSend?.message);
        formData.append('file', '');

        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await sendExternalMessages(chatState?.channelId, formData);
        } else {
            dataReturn = await sendMessageByChannelId(chatState?.channelId, formData);
        }

        if (dataReturn?.data?.response === 'success') {
            setMessageToSend(initialState);
            messageRef.current.focus();

            let usersId = chatState?.channelData?.users?.map((item, idx) => {
                return item?.id;
            });

            if (chatState?.channelData?.type === 'livechat' && !usersId.includes(userData?.userDataState?.id_usuario)) {
                changeChannelType();
            }
        } else {
            handleErrorsToast('Ocorreu um erro ao enviar a mensagem!', dataReturn?.data);
        }
        setDisableEnter(prev => !prev);

    }

    function readMessage(messageId) {
        let formData = {
            user_id: [userData?.userDataState?.id_usuario],
            message_id: messageId
        }

        if (getChatTypeByRoute() === 'externo') {
            seenBatchExternalMessages(formData).then((dataReturn) => {
                if (dataReturn?.data?.response === 'success') {
                    let messages = dataReturn?.data?.data;
                    let newMessages = [...chatState.messages];
                    for (let message of messages) {
                        let idx = chatState.messages.findIndex(obj => obj._id === message._id);
                        if (idx !== -1) {
                            newMessages[idx] = message;
                        }
                    }

                    setChatState((prevState) => ({ ...prevState, messages: newMessages }));
                }
            });
        } else {
            seenBatchMessages(formData).then((dataReturn) => {
                if (dataReturn?.data?.response === 'success') {
                    let messages = dataReturn?.data?.data;
                    let newMessages = [...chatState.messages];
                    for (let message of messages) {
                        let idx = chatState.messages.findIndex(obj => obj._id === message._id);
                        if (idx !== -1) {
                            newMessages[idx] = message;
                        }
                    }

                    setChatState((prevState) => ({ ...prevState, messages: newMessages }));
                }
            });
        }
    }

    function handleScroll() {
        const element = chatRef.current;
        if (element) {

            if (element.scrollTop === 0) {
                setCurrentScrollHeight(element.scrollHeight);
                setScrollOnTop(true);
                return;
            }

            if ((element.scrollTop + element.clientHeight) >= (element.scrollHeight - 2)) {
                setScrollOnBottom(true);
            } else {
                setScrollOnBottom(false);
            }

            setScrollOnTop(false);

        }
    }

    function workSendMessages() {

        let unreadMessages = chatState?.messages.filter((item) => {
            let seenId = item.seen_by.map((item2) => {
                return item2.id;
            });

            if (!seenId.includes(userData?.userDataState?.id_usuario)) {
                return item;
            }
        }).map((item) => item._id);
        if (unreadMessages?.length > 0) {
            readMessage(unreadMessages);
        }
    }

    function checkUnreadMessage() {
        let count = 0;
        chatState?.messages.forEach((item) => {

            let seenId = item.seen_by.map((item2) => {
                return item2.id;
            });

            if (!seenId.includes(userData?.userDataState?.id_usuario)) {
                count += 1;
            }
        });

        if (count > 0) {
            return count;
        }
    }

    function goToBottom() {
        const element = chatRef.current;

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }

    function handleImgChange(e) {
        const files = Array.prototype.slice.call(e.target.files);

        for (let f of files) {
            let formData = new FormData();
            formData.append('file', f)

            sendImg(formData);
        }
    }

    async function sendImg(formData) {

        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await sendExternalMessages(chatState?.channelId, formData);
        } else {
            dataReturn = await sendMessageByChannelId(chatState?.channelId, formData);
        }

        if (dataReturn?.data?.response !== 'success') {
            handleErrorsToast('Ocorreu um erro ao enviar imagem!', dataReturn?.data);
        }
    }

    async function getTabsChannelsByTicketId(ticketId) {
        let dataReturn = await getChannelsByTicketId(ticketId);

        if (dataReturn?.data?.response === 'success') {
            let channelsData = dataReturn?.data?.data || [];
            setTabsChannels(channelsData);
        } else {
            setTabsChannels([]);
        }
    }

    function renderTabs() {
        let tabsArray = [...tabsChannels];
        tabsArray[0] = tabsArray.splice(tabsArray.findIndex(item => item._id === chatState?.channelId), 1, tabsArray[0])[0];

        return tabsArray.map((item) => {
            return (
                <NavItem key={item?._id + "_tab"}>
                    <NavLink 
                        key={item?._id + "_tab"} 
                        className={`${item?._id === chatState?.channelId ? 'active font-bold' : ''}`} 
                        id={item?._id + "_tab"} 
                        onClick={() => setChatState(prevState => ({ ...prevState, channelId: item._id }))} 
                        aria-controls={item?._id} aria-selected="false">
                            {item?.channel_name || 'Sem Nome'} 
                            {item?.pending_message_count > 0 && <span className="badge badge-warning float-right">{item?.pending_message_count}</span>}
                    </NavLink>
                </NavItem>
            )
        });
    }

    function getMoreMessages() {
        setChatState(prev => ({ ...prev, page: prev?.page + 1, moreMessages: true }));
    }

    useEffect(() => {
        if (chatState?.channelId !== '') {
            const channel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}channel.${chatState?.channelId}`);

            channel.listen('MessageSent', (e) => {
                setChatState((prevState) => ({
                    ...prevState,
                    messages: [...prevState.messages, e.message]
                }))
            });

            channel.listen('MessageRead', (e) => {
                setChatState((prevState) => {
                    let idx = prevState.messages.findIndex(obj => obj._id === e.message._id)

                    if (idx !== -1) {
                        const newMessages = [...prevState.messages];
                        newMessages[idx] = e.message;
                        return {
                            ...prevState,
                            messages: newMessages
                        };
                    }

                    return prevState;
                })
            });

            channel.listen('SpecificChannelUpdate', (e) => {
                if (e.notification === 'channel_update') {
                    setChatState((prevState) => ({
                        ...prevState,
                        updateChat: true
                    }))
                }
            })

            return () => {
                echo.leave(`${process.env.REACT_APP_WEBSOCKET_PREFIX}channel.${chatState?.channelId}`);
            }
        }
    }, [chatState?.channelId]);

    useEffect(() => {
        if ((![null, undefined, ''].includes(chatState?.channelData?.ticket_id) || chatState?.ticketId !== '') && getChatTypeByRoute() === 'padrao') {
            let ticketId = chatState?.channelData?.ticket_id || chatState?.ticketId;
            getTabsChannelsByTicketId(ticketId);

            const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

            webSocketChannel.listen('ChannelUpdate', (e) => {
                if (e.notification === 'channel_update') {
                    getTabsChannelsByTicketId(ticketId);
                }
            })

            return () => {
                webSocketChannel.stopListening(`ChannelUpdate`);
            }
        }
    }, [chatState?.channelData]);

    function handleKeyUp(e) {
        if (e.key === 'Enter' && e.shiftKey) {
            setMessageToSend(prevState => ({ ...prevState, message: e.target.value }))
            return;
        }

        if (e.key === 'Enter' && !handleDisableSendMessage() && !disableEnter) {
            sendMessage();
        }
    }

    function handleDisableSendMessage() {
        return /^\s*$/.test(messageToSend?.message)
    }

    useEffect(() => {
        const element = chatRef.current;

        if (element) {
            if ((chatState?.messages[chatState?.messages?.length - 1]?.sent_by?.id === userData?.userDataState?.id_usuario || firstMessageLoad || scrollOnBottom) && !scrollOnTop) {
                element.scrollTop = element.scrollHeight;
                workSendMessages();
            }

            if (chatState?.messages?.length > 0) {
                setFirstMessageLoad(false);
            }

            if (scrollOnTop) {
                element.scrollTop = element.scrollHeight - currentScrollHeight;
                setScrollOnTop(false);
            }

            element.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        }
    }, [chatState?.messages]);

    return (
        <Card style={STYLE}>
            <ChatDetails isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)}></ChatDetails>
            <If condition={chatState?.channelId !== ''}>
                <If condition={((![null, undefined, ''].includes(chatState?.channelData?.ticket_id) || chatState?.ticketId !== '') && getChatTypeByRoute() === 'padrao' && tabsChannels.length > 0)}>
                    <div key='ticketTabs'>
                        <Nav variant='tabs'>
                            {renderTabs()}
                        </Nav>
                        {/* <nav className='nav nav-tabs' id='tabsNavMessages' role='tablist'>
                            {renderTabs()}
                        </nav> */}
                    </div>
                </If>
                <CardHeader style={{ height: '60px' }}>
                    <Row>
                        <Col xs='10' className="text-truncate">
                            {renderMessageHeader()}
                        </Col>
                        <Col xs='2'>
                            <If condition={getChatTypeByRoute() !== 'externo'}>
                                <div className="dropdown float-right">
                                    <button className="btn btn-success dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false' title='Opções...'><i className="fas fa-ellipsis-v"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <button type='button' className='dropdown-item' onClick={() => setIsOpen(prevState => !prevState)}>Dados do Canal</button>
                                        <If condition={chatState?.channelData?.ticket_id}>
                                            <button type='button' className='dropdown-item' onClick={() => openNewTab(`/chamados/detalhes-chamado/${chatState?.channelData?.ticket_id}`)}>Ir para Detalhes do Chamado</button>
                                        </If>
                                    </div>
                                </div>
                            </If>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody
                    ref={chatRef}
                    style={{
                        overflowY: 'scroll',
                        backgroundColor: '#222D32',
                        backgroundImage: `url(${maminfoLogo})`,
                        backgroundSize: '60%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        marginBottom: '2px',
                    }}
                >
                    <Row style={{ height: '100%' }}>
                        <Col>
                            <div className="flex justify-content-center">
                                <Button variant='secondary' type="button" style={{ opacity: 0.5 }} onClick={getMoreMessages}>
                                    <i className='fas fa-sync'></i> Carregar mensagens anteriores
                                </Button>
                            </div>
                            {
                                renderMessages()
                            }
                        </Col>
                        <If condition={scrollOnBottom === false}>
                            <Fab ref={fabRef} size='small' aria-label="new_messages" sx={{ position: 'absolute', bottom: 100, right: 25, opacity: 0.5, '&:hover': { opacity: 1 } }} onClick={goToBottom}>
                                {checkUnreadMessage()}
                                <i className='fas fa-arrow-down'></i>
                            </Fab>
                        </If>
                    </Row>
                </CardBody>
                <If condition={checkPermission(pagePermissions[0]) || getChatTypeByRoute() === 'externo'}>
                    <CardFooter>
                        <Row>
                            <Col>
                                <Form>
                                    <InputGroup className="mb-3">
                                        <FormControl as='textarea' ref={messageRef} aria-label="Mensagem..." value={messageToSend?.message || ''} onChange={(e) => setMessageToSend(prevState => ({ ...prevState, message: e.target.value }))} onKeyUp={(e) => handleKeyUp(e)} placeholder='Mensagem...' style={{ fieldSizing: 'content', maxHeight: '200px' }}></FormControl>
                                        <FormControl type='file' accept='image/*' className='hidden' ref={imgRef} id='chatImg' multiple onChange={handleImgChange}></FormControl>
                                        <Button variant='outline-secondary' type="button" onClick={() => { imgRef.current.click(); }}><i className='fas fa-camera'></i></Button>
                                        <Button variant='outline-secondary' type="button" onClick={sendMessage} disabled={handleDisableSendMessage()}><i className='fas fa-paper-plane'></i></Button>
                                    </InputGroup>
                                </Form>
                            </Col>
                        </Row>
                    </CardFooter>
                </If>
            </If>
            <If condition={chatState?.channelId === ''}>
                <CardBody style={{ overflowY: 'auto' }}>
                    <Row style={{ height: '100%' }}>
                        <Col className="flex flex-column justify-content-center align-items-center">
                            <img alt='img_mamtalks' src={maminfoLogo} style={{ opacity: '0.8', width: '60%' }}></img>
                            <h6 style={{ color: '#ADAFB0' }}>Bem-Vindo ao MAMINFO Talks, o chat empresarial da MAMINFO!</h6>
                            <p style={{ color: '#ADAFB0' }}>Use o menu ao lado para selecionar o canal de conversa.</p>
                        </Col>
                    </Row>
                </CardBody>
            </If>
        </Card>)
}