import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';
import If from '../If';

export default function MenuItem(props) {
    const [isActive, setIsActive] = useState(false);
    const [isChildActive, setIsChildActive] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { checkAllPermissions, checkPermission} = usePermissions();

    function toggleMenu() {
        setIsOpened(!isOpened);
    }

    function handleMainMenuAction() {
        if (isExpandable) {
            toggleMenu();
            return;
        }

        navigate(props.path ? props.path : "/");
    }

    function getIfIsActive(url) {
        setIsActive(false);
        setIsChildActive(false);
        if (isExpandable && props && props.children) {
            props.children.forEach((item) => {
                if (url.pathname.includes(item.path.split("?")[0])) {
                    setIsChildActive(true);
                    setIsOpened(true);
                }
            });
        } else if (url.pathname.includes(props.path)) {
            setIsActive(true);
        }
    }

    useEffect(() => {
        if (location) {
            getIfIsActive(location);
        }
    }, [location, isExpandable, props]);

    useEffect(() => {
        if (!isActive && !isChildActive) {
            setIsOpened(false);
        }
    }, [isActive, isChildActive]);

    useEffect(() => {
        setIsExpandable(
            Boolean(props && props.children && props.children.length > 0)
        );
    }, [props]);

    return (
        <li className={`nav-item ${isOpened ? "menu-open" : ""} ${ !checkAllPermissions(props.permission_group) ? 'd-none' : ''}`}>
            <a className={`nav-link ${isActive || isChildActive ? 'active' : ''}`} onClick={() => { handleMainMenuAction() }} style={{ cursor: 'pointer' }}>
                <i className={`nav-icon nav-icon ${props.icon}`} />
                <p>
                    {props.name}
                    <If condition={isExpandable}>
                        <i className="right fas fa-angle-left" />
                    </If>
                    <If condition={!isExpandable}>
                        {null}
                    </If>
                </p>
            </a>
            {isExpandable && props && props.children && props.children.map((item) => {
                return (
                    <ul className={`nav nav-treeview ${!checkPermission(item.menu_permission) ? 'd-none' : ''}`} key={item.name}>
                        <li className="nav-item">
                            <NavLink to={`${item.path}`} className={`nav-link`}>
                                <i className="far fa-circle nav-icon" />
                                <p>{item.name}</p>
                            </NavLink>
                        </li>
                    </ul>
                )
            }
            )}
        </li>
    );
}