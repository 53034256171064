import React, { useEffect, useState } from 'react';
import { changeRentedVehicleStatus, getRentedVehicles } from '../../../../../services/Employee';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';
import If from '../../../../../common/Layout/If';
import CreateVehicle from './CreateVehicle';
import usePermissions from '../../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';

export default function RentedVehicles({ technicianId = '', readOnly = true, type = 'technician' }) {

    const [rentedVehicleData, setRentedVehicleData] = useState([]);
    const [openRentedVehicle, setOpenRentedVehicle] = useState(false);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_cadastros', 'TechnicianDetails', 'RentedVehicles');
    const { action: actionPermissionsEmployee, page: pagePermissionsEmployee } = getSubComponentsPermissions('mam_cadastros', 'EmployeeDetails', 'RentedVehicles');
    const columns = [
        {
            id: 'veiculo_locacao_table',
            name: 'Veículo',
        },
        {
            id: 'modelo_locacao_table',
            name: 'Modelo',
        },
        {
            id: 'cor_locacao_table',
            name: 'Cor',
        },
        {
            id: 'placa_locacao_table',
            name: 'Placa',
        },
        {
            id: 'data_locacao_table',
            name: 'Data de Locação',
        },
        {
            id: 'data_expiracao_table',
            name: 'Data de Expiração',
        },
        {
            id: 'status_locacao_table',
            name: 'Status',
        },
        {
            id: 'action_locacao_table',
            name: 'Ação',
            body: (row) => (
                <If condition={row.status_locacao_table === "Em Uso" && checkPermission(type === 'technician' ? actionPermissions[0] : actionPermissionsEmployee[0])}>
                    <div className='align-items-center'>
                        <button type="button" className='btn btn-danger' onClick={() => showCancelRentedVehicleSwal(row.id_veiculo_locacao_table)} disabled={readOnly}><i className='fas fa-ban'></i></button>
                    </div>
                </If>),
            permission: checkAllPermissions(type === 'technician' ? actionPermissions : actionPermissionsEmployee)
        }
    ]

    async function getVehicles() {
        const toastVehicle = toast.loading("Carregando veículos, aguarde...");

        let dataReturn = await getRentedVehicles(technicianId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Veículos encontrados', 'update', toastVehicle);
            let data = dataReturn?.data?.data?.map((item, idx) => {

                let currentDate = new Date();
                let expirationDate = new Date(item?.data_expiracao_locacao);
                let statusByDate = new Date(expirationDate.toDateString()) >= new Date(currentDate.toDateString()) ? 'Em Uso' : 'Expirado';
                return {
                    id_veiculo_locacao_table: item?.id || '',
                    veiculo_locacao_table: item?.veiculo || '',
                    modelo_locacao_table: item?.modelo_veiculo || '',
                    cor_locacao_table: item?.cor_veiculo || '',
                    placa_locacao_table: item?.placa_veiculo || '',
                    data_locacao_table: item?.data_locacao || '',
                    data_expiracao_table: item?.data_expiracao_locacao || '',
                    status_locacao_table: item?.status === 0 ? 'Cancelado' : statusByDate
                }
            });

            setRentedVehicleData(data);
        } else {
            handleErrorsToast('Ocorreu um erro ao procurar os veiculos!', dataReturn?.data, 'update', toastVehicle);
        }
    }

    async function cancelRentedVehicle(vehicleId) {
        let formData = {
            status: 0
        }
        const toastVehicle = toast.loading("Atualizando status, aguarde...");

        let dataReturn = await changeRentedVehicleStatus(vehicleId, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Status atualizado com sucesso!', 'update', toastVehicle);
            getVehicles();
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o status!', dataReturn?.data, 'update', toastVehicle);
        }
    }

    async function showCancelRentedVehicleSwal(vehicleId) {
        let response = await Swal.fire({
            title: 'Deseja realmente cancelar este veículo?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (response.isConfirmed) {
            cancelRentedVehicle(vehicleId);
        }
    }

    useEffect(() => {
        if (technicianId !== '') {
            getVehicles();
        }
    }, [technicianId]);

    return (
        <div className="row">
            <CreateVehicle isOpen={openRentedVehicle} setIsOpen={() => setOpenRentedVehicle(!openRentedVehicle)} setUpdateParent={getVehicles} technicianId={technicianId}></CreateVehicle>
            <div className="col-12">
                <h5><i className="fas fa-dot-circle"></i> Veículos Alugados</h5>
            </div>
            <If condition={checkPermission(type === 'technician' ? pagePermissions[0] : pagePermissionsEmployee[0])}>
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={() => setOpenRentedVehicle(!openRentedVehicle)} disabled={readOnly}>Cadastrar Veículo</button>
                </div>
            </If>
            <div className="col-12">
                <PrimeTable
                    columns={columns}
                    rows={rentedVehicleData}
                    id='veiculos_locados_table'
                ></PrimeTable>
            </div>
        </div>
    )
}