import React, { useEffect, useState } from 'react';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';
import If from '../../../../common/Layout/If';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import ProdutosByIdLocalidadeSelect from '../../../../common/Selects/ProdutosByIdLocalidadeSelect';
import { getProductByProductId } from '../../../../services/Product';
import { Dialog } from 'primereact/dialog';
import ProdutosByIdTecnicoSelect from '../../../../common/Selects/ProdutosByIdTecnicoSelect';
import { handleErrorsToast } from '../../../../configs/GenericFunctions';

export default function CreateProduct({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    num_chamado = '',
    envolve_logistica = '',
    id_tecnico = '',
}) {
    const INITIAL_STATE = {
        id_fabricante: '',
        id_modelo: '',
        id_produto: '',
        exibe_produto: 'nao',
        qtd_estoque: 0,
        qtd_enviar: 1,
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [disableButton, setDisableButton] = useState(false);

    async function submitForm(e) {
        e.preventDefault();
        setDisableButton(true);
        let productArray = [];
        let params = { ...formData };

        let label = params?.id_modelo?.label?.split(" - ")[0];
        params.id_modelo = { ...params?.id_modelo, label: label}

        if (params.exibe_produto === 'nao' && envolve_logistica !== 'Não') {
            delete params.id_produto;
        }

        if (params.exibe_produto === 'sim_id_localidade' || envolve_logistica === 'Não') {
            delete params.id_fabricante;
            delete params.id_modelo;
        }

        if(params.exibe_produto !== 'nao' || envolve_logistica === 'Não'){
            delete params.qtd_enviar;
            delete params.qtd_estoque;
        }

        for (let data in params) {
            if (params[data] === '') {
                handleErrorsToast('Preencha todos os campos');
                setDisableButton(false);
                return;
            }

            if (data === 'qtd_enviar' && params[data] > params?.qtd_estoque) {
                handleErrorsToast("A quantidade a ser enviada não pode ser maior que a quantidade em estoque");
                setDisableButton(false);
                return;
            }

            if (data === 'qtd_enviar' && params[data] < 1) {
                handleErrorsToast("A quantidade a ser enviada não pode ser menor que 1");
                setDisableButton(false);
                return;
            }
        }

        if (params.exibe_produto === 'sim_id_localidade' || envolve_logistica === 'Não') {
            let dataReturn = await getProductByProductId(formData?.id_produto?.value);

            if (dataReturn?.data?.response === 'success') {

                let manufacturer = dataReturn?.data?.data?.partnumber?.manufacturer;
                let partnumber = dataReturn?.data?.data?.partnumber;
                let product = dataReturn?.data?.data;
                params['id_fabricante'] = { value: manufacturer?.id || '', label: manufacturer?.nome_fabricante || '' };
                params['id_modelo'] = { value: partnumber?.id || '', label: partnumber?.modelo || '' };
                params['id_produto'] = { value: product?.id || '', label: `${product?.nome_produto || ''} - ${product?.serial || ''}` };
            
                productArray.push(params);
            }
        }
 
        if ((params.exibe_produto === 'nao' && envolve_logistica !== 'Não')) {
            for(let c = 0; c < params.qtd_enviar; c++){
                productArray.push(params);
            }
        }

        if(params.exibe_produto === 'sim'){
            productArray.push(params);
        }

        updateParent(productArray);
        setFormData(INITIAL_STATE);
        setDisableButton(false);
    }

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '', id_produto: ''}));
    }

    function handleExibeProduto(value, name) {
        let params = {
            id_produto: '',
            id_fabricante: '',
            id_modelo: ''
        }
        setFormData(prev => ({ ...prev, [name]: value, ...params }));
    }

    function handleQtdEnviar(e) {

        if (e.target.value > formData?.qtd_estoque) {
            handleErrorsToast("A quantidade a ser enviada não pode ser maior que a quantidade em estoque");
            return;
        }

        if (e.target.value < 1) {
            handleErrorsToast("A quantidade não pode ser menor que 1");
            return;
        }

        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    async function handleModelo(value, name) {
        let qtd_estoque = value?.label?.split(" - ")[1] || 0;

        setFormData(prev => ({ ...prev, [name]: value, qtd_estoque: +qtd_estoque }));
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Cadastrar Produto'
            maximizable={true}
        >
            <div className="row">
                <If condition={envolve_logistica === 'Não'}>
                    <ProdutosByIdTecnicoSelect formData={formData} setFormData={setFormData} cols={[12, 12, 12, 12]} fieldName='id_produto' id_tecnico={id_tecnico || ''} required={true} title='Produtos com o Técnico'></ProdutosByIdTecnicoSelect>
                </If>
                <If condition={envolve_logistica !== 'Não'}>
                    <div className="col-12">
                        <label htmlFor="">Necessário selecionar o serial do produto?</label>
                        <div className="form-check">
                            <input type="radio" name="exibe_produto" id="exibe_produto_nao" className="form-check-input" value={'nao'} checked={formData?.exibe_produto === 'nao'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)} />
                            <label htmlFor="exibe_produto_nao" className="form-check-label">Não</label>
                        </div>
                        <div className="form-check">
                            <input type="radio" name="exibe_produto" id="exibe_produto_sim" className="form-check-input" value={'sim'} checked={formData?.exibe_produto === 'sim'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)} />
                            <label htmlFor="exibe_produto_sim" className="form-check-label">Sim - Pesquisar a partir do nome do produto</label>
                        </div>
                        <div className="form-check">
                            <input type="radio" name="exibe_produto" id="exibe_produto_sim_localidade" className="form-check-input" value={'sim_id_localidade'} checked={formData?.exibe_produto === 'sim_id_localidade'} onChange={(e) => handleExibeProduto(e.target.value, e.target.name)} />
                            <label htmlFor="exibe_produto_sim_localidade" className="form-check-label">Sim - Pesquisar a partir do ID Localidade</label>
                        </div>
                    </div>
                    <If condition={['nao', 'sim'].includes(formData?.exibe_produto)}>
                        <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_fabricante' required={true} advancedSetFormData={handleFabricante}></FabricanteSelect>
                        <ModelosSelect title='Nome do Produto - Quantidade' advancedSetFormData={handleModelo} formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value || ''} required={true} only_available='only_available' show_qty></ModelosSelect>
                        <If condition={formData?.exibe_produto === 'sim'}>
                            <AsyncProdutosSelect formData={formData} setFormData={setFormData} id_modelo={formData?.id_modelo?.value || ''} fieldName='id_produto' required></AsyncProdutosSelect>
                        </If>
                        <If condition={formData?.exibe_produto === 'nao'}>
                            <div className="col-sm-12 col-md-6">
                                <label>Quantidade em Estoque</label>
                                <input type="number" name="qtd_estoque" id="qtd_estoque" className="form-control" value={formData?.qtd_estoque || 0} readOnly />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor='qtd_enviar'>Quantidade Desejada</label>
                                <input type="number" name="qtd_enviar" id="qtd_enviar" className="form-control" value={formData?.qtd_enviar || 1} onChange={handleQtdEnviar} />
                            </div>
                        </If>
                    </If>
                    <If condition={formData?.exibe_produto === 'sim_id_localidade'}>
                        <ProdutosByIdLocalidadeSelect formData={formData} setFormData={setFormData} fieldName='id_produto' idLocalidade={num_chamado || ''} required title='Produtos no ID Localidade'></ProdutosByIdLocalidadeSelect>
                    </If>
                </If>
                <div className="col-12 mt-1">
                    <button type="submit" className="btn btn-primary" disabled={disableButton} onClick={submitForm}>Cadastrar</button>
                </div>
            </div>
        </Dialog>);
}