import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import { updatePendingProduct } from '../../../../services/Shipment';

export default function UpdateProduct({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    dados_remessa = {},
}) {
    const INITIAL_STATE = {
        id_fabricante: '',
        id_modelo: '',
        id_produto: '',
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        let toastAddProduct = toast.loading("Atualizando remessa, aguarde...");
        let params = {
            id_produto: formData?.id_produto?.value || ''
        }
        let addProductResponse = await updatePendingProduct(dados_remessa.id, params);

        if (addProductResponse?.status === 200) {
            handleSuccessToast("Remessa atualizada com sucesso!", "update", toastAddProduct);
            updateParent();
            setFormData(INITIAL_STATE);
            setIsOpen(false);
        } else {
            handleErrorsToast("Ocorreu um erro ao atualizar a remessa!", addProductResponse?.data, 'update', toastAddProduct);
        }
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Atualizar Produto da Remessa'
            maximizable={true}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <label>Fabricante</label>
                        <input type="text" className="form-control" value={dados_remessa?.partnumber?.manufacturer?.nome_fabricante || ''} readOnly/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label>Nome do Produto</label>
                        <input type="text" className="form-control" value={dados_remessa?.partnumber?.modelo || ''} readOnly/>
                    </div>
                    <AsyncProdutosSelect formData={formData} setFormData={setFormData} id_modelo={dados_remessa?.partnumber?.id || ''} fieldName='id_produto' required></AsyncProdutosSelect>
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Salvar</button>
                    </div>
                </div>
            </form>
        </Dialog>);
}