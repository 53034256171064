import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import Form from './Form/Form';
import { toast } from 'react-toastify';
import { getReportById, updateAnalysis } from '../../../services/Repair';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function ReportDetails({
    visible = false,
    onHide = null,
    id = '',
    viewMode = 'view',
    updateParent = null,
    setUpdateReport = null
}) {
    const INITIAL_STATE = {
        id: '',
        id_fabricante: '',
        id_modelo: '',
        id_produto_reparo: '',
        created_at: '',
        status: '',
        houve_reparo: '',
        descricao_laudo: '',
        componentes_existentes: [],
        componentes_novos_comprados: [],
        componentes_spares: []
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        if (formData?.status?.value === 'finalizado' && formData?.descricao_laudo.length < 100) {
            handleErrorsToast('A descrição do laudo precisa conter no mínimo 100 caracteres!');
            return;
        }

        const toastReport = toast.loading('Atualizando análise, aguarde...');

        let params = {
            ...formData,
            id_produto_reparo: formData?.id_produto_reparo?.value || '',
            houve_reparo: formData?.houve_reparo?.value,
            status: formData?.status?.value || '',
            comentarios: formData?.andamento_analise || '',
            componentes_canibalizados: formData?.componentes_existentes?.filter(item => [null, undefined, '', false].includes(item?.from_database))?.map((item) => ({ id: item.id || '' })) || [],
            componentes_novos_comprados: formData?.componentes_novos_comprados?.map((item) => ({ id_modelo: formData?.id_modelo?.value || '', componente: item?.componente || '', valor: item?.valor?.replace(',', '.') || '0' })) || [],
            componentes_spares: formData?.componentes_spares?.map((item) => ({ id_modelo: formData?.id_modelo?.value || '', componente: item?.componente || '', valor: item?.valor?.replace(',', '.') || '0' })) || []
        };

        let dataReturn = await updateAnalysis(id, params);

        if (dataReturn?.data?.response === 'success') {
            updateParent();
            setUpdateReport(true);
            handleSuccessToast('Análise atualizada com sucesso!', 'update', toastReport);
            onHide(false);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar a análise!', dataReturn?.data, 'update', toastReport);
        }
    }

    async function getReportDataById(id) {
        let toastReport = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getReportById(id);

        if (dataReturn?.data?.response === 'success') {
            let reportData = dataReturn?.data?.data;
            let productRepair = reportData?.product_repair || {};
            let partNumber = productRepair?.partnumber || {};
            let manufacturer = partNumber?.manufacturer || {};
            let andamento_analise = '';
            let houve_reparo = reportData?.status !== 'finalizado' ? '' : { value: reportData?.houve_reparo, label: `${reportData?.houve_reparo === 1 ? 'Sim' : 'Não'}` || '' };
            let status = viewMode === 'view' ? { value: reportData?.status || '', label: 'Em Análise' } : '';
            let divisor = '---------------------------------------------------------------------------------------------\n';
            let created_at =new Date(reportData?.created_at).toLocaleString('sv-SE');

            if (viewMode === 'view') {
                for (let h of reportData?.history) {
                    let data =  new Date(h?.created_at).toLocaleString();
                    andamento_analise += `${data} - ${h?.user?.login}\n`
                    andamento_analise += `${h?.descricao}\n`;
                    andamento_analise += divisor;
                }
            }

            switch (reportData?.status) {
                case "aberto":
                    status = { value: reportData?.status || '', label: viewMode === 'view' ? 'Em Aberto' : 'Enviar para Em Aberto' };
                    break;
                case "em_analise":
                    status = { value: reportData?.status || '', label: viewMode === 'view' ? 'Em Análise' : 'Enviar para Em Análise' }
                    break;
                case 'aguardando_componente':
                    status = { value: reportData?.status || '', label: viewMode === 'view' ? 'Aguardando Componente' : 'Enviar para Aguardando Componente' };
                    break;
                case 'finalizado':
                    status = { value: reportData?.status || '', label: 'Finalizado' };
                    break;
                default:
                    break;
            }

            setFormData(prev => ({
                ...prev,
                ...reportData,
                id: reportData?.id || '',
                id_fabricante: { value: manufacturer?.id || '', label: manufacturer?.nome_fabricante || '' },
                id_modelo: { value: partNumber?.id || '', label: partNumber?.modelo || '' },
                id_produto_reparo: { value: productRepair?.id || '', label: `${productRepair?.nome_produto || ''} - ${productRepair?.serial || ''}` },
                created_at: created_at,
                andamento_analise: andamento_analise,
                status: status,
                houve_reparo: houve_reparo,
                componentes_existentes: reportData?.components?.map((item, idx) => ({
                    id_temp: idx,
                    componente: item?.component?.componente || '',
                    valor: item?.component?.valor?.replace('.', ',') || '0',
                    origem: item?.component?.origem || '',
                    from_database: true,
                    id: item?.component?.id || ''
                })) || [],
                componentes_spares: reportData?.product_repair?.spare_component?.map((item, idx) => ({id: item?.id, componente: item?.componente || '', valor: item?.valor?.replace('.', ',') || '0'})) || []
            }));
            handleSuccessToast('Dados carregados!', 'update', toastReport);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastReport);
        }
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id) && visible) {
            getReportDataById(id);
        } else {
            setFormData(INITIAL_STATE);
        }
    }, [visible, id]);

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header='Detalhes do Laudo'
            draggable={false}
            resizable={false}
            maximized
        >
            <Form formData={formData} setFormData={setFormData} submitForm={submitForm} mode={viewMode}></Form>
        </Dialog>
    )
}