import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { createEmployee } from '../../../services/Employee';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';
import Form from './Components/Form';


export default function CreateTechnician({ type = "technician" }) {

    const initialState = {
        representantes: [],
        nome_tec: '',
        rg_tec_orgao_expedidor: '',
        rg_tec: '',
        cpf_cnpj: '',
        data_nascimento: '',
        estado_civil: '',
        numero_cnh: '',
        cnh_img: '',
        nome_mae: '',
        data_validade_cnh: '',
        data_validade_aso: '',
        data_exame_periodico: '',
        logradouro_end: '',
        numero_complemento_endereco: '',
        bairro_end: '',
        cidade_end: '',
        estado_end: '',
        cep_end: '',
        telefone_fixo_tec: '',
        telefone_recados: '',
        celular_tec: '',
        celular_tec_pessoal: '',
        email_tec: '',
        email_tec_pessoal: '',
        veiculo: '',
        cor_veiculo: '',
        modelo_veiculo: '',
        placa_veiculo: '',
        banco: '',
        tipo_conta_banco: '',
        agencia_banco: '',
        num_conta_banco: '',
        titular_banco2: '',
        cpf_titular2: '',
        desc_base: '',
        desc_habilidades: '',
        descricao: '',
        ativo: '',
        credenciado: '',
        modal_contratacao: '',
        categoria: "Técnico",
        id_representante: '',
        id_usuario_gestor: '',
        cnpj: '',
        id_integradora: [],
        id_contrato: []
    }

    const [formTechnicianData, setFormTechnicianData] = useState(initialState);
    const navigate = useNavigate();

    async function submitForm(e) {
        e.preventDefault();
        let representantes;
        let modal_contratacao;
        let categoria;

        if (type === 'technician') {
            modal_contratacao = formTechnicianData?.id_representante?.value === 1 ? 'CLT' : 'PJ';
            representantes = [formTechnicianData?.id_representante?.value || ''];
            categoria = 'Técnico';
        }

        if (type === 'employee') {
            modal_contratacao = formTechnicianData?.modal_contratacao?.value || '';
            representantes = modal_contratacao === 'CLT' ? [1] : [845];
            categoria = formTechnicianData?.categoria?.value || '';
        }

        let id_usuario_gestor = formTechnicianData?.id_representante?.value === 1 ? '' : formTechnicianData?.id_usuario_gestor?.value || '';

        let formData = {
            ...formTechnicianData,
            representantes: representantes,
            nome_tec: formTechnicianData?.nome_tec || '',
            rg_tec_orgao_expedidor: formTechnicianData?.rg_tec_orgao_expedidor || '',
            rg_tec: formTechnicianData?.rg_tec || '',
            cpf_cnpj: formTechnicianData?.cpf_cnpj || '',
            data_nascimento: formTechnicianData?.data_nascimento || '',
            estado_civil: formTechnicianData?.estado_civil?.value || '',
            numero_cnh: formTechnicianData?.numero_cnh || '',
            cnh_img: '',
            nome_mae: formTechnicianData?.nome_mae || '',
            data_validade_cnh: formTechnicianData?.data_validade_cnh || '',
            data_validade_aso: formTechnicianData?.data_validade_aso || '',
            data_exame_periodico: formTechnicianData?.data_exame_periodico || '',
            logradouro_end: formTechnicianData?.logradouro_end || '',
            numero_complemento_endereco: formTechnicianData?.numero_complemento_endereco || '',
            bairro_end: formTechnicianData?.bairro_end || '',
            cidade_end: formTechnicianData?.cidade_end || '',
            estado_end: formTechnicianData?.estado_end || '',
            cep_end: formTechnicianData?.cep_end || '',
            telefone_fixo_tec: formTechnicianData?.telefone_fixo_tec || '',
            telefone_recados: formTechnicianData?.telefone_recados || '',
            celular_tec: formTechnicianData?.celular_tec || '',
            celular_tec_pessoal: formTechnicianData?.celular_tec_pessoal || '',
            email_tec: formTechnicianData?.email_tec || '',
            email_tec_pessoal: formTechnicianData?.email_tec_pessoal || '',
            veiculo: formTechnicianData?.veiculo || '',
            cor_veiculo: formTechnicianData?.cor_veiculo || '',
            modelo_veiculo: formTechnicianData?.modelo_veiculo || '',
            placa_veiculo: formTechnicianData?.placa_veiculo || '',
            banco: formTechnicianData?.banco || '',
            tipo_conta_banco: formTechnicianData?.tipo_conta_banco || '',
            agencia_banco: formTechnicianData?.agencia_banco || '',
            num_conta_banco: formTechnicianData?.num_conta_banco || '',
            titular_banco2: formTechnicianData?.titular_banco2 || '',
            cpf_titular2: formTechnicianData?.cpf_titular2 || '',
            desc_base: '',
            desc_habilidades: '',
            descricao: formTechnicianData?.descricao || '',
            ativo: 1,
            credenciado: 1,
            modal_contratacao: modal_contratacao,
            categoria: categoria,
            // id_usuario_gestor: formTechnicianData?.id_representante?.value === 1 ? 47437 : formTechnicianData?.id_usuario_gestor?.value || ''
            id_usuario_gestor: id_usuario_gestor,
            contratos: formTechnicianData?.id_contrato?.map((item) => item.value)
        }

        const toastCreateForm = toast.loading("Cadastrando, aguarde...");

        let dataReturn = await createEmployee(formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Cadastro efetuado com sucesso!', 'update', toastCreateForm);
            navigate(type === 'technician' ? "/cadastros/tecnicos/dados" : '/cadastros/funcionarios/dados');
        } else if (dataReturn?.data?.message === 'The cpf cnpj has already been taken.') {
            handleErrorsToast('Este CPF/CNPJ já existe!', dataReturn?.data, 'update', toastCreateForm);
        } else {
            handleErrorsToast('Ocorreu um erro ao efetuar o cadastro!', dataReturn?.data, 'update', toastCreateForm);
        }
    }

    return (
        <Content headerTitle={`Cadastrar ${type === 'technician' ? 'Técnico' : 'Funcionário'}`}>
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <Form type={type} page='create' submitForm={submitForm} formTechnicianData={formTechnicianData} setFormTechnicianData={setFormTechnicianData}></Form>
            </Card>
        </Content >
    );
}