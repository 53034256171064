import React, { useEffect, useState } from 'react';
import { getShipmentById, sendBulkConfirmReception } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function BulkMarkAsReceived({ isOpen = false, setModalOpen = null, setUpdateProducts = null, inTransitId = '', setUpdateLogistic = null, handleClearRows = null }) {

    const INITIAL_STATE = {
        data_recebimento: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [tableFormData, setTableFormData] = useState([]);
    const mySwal = withReactContent(Swal);
    
    const columns = [
        {
            id: 'nome_produto',
            name: 'Nome do Produto'
        },
        {
            id: 'serial_produto',
            name: 'Serial'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto'
        }
    ];

    async function getShipments() {
        let shipmentIdArray = inTransitId.split(',');

        for (let id of shipmentIdArray) {
            getShipmentById(id).then((resp) => {
                let item = resp?.data?.data;
                setTableFormData(prev => [...prev, { nome_produto: item?.product?.partnumber?.modelo || '', serial_produto: item?.product?.serial || '', tipo_produto: item?.product?.nome_produto || '' }])
            }).catch(e => handleErrorsToast('Ocorreu um erro', e?.data));
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            ...formData,
            ids_remessa: inTransitId?.split(',')
        }

        const toastData = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await sendBulkConfirmReception(params);

        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data;
            let errors = [];

            for (let d in data) {
                if (!data[d]['success']) {
                    errors.push({ message: data[d]['message'] });
                }
            }

            if (errors.length > 0) {
                handleErrorsToast('Algumas remessas não foram atualizadas', {}, 'update', toastData);
                mySwal.fire({
                    title: 'Algumas remessas não foram atualizadas!',
                    text: 'Segue os erros abaixo',
                    html: (
                        <ul>
                            {errors.map((item, idx) => {
                                return <li key={idx}>Erro: {item?.message}</li>
                            })}
                        </ul>
                    )
                });
            } else {
                handleSuccessToast('Todas as remessas foram atualizadas com sucesso!', 'update', toastData);
            }

            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
            handleClearRows();

        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastData);
        }
    }


    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(inTransitId)) {
            setFormData(INITIAL_STATE);
            setTableFormData([]);
            getShipments();
        }
    }, [isOpen, inTransitId]);

    return (<Dialog
        visible={isOpen}
        onHide={setModalOpen}
        header='Marcar como Recebido'
        maximized={true}
        draggable={false}
        resizable={false}
    >
        <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5>Confirmar entrega das remessas:</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <PrimeTable
                        columns={columns}
                        rows={tableFormData}
                        needExport={false}
                        id='bulk_mark_as_received'
                    ></PrimeTable>
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5>Informe a data e horário de entrega</h5>
                </div>
                <div className="col-12">
                    <input type="datetime-local" name="data_recebimento" id="data_recebimento" className='form-control' value={formData?.data_recebimento || ''} onChange={(e) => setFormData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-1">
                    <button type="submit" className="btn btn-primary">Confirmar Recebimento</button>
                </div>
            </div>
        </form>
    </Dialog>)

}