import React, { useEffect, useState } from 'react';
import Card from '../../../../common/Layout/Card';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import CreateSenhaAtendimento from './CreateSenhaAtendimento';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';

export default function SenhaAtendimentoCard({ getHistory = null, detailsData = {}, id = '', passwordData = [], getPasswordsData = null }) {

    const [isCadastrarSenhaOpen, setIsCadastrarSenhaOpen] = useState(false);
    const { checkPermission } = usePermissions();
    const { page: pagePermissions } = getSubComponentsPermissions('mam_chamados', 'TicketDetails', 'SenhaAtendimentoCard');
    const passwordColumns = [
        {
            id: 'senha_password',
            name: 'Senha',
        },
        {
            id: 'atividade_password',
            name: 'Atividade',
        },
        {
            id: 'data_criacao_password',
            name: 'Data de Criação',
        }
    ];

    function updateThis() {
        getHistory();
        getPasswordsData();
    }

    useEffect(() => {
        getPasswordsData();
    }, [id]);

    return (<Card title={"Senhas de Atendimento"} icon={"fa-keyboard"}>
        <CreateSenhaAtendimento id={id} isOpen={isCadastrarSenhaOpen} detailsData={detailsData} setIsOpen={() => setIsCadastrarSenhaOpen(!isCadastrarSenhaOpen)} updateParent={updateThis}></CreateSenhaAtendimento>
        <div className='row'>
            <If condition={checkPermission(pagePermissions[0])}>
                <div className='col-12'>
                    <button type="button" className="btn btn-primary" onClick={() => setIsCadastrarSenhaOpen(!isCadastrarSenhaOpen)}>Cadastrar Senha de Atendimento</button>
                </div>
            </If>
            <hr></hr>
            <div className="col-12">
                <PrimeTable
                    columns={passwordColumns}
                    rows={passwordData}
                    needExport={false}
                    id='password_prime_table'
                ></PrimeTable>
            </div>
        </div>
    </Card>);
}