import interceptorInstance from "./Interceptor";

export async function getAllPartners(){
    try{
        let dados = await interceptorInstance.get('partner/all/1');
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getTechniciansByPartnerId(id, disponivel = 0, id_contrato = ''){
    try{

        let queryParams = `disponivel=${disponivel}`;

        if(!['', undefined, null, false].includes(id_contrato)){
            queryParams += `&id_contrato=${id_contrato}`;
        }

        let dados = await interceptorInstance.get(`partner/search/${id}/technicians?${queryParams}`);
        return dados;
    }catch(e){ 
        return e.response;
    }
}

export async function getPartnerById(id){
    try{
        let dados = await interceptorInstance.get(`partner/search/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getPartnerGroupUsers(){
    try{
        let dados = await interceptorInstance.get(`partner-group/users`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getPartnersByStatus(id){
    try{
        let dados = await interceptorInstance.get(`partner/all/${id}`);
        return dados;
    }catch(e){
        return e.response;
    } 
}

export async function changePartnerStatusById(id, status){
    try{
        let dados = await interceptorInstance.patch(`partner/enable-disable/${id}/${status}`, {});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function insertPartner(params){
    try{
        let dados = await interceptorInstance.post(`partner/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updatePartner(id, params){
    try{
        let dados = await interceptorInstance.patch(`partner/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}