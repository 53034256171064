import React, { useContext, useEffect, useState } from 'react';
import Content from '../../common/Layout/Content';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../common/Layout/Card';
import GrupoAcessoSelect from '../../common/Selects/GrupoAcessoSelect';
import IntegradoraSelect from '../../common/Selects/IntegradoraSelect';
import { withMask } from 'use-mask-input';
import { toast } from 'react-toastify';
import { getUserDataById, updateUser } from '../../services/Users';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../App';
import If from '../../common/Layout/If';
import GenericSelect from '../../common/Selects/GenericSelect';
import usePermissions from '../../hooks/usePermissions';
import { getSubComponentsPermissions } from '../../configs/MenuItemsConfigs';
import { handleErrorsToast, handleSuccessToast } from '../../configs/GenericFunctions';
import ContratosMultiSelect from '../../common/Selects/ContratosMultiSelect';

export default function UserDetails() {

    const initialState = {
        grupos: '',
        id_contrato: '',
        integradora: '',
        rat_acesso: 0,
        rat_contrato: '',
        cliente: false,
        nome: '',
        email: '',
        telefone: '',
        login: '',
        senha: '',
        observacao: '',
        hora_inicial_trabalho: '08:00',
        hora_final_trabalho: '17:00',
        status_colaborador: { value: 'Ativo', label: 'Ativo' },
        data_inicial_afastamento: '',
        data_final_afastamento: ''
    }

    const { id } = useParams();
    const [formCreateUser, setFormCreateUser] = useState(initialState);
    const [showPwd, setShowPwd] = useState(false);
    const [updateData, setUpdataData] = useState(false);
    const { checkPermission } = usePermissions();
    const { page: pagePermission } = getSubComponentsPermissions('mam_usuarios', 'UserDetails', 'UserDetails')
    const userData = useContext(Context);
    const navigate = useNavigate();

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    async function submitForm() {
        let horaInicio = formCreateUser?.hora_inicial_trabalho?.split(":") || ["08", "00"];
        let horaFinal = formCreateUser?.hora_final_trabalho?.split(":") || ["17", "00"];

        let formData = {
            ...formCreateUser,
            grupos: formCreateUser?.grupos?.length > 0 ? formCreateUser?.grupos.map((item, idx)=>{
                return item.value
            }) : [],
            cliente: formCreateUser?.cliente?.value || false,
            id_contrato: '',
            contratos: formCreateUser?.id_contrato?.length > 0 ? formCreateUser?.id_contrato?.map((item) => item.value) : [],
            status_colaborador: formCreateUser?.status_colaborador?.value || '',
            hora_inicial_trabalho: `${horaInicio[0]}:${horaInicio[1]}`,
            hora_final_trabalho: `${horaFinal[0]}:${horaFinal[1]}`,
        }

        if(formData?.senha?.length > 0 && formData?.senha?.length < 8){
            handleErrorsToast("A senha deve ter no mínimo 8 caracteres!");
            return;
        }

        const toastUsers = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updateUser(id, formData);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Usuario atualizado com sucesso!', 'update', toastUsers);
            getUserData();
        } else if (dataReturn?.data?.message === 'The login has already been taken.') {
            handleErrorsToast('Este usuário já existe!', dataReturn?.data, 'update', toastUsers);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o usuário!', dataReturn?.data, 'update', toastUsers);
        }
    }

    const getUserData = async () => {
        const toastUsers = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getUserDataById(id);

        if (dataReturn?.data?.response === 'success') {
            let user = dataReturn?.data?.data;
            
            if (user?.login === userData.userDataState.login) {
                handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastUsers);
                navigate("/usuarios/dados/ativo");
                return;
            }

            let cliente = user?.cliente === 1 ? { value: 1, label: 'SIM'} : { value: 0, label: 'NÃO'};
            let integradora = [];
            let contrato = [];

            if(user?.contracts?.length > 0){
                user?.contracts?.forEach((item) => {
                    contrato.push({value: item?.id, label: item?.contrato});
                    integradora.push({value: item?.integrator?.id, label: item?.integrator?.nome});
                });

                integradora = integradora.filter((item, idx) => integradora.findIndex(item2 => item?.label === item2?.label) === idx);
            }

            handleSuccessToast('Usuario encontrado!', 'update', toastUsers);
            setFormCreateUser({
                ...user,
                grupos: user?.groups?.map((item, idx) => {
                    return ({ value: item.id, label: item.descricao });
                }) || [],
                cliente: cliente,
                integradora: integradora,
                id_contrato: contrato,
                status_colaborador: { value: user?.status_colaborador || '', label: user?.status_colaborador || '' },
            });
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastUsers);
            navigate("/usuarios/dados/ativo");
        }
    }

    useEffect(() => {
        getUserData();
    }, [id]);

    useEffect(()=>{
        if(userData?.userDataState?.id_usuario === +id){
            navigate("/usuarios/dados/ativo");
            return;
        }  
    }, [userData]);

    return (
        <Content headerTitle='Detalhes Usuário'>
            <SubHeaderSpecific subHeaderConfig='mam_usuarios'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <If condition={checkPermission(pagePermission[0])}>
                        <div className="col-12">
                            <If condition={updateData}>
                                <button className="btn btn-primary m-1" onClick={submitForm}>Salvar Alterações</button>
                            </If>
                            <If condition={!updateData}>
                                <button className="btn btn-primary m-1" onClick={() => setUpdataData(prevState => !prevState)}>Alterar</button>
                            </If>
                        </div>
                    </If>
                </div>
                <hr></hr>
                <div className="row">
                    <If condition={checkPermission(pagePermission[1]) || checkPermission(pagePermission[7])}>
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Grupo de Acesso / Usuário Cliente</h5>
                        </div>
                    </If>
                    <If condition={checkPermission(pagePermission[1])}>
                        <GrupoAcessoSelect formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 6, 6]} isMulti={true} required={true} isDisabled={!updateData || !checkPermission(pagePermission[2])}></GrupoAcessoSelect>
                    </If>
                    <If condition={checkPermission(pagePermission[7])}>
                        <GenericSelect formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 6, 6]} fieldName={'cliente'} genericOption='SimNaoBoolOptions' required title='Usuário é cliente?' isDisabled={!updateData || !checkPermission(pagePermission[8])}></GenericSelect>
                    </If>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                    </div>
                    <IntegradoraSelect formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 6, 6]} isDisabled={!updateData} isMulti></IntegradoraSelect>
                    <ContratosMultiSelect formData={formCreateUser} setFormData={setFormCreateUser} dependsOnIntegradora={true} cols={[12, 12, 6, 6]} fieldName='id_contrato' id_integradora_string={formCreateUser?.integradora?.length > 0 ? formCreateUser?.integradora?.map((item) => item?.value).toString() : ''} isDisabled={!updateData}></ContratosMultiSelect>
                    {/* <span className='m-1'><b>Obs: Deixe em branco para que o usuário tenha acesso a todas as Integradoras e Contratos</b></span> */}
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Dados do Usuário</h5>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="nome">Nome Completo</label>
                        <input type="text" name="nome" id="nome" className="form-control" value={formCreateUser?.nome || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} readOnly={!updateData} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="telefone">Telefone Celular</label>
                        <input type="tel" name="telefone" id="telefone" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} className="form-control" value={formCreateUser?.telefone || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} readOnly={!updateData} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" className="form-control" value={formCreateUser?.email || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} readOnly={!updateData} />
                    </div>
                    <If condition={checkPermission(pagePermission[3])}>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="login">Login</label>
                            <input type="text" name="login" id="login" className="form-control text-lowercase" value={formCreateUser?.login || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value.toLowerCase() }))} placeholder='...' required={true} readOnly={!updateData || !checkPermission(pagePermission[4])} />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="senha">Nova Senha(Deixe em branco para manter a antiga)</label>
                            <div className="input-group mb-3">
                                <input type={!showPwd ? "password" : "text"} name="senha" id="senha" minLength={8} className="form-control" placeholder="..." value={formCreateUser?.senha || ''} onChange={(e) => (setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} required={true} readOnly={!updateData || !checkPermission(pagePermission[4])} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </If>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="hora_inicial_trabalho">Horario Inicial de Trabalho</label>
                        <input type="time" name="hora_inicial_trabalho" id="hora_inicial_trabalho" className='form-control' value={formCreateUser?.hora_inicial_trabalho || '08:00'} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} readOnly={!updateData} />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="hora_final_trabalho">Horario Final de Trabalho</label>
                        <input type="time" name="hora_final_trabalho" id="hora_final_trabalho" className='form-control' value={formCreateUser?.hora_final_trabalho || '17:00'} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} readOnly={!updateData} />
                    </div>
                    <If condition={checkPermission(pagePermission[5])}>
                        <GenericSelect fieldName={'status_colaborador'} formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 4, 4]} genericOption='StatusColaboradorOptions' isDisabled={!updateData || !checkPermission(pagePermission[6])} required={true} title='Status Colaborador'></GenericSelect>
                        <If condition={formCreateUser?.status_colaborador?.value !== 'Ativo'}>
                            <>
                                <div className="col-md-12 col-lg-4">
                                    <label htmlFor="data_inicial_afastamento">Data Inicial Afastamento Colaborador</label>
                                    <input type="date" name="data_inicial_afastamento" id="data_inicial_afastamento" className='form-control' value={formCreateUser?.data_inicial_afastamento || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required readOnly={!updateData || !checkPermission(pagePermission[6])} />
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <label htmlFor="data_final_afastamento">Data Final Afastamento Colaborador</label>
                                    <input type="date" name="data_final_afastamento" id="data_final_afastamento" className='form-control' value={formCreateUser?.data_final_afastamento || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required readOnly={!updateData || !checkPermission(pagePermission[6])} />
                                </div>
                            </>
                        </If>
                    </If>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Detalhamento </h5>
                    </div>
                    <div className="col-12">
                        <label htmlFor="observacao">Observações / Informações Adicionais</label>
                        <textarea className='form-control' rows={'10'} placeholder='...' id='observacao' value={formCreateUser?.observacao || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} name='observacao' required={true} readOnly={!updateData}></textarea>
                    </div>
                </div>
            </Card>
        </Content>
    );
}