import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { deleteProduct, duplicateProduct, exportProducts, getAllProducts, getAllProductsParams, getProductsReversa } from '../../../../services/Product';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import CreateProduct from './CreateProduct';
import ProductDetails from '../Components/ProductDetails/ProductDetails';
import Swal from 'sweetalert2';
import BulkUpdate from '../Components/BulkUpdate';
import CreateShipment from './CreateShipment';
import If from '../../../../common/Layout/If';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import CreateModel from './CreateModel';
import CreateManufacturer from './CreateManufacturer';
import CreateBox from './CreateBox';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import { createReport } from '../../../../services/Repair';
import { handleErrorsToast, handleSuccessToast, openNewTab } from '../../../../configs/GenericFunctions';
import BulkCreate from './BulkCreate';
import BulkSelectSerial from './BulkSelectSerial';
import { Button, Col, DropdownButton, DropdownItem, Row } from 'react-bootstrap';
import ProductFilters from './Components/ProductFilters';

export default function ShowProducts({ queue = 'products' }) {

    const [loading, setLoading] = useState(true);
    const [productsData, setProductsData] = useState([]);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [search, setSearch] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
    const [isCreateModelOpen, setIsCreateModelOpen] = useState(false);
    const [isCreateShipmentModalOpen, setIsCreateShipmentModalOpen] = useState(false);
    const [isCreateManufacturerOpen, setIsCreateManufacturerOpen] = useState(false);
    const [isCreateBoxOpen, setIsCreateBoxOpen] = useState(false);
    const [isBulkCreateOpenModal, setIsBulkCreateOpenModal] = useState(false);
    const [isBulkSelectSerialOpen, setIsBulkSelectSerialOpen] = useState(false);

    const [productId, setProductId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [shipmentMode, setShipmentMode] = useState('single');
    const [updateLogistic, setUpdateLogistic] = useState(false);
    const { checkPermission, checkAllPermissions } = usePermissions();
    const { action: actionPermissions, cadastro: cadastroPermissions, acoes_em_massa: acoesEmMassaPermissions, acoes: acoesPermissions } = getSubComponentsPermissions('mam_logistica', 'ShowProducts', 'ShowProducts');
    const { action: actionPermissionsReturn } = getSubComponentsPermissions('mam_logistica', 'ShowReturn', 'ShowReturn');
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <DropdownButton title='Ações'>
                    <If condition={checkPermission(queue === 'products' ? actionPermissions[0] : actionPermissionsReturn[0])}>
                        <DropdownItem onClick={() => openProductModal(row.id_produto, 'view')}>Ver Produto</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[1]) && queue === 'products'}>
                        <DropdownItem onClick={() => showDuplicateProductSwal(row.id_produto)}>Duplicar</DropdownItem>
                    </If>
                    <If condition={checkPermission(queue === 'products' ? actionPermissions[2] : actionPermissionsReturn[0])}>
                        <DropdownItem onClick={() => openProductModal(row.id_produto, 'edit')}>Editar</DropdownItem>
                    </If>
                    <If condition={['spare_tecnico', 'em_estoque'].includes(row.chave_opcao_status_produto) && checkPermission(actionPermissions[3]) && queue === 'products'}>
                        <DropdownItem onClick={() => openCreateShipmentModal(row.id_produto)}>Criar Remessa</DropdownItem>
                    </If>
                    <If condition={checkPermission(actionPermissions[4]) && queue === 'products'}>
                        <DropdownItem onClick={() => showDeleteProductSwal(row.id_produto)}>Deletar</DropdownItem>
                    </If>
                    <If condition={row.chave_opcao_status_produto === 'em_estoque' && checkPermission(actionPermissions[5]) && queue === 'products'}>
                        <DropdownItem onClick={() => showSendProductToRepairSwal(row.id_produto)}>Enviar para Reparo</DropdownItem>
                    </If>
                </DropdownButton>),
            permission: checkAllPermissions(queue === 'products' ? actionPermissions : actionPermissionsReturn)
        },
        {
            id: 'status_produto',
            name: 'Status Produto',
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'num_chamado_produto',
            name: 'Número do Chamado',
            body: (row) => (<Button variant='link' onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)}>{row.num_chamado_produto}</Button>)
        },
        {
            id: 'rastreamento_produto',
            name: 'Rastreamento'
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade',
        },
        {
            id: 'mac_produto',
            name: 'MAC',
        },
        {
            id: 'imei_produto',
            name: 'IMEI',
        },
        {
            id: 'patrimonio_produto',
            name: 'Patrimônio',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto'
        },
        {
            id: 'codigo_sap_produto',
            name: 'Codigo SAP',
        },
        {
            id: 'invoice_produto',
            name: 'Invoice'
        },
        {
            id: 'nfe_entrada_produto',
            name: 'NFe Entrada',
        },
        {
            id: 'data_nfe_entrada_produto',
            name: 'Data NFe Entrada',
        },
        {
            id: 'peso_equipamento_produto',
            name: 'Peso Equipamento',
        },
        {
            id: 'preco_equipamento_produto',
            name: 'Preço Equipamento',
        },
        {
            id: 'kit_spare_produto',
            name: 'Kit Spare',
        },
        {
            id: 'fabricante_produto',
            name: 'Fabricante',
        },
        {
            id: 'integradora_produto',
            name: 'Integradora',
        },
        {
            id: 'contrato_produto',
            name: 'Contrato',
        },
        {
            id: 'valorado_produto',
            name: 'Valorado',
        },
        {
            id: 'local_produto',
            name: 'Local',
        },
        {
            id: 'estoque_produto',
            name: 'Estoque'
        },
        {
            id: 'corredor_produto',
            name: 'Corredor',
        },
        {
            id: 'estante_produto',
            name: 'Estante',
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico',
        },
        {
            id: 'tipo_tecnico_produto',
            name: 'Tipo do Técnico',
        },
        {
            id: 'email_produto',
            name: 'Email',
        }
    ];

    async function showSendProductToRepairSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo enviar para reparo?',
            text: 'Esta ação só poderá ser desfeita após a conclusão do laudo. Em caso de certeza, por favor descreva o defeito da peça abaixo:',
            input: 'textarea',
            inputValidator: (value) => {
                if (['', undefined, null].includes(value)) {
                    return 'Por favor, descreva o defeito!';
                }
                return null;
            },
            inputLabel: 'Descrição da Falha',
            inputPlaceholder: '...',
            inputAttributes: {
                "aria-label": "..."
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            confirmButtonColor: 'green'
        });

        if (swal.isConfirmed) {
            sendProductToRepair(id, swal.value);
        }
    }

    async function sendProductToRepair(id, descricao) {

        const toastProduct = toast.loading("Enviando para reparo, aguarde...");

        let params = {
            id_produto_reparo: id,
            descricao_defeito: descricao
        }

        let dataReturn = await createReport(params);
        if (dataReturn?.status === 201) {
            handleSuccessToast('Produto enviado para reparo com sucesso!', 'update', toastProduct);
            getProducts(page + 1, perPage, search);
        } else if (dataReturn?.data?.message === 'Já existe um laudo de reparo em andamento para este produto. Não é possível criar um novo enquanto o anterior não for finalizado.') {
            handleErrorsToast('Erro', dataReturn?.data, 'update', toastProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao enviar o produto para reparo!', dataReturn?.data, 'update', toastProduct);
        }
    }

    async function duplicateProductById(id) {

        const toastDuplicate = toast.loading("Duplicando dados, aguarde...");

        let dataReturn = await duplicateProduct(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Produto duplicado com sucesso!', 'update', toastDuplicate);
            getProducts(page + 1, perPage, search);
            openProductModal(dataReturn?.data?.data?.new_product?.id, 'edit');
        } else {
            handleErrorsToast('Ocorreu um erro ao duplicar o produto!', dataReturn?.data, 'update', toastDuplicate);
        }
    }

    async function showDuplicateProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo duplicar este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            duplicateProductById(id);
        }
    }

    async function showDeleteProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo excluir este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            deleteProductById(id);
        }
    }

    async function deleteProductById(id) {
        const toastDuplicate = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deleteProduct(id);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast("Produto excluido com sucesso!", 'update', toastDuplicate);
            getProducts(page + 1, perPage, search);
        } else {
            handleErrorsToast("Ocorreu um erro ao excluir o produto!", dataReturn?.data, 'update', toastDuplicate);
        }
    }

    async function getProducts(page = 1, perPage = 100, params = {}) {
        setLoading(true);
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn;

        if (queue === 'products') {
            // dataReturn = await getAllProducts(page, perPage, params);
            dataReturn = await getAllProductsParams(page, perPage, params);
        } else {
            dataReturn = await getProductsReversa();
        }

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let data = queue === 'products' ? dataReturn?.data?.data?.data : dataReturn?.data?.data;

            let products = data?.map((item) => {
                let tipoTecnico = "";

                if (item.technician?.id_representante !== null) {
                    tipoTecnico = item.technician?.id_representante === 1 ? 'CLT' : 'Parceiro';
                }

                return (
                    {
                        id_produto: item.id,
                        status_produto: item.status?.valor_opcao || '',
                        tipo_produto: item.nome_produto,
                        nome_produto: item?.partnumber?.modelo || '',
                        serial_produto: item.serial,
                        mac_produto: item.mac || '',
                        imei_produto: item.imei || '',
                        patrimonio_produto: item.patrimonio || '',
                        codigo_sap_produto: item.codigo_sap || '',
                        nfe_entrada_produto: item.nfe_entrada || '',
                        data_nfe_entrada_produto: item.data_nfe_entrada || '',
                        peso_equipamento_produto: item.peso_equipamento || '',
                        preco_equipamento_produto: item.preco_equipamento || '',
                        kit_spare_produto: item.kit_spare || '',
                        fabricante_produto: item?.partnumber?.manufacturer?.nome_fabricante || '',
                        integradora_produto: item.integrator?.nome || '',
                        contrato_produto: item.contract?.contrato || '',
                        valorado_produto: item.valorado || '',
                        local_produto: item.local || '',
                        corredor_produto: item.hallway?.valor_opcao || '',
                        estante_produto: item.stand?.valor_opcao || '',
                        tecnico_produto: item.technician?.nome_tec || '',
                        tipo_tecnico_produto: tipoTecnico,
                        email_produto: item.technician?.email_tec || '',
                        chave_opcao_status_produto: item?.status?.chave_opcao || '',
                        invoice_produto: item?.invoice || '',
                        id_localidade_produto: item?.id_localidade || '',
                        id_chamado_produto: item?.ticket?.id || '',
                        id_tecnico_produto: item?.id_tecnico || '',
                        id_contrato_produto: item?.id_contrato || '',
                        id_chamado_produto: item?.last_shipment?.id_chamado || '',
                        num_chamado_produto: item?.last_shipment?.ticket?.num_chamado || '',
                        estoque_produto: item?.box || '',
                        rastreamento_produto: item?.last_shipment?.rastreamento || ''

                    }
                );
            });
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setProductsData(products);
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setProductsData([]);
        }
        setLoading(false);
    }

    function openProductModal(id, mode) {
        setProductId(id);
        setViewMode(mode);
        setIsUpdateProductModalOpen(true);
    }

    function openCreateShipmentModal(id) {
        setProductId(id);
        setIsCreateShipmentModalOpen(true);
        setShipmentMode('single');
    }

    function openCreateBulkShipmentModal() {
        let tecnico = '';
        let contrato = '';
        for (let sr of selectedRows) {
            if (!['SPARE TÉCNICO', 'SPARE TECNICO', 'EM ESTOQUE'].includes(sr?.status_produto)) {
                handleErrorsToast('Todos os produtos precisam estar EM ESTOQUE ou SPARE TÉCNICO', {});
                return;
            }

            if (['SPARE TÉCNICO', 'SPARE TECNICO'].includes(sr?.status_produto) && tecnico !== '' && tecnico !== sr?.id_tecnico_produto) {
                handleErrorsToast('Todas as peças em SPARE TÉCNICO devem pertencer ao mesmo técnico!', {});
                return;
            }

            tecnico = sr?.id_tecnico_produto;

            if (['SPARE TÉCNICO', 'SPARE TECNICO'].includes(sr?.status_produto) && contrato !== '' && contrato !== sr?.id_contrato_produto) {
                handleErrorsToast('Todas as peças em SPARE TÉCNICO devem pertencer ao mesmo contrato!', {});
                return;
            }

            contrato = sr?.id_contrato_produto;
        }
        setIsCreateShipmentModalOpen(true);
        setShipmentMode('bulk');
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getProducts(newPage + 1, perPage, search);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getProducts(1, rows, search);
    }

    function handleFilters(params = {}){
        getProducts(1, 100, params);
        setPerPage(100);
        setPage(0);
        setSearch(params);
    }

    async function handleXlsxExport(){
        const toastData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await exportProducts(search);
        
        if(dataReturn?.status === 200){
            handleSuccessToast(dataReturn?.data?.message || 'Exportação iniciada com sucesso. Você receberá um email com os dados.', 'update', toastData);
        }else{
            handleErrorsToast("Ocorreu um ou mais erros: ", dataReturn?.data, 'update', toastData);
        }
    }

    useEffect(() => {
        getProducts();
    }, [queue]);

    return (
        <Content headerTitle="Gerenciamento de Produtos">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <Card title={queue === 'products' ? "Todos os Produtos" : "Produtos Reversa"}>
                <Row>
                    <Col sm='12' className='flex justify-content-start flex-wrap'>
                        <If condition={checkAllPermissions(cadastroPermissions) && queue === 'products'}>
                            <DropdownButton title='Cadastros' id='dropdown-show-products-registrations' className='m-1'>
                                <If condition={checkPermission(cadastroPermissions[0])}>
                                    <DropdownItem onClick={() => setIsCreateManufacturerOpen(!isCreateManufacturerOpen)}>Adicionar Fabricante</DropdownItem>
                                </If>
                                <If condition={checkPermission(cadastroPermissions[1])}>
                                    <DropdownItem onClick={() => setIsCreateModelOpen(!isCreateModelOpen)}>Adicionar Nome do Produto</DropdownItem>
                                </If>
                                <If condition={checkPermission(cadastroPermissions[2])}>
                                    <DropdownItem onClick={() => setIsAddProductModalOpen(!isAddProductModalOpen)}>Adicionar Produto</DropdownItem>
                                </If>
                                <If condition={checkPermission(cadastroPermissions[4])}>
                                    <DropdownItem onClick={() => setIsBulkCreateOpenModal(!isBulkCreateOpenModal)}>Adicionar Produto em Lote</DropdownItem>
                                </If>
                                <If condition={checkPermission(cadastroPermissions[3])}>
                                    <DropdownItem onClick={() => setIsCreateBoxOpen(!isCreateBoxOpen)}>Adicionar Estoque</DropdownItem>
                                </If>
                            </DropdownButton>
                        </If>
                        <If condition={checkAllPermissions(acoesPermissions) && queue === 'products'}>
                            <DropdownButton title='Ações' id='dropdown-show-products-actions' className='m-1'>
                                <If condition={checkPermission(acoesPermissions[0])}>
                                    <DropdownItem onClick={() => setIsBulkSelectSerialOpen(!isBulkSelectSerialOpen)}>Selecionar Seriais e Criar Remessa em Massa</DropdownItem>
                                </If>
                            </DropdownButton>
                        </If>
                        <If condition={selectedRows?.length > 0 && checkAllPermissions(acoesEmMassaPermissions) && queue === 'products'}>
                            <DropdownButton title='Ações em Massa' className="m-1">
                                <If condition={checkPermission(acoesEmMassaPermissions[0])}>
                                    <DropdownItem onClick={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)}>Editar Selecionados</DropdownItem>
                                </If>
                                <If condition={checkPermission(acoesEmMassaPermissions[1])}>
                                    <DropdownItem onClick={() => openCreateBulkShipmentModal()}>Criar Remessa em Massa</DropdownItem>
                                </If>
                            </DropdownButton>
                        </If>
                    </Col>
                </Row>
                <If condition={queue === 'products'}>
                   <ProductFilters handleFilters={handleFilters} setSearch={setSearch}></ProductFilters>
                </If>
                <Row>
                    <Col className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={productsData}
                            serverPagination={queue === 'products' ? true : false}
                            handlePagination={queue === 'products' ? handlePaginationPrime : null}
                            handleRowPerPage={queue === 'products' ? handleRowPerPagePrime : null}
                            count={queue === 'products' ? paginationTotalRows : 0}
                            page={queue === 'products' ? page : 0}
                            rowsPerPage={queue === 'products' ? perPage : 100}
                            loading={queue === 'products' ? loading : false}
                            selectableRows={checkAllPermissions(acoesEmMassaPermissions) && queue === 'products' ? true : false}
                            selectedRows={checkAllPermissions(acoesEmMassaPermissions) && queue === 'products' ? selectedRows : null}
                            handleSelect={checkAllPermissions(acoesEmMassaPermissions) && queue === 'products' ? setSelectedRows : null}
                            id='show_products'
                            reorderableColumns
                            xlsxFunction={paginationTotalRows > 100 ? handleXlsxExport : null}
                        ></PrimeTable>
                    </Col>
                </Row>
            </Card>
            <CreateProduct isOpen={isAddProductModalOpen} setModalOpen={() => setIsAddProductModalOpen(!isAddProductModalOpen)} setUpdateProducts={() => getProducts(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></CreateProduct>
            <ProductDetails isOpen={isUpdateProductModalOpen} setModalOpen={() => setIsUpdateProductModalOpen(!isUpdateProductModalOpen)} productId={productId} viewMode={viewMode} setUpdateProducts={() => getProducts(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic} queue={queue}></ProductDetails>
            <BulkUpdate isOpen={isBulkUpdateModalOpen} setModalOpen={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)} setUpdateProducts={() => getProducts(page + 1, perPage, search)} handleClearRows={() => setSelectedRows([])} ids={[...selectedRows.map((item) => item.id_produto)]}></BulkUpdate>
            <CreateShipment isOpen={isCreateShipmentModalOpen} setIsOpen={() => setIsCreateShipmentModalOpen(!isCreateShipmentModalOpen)} productId={shipmentMode === 'single' ? productId : [...selectedRows.map((item) => item.id_produto)].toString()} setUpdateProducts={() => getProducts(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic} mode={shipmentMode} handleClearRows={() => setSelectedRows([])}></CreateShipment>
            <CreateModel isOpen={isCreateModelOpen} setIsOpen={() => setIsCreateModelOpen(!isCreateModelOpen)}></CreateModel>
            <CreateManufacturer isOpen={isCreateManufacturerOpen} setIsOpen={() => setIsCreateManufacturerOpen(!isCreateManufacturerOpen)}></CreateManufacturer>
            <CreateBox isOpen={isCreateBoxOpen} setIsOpen={() => setIsCreateBoxOpen(!isCreateBoxOpen)}></CreateBox>
            <BulkCreate isOpen={isBulkCreateOpenModal} setModalOpen={() => setIsBulkCreateOpenModal(!isBulkCreateOpenModal)} setUpdateProducts={() => getProducts(page + 1, perPage, search)}></BulkCreate>
            <BulkSelectSerial isOpen={isBulkSelectSerialOpen} setModalOpen={() => setIsBulkSelectSerialOpen(!isBulkSelectSerialOpen)} setUpdateProducts={() => getProducts(page + 1, perPage, search)} setUpdateLogistic={setUpdateLogistic}></BulkSelectSerial>
        </Content >
    )
}