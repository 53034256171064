import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import Form from './Form';
import { getComponentById } from '../../../services/Repair';
import { toast } from 'react-toastify';
import { handleErrorsToast, handleSuccessToast } from '../../../configs/GenericFunctions';

export default function SparePartDetails({
    visible = false,
    onHide = null,
    id = ''
}){
    const INITIAL_STATE = {
        id_modelo: '',
        id_produto_origem: '',
        origem: '',
        componente: '',
        valor: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getSparePartDetails(){
        let toastSpare = toast.loading('Carregando dados, aguarde...');
        
        let dataReturn = await getComponentById(id);

        if(dataReturn?.data?.response === 'success'){
            let sparePart = dataReturn?.data?.data;
            sparePart['origem'] = { value: sparePart?.origem || '', label: sparePart?.origem || ''};
            sparePart['criado_por'] = sparePart?.created_by?.login || '';
            sparePart['criado_em'] = new Date(sparePart?.created_at).toLocaleString('sv-SE') || '';
            if(sparePart?.origem?.value === 'comprado'){
                sparePart['id_fabricante'] = { value: sparePart?.partnumber?.manufacturer?.id || '', label: sparePart?.partnumber?.manufacturer?.nome_fabricante || ''};
                sparePart['id_modelo'] = { value: sparePart?.partnumber?.id || '', label: sparePart?.partnumber?.modelo || ''}
            }else{
                sparePart['id_produto_origem'] = { value: sparePart?.product_origin?.id || '', label:  `${sparePart?.partnumber?.manufacturer?.nome_fabricante || ''} - ${sparePart?.partnumber?.modelo || ''} - ${sparePart?.product_origin?.nome_produto || ''} - ${sparePart?.product_origin?.serial || ''}` }
            }

            setFormData(sparePart);
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastSpare);
        }else{
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastSpare);
        }     
    }

    useEffect(()=>{
        if(visible && !['', undefined, null].includes(id)){
            getSparePartDetails();
        }else{
            setFormData(INITIAL_STATE);
        }
    },[visible, id]);

    return(<Dialog
        visible={visible}
        onHide={onHide}
        header='Detalhes do Componente'
        maximizable
    >
        <Form mode='view' submitForm={null} formData={formData} setFormData={setFormData}></Form>
    </Dialog>)
}