import React, { useEffect, useState } from 'react';
import If from '../../../../common/Layout/If';
import { getProductHistory } from '../../../../services/Product';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import { checkValue } from '../../../../configs/GenericFunctions';

export default function Timeline({ idProduto = '', updateTimeline = false, setUpdateTimeline = null }) {
    const [timelineData, setTimelineData] = useState([]);
    const timeLineObjects = {
        'ticket': 'num_chamado',
        'product': 'serial',
        'partnumber': 'modelo',
        // 'tipo_remessa': 'label',
        // 'id_representante': 'label',
    }
    function handleTimelineActions(id) {
        setTimelineData(prevState => prevState.map(item => item?.id === id ? { ...item, showInfo: !item?.showInfo } : item));
    }

    async function getTimelineData() {
        let dataReturn = await getProductHistory(idProduto);

        if (dataReturn?.data?.response === 'success') {
            let timeline = dataReturn?.data?.data?.map((item, idx) => {
                let detalhesAcao = {rows: [{}], columns: []};
                for (const [key, value] of Object.entries(JSON.parse(item?.detalhes_acao))) {
                    if(typeof value === 'object' && !checkValue(value)){
                        if(Array.isArray(value)){
                            detalhesAcao.rows[0][`${key}_${idx}`] = value.join(', ');
                        }else{
                            detalhesAcao.rows[0][`${key}_${idx}`] = value?.[timeLineObjects?.[key] || 'label'] || '';
                        }
                    }else{
                        detalhesAcao.rows[0][`${key}_${idx}`] = value || '';
                    }


                    detalhesAcao.columns.push(
                        {
                            id: `${key}_${idx}`,
                            name: key
                        }
                    )
                }

                return ({
                    ...item,
                    detalhes_acao: detalhesAcao,
                    showInfo: false
                })
            });
            setTimelineData(timeline);
        } else {
            setTimelineData([]);
        }
    }

    useEffect(() => {
        getTimelineData();
    }, []);

    useEffect(() => {
        if (updateTimeline) {
            getTimelineData();
            setUpdateTimeline(false);
        }
    }, [updateTimeline])

    return (<>
        <h5>Timeline</h5>
        <div className="timeline">
            <div className="timeline-border"></div>
            <ul className="timeline-items" id="timeline-items">
                {
                    timelineData.map((item, idx) => (
                        <li className="timeline-item" key={item?.id}>
                            <div className="timeline-item-header" onClick={() => handleTimelineActions(item?.id)} style={{ cursor: 'pointer' }}>
                                <h4 className="timeline-item-title">
                                    <strong>Ação: </strong> {item?.acao_realizada || ''} <i className={`fas ${item?.showInfo ? 'fa-arrow-down' : 'fa-arrow-right'}`}></i>
                                </h4>
                                <span style={{ fontSize: '0.8rem' }}>{item?.data_modificacao || ''}</span>
                            </div>
                            <If condition={item?.showInfo}>
                                <div className="timeline-item-body">
                                    <div className="timeline-item-description">
                                        <strong>Detalhes Alterados: </strong>
                                        <PrimeTable
                                            columns={item?.detalhes_acao?.columns || []}
                                            rows={item?.detalhes_acao?.rows || []}
                                            id={`${item?.id}_table`}
                                        ></PrimeTable>
                                    </div>
                                    <div className="timeline-item-description">
                                        <strong>Usuário Responsável pela Alteração: </strong>{item?.user?.nome || ''}
                                    </div>
                                </div>
                            </If>
                            <hr></hr>
                        </li>
                    ))}
            </ul>
        </div>
    </>)

}