import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { getSubComponentsPermissions } from '../../../../configs/MenuItemsConfigs';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import { toast } from 'react-toastify';
import { getRMAProducts } from '../../../../services/Product';
import ProductRMADetails from './ProductRMADetails';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function ShowRMA() {
    const [updateLogistic, setUpdateLogistic] = useState(false);
    const [isProductDetailsVisible, setIsProductDetailsVisible] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    const [idProduct, setIdProduct] = useState('');
    const [data, setData] = useState([]);
    const { action: actionPermission } = getSubComponentsPermissions('mam_logistica', 'ShowRMA', 'ShowRMA');
    const { checkAllPermissions, checkPermission } = usePermissions();
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={checkPermission(actionPermission[0])}>
                            <button type="button" className="dropdown-item" onClick={()=>handleProductDetails(row.id, 'view')}>Ver Produto</button>
                        </If>
                        <If condition={checkPermission(actionPermission[1])}>
                            <button type='button' className="dropdown-item" onClick={()=>handleProductDetails(row.id, 'edit')}>Editar</button>
                        </If>
                    </div>
                </div >),
            permission: checkAllPermissions(actionPermission)
        },
        {
            id: 'status',
            name: 'Status Produto',
        },
        {
            id: 'modelo',
            name: 'Nome Produto',
        },
        {
            id: 'serial',
            name: 'Serial',
        },
        {
            id: 'nome_produto',
            name: 'Tipo de Produto'
        },
        {
            id: 'data_entrada_rma',
            name: 'Data Entrada RMA',
        },
        {
            id: 'data_coleta',
            name: 'Data Coleta',
        },
        {
            id: 'data_solicitacao_coleta',
            name: 'Data Solicitação Coleta'
        },
        {
            id: 'produto_rma',
            name: 'Produto RMA',
        },
        {
            id: 'nfe_devolucao',
            name: 'NFe Devolução',
        },
        {
            id: 'numero_rma',
            name: 'Número RMA',
        },
        {
            id: 'numero_rma_reposicao',
            name: 'Número RMA Reposição',
        }
    ]

    async function getProducts() {
        const toastProduct = toast.loading('Carregando dados, aguarde....');

        let dataReturn = await getRMAProducts();

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados Encontrados', 'update', toastProduct);
            let products = dataReturn?.data?.data.map((item)=>({
                ...item,
                status: item?.status?.valor_opcao || '',
                modelo: item?.partnumber?.modelo || '',
                produto_rma: `${item?.rma_product?.partnumber?.modelo || ''} - ${item?.rma_product?.serial || ''}`
            }));
            setData(products);
        } else {
            handleErrorsToast('Ocorreu um erro ao encontrar os dados!', dataReturn?.data, 'update', toastProduct);
        }
    }

    function handleProductDetails(id, viewMode){
        setIdProduct(id);
        setViewMode(viewMode);
        setIsProductDetailsVisible(true);
    }

    useEffect(() => {
        getProducts();
    }, [])

    return (
        <Content headerTitle='Peças em RMA'>
            <ProductRMADetails visible={isProductDetailsVisible} onHide={() => setIsProductDetailsVisible(!isProductDetailsVisible)} id_produto={idProduct} viewMode={viewMode} updateParent={getProducts} setUpdateLogistic={setUpdateLogistic}></ProductRMADetails>
            <SubHeaderSpecific subHeaderConfig='mam_logistica' updateLogistic={updateLogistic} setUpdateLogistic={setUpdateLogistic}></SubHeaderSpecific>
            <Card title='Produtos - RMA'>
                <PrimeTable
                    columns={columns}
                    rows={data}
                    id='show_rma'
                    reorderableColumns
                ></PrimeTable>
            </Card>
        </Content>
    )
}