import React, { useRef, useState } from 'react';
import If from '../../../../common/Layout/If';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../../services/Contracts';
import { handleErrorsToast, handleSuccessToast } from '../../../../configs/GenericFunctions';

export default function POP({formContractData = {}, updateData = false, editorState = false, setEditorState = null, idContract = '', getContractDataById = null}) {
    const arquivoPopRef = useRef();
    const arquivoPlantaRef = useRef();
    const [popFile, setPopFile] = useState(null);
    const [plantFile, setPlantFile] = useState(null);

    async function submitFiles(e, type) {
        e.preventDefault();

        const formData = new FormData();

        formData.append('arquivo', type === 'pop' ? popFile : plantFile)
        formData.append('tipo', type)

        const toastUpdate = toast.loading("Executando upload, aguarde...");

        let dataReturn = await uploadFile(idContract, formData);

        if (dataReturn?.data.response === 'success') {
            handleSuccessToast('Upload executado com sucesso!', 'update', toastUpdate);
            getContractDataById('files');
        } else {
            handleErrorsToast('Ocorreu um erro ao executar o upload', dataReturn?.data, 'update', toastUpdate);
        }

        if (type === 'planta') {
            arquivoPlantaRef.current.value = "";
        } else {
            arquivoPopRef.current.value = "";
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className="fas fa-sticky-note"></i> POP</h5>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="">Resumo POP</label>
                    <If condition={updateData}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        ></Editor>
                    </If>
                    <If condition={!updateData}>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class-readonly"
                            toolbarClassName="toolbar-class-readonly"
                            readOnly={true}
                        ></Editor>
                    </If>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={((e) => submitFiles(e, 'pop'))} encType='multipart/form-data'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="" className='d-block'>Arquivo POP</label>
                                        <input type="file" ref={arquivoPopRef} name="arquivo_pop" accept='.doc, .docx' onChange={(e) => { setPopFile(e.target.files[0]) }} disabled={!updateData} required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className='btn btn-primary' disabled={!updateData}>Subir POP</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <If condition={formContractData?.arquivo_pop !== null}>
                        <div className="col-12">
                            <a href={`https://view.officeapps.live.com/op/view.aspx?src=${formContractData?.url_arquivo_pop}`} className='m-1' target="_blank">Acessar POP</a>
                            <a href={`${formContractData?.url_arquivo_pop}`} className='m-1' target="_blank" download>Download POP</a>
                        </div>
                    </If>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={((e) => submitFiles(e, 'planta'))} encType='multipart/form-data'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="" className="d-block">Arquivo Planta</label>
                                        <input type="file" ref={arquivoPlantaRef} name="arquivo_planta" accept='.xls, .xlsx' onChange={(e) => { setPlantFile(e.target.files[0]) }} required disabled={!updateData} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className='btn btn-primary' disabled={!updateData}>Subir Planta</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <If condition={formContractData?.arquivo_planta !== null}>
                        <div className="col-12">
                            <a href={`https://view.officeapps.live.com/op/view.aspx?src=${formContractData?.url_arquivo_planta}`} className='m-1' target="_blank">Acessar Planta</a>
                            <a href={`${formContractData?.url_arquivo_planta}`} className='m-1' target="_blank" download>Download Planta</a>
                        </div>
                    </If>
                </div>
            </div>
        </div>
    );
}