import React from 'react';
import AsyncSelect from 'react-select/async';
import { getAvailableProducts } from '../../services/Product';
import { Col, FormLabel } from 'react-bootstrap';

export default function AsyncProdutosSelect({ formData = {}, setFormData = null, cols=[12, 12, 12, 12], fieldName = 'modelo', id_modelo = '', required = false, isDisabled= false, isMulti = false, advancedSetFormData = null }) {

    async function getPartnumbers(inputValue) {
        if(inputValue?.length < 4){
            return;
        }

        let dataReturn = await getAvailableProducts(id_modelo, inputValue);

        if(dataReturn?.data?.response === 'success'){
            return dataReturn?.data?.data?.map((item, idx) => ({ value: item?.id || '', label: `${item?.nome_produto  || ''} - ${item?.serial || ''}`})) || [];
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData !== null){
            advancedSetFormData(value, name);
        }else{
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    }


    return (
        <Col {...(cols.length > 0 ? { sm: cols[0], md: cols[1], lg: cols[2], xl: cols[3] } : {})}>
            <FormLabel htmlFor={fieldName}>Tipo de Produto - Serial</FormLabel>
            <AsyncSelect
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                cacheOptions={true}
                loadOptions={getPartnumbers}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                required={required}
                isDisabled={isDisabled}
                isMulti={isMulti}
                isClearable
                noOptionsMessage={() => ('Sem dados')}
                placeholder="Digite no mínimo 4 caracteres"
            ></AsyncSelect>
        </Col>

    );
}