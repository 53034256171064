import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../App";
import { Link } from 'react-router-dom';
import { countByQueue } from "../../../services/Ticket";
import usePermissions from "../../../hooks/usePermissions";
import './Header.css';
import { getHeaderIconCounts, getHeaderImageCounts } from "../../../configs/MenuItemsConfigs";


export default function QtChamadosHeader() {
    const userData = useContext(Context);
    const [componentTickets, setComponentTickets] = useState(null);
    const { checkPermission } = usePermissions();
    const milliseconds = 60000;
    let interval;

    async function renderQtdChamados() {

        let resp = await countByQueue();

        if (resp?.data?.data) {
            let data = Object.entries(resp.data?.data);
            const iconItems = getHeaderIconCounts();
            const imageItems = getHeaderImageCounts();

            let iconcommon = data
                .filter(value => {
                    const iconItem = iconItems.find(obj => obj.type === value[0]);
                    return iconItem !== undefined;
                })
                .map((value) => {
                    const iconItem = iconItems.find(obj => obj.type === value[0]);
                    if (checkPermission(iconItem.menu_permission)) {
                        return (
                            <li className="nav-item" key={`icon_${value[0]}`}>
                                <Link title={iconItem.name} className="nav-link" to={iconItem.path}>
                                    <i className={`fas ${iconItem.icon} icon-qtd-chamados`} style={{ color: iconItem.color }}></i>{value[1]}
                                </Link>
                            </li>
                        );
                    }
                    return null;
                });

            let imagecommon = data
                .filter(value => {
                    const imageItem = imageItems.find(obj => obj.type === value[0]);
                    return imageItem !== undefined;
                })
                .map((value, idx) => {
                    const imageItem = imageItems.find(obj => obj.type === value[0]);
                    if (checkPermission(imageItem.menu_permission)) {
                        return (
                            <li className="nav-item" key={`image_${value[0]}`}>
                                <Link title={imageItem.name} className="nav-link" to={imageItem.path}>
                                    <img src={imageItem.image} alt={imageItem.name} className="img-qtd-chamados"></img>{value[1]}
                                </Link>
                            </li>
                        );
                    }
                    return null;

                });

            const tickets = [...iconcommon, ...imagecommon];

            setComponentTickets(tickets);
        }
    }

    useEffect(() => {
        renderQtdChamados();

        interval = setInterval(() => {
            renderQtdChamados();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };

    }, [userData.userDataState.permissoes]);

    return (
        <ul className="navbar-nav ml-auto d-flex flex-row flex-wrap align-items-center">
            {componentTickets}
        </ul>
    );
}