import React from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import defaultUserImage from '../resources/imgs/profile/default.jpg';
import defaultErrorImage from '../resources/imgs/error/no-image.jpg';
import { getEquipmentsByTicketId, getShipmentsByStatus } from '../services/Shipment';
import Swal from 'sweetalert2';
import { getAllUsers } from '../services/Users';
import { getOptionsByModuleField } from '../services/Options';

let timeoutId;

export function handleErrorsToast(title = 'Ocorreu um ou mais erros:', error = {}, toastType = 'warning', toastClass = null) {
    let data = [<p key='title'>{title}</p>];

    if (error?.errors) {
        for (let e in error?.errors) {
            let errorsMessages = error?.errors[e];

            if(Array.isArray(errorsMessages)){
                errorsMessages.forEach((item, idx) => data.push(<p key={idx}>{item}</p>))
            }else{
                data.push(<p key={1}>{errorsMessages}</p>)
            }
        }
    } else if (error?.message) {
        if (typeof error?.message === 'string') {
            data.push(<p key='message'>{error?.message}</p>);
        } else {
            for (let e in error?.message) {
                let errorsMessages = error?.message[e];
                errorsMessages.forEach((item, idx) => data.push(<p key={idx}>{item}</p>))
            }
        }
    }

    if (toastType === 'update') {
        return toast.update(toastClass, { render: <>{data}</>, type: "warning", isLoading: false, autoClose: 1500 });
    }

    return toast.warning(<>{data}</>, { autoClose: 2000 });
}

export async function handleSuccessToast(message = 'Sucesso!', toastType = 'success', toastClass = null) {

    if (toastType === 'update') {
        return toast.update(toastClass, { render: message, type: 'success', isLoading: false, autoClose: 1500 });
    }

    return toast.success(message);

}

export function getNextDate(currentDate = '', daysToAdd = 0) {
    if (['', undefined, null].includes(currentDate)) {
        return;
    }
    const date = new Date(currentDate);
    date.setDate(date.getDate() + daysToAdd);
    return date;
}

export function getCurrentDate(daysToAdd = 0, monthsToAdd = 0, yearsToAdd = 0) {
    const date = new Date();

    date.setDate(date.getDate() + daysToAdd);
    date.setMonth(date.getMonth() + monthsToAdd);
    date.setFullYear(date.getFullYear() + yearsToAdd);

    const currentMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const currentDay = (date.getDate()).toString().padStart(2, '0');
    const currentDate = `${date.getFullYear()}-${currentMonth}-${currentDay}`;
    return currentDate;
}

export function getCurrentTime() {
    const date = new Date();
    const currentHour = date.getHours().toString().padStart(2, '0');
    const currentMinutes = date.getMinutes().toString().padStart(2, '0');
    const currentTime = `${currentHour}:${currentMinutes}`;
    return currentTime;
}

export function checkEmptyString(data) {
    return /^\s*$/.test(data);
}

export function isJsonValid(json) {
    try {
        JSON.parse(json);
        return true;
    } catch (e) {
        return false;
    }
}

export function openNewTab(url) {
    window.open(window.location.origin + url, '_blank')
}

export function onImageError(e, type = 'profile') {
    if (type === 'profile') {
        e.target.src = defaultUserImage
    } else {
        e.target.src = defaultErrorImage
    }
}

export function detectDeviceTypeByUserAgent() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i)) {
        return "mobile";
    } else {
        return "desktop";
    }
}

export function checkDateBetween(dataInicial, dataFinal, dataCorrente) {
    let firstDate = new Date(dataInicial);
    let lastDate = new Date(dataFinal);
    let currentDate = new Date(dataCorrente);
    return ((firstDate <= currentDate) && (currentDate <= lastDate));
}

export function getWeek(date) {
    const newDate = new Date(date);
    const startOfYear = new Date(newDate.getFullYear(), 0, 1);
    const pastDayOfYear = (newDate - startOfYear) / 86400000;
    const weekNumber = Math.ceil((pastDayOfYear + startOfYear.getDay() + 1) / 7);

    return `${newDate.getFullYear()}-W${weekNumber}`;
}

export function convertWeekToDate(week) {
    const splittedData = week.split("-W");
    const startOfYear = dayjs(`${splittedData[0]}-01-01`);
    let firstMonday = startOfYear.day(1);

    if (firstMonday.isBefore(startOfYear)) {
        firstMonday = firstMonday.add(1, 'week');
    }

    const mondayOfWeek = firstMonday.add(parseInt(splittedData[1]) - 1, 'week');
    const sundayOfWeek = mondayOfWeek.add(6, 'day');

    return {
        startOfWeek: mondayOfWeek.format('YYYY-MM-DD'),
        endOfWeek: sundayOfWeek.format('YYYY-MM-DD')
    }
}

export function base64toBlob(base64, contentType = 'image/jpeg', sliceSize = 512) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function copyToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {

        navigator.clipboard.writeText(text).then(() => {
            toast.success('Texto copiado com sucesso!', { autoClose: 100 });
        }).catch((e) => {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);

            textarea.select();
            document.execCommand('copy');

            document.body.removeChild(textarea);
        });

    } else {

        console.warn('Clipboard API indisponível, usando método legado');
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand('copy');

        document.body.removeChild(textarea);
    }
}

export function formatDate(date, format = "YYYY-MM-DD") {
    if (['', undefined, null].includes(date)) {
        return null;
    }

    let formattedDate = dayjs(date).format(format);

    if (formattedDate === 'Invalid Date') {
        return null;
    }

    return formattedDate;
}

export async function getTicketQuant(num_chamado) {
    if (!['', undefined, null, false].includes(num_chamado)) {

        let dataReturn = await getEquipmentsByTicketId(num_chamado);
        if (dataReturn?.status === 200) {
            let data = dataReturn?.data?.data;

            return data?.length > 1 ? data?.length : 1;
        } else {
            return 1;
        }
    } else {
        return 1;
    }
}

export async function getShipmentsByTicketId(status, search) {
    let dataReturn = await getShipmentsByStatus(status, 1, 1000, search);

    if (dataReturn?.status === 200) {
        let products = dataReturn?.data?.data?.data || [];

        let productData = products.map((item) => ({
            id_produto: item?.id || '',
            modal_envio_produto: item?.modal_envio || '',
            id_chamado_produto: item?.ticket?.id || '',
            chave_tipo_remessa_produto: item?.chave_tipo_remessa || ''
        }));

        return productData?.length > 0 ? productData : [];
    } else {
        return [];
    }
}

export async function showBulkShipmentMessage(singleShipmentFunction, bulkShipmentFunction) {
    let response = await Swal.fire({
        title: 'Esta remessa está ligada a outras pelo mesmo chamado. Gostaria de atualizar apenas ela ou atualizar as remessas em massa?',
        showCancelButton: true,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: 'Atualizar remessa atual',
        cancelButtonText: 'Cancelar operação',
        denyButtonText: 'Atualizar remessas em massa'
    });

    if (response.isConfirmed) {
        singleShipmentFunction();
    }

    if (response.isDenied) {
        bulkShipmentFunction();
    }
}

export async function showBulkProductMessage(singleProductFunction, bulkProductFunction) {
    let response = await Swal.fire({
        title: 'Este produto está ligada a outras pelo mesmo técnico. Gostaria de atualizar apenas ele ou atualizar os produtos em massa?',
        showCancelButton: true,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: 'Atualizar produto atual',
        cancelButtonText: 'Cancelar operação',
        denyButtonText: 'Atualizar produtos em massa'
    });

    if (response.isConfirmed) {
        singleProductFunction();
    }

    if (response.isDenied) {
        bulkProductFunction();
    }
}

export function checkValue(value) {
    return ['', undefined, null].includes(value);
}

export async function getActivatedUsers(status = 1, userGroup = '') {
    let dataReturn = await getAllUsers(status, userGroup);

    if (dataReturn?.data?.response === 'success') {
        let usuarios = dataReturn?.data?.data.map((item, idx) => {
            return ({ value: item.id, label: item.nome });
        });

        return usuarios;
    } else {
        return []
    }
}

export function debounce(callbackFunction, delay = 300) {
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callbackFunction(...args);
        }, delay);
    }
}

export async function getChaveStatusProduto(status_remessa) {
    let dataReturn = await getOptionsByModuleField('produtos', 'id_status_produto');

    if (dataReturn?.status === 200) {
        let statusProduto = dataReturn?.data?.data?.data?.filter(item => item.valor_opcao === status_remessa)[0];
        return { value: statusProduto?.chave_opcao || '', label: status_remessa || '' };
    }

    return false;
}