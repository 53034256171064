import React, { useEffect, useState } from 'react';
import { getProductByProductId, updateProductData } from '../../../../../services/Product';
import { toast } from 'react-toastify';
import usePermissions from '../../../../../hooks/usePermissions';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { getSubComponentsPermissions } from '../../../../../configs/MenuItemsConfigs';
import { base64toBlob, checkValue, getActivatedUsers, getChaveStatusProduto, handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';
import Form from '../Form';
import GedTable from '../Ged/GedTable';
import Timeline from '../Timeline';
import ShipmentForm from '../ShipmentForm';
import { confirmInvoiceIssuance, sendForInvoiceIssuance, updateInPersonDelivery } from '../../../../../services/Shipment';
import { updateEquipmentStatus } from '../../../../../services/Ticket';

export default function ProductDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, productId = '', viewMode = 'view', setUpdateLogistic = null, queue = 'products', parentPage = '' }) {
    const initialState = {
        nome_produto: '',
        serial: '',
        id_modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        mac: '',
        imei: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        patrimonio: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: '',
        id_localidade: '',
        numero_rma: '',
        proveniente_rma: 'nao',
        id_produto_rma: '',
        numero_reversa: '',
        serial_defeituoso: '',
        nf_retorno: '',
        rastreamento_retorno: '',
        data_reversa: '',
        data_postagem_retorno: '',
        data_retorno: '',
        data_expiracao_reversa: '',
        id_usuario_gestor: '',
        data_informada_gestao: '',
        descricao_interna: ''
    }
    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);

    const [formRemessasProduto, setFormRemessaProduto] = useState({});
    const [sign, setSign] = useState(null);
    const [url, setUrl] = useState('');

    const [updateParent, setUpdateParent] = useState(false);
    const { checkPermission } = usePermissions();
    const { tab: tabPermissions, page: pagePermissions } = getSubComponentsPermissions('mam_logistica', 'ShowProducts', 'ProductDetails');
    const { tab: tabPermissionsReturn, page: pagePermissionsReturn } = getSubComponentsPermissions('mam_logistica', 'ShowReturn', 'ProductDetails');

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            ...formCadastroProduto,
            id_modelo: formCadastroProduto.id_modelo?.value || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            valorado: formCadastroProduto.valorado?.value || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || '',
            id_produto_rma: formCadastroProduto?.id_produto_rma?.value || '',
            id_usuario_gestor: formCadastroProduto?.id_usuario_gestor?.value || '',
            serial: formCadastroProduto?.serial?.replace(/^0+/, ""),
            serial_defeituoso: formCadastroProduto?.serial_defeituoso?.replace(/^0+/, "")
        }

        const toastCreateProduct = toast.loading("Alterando dados do produto, aguarde...");

        let dataReturn = await updateProductData(productId, formData);
        if (dataReturn?.data?.data?.message === 'Produto atualizado com sucesso!') {
            handleSuccessToast('Produto atualizado com sucesso!', 'update', toastCreateProduct);
            setUpdateProducts();
            setUpdateParent(true);
            setUpdateLogistic(true);
        } else if (dataReturn?.data?.message === 'Já existe um produto com esse número de série.') {
            handleErrorsToast('Esse serial já existe!', dataReturn?.data, 'update', toastCreateProduct);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar o produto!', dataReturn?.data, 'update', toastCreateProduct);
        }
    }

    async function getProductDataById() {
        const toastProducts = toast.loading('Carregando dados do Produto, aguarde...');

        let dataReturn = await getProductByProductId(productId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let product = dataReturn?.data?.data;
            let shipmentData = dataReturn?.data?.data?.last_shipment || {};
            let tipoTecnico = '';
            let responsavel_separacao = {}
            let status_remessa = {};


            if (shipmentData && viewMode !== 'view') {
                responsavel_separacao = await getActivatedUsers(1, 'logistica_operacao').then((response) => { return response.filter(item => item.label === shipmentData?.responsavel_separacao)[0] });
            }

            if (['EM TRANSITO', 'EM TRÂNSITO'].includes(shipmentData?.status_remessa)) {
                status_remessa = await getChaveStatusProduto('EM TRANSITO');
            }

            if (product?.technician?.id_representante !== null) {
                tipoTecnico = product?.technician?.id_representante === 1 ? 'CLT' : 'Parceiro'
            }

            setFormCadastroProduto(prevState => (
                {
                    ...prevState,
                    ...product,
                    kit_spare: { value: product?.kit_spare || '', label: product?.kit_spare || '' },
                    id_fabricante: { value: product?.partnumber?.manufacturer?.id || '', label: product?.partnumber?.manufacturer?.nome_fabricante || '' },
                    id_integradora: { value: product?.contract?.id_integradora || '', label: product?.contract?.integrator?.nome || '' },
                    id_contrato: { value: product?.id_contrato || '', label: product?.contract?.contrato || '' },
                    valorado: { value: product?.valorado || '', label: product?.valorado || '' },
                    local: { value: product?.local || '', label: product?.local || '' },
                    box: { value: product?.box || '', label: product?.box || '' },
                    id_corredor: { value: product?.id_corredor || '', label: product?.hallway?.valor_opcao || '' },
                    id_estante: { value: product?.id_estante || '', label: product?.stand?.valor_opcao || '' },
                    id_prateleira: { value: product?.id_prateleira || '', label: product?.shelf?.valor_opcao || '' },
                    id_representante: { value: product?.technician?.partner?.id || '', label: product?.technician?.partner?.nome_representante || '' },
                    id_tecnico: { value: product?.id_tecnico || '', label: product?.technician?.nome_tec || '' },
                    id_status_produto: { value: product?.status?.id || '', label: product?.status?.valor_opcao || '' },
                    id_modelo: { value: product?.id_modelo || '', label: product?.modelo || '' },
                    tipo_tecnico: tipoTecnico,
                    proveniente_rma: [null, undefined, ''].includes(product?.rma_product) ? 'nao' : 'sim',
                    id_produto_rma: { value: product?.rma_product?.id || '', label: `${product?.rma_product?.partnumber?.manufacturer?.nome_fabricante || ''} - ${product?.rma_product?.partnumber?.modelo || ''} - ${product?.rma_product?.nome_produto || ''} - ${product?.rma_product?.serial || ''}` },
                    id_usuario_gestor: { value: product?.manager?.id || '', label: product?.manager?.nome || '' },
                }
            ));

            setFormRemessaProduto(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
                urgencia_remessa: viewMode === 'view' ? shipmentData?.urgencia_remessa || '' : { value: shipmentData?.urgencia_remessa || '', label: shipmentData?.urgencia_remessa || '' },
                local: viewMode === 'view' ? shipmentData?.product?.local || '' : { value: shipmentData?.product?.local || '', label: shipmentData?.product?.local || '' },
                box: viewMode === 'view' ? shipmentData?.product?.box || '' : { value: shipmentData?.product?.box || '', label: shipmentData?.product?.box || '' },
                id_localidade: shipmentData?.id_localidade || '',
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: viewMode === 'view' ? shipmentData?.product?.technician?.nome_tec || '' : { value: shipmentData?.product?.technician?.id || '', label: shipmentData?.product?.technician?.nome_tec || '' },
                fabricante: shipmentData?.product?.partnumber?.manufacturer?.nome_fabricante || '',
                id_chamado: viewMode === 'view' ? shipmentData?.ticket?.num_chamado : { value: shipmentData?.ticket?.id || '', label: shipmentData?.ticket?.num_chamado || '' },
                responsavel_separacao: viewMode === 'view' ? shipmentData?.responsavel_separacao : responsavel_separacao,
                tipo_remessa: viewMode === 'view' ? shipmentData?.tipo_remessa || '' : { value: shipmentData?.chave_tipo_remessa || '', label: shipmentData?.tipo_remessa || '' },
                volume_nfe: viewMode === 'view' ? shipmentData?.volume_nfe || '1' : { value: shipmentData?.volume_nfe || '1', label: shipmentData?.volume_nfe || '1' },
                modal_envio: viewMode === 'view' ? shipmentData?.modal_envio || '' : { value: shipmentData?.modal_envio || '', label: shipmentData?.modal_envio || '' },
                chave_status_remessa: status_remessa?.value || ''
            }));
        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setModalOpen(false);
        }
    }

    async function submitShipmentForm(e) {
        e.preventDefault();

        async function submitSignDetails() {
            if (['', undefined, null].includes(url)) {
                handleErrorsToast('Por favor, assine o documento!');
                return false;
            }

            let blob = base64toBlob(url.split(",")[1], 'image/png');

            let params = new FormData();

            params.append('assinatura_recebedor', blob, blob?.type.replace('/', '.'));
            params.append('nome_recebedor', formRemessasProduto?.nome_recebedor);
            params.append('cpf_recebedor', formRemessasProduto?.cpf_recebedor);

            let dataReturn = await updateInPersonDelivery(formRemessasProduto?.id, params);

            if (dataReturn?.data?.response === 'success') {
                return 'success';
            } else {
                return dataReturn?.data;
            }
        }

        async function submitShipmentDetails() {
            let params = {
                ...formRemessasProduto,
                urgencia_remessa: formRemessasProduto?.urgencia_remessa?.value || '',
                id_tecnico: formRemessasProduto?.id_tecnico?.value || '',
                modal_envio: formRemessasProduto?.modal_envio?.value || '',
                box: formRemessasProduto?.box?.value || '',
                local: formRemessasProduto?.local?.value || '',
                id_chamado: formRemessasProduto?.id_chamado?.value || '',
                volume_nfe: formRemessasProduto?.volume_nfe?.value || '',
                responsavel_separacao: formRemessasProduto?.responsavel_separacao?.label || ''
            }

            let dataReturn = await sendForInvoiceIssuance(formRemessasProduto?.id, params);
            if (dataReturn?.data?.response === 'success') {
                return 'success';
            } else {
                return dataReturn?.data;;
            }

        }

        async function submitInvoiceDetails() {
            let formData = {
                ...formRemessasProduto,
                local: formRemessasProduto?.local?.value || '',
                box: formRemessasProduto?.box?.value || '',
                urgencia_remessa: formRemessasProduto?.urgencia_remessa?.value || '',
                id_tecnico: formRemessasProduto?.id_tecnico?.value || '',
                id_chamado: formRemessasProduto?.id_chamado?.value || '',
                modal_envio: formRemessasProduto?.modal_envio?.value || '',
                responsavel_separacao: formRemessasProduto?.responsavel_separacao?.label || '',
                chave_tipo_remessa: formRemessasProduto?.tipo_remessa?.value || '',
                tipo_remessa: formRemessasProduto?.tipo_remessa?.label || '',
                volume_nfe: formRemessasProduto?.volume_nfe?.value || ''
            }

            let dataReturn = await confirmInvoiceIssuance(formRemessasProduto?.id, formData);

            if (dataReturn?.data?.response === 'success') {
                return 'success';
            } else {
                return dataReturn?.data;
            }
        }

        async function submitInTransitDetails() {
            let params = {
                id_remessa: formRemessasProduto?.id || '',
                id_produto: formRemessasProduto?.product?.id || '',
                modal_envio: formRemessasProduto?.modal_envio?.value || '',
                rastreamento: formRemessasProduto?.rastreamento || '',
                status_produto: formRemessasProduto?.chave_status_remessa || ''
            }

            let dataReturn = await updateEquipmentStatus(params);

            if (dataReturn?.data?.response === 'success') {
                return 'success';
            } else {
                return dataReturn?.data;
            }
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');
        
        let result;

        switch (formRemessasProduto?.status_remessa) {
            case "AGUARDANDO ENVIO":
                result = await submitShipmentDetails();
                break;
            case "AGUARDANDO NFE SAÍDA":
                result = await submitInvoiceDetails();
                break;
            case "AGUARDANDO RETIRADA EM MÃOS":
                result = await submitSignDetails();
                break;
            case "EM TRANSITO":
                result = await submitInTransitDetails();
                break;
            case "EM TRÂNSITO":
                result = await submitInTransitDetails();
                break;
            default:
                result = false;
                break;
        }

        if (result === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastProducts);
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', result, 'update', toastProducts);
        }
    }

    function handleParentPage() {
        let params = {
            mode: viewMode,
            page: parentPage,
        }

        if (viewMode === 'view') {
            params.page = 'ProductDetails';
            return params;
        }

        if (formRemessasProduto?.status_remessa === 'AGUARDANDO ENVIO') {
            params.page = 'ShipmentDetails';
            return params;
        }

        if (formRemessasProduto?.status_remessa === 'AGUARDANDO NFE SAÍDA') {
            params.page = 'InvoiceDetails';
            return params;
        }

        if (formRemessasProduto?.status_remessa === 'AGUARDANDO RETIRADA EM MÃOS' && ['update', 'edit'].includes(viewMode)) {
            params.page = 'SignDetails';
            return params;
        }

        if (['EM TRANSITO', 'EM TRÂNSITO'].includes(formRemessasProduto?.status_remessa)) {
            params.page = 'InTransitDetails';
            return params;
        }

        params.page = 'ProductDetails';
        params.mode = 'view';

        return params;
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(productId)) {
            setFormCadastroProduto(initialState);
            setFormRemessaProduto({});
            getProductDataById();
        }
    }, [isOpen, productId]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header={viewMode === 'view' ? 'Visualizar Produto' : 'Atualizar Produto'}
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <TabView>
                <TabPanel header='Detalhes do Produto'>
                    <Form formData={formCadastroProduto} setFormData={setFormCadastroProduto} mode={viewMode} submitForm={submitForm}></Form>
                </TabPanel>
                <TabPanel header='GED' visible={checkPermission(queue === 'products' ? tabPermissions[0] : tabPermissionsReturn[0])}>
                    <GedTable idProduto={productId} submodule='produtos' viewMode={(viewMode === 'edit' && checkPermission(queue === 'products' ? pagePermissions[0] : pagePermissionsReturn[0]) ? 'edit' : 'view')} addTitle='Novo Documento' setUpdateParent={setUpdateParent}></GedTable>
                </TabPanel>
                <TabPanel header='Linha do Tempo' visible={checkPermission(queue === 'products' ? tabPermissions[1] : tabPermissionsReturn[1])}>
                    <Timeline idProduto={productId} setUpdateTimeline={setUpdateParent} updateTimeline={updateParent}></Timeline>
                </TabPanel>
                <TabPanel header='Detalhes da Última Remessa' visible={!checkValue(formRemessasProduto?.id) && checkPermission(queue === 'products' ? tabPermissions[2] : tabPermissionsReturn[2]) && parentPage !== 'CanceledShipments'}>
                    <ShipmentForm
                        formData={formRemessasProduto}
                        parentPage={handleParentPage().page}
                        mode={(handleParentPage().mode === 'edit' && checkPermission(queue == 'products' ? tabPermissions[3] : tabPermissionsReturn[3]) ? 'edit' : 'view')}
                        setFormData={setFormRemessaProduto}
                        submitForm={submitShipmentForm}
                        setSign={setSign}
                        setUrl={setUrl}
                        sign={sign}
                        url={url}
                    ></ShipmentForm>
                </TabPanel>
            </TabView>
        </Dialog >);
}