import React, { useEffect, useState } from 'react';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import Cep from '../../../../common/Cep/Cep';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { getShipmentById, sendForInvoiceIssuance } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import If from '../../../../common/Layout/If';
import AsyncChamadosSelect from '../../../../common/Selects/AsyncChamadosSelect';
import { getTicketById } from '../../../../services/Ticket';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast, getCurrentDate, getActivatedUsers } from '../../../../configs/GenericFunctions';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';

export default function ShipmentDetails({ isOpen = false, setIsOpen = null, shipmentId = '', mode = 'view', setUpdateShipment = null, setUpdateLogistic = null }) {

    const INITIAL_STATE = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        local: '',
        box: '',
        id_representante: '',
        id_tecnico: '',
        id_chamado: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: '',
        responsavel_separacao: '',
        destinatario: '',
        data_entrega: getCurrentDate(),
        volume_nfe: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(shipmentId);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados carregados com sucesso!', 'update', toastProducts);
            let shipmentData = dataReturn?.data?.data;
            let id_chamado = shipmentData?.ticket?.id === undefined ? '' :  { value: shipmentData?.ticket?.id || '', label: `${shipmentData?.ticket?.num_chamado || ''} - ${shipmentData?.ticket?.status_chamado || ''}`}

            let responsavel_separacao = await getActivatedUsers(1, 'logistica_operacao').then((response) => { return response.filter(item => item.label === shipmentData?.responsavel_separacao)[0]});

            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.partnumber?.modelo || '',
                urgencia_remessa: { value: shipmentData?.urgencia_remessa || '', label: shipmentData?.urgencia_remessa || '' },
                local: { value: shipmentData?.product?.local || '', label: shipmentData?.product?.local || '' },
                box: { value: shipmentData?.product?.box || '', label: shipmentData?.product?.box || '' },
                id_representante: { value:  shipmentData?.ticket?.technician?.id_representante || shipmentData?.product?.technician?.id_representante || '', label:  shipmentData?.ticket?.technician?.partners?.[0]?.nome_representante || shipmentData?.product?.technician?.partners?.[0]?.nome_representante || '' },
                modal_envio: { value: shipmentData?.modal_envio || '', label: shipmentData?.modal_envio || '' },
                id_chamado: id_chamado,
                id_tecnico: { value:  shipmentData?.ticket?.technician?.id || shipmentData?.product?.technician?.id || '', label:  shipmentData?.ticket?.technician?.nome_tec || shipmentData?.product?.technician?.nome_tec || '' },
                volume_nfe: { value: shipmentData?.volume_nfe || '', label: shipmentData?.volume_nfe || ''},
                responsavel_separacao: responsavel_separacao
            }));
            
            if(![undefined, '', null].includes(shipmentData?.ticket?.id)){
                getChamadoById(shipmentData?.ticket?.id, 'centro_custo');
            }

        } else {
            handleErrorsToast('Ocorreu um erro ao carregar os dados!', dataReturn?.data, 'update', toastProducts);
            setIsOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            ...formData,
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            id_tecnico: formData?.id_tecnico?.value || '',
            modal_envio: formData?.modal_envio?.value || '',
            box: formData?.box?.value || '',
            local: formData?.local?.value || '',
            id_chamado: formData?.id_chamado?.value || '',
            volume_nfe: formData?.volume_nfe?.value || '',
            responsavel_separacao: formData?.responsavel_separacao?.label || ''
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await sendForInvoiceIssuance(shipmentId, params);
        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Dados atualizados com sucesso!', 'update', toastProducts);
            setUpdateShipment();
            setIsOpen(false);
            setUpdateLogistic(true);
        } else {
            handleErrorsToast('Ocorreu um erro ao atualizar os dados!', dataReturn?.data, 'update', toastProducts);
        }

    }

    function setRepresentante(value, name) {
        setFormData(prevState => ({ ...prevState, [name]: value, id_tecnico: '' }));
    }

    function handleChamado(value, name) {
        setFormData(prevState => ({ ...prevState, [name]: value }));
        if (value && formData?.modal_envio?.value !== 'Em Mãos') {
            getChamadoById(value?.value);
        }else{
            setFormData(prevState => ({
                ...prevState,
                bairro:  '',
                cep:  '',
                cidade:  '',
                uf:  '',
                rua:  '',
                numero: '',
                complemento:  '',
                destinatario:  '',
                centro_custo: ''
            }))
        }
    }

    async function getChamadoById(id, mode = 'all') {
           let ticketData = await getTicketById(id);
           if (ticketData?.data?.response === 'success') {
               let ticket = ticketData?.data?.data;
               let address = ticket?.logistics_shipping;
               let addressNumber = address?.compl_ref_obs_endereco_logistica.match(/\d+/)[0] || address?.logradouro_endereco_logistica.match(/\d+/)[0] || '';
   
               if (mode === 'all') {
                   let final_address = {};
   
                   if (!['', null, undefined, false].includes(address)) {
                       final_address = {
                           bairro: address?.bairro_endereco_logistica || '',
                           cep: address?.cep_endereco_logistica || '',
                           cidade: address?.cidade_endereco_logistica || '',
                           uf: address?.estado_endereco_logistica || '',
                           rua: address?.logradouro_endereco_logistica || '',
                           numero: addressNumber,
                           complemento: address?.compl_ref_obs_endereco_logistica || '',
                           destinatario: address?.destinatario_remessa || '',
                       }
                   }
                   else if (['', null, undefined, false].includes(address) && ['envio_tecnico'].includes(formData?.chave_tipo_remessa)) {
                       let technicianAddress = ticket?.technician;
                       let technicianAddressNumber = technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || technicianAddress?.numero_complemento_endereco?.match(/\d+/)[0] || '';
   
                       final_address = {
                           bairro: technicianAddress?.bairro_end || '',
                           cep: technicianAddress?.cep_end || '',
                           cidade: technicianAddress?.cidade_end || '',
                           uf: technicianAddress?.estado_end || '',
                           rua: technicianAddress?.logradouro_end || '',
                           numero: technicianAddressNumber,
                           complemento: technicianAddress?.compl_ref_obs_end || '',
                           destinatario: technicianAddress?.nome_tec || '',
                       }
                   } else if (['', null, undefined, false].includes(address) && formData?.chave_tipo_remessa === 'envio_cliente') {
                       let customerAddress = ticket?.customer;
                       let customerAddressNumber = customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || customerAddress?.compl_ref_obs_endereco_cliente.match(/\d+/)[0] || '';
   
                       final_address = {
                           bairro: customerAddress?.bairro_cliente || '',
                           cep: customerAddress?.cep_cliente || '',
                           cidade: customerAddress?.cidade_cliente || '',
                           uf: customerAddress?.estado_cliente || '',
                           rua: customerAddress?.endereco_cliente || '',
                           numero: customerAddressNumber,
                           complemento: customerAddress?.compl_ref_obs_endereco_cliente || '',
                           destinatario: '',
                       }
                   }
   
                   setFormData(prevState => ({
                       ...prevState,
                       ...final_address,
                       centro_custo: ticket?.contract?.centro_custo || '',
                   }));
   
               }
   
               if (mode === 'centro_custo') {
                   setFormData(prevState => ({
                       ...prevState,
                       centro_custo: ticket?.contract?.centro_custo || ''
                   }))
               }
   
           } else {
               handleErrorsToast('Ocorreu um erro ao buscar os dados do chamado!', ticketData?.data);
           }
       }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(shipmentId)) {
            setFormData(INITIAL_STATE);
            getShipment();
        }
    }, [isOpen, shipmentId]);

    useEffect(() => {
        if (formData?.id_representante?.value === formData?.product?.technician?.id_representante || formData?.id_representante?.value === formData?.ticket?.technician?.id_representante) {
            setFormData(prevState => ({
                ...prevState,
                id_tecnico: { value: formData?.ticket?.id_tecnico || formData?.product?.id_tecnico ||  '', label: formData?.ticket?.technician?.nome_tec || formData?.product?.technician?.nome_tec ||  '' },
                product: ''
            }))
        }
    }, [formData?.id_representante]);

    return (
        <Dialog
            visible={isOpen}
            onHide={setIsOpen}
            header='Emissão de NFE'
            maximized={true}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5>{formData?.modal_envio?.value !== 'Em Mãos' ? `Preencha todos os dados abaixo para "Emissão da NFE"` : `Preencha todos os dados abaixo para confirmar a entrega ao técnico.`}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mt-1">
                        <label>Nome do Produto</label>
                        <input type="text" className="form-control" placeholder='Nome do Produto' value={formData?.modelo || ''} readOnly />
                    </div>
                    <div className="col-md-4 col-sm-12 mt-1">
                        <label>Serial</label>
                        <input type="text" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label>Tipo de Produto</label>
                        <input type="text" className="form-control" placeholder='Tipo de Produto' value={formData?.nome_produto || ''} readOnly />
                    </div>
                </div>
                <div className="row">
                    <GenericSelect fieldName={'urgencia_remessa'} formData={formData} setFormData={setFormData} title={'Urgência da Remessa'} cols={[12, 12, 4, 4]} genericOption='UrgenciaRemessaOptions' required={true} isDisabled={mode === 'view'}></GenericSelect>
                    <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 12, 4, 4]} genericOption='UfOptions' isDisabled={mode === 'view'}></GenericSelect>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} required={true} uf={formData?.local?.value} isDisabled={mode === 'view'}></BoxSelect>
                    </If>
                    <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='id_representante' required={true} isDisabled={mode === 'view'} advancedSetFormData={setRepresentante}></RepresentanteSelect>
                    <TecnicosSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='id_tecnico' id_representante={formData?.id_representante?.value || ''} required={true} isDisabled={mode === 'view'}></TecnicosSelect>
                    <AsyncChamadosSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='id_chamado' title='ID da Localidade\ID do Chamado' advancedSetFormData={handleChamado} isDisabled={mode === 'view'}></AsyncChamadosSelect>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h6>Detalhes da Remessa</h6>
                    </div>
                </div>
                <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                    <Cep
                        formData={formData}
                        setFormData={setFormData}
                        formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua' }}
                        required={true}
                        readOnly={mode === 'view'}
                    ></Cep>
                </If>
                <div className="row">
                    <If condition={formData?.modal_envio?.value !== 'Em Mãos'}>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="numero">Número</label>
                            <input type="text" name="numero" id="numero" placeholder='...' className="form-control" value={formData?.numero || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={mode === 'view'} />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="complemento">Complemento</label>
                            <input type="text" name="complemento" id="complemento" placeholder='...' className="form-control" value={formData?.complemento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'view'} />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="destinatario">Destinatário</label>
                            <input type="text" name="destinatario" id="destinatario" placeholder='...' className="form-control" value={formData?.destinatario || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={mode === 'view'} />
                        </div>
                    </If>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="dimensoes">Dimensões</label>
                        <input type="text" name="dimensoes" id="dimensoes" placeholder='...' className="form-control" value={formData?.dimensoes || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={mode === 'view'} />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="centro_custo">Centro Custo</label>
                        <input type="text" name="centro_custo" id="centro_custo" placeholder='...' className="form-control" value={formData?.centro_custo || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={mode === 'view'} />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label htmlFor="peso_kg">Peso (kg)</label>
                        <input type="number" step={"0.01"} min={"0"} name="peso_kg" id="peso_kg" placeholder='...' className="form-control" value={formData?.peso_kg || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={mode === 'view'} />
                    </div>
                    <GenericSelect fieldName={'volume_nfe'} formData={formData} setFormData={setFormData} title={'Volume da NFe'} cols={[12, 12, 4, 4]} genericOption='VolumeNfeOptions' required={true} isDisabled={mode === 'view'}></GenericSelect>
                    <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 12, 4, 4]} fieldName='modal_envio' required={true} isDisabled={mode === 'view'}></ModalEnvioSelect>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='responsavel_separacao' cols={[12, 12, 4, 4]} userType='all' required title='Responsável pela Separação' userGroup='logistica_operacao' isDisabled={mode === 'view'}></UsuariosAtivosSelect>
                    <If condition={formData?.modal_envio?.value === 'Em Mãos'}>
                        <div className='col-md-4 col-sm-12'>
                            <label htmlFor="data_entrega">Data de entrega ao técnico</label>
                            <input type="date" name="data_entrega" id="data_entrega" className="form-control" title='Data de entrega nas mãos do técnico' value={formData?.data_entrega || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={mode === 'view'} />
                        </div>
                    </If>
                </div>
                <If condition={mode === 'edit'}>
                    <div className="row mt-1">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                        </div>
                    </div>
                </If>
            </form>
        </Dialog>)
}
