import React, { useEffect, useRef, useState } from 'react';
import { addGed, bulkAddGed } from '../../../../../services/Ged';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { handleErrorsToast, handleSuccessToast } from '../../../../../configs/GenericFunctions';
import { Button, Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';

export default function AddGed({ isOpen, setModalOpen, productId = '', setUpdateData = null, submodule = 'produtos', multiple = false, productIds = [] }) {

    const INITIAL_STATE = {
        titulo: '',
        arquivo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const arquivoRef = useRef();

    async function submitForm(e) {
        e.preventDefault();

       if(multiple){
            submitFileByMultipleId();
       }else{
            submitFileById();
       }
    }

    async function submitFileById(){
        const toastAddGed = toast.loading('Adicionando documento, aguarde...');

        let params = new FormData();

        params.append('files', formData?.arquivo);
        params.append('titulo', formData?.titulo);
        params.append('submodulo', submodule);

        let dataReturn = await addGed(productId, params);

        if (dataReturn?.data?.response === 'success') {
            handleSuccessToast('Documento adicionado com sucesso!', 'update', toastAddGed);
            setFormData(INITIAL_STATE);
            setUpdateData();
            arquivoRef.current.value = '';
        } else {
            handleErrorsToast('Ocorreu um erro ao adicionar o documento!', dataReturn?.data, 'update', toastAddGed);
        }
    }

    async function submitFileByMultipleId() {
        const formDataProduct = new FormData();

        formDataProduct.append('files[]', formData?.arquivo);
        formDataProduct.append('submodulo', submodule);
        formDataProduct.append('titulo', formData?.titulo);
        productIds.forEach(item => formDataProduct.append('ids_produto[]', item));

        let dataReturn = await bulkAddGed(formDataProduct);

        if (dataReturn?.status === 201) {
            handleSuccessToast('Upload da DACT efetuada com sucesso!');
            setFormData(INITIAL_STATE);
            setUpdateData();
            arquivoRef.current.value = '';
        } else {
            handleErrorsToast('Ocorreu um erro', dataReturn?.data);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen])

    return (
        <Dialog
            visible={isOpen}
            onHide={setModalOpen}
            header='Adicionar Documento'
            maximizable={true}
        >
            <Form onSubmit={submitForm}>
                <Row>
                    <Col sm='12'>
                        <FormLabel htmlFor="titulo">Título</FormLabel>
                        <FormControl type="text" name="titulo" id="titulo" value={formData?.titulo || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </Col>
                    <Col sm='12'>
                        <FormLabel htmlFor="arquivo">Arquivo</FormLabel>
                        <FormControl type="file" name="arquivo" id="arquivo" onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }))} ref={arquivoRef} required />
                    </Col>
                    <Col sm='12'>
                        <Button type="submit" variant='primary' className=' mt-1'>Salvar Documento</Button>
                    </Col>
                </Row>
            </Form>
        </Dialog>
    );
}